/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_ReportService_Dtos_ReportConfiguration_SortOrderTypeEnumDto } from '../models/Wealthlane_ReportService_Dtos_ReportConfiguration_SortOrderTypeEnumDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SortOrderTypeService {

    /**
     * Gets Sort Order Type Enums
     * @returns Wealthlane_ReportService_Dtos_ReportConfiguration_SortOrderTypeEnumDto Success
     * @throws ApiError
     */
    public static getApiReportApiAppSortOrderTypeSortOrderType(): CancelablePromise<Array<Wealthlane_ReportService_Dtos_ReportConfiguration_SortOrderTypeEnumDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/sort-order-type/sort-order-type',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
