/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAward_OptionAwardsVestingReportDto_Wealthlane_ReportService_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAward_OptionAwardsVestingReportDto_Wealthlane_ReportService_Application_Contracts';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAwards_OptionAwardsReportDto_Wealthlane_ReportService_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAwards_OptionAwardsReportDto_Wealthlane_ReportService_Application_Contracts';
import type { Wealthlane_ReportService_Dtos_OptionAward_OptionAwardReportRequestDto } from '../models/Wealthlane_ReportService_Dtos_OptionAward_OptionAwardReportRequestDto';
import type { Wealthlane_ReportService_Dtos_OptionAward_OptionAwardVestingReportRequestDto } from '../models/Wealthlane_ReportService_Dtos_OptionAward_OptionAwardVestingReportRequestDto';
import type { Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto } from '../models/Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OptionAwardsService {

    /**
     * @param requestBody 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAwards_OptionAwardsReportDto_Wealthlane_ReportService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiReportApiAppOptionAwardsGetOptionsAwardExerciseReport(
requestBody?: Wealthlane_ReportService_Dtos_OptionAward_OptionAwardReportRequestDto,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAwards_OptionAwardsReportDto_Wealthlane_ReportService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/option-awards/get-options-award-exercise-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppOptionAwardsGetExportOptionsAwardExerciseReport(
requestBody?: Wealthlane_ReportService_Dtos_OptionAward_OptionAwardReportRequestDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/option-awards/get-export-options-award-exercise-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAward_OptionAwardsVestingReportDto_Wealthlane_ReportService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiReportApiAppOptionAwardsGetOptionsAwardVestingReport(
requestBody?: Wealthlane_ReportService_Dtos_OptionAward_OptionAwardVestingReportRequestDto,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAward_OptionAwardsVestingReportDto_Wealthlane_ReportService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/option-awards/get-options-award-vesting-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppOptionAwardsGetExportOptionsAwardVestingReport(
requestBody?: Wealthlane_ReportService_Dtos_OptionAward_OptionAwardVestingReportRequestDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/option-awards/get-export-options-award-vesting-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
