import { type UseQueryResult, useQuery } from 'react-query';
import {
  StandardReportsService,
  Wealthlane_ReportService_Dtos_StandardReport_StandardReportDetailDto,
} from '../../services/wealthlane-report-services';

const useGetReportDesignerById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_ReportService_Dtos_StandardReport_StandardReportDetailDto | undefined,
  unknown
> => {
  const standerReportDesignerById = useQuery(['getReportDesignerById'], async () => {
    return await handleRequest(
      StandardReportsService.getApiReportApiAppStandardReportsStandardReportByIdForCompany(id)
    ).then((response) => {
      if (response != null) {
        const filteredValue = {
          ...response,
          requiredFieldBuckets: response.requiredFieldBuckets?.map((item) => ({
            ...item,
            userFieldFormats: [
              {
                key: item?.userFieldFormats && item?.userFieldFormats[0]?.key,
                value: item?.userFieldFormats && '',
                placeholder: item?.userFieldFormats && item?.userFieldFormats[0]?.value,
              },
            ],
          })),
          optionalFieldBuckets: response.optionalFieldBuckets?.map((item) => ({
            ...item,
            userFieldFormats: [
              {
                key: item?.userFieldFormats && item?.userFieldFormats[0]?.key,
                value: item?.userFieldFormats && '',
                placeholder: item?.userFieldFormats && item?.userFieldFormats[0]?.value,
              },
            ],
          })),
        };
        return filteredValue;
      }
    });
  });
  return standerReportDesignerById;
};

export default useGetReportDesignerById;
