import { type UseQueryResult, useQuery } from 'react-query';
import { PerformanceRuleService, PlanService } from '../../services/wealthlane-award-services';

const useGetPlanGrantDocumentList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  documentName?: number
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const planGrantDocumentList = useQuery(
    ['getPlanGrantDocumentList', id, documentName],

    async () => {
      if (id) {
        return await handleRequest(
          PlanService.getApiAwardApiAppPlanPlanGrantDocumentList(id, documentName)
        );
      }
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.documentName ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return planGrantDocumentList;
};

export default useGetPlanGrantDocumentList;
