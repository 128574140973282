import { isEmpty } from 'lodash';
import { type UseQueryResult, useQuery } from 'react-query';
import {
  RsuAwardApprovalService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetRsuAwardsParticipantsList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    approvalStatusId,
    optionAcceptance: rsuAcceptance,
    fromDate,
    toDate,
    createdFromDate,
    createdToDate,
    filter,
    sorting,
    skipCount,
    maxResultCount,
    employeeStatusId,
  } = tableInfo;
  const rsuStatus = 0;

  const RsuAwardsParticipantApprovalData = useQuery(
    [
      'getRsuAwardsParticipantApprovalList',
      approvalStatusId,
      fromDate,
      toDate,
      employeeStatusId,
      rsuStatus,
      rsuAcceptance,
      createdFromDate,
      createdToDate,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        RsuAwardApprovalService.getApiAwardApiAppRSUAwardApprovalRSUAwardList(
          !isEmpty(approvalStatusId) ? approvalStatusId : [0],
          fromDate,
          toDate,
          employeeStatusId ?? [0],
          rsuStatus,
          rsuAcceptance,
          createdFromDate,
          createdToDate,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return RsuAwardsParticipantApprovalData;
};

export default useGetRsuAwardsParticipantsList;
