import { type UseMutationResult, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  UserDefinedFieldValuesService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UpdateUserDefinedFieldValuesDto,
} from '../../services/wealthlane-customer-services';

const usePutUdfValue = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  route: string
): UseMutationResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UpdateUserDefinedFieldValuesDto[]
  | undefined,
  unknown,
  any,
  unknown
> => {
  const navigate = useNavigate();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        UserDefinedFieldValuesService.putApiCustomerApiAppUserDefinedFieldValuesUserDefinedFieldValues(
          payload?.data
        )
      );
    },
    {
      onSuccess: (response, data: any) => {
        if (response != null) {
          Boolean(data?.redirect) &&
            navigate(route, {
              state: {
                loading: true,
                modalStatus: data?.formStatus,
                reportDetail: data?.reportDetail,
              },
            });
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePutUdfValue;
