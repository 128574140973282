import React from 'react';
import { usePagination } from '../../hooks';
import { DOTS } from '../../hooks/custom-hooks/usePagination';
import Heading from '../typography';
import { Pagination } from './table.style';

interface PaginationType {
  data: string;
  totalCount: number;
  pageIndex: number;
  showRow?: boolean;
  pageSize: string;
  maxResultCount: number;
  showPaginationList?: boolean;
  handlePaginationInfo: (value: number) => void;
  handleSelectInfo: (value: string) => void;
  pageSizeTotal: number;
}

const PaginationComponent = ({
  data,
  totalCount,
  showRow,
  showPaginationList,
  pageIndex,
  pageSize,
  maxResultCount,
  handlePaginationInfo,
  handleSelectInfo,
  pageSizeTotal,
}: PaginationType): JSX.Element => {
  const paginationRange = usePagination({
    currentPage: pageIndex,
    totalCount,
    siblingCount: 1,
    pageSize: maxResultCount,
  });

  return (
    <>
      <Pagination>
        <Heading
          variant='body-s'
          title={`${data.length} of ${totalCount} items`}
          className='totalItems'
        />

        {(showPaginationList ?? false) && (
          <ul className='pagination'>
            {paginationRange.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return (
                  <li>
                    <Heading variant='body' title='&#8230;' />
                  </li>
                );
              }
              return (
                <li
                  className={`${Number(pageNumber) === pageIndex ? 'active' : ''}`}
                  onClick={() => {
                    handlePaginationInfo(Number(pageNumber));
                  }}>
                  <Heading variant='body' title={pageNumber} />
                </li>
              );
            })}
          </ul>
        )}

        {(showRow ?? false) && (
          <div className='rowItems'>
            <Heading variant='body-s' title='Per page' className='totalItems' />
            <div className='customSelect'>
              <select
                value={pageSizeTotal}
                onChange={(e) => {
                  handleSelectInfo(e.target.value);
                }}>
                {[10, 20, 50, 100, 200].map((pageSizeTotal) => (
                  <option key={pageSizeTotal} value={pageSizeTotal}>
                    {pageSizeTotal} rows
                  </option>
                ))}
              </select>
              <span className='material-symbols-rounded'>arrow_drop_down</span>
            </div>
          </div>
        )}
      </Pagination>
    </>
  );
};

export default PaginationComponent;
