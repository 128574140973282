import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  CompanyBankService,
  // eslint-disable-next-line camelcase
  type Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto,
} from '../../services/wealthlane-finance-integration-services';

const usePostBankAccount = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeVerificationModal: any
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        CompanyBankService.postApiFinanceIntegrationApiAppCompanyBankSavePublicTokenMetaData(
          payload
        )
      );
    },
    {
      onSuccess: async (
        response: // eslint-disable-next-line camelcase
        Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto | undefined
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries('getCompanyBankList');
          await queryClient.invalidateQueries('getCompanyBankToken');
          toast.success(<ToastComponent label='Success' message='Bank Account Added ' />);
          closeVerificationModal();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostBankAccount;
