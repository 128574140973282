import { type UseQueryResult, useQuery } from 'react-query';
import {
  PsuAwardService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetUpcomingPsuVestingList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { sorting, skipCount, maxResultCount, filter } = tableInfo;
  const upcomingPsuVestingList = useQuery(
    ['getUpcomingPsuVestingList', sorting, skipCount, maxResultCount, filter],
    async () => {
      return await handleRequest(
        PsuAwardService.getApiAwardApiAppPSUAwardUpcomingVestingGrants(
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return upcomingPsuVestingList;
};

export default useGetUpcomingPsuVestingList;
