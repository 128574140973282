import { Form, Formik } from 'formik';
import { preClearanceSelectType } from '../../../constants/common';
import { type ModalStatusType, type OptionType, type UserType } from '../../../types/common';
import ModalComponent from '../../../components/modal/modal';
import InitialValuesForm from '../../../constants/initialValues';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import FormSelect from '../../../components/dropdown/formSelect';
import { GroupOptions, IndividualtOptions } from './pre-clearance-options';
import { TradePreClearanceContainer } from './tradePreClearance.styled';
import { usePostTradePreClearance, useWealthlaneCustomerApi } from '../../../hooks';
import { TRADE_PRE_CLEARANCE_VALIDATION } from '../../../utils/validations/validations';
import _ from 'lodash';

interface TradePreClearanceType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  departmentOptions?: OptionType[];
  employeeTypeOptions?: OptionType[];
  jobTitleOptions?: UserType[];
  loading: boolean;
}

const TradePreClearanceModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  departmentOptions,
  employeeTypeOptions,
  jobTitleOptions,
  loading,
}: TradePreClearanceType): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const { mutate, isLoading } = usePostTradePreClearance(handleRequest, closeModal);
  const handleSubmit = (values: any): void => {
    const payload = { ...values, id: (values.id as boolean) ? values?.id : null };
    mutate(payload);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={InitialValuesForm.tradePreClearance}
      validationSchema={TRADE_PRE_CLEARANCE_VALIDATION}
      onSubmit={handleSubmit}>
      {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            loading={loading}
            size={'lg'}
            valueEdited={_.isEqual(values, InitialValuesForm.tradePreClearance)}
            title={`${modalStatus === 'Add' ? 'Create' : modalStatus} Trade Pre-Clearance`}
            children={
              <Form>
                <ModalBodyComponent>
                  <div className='flex flex-col justify-between'>
                    <div>
                      <TradePreClearanceContainer className='grid grid-cols-12'>
                        <FormSelect
                          containerClassName='col-span-6'
                          label='How would you like to add Participants? '
                          name='optionType'
                          onChange={(option: string) => {
                            handleChange(option);
                            if (option.includes('individual')) {
                              setFieldValue('isIndividual', true);
                              setFieldValue('isGroup', false);
                              setFieldValue('participantIds', []);
                              setFieldValue('tradePreClearanceGroups', [
                                {
                                  departmentId: null,
                                  employeeTypeId: null,
                                  jobTitleIds: [],
                                  groupData: false,
                                },
                              ]);
                            } else {
                              setFieldValue('isIndividual', false);
                              setFieldValue('isGroup', true);
                              setFieldValue('participantIds', []);
                              setFieldValue('tradePreClearanceGroups', [
                                {
                                  departmentId: null,
                                  employeeTypeId: null,
                                  jobTitleIds: [],
                                  groupData: false,
                                },
                              ]);
                            }
                            setTimeout(() => {
                              setFieldTouched('optionType', true);
                            });
                          }}
                          errors={errors}
                          touched={touched}
                          options={preClearanceSelectType}
                          values={values}
                          setFieldValue={setFieldValue}
                          required
                        />
                        {values.isIndividual ? (
                          <IndividualtOptions
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleRequest={handleRequest}
                          />
                        ) : (
                          values.isGroup && (
                            <GroupOptions
                              errors={errors}
                              touched={touched}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              departmentOptions={departmentOptions}
                              employeeTypeOptions={employeeTypeOptions}
                              jobTitleOptions={jobTitleOptions}
                              handleRequest={handleRequest}
                            />
                          )
                        )}
                      </TradePreClearanceContainer>
                    </div>
                  </div>
                </ModalBodyComponent>
                <ModalFooterComponent
                  modalStatus={modalStatus}
                  onCloseModal={() => {
                    closeModal();
                  }}
                  loading={isLoading}
                  isEditShow={false}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default TradePreClearanceModal;
