import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import { IntegrationProviderService } from '../../services/wealthlane-hr-integration-services';

const usePostAuthorizationCode = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: { authorizationCode?: string; userId?: string }) => {
      return await handleRequest(
        IntegrationProviderService.postApiHrintegrationApiAppIntegrationProviderAuthorizationcode(
          payload
        )
      );
    },
    {
      onSuccess: (response) => {
        if (response?.success as boolean) {
          void queryClient.invalidateQueries('postCheckFinchConnection');
          toast.success(<ToastComponent label='Success' message='Finch Connected' />);
        } else {
          toast.error(<ToastComponent label='Error' message={response?.message ?? ''} />);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostAuthorizationCode;
