import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import AlertPopup from '../../../components/alert-popup';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import Button from '../../../components/button';
import FormSelect from '../../../components/dropdown/formSelect';
import Filter from '../../../components/filter';
import Search from '../../../components/search/search';
import Table from '../../../components/table';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import { InvitationBlock, ResetFilterWrapper } from '../../../constants/style/common.styled';
import { tradePreClearanceHeader } from '../../../constants/table-headers/tradePreClearanceHeader';
import {
  useBoolean,
  useGetAllDepartments,
  useGetAllEmployeeType,
  useGetAllJobTitles,
  useGetTradePreClearanceList,
  useWealthlaneCustomerApi,
} from '../../../hooks';
import { TradePreClearanceService } from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { FilterBlock } from '../../participants/participant.styled';
import { SettingSystemUserHeader, SystemUserContainer } from '../system-users/systemUserStyled';
import TradePreClearanceModal from './tradePreClearanceModal';

const TradePreClearanceList = (): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const queryClient = useQueryClient();
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);

  const { data, status } = useGetTradePreClearanceList(handleRequest, tableInfo);
  const { data: departmentOptions } = useGetAllDepartments(handleRequest);
  const { data: employeeTypeOptions } = useGetAllEmployeeType(handleRequest);
  const { data: jobTitleOptions } = useGetAllJobTitles(handleRequest);

  const useDeleteTradePreClearance = (): any => {
    return useMutation(
      async () => {
        return await handleRequest(
          TradePreClearanceService.postApiCustomerApiAppTradePreClearanceRemovePreclearance(
            checkedValues
          )
        );
      },
      {
        onSuccess: (response: any) => {
          if (response != null) {
            closeConfirmModal();
            setCheckedValues([]);
            toast.success(
              <ToastComponent label={'Success'} message={'Trade pre clearance deleted.'} />
            );
            void queryClient.invalidateQueries('getTradePreClearanceList');
          }
        },
        onError: (err) => {
          console.log('error', err);
        },
      }
    );
  };
  const { mutate, isLoading } = useDeleteTradePreClearance();

  const actions = [
    {
      action: <div className='delete-label'>Remove from list</div>,
      onClick: async (id: string, rowData: any) => {
        setCheckedValues([rowData?.id]);
        setModalStatus('Delete');
        openConfirmModal();
      },
    },
  ];

  return (
    <SystemUserContainer>
      <div className='flex justify-between items-center'>
        <SettingSystemUserHeader>
          <Heading variant='h3' title='Trade Pre-Clearance' />
          <Heading
            variant='body-s'
            title='Secure your account & manage your password.'
            color={`${COLORS.Gray}`}
          />
        </SettingSystemUserHeader>
      </div>

      <FilterBlock>
        <div className='flex flex-col sm:flex-row justify-between gap-4 sm:gap-0'>
          <div className='grid grid-cols-3 sm:grid-cols-6 md:grid-cols-12 w-full gap-1.5'>
            <div className='col-span-6 sm:col-span-3 md:col-span-5'>
              <Search
                placeholder='Search'
                searchValue={searchQuery}
                onSearch={(value: string) => {
                  setSearchQuery(value);
                }}
                setTableInfo={setTableInfo}
                tableInfo={tableInfo}
              />
            </div>
            <div className='col-span-1 md:col-span-2 lg:col-span-1 flex lg:gap-1.5'>
              <Filter setOpen={setOpen} open={open} className='block'>
                <Heading variant='body-xs' title={'Filter By:'} className={'filter-label'} />
                <div className='grid sm:grid-cols-2 gap-x-5'>
                  <FormSelect
                    type='text'
                    name=''
                    placeholder='Department'
                    onChange={(value: string) => {
                      setTableInfo({ ...tableInfo, departmentId: value, skipCount: 0 });
                    }}
                    options={departmentOptions}
                    values={tableInfo.departmentId}
                    arrValue={tableInfo.departmentId}
                    isFormik={false}
                  />
                  <FormSelect
                    type='text'
                    name=''
                    placeholder='Employee Type'
                    onChange={(value: string) => {
                      setTableInfo({ ...tableInfo, employeeTypeId: value, skipCount: 0 });
                    }}
                    options={employeeTypeOptions}
                    values={tableInfo.employeeTypeId}
                    arrValue={tableInfo.employeeTypeId}
                    isFormik={false}
                  />
                  <FormSelect
                    type='text'
                    name=''
                    placeholder='Job Title'
                    onChange={(value: string) => {
                      setTableInfo({ ...tableInfo, jobTitleId: value, skipCount: 0 });
                    }}
                    options={jobTitleOptions}
                    values={tableInfo.jobTitleId}
                    arrValue={tableInfo.jobTitleId}
                    isFormik={false}
                  />
                </div>
              </Filter>
              <div className='col-span-5 lg:col-span-2 items-start gap-x-1'>
                <ResetFilterWrapper
                  onClick={() => {
                    setTableInfo({
                      ...InitialValuesForm.tableParams,
                      employeeStatusId: [],
                    });
                    setSearchQuery('');
                  }}>
                  <span className='material-symbols-outlined'>settings_backup_restore</span>
                  <Heading variant='body-s' title='Reset Filter' />
                </ResetFilterWrapper>
              </div>
            </div>
          </div>
          <div className='flex gap-x-4 justify-end'>
            <Button
              // as='Create'
              variant={'primary'}
              title={'Add Trade Pre-Clearance'}
              icon={'add_circle_outline'}
              onClick={() => {
                // setUsersInitialData(usersInitialValues);
                setModalStatus('Add');
                // setDataRow('');
                openModal();
              }}
            />
          </div>
        </div>
      </FilterBlock>
      {/* <SettingSystemUserButton className='xl:col-span-2 flex justify-start lg:justify-end'></SettingSystemUserButton> */}
      <InvitationBlock>
        {!isEmpty(checkedValues) && (
          <>
            <Button
              variant={'secondary-outline'}
              fontColor={COLORS.Purple}
              borderColor={COLORS.Purple}
              title={'Remove Selected'}
              onClick={() => {
                setModalStatus('Delete');
                openConfirmModal();
              }}
            />

            <Heading
              variant='body-xs'
              title={`${String(checkedValues.length ?? '')} Trade pre-clearance${checkedValues.length > 1 ? 's' : ''
                } selected`}
            />
          </>
        )}
      </InvitationBlock>
      <AnimationWrapper>
        {isModalOpen && (
          <TradePreClearanceModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            departmentOptions={departmentOptions}
            employeeTypeOptions={employeeTypeOptions}
            jobTitleOptions={jobTitleOptions}
            loading={false}
          />
        )}
      </AnimationWrapper>
      <Table
        columns={tradePreClearanceHeader(actions)}
        data={data?.items ?? []}
        loading={status}
        showCheckBox={true}
        showPagination
        totalCounts={data?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        onChecked={(values: string[]) => {
          setCheckedValues(values);
        }}
        checkedValues={checkedValues}
      />
      {isConfirmOpen && (
        <AlertPopup
          isShow={isConfirmOpen}
          handleSubmit={() => mutate(checkedValues)}
          closeModal={closeConfirmModal}
          modalStatus={modalStatus}
          title={`${checkedValues.length > 1 ? 'these' : 'this'} trade pre-clearance`}
          loading={isLoading}
        />
      )}
    </SystemUserContainer>
  );
};

export default TradePreClearanceList;
