import { rem } from '../../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';

export const BankRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${rem(12)};

  .radio-wrap {
    border: 1px solid ${COLORS.CulturedWhite};
    border-radius: 6px;
    padding: ${rem(14)} ${rem(16)};
    cursor: pointer;

    &.is-invalid {
      border: 1px solid ${COLORS.Red};
    }

    &.valid {
      border: 1px solid rgba(19, 168, 61, 0.25);
    }
  }
`;
