/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_FinanceIntegrationService_Dtos_AlpacaDto_CurrentMarketValue } from '../models/Wealthlane_FinanceIntegrationService_Dtos_AlpacaDto_CurrentMarketValue';
import type { Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto';
import type { Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StockService {

    /**
     * @param fromDate 
     * @param toDate 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppStockStocks(
fromDate?: string,
toDate?: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/stock/stocks',
            query: {
                'FromDate': fromDate,
                'ToDate': toDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param fromDate 
     * @param toDate 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppStockStocksForCustomer(
fromDate?: string,
toDate?: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/stock/stocks-for-customer',
            query: {
                'FromDate': fromDate,
                'ToDate': toDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppStockFiveYearStockData(): CancelablePromise<Array<Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/stock/five-year-stock-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppStockThreeYearStockData(): CancelablePromise<Array<Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/stock/three-year-stock-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppStockOneYearStockData(): CancelablePromise<Array<Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/stock/one-year-stock-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppStockOneMonthStockData(): CancelablePromise<Array<Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/stock/one-month-stock-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppStockYearToDateStockData(): CancelablePromise<Array<Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/stock/year-to-date-stock-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_AlpacaDto_CurrentMarketValue Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppStockCurrentMarketQuote(): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_AlpacaDto_CurrentMarketValue> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/stock/current-market-quote',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiFinanceIntegrationApiAppStockCheckCurrentUser(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/finance-integration/api/app/stock/check-current-user',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
