import { type UseQueryResult, useQuery } from 'react-query';
import {
  StandardReportsService,
  Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_StandardReport_StandardReportListDto_Wealthlane_ReportService_Application_Contracts,
} from '../../services/wealthlane-report-services';
import { TableParamsType } from '../../types/common';

const useGetStandardReportList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  selectedReportType?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_StandardReport_StandardReportListDto_Wealthlane_ReportService_Application_Contracts
  | undefined,
  unknown
> => {
  const {
    createdFromDate,
    createdToDate,
    areaTypeId,
    categoryTypeId,
    filter,
    sorting,
    skipCount,
    maxResultCount,
  } = tableInfo;
  const standardReportList = useQuery(
    [
      'getStandardReportList',
      createdFromDate,
      createdToDate,
      categoryTypeId,
      areaTypeId,
      filter,
      sorting,
      skipCount,
      maxResultCount,
      selectedReportType,
    ],
    async () => {
      if (selectedReportType === 'standardReport') {
        return await handleRequest(
          StandardReportsService.getApiReportApiAppStandardReportsStandardReportListForCompany(
            createdFromDate,
            createdToDate,
            categoryTypeId,
            areaTypeId,
            filter,
            sorting,
            skipCount,
            maxResultCount
          )
        );
      }
    }
  );

  return standardReportList;
};

export default useGetStandardReportList;
