/* eslint-disable no-unused-vars */
import { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Login from '../pages/auth/login';
import AuthService from '../services/authService';
import Callback from '../pages/auth/callback';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../pages/auth/authSlice';
import { RouteList } from './routesList';

const RoutesContainer = () => {
  const authService = new AuthService();

  const isAuthenticated = useSelector(selectIsAuthenticated) || authService.isAuthenticated();

  return (
    <Suspense fallback={() => <>LOADING</>}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/callback' element={<Callback />} />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          {RouteList.map((route, i) => (
            <Route path={route.path} element={<route.component />} key={i} exact={route.exact} />
          ))}
          <Route path='/' element={<Navigate to='/check-user' replace />} />
          <Route path='*' element={<Navigate to='/page-not-found' replace />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutesContainer;
