/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction } from '../models/Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction';
import type { Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantTransaction } from '../models/Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantTransaction';
import type { Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto } from '../models/Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto';
import type { Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationTestBlobDto } from '../models/Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationTestBlobDto';
import type { Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationUsingImportDto } from '../models/Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationUsingImportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxCalculationService {

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationParticipantTaxDetailForCompany(
requestBody?: Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/participant-tax-detail-for-company',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationParticipantTaxDetailForParticipant(
requestBody?: Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/participant-tax-detail-for-participant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationTaxCalculation(
requestBody?: Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantTransaction,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/tax-calculation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationCalculateTest(): CancelablePromise<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/calculate-test',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param ytdIncome 
     * @param transactionGainAmount 
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationCalculateNewTest(
ytdIncome?: number,
transactionGainAmount?: number,
): CancelablePromise<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/calculate-new-test',
            query: {
                'ytdIncome': ytdIncome,
                'transactionGainAmount': transactionGainAmount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationUsingImportDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationTaxCalculationUsingImport(
formData?: {
File?: Blob;
TaxItemId?: string;
},
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationUsingImportDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/tax-calculation-using-import',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationTestBlobDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationDownloadTaxCalculationTestSample(): CancelablePromise<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationTestBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/download-tax-calculation-test-sample',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationUploadTaxCalculationTestSample(
formData?: {
document?: Blob;
},
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/upload-tax-calculation-test-sample',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
