import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';
import { rem } from '../../../utils/style';

export const FormDivider = styled.div`
  margin: 2.4rem 0;
  border-top: 1px solid ${COLORS.CulturedWhite};
`;

export const ListWrapper = styled.div`
  padding: 12px 0;
  border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};
  h5 {
    margin-bottom: 0;
  }
`;

export const PlainTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: column;
  background-color: ${COLORS.White};
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};
  padding: ${rem(15)} ${rem(20)};
  gap: ${rem(12)};
  .spinner-container {
    width: ${rem(15)};
    height: ${rem(14)};
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: ${rem(12)};
  }
`;

export const FieldLevelPermissionsGroups = styled.div`
  margin-bottom: ${rem(10)};

  & > div:last-child {
    border-bottom: none;
  }
`;

export const CheckBoxFlex = styled.div`
  .CheckboxCenterInRoles {
    div {
      justify-content: center;
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 auto;
      position: relative;
    }
    input {
      position: static;
    }
  }
`;
