/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  TaxPeriodService,
  type Wealthlane_TaxService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-tax-services';

const usePostTaxPeriod = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  id?: string
): UseMutationResult<
  Wealthlane_TaxService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      if (id != null && id !== '') {
        return await handleRequest(TaxPeriodService.putApiTaxApiAppTaxPeriodTaxPeriod(payload));
      } else {
        return await handleRequest(TaxPeriodService.postApiTaxApiAppTaxPeriodTaxPeriod(payload));
      }
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          void queryClient.invalidateQueries('getTaxPeriodTableList');
          closeModal();
          toast.success(
            <ToastComponent
              label='Success'
              message={`Tax period ${id != null && id !== '' ? 'edited' : 'added'}`}
            />
          );
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostTaxPeriod;
