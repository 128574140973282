import { type UseQueryResult, useQuery } from 'react-query';
import {
  PlanService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Plan_PlanListDto,
} from '../../services/wealthlane-award-services';

const useGetPlanManagementList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  status?: number[]
  // eslint-disable-next-line camelcase
): UseQueryResult<Wealthlane_AwardService_Dtos_Plan_PlanListDto[] | undefined, unknown> => {
  const planData = useQuery(
    ['getPlanManagementList', status],
    async () => {
      return await handleRequest(PlanService.getApiAwardApiAppPlanPlanList());
    },
    {
      keepPreviousData: true,
    }
  );

  return planData;
};

export default useGetPlanManagementList;
