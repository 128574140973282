/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';
import type { Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskDetailStatusByIdResponseDto } from '../models/Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskDetailStatusByIdResponseDto';
import type { Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskExecutedFiles } from '../models/Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskExecutedFiles';
import type { Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleByIdResultDto } from '../models/Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleByIdResultDto';
import type { Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleListResultDto } from '../models/Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleListResultDto';
// import type { Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleListResultDto_Wealthlane_TaskManagerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_ } from '../models/Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleListResultDto_Wealthlane_TaskManagerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaskManagerConsoleService {
  /**
   * @param fromCreatedDate
   * @param toCreatedDate
   * @param taskTypeId
   * @param statusId
   * @param createdById
   * @param filter
   * @param sorting
   * @param skipCount
   * @param maxResultCount
   * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleListResultDto_Wealthlane_TaskManagerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_> Success
   * @throws ApiError
   */
  public static getApiTaskmanagerApiAppTaskManagerConsoleTaskManagerConsoleList(
    fromCreatedDate?: string,
    toCreatedDate?: string,
    taskTypeId?: Array<string>,
    statusId?: Array<string>,
    createdById?: Array<string>,
    filter?: string,
    sorting?: string,
    skipCount?: number,
    maxResultCount?: number
  ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/taskmanager/api/app/task-manager-console/task-manager-console-list',
      query: {
        FromCreatedDate: fromCreatedDate,
        ToCreatedDate: toCreatedDate,
        TaskTypeId: taskTypeId,
        StatusId: statusId,
        CreatedById: createdById,
        Filter: filter,
        Sorting: sorting,
        SkipCount: skipCount,
        MaxResultCount: maxResultCount,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleByIdResultDto Success
   * @throws ApiError
   */
  public static getApiTaskmanagerApiAppTaskManagerConsoleTaskManagerConsoleById(
    id: string
  ): CancelablePromise<Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleByIdResultDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/taskmanager/api/app/task-manager-console/{id}/task-manager-console-by-id',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskDetailStatusByIdResponseDto Success
   * @throws ApiError
   */
  public static getApiTaskmanagerApiAppTaskManagerConsoleTaskDetailStatusById(
    id: string
  ): CancelablePromise<Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskDetailStatusByIdResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/taskmanager/api/app/task-manager-console/{id}/task-detail-status-by-id',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleListResultDto Success
   * @throws ApiError
   */
  public static getApiTaskmanagerApiAppTaskManagerConsoleExecutedReportList(): CancelablePromise<
    Array<Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleListResultDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/taskmanager/api/app/task-manager-console/executed-report-list',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param taskId
   * @returns Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskExecutedFiles Success
   * @throws ApiError
   */
  public static getApiTaskmanagerApiAppTaskManagerConsoleTaskFileStatusByTaskId(
    taskId: string
  ): CancelablePromise<
    Array<Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskExecutedFiles>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/taskmanager/api/app/task-manager-console/task-file-status-by-task-id/{TaskId}',
      path: {
        TaskId: taskId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }
}
