/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_CustomerService_Dtos_ResponseDto_1OfOfSystem_String_System_Private_CoreLib_Version_7_0_0_0_Culture_neutral_PublicKeyToken_7cec85d7bea7798e } from '../models/Wealthlane_CustomerService_Dtos_ResponseDto_1OfOfSystem_String_System_Private_CoreLib_Version_7_0_0_0_Culture_neutral_PublicKeyToken_7cec85d7bea7798e';
import type { YtdImportSampleDownloadResponse } from '../models/YtdImportSampleDownloadResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class YtdService {

    /**
     * @param formData 
     * @returns Wealthlane_CustomerService_Dtos_ResponseDto_1OfOfSystem_String_System_Private_CoreLib_Version_7_0_0_0_Culture_neutral_PublicKeyToken_7cec85d7bea7798e Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppYtdImportYtd(
formData?: {
ImportFile?: Blob;
},
): CancelablePromise<Wealthlane_CustomerService_Dtos_ResponseDto_1OfOfSystem_String_System_Private_CoreLib_Version_7_0_0_0_Culture_neutral_PublicKeyToken_7cec85d7bea7798e> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/ytd/import-ytd',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns YtdImportSampleDownloadResponse Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppYtdDownloadYtdImportSample(): CancelablePromise<YtdImportSampleDownloadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/ytd/download-ytd-import-sample',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
