/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useMousePositionAsTrigger } from 'react-laag';
import Button from '../../../components/button';
import FormSelect from '../../../components/dropdown/formSelect';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import PopoverModalComponent from '../../../components/modal/popoverModal';
import Search from '../../../components/search/search';
import Table from '../../../components/table';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import {
  ImportBlock,
  PageHeader,
  ResetFilterWrapper,
} from '../../../constants/style/common.styled';
import { VestingTemplatesHeader } from '../../../constants/table-headers/vestingTemplatesHeader';
import {
  VestingTemplateService,
  type Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto,
} from '../../../services/wealthlane-award-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { SettingRoleButton } from '../system-roles/systemRoleStyled';
import VestingTemplatePopoverContent from './vestingTemplatePopoverContent';
import {
  useAdvanceBoolean,
  useBoolean,
  useGetVestingTemplateList,
  useGetVestingTypeOptions,
  usePostFileImport,
  useWealthlaneAwardApi,
} from '../../../hooks';
import ToastComponent from '../../../components/toast';
import AlertPopup from '../../../components/alert-popup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import VestingModal from './vestingModal';
import { useNavigate } from 'react-router-dom';
import NewVestingModal from './newVestingModal';
import { PlainTextWrapper } from '../system-roles/roles.styled';
import { InputField } from '../../participants/participant.styled';
import { isEmpty } from 'lodash';
import ParticipantImportModal from '../../participants/participantImportModal';
import { handleOpenFile } from '../../../utils/global';
import Spinners from '../../../components/spinner';

const initialValues = {
  data: InitialValuesForm.vestingTemplate,
  id: '',
};

const VestingTemplates = (): JSX.Element => {
  const [vestingType, setVestingType] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [popoverData, setPopoverData] =
    useState<Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto>({});
  const [modalLoading, setModalLoading] = useState(false);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [initialData, setInitialData] = useState(initialValues);
  const [importLoading, setImportLoading] = useState(false);
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
    customValue,
    setCustomValue,
  } = useAdvanceBoolean<string>(false);
  const { handleRequest, error: exception } = useWealthlaneAwardApi();
  const { data: vestingTypeOptions } = useGetVestingTypeOptions(handleRequest);
  const { data, status } = useGetVestingTemplateList(
    handleRequest,
    tableInfo,
    vestingType != '' ? [vestingType] : []
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const actions = [
    {
      action: 'View',
      onClick: (id: string) => {
        setModalStatus('View');
        getVestingTemplateViewData(id);
        openModal();
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string) => {
        setModalStatus('Edit');
        getVestingTemplateByIdForUpdateData(id);
      },
    },
    {
      action: 'Preview',
      // as: 'Edit',
      onClick: (id: string, rowData: any) => {
        navigate('/settings/organization-settings/vesting-templates/preview', {
          state: {
            id: id,
            data: rowData,
          },
        });
      },
    },
    {
      // action: <div className='delete-label'>Delete</div>,
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      onClick: (id: string) => {
        setModalStatus('Delete');
        openConfirmModal();
        setCustomValue(id);

        // deleteVestingTemplateById(id);
      },
    },
  ];

  const getVestingTemplateViewData = (id: string): void => {
    setModalLoading(true);
    handleRequest(VestingTemplateService.getApiAwardApiAppVestingTemplateVestingTemplateForView(id))
      .then((data: any) => {
        if (data != null) {
          setInitialData({ id, data });
          setModalLoading(false);
        }
      })
      .catch(() => {})
      .finally(() => {
        setModalLoading(false);
      });
  };

  const getVestingTemplateByIdForUpdateData = (id: string): void => {
    setModalLoading(true);
    handleRequest(VestingTemplateService.getApiAwardApiAppVestingTemplateVestingTemplate(id))
      .then((data: any) => {
        if (data != null) {
          setInitialData({ id, data });
          openModal();
          setModalLoading(false);
        }
      })
      .catch(() => {})
      .finally(() => {
        setModalLoading(false);
      });
  };
  const onPrimaryClick = (values: Record<string, any>): void => {
    deleteVestingTemplateById(customValue);
  };

  const deleteVestingTemplateById = (id: string): void => {
    setModalLoading(true);
    handleRequest(VestingTemplateService.deleteApiAwardApiAppVestingTemplateVestingTemplate(id))
      .then((data: any) => {
        if (data != null) {
          toast.success(<ToastComponent label={'Success'} message={'Vesting template deleted.'} />);
          void queryClient.invalidateQueries('getVestingTemplateList');
          setModalLoading(false);
          closeConfirmModal();
        }
      })
      .catch(() => {
        setModalLoading(false);
        closeConfirmModal();
      })
      .finally(() => {
        setModalLoading(false);
        closeConfirmModal();
      });
  };

  const {
    hasMousePosition, // did we get a mouse-position from the event-handler
    resetMousePosition, // reset the mouse-position to `null`, essentially closing the menu
    handleMouseEvent, // event-handler we will use below
    trigger, // information regarding positioning we can provide to `useLayer`
  } = useMousePositionAsTrigger();

  useEffect(() => {
    if (exception != null) {
      closeModal();
    }
  }, [exception]);

  const handleShow = (data: any, e: any): void => {
    setPopoverData(data);
    handleMouseEvent(e);
  };
  // const [importedData, setImportedData] = useState<any>();
  // const [eventBusId, setEventBusId] = useState<string>('');
  // const [formStatus, setFormStatus] = useState<ModalStatusType>('Create');
  // const [importCounter, setImportCounter] = useState(1);
  // const [importStatus, setImportStatus] = useState<any | null>();
  // const [importLoader, setImportLoader] = useState<boolean>(false);
  // const [parsedValue, setParsedValue] = useState<any>();
  // const [uploadTitle, setUploadTitle] = useState<string>('');
  // const [counter, setCounter] = useState(1);
  // const [verifiedLoader, setVerifiedLoader] = useState<boolean>(false);

  // const {
  //   value: isBulkModalOpen,
  //   setFalse: closeBulkModal,
  //   setTrue: openBulkModal,
  // } = useBoolean(false);

  // const { mutate, isLoading: ImportLoader } = usePostFileImport(
  //   handleRequest,
  //   setEventBusId,
  //   'VestingTemplate',
  //   setImportStatus,
  //   setImportCounter
  // );

  // function fileImport(event: any): void {
  //   setImportedData('');
  //   setFormStatus('Create');
  //   setImportStatus(null);
  //   if (!isEmpty(event.target.files)) {
  //     const payload = {
  //       File: event.target.files[0],
  //     };

  //     setImportLoader(true);

  //     mutate(payload);
  //     event.target.value = null;
  //     openBulkModal();
  //   }
  //   setEventBusId('');
  //   setParsedValue({});
  //   setUploadTitle('');
  // }

  // const onVerified = (): void => {
  //   setVerifiedLoader(true);
  //   const handleAPI = VestingTemplateService.postApiAwardApiAppVestingTemplateImportExcel(
  //     importedData?.id
  //   );
  //   (importedData as boolean) &&
  //     handleRequest(handleAPI)
  //       .then((data: any) => {
  //         if (data !== null) {
  //           setImportCounter(1);
  //           setUploadTitle('Adding all vesting template records');
  //           data?.id && setEventBusId(data.id);
  //           setImportStatus(data);
  //         }
  //       })
  //       .catch((error: any) => {
  //         console.log('error', error);
  //       });
  // };
  const importExcel = (event: any): void => {
    if (!isEmpty(event.target.files)) {
      setImportLoading(true);

      const payload = {
        File: event.target.files[0],
      };

      // const handleAPI =
      //   VestingTemplateService.postApiAwardApiAppVestingTemplateImportBulkVestingTemplateExcel(
      //     payload
      //   );

      handleRequest(
        VestingTemplateService.postApiAwardApiAppVestingTemplateImportBulkVestingTemplateExcel(
          payload
        )
      )
        .then((data: any) => {
          if (data) {
            toast.success(<ToastComponent label='Success' message='Vesting Templated imported.' />);
            queryClient.invalidateQueries('getVestingTemplateList');
            setImportLoading(false);
          }
        })
        .catch((error: any) => {
          console.log('error', error);
          setImportLoading(false);
        })
        .finally(() => {
          setImportLoading(false);
        });

      event.target.value = null;
    }
  };

  const downloadTemplate = (name: string): void => {
    handleRequest(
      VestingTemplateService.postApiAwardApiAppVestingTemplateDownloadVestingTemplateSample()
    )
      .then((data: any) => {
        if (data != null) {
          handleOpenFile(`${name}.xlsx`, data.content);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  return (
    <>
      <PageHeader>
        <Heading variant='h3' title='Vesting Templates' />
        <Heading variant='body-s' title='Current list of default and custom vesting templates.' />
      </PageHeader>
      <div className='grid lg:grid-cols-12 gap-4 lg:gap-1.5'>
        <div className='lg:col-span-3'>
          <Search
            placeholder='Search'
            searchValue={searchQuery}
            onSearch={(value: string) => {
              setSearchQuery(value);
            }}
            setTableInfo={setTableInfo}
            tableInfo={tableInfo}
          />
        </div>
        <div className='lg:col-span-2'>
          <FormSelect
            type='text'
            name='vestingType'
            placeholder='Vesting Type'
            onChange={(value: string) => {
              setVestingType(value);
            }}
            options={vestingTypeOptions}
            values={vestingType}
            arrValue={vestingType || null}
            containerClassName='formGroup mb-0'
            isFormik={false}
          />
        </div>
        <div className='lg:col-span-2'>
          <ResetFilterWrapper
            onClick={() => {
              setVestingType('');
              setTableInfo(InitialValuesForm.tableParams);
              setSearchQuery('');
            }}>
            <span className='material-symbols-outlined'>settings_backup_restore</span>
            <Heading variant='body-s' title='Reset Filter' />
          </ResetFilterWrapper>
        </div>
        <SettingRoleButton className='lg:col-span-5 flex justify-start sm:justify-end'>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add Template'}
            onClick={() => {
              setInitialData(initialValues);
              setModalStatus('Add');
              openModal();
            }}
            icon='add_circle'
          />
        </SettingRoleButton>
      </div>
      <ImportBlock>
        <PlainTextWrapper>
          <Heading
            variant={'body-s'}
            title={'Quick & easy to use CSV template to import large data in an instant.'}
          />
          <InputField>
            <Button
              variant={'secondary-white'}
              title={'Download Template'}
              icon={'cloud_upload'}
              onClick={() => {
                downloadTemplate('Vesting Template');
              }}
            />
            <div className='input-file-container'>
              <input
                className='input-file'
                id='my-file'
                type='file'
                accept='.xlsx, .xls'
                onChange={(e: any) => {
                  importExcel(e);
                  // fileImport(e);
                }}
              />
              {!importLoading ? (
                <label
                  className='dt-btn dt-btn-outline-primary dt-btn-sm input-file-trigger text-center sm:text-left'
                  htmlFor='my-file'>
                  Import Bulk XLS
                </label>
              ) : (
                <label
                  className='dt-btn dt-btn-outline-primary dt-btn-sm input-file-trigger text-center sm:text-left flex gap-2 items-center'
                  htmlFor='my-file'>
                  <Spinners className='spinner-container ' /> Import Bulk XLS
                </label>
              )}
            </div>
            {/* <div className='input-file-container'>
              <input
                className='input-file'
                id='update-file'
                type='file'
                accept='.xlsx, .xls'
                onChange={(e: any) => {
                  fileUpdate(e);
                }}
              />
              <label
                className='dt-btn dt-btn-outline-primary dt-btn-sm input-file-trigger text-center sm:text-left'
                htmlFor='update-file'>
                Update Existing XLS
              </label>
            </div> */}
          </InputField>
        </PlainTextWrapper>
      </ImportBlock>
      <Table
        columns={VestingTemplatesHeader(actions, handleShow, hasMousePosition, resetMousePosition)}
        data={data?.items ?? []}
        loading={status}
        showPagination
        totalCounts={data?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />

      <PopoverModalComponent
        children={<VestingTemplatePopoverContent data={popoverData} />}
        hasMousePosition={hasMousePosition}
        trigger={trigger}
        popoverClassName='pop-overs'
        resetMousePosition={resetMousePosition}
      />
      {isConfirmOpen && (
        <AlertPopup
          isShow={isConfirmOpen}
          closeModal={closeConfirmModal}
          modalStatus={modalStatus}
          title={'this Vesting Template'}
          handleSubmit={onPrimaryClick}
          // setRemark={setRemark}
          // remark={remark}
          loading={modalLoading}
        />
      )}

      <AnimationWrapper>
        {isModalOpen && (
          <NewVestingModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            modalStatus={modalStatus}
            modalLoading={modalLoading}
            initialData={initialData}
            vestingTypeOptions={vestingTypeOptions}
            setModalStatus={setModalStatus}
          />
        )}
      </AnimationWrapper>
      {/* <AnimationWrapper>
        {isBulkModalOpen && (
          <ParticipantImportModal
            isModalOpen={isBulkModalOpen}
            closeModal={() => {
              closeBulkModal();
              setEventBusId('');
              setImportCounter(0);
              setCounter(0);
              setEventBusId('');
              setParsedValue({});
              setUploadTitle('');
              setImportLoader(false);
              setVerifiedLoader(false);
              // (importedData?.status === 'Started' || importedData?.status === 'In Progress') &&
              //   stopImport();
            }}
            isLoading={importLoader}
            addLoading={verifiedLoader}
            data={importedData}
            parsedData={parsedValue}
            uploadTitle={uploadTitle}
            error={exception}
            fileImport={fileImport}
            onVerified={onVerified}
            importStatus={importStatus}
          />
        )}
      </AnimationWrapper> */}
    </>
  );
};

export default VestingTemplates;
