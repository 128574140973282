import { type UseQueryResult, useQuery } from 'react-query';
import {
  ReleaseService,
  TransferAllocateService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto,
} from '../../services/wealthlane-award-services';

const useGetTransferAndAllocateTaxDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  setInitialValue?: any
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto | undefined,
  unknown
> => {
  const transferAndAllocateTaxDetails = useQuery(
    ['getTransferAndAllocateTaxDetails', id],
    async () => {
      if (id) {
        return await handleRequest(
          TransferAllocateService.getApiAwardApiAppTransferAllocateTaxDetailsByTransferAllocateItemId(
            id
          )
        );
      }
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          setInitialValue(response);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );

  return transferAndAllocateTaxDetails;
};

export default useGetTransferAndAllocateTaxDetails;
