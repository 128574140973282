import { type UseQueryResult, useQuery } from 'react-query';
import {
  RetirementEligibilityConfigurationService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityConfigurationDto,
} from '../../services/wealthlane-award-settlement-services';

const useGetRetirementEligibilityData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityConfigurationDto
  | undefined,
  unknown
> => {
  const retirementEligibilityData = useQuery(['getRetirementEligibilityData'], async () => {
    return await handleRequest(
      RetirementEligibilityConfigurationService.getApiAwardSettlementApiAppRetirementEligibilityConfigurationRetirementEligibilityConfiguration()
    );
  });

  return retirementEligibilityData;
};

export default useGetRetirementEligibilityData;
