/* eslint-disable camelcase */
import { Form, Formik } from 'formik';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import CustomDatePicker from '../../../components/date-picker/customDatePicker';
import FormSelect from '../../../components/dropdown/formSelect';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import CustomTextArea from '../../../components/text-area/textArea';
import { DATE_FORMAT } from '../../../constants/common';
import { useTradingBlackoutEligibilityOptions, useWealthlaneCustomerApi } from '../../../hooks';
import usePostTradingBlackoutConfig from '../../../hooks/post/usePostTradingBlackoutConfig';
import {
  type Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackOutGroupDto,
  type Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutTitleDto,
} from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type OptionType, type UserType } from '../../../types/common';
import { TRADING_BLACKOUT_CONFIG_VALIDATION } from '../../../utils/validations/validations';
import { TradePreClearanceContainer } from '../trade-pre-clearance/tradePreClearance.styled';
import { GroupOptions } from './trading-blackout-options';

interface TradingBlackoutConfigurationType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  initialBlackoutConfig: any;
  departmentOptions?: OptionType[];
  employeeTypeOptions?: OptionType[];
  jobTitleOptions?: UserType[];
  loading: boolean;
}

const TradingBlackoutConfigurationModel = ({
  isModalOpen,
  closeModal,
  modalStatus,
  setModalStatus,
  initialBlackoutConfig,
  loading,
  departmentOptions,
  employeeTypeOptions,
  jobTitleOptions,
}: TradingBlackoutConfigurationType): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data: blackoutEligibilityOptions } = useTradingBlackoutEligibilityOptions(handleRequest);

  const { mutate, isLoading } = usePostTradingBlackoutConfig(handleRequest, closeModal);
  const handleSubmit = (values: any): void => {
    const payload = {
      ...values,
      id: Boolean(values.id) && modalStatus === 'Edit' ? values?.id : null,
      blackoutGroups: !isEmpty(values?.blackoutGroups)
        ? values?.blackoutGroups?.map(
            (item: Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackOutGroupDto) => ({
              ...item,
              blackOutJobTitles: item.blackOutJobTitles?.map(
                (
                  jobTitleId: Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutTitleDto
                ) => ({
                  customerTitleId: jobTitleId,
                })
              ),
            })
          )
        : null,
    };
    mutate(payload);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialBlackoutConfig}
      validationSchema={TRADING_BLACKOUT_CONFIG_VALIDATION}
      onSubmit={handleSubmit}>
      {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            loading={loading}
            size={'lg'}
            valueEdited={_.isEqual(values, initialBlackoutConfig)}
            title={`${
              modalStatus === 'Add' ? 'Create' : modalStatus
            } Trading Blackout Configuration`}
            children={
              <Form>
                <ModalBodyComponent>
                  <div className='flex flex-col justify-between'>
                    <div>
                      <TradePreClearanceContainer className='grid grid-cols-12 gap-x-5'>
                        <InputText
                          className='col-span-12'
                          type='text'
                          label='Blackout Title'
                          name='blackoutTitle'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled={modalStatus === 'View'}
                          required
                        />
                        {/* <InputText
                          className='col-span-12'
                          type='text'
                          label='Message'
                          name='message'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled={modalStatus === 'View'}
                          required
                        /> */}
                        <CustomTextArea
                          containerClassName='col-span-12'
                          label='Message'
                          name='message'
                          onChange={handleChange}
                          errors={errors}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          touched={touched}
                          required
                        />
                        <div className='col-span-4 '>
                          <CustomDatePicker
                            label='Start Date'
                            name='startDate'
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            values={values}
                            min={moment().add(1, 'day').toDate()}
                            onChange={() => {
                              setFieldValue('endDate', null);
                            }}
                            disabled={
                              modalStatus === 'View'
                                ? true
                                : !!(
                                    modalStatus === 'Edit' &&
                                    moment(
                                      moment(initialBlackoutConfig?.startDate).format(DATE_FORMAT)
                                    ).isSameOrBefore(moment().format(DATE_FORMAT), 'day')
                                  )
                            }
                            required
                          />
                        </div>
                        <div className='col-span-4'>
                          <CustomDatePicker
                            label='End Date'
                            name='endDate'
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            values={values}
                            min={
                              modalStatus === 'Edit' &&
                              moment(moment(values?.startDate).format(DATE_FORMAT)).isSameOrBefore(
                                moment().format(DATE_FORMAT),
                                'day'
                              )
                                ? moment().add(1, 'day').toDate()
                                : moment(values?.startDate).toDate()
                            }
                            disabled={values?.startDate === null || modalStatus === 'View'}
                            required
                          />
                        </div>

                        <FormSelect
                          containerClassName='col-span-6'
                          label='How would you to like to add Participants? '
                          name='blackoutEligibilityId'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={blackoutEligibilityOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                          disabled={modalStatus === 'View'}
                          required
                        />
                        {(blackoutEligibilityOptions
                          ?.find((item) => item.value === values?.blackoutEligibilityId)
                          ?.label.includes('Groups') ??
                          false) && (
                          <GroupOptions
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                            modalStatus={modalStatus}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            departmentOptions={departmentOptions}
                            employeeTypeOptions={employeeTypeOptions}
                            jobTitleOptions={jobTitleOptions}
                            handleRequest={handleRequest}
                          />
                        )}
                      </TradePreClearanceContainer>
                    </div>
                  </div>
                </ModalBodyComponent>
                <ModalFooterComponent
                  modalStatus={modalStatus}
                  onCloseModal={() => {
                    closeModal();
                  }}
                  loading={isLoading}
                  isEditShow={
                    !moment(
                      moment(initialBlackoutConfig?.endDate).format(DATE_FORMAT)
                    ).isSameOrBefore(moment().format(DATE_FORMAT), 'day') && true
                  }
                  valueChanged={_.isEqual(values, initialBlackoutConfig)}
                  onEditModal={() => {
                    setModalStatus('Edit');
                  }}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default TradingBlackoutConfigurationModel;
