/* eslint-disable camelcase */
import { type UseQueryResult, useQuery } from 'react-query';
import {
  TaxItemApprovalService,
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-tax-services';
import { type TableParamsType } from '../../types/common';

const useGetTaxSetupApproverList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { taxPeriodId, taxApprovalStatusId, filter, sorting, skipCount, maxResultCount } =
    tableInfo;
  const taxSetupApproverList = useQuery(['getTaxSetupApproverList', tableInfo], async () => {
    return await handleRequest(
      TaxItemApprovalService.getApiTaxApiAppTaxItemApprovalTaxItemList(
        taxPeriodId,
        taxApprovalStatusId,
        filter,
        sorting,
        skipCount,
        maxResultCount
      )
    );
  });

  return taxSetupApproverList;
};

export default useGetTaxSetupApproverList;
