import { COLORS } from '../../constants/style/color';
import { getShadow, rem } from '../../utils/style';
import styled, { css } from 'styled-components';
import { Display } from '../../constants/style/common.styled';

interface TagType {
  variant:
    | 'default'
    | 'default-outline'
    | 'default-light'
    | 'default-outline-light'
    | 'primary'
    | 'primary-outline'
    | 'notice'
    | 'success'
    | 'success-outline'
    | 'error'
    | 'error-dark'
    | 'error-outline'
    | 'info'
    | 'info-outline'
    | 'rounded-primary'
    | 'rounded-default'
    | 'pink-tag';
}

interface LozengeType {
  variant: 'default' | 'primary' | 'success' | 'error' | 'info';
  color?: string;
}

export const TagWrapper = styled(Display)<TagType>`
  padding: ${rem(3)} ${rem(6)};
  width: fit-content;
  border-radius: ${rem(4)};
  border-width: ${rem(1)};
  border-style: solid;
  justify-content: space-between;

  span {
    font-weight: 500;
    color: ${(props) => (props.color != null ? props.color : `${COLORS.Black}`)};
    line-height: 12px;
  }

  ${(props) => {
    if (props.variant === 'rounded-default') {
      return css`
        background: ${COLORS.OffWhite};
        border-radius: ${rem(33)};
        padding: ${rem(7)} ${rem(14)};
        @include ${getShadow({ variant: 'tag-white' })};
        span {
        }
      `;
    }
    if (props.variant === 'rounded-primary') {
      return css`
        background: ${COLORS.Purple};
        border-color: ${COLORS.Purple};
        border-radius: ${rem(33)};
        padding: ${rem(7)} ${rem(14)};
        span {
          color: ${COLORS.White};
        }
      `;
    }

    if (props.variant === 'default') {
      return css`
        background: ${COLORS.OffWhite};
        /* border-color: ${COLORS.LightGray}; */
        @include ${getShadow({ variant: 'tag-white' })};
        span {
        }
      `;
    }

    if (props.variant === 'default-outline') {
      return css`
        background: ${COLORS.White};
        border-color: ${COLORS.LightGray};
        span {
          color: ${COLORS.Silver};
        }
      `;
    }
    if (props.variant === 'default-light') {
      return css`
        background: ${COLORS.OffWhite};
        border-color: ${COLORS.OffWhite};
        @include ${getShadow({ variant: 'tag-white' })};
        span {
          color: ${COLORS.Gray};
        }
      `;
    }

    if (props.variant === 'default-outline-light') {
      return css`
        background: ${COLORS.OffWhite};
        border-color: ${COLORS.LightGray};
        span {
          color: ${COLORS.SpanishGray};
        }
      `;
    }

    if (props.variant === 'primary') {
      return css`
        background: ${COLORS.LightPurple};
        border-color: ${COLORS.LightPurple};

        span {
          color: ${COLORS.Purple};
        }
      `;
    }

    if (props.variant === 'primary-outline') {
      return css`
        background: ${COLORS.White};
        border-color: ${COLORS.Purple};

        span {
          color: ${COLORS.Purple};
        }
      `;
    }

    if (props.variant === 'notice') {
      return css`
        border-color: ${COLORS.White};
        border: ${rem(1)} solid ${COLORS.White};

        span {
          color: ${COLORS.White};
        }
      `;
    }

    if (props.variant === 'success') {
      return css`
        border-color: ${COLORS.LightGreen};
        background: ${COLORS.LightGreen};

        span {
          color: ${COLORS.Green};
        }
      `;
    }

    if (props.variant === 'success-outline') {
      return css`
        border-color: ${COLORS.Green};
        border: ${rem(1)} solid ${COLORS.Green};

        span {
          color: ${COLORS.Green};
        }
      `;
    }
    if (props.variant === 'pink-tag') {
      return css`
        background: ${COLORS.LightPink};
        border-color: ${COLORS.LightPink};
        border: ${rem(1)} solid ${COLORS.LightPink};

        span {
          color: ${COLORS.Pink};
        }
      `;
    }

    if (props.variant === 'error') {
      return css`
        border-color: ${COLORS.LightRed};
        background: ${COLORS.LightRed};

        span {
          color: ${COLORS.Red};
        }
      `;
    }

    if (props.variant === 'error-outline') {
      return css`
        border-color: ${COLORS.Red};
        border: ${rem(1)} solid ${COLORS.Red};

        span {
          color: ${COLORS.Red};
        }
      `;
    }
    if (props.variant === 'error-dark') {
      return css`
        border-color: ${COLORS.CulturedWhite};
        border: ${rem(1)} solid ${COLORS.CulturedWhite};
        filter: drop-shadow(0px 1px 2px {COLORS.TagShadow});

        span {
          color: ${COLORS.Red};
        }
      `;
    }

    if (props.variant === 'info') {
      return css`
        border-color: ${COLORS.Magnolia};
        background: ${COLORS.Magnolia};

        span {
          color: ${COLORS.Iris};
        }
      `;
    }

    if (props.variant === 'info-outline') {
      return css`
        border-color: ${COLORS.Iris};
        border: ${rem(1)} solid ${COLORS.Iris};

        span {
          color: ${COLORS.Iris};
        }
      `;
    }
  }};

  .dot {
    position: relative;
    width: ${rem(4)};
    height: ${rem(4)};
    border-radius: ${rem(4)};
    background: inherit;
    margin-right: ${rem(4)};

    ${(props) => {
      if (props.variant === 'default') {
        return css`
          background: ${COLORS.Gray};
        `;
      }

      if (props.variant === 'default-outline') {
        return css`
          background: ${COLORS.SpanishGray};
        `;
      }

      if (props.variant === 'primary') {
        return css`
          background: ${COLORS.Purple};
        `;
      }

      if (props.variant === 'primary-outline') {
        return css`
          background: ${COLORS.Purple};
        `;
      }

      if (props.variant === 'success') {
        return css`
          background: ${COLORS.Green};
        `;
      }

      if (props.variant === 'success-outline') {
        return css`
          background: ${COLORS.Green};
        `;
      }

      if (props.variant === 'error') {
        return css`
          background: ${COLORS.Red};
        `;
      }

      if (props.variant === 'error-outline') {
        return css`
          background: ${COLORS.Red};
        `;
      }

      if (props.variant === 'info') {
        return css`
          background: ${COLORS.Iris};
        `;
      }

      if (props.variant === 'info-outline') {
        return css`
          background: ${COLORS.Iris};
        `;
      }
    }};
  }
`;

export const LozengeWrapper = styled(Display)<LozengeType>`
  padding: ${rem(4)} ${rem(6)};
  border-radius: ${rem(4)};
  justify-content: space-between;

  span.material-symbols-outlined {
    font-size: ${rem(12)};
    margin-left: ${rem(12)};
    color: ${COLORS.SpanishGray};
    cursor: pointer;

    &.active {
      opacity: 0.5;
    }
  }

  ${(props) => {
    if (props.variant === 'default') {
      return css`
        background: ${COLORS.CulturedWhite};
      `;
    }

    if (props.variant === 'primary') {
      return css`
        background: ${COLORS.LightPurple};
      `;
    }

    if (props.variant === 'success') {
      return css`
        background: ${COLORS.LightGreen};
      `;
    }

    if (props.variant === 'error') {
      return css`
        background: ${COLORS.LightRed};
      `;
    }

    if (props.variant === 'info') {
      return css`
        background: ${COLORS.Magnolia};
      `;
    }
  }};
`;
