import { type ReactNode } from 'react';

interface DefaultTreeViewProps {
  children: ReactNode;
}

export function ListLineItemWrapper({ children }: DefaultTreeViewProps): JSX.Element {
  return (
    <div className='relative space-y-3 p-5'>
      {children}
      <div className='absolute left-0 top-0 h-[calc(100%-4.8rem)] w-[1.5px] bg-gray-300'></div>
    </div>
  );
}

export function ListLineItem({ children }: DefaultTreeViewProps): JSX.Element {
  return (
    <div className='relative rounded-md  text-white'>
      {children}
      <span className='absolute -left-[1.24rem] top-12 h-[1.5px] w-5 bg-gray-300'></span>
    </div>
  );
}
