import { type UseQueryResult, useQuery } from 'react-query';
import { ReportTablePreferenceService } from '../../services/wealthlane-report-services';

const useGetReportTablePreferences = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  reportName: string
): UseQueryResult<string[] | undefined, unknown> => {
  const reportTablePreferences = useQuery(['getReportTablePreferences'], async () => {
    return await handleRequest(
      ReportTablePreferenceService.getApiReportApiAppReportTablePreferenceTablePreferences(
        reportName
      )
    );
  });
  return reportTablePreferences;
};

export default useGetReportTablePreferences;
