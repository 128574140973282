/* eslint-disable camelcase */
import { isEmpty } from 'lodash';
import { type UseMutationResult, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  PsuAwardService,
  type Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto,
} from '../../services/wealthlane-award-services';
import {
  UserDefinedFieldValuesService,
  type Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UserDefinedFieldValuesDto,
} from '../../services/wealthlane-customer-services';
import useWealthlaneCustomerApi from '../useWealthlaneCustomerApi';
import useGetUDFByModuleId from './useGetUDFByModuleId';
import { CommaFormatted } from '../../utils/global';

const useGetPsuAwardForUpdateById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  [
    Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto | undefined,
    Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UserDefinedFieldValuesDto[] | undefined
  ],
  unknown,
  string,
  unknown
> => {
  const navigate = useNavigate();
  const { handleRequest: customerHandleRequest } = useWealthlaneCustomerApi();
  const { data: UdfFields } = useGetUDFByModuleId(customerHandleRequest, 1);
  return useMutation(
    async (id: string) => {
      return await Promise.all([
        handleRequest(PsuAwardService.getApiAwardApiAppPSUAwardPSUAwardForUpdateById(id)),
        customerHandleRequest(
          UserDefinedFieldValuesService.getApiCustomerApiAppUserDefinedFieldValuesUserDefinedFieldValues(
            4,
            id
          )
        ),
      ]);
    },
    {
      onSuccess: (response: any, context: string) => {
        if (response != null) {
          const udfAddedLater = UdfFields?.filter(
            (value: any) =>
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              !response[1]?.some((value2: any) => value.id === value2.userDefinedFieldId)
          );

          const filteredData = response[1]?.map((udfFields: any) => {
            const fieldName = udfFields?.fieldName?.replaceAll(' ', '');
            const udfName: any =
              Boolean(fieldName) &&
              String(fieldName.charAt(0).toLowerCase()) + String(fieldName.slice(1));
            const udfPayload = {
              [udfName]: udfFields?.value,
              [`${String(udfName)}Id`]: udfFields?.id,
            };
            return udfPayload;
          });

          const udfAddedLaterPayload = udfAddedLater?.map((item) => {
            const fieldName = item?.fieldName?.replaceAll(' ', '');
            const udfName: any =
              fieldName != null && fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
            const udfAddedLaterPayload = {
              [udfName]: '',
            };
            return udfAddedLaterPayload;
          });
          const newUdfResp =
            !isEmpty(udfAddedLaterPayload) &&
            udfAddedLaterPayload?.reduce((e: any, r: any) => ({
              ...e,
              ...r,
            }));

          const udfValuesResp =
            !isEmpty(filteredData) &&
            filteredData?.reduce((e: any, r: any) => ({
              ...e,
              ...r,
            }));
          if (response[0] != null) {
            navigate('/awards/psu-awards/edit', {
              state: {
                formStatus: 'Edit',
                employee: [
                  {
                    ...response[0],
                    marketValueAtGrant: CommaFormatted(response[0].marketValueAtGrant),
                    quantity: CommaFormatted(response[0].quantity),
                    grantPrice: CommaFormatted(response[0].grantPrice),
                    ...udfValuesResp,
                    ...newUdfResp,
                  },
                ],
              },
            });
          }
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetPsuAwardForUpdateById;
