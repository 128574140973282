import { Form, Formik } from 'formik';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import ModalBody from '../../../components/modal/modalBodyComponent';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import CustomTextArea from '../../../components/text-area/textArea';
import { usePostJobTitle } from '../../../hooks';
import { type ModalStatusType } from '../../../types/common';
import { JOB_TITLE_VALIDATION } from '../../../utils/validations/validations';
import _ from 'lodash';

interface JobTitleType {
  isModalOpen: boolean;
  closeModal: () => void;
  setModalStatus: (value: ModalStatusType) => void;
  initialData: {
    data: {
      name: string;
      description: string;
    };
    id: string;
    modalStatus: ModalStatusType;
  };
  loading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
}

const JobTitleModal = ({
  isModalOpen,
  closeModal,
  initialData,
  setModalStatus,
  loading,
  handleRequest,
}: JobTitleType): JSX.Element => {
  const { mutate, isLoading } = usePostJobTitle(handleRequest, closeModal, initialData.id);

  return (
    <Formik
      enableReinitialize
      initialValues={initialData.data}
      validationSchema={JOB_TITLE_VALIDATION}
      onSubmit={(values) => {
        mutate(values);
      }}>
      {({ touched, handleChange, errors, values }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            loading={loading}
            size='md'
            title={`${initialData.modalStatus} Job Title`}
            valueEdited={_.isEqual(values, initialData.data)}
            children={
              <Form>
                <ModalBody>
                  <InputText
                    type='text'
                    label='Title'
                    name='name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    inputType={initialData.modalStatus}
                    disabled={initialData.modalStatus === 'View'}
                    required
                  />
                  <CustomTextArea
                    label='Description'
                    rows='5'
                    name='description'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    disabled={initialData.modalStatus === 'View'}
                  />
                </ModalBody>
                <ModalFooterComponent
                  modalStatus={initialData.modalStatus}
                  onCloseModal={() => {
                    closeModal();
                  }}
                  onEditModal={() => {
                    setModalStatus('Edit');
                  }}
                  valueChanged={_.isEqual(values, initialData.data)}
                  loading={isLoading}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default JobTitleModal;
