import FlashMessage from '../../../components/flash-message';
import { PopoverContentWrapper } from '../../../components/modal/modal.styled';
import Spinners from '../../../components/spinner';
import { TableBodyCellColumn } from '../../../constants/style/table.styled';
import { getPopoverGridSystemColumn, getPopoverGridSystemRow } from '../../../utils/global';
import { UserPopoverContentWrapper } from './systemRoleStyled';

interface UserPopoverContentType {
  data: Array<{
    fullName: string;
    title: string;
    department: string;
  }>;
  loading: boolean;
}

const UserPopoverContent = ({ data, loading }: UserPopoverContentType): JSX.Element => {
  const nonFieldGridRow = getPopoverGridSystemRow(data) ?? 1;
  const nonFieldGridColumn = getPopoverGridSystemColumn(data) ?? 1;

  return (
    <UserPopoverContentWrapper className='roles-popovers'>
      {loading && <Spinners size='md' />}
      {!loading && (
        <PopoverContentWrapper className='roles-popovers content-scroll'>
          {data.length > 0 && (
            <div
              className={`roles-popovers content grid gap-4 grid-cols-${nonFieldGridColumn} grid-rows-${nonFieldGridRow}`}>
              {data.map((item) => {
                return (
                  <TableBodyCellColumn className='gap-0 roles-popovers'>
                    <div className='cellTitle roles-popovers'>{item.fullName}</div>
                    <div className='cellSubtitle roles-popovers'>
                      {item.department !== '' && item.title !== ''
                        ? `${item.department} | ${item.title}`
                        : '-'}
                    </div>
                  </TableBodyCellColumn>
                );
              })}
            </div>
          )}
          {data.length === 0 && (
            <FlashMessage
              size='sm'
              message='No Data Found'
              type='info'
              popoverClassName='roles-popovers'
            />
          )}
        </PopoverContentWrapper>
      )}
    </UserPopoverContentWrapper>
  );
};

export default UserPopoverContent;
