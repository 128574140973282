/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_HRIntegrationService_Dtos_IntegrationProviders_ViewLogsDto_Wealthlane_HRIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_HRIntegrationService_Dtos_IntegrationProviders_ViewLogsDto_Wealthlane_HRIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_HRIntegrationService_Dtos_Common_CommonResopnseDto } from '../models/Wealthlane_HRIntegrationService_Dtos_Common_CommonResopnseDto';
import type { Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_CheckConnectionDto } from '../models/Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_CheckConnectionDto';
import type { Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_GetIntegrationProviderDto } from '../models/Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_GetIntegrationProviderDto';
import type { Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_PostAuthorizationcodeDto } from '../models/Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_PostAuthorizationcodeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IntegrationProviderService {

    /**
     * @returns Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_GetIntegrationProviderDto Success
     * @throws ApiError
     */
    public static getApiHrintegrationApiAppIntegrationProviderIntegrationProvider(): CancelablePromise<Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_GetIntegrationProviderDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hrintegration/api/app/integration-provider/integration-provider',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_HRIntegrationService_Dtos_Common_CommonResopnseDto Success
     * @throws ApiError
     */
    public static postApiHrintegrationApiAppIntegrationProviderAuthorizationcode(
requestBody?: Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_PostAuthorizationcodeDto,
): CancelablePromise<Wealthlane_HRIntegrationService_Dtos_Common_CommonResopnseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hrintegration/api/app/integration-provider/authorizationcode',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_CheckConnectionDto Success
     * @throws ApiError
     */
    public static postApiHrintegrationApiAppIntegrationProviderCheckConnection(): CancelablePromise<Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_CheckConnectionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hrintegration/api/app/integration-provider/check-connection',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiHrintegrationApiAppIntegrationProviderRevokeAuthorization(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hrintegration/api/app/integration-provider/revoke-authorization',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param error 
     * @param uri 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_HRIntegrationService_Dtos_IntegrationProviders_ViewLogsDto_Wealthlane_HRIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiHrintegrationApiAppIntegrationProviderLogs(
error?: boolean,
uri?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_HRIntegrationService_Dtos_IntegrationProviders_ViewLogsDto_Wealthlane_HRIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hrintegration/api/app/integration-provider/logs',
            query: {
                'Error': error,
                'Uri': uri,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
