import { type UseQueryResult, useQuery } from 'react-query';
import { PlanTypeCategoryService } from '../../services/wealthlane-award-services';

const useGetPlanManagementPlanTypeCategory = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
  // id?: string
): UseQueryResult<any, unknown> => {
  const PlanManagementPlanTypeCategory = useQuery(
    ['getPlanTypeCategoryService'],
    async () => {
      return await handleRequest(
        PlanTypeCategoryService.getApiAwardApiAppPlanTypeCategoryPlanTypeCategoryList()
      );
    },
    {
      select: (data: any) => {
        return data?.map((item: any) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return PlanManagementPlanTypeCategory;
};

export default useGetPlanManagementPlanTypeCategory;
