import OptionAwardsApproval from '../pages/option-awards/optionsAwardsApproval';
import ParticipantsApproval from '../pages/participants/participantApproval';
import PsuAwardsApproval from '../pages/psu-awards/psuAwardsApproval';
import RsuAwardsApproval from '../pages/rsu-awards/rsuAwardsApproval';
import ApprovalConfig from '../pages/settings/approval-config';
import BankAccount from '../pages/settings/bank-account';
import CompanyProfile from '../pages/settings/company-profile';
import DepartmentComponent from '../pages/settings/department';
import EmailLogs from '../pages/settings/email-service';
import EmployeeTypesComponent from '../pages/settings/employee-types';
import FinchComponent from '../pages/settings/finch';
import GrantManagement from '../pages/settings/grant-management';
import GrantManagementList from '../pages/settings/grant-management/grantList';
import JobTitleComponent from '../pages/settings/job-title';
import ParticipantBroadcast from '../pages/settings/participant-boadcast';
import PerformanceRules from '../pages/settings/performance-rule';
import PerformanceRuleApproval from '../pages/settings/performance-rule/performanceRuleApproval';
import PlanManagement from '../pages/settings/plan-management';
import PlanManagementApproval from '../pages/settings/plan-management/planManagementApproval';
import RetirementEligibility from '../pages/settings/retirement-eligibility';
import SettlementConfig from '../pages/settings/settlement-config';
import SystemRoles from '../pages/settings/system-roles';
import SystemUsers from '../pages/settings/system-users';
import SystemUserApproval from '../pages/settings/system-users/systemUserApproval';
import TaxLibrary from '../pages/settings/tax/tax-library';
import TaxYear from '../pages/settings/tax/tax-period';
import TaxSetup from '../pages/settings/tax/tax-setup';
import TaxSetupApprover from '../pages/settings/tax/tax-setup/taxSetupApprover';
import TermAndCondition from '../pages/settings/term-and-condition';
import TradePreClearanceList from '../pages/settings/trade-pre-clearance';
import TradingBlackoutConfiguration from '../pages/settings/trading-black-out-configuration';
import UserDefineFields from '../pages/settings/udf';
import VestingTemplates from '../pages/settings/vesting-templates';

export const OrganizationSettingsList = [
  {
    showOnList: true,
    url: 'company-profile',
    slug: 'company-profile',
    title: 'Company Profile',
    name: 'Companies',
    category: 'My Account',
    component: <CompanyProfile />,
  },
  {
    showOnList: true,
    url: 'users',
    slug: 'users',
    title: 'System Users',
    name: 'CustomerUsers',
    category: 'System Users & Roles',
    component: <SystemUsers />,
  },
  {
    showOnList: true,
    url: 'roles',
    slug: 'roles',
    title: 'System Roles',
    name: 'CustomerRoles',
    category: 'System Users & Roles',
    component: <SystemRoles />,
  },
  {
    showOnList: true,
    url: 'department',
    slug: 'department',
    title: 'Departments',
    name: 'CustomerDepartments',
    category: 'System Users & Roles',
    component: <DepartmentComponent />,
  },
  {
    showOnList: true,
    url: 'job-title',
    slug: 'job-title',
    title: 'Job Titles',
    name: 'CustomerTitles',
    category: 'System Users & Roles',
    component: <JobTitleComponent />,
  },
  {
    showOnList: true,
    url: 'employee-types',
    slug: 'employee-types',
    title: 'Employee Types',
    name: 'CustomerEmployeeTypes',
    category: 'System Users & Roles',
    component: <EmployeeTypesComponent />,
  },
  {
    showOnList: true,
    url: 'plan-management',
    slug: 'plan-management',
    title: 'Plan Management',
    name: 'Plans',
    category: 'Award Management',
    component: <PlanManagement />,
  },
  {
    showOnList: true,
    url: 'vesting-templates',
    slug: 'vesting-templates',
    title: 'Vesting Templates',
    name: 'VestingTemplates',
    category: 'Award Management',
    component: <VestingTemplates />,
  },
  {
    showOnList: true,
    url: 'performance-rule',
    slug: 'performance-rule',
    title: 'Performance Rules',
    name: 'PerformanceRule',
    category: 'Award Management',
    component: <PerformanceRules />,
  },
  {
    showOnList: true,
    url: 'tax-period',
    slug: 'tax-period',
    title: 'Tax Period',
    name: 'CompanyTax',
    category: 'Configuration',
    component: <TaxYear />,
  },
  {
    showOnList: true,
    url: 'tax-setup',
    slug: 'tax-setup',
    title: 'Tax Setup',
    name: 'CompanyTax',
    category: 'Configuration',
    component: <TaxSetup />,
  },
  {
    showOnList: true,
    url: 'tax-library',
    slug: 'tax-library',
    title: 'Tax Library',
    name: 'CompanyTax',
    category: 'Configuration',
    component: <TaxLibrary />,
  },
  {
    showOnList: true,
    url: 'finch',
    slug: 'finch',
    title: 'HR Payroll Integration',
    name: 'HRIntergration',
    category: 'Configuration',
    component: <FinchComponent />,
  },
  {
    showOnList: true,
    url: 'terms-and-conditions',
    slug: 'terms-and-conditions',
    title: 'T&Cs, Regulatory & Other Legal Documents',
    name: 'TermsAndConditions',
    category: 'Others',
    component: <TermAndCondition />,
  },
  {
    showOnList: true,
    url: 'trade-pre-clearance',
    slug: 'trade-pre-clearance',
    title: 'Trade Pre-Clearance',
    name: 'TradePreClearance',
    category: 'Others',
    component: <TradePreClearanceList />,
  },
  {
    showOnList: true,
    url: 'trading-black-out-configuration',
    slug: 'trading-black-out-configuration',
    title: 'Trading Black-out Configuration',
    name: 'TradingBlackOut',
    category: 'Others',
    component: <TradingBlackoutConfiguration />,
  },
  {
    showOnList: true,
    url: 'bank-account',
    slug: 'bank-account',
    title: 'Bank Account',
    name: 'Banks',
    category: 'Others',
    component: <BankAccount />,
  },
  {
    showOnList: true,
    url: 'udf',
    slug: 'udf',
    title: 'UDF',
    name: 'UDF',
    category: 'Others',
    component: <UserDefineFields />,
  },
  {
    showOnList: false,
    url: 'tax-management',
    slug: 'tax-management',
    title: 'Tax Management',
    name: 'CustomerTax',
    category: 'Others',
    component: <h1>Bank Account</h1>,
  },
  {
    showOnList: true,
    url: 'blackout-config',
    slug: 'blackout-config',
    title: 'Blackout Config',
    name: 'BlackoutConfigs',
    category: 'Award Management',
    component: <h1>Bank Account</h1>,
  },
  {
    showOnList: true,
    url: 'settlement-config',
    slug: 'settlement-config',
    title: 'Settlement Configuration',
    name: [
      'SettlementConfigs',
      'ElectionWindowConfigurations',
      'TradePreClearanceTransactionPeriodConfigs',
    ],
    category: 'Others',
    component: <SettlementConfig />,
  },
  {
    showOnList: true,
    url: 'retirement-config',
    slug: 'retirement-config',
    title: 'Retirement Configuration',
    name: 'RetirementEligibilityConfigs',
    category: 'Others',
    component: <RetirementEligibility />,
  },
  {
    showOnList: true,
    url: 'system-approvers',
    slug: 'system-approvers',
    title: 'System Approvers',
    name: 'SystemApprovers',
    category: 'System Users & Roles',
    component: <ApprovalConfig />,
  },
  {
    showOnList: false,
    url: 'grant-management/:id',
    slug: 'grant-management',
    title: 'Grant Management',
    name: 'Plans',
    category: 'Others',
    component: <GrantManagement />,
  },
  {
    showOnList: false,
    url: 'grant-list',
    slug: 'grant-list',
    title: 'Grant list',
    name: 'Plans',
    category: 'Others',
    component: <GrantManagementList />,
  },
  {
    showOnList: true,
    url: 'user-defined-field',
    slug: 'user-defined-field',
    title: 'User Defined Fields',
    name: 'UserDefinedFields',
    category: 'Others',
    component: <UserDefineFields />,
  },
  {
    showOnList: true,
    url: 'participant-broadcast',
    slug: 'participant-broadcast',
    title: 'Participant Broadcast',
    name: 'ParticipantBroadcastMessages',
    category: 'System Users & Roles',
    component: <ParticipantBroadcast />,
  },
  {
    showOnList: true,
    url: 'email-logs',
    slug: 'email-logs',
    title: 'Email Logs',
    name: 'Notification',
    category: 'Others',
    component: <EmailLogs />,
  },
  {
    showOnList: true,
    url: '/task/user-approval',
    slug: '/task/user-approval',
    permissionName: 'CustomerUserApproval',
    title: 'System User Approval',
    name: 'CustomerUserApproval',
    category: 'Approval Console',
    // component: <SystemUserApproval />,
  },
  {
    showOnList: true,
    url: '/task/plan-approval',
    slug: '/task/plan-approval',
    permissionName: 'PlanApproval',
    title: 'Plan Approval',
    name: 'PlanApproval',
    category: 'Approval Console',
    // component: <PlanManagementApproval />,
  },
  {
    showOnList: true,
    url: '/task/participants-approval',
    slug: '/task/participants-approval',
    permissionName: 'ParticipantApproval',
    title: 'Participant Approval',
    name: 'ParticipantApproval',
    category: 'Approval Console',
    // component: <ParticipantsApproval />,
  },
  {
    showOnList: true,
    url: '/task/rsu-awards-approval',
    slug: '/task/rsu-awards-approval',
    permissionName: 'RSUAwardApproval',
    title: 'RSU Awards Approval',
    name: 'RSUAwardApproval',
    category: 'Approval Console',
    // component: <RsuAwardsApproval />,
  },
  {
    showOnList: true,
    url: '/task/psu-awards-approval',
    slug: '/task/psu-awards-approval',
    permissionName: 'PSUAwardApproval',
    title: 'PSU Awards Approval',
    name: 'PSUAwardApproval',
    category: 'Approval Console',
    // component: <PsuAwardsApproval />,
  },
  {
    showOnList: true,
    url: '/task/option-awards-approval',
    slug: '/task/option-awards-approval',
    permissionName: 'OptionAwardApproval',
    title: 'Option Awards Approval',
    name: 'OptionAwardApproval',
    category: 'Approval Console',
    // component: <OptionAwardsApproval />,
  },
  {
    showOnList: true,
    url: '/task/pre-clearance-requests',
    slug: '/task/pre-clearance-requests',
    permissionName: 'TradePreClearanceApproval',
    title: 'Trade Pre-Clearance Approval',
    name: 'TradePreClearanceApproval',
    category: 'Approval Console',
    // component: <OptionAwardsApproval />,
  },
  {
    showOnList: true,
    url: '/task/tax-setup-approval',
    slug: '/task/tax-setup-approval',
    permissionName: 'TaxApproval',
    title: 'Tax Approval',
    name: 'TaxApproval',
    category: 'Approval Console',
    // component: <TaxSetupApprover />,
  },
  {
    showOnList: true,
    url: '/task/performance-rule-approval',
    slug: '/task/performance-rule-approval',
    permissionName: 'PerformanceRuleApproval',
    title: 'Performance Rule Approval',
    name: 'PerformanceRuleApproval',
    category: 'Approval Console',
    // component: <PerformanceRuleApproval />,
  },
];

export const ComponentsNames = [
  {
    url: 'company-profile',
    name: 'Companies',
  },
  {
    url: 'vesting-templates',
    name: 'VestingTemplates',
  },
  {
    url: 'performance-rule',
    name: 'PerformanceRule',
  },
  {
    url: 'trade-pre-clearance',
    name: 'TradePreClearance',
  },
  {
    url: 'trading-black-out-configuration',
    name: 'TradingBlackOut',
  },
  {
    url: 'notification',
    name: 'Notification',
  },
  {
    url: 'users',
    name: 'CustomerUsers',
  },
  {
    url: 'settlement-config',
    name: 'SettlementConfigs',
  },
  {
    url: 'retirement-config',
    name: 'RetirementConfigs',
  },
  {
    url: 'election-window-config',
    name: 'ElectionWindowConfigurations',
  },
  {
    url: 'plan-management',
    name: 'Plans',
  },
  {
    url: 'roles',
    name: 'CustomerRoles',
  },
  {
    url: 'department',
    name: 'CustomerDepartments',
  },
  {
    url: 'job-title',
    name: 'CustomerTitles',
  },
  {
    url: 'employee-types',
    name: 'CustomerEmployeeTypes',
  },
  {
    url: 'system-approvers',
    name: 'SystemApprovers',
  },
  {
    url: 'grant-management',
    name: 'Grants',
  },
  {
    url: 'grant-list',
    name: 'Grants',
  },
  {
    url: 'plan-approvals',
    name: 'PlanApproval',
  },
  {
    url: 'bank-account',
    name: 'Banks',
  },
  {
    url: 'rsu-awards',
    name: 'RSUAwards',
  },
  {
    url: 'psu-awards',
    name: 'PSUAwards',
  },
  {
    url: 'option-awards',
    name: 'OptionAwards',
  },
  {
    url: 'participants',
    name: 'Participants',
  },
  {
    url: 'terms-and-conditions',
    name: 'TermsAndConditions',
  },
  {
    url: 'user-defined-field',
    name: 'UserDefinedFields',
  },
  {
    url: 'participant-broadcast',
    name: 'ParticipantBroadcastMessages',
  },
  {
    url: 'tax-setup',
    name: 'CompanyTax',
  },
  {
    url: 'tax-period',
    name: 'CompanyTax',
  },
  {
    url: 'reports',
    name: 'DynamicReport',
  },
  {
    url: 'task-management-console',
    name: 'TaskManagerConsole',
  },
  {
    url: 'email-logs',
    name: 'Notification',
  },
  {
    url: '/task/user-approval',
    permissionName: 'CustomerUserApproval',
    name: 'System User Approval',
  },
];

export const MyTaskRouteList = [
  {
    url: '/task/user-approval',
    permissionName: 'CustomerUserApproval',
    name: 'System User Approval',
  },
  {
    url: '/task/participants-approval',
    permissionName: 'ParticipantApproval',
    name: 'Participant Approval',
  },
  {
    url: '/task/rsu-awards-approval',
    permissionName: 'RSUAwardApproval',
    name: 'RSU Awards Approval',
  },
  {
    url: '/task/psu-awards-approval',
    permissionName: 'PSUAwardApproval',
    name: 'PSU Awards Approval',
  },
  {
    url: '/task/plan-approval',
    permissionName: 'PlanApproval',
    name: 'Plan Approval',
  },
  {
    url: '/task/settlement-config-approval',
    permissionName: 'SettlementConfigs',
    name: 'Settlement Config Approval',
  },
  {
    url: '/task/option-awards-approval',
    permissionName: 'OptionAwardApproval',
    name: 'Option Awards Approval',
  },
  {
    url: '/task/pre-clearance-requests',
    permissionName: 'TradePreClearanceApproval',
    name: 'Trade Pre-Clearance Approval',
  },
  {
    url: '/settings/organization-settings/plan-management',
    permissionName: 'Plans',
    name: 'Replenish Share Pool',
  },
  {
    url: '/settings/organization-settings/plan-management',
    permissionName: 'Plans',
    name: 'Plan Expiry',
  },
  {
    url: '/task/tax-setup-approval',
    permissionName: 'TaxApproval',
    name: 'Tax Approval',
  },
  {
    url: '/task/performance-rule-approval',
    permissionName: 'PerformanceRuleApproval',
    name: 'Performance Rule Approval',
  },
  {
    url: '/settings/organization-settings/performance-rule',
    permissionName: 'PerformanceRule',
    name: 'Multiplier Entry',
  },
];

export const EquityAwardsList = [
  {
    url: '/awards/rsu-awards',
    permissionName: 'RSUAwards',
    name: 'RSU Awards',
  },
  {
    url: '/awards/option-awards',
    permissionName: 'OptionAwards',
    name: 'Option Awards',
  },
  {
    url: '/awards/psu-awards',
    permissionName: 'PSUAwards',
    name: 'PSU Awards',
  },
];
export const TransactionsList = [
  {
    url: '/transactions/release',
    permissionName: 'Release',
    name: 'Release',
  },
  {
    url: '/transactions/exercise',
    permissionName: 'OptionExercise',
    name: 'Exercise',
  },
  {
    url: '/transactions/transfer-and-allocate',
    permissionName: 'TransferAllocate',
    name: 'Transfer and Allocate',
  },
  {
    url: '/transactions/export',
    permissionName: 'TransferAllocate',
    name: 'Export and Import',
  },
];

export const TaskManagementConsole = [
  {
    url: 'task-management-console',
    permissionName: 'TaskManagerConsole',
    name: 'Job Console',
  },
];

export const ReportList = [
  {
    url: '/reports',
    permissionName: 'DynamicReport',
    name: 'Reports',
  },
];
