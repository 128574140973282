/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import {
  CustomerCompanyService,
  type Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto,
} from '../../services/wealthlane-customer-services';

const usePostCompanyProfile = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void
): UseMutationResult<
  Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        CustomerCompanyService.putApiCustomerApiAppCustomerCompanyCompanyProfile(payload)
      );
    },
    {
      onSuccess: async (
        response: Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto | undefined
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries('companyProfileDetails');
          closeModal();
          toast.success(
            <ToastComponent label='Success' message='Company Profile Details Updated!' />
          );
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostCompanyProfile;
