/* eslint-disable camelcase */
import _, { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { getFilteredPermissionData } from '.';
import {
  DATE_FORMAT,
  DATE_FORMAT_WITH_TIME,
  ROLES_PERMISSION_LIST,
  SUPPORTED_IMAGE_FORMATS,
} from '../constants/common';
import { selectAuthRoutesList } from '../pages/configuration/routeSlice';
import { PermissionType, type NavRoutePermissionListType, type OptionType } from '../types/common';
import moment from 'moment';
import { type Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto } from '../services/wealthlane-customer-services';

export const getFormData = (data: any): any => {
  const formData = new FormData(); // Must be FormData so that the ajax request will be Form post
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};
//get default option from dropdown option
// export const getDefaultOption = (value?: string | boolean | number, options?: OptionType[]) => {
//   const defaultLabel = options?.find((item) => item?.value === value);
//   return defaultLabel;
// };

export const getActionList = (data: any[]): any[] => {
  const actionList = data?.map((item: any) => {
    return { ...item, action: item?.action, type: item?.action?.props?.children ?? item?.action };
  });

  return actionList;
};

export const checkNaNValue = (values: string | number, name?: string, setFieldName?: any) => {
  const checkValue = Number(values);
  if (!isNaN(checkValue)) {
    setFieldName && setFieldName(name, checkValue);
    return checkValue;
  } else {
    return '';
  }
};

export const setFormikFieldToNull = (
  key: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
): any => {
  setFieldValue(key, '');
  return null;
};

export const CommaFormatted = (
  amount: any,
  name?: string,
  setFieldValue?: any,
  ...rest: any
): string => {
  const numberValue = Number(String(amount)?.replaceAll(',', '') ?? '');
  const value = numberValue.toLocaleString();
  if (name != null && Boolean(amount)) setFieldValue(name, !isNaN(numberValue) ? value : '');
  return value;
};
export const CommaFormattedDecimal = (amount: any, name?: string, setFieldValue?: any): any => {
  const numberValue = amount;
  let value = numberValue.toLocaleString(undefined, { maximumFractionDigits: 2 });
  // Check if the number is a whole number (no decimal part)
  if (Number.isInteger(numberValue)) {
    // Append .00 to indicate it's a whole number
    value += '.00';
  }
  if (name != null && Boolean(amount)) setFieldValue(name, value);
  return value;
};

export const CommaFormattedFloatPost = (amount: any, name?: string, setFieldValue?: any): any => {
  const removeComma = String(amount)?.replaceAll(',', '') ?? '';
  if (!isNaN(Number(removeComma))) {
    const numberValue = amount;
    const splitValue = amount?.split('.');
    const decimalValueWithComma = parseInt(
      String(splitValue[0])?.replaceAll(',', '') ?? ''
    ).toLocaleString();

    const value =
      String(amount).includes('.') && name == null
        ? numberValue.toFixed(2).toLocaleString(undefined, { maximumFractionDigits: 2 })
        : numberValue.toLocaleString(undefined, { maximumFractionDigits: 2 });

    if (name != null && Boolean(amount))
      setFieldValue(
        name,
        amount.includes('.') === true
          ? `${decimalValueWithComma}.${typeof splitValue[1] === 'string' ? splitValue[1] : ''}`
          : decimalValueWithComma
      );
    return value;
  } else {
    setFieldValue(name, '');
  }
};

export const getFileImage = (value: any): any => {
  const fileExtension = getFileExtension(value.file.name);
  if (fileExtension === 'pdf') {
    // return PdfImage;
  } else if (fileExtension === 'doc' || fileExtension === 'docx') {
    // return DocImage;
  } else if (SUPPORTED_IMAGE_FORMATS.includes(fileExtension)) {
    return value.src;
  } else {
    // return FileImage;
  }
};

export const getNonUsCountryValidation = (
  isOtherCountry: boolean | undefined,
  fieldName: string
): any => {
  if (isOtherCountry ?? false) {
    return Yup.string()
      .required('Required field')
      .matches(/^[a-zA-Z ]*$/, `Invalid ${fieldName} Name`)
      .matches(/^\s*\S[\s\S]*$/, 'Must not contain blank spaces')
      .nullable();
  } else {
    return Yup.string()
      .matches(/^[a-zA-Z ]*$/, `Invalid ${fieldName} Name`)
      .matches(/^\s*\S[\s\S]*$/, 'Must not contain blank spaces')
      .nullable();
  }
};

// Get US Validation
export const getUsCountryValidation = (isCountryUS: boolean | undefined): any => {
  if (!(isCountryUS ?? false)) {
    return Yup.string().required('Required field').nullable();
  } else {
    return Yup.string().nullable();
  }
};

export const setObjectNullValue = (data: any): any => {
  const nullableData = Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k, v === false ? false : v ?? null])
  );
  return nullableData;
};

// Set setArrayOfObjectNullValue
export const setArrayOfObjectNullValue = (arrData: any[]): any => {
  const nullableArrayData = arrData.map((item: any) =>
    Object.fromEntries(Object.entries(item).map(([k, v]) => [k, v === false ? false : v ?? null]))
  );
  return nullableArrayData;
};

export const getFileExtension = (name: string): string => {
  const fileArray = name.split('.');
  const fileExtension = fileArray[fileArray.length - 1];

  return fileExtension;
};

export const CommaFormattedFloat = (amount: any, name?: string, setFieldValue?: any): any => {
  const numberValue = amount;
  const value =
    amount.toString().includes('.') === true
      ? numberValue.toFixed(2).toLocaleString(undefined, { maximumFractionDigits: 2 })
      : numberValue.toLocaleString(undefined, { maximumFractionDigits: 2 });

  if (name != null && Boolean(amount)) setFieldValue(name, value);
  return value;
};

export const setDashAsEmptyValue = (
  values: string | number | null | undefined
): string | number => {
  return values === null || values === 0 || values === '' || values === undefined ? '-' : values;
};

export const phoneFormat = (name: string, setFieldValue: any, value: string): void => {
  if (value === '') setFieldValue(name, value);
  const phoneNumber = value.replaceAll(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) {
    setFieldValue(name, phoneNumber);
  } else if (phoneNumberLength < 7) {
    setFieldValue(name, `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`);
  } else {
    setFieldValue(
      name,
      `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    );
  }
};

export const phoneFormatWithDash = (value: string): string => {
  const phoneNumber = value?.replaceAll(/[^\d]/g, '');
  const formattedPhone = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
    6,
    10
  )}`;
  return formattedPhone;
};
export const taxFormatWithDash = (value: string): string => {
  const taxNumber = value?.replace(/[^\d]/g, '');
  const formattedTax = `${taxNumber.slice(0, 2)}-${taxNumber.slice(2, 9)}`;
  return formattedTax;
};

export const ssnFormatWithDash = (name: string, setFieldValue: any, value: string): void => {
  if (value === '') setFieldValue(name, value);
  const ssnNumber = value.replaceAll(/[^\d]/g, '');
  const ssnNumberLength = ssnNumber.length;

  if (ssnNumberLength < 4) {
    setFieldValue(name, ssnNumber);
  } else if (ssnNumberLength < 6) {
    setFieldValue(name, `${ssnNumber.slice(0, 3)}-${ssnNumber.slice(3)}`);
  } else {
    setFieldValue(
      name,
      `${ssnNumber.slice(0, 3)}-${ssnNumber.slice(3, 5)}-${ssnNumber.slice(5, 9)}`
    );
  }
};
export const ssnDisplayFormat = (values: string, display: string): string => {
  const filteredValue = display.includes('show')
    ? `${values.slice(0, 3)}-${values.slice(3, 5)}-${values.slice(5, 9)}`
    : `XXX-XX-${values.slice(5, 9)}`;
  return filteredValue;
};

export const ssnWithDash = (value: string, view: string): string => {
  const ssnNumber = value?.replaceAll(/[^\d]/g, '');
  const formattedPhone =
    view === 'show'
      ? `${ssnNumber.slice(0, 3)}-${ssnNumber.slice(3, 5)}-${ssnNumber.slice(5, 9)}`
      : `XXX-XXX-${ssnNumber.slice(5, 9)}`;
  return formattedPhone;
};

export const NavPermissionCheck = (
  routeList: NavRoutePermissionListType[],
  permissionInfo: string
): any => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {},
    permissionInfo
  );
  const hasPermission = Object.keys(nonFieldLevelPermissionsGroups)?.map((item: string) => item);

  const filterPermissionList = routeList.filter((item: any) =>
    hasPermission.includes(item.permissionName)
  );

  return filterPermissionList;
};

export const handleOpenFile = (fileName: string, fileContent: string): any => {
  const fileExtension = getFileExtension(fileName);

  if (SUPPORTED_IMAGE_FORMATS.includes(fileExtension)) {
    const image = new Image();
    image.src = `data:image/${fileExtension};base64,` + fileContent;
    const w: any = window.open('');
    w.document.write(image.outerHTML);
    w.document.close();
  } else if (fileExtension === 'pdf') {
    const pdfWindow: any = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ` +
        encodeURI(fileContent) +
        "'></iframe>"
    );
  } else {
    const aElement = document.createElement('a');
    const downloadLink = `data:application/${fileExtension};base64,` + fileContent;
    aElement.setAttribute('download', fileName);
    aElement.setAttribute('href', downloadLink);
    aElement.click();
  }
};

export const handleDownloadFile = (fileName: string, fileContent: string): void => {
  const fileExtension = getFileExtension(fileName);
  const aElement = document.createElement('a');
  const downloadLink = `data:application/${fileExtension};base64,` + fileContent;
  aElement.setAttribute('download', fileName);
  aElement.setAttribute('href', downloadLink);
  aElement.click();
};

export const getPopoverGridSystemRow = (data: any[]): number => {
  const dataLength = _.range(1, 11);
  let row: number = 1;
  dataLength.forEach((item) => {
    if (data.length >= item * 5) {
      row = row + 1;
    }
  });
  return row;
};

export const getPopoverGridSystemColumn = (data: any[]): 1 | 2 | 3 | 4 | undefined => {
  if (data.length <= 2) {
    return 1;
  } else if (data.length <= 4) {
    return 2;
  } else if (data.length <= 6) {
    return 3;
  } else if (data.length > 6) {
    return 4;
  }
};

export const handleFilterPermissionList = (
  data: Record<
    string,
    Array<{
      groupKey: string;
      key: string;
      text: string;
    }>
  >
): any => {
  const rolesObj: any = {};
  const permissionInfo = 'CustomerService.Customer.';
  Object.entries(data).forEach(([key]) => {
    const rolesAllData = data[`${key}`].map((itemData) => itemData.text);
    const newData: any = data[`${key}`];
    ROLES_PERMISSION_LIST.forEach((item) => {
      if (!rolesAllData.includes(item)) {
        newData.push({
          groupKey: data[`${key}`][0].groupKey,
          key: `${permissionInfo}${data[`${key}`][0].groupKey}.${item}`,
          text: item,
          isDisabled: true,
        });
      }
    });

    const filteredRoles = newData.sort((a: any, b: any) => {
      const A = a.text;
      const B = b.text;
      return ROLES_PERMISSION_LIST.indexOf(A) > ROLES_PERMISSION_LIST.indexOf(B) ? 1 : -1;
    });

    rolesObj[key] = filteredRoles;
  });

  return rolesObj;
};

export const handleSortPermissionNames = (data: PermissionType, filterList: string[]): any => {
  const sortedData = Object.keys(data)
    .sort((a: string, b: string) => {
      return filterList.indexOf(a) > filterList.indexOf(b) ? 1 : -1;
    })
    .reduce((result: PermissionType, key: string) => {
      result[key] = data[key];
      return result;
    }, {});

  return sortedData;
};

export const convertNumberWithCommas = (
  value?: number | string,
  showDecimal?: boolean,
  fixValue?: number
): any => {
  const NumberValue = Number(value)?.toFixed(fixValue ? fixValue : 2);
  value = `${String(value) ?? ''}`;
  const x: any = NumberValue?.toString().split('.');
  let x1 = x[0];
  const x2 = /* x.length > 1 ? '.' + Number( */ '.' + String(x[1] ?? ''); /* ).toFixed(2) : '' */
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  const finalValue = showDecimal ?? false ? String(x1 ?? '') + String(x2 ?? '') : x1;

  return finalValue;
};

export const MaterialIconFilled = {
  fontVariationSettings: `"FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48`,
};

export const changeTimeZone = (date: any, timeZone: string): any => {
  if (typeof date === 'string') {
    return moment(
      new Date(date)?.toLocaleString('en-US', {
        timeZone,
      })
    ).format(DATE_FORMAT_WITH_TIME);
  }

  return moment(
    date?.toLocaleString('en-US', {
      timeZone,
    })
  ).format(`${DATE_FORMAT} . h:mm A`);
};

export const reportDetailFunction = (response: Record<string, any>, formStatus: string): any => {
  const reportDetail =
    formStatus === 'Edit'
      ? {
          taskId: response.taskId,
          detailIds: [response?.detailId],
        }
      : {
          taskId: response[0].taskId,
          detailIds: response?.map((data: any) => data?.detailId),
        };
  return reportDetail;
};
export const getUdfModuleId = (
  options: OptionType[] | undefined,
  modulePage: number | string
): any => {
  return options?.find((option: OptionType) => option.value === modulePage)?.value;
};

export const udfPayloadFiltered = (
  formData: Record<string, any>,
  response: Record<string, any>,
  UdfFields:
    | Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto[]
    | any,
  UdfValueResponse: Array<Record<string, string>>,
  moduleId: number
): any => {
  const udfWithoutValue = UdfFields?.filter(
    (value: any) => !UdfValueResponse?.some((value2: any) => value.id === value2.userDefinedFieldId)
  );
  const udfWithValue = UdfValueResponse.filter((value: any) =>
    UdfFields.some((value2: any) => value.userDefinedFieldId === value2.id)
  );
  const filteredData = !isEmpty(udfWithoutValue)
    ? formData?.flatMap((item: any, index: number) =>
        UdfFields?.map((udfFields: any) => {
          const fieldName = udfFields?.fieldName?.replaceAll(' ', '');
          const udfName: any =
            Boolean(fieldName) &&
            String(fieldName.charAt(0).toLowerCase() ?? '') + String(fieldName.slice(1) ?? '');
          const udfPayloadWithoutValue = Boolean(udfWithoutValue) && {
            moduleId: moduleId,
            participantId: response?.participantId ?? response[index]?.participantId,

            userDefinedFieldId: !isEmpty(udfWithoutValue)
              ? udfWithoutValue?.find((udfValue: any) => udfValue.id === udfFields.id)?.id
              : udfFields.id,
            moduleFormId: response?.id ?? response[index]?.id,
            value: item[udfName] != null ? item[udfName] : '',
          };
          return udfPayloadWithoutValue;
        })
      )
    : [];
  const filteredData2 = !isEmpty(udfWithValue)
    ? formData?.flatMap((item: any, index: number) =>
        UdfFields?.map((udfFields: any) => {
          const fieldName = udfFields?.fieldName?.replaceAll(' ', '');
          const udfName: any =
            Boolean(fieldName) &&
            String(fieldName.charAt(0).toLowerCase() ?? '') + String(fieldName.slice(1) ?? '');
          const udfPayloadWithValue = {
            moduleFormId: response?.id,
            id: item[`${String(udfName ?? '')}Id`],
            value: item[udfName] != null ? item[udfName] : '',
          };
          return udfPayloadWithValue;
        })
      )
    : [];
  const udfPayloadWithValue = !isEmpty(filteredData)
    ? filteredData?.filter((item: any) => item.userDefinedFieldId !== undefined)
    : [];

  const udfPayloadWithoutFormId = !isEmpty(filteredData2)
    ? [...filteredData2]?.filter((item: any) => item.id !== undefined)
    : [];

  return { udfPayloadWithValue, udfPayloadWithoutFormId };
};

export const capitalizeFirstLetter = (str: string): string => {
  return str?.charAt(0).toUpperCase() + str.slice(1);
};

export const calculateAvailableShares = (totalShares: number, availableShares: number): number => {
  const shareAvailableInPercent = ((availableShares / totalShares) * 100).toFixed(2);
  return Number(shareAvailableInPercent);
};

export const handleStringCheck = (
  value: string,
  setCheck: ({
    longer,
    camelCase,
    isNumber,
    hasSymbol,
  }: {
    longer: boolean;
    camelCase: boolean;
    isNumber: boolean;
    hasSymbol: boolean;
  }) => void
): any => {
  const longer = value.length >= 8;
  const isNumber = /\d/.test(value);
  // eslint-disable-next-line no-useless-escape
  const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
  const camelCase = /[a-z]/.test(value) && /[A-Z]/.test(value);

  setCheck({
    longer,
    camelCase,
    isNumber,
    hasSymbol,
  });
};

export const UniqueArrayCheck = (array1: string[], array2: string[]) => {
  const combinedArray = array1.concat(array2);
  const uniqueArray: string[] = [];

  combinedArray.forEach((item, i) => {
    if (!uniqueArray.includes(combinedArray[i])) {
      uniqueArray.push(combinedArray[i]);
    }
  });

  return uniqueArray;
};

export const areArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
