import { ErrorMessage } from 'formik';
import React, { type ReactNode, useEffect, useState } from 'react';
import { FormWrapper, Label } from '../input-text/input.styled';
import { DateWrapper, DatePickerInput } from './datePicker.styled';
import { DATE_FORMAT } from '../../constants/common';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isEmpty } from 'lodash';
import CheckFieldLevelPermissions from '../../utils/permission/checkFieldLevelPermission';

interface DatePickerTypeProps {
  label?: ReactNode | string;
  name: string;
  placeholder?: string;
  touched?: any;
  labelClassName?: string;
  onChange?: any;
  errors?: any;
  errorValue?: any;
  touchedValue?: any;
  required?: boolean;
  disabled?: boolean;
  setFieldValue?: any;
  values?: any;
  arrValue?: any;
  as?: string;
  min?: string | Date;
  onKeyUp?: any;
  defaultValue?: any;
  max?: string | Date;
  inputType?: 'View' | string;
  width?: string;
  setFieldTouched?: any;
  fieldLabelName?: string;
  isEdit?: boolean;
  isFormik?: boolean;
  dateFormat?: string;
}

const CustomDatePicker = React.memo((props: DatePickerTypeProps) => {
  const [title, setTitle] = useState<string>('');
  const [datePickerActive, setDatePickerActive] = useState<boolean>(false);
  const {
    label,
    name,
    onChange,
    touched,
    placeholder,
    labelClassName,
    errors,
    errorValue,
    touchedValue,
    disabled,
    required,
    onKeyUp,
    values,
    arrValue,
    min = null,
    defaultValue,
    setFieldValue,
    inputType,
    max = null,
    width,
    setFieldTouched,
    isFormik = false,
    fieldLabelName,
    isEdit,
    dateFormat,
  } = props;
  useEffect(() => {
    if (
      (Boolean(arrValue) && arrValue !== undefined) ||
      (Boolean(values) && Boolean(values[name]) && values[name] !== undefined)
    ) {
      if (setFieldTouched != null) {
        setFieldTouched(name, true);
      }
    }
  }, [arrValue]);

  return (
    <FormWrapper>
      <div className='flex flex-col'>
        {label != null && label !== '' && (
          <Label
            htmlFor={name}
            className={`${labelClassName ?? ''} ${inputType === 'View' ? 'label-view' : ''}`}>
            {label} {!(required ?? false) && <span>(Optional)</span>}
          </Label>
        )}
        <DateWrapper className={`${name === title ? 'date-active' : ''}`}>
          <DatePickerInput
            className={`${inputType === 'View' ? 'input-view' : ''} customDatepicker ${
              datePickerActive ? 'customDatepicker-active' : ''
            }`}
            onClick={() => {
              setTitle(name);
            }}>
            <DatePicker
              name={name}
              onClickOutside={() => {
                setDatePickerActive(false);
              }}
              label={label}
              autoComplete='off'
              className={`date-field ${
                fieldLabelName != null &&
                fieldLabelName !== '' &&
                CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                  ? 'show-disabled'
                  : (Boolean(errors) &&
                      Boolean(errors[name]) &&
                      Boolean(touched) &&
                      Boolean(touched[name])) ||
                    (Boolean(errorValue) && Boolean(touchedValue))
                  ? 'is-invalid'
                  : ''
              }`}
              format={DATE_FORMAT}
              dateFormat={dateFormat ?? 'MM/dd/yyyy'}
              showMonthYearPicker={!(dateFormat == null)}
              // onBlur={onBlur}
              minDate={min != null ? moment(min).toDate() : moment().subtract(100, 'year').toDate()}
              maxDate={moment(max).toDate() ?? null}
              onKeyUp={onKeyUp}
              // showMonthDropdown
              placeholderText={
                fieldLabelName != null &&
                fieldLabelName !== '' &&
                CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                  ? 'xxxxx'
                  : placeholder ?? `${DATE_FORMAT}`
              }
              onChange={async (date: Date) => {
                setDatePickerActive(true);
                Boolean(setFieldValue) &&
                  (await setFieldValue(name, moment(date).format('YYYY-MM-DD')));
                Boolean(await onChange) && onChange(date);
                setDatePickerActive(false);
              }}
              defaultValue={defaultValue}
              selected={
                Boolean(arrValue) && arrValue !== 'Invalid date'
                  ? moment(arrValue).toDate()
                  : !isEmpty(values)
                  ? Boolean(values[name]) && values[name] !== 'Invalid date'
                    ? moment(values[name]).toDate()
                    : null
                  : null
              }
              disabled={
                fieldLabelName != null && fieldLabelName !== ''
                  ? CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                  : disabled
              }
              style={{ width: width ?? '100%' }}
            />
            {inputType !== 'View' && (
              <span className='material-symbols-outlined'>calendar_month</span>
            )}
          </DatePickerInput>
        </DateWrapper>

        {(isFormik || Boolean(errorValue) || Boolean(errors)) && (
          <ErrorMessage name={name} component='div' className='error-message' />
        )}
      </div>
    </FormWrapper>
  );
});

export default CustomDatePicker;
