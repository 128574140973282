/* eslint-disable camelcase */
import React from 'react';
import AnimationWrapper from '../../../../components/animation/animationWrapper';
import Button from '../../../../components/button';
import { SkeletonCircle, SkeletonLine } from '../../../../components/skeleton';
import Heading from '../../../../components/typography';
import { useBoolean } from '../../../../hooks';
import { getProfileListData, type ProfileComponentType } from '../company';
import { ProfileContainer, ProfileContentWrapper } from '../profile.styled';
import HeadquarterModal from './headquarterModal';
import { type Wealthlane_CustomerService_Dtos_VendorCompany_CompanyAddressDto } from '../../../../services/wealthlane-customer-services';
import AdditionalAddressModal from './additionalAddressModal';
import { taxFormatWithDash } from '../../../../utils/global';

const HeadquarterComponent = ({ data, loading }: ProfileComponentType): JSX.Element => {
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isAddressModalOpen,
    setFalse: closeAddressModal,
    setTrue: openAddressModal,
  } = useBoolean(false);

  const HeadquarterInfo = [
    {
      title: 'Address Line 1',
      icon: 'location_on',
      isContent: true,
      content: getProfileListData(data?.addressLine1),
    },
    {
      title: 'Address Line 2 (Optional)',
      icon: 'add_location_alt',
      isContent: true,
      content: getProfileListData(data?.addressLine2),
    },
    {
      title: 'Country',
      icon: 'flag',
      isContent: true,
      content: getProfileListData(data?.country),
    },
    {
      title: 'City',
      icon: 'location_city',
      isContent: true,
      content: getProfileListData(data?.city),
    },
    {
      title: 'State',
      icon: 'map',
      isContent: true,
      content: getProfileListData(data?.state),
    },
    {
      title: 'ZIP Code',
      icon: 'pin',
      isContent: true,
      content: getProfileListData(data?.zipCode),
    },
  ];

  const GetAdditionalInfo = ({
    details,
  }: {
    details: Wealthlane_CustomerService_Dtos_VendorCompany_CompanyAddressDto;
  }): JSX.Element => {
    const AdditionalInfo = [
      {
        title: 'Address Line 1',
        icon: 'location_on',
        isContent: true,
        content: getProfileListData(details?.addressLine1),
      },
      {
        title: 'Address Line 2 (Optional)',
        icon: 'add_location_alt',
        isContent: true,
        content: getProfileListData(details?.addressLine2),
      },
      {
        title: 'Tax ID',
        icon: 'mail',
        isContent: true,
        content: getProfileListData(
          (details?.taxId as unknown as boolean) ? taxFormatWithDash(String(details?.taxId)) : ''
        ),
      },
      {
        title: 'Country',
        icon: 'flag',
        isContent: true,
        content: getProfileListData(details?.countryText),
      },
      {
        title: 'City',
        icon: 'location_city',
        isContent: true,
        content: getProfileListData(details?.cityText),
      },
      {
        title: 'State',
        icon: 'map',
        isContent: true,
        content: getProfileListData(details?.stateText),
      },
      {
        title: 'ZIP Code',
        icon: 'pin',
        isContent: true,
        content: getProfileListData(details?.zipCode),
      },
    ];

    return (
      <div className='mb-5'>
        {AdditionalInfo.map((item) => (
          <div className='list grid sm:grid-cols-4 lg:grid-cols-12'>
            <div className='list-icon sm:col-span-2 lg:col-span-4'>
              <span className='material-symbols-outlined'>{item.icon}</span>
              <Heading title={item.title} variant='body-s' />
            </div>
            <div className='list-content sm:col-span-2 lg:col-span-8'>{item.content}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <ProfileContentWrapper className='mb-10'>
        <div className='profile-header'>
          <Heading title='Headquarter Address Details' variant='h5' />
          <Button
            as='Edit'
            variant={'secondary-dark'}
            title='Edit'
            icon='edit'
            onClick={() => {
              openModal();
            }}
          />
        </div>
        <ProfileContainer>
          {loading && (
            <>
              {HeadquarterInfo.map((item) => (
                <div className='list list-loading'>
                  <div className='list-icon'>
                    <SkeletonCircle size='table' shape='circle' />
                  </div>
                  <div className='list-content grid sm:grid-cols-4 lg:grid-cols-12'>
                    <SkeletonLine size='table' containerClassName='sm:col-span-2 lg:col-span-3' />
                    <SkeletonLine size='table' containerClassName='sm:col-span-2 lg:col-span-9' />
                  </div>
                </div>
              ))}
            </>
          )}
          {!loading &&
            HeadquarterInfo.map((item) => (
              <div className='list grid sm:grid-cols-4 lg:grid-cols-12'>
                <div className='list-icon sm:col-span-2 lg:col-span-4'>
                  <span className='material-symbols-outlined'>{item.icon}</span>
                  <Heading title={item.title} variant='body-s' />
                </div>
                <div className='list-content sm:col-span-2 lg:col-span-8'>{item.content}</div>
              </div>
            ))}
        </ProfileContainer>
      </ProfileContentWrapper>
      <ProfileContentWrapper>
        <div className='profile-header'>
          <Heading title='Additional Address Details' variant='h5' />
          <Button
            as='Edit'
            variant={'secondary-dark'}
            title='Edit'
            icon='edit'
            onClick={() => {
              openAddressModal();
            }}
          />
        </div>
        {loading && (
          <ProfileContainer>
            {HeadquarterInfo.map((item) => (
              <div className='list list-loading'>
                <div className='list-icon'>
                  <SkeletonCircle size='table' shape='circle' />
                </div>
                <div className='list-content grid sm:grid-cols-4 lg:grid-cols-12'>
                  <SkeletonLine size='table' containerClassName='sm:col-span-2 lg:col-span-3' />
                  <SkeletonLine size='table' containerClassName='sm:col-span-2 lg:col-span-9' />
                </div>
              </div>
            ))}
          </ProfileContainer>
        )}
        {!loading &&
          data?.companyAddressList?.map(
            (item: Wealthlane_CustomerService_Dtos_VendorCompany_CompanyAddressDto) => (
              <ProfileContainer>
                <GetAdditionalInfo details={item} />
              </ProfileContainer>
            )
          )}
      </ProfileContentWrapper>
      <AnimationWrapper>
        {isModalOpen && (
          <HeadquarterModal
            initialData={data}
            loading={loading}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
        )}
      </AnimationWrapper>
      <AnimationWrapper>
        {isAddressModalOpen && (
          <AdditionalAddressModal
            initialData={data}
            loading={loading}
            isModalOpen={isAddressModalOpen}
            closeModal={closeAddressModal}
          />
        )}
      </AnimationWrapper>
    </>
  );
};

export default HeadquarterComponent;
