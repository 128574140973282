import { type PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { getFilteredPermissionData } from '..';
import { selectAuthRoutesList } from '../../pages/configuration/routeSlice';
import { type ModalStatusType } from '../../types/common';

interface checkPermissionProps {
  type?: ModalStatusType;
  fieldName: string;
}

// Checks Permission
const CheckPermissionWithFieldName = ({
  fieldName,
  children,
}: PropsWithChildren<checkPermissionProps>): JSX.Element => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {}
  );

  const hasPermission = nonFieldLevelPermissionsGroups[fieldName]?.length > 0;
  return <>{hasPermission ? children : ''}</>;
};

export default CheckPermissionWithFieldName;
