import { type UseQueryResult, useQuery } from 'react-query';
import { PsuAwardApprovalService } from '../../services/wealthlane-award-services';

const useGetPsuAwardApprovalPerformanceRuleDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  awardId: string,
  isApprover?: boolean
): any => {
  const psuAwardApprovalPerformanceRuleDetails = useQuery(
    ['getPsuAwardApprovalPerformanceRuleDetails'],
    async () => {
      if (awardId && isApprover) {
        return await handleRequest(
          PsuAwardApprovalService.getApiAwardApiAppPSUAwardApprovalPerformanceRuleForApproverDetail(
            awardId
          )
        );
      }
    }
  );
  return psuAwardApprovalPerformanceRuleDetails;
};

export default useGetPsuAwardApprovalPerformanceRuleDetails;
