import { type ReactNode, useState } from 'react';

import { Transition } from 'react-transition-group';
import { isEmpty } from 'lodash';
import { AlertPopupContainer } from '../../../components/alert-popup/alertPopup.styled';
import Heading from '../../../components/typography';
import Button from '../../../components/button';
import { FormWrapper } from '../../../components/input-text/input.styled';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/common';

interface DeactivatePopupType {
  isShow: boolean;
  closeModal: () => void;
  handleSubmit?: any;
  title?: string | ReactNode;
  modalStatus?: string | ReactNode;
  setRemark?: any;
  remark?: any;
  loading?: boolean;
  customTitle?: string;
  dataRow?: any;
}

const DeactivatePopup = ({
  isShow,
  title,
  modalStatus,
  closeModal,
  handleSubmit,
  remark,
  setRemark,
  loading,
  customTitle,
  dataRow,
}: DeactivatePopupType): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Transition in={isShow} timeout={250}>
      {(state) => (
        <AlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className='popupDialog'>
                <div className='popupContent'>
                  <Heading
                    variant='h5'
                    className='mb-5'
                    title={
                      customTitle ??
                      (modalStatus === 'View Remarks'
                        ? 'Reason'
                        : `Are you sure you want to ${
                            modalStatus === 'Deactivate' ? 'deactivate' : 'confirm'
                          } ${String(title)}?`)
                    }
                  />

                  {(modalStatus === 'Deactivate' || modalStatus === 'View Remarks') && (
                    <FormWrapper>
                      <textarea
                        name='remarks'
                        placeholder='Enter Reason'
                        className={!isEmpty(errorMessage) ? 'border-red-600' : 'input-view'}
                        style={{ minHeight: '100px', minWidth: '430px', resize: 'none' }}
                        defaultValue={remark}
                        disabled={modalStatus === 'View Remarks'}
                        onChange={(e) => {
                          if (e.target.value !== '') {
                            setErrorMessage('');
                            setRemark(e.target.value);
                          }
                        }}></textarea>
                      {errorMessage !== '' && (
                        <Heading variant='body-xs' title={'Required Field'} className={'mt-2'} />
                      )}
                      {modalStatus === 'View Remarks' && (
                        <>
                          <div className='flex mb-1 gap-2 mt-5'>
                            <Heading variant='h6' title='Deactivated by:' />
                            <Heading
                              variant='body'
                              title={
                                (dataRow as boolean) && dataRow.deactiatedByFullName != null
                                  ? dataRow.deactiatedByFullName
                                  : '-'
                              }
                              fontWeight={500}
                            />
                          </div>
                          <div className='flex gap-2 mt-5'>
                            <Heading variant='h6' title='Deactivated date:' />
                            <Heading
                              variant='body'
                              title={
                                Boolean(dataRow) && (dataRow.deactivationTime as boolean)
                                  ? moment(dataRow.deactivationTime).format(DATE_FORMAT)
                                  : '-'
                              }
                              fontWeight={500}
                            />
                          </div>
                        </>
                      )}
                    </FormWrapper>
                  )}
                  <div className='popupButtons'>
                    <Button
                      variant='primary-dark'
                      title={modalStatus === 'View Remarks' ? 'Close' : 'No'}
                      onClick={() => {
                        closeModal();
                        setRemark('');
                      }}
                    />
                    {modalStatus !== 'View Remarks' && (
                      <Button
                        variant='primary'
                        title='Confirm'
                        onClick={() => {
                          modalStatus === 'Deactivate'
                            ? !isEmpty(remark)
                              ? handleSubmit()
                              : setErrorMessage('Required Field')
                            : handleSubmit();
                        }}
                        icon={<span className='material-symbols-outlined'>check_circle</span>}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertPopupContainer>
      )}
    </Transition>
  );
};

export default DeactivatePopup;
