/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Participant_GetParticipantsDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Participant_GetParticipantsDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_CompanyLogo_CompanyLogoBlobDto } from '../models/Wealthlane_CustomerService_Dtos_CompanyLogo_CompanyLogoBlobDto';
import type { Wealthlane_CustomerService_Dtos_Participant_EmployeeStatusDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_EmployeeStatusDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ExportParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ExportParticipantDto';
import type { Wealthlane_CustomerService_Dtos_Participant_GetParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_GetParticipantDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantAccountDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantAccountDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantBulkExportDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantBulkExportDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantdAdminDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantdAdminDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantdBlobDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantdBlobDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantForOnBoardingDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantForOnBoardingDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantImportDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantImportDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantSecurityQuestionsAnswersDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantSecurityQuestionsAnswersDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantStatusDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantStatusDto';
import type { Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ResidentStatusDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ResidentStatusDto';
import type { Wealthlane_CustomerService_Dtos_Participant_SubmitParticipantForOnBoardingDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_SubmitParticipantForOnBoardingDto';
import type { Wealthlane_CustomerService_Dtos_Participant_UpdateAddressByParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_UpdateAddressByParticipantDto';
import type { Wealthlane_CustomerService_Dtos_Participant_UpdateDetailByParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_UpdateDetailByParticipantDto';
import type { Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantAddressDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantAddressDto';
import type { Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantDetailDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantDetailDto';
import type { Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantEmploymentDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantEmploymentDto';
import type { Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantSecurityQuestionsAnswersDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantSecurityQuestionsAnswersDto';
import type { Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantTaxDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantTaxDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ViewParticipantAddressDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ViewParticipantAddressDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentByParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentByParticipantDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ViewParticipantPersonalDetailDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ViewParticipantPersonalDetailDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ViewParticipantTaxDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ViewParticipantTaxDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GetGovernmentDocumentBlobRequest } from '../models/Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GetGovernmentDocumentBlobRequest';
import type { Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobRequest } from '../models/Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobRequest';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_SendParticipantSignUpLinkDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_SendParticipantSignUpLinkDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantService {
  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantImportDto Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantParticipants(
    requestBody?: Array<Wealthlane_CustomerService_Dtos_Participant_ParticipantImportDto>
  ): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_Participant_ParticipantImportDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/participants',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param employeeStatusIds
   * @param employeeTypeId
   * @param filter
   * @param sorting
   * @param skipCount
   * @param maxResultCount
   * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Participant_GetParticipantsDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantsList(
    employeeStatusIds?: Array<number>,
    employeeTypeId?: string,
    filter?: string,
    sorting?: string,
    skipCount?: number,
    maxResultCount?: number
  ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Participant_GetParticipantsDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participants-list',
      query: {
        EmployeeStatusIds: employeeStatusIds,
        EmployeeTypeId: employeeTypeId,
        Filter: filter,
        Sorting: sorting,
        SkipCount: skipCount,
        MaxResultCount: maxResultCount,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantParticipant(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_ParticipantDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ParticipantDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/participant',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantById(
    id: string
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-by-id/{Id}',
      path: {
        Id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantUpdateParticipantById(
    id: string
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ParticipantDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/update-participant-by-id/{Id}',
      path: {
        Id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantStatusDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantStatuses(): CancelablePromise<
    Array<Wealthlane_CustomerService_Dtos_Participant_ParticipantStatusDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-statuses',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_EmployeeStatusDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantEmployeeStatuses(): CancelablePromise<
    Array<Wealthlane_CustomerService_Dtos_Participant_EmployeeStatusDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-employee-statuses',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ResidentStatusDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantResidentStatuses(): CancelablePromise<
    Array<Wealthlane_CustomerService_Dtos_Participant_ResidentStatusDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/resident-statuses',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param name
   * @param subject
   * @param requestBody
   * @returns boolean Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantSendEmail(
    name?: string,
    subject?: string,
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_ParticipantdAdminDto
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/send-email',
      query: {
        name: name,
        subject: subject,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param formData
   * @returns boolean Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantUploadParticipantSample(formData?: {
    document?: Blob;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/upload-participant-sample',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns boolean Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantSendParticipantSignUpLink(
    requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_SendParticipantSignUpLinkDto
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/send-participant-sign-up-link',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantdBlobDto Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantDownloadParticipantSample(): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ParticipantdBlobDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/download-participant-sample',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantPersonalDetailDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantPersonalDetailById(
    id: string
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantPersonalDetailDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-personal-detail-by-id/{Id}',
      path: {
        Id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantAddressDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantAddressById(
    id: string
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantAddressDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-address-by-id/{Id}',
      path: {
        Id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantEmploymentById(
    id: string
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-employment-by-id/{Id}',
      path: {
        Id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantTaxDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantTaxById(
    id: string
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantTaxDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-tax-by-id/{Id}',
      path: {
        Id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantDetailDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantParticipantDetails(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantDetailDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantDetailDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/participant-details',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantAddressDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantParticipantAddresses(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantAddressDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantAddressDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/participant-addresses',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantEmploymentDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantParticipantEmployment(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantEmploymentDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantEmploymentDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/participant-employment',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentByParticipantDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantEmployment(): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentByParticipantDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-employment',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantTaxDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantParticipantTax(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantTaxDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantTaxDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/participant-tax',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantTaxDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantTax(): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantTaxDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-tax',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantForOnBoardingDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantForOnBoarding(): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ParticipantForOnBoardingDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-for-on-boarding',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_SubmitParticipantForOnBoardingDto Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantSubmitParticipantForOnBoarding(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_SubmitParticipantForOnBoardingDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_SubmitParticipantForOnBoardingDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/submit-participant-for-on-boarding',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_CompanyLogo_CompanyLogoBlobDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantCompanyLogo(): CancelablePromise<Wealthlane_CustomerService_Dtos_CompanyLogo_CompanyLogoBlobDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/company-logo',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantPersonalDetailDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantPersonalDetail(): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantPersonalDetailDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-personal-detail',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantAddressDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantAddress(): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantAddressDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-address',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantDetailDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantPersonalDetailsByParticipant(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_UpdateDetailByParticipantDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantDetailDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/personal-details-by-participant',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantAddressDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantAddressesByParticipant(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_UpdateAddressByParticipantDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantAddressDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/addresses-by-participant',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantSecurityQuestionsAnswersDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantSecurityQuestionsAnswers(): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ParticipantSecurityQuestionsAnswersDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-security-questions-answers',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantSecurityQuestionsAnswersDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantParticipantsSecurityAnswers(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantSecurityQuestionsAnswersDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_UpdateParticipantSecurityQuestionsAnswersDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/participants-security-answers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param phoneNumber
   * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
   * @throws ApiError
   */
  public static putApiCustomerApiAppParticipantPersonContactNumber(
    phoneNumber?: string
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/customer/api/app/participant/person-contact-number',
      query: {
        phoneNumber: phoneNumber,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns string Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantContactNumber(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/contact-number',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantAccountDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantAccountNumber(): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ParticipantAccountDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participant-account-number',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantRegistredParticipants(): CancelablePromise<
    Array<Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/registred-participants',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantUnregisteredParticipants(): CancelablePromise<
    Array<Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/unregistered-participants',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantDownloadGovernmentDocument(
    requestBody?: Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GetGovernmentDocumentBlobRequest
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/download-government-document',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantDownloadGovernmentDocumentByParticipant(
    requestBody?: Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobRequest
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/download-government-document-by-participant',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param input
   * @param participantId
   * @returns Wealthlane_CustomerService_Dtos_Participant_GetParticipantDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantParticipantsForTax(
    input?: string,
    participantId?: string
  ): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_Participant_GetParticipantDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/participants-for-tax',
      query: {
        input: input,
        ParticipantId: participantId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantTerminatedParticipants(): CancelablePromise<
    Array<Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/terminated-participants',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @returns Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantRetirementEligibleParticipants(): CancelablePromise<
    Array<Wealthlane_CustomerService_Dtos_Participant_RegistredParticipantDto>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/retirement-eligible-participants',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param id
   * @returns Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto Success
   * @throws ApiError
   */
  public static getApiCustomerApiAppParticipantBulkImportStatus(
    id: string
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/customer/api/app/participant/bulk-import-status/{Id}',
      path: {
        Id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param sessionId
   * @returns boolean Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantStopBulkImport(
    sessionId: string
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/stop-bulk-import/{sessionId}',
      path: {
        sessionId: sessionId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantBulkExportDto Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantExportParticipants(
    requestBody?: Wealthlane_CustomerService_Dtos_Participant_ExportParticipantDto
  ): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ParticipantBulkExportDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/export-participants',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }

  /**
   * @param formData
   * @returns Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto Success
   * @throws ApiError
   */
  public static postApiCustomerApiAppParticipantImportBulkParticipants(formData?: {
    File?: Blob;
  }): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/customer/api/app/participant/import-bulk-participants',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
        501: `Server Error`,
      },
    });
  }
}
