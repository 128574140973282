import { type UseQueryResult, useQuery } from 'react-query';
import { PsuAwardService, RsuAwardService } from '../../services/wealthlane-award-services';

const useGetPsuAwardNumber = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string,
  isApprover: boolean | undefined
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const psuAwardNumberOptions = useQuery(
    ['getPsuAwardNumberOptions', id, isApprover],
    async () => {
      return !(isApprover ?? false)
        ? await handleRequest(PsuAwardService.getApiAwardApiAppPSUAwardPSUAwardNumbers(id))
        : [];
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.awardNumber ?? '',
            value: item.psuAwardId ?? '',
          };
        });
      },
    }
  );

  return psuAwardNumberOptions;
};

export default useGetPsuAwardNumber;
