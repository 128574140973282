import { type UseQueryResult, useQuery } from 'react-query';
import { PsuAwardApprovalService, PsuAwardService } from '../../services/wealthlane-award-services';

const useGetPsuApprovalAcceptanceList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const psuApprovalAcceptanceList = useQuery(
    ['getPsuApprovalAcceptanceList'],
    async () => {
      return await handleRequest(
        PsuAwardApprovalService.getApiAwardApiAppPSUAwardApprovalPSUAcceptances()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return psuApprovalAcceptanceList;
};

export default useGetPsuApprovalAcceptanceList;
