/* eslint-disable camelcase */
// import { isEmpty } from 'lodash';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';

import {
  CustomReportsService,
  Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto,
} from '../../services/wealthlane-report-services';

const usePostRenameReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  setCheckNameError?: any
): UseMutationResult<
  Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        CustomReportsService.postApiReportApiAppCustomReportsRenameReport(payload)
      );
    },
    {
      onSuccess: async (response) => {
        if (response != null) {
          await queryClient.invalidateQueries('getCustomReportList');
          if (response?.error && response?.error?.length > 0) {
            setCheckNameError(response.error);
          }
          if (response?.isSuccess) {
            {
              closeModal();
              toast.success(<ToastComponent label='Success' message='Rename successfully' />);
            }
          }
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostRenameReport;
