import { type FormikErrors, type FormikTouched } from 'formik';
import React, { useState, useEffect } from 'react';
import { type OptionType } from '../../../../../types/common';
import Heading from '../../../../../components/typography';
import FormSelect from '../../../../../components/dropdown/formSelect';
import { COLORS } from '../../../../../constants/style/color';
import { ReactLiquid } from 'react-liquid';
import { Label } from '../../../../../components/input-text/input.styled';
import _, { isEmpty } from 'lodash';
import { DescriptionType } from '../taxConstantTypes';
interface JurisdictionLevel {
  metaData?: any;
  handleChange: any;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  values: any;
  setFieldValue: any;
  modalStatus: string;
  countryOptions?: OptionType[];
  stateOptions?: OptionType[];
  cityOptions?: OptionType[];
  setOptionSelectedState: (value: any) => void;
  optionSelectedState: Record<string, string>;
  initialValue?: any;
  descriptionData?: DescriptionType[];
  filteredValue?: any;
}

const JurisdictionLevelComponent = ({
  metaData,
  handleChange,
  errors,
  touched,
  values,
  initialValue,
  setFieldValue,
  modalStatus,
  countryOptions,
  stateOptions,
  cityOptions,
  setOptionSelectedState,
  optionSelectedState,
  descriptionData,
  filteredValue,
}: JurisdictionLevel): JSX.Element => {
  // const [filteredValue, setFilteredValue] = useState<any>();
  // useEffect(() => {
  //   // let group =
  //   //   descriptionData !== undefined &&
  //   //   descriptionData[0]?.groupLogicalOperator !== undefined &&
  //   //   _(descriptionData)
  //   //     .groupBy('groupLogicalOperator')
  //   //     .map((data, groupLogicalOperator) => {
  //   //       let field = _(data)
  //   //         ?.groupBy('field')
  //   //         ?.map((item, field) => {
  //   //           let equality = _(item)
  //   //             ?.groupBy('equalityOperator')
  //   //             ?.map((equals, equalityOperator) => {
  //   //               let name = equals.map((country: any) => country.name);
  //   //               return { equalityOperator, name };
  //   //             })
  //   //             .value();
  //   //           return { field, equality };
  //   //         })
  //   //         .value();
  //   //       return {
  //   //         field,
  //   //         groupLogicalOperator,
  //   //       };
  //   //     })
  //   //     .value();
  //   setFilteredValue(group);
  // }, [descriptionData, values]);

  useEffect(() => {
    if (metaData?.establishedCountry === 'US') {
      setOptionSelectedState({
        ...optionSelectedState,
        juridictionLevelId: values?.jurisdictionLevelId,
        countryId: countryOptions?.[0]?.value,
      });
      setFieldValue('countryId', countryOptions?.[0]?.value);
      !_.isEqual(values?.stateId, initialValue?.stateId) && setFieldValue('stateId', null);
      !_.isEqual(values?.cityId, initialValue?.cityId) && setFieldValue('cityId', null);
    } else if (modalStatus === 'Add' && values?.countryId !== null) {
      setFieldValue('countryId', values?.countryId);
      setOptionSelectedState({
        ...optionSelectedState,
        juridictionLevelId: values?.jurisdictionLevelId,
        countryId: values?.countryId,
      });
    } else if (values?.countryId !== null) {
      setFieldValue('countryId', values?.countryId);
      setOptionSelectedState({
        ...optionSelectedState,
        juridictionLevelId: values?.jurisdictionLevelId,
        countryId: values?.countryId,
      });
    } else {
      setFieldValue('countryId', null);
    }

    setFieldValue('description', metaData?.description);
  }, [metaData, countryOptions]);

  // useEffect(() => {
  //   if (stateArrayOptions !== null) {
  //     setFieldValue('stateId', values?.stateId);
  //     setOptionSelectedState({
  //       ...optionSelectedState,
  //       stateId: values?.stateId,
  //       cityArrayIndex: `cityOptions`,
  //     });
  //   }
  // }, [stateArrayOptions]);
  const template = `<textarea class="description-textarea" disabled>
 ${
   values?.description !== null
     ? `${values?.description} \n` +
       `${
         typeof filteredValue === 'object'
           ? filteredValue
               ?.map((item: any) =>
                 item?.field
                   ?.map((fieldBlock: any) =>
                     fieldBlock.equality
                       ?.map(
                         (equalityBlock: any) =>
                           `And ${fieldBlock?.field !== 'undefined' ? fieldBlock?.field?.replace(/\b\w/g, (x: string) =>
                             x.toUpperCase()
                           ) : ''} ${
                             equalityBlock?.equalityOperator !== 'undefined'
                               ? equalityBlock?.equalityOperator?.replace(/\b\w/g, (x: string) =>
                                   x.toUpperCase()
                                 )
                               : ''
                           } ${equalityBlock?.name
                             ?.map((names: string) =>
                               names?.replace(/\b\w/g, (x: string) => x.toUpperCase())
                             )
                             .join(
                               `${
                                 item?.groupLogicalOperator?.includes('And')
                                   ? ' and '
                                   : item?.groupLogicalOperator?.includes('Not')
                                   ? ' not '
                                   : ' or '
                               }`
                             )} \n`
                       )
                       .join('')
                   )
                   .join('')
               )
               .join('')
           : ''
       }`
     : `${
         typeof filteredValue === 'object'
           ? filteredValue
               ?.map((item: any) =>
                 item?.field
                   ?.map((fieldBlock: any) =>
                     fieldBlock.equality
                       ?.map(
                         (equalityBlock: any) =>
                           `And ${fieldBlock?.field !== 'undefined' ?  fieldBlock?.field?.replace(/\b\w/g, (x: string) =>
                             x.toUpperCase()
                           ) : ''} ${
                             equalityBlock?.equalityOperator !== 'undefined'
                               ? equalityBlock?.equalityOperator?.replace(/\b\w/g, (x: string) =>
                                   x.toUpperCase()
                                 )
                               : ''
                           } ${equalityBlock?.name
                             ?.map((names: string) =>
                               names?.replace(/\b\w/g, (x: string) => x.toUpperCase())
                             )
                             .join(
                               `${
                                 item?.groupLogicalOperator?.includes('And')
                                   ? ' and '
                                   : item?.groupLogicalOperator?.includes('Not')
                                   ? ' not '
                                   : ' or '
                               }`
                             )} \n`
                       )
                       .join('')
                   )
                   .join('')
               )
               .join('')
           : ''
       }`
 }
   </textarea>`;

  const data = {
    Country:
      values?.countryId !== null
        ? countryOptions?.find((item: OptionType) => item.value === values?.countryId)?.label
        : '{{Country}}',
    State:
      values?.stateId !== null
        ? stateOptions?.find((item: OptionType) => item.value === values?.stateId)?.label
        : '{{State}}',
    City:
      values?.cityId !== null
        ? cityOptions?.find((item: OptionType) => item.value === values?.cityId)?.label
        : '{{City}}',
  };
  return (
    <div>
      {(metaData?.requiresCountry === true ??
        metaData?.requiresState === true ??
        metaData?.requiresCity === true) && (
        <>
          <Heading title='Jurisdiction' variant='h5' color={COLORS.Gray} className='mb-4' />
          <div className='grid lg:grid-cols-3 sm:grid-cols-1 gap-x-5'>
            {metaData?.requiresCountry === true && (
              <FormSelect
                label={'Country'}
                name='countryId'
                containerClassName='formGroup'
                onChange={(event: any) => {
                  setOptionSelectedState({
                    ...optionSelectedState,
                    countryId: event,
                  });
                  setFieldValue('stateId', null);
                  setFieldValue('cityId', null);
                }}
                arrValue={
                  metaData?.establishedCountry === 'US'
                    ? countryOptions?.[0]?.value
                    : values?.countryId
                }
                errors={errors}
                touched={touched}
                options={countryOptions}
                values={values}
                setFieldValue={setFieldValue}
                inputType={modalStatus}
                disabled={metaData?.establishedCountry === 'US' ? true : modalStatus === 'View'}
                required
              />
            )}
            {metaData?.requiresState === true && (
              <FormSelect
                label={'State'}
                name='stateId'
                containerClassName='formGroup'
                onChange={(event: any) => {
                  setOptionSelectedState({
                    ...optionSelectedState,
                    stateId: event,
                  });
                  setFieldValue('cityId', null);
                }}
                errors={errors}
                touched={touched}
                arrValue={values?.stateId}
                options={stateOptions}
                values={values}
                setFieldValue={setFieldValue}
                inputType={modalStatus}
                disabled={modalStatus === 'View'}
                required
              />
            )}
            {metaData?.requiresCity === true && (
              <FormSelect
                label={'City'}
                name='cityId'
                containerClassName='formGroup'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                arrValue={values?.cityId}
                options={cityOptions}
                values={values}
                setFieldValue={setFieldValue}
                inputType={modalStatus}
                disabled={modalStatus === 'View'}
                required
              />
            )}
          </div>
        </>
      )}
      <Label className='label-view'>Description</Label>
      <ReactLiquid template={template} data={data} html />
    </div>
  );
};

export default JurisdictionLevelComponent;
