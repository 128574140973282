import { type UseQueryResult, useQuery } from 'react-query';
import {
  OptionExerciseService,
  StatusDropdownAppServicesService,
} from '../../services/wealthlane-award-services';

const useGetOptionExerciseRecordType = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
      systemName?: any;
    }>
  | undefined,
  unknown
> => {
  const optionExerciseRecordType = useQuery(
    ['getOptionExerciseRecordType'],
    async () => {
      return await handleRequest(
        StatusDropdownAppServicesService.getApiAwardApiAppStatusDropdownAppServicesExerciseRecordTypes()
      );
    },
    {
      select: (data) => {
        const options = data?.map((item) => {
          return {
            label: item.displayName ?? '',
            value: item.id ?? '',
            systemName: item?.systemName,
          };
        });

        const customOptions = options?.sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });

        return customOptions;
      },
      retry: false,
    }
  );

  return optionExerciseRecordType;
};

export default useGetOptionExerciseRecordType;
