import { type UseQueryResult, useQuery } from 'react-query';
import {
  ReleaseService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Release_ReleaseDto,
} from '../../services/wealthlane-award-services';

const useGetTransactionReleaseData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  setInitialValue?: any,
  setSelectedId?: any,
  setSubmitted?: any
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Release_ReleaseDto | undefined,
  unknown
> => {
  const transactionReleaseData = useQuery(
    ['getTransactionReleaseData', id],
    async () => {
      if (id) {
        return await handleRequest(ReleaseService.getApiAwardApiAppReleaseReleaseById(id));
      }
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          setInitialValue(response);
          setSelectedId(response?.id);
          setSubmitted(true);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );

  return transactionReleaseData;
};

export default useGetTransactionReleaseData;
