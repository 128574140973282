import { COLORS } from './constants/style/color';
import { createGlobalStyle } from 'styled-components/macro';
import { DEFAULT_FONT_SIZE } from './constants/common';
import { getViewHeight, rem } from './utils/style';

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'HK Grotesk', sans-serif;
  }

  *{
    /* outline: 1px solid red; */
  }

  html {
    font-size: ${DEFAULT_FONT_SIZE}px; /* 10px or 62.5% */
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    margin-right: calc(-1 * (100vw - 100%));
  }

  button, .sidebarIcon-close {
    -webkit-tap-highlight-color: transparent!important;
    outline: none!important;
    -ms-touch-action: manipulation!important;
    touch-action: manipulation!important;
  }
  
  body {
    color: ${COLORS.Black};
    background-color: ${COLORS.White};
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 67px;
    transition: padding-top 0.2s linear;
  }

  .h-normal-screen {
    height: ${getViewHeight('67px')};
  }

  .material-symbols-outlined,
  .material-symbols-rounded,
  .material-symbols-sharp {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }

  .material-symbols-outlined-filled,
  .material-symbols-rounded-filled,
  .material-symbols-sharp-filled {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }

  .description-textarea{
    padding: 7px;
    border: 1px solid #D7D7D7;
    font-size: 16px;
    border-radius: 0.6rem;
    margin: 5px 0px;
    width: 100%;

    &:disabled{
      background-color: #F3F3F3;
    border: 0.1rem solid #D7D7D7;
    }
  }

  .toast-container {
    background: ${COLORS.White};
    border-radius: ${rem(15)};
    color: ${COLORS.Gray30};
    border-radius: 15px;

    box-shadow: inset -4px -1px 5px rgba(232, 232, 232, 0.23);
    padding: ${rem(14)} ${rem(18)};
    display: flex;
    align-items: center;
    font-size: ${rem(12)};
    top: 90px;
  }

  .Toastify__toast--success {
    filter: drop-shadow(0px -1px 7px rgba(5, 185, 55, 0.2));
  }

  .Toastify__toast--error {
    filter: drop-shadow(0px -1px 7px rgba(221, 37, 37, 0.2));
  }

  .Toastify__toast--info {
    filter: drop-shadow(0px -1px 7px rgba(28, 133, 231, 0.2));
  }
  
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }

  .form-gap {
    margin-bottom: ${rem(14)};
  }
  fieldset {
    margin-bottom: ${rem(14)};
  }
  .formGroup {
    margin-bottom: ${rem(18)};
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

  /* animation sidebar */
  .sideBar {
    z-index: 999999;
    opacity: 0;
    animation: 500ms fadeOut ease-in-out;
    &-entered {
      animation: 500ms fadeIn ease-in-out;
      display: block;
      opacity: 1;
      height: 100%;
    }
    &-entering {
    }
    &-exited {
      animation: 500ms fadeOut ease-in-out;
      opacity: 0;
    }
  }
  .alertPopup {
    z-index: 999999;
    opacity: 0;
    animation: 500ms fadeOut ease-in-out;
    &-entered {
      animation: 500ms fadeIn ease-in-out;
      display: block;
      opacity: 1;
    }
    &-entering {
    }
    &-exited {
      animation: 500ms fadeOut ease-in-out;
      opacity: 0;
    }
  }
  .modalBackdrop {
    background: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    /* transition: all 500ms ease-in-out; */
    z-index: -100;
    opacity: 0;
    visibility: hidden;
    &-entered {
      animation: 300ms backdropfadeIn ease-in-out;
      background: rgba(22, 22, 22, 0.85);
      /* z-index: 100; */
      z-index: 1300;
      opacity: 1;
      visibility: visible;
    }
    &-exited {
      animation: 300ms backdropfadeOut ease-in-out;
      background-color: rgba(0, 0, 0, 0);
      z-index: -100;
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes backdropfadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes backdropfadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(20rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(20rem);
    }
  }

  /* main layout */
  .wrapperContainer {
    display: flex;
    /* background: white; */
    .aside {
      width: 240px;
    }
  }
  .profile-header {
    button {
      /* padding: 6px 8px; */
      /* border:none; */
    }
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in-out;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
  }



  .methodTable{
  background:#f3f3f380;
  border-radius: 6px;
  padding:17px 20px 10px 24px;
  font-size: 14px;
  max-height: 400px;
  overflow: auto;

  ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 7px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${COLORS.SpanishGray};
        border-radius: 7px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
  &:not(:last-child){
    margin-bottom:12px;

  }
  &BoxTitle{
    margin-bottom:18px;
  }
  &Grid{
    grid-column-gap:12.4rem ;
  }
  &Tag{
    margin-left: ${rem(5)} ;
  }
  table{
    width:100%;
    thead{
      tr{
        td{
          font-weight: 900;
          padding-bottom:15px;
        }
      }
    }
  }
  tbody{
    tr{
      &:first-child{
        td{

          padding-top:14;
        }
      }
      &:not(:last-child){
        border-bottom: 1px solid #D7D7D7;
      }
      
      td{
        width: 200px;
        padding:14px 0;
        span{
          color:#595959;
        }
        &:last-child{
          span{
            justify-content:flex-end ;
          }
        }
      }
      &.bold{
         td{
            span{
              font-weight:600;
              color:${COLORS.Black}
            }
          }
      }
    }
  }
}
  .MyCustomTransition-appear-active,
  .MyCustomTransition-enter-active,
  .MyCustomTransition-enter-done {
    opacity: 1;
    /* transform: translateY(0); */
    transition: all 400ms ease-in-out;
  }

  .MyCustomTransition-appear,
  .MyCustomTransition-enter,
  .MyCustomTransition-exit-active,
  .MyCustomTransition-exit-done {
    opacity: 0;
    transition: all 400ms ease-in-out;
  }

  .customBold {
    font-weight: bold !important;
  }

  .descriptionOutput {
    p > * {
      background: initial !important;
      font-size: inherit !important;
    }
  }

  .needHelp {
    width: 100%;
    img {
      margin-right: 12px;
    }
    padding: 18px 24px;
    background: linear-gradient(90.44deg, rgba(255, 255, 255, 0.65) 39.11%, #ffffff 96.9%);
    border-radius: 10px;
    display: flex;
    align-items: center;
  }

  .delete-label {
    color: ${COLORS.Red};
  }

  .descriptionOutput {
    min-height: ${rem(40)};
  }


  .error-message,
  .field-error-message {
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    color: ${COLORS.Gray};
    margin-top: ${rem(6)};
    transition: all 0.2s linear;
  }
  .report-error{
       font-weight: 400;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    color: ${COLORS.Red};
    margin-top: ${rem(6)};
    transition: all 0.2s linear;
  }

  .reject-view{
    padding: 4px;
    border: 2px solid ${COLORS.Gray100};
    font-size: 16px;
  }
  .view-link{
    font-size: 16px;
    color: ${COLORS.Iris};
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 4px;
  }
   .testDrop{
  z-index:9999;
}

  .input-view-error{
    padding: 4px;
    border: 2px solid ${COLORS.Red};
    font-size: 16px;
  }
  .is-invalid {
        border: 1px solid ${COLORS.Red};
      }
  .tmc-tab-margin {
        margin-left:35%
      }
  .exercise-tab-margin {
        margin-left:25%
      }
  .transaction-tab-margin {
        margin-left:43%
      }
  .transaction-include-tab-margin {
        margin-left:20%
      }
.apply-button {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      padding: 10px 20px;
      }

      .scroll-y{
        overflow-y: scroll;
        ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 7px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${COLORS.SpanishGray};
        border-radius: 7px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      }


    .import-spinner{
          width: ${rem(15)};
      height: ${rem(14)};
  }
     
`;
