import styled, { keyframes, css } from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils/style';

interface SpinnerType {
  borderColor: string;
  size?: 'sm' | 'md' | 'lg';
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<SpinnerType>`
  pointer-events: none;
  border: 3px solid ${COLORS.LightGray};
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1.5s linear infinite;

  ${(props: { borderColor: string }) => {
    return css`
      border-bottom-color: ${props.borderColor};
    `;
  }}

  ${(props) => {
    if (props.size === 'sm') {
      return css`
        width: ${rem(16)};
        height: ${rem(16)};
      `;
    }
    if (props.size === 'md') {
      return css`
        width: ${rem(40)};
        height: ${rem(40)};
      `;
    }
    if (props.size === 'lg') {
      return css`
        width: ${rem(52)};
        height: ${rem(52)};
      `;
    }
  }};

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* cursor: pointer;
  padding: 2px;
  animation: ${rotate360} 1.5s linear infinite;
  transform: translateZ(0);
  border-style: solid;
  ${(props: { borderColor: string }) => {
    return css`
      border-color: ${props.borderColor};
    `;
  }}
  border-left: 3px solid transparent;
  background: transparent;
  border-radius: 50%;

  ${(props) => {
    if (props.size === 'sm') {
      return css`
        width: 10px;
        height: 10px;
        border-width: 2px;
      `;
    }
    if (props.size === 'md') {
      return css`
        width: 20px;
        height: 20px;
        border-width: 4px;
      `;
    }
    if (props.size === 'lg') {
      return css`
        width: 30px;
        height: 30px;
        border-width: 6px;
      `;
    }
  }}; */
`;
