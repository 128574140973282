import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto } from '../../../../services/wealthlane-tax-services';
import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import {
  useGetGrantById,
  useGetParticipantViewDetails,
  useGetPlanById,
  useGetTaxCityOptions,
  useGetTaxCountryOptions,
  useGetTaxJuridictionLevel,
  useGetTaxPeriodVendor,
  useGetTaxStateOptions,
  useGetTransactionById,
  useWealthlaneAwardApi,
  useWealthlaneCustomerApi,
} from '../../../../hooks';
import InitialValuesForm from '../../../../constants/initialValues';
import { OptionType } from '../../../../types/common';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import {
  TaxBracketAccordingContainer,
  TaxBracketAccordingValue,
  TaxBracketHeaders,
  TaxBracketValue,
  ViewData,
} from '../tax-setup/taxSetupStyled';
import Tag from '../../../../components/lozenge/tags';
import Heading from '../../../../components/typography';
import { COLORS } from '../../../../constants/style/color';
import { UserDefinedCriteriaComponent } from '../tax-setup/tax-block';
import { motion } from 'framer-motion';
import InputText from '../../../../components/input-text/inputText';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants/common';
import { DescriptionType } from '../tax-setup/taxConstantTypes';

interface TaxLibraryViewType {
  viewData?: any;
}

const TaxLibraryView = ({ viewData }: TaxLibraryViewType) => {
  const { handleRequest } = useWealthlaneTaxApi();
  const { handleRequest: participantRequest } = useWealthlaneCustomerApi();
  const { data: juridictionLevelOptions } = useGetTaxJuridictionLevel(handleRequest);
  const [descriptionData, setDescriptionData] = useState<DescriptionType[]>([]);

  const { handleRequest: awardRequest } = useWealthlaneAwardApi();
  const [metaValidation, setMetaValidation] = useState<
    Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | undefined
  >();
  const [hide, setHide] = useState<boolean>(false);
  const optionState = {
    countryId: '',
    stateId: '',
  };

  const initialData = {
    ...viewData,
    taxBrackets: viewData?.taxBrackets?.map((item: any) => ({
      lowerLimit: item?.lowerLimit,
      taxRate: item?.taxRate === 0 ? '0' : item?.taxRate,
      upperLimit: item?.lowerLimit,
    })),
  };
  const [stateArrayOptions, setStateArrayOptions] = useState<any>(null);
  const [cityArrayOptions, setCityArrayOptions] = useState<any>(null);
  const [optionSelectedState, setOptionSelectedState] =
    useState<Record<string, string>>(optionState);

  const { data: countryOptions } = useGetTaxCountryOptions(handleRequest);
  const { data: stateOptions } = useGetTaxStateOptions(
    handleRequest,
    optionSelectedState?.countryId !== '' ? optionSelectedState?.countryId : viewData?.countryId
  );
  const { data: cityOptions } = useGetTaxCityOptions(
    handleRequest,
    optionSelectedState?.stateId !== '' ? optionSelectedState?.stateId : viewData?.stateId
  );

  return (
    <Formik enableReinitialize initialValues={initialData} onSubmit={(values) => {}}>
      {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }) => {
        const metaData: Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | undefined =
          juridictionLevelOptions?.filter(
            (item: OptionType) => item?.value === values?.jurisdictionLevelId
          )[0]?.metadata;

        useEffect(() => {
          setMetaValidation(metaData);
        }, [metaData]);

        return (
          <Form>
            {/* <ModalBodyComponent> */}
            <ViewData>
              <Tag variant='info' title={'Tax Item Details'} color={COLORS.Gray} />
              <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-5 gap-5'>
                {viewData?.name != null && (
                  <div>
                    <Heading variant='body-xs' title={'Name'} />
                    <Heading
                      variant='h6'
                      title={`${viewData?.name != null ? viewData?.name : '-'}`}
                    />
                  </div>
                )}
                {viewData?.taxPeriodName != null && (
                  <div>
                    <Heading variant='body-xs' title={'Tax Period'} color={COLORS.Gray} />
                    <Heading variant='h6' title={`${viewData?.taxPeriodName}`} />
                  </div>
                )}
                {viewData?.jurisdictionName != null && (
                  <div>
                    <Heading variant='body-xs' title={'Jurisdiction'} color={COLORS.Gray} />
                    <Heading
                      variant='h6'
                      title={`${
                        viewData?.jurisdictionName !== '' ? viewData?.jurisdictionName : '-'
                      }`}
                    />
                  </div>
                )}
                {viewData?.statusDisplayName != null && (
                  <div>
                    <Heading variant='body-xs' title={'Status'} color={COLORS.Gray} />
                    <Heading variant='h6' title={`${viewData?.statusDisplayName}`} />
                  </div>
                )}
              </div>
            </ViewData>
            {viewData?.countryName != null && (
              <ViewData>
                <Tag variant='info' title={'Jurisdiction'} />
                <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-5'>
                  <div>
                    <Heading variant='body-xs' title={'Country'} color={COLORS.Gray} />
                    <Heading variant='h6' title={viewData?.countryName} />
                  </div>
                  {viewData?.stateName && (
                    <div>
                      <Heading variant='body-xs' title={'State'} color={COLORS.Gray} />
                      <Heading variant='h6' title={viewData?.stateName} />
                    </div>
                  )}
                  {viewData?.cityName && (
                    <div>
                      <Heading variant='body-xs' title={'City'} color={COLORS.Gray} />
                      <Heading variant='h6' title={viewData?.cityName} />
                    </div>
                  )}
                </div>
              </ViewData>
            )}
            {(metaData?.allowUserDefinedCriteria ?? false) && (
              <UserDefinedCriteriaComponent
                handleRequest={handleRequest}
                metaData={metaData}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                modalStatus={'View'}
                jurisdictionOptions={juridictionLevelOptions}
                setDescriptionData={setDescriptionData}
                descriptionData={descriptionData}
              />
            )}
            <ViewData>
              <Tag variant='info' title={'Tax Bracket'} />
              <TaxBracketAccordingContainer className='flex justify-between mt-5'>
                <Heading variant='body' title={'Tax Bracket'} />
                <div
                  className='flex'
                  onClick={() => {
                    setHide(!hide);
                  }}>
                  {!hide ? (
                    <>
                      {/* <Heading variant='body-s' title={'Hide'} /> */}
                      <span className='material-symbols-outlined image-icon'>expand_less</span>
                    </>
                  ) : (
                    <>
                      {/* <Heading variant='body-s' title={'Show'} /> */}
                      <span className='material-symbols-outlined image-icon'>expand_more</span>
                    </>
                  )}
                </div>
              </TaxBracketAccordingContainer>
              <FieldArray
                name='taxBracket'
                render={(arrayHelpers) => {
                  return (
                    <>
                      {!hide && (
                        <motion.div
                          initial={{ y: -2, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{
                            delay: 0.5,
                            duration: 0.3,
                          }}>
                          <TaxBracketAccordingValue>
                            <motion.div initial={{ y: 10 }} animate={{ y: 0 }}>
                              <TaxBracketHeaders className='grid grid-cols-12 gap-x-5'>
                                <div className='col-span-4'>
                                  <Heading
                                    variant='body-xs'
                                    title={'Tax Rate (%)'}
                                    color={COLORS.Gray}
                                  />
                                </div>
                                <div className='col-span-4'>
                                  <Heading
                                    variant='body-xs'
                                    title={'Lower Bracket Limit ($)'}
                                    color={COLORS.Gray}
                                  />
                                </div>
                                <div className='col-span-4'>
                                  <Heading
                                    variant='body-xs'
                                    title={'Upper Bracket Limit ($)'}
                                    color={COLORS.Gray}
                                  />
                                </div>
                              </TaxBracketHeaders>
                              <div>
                                {values?.taxBrackets != null &&
                                  values?.taxBrackets.length > 0 &&
                                  values?.taxBrackets?.map((taxBracket: any, index: number) => (
                                    <TaxBracketValue
                                      key={'1'}
                                      className='grid lg:grid-cols-12 md:grid-cols-4 grid-cols-1 items-start gap-x-5'>
                                      <div className='col-span-4'>
                                        <div className='grid lg:grid-cols-2 grid-cols-1'>
                                          <InputText
                                            type='text'
                                            name={`taxBracket[${index}].taxRate`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            value={taxBracket?.taxRate}
                                            disabled
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className='col-span-4'>
                                        <div className=' grid lg:grid-cols-3 grid-cols-1'>
                                          <InputText
                                            className='col-span-2'
                                            type='text'
                                            name={`taxBracket[${index}].lowerBracketLimit`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            value={taxBracket?.lowerLimit}
                                            disabled
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className='col-span-4'>
                                        <div className=' grid lg:grid-cols-3 grid-cols-1'>
                                          <InputText
                                            className=' col-span-2'
                                            type='text'
                                            name={`taxBracket[${index}].upperBracketLimit`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            value={taxBracket?.upperLimit}
                                            disabled
                                            required
                                          />
                                        </div>
                                      </div>
                                    </TaxBracketValue>
                                  ))}
                              </div>
                            </motion.div>
                          </TaxBracketAccordingValue>
                        </motion.div>
                      )}
                    </>
                  );
                }}
              />
            </ViewData>
            <ViewData className='border-0'>
              <Tag variant='info' title={'Applicable'} />
              <div className='grid grid-cols-4  gap-5 mt-5'>
                {viewData?.equityCompensationLevelName != null && (
                  <div>
                    <Heading variant='body-xs' title={'Applicable To'} color={COLORS.Gray} />
                    <Heading variant='h6' title={`${viewData?.equityCompensationLevelName}`} />
                  </div>
                )}
              </div>
            </ViewData>
            {/* </ModalBodyComponent> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default TaxLibraryView;
