import { type UseQueryResult, useQuery } from 'react-query';
import { GrantService } from '../../services/wealthlane-award-services';

const useGetAllGrantsList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const allGrantsList = useQuery(
    ['getAllGrantsList'],
    async () => {
      return await handleRequest(GrantService.getApiAwardApiAppGrantGrantAllList());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label:
              item.grantName != null && item.uniqueGrantNo != null
                ? `${item.grantName} - ${item.uniqueGrantNo}`
                : '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return allGrantsList;
};

export default useGetAllGrantsList;
