import { type UseQueryResult, useQuery } from 'react-query';
import { type OptionType } from '../../types/common';
import { TaskTypeService } from '../../services/wealthlane-task-manager-services';

const useGetTmcTaskType = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  OptionType[] | undefined,
  unknown
> => {
  const getTmcTaskType = useQuery(
    ['getTmcTaskType'],
    async () => {
      return await handleRequest(TaskTypeService.getApiTaskmanagerApiAppTaskTypeTaskTypeList());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.taskTypeDisplayName ?? '',
            value: item.taskTypeId ?? '',
          };
        });
      },
    }
  );

  return getTmcTaskType;
};

export default useGetTmcTaskType;
