/* eslint-disable no-useless-escape */
import {
  DigitsOnlyMessage,
  RequiredMessage,
  NotBlankMessage,
  MaxCharMessage,
  EmailMessage,
  AlphanumericMessage,
} from '../../constants/validationMessage';
import * as Yup from 'yup';

export const regExp = {
  text: /^[a-zA-Z]+$/,
  decimal: /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
  // decimal: /^\d*(\.\d{0,2})?$/,
  decimalFour: /^\d*(\.\d{0,4})?$/,
  textWithSpace: /^[A-Za-z\s]+$/,
  textWithoutSpace: /^\s*\S[\s\S]*$/,
  textWithNumber: /^[a-zA-Z0-9]+$/,
  textWithNumberAndDot: /^[a-zA-Z0-9 .,]*$/,
  textWithHyphenAndApostrophe: /^[a-zA-Z-'']+$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  contact: /^(98|97)([0-9]{8})$/,
  digits: /^[0-9][0-9]*$/,
  digitsWithComma: /^[0-9,]*$/,
  negativeDigits: /^[0-9]*$/,
  floatDigits: /^[0-9.,]*$/,
  numericPhone: /^[0-9-][0-9-]*$/,
  number: /^[0-9][0-9]*$/,
  numberWithSpecialCharacter: /^[0-9#$%^&*()@!-/.,]*$/,
  landline: /^01[0-9]+$/,
  textWithNumberWithoutZeroLeading: /^[a-zA-Z1-9_ ][a-zA-Z0-9_ ]*$/,
  phone: /^((\\([0-9]{3}\\)[ \\-]*)|([0-9]{3})[ \\-]*)*?[0-9]{4}?$/,
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm,
};

interface commonValidationType {
  required: boolean;
  decimal?: boolean;
  decimalFour?: boolean;
  email?: boolean;
  noBlank?: boolean;
  alphanumeric?: boolean;
  alphanumericDot?: boolean;
  digits?: boolean;
  digitsWithComma?: boolean;
  negativeDigits?: boolean;
  floatDigits?: boolean;
  numericPhone?: boolean;
  textLimit?: number;
  min?: number;
  minValue?: number;
  max?: number;
  isNumber?: boolean;
  isBoolean?: boolean;
  isDate?: boolean;
  doubleSpace?: boolean;
  textWithHyphen?: boolean;
  floatValue?: number;
}

export const commonValidation = ({
  isNumber = false,
  isBoolean = false,
  isDate = false,
  required,
  decimal,
  decimalFour,
  email,
  noBlank,
  minValue,
  alphanumeric,
  alphanumericDot,
  numericPhone,
  negativeDigits,
  digits,
  digitsWithComma,
  floatDigits,
  textLimit,
  min,
  max,
  doubleSpace = false,
  textWithHyphen = false,
  floatValue,
}: commonValidationType): any => {
  let textYup: any = Yup.string();

  if (isNumber) {
    textYup = Yup.number();
  }
  if (isDate) {
    textYup = Yup.date().typeError('Required field');
  }

  if (isBoolean) {
    textYup = Yup.boolean();
  }

  if (textLimit != null) {
    textYup = textYup.max(textLimit, MaxCharMessage(textLimit));
  }

  if (required) {
    textYup = textYup.required(RequiredMessage);
  }

  if (min != null) {
    textYup =
      minValue != null
        ? textYup.min(
            min,
            minValue !== 0 ? `Must be ${minValue} digits` : `Must be greater than ${min - 1}`
          )
        : textYup.min(min, `Must be greater than ${min - 1}`);
  }

  if (max != null) {
    textYup = textYup.max(max, `Must be ${max} digits`);
  }

  if (email ?? false) {
    textYup = textYup.email(EmailMessage);
  }

  if (alphanumeric ?? false) {
    textYup = textYup.matches(regExp.textWithNumber, AlphanumericMessage);
  }
  if (alphanumericDot ?? false) {
    textYup = textYup.matches(regExp.textWithNumberAndDot, AlphanumericMessage);
  }

  if (digits ?? false) {
    textYup = textYup.matches(regExp.digits, DigitsOnlyMessage);
  }
  if (negativeDigits ?? false) {
    textYup = textYup.matches(regExp.negativeDigits, 'Must be whole number');
  }
  if (decimal) {
    textYup = textYup.matches(regExp.decimal, 'Must be 2 decimal place only');
  }

  if (digitsWithComma ?? false) {
    textYup = textYup.matches(regExp.digitsWithComma, DigitsOnlyMessage);
  }

  if (floatDigits ?? false) {
    textYup = Yup.string()
      .test('float', function (value: any) {
        const splitValue = Boolean(value) && value.split('.');
        if (Boolean(value?.includes('.')) && splitValue[1].length > 2) {
          return this.createError({
            path: this.path,
            message: `Must be  2 decimal place only`,
          });
        } else {
          return true;
        }
      })
      .concat(textYup);
    // textYup.matches(regExp.floatDigits, DigitsOnlyMessage);
  }

  if (numericPhone ?? false) {
    textYup = textYup.matches(regExp.numericPhone, DigitsOnlyMessage);
  }
  if (noBlank ?? false) {
    textYup = textYup.matches(regExp.textWithoutSpace, NotBlankMessage);
  }

  if (doubleSpace) {
    textYup = Yup.string()
      .test('double-space', function (value) {
        const validation: boolean = (value?.includes('  ') ?? false) || false;
        if (validation) {
          return this.createError({
            path: this.path,
            message: 'Must not contain double space ',
          });
        } else {
          return true;
        }
      })
      .concat(textYup);
  }

  if (textWithHyphen ?? false) {
    textYup = textYup.matches(regExp.textWithHyphenAndApostrophe, 'Invalid ${label}');
  }

  return textYup.nullable();
};
