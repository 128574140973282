export const NotificationRouteList = [
  {
    title: 'System User Approval',
    url: '/task/user-approval',
  },
  {
    title: 'System User',
    url: '/settings/organization-settings/users',
  },
  {
    title: 'Dashboard',
    url: '/overview',
  },
  {
    title: 'Plan Approval',
    url: '/task/plan-approval',
  },
  {
    title: 'Performance Rule Approver',
    url: '/task/performance-rule-approval',
  },
  {
    title: 'Plan',
    url: '/settings/organization-settings/plan-management',
  },
  {
    title: 'Performance Rule',
    url: '/settings/organization-settings/performance-rule',
  },
  {
    title: 'Participant Approval',
    url: '/task/participants-approval',
  },
  {
    title: 'Participant',
    url: '/participants',
  },
  {
    title: 'RSU Approval',
    url: '/task/rsu-awards-approval',
  },
  {
    title: 'PSU Approval',
    url: '/task/psu-awards-approval',
  },
  {
    title: 'RSU',
    url: '/awards/rsu-awards',
  },
  {
    title: 'PSU',
    url: '/awards/psu-awards',
  },
  {
    title: 'Option Approval',
    url: '/task/option-awards-approval',
  },
  {
    title: 'Option',
    url: '/awards/option-awards',
  },
  {
    title: 'Pre-Clearance Request',
    url: '/task/pre-clearance-requests',
  },
  {
    title: 'Tax Approval',
    url: '/task/tax-setup-approval',
  },
];
