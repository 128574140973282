import { FieldArray, Form, Formik } from 'formik';
import { ModalStatusType } from '../../../types/common';
import ModalComponent from '../../../components/modal/modal';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import InputText from '../../../components/input-text/inputText';
import FormSelect from '../../../components/dropdown/formSelect';
import CustomTextArea from '../../../components/text-area/textArea';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import { YesNoOptions } from '../../../constants/common';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
import Heading from '../../../components/typography';
import { COLORS } from '../../../constants/style/color';
import Tag from '../../../components/lozenge/tags';
import CustomDatePicker from '../../../components/date-picker/customDatePicker';
import { FormDivider } from '../system-roles/roles.styled';
import Button from '../../../components/button';
import {
  EstimationTrackingAccordingValue,
  EstimationTrackingHeaders,
  EstimationTrackingValue,
} from './performanceRuleStyled';
import InitialValuesForm from '../../../constants/initialValues';
import { useEffect, useState } from 'react';
import {
  useGetPerformanceRuleById,
  useGetPerformanceRuleTypes,
  usePostPerformanceRules,
  useWealthlaneAwardApi,
} from '../../../hooks';
import _, { isEmpty } from 'lodash';
import { PERFORMANCE_RULE_VALIDATION } from '../../../utils/validations/validations';
import { CommaFormatted } from '../../../utils/global';

interface PerformanceRuleType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  initialData: {
    data: any;
    id: string;
  };
  rowData?: any;
  id?: string;
}

const PerformanceRulesModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  initialData,
  setModalStatus,
  rowData,
  id,
}: PerformanceRuleType): JSX.Element => {
  const { handleRequest } = useWealthlaneAwardApi();
  const [deleted, setDeleted] = useState<boolean>(false);
  const { data: performanceRuleTypes, isLoading: performanceRuleTypeLoading } =
    useGetPerformanceRuleTypes(handleRequest);

  const { mutate, isLoading } = usePostPerformanceRules(
    handleRequest,
    closeModal,
    rowData?.id,
    id,
    setModalStatus
  );

  const handleSubmit = (values: any): void => {
    const payload = {
      ...values,
      minimumPercentage: Number(String(values?.minimumPercentage)?.replaceAll(',', '') ?? ''),
      maximumPercentage: Number(String(values?.maximumPercentage)?.replaceAll(',', '') ?? ''),
      estimatePerformanceTrackings:
        values?.estimatePerformanceTrackings?.length > 0
          ? values?.estimatePerformanceTrackings?.map((item: any) => ({
              trackingDate: item?.trackingDate ?? '',
              multiplierPercentage: Number(
                String(item?.multiplierPercentage)?.replaceAll(',', '') ?? ''
              ),
              comment: item?.comment ?? '',
            }))
          : [],

      performanceEvaluation:
        values?.performanceEvaluation?.earnedMultipier &&
        values?.performanceEvaluation?.evaluationDate
          ? {
              earnedMultipier: Number(
                String(values?.performanceEvaluation?.earnedMultipier)?.replaceAll(',', '') ?? ''
              ),
              evaluationDate: values?.performanceEvaluation?.evaluationDate ?? '',
            }
          : null,
    };

    mutate(payload as any);
  };

  const { data: performanceRuleById } = useGetPerformanceRuleById(handleRequest, rowData?.id || id);

  const initialValue = {
    ...performanceRuleById,
    minimumPercentage: performanceRuleById?.minimumPercentage,
    maximumPercentage: performanceRuleById?.maximumPercentage,
    estimatePerformanceTrackings: _.orderBy(
      performanceRuleById?.estimatePerformanceTrackings,
      ['trackingDate'],
      ['asc']
    )?.map((item: any) => ({
      trackingDate: item?.trackingDate ?? '',
      multiplierPercentage: item?.multiplierPercentage,
      comment: item?.comment ?? '',
      minValue: performanceRuleById?.minimumPercentage,
      maxValue: performanceRuleById?.maximumPercentage,
    })),
    performanceEvaluation: !isEmpty(
      performanceRuleById?.performanceEvaluation?.performanceEvaluationId
    )
      ? {
          earnedMultipier: performanceRuleById?.performanceEvaluation?.earnedMultipier,
          evaluationDate: performanceRuleById?.performanceEvaluation?.evaluationDate ?? null,
          minValue: performanceRuleById?.minimumPercentage,
          maxValue: performanceRuleById?.maximumPercentage,
        }
      : null,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValue && modalStatus !== 'Add' ? initialValue : initialData?.data}
      validationSchema={PERFORMANCE_RULE_VALIDATION}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({
        touched,
        handleChange,
        errors,
        setFieldValue,
        setFieldTouched,
        values,
        setTouched,
      }: any) => {
        return (
          <>
            <ModalComponent
              show={isModalOpen}
              closeModal={closeModal}
              loading={false}
              size='lg'
              valueEdited={_.isEqual(
                values,
                modalStatus !== 'Add' ? initialValue : initialData?.data
              )}
              customAlertMessage={'Your changes will not be saved. Are you sure you want to close?'}
              title={`${modalStatus} Performance Rule`}
              children={
                <Form>
                  <ModalBodyComponent>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-x-5'>
                      <div className='col-span-12 sm:col-span-2 lg:col-span-8'>
                        <InputText
                          type='text'
                          label='Rule Name'
                          name='ruleName'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                      <div className='col-span-1 sm:col-span-4 '>
                        <FormSelect
                          type='text'
                          label='Rule Type'
                          name='ruleTypeId'
                          viewName='ruleTypeId'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={performanceRuleTypes}
                          values={values}
                          setFieldValue={setFieldValue}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                    </div>
                    <CustomCheckBox
                      name='isAvailable'
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      errors={errors}
                      touched={touched}
                      className={'individual-check'}
                      labelClassName={'whitespace-normal'}
                      disabled={modalStatus === 'View'}
                      checked={values?.isAvailable}
                      id='acceptCheck'
                      label={<Heading variant='body-xs' className='mt-1' title={'Available'} />}
                      required
                    />
                    <div className='mt-8'>
                      <Tag variant='info' title={'Evaluation Period'} />
                      <div className='flex gap-x-8 mt-4'>
                        <CustomDatePicker
                          label='Start Date'
                          name={`evaluationPeriodStartDate`}
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          values={values}
                          errorValue={errors?.evaluationPeriodStartDate}
                          touchedValue={touched?.evaluationPeriodStartDate}
                          defaultValue={new Date()}
                          disabled={modalStatus === 'View'}
                          fieldLabelName='test'
                          required
                        />
                        <CustomDatePicker
                          label='End Date'
                          name='evaluationPeriodEndDate'
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          errorValue={errors?.evaluationPeriodEndDate}
                          touchedValue={touched?.evaluationPeriodEndDate}
                          values={values}
                          min={values?.evaluationPeriodStartDate}
                          defaultValue={new Date()}
                          disabled={modalStatus === 'View'}
                          fieldLabelName='test'
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <Tag variant='info' title={'Multiplier Range'} />
                      <div className='grid grid-cols-3 gap-8 mt-4'>
                        <InputText
                          type='text'
                          label='Minimum %'
                          name='minimumPercentage'
                          onChange={(e: any) => {
                            setFieldValue('maximumPercentage', '');
                            handleChange(e);
                          }}
                          onBlur={(e: any) => {
                            setFieldTouched(`minimumPercentage`, true);
                          }}
                          errors={errors}
                          touched={touched}
                          errorValue={errors?.minimumPercentage}
                          touchedValue={touched?.minimumPercentage}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                        <InputText
                          type='text'
                          label='Maximum %'
                          name='maximumPercentage'
                          onChange={(e: any) => {
                            handleChange(e);
                          }}
                          onBlur={(e: any) => {
                            setFieldTouched(`maximumPercentage`, true);
                          }}
                          errors={errors}
                          touched={touched}
                          errorValue={errors?.maximumPercentage}
                          touchedValue={touched?.maximumPercentage}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <CustomTextArea
                        label='Rule'
                        name='ruleDescription'
                        onChange={handleChange}
                        errors={errors}
                        inputType={modalStatus}
                        disabled={modalStatus === 'View'}
                        touched={touched}
                        rows='3'
                        required
                      />
                      <CustomTextArea
                        label='Rule Display'
                        name='ruleSummary'
                        onChange={handleChange}
                        errors={errors}
                        inputType={modalStatus}
                        disabled={modalStatus === 'View'}
                        touched={touched}
                        rows='3'
                        required
                      />
                    </div>
                    <CustomCheckBox
                      name='showRuleDisplayToParticipant'
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      errors={errors}
                      touched={touched}
                      className={'individual-check'}
                      labelClassName={'whitespace-normal'}
                      disabled={modalStatus === 'View'}
                      checked={values?.showRuleDisplayToParticipant}
                      id='acceptCheck'
                      label={
                        <Heading variant='body-xs' className='mt-1' title={'Show to participant'} />
                      }
                      required
                    />
                    <FormDivider />
                    <FieldArray
                      name='estimatePerformanceTrackings'
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            <Heading
                              variant='h5'
                              className='mt-1'
                              color={COLORS.Gray}
                              title={'Estimate Performance Tracking'}
                            />
                            <div className='my-8'>
                              <Button
                                title={'Add Tracking'}
                                type='button'
                                variant='primary-outline'
                                icon='add_circle'
                                onClick={() => {
                                  arrayHelpers.push({
                                    trackingDate: '',
                                    multiplierPercentage: '',
                                    comment: '',
                                  });
                                  setTouched({
                                    ...touched,
                                    estimatePerformanceTrackings: [
                                      ...touched?.estimatePerformanceTrackings,
                                      {
                                        trackingDate: true,
                                        multiplierPercentage: true,
                                        comment: true,
                                      },
                                    ],
                                  });
                                }}
                                disabled={modalStatus === 'View'}
                              />
                            </div>
                            {values.estimatePerformanceTrackings?.length > 0 && (
                              <div>
                                <CustomCheckBox
                                  name='showPerformanceTrackingToParticipant'
                                  onChange={(e: any) => {
                                    handleChange(e);
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  className={'individual-check'}
                                  labelClassName={'whitespace-normal'}
                                  disabled={modalStatus === 'View'}
                                  checked={values?.showPerformanceTrackingToParticipant}
                                  id='acceptCheck'
                                  label={
                                    <Heading
                                      variant='body-xs'
                                      className='mt-1'
                                      //   color={COLORS.Gray}
                                      title={'Show to participant'}
                                    />
                                  }
                                  required
                                />
                                <div className='mt-8'>
                                  <>
                                    <EstimationTrackingAccordingValue
                                      array={values?.estimatePerformanceTrackings?.length}>
                                      <EstimationTrackingHeaders className='grid grid-cols-12 gap-x-5'>
                                        <div className='col-span-4'>
                                          <Heading
                                            variant='body-xs'
                                            title={'Date'}
                                            color={COLORS.Gray}
                                          />
                                        </div>
                                        <div className='col-span-3'>
                                          <Heading
                                            variant='body-xs'
                                            title={'Multiplier (%)'}
                                            color={COLORS.Gray}
                                          />
                                        </div>
                                        <div className='col-span-4'>
                                          <Heading
                                            variant='body-xs'
                                            title={'Comment'}
                                            color={COLORS.Gray}
                                          />
                                        </div>
                                      </EstimationTrackingHeaders>

                                      <div>
                                        {values?.estimatePerformanceTrackings &&
                                          values?.estimatePerformanceTrackings?.length > 0 &&
                                          values?.estimatePerformanceTrackings?.map(
                                            (
                                              detail: Record<string, number | string>,
                                              index: number
                                            ) => {
                                              return (
                                                <EstimationTrackingValue
                                                  className='grid lg:grid-cols-12 sm:grid-cols-1 items-start gap-x-5'
                                                  key={index}>
                                                  <div className='col-span-4'>
                                                    <CustomDatePicker
                                                      // label='Start Date'
                                                      arrValue={
                                                        values?.estimatePerformanceTrackings?.[
                                                          index
                                                        ]?.trackingDate
                                                      }
                                                      name={`estimatePerformanceTrackings[${index}].trackingDate`}
                                                      onChange={handleChange}
                                                      setFieldValue={setFieldValue}
                                                      setFieldTouched={setFieldTouched}
                                                      errors={errors}
                                                      touched={touched}
                                                      values={values}
                                                      errorValue={
                                                        errors?.estimatePerformanceTrackings?.[
                                                          index
                                                        ]?.trackingDate
                                                      }
                                                      touchedValue={
                                                        touched?.estimatePerformanceTrackings?.[
                                                          index
                                                        ]?.trackingDate
                                                      }
                                                      min={values?.evaluationPeriodStartDate}
                                                      max={values?.evaluationPeriodEndDate}
                                                      defaultValue={
                                                        values?.evaluationPeriodStartDate
                                                      }
                                                      disabled={modalStatus === 'View'}
                                                      fieldLabelName='test'
                                                    />
                                                  </div>
                                                  <div className='col-span-3'>
                                                    <InputText
                                                      type='text'
                                                      name={`estimatePerformanceTrackings[${index}].multiplierPercentage`}
                                                      onChange={(e: any) => {
                                                        setFieldValue(
                                                          `estimatePerformanceTrackings[${index}].minValue`,
                                                          values?.minimumPercentage
                                                        );
                                                        setFieldValue(
                                                          `estimatePerformanceTrackings[${index}].maxValue`,
                                                          values?.maximumPercentage
                                                        );

                                                        handleChange(e);
                                                      }}
                                                      onBlur={(e: any) => {
                                                        setFieldTouched(
                                                          `estimatePerformanceTrackings[${index}].multiplierPercentage`,
                                                          true
                                                        );
                                                      }}
                                                      errorValue={
                                                        errors?.estimatePerformanceTrackings?.[
                                                          index
                                                        ]?.multiplierPercentage
                                                      }
                                                      touchedValue={
                                                        touched?.estimatePerformanceTrackings?.[
                                                          index
                                                        ]?.multiplierPercentage
                                                      }
                                                      disabled={modalStatus === 'View'}
                                                    />
                                                  </div>
                                                  <div className='col-span-4'>
                                                    <InputText
                                                      type='text'
                                                      name={`estimatePerformanceTrackings[${index}].comment`}
                                                      onChange={(e: any) => {
                                                        handleChange(e);
                                                      }}
                                                      disabled={modalStatus === 'View'}
                                                      errors={errors}
                                                      touched={touched}
                                                    />
                                                  </div>
                                                  {modalStatus !== 'View' && (
                                                    <div>
                                                      <span
                                                        className='material-symbols-outlined text-red-600 mt-3 cursor-pointer'
                                                        onClick={() => {
                                                          arrayHelpers.remove(index);
                                                          setDeleted(true);
                                                        }}>
                                                        delete
                                                      </span>
                                                    </div>
                                                  )}
                                                </EstimationTrackingValue>
                                              );
                                            }
                                          )}
                                      </div>
                                    </EstimationTrackingAccordingValue>
                                  </>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                    <div className='grid grid-cols-2 gap-8 md:grid-cols-12 mt-8'>
                      <div className='col-span-4'>
                        <CustomDatePicker
                          label='Evaluation Date'
                          arrValue={values?.performanceEvaluation?.evaluationDate}
                          name='performanceEvaluation.evaluationDate'
                          onChange={(e: any) => {
                            if (values?.performanceEvaluation.evaluationDate) {
                              null;
                            } else {
                              setFieldValue(`performanceEvaluation.earnedMultipier`, '');
                            }

                            handleChange(e);
                          }}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          values={values}
                          errorValue={errors?.performanceEvaluation?.evaluationDate}
                          touchedValue={touched?.performanceEvaluation?.evaluationDate}
                          min={values?.evaluationPeriodStartDate}
                          max={values?.evaluationPeriodEndDate}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                      <div className='col-span-3'>
                        <InputText
                          type='text'
                          label='Earned Multiplier %'
                          name='performanceEvaluation.earnedMultipier'
                          onChange={(e: any) => {
                            setFieldValue(
                              `performanceEvaluation.minValue`,
                              values?.minimumPercentage
                            );
                            setFieldValue(
                              `performanceEvaluation.maxValue`,
                              values?.maximumPercentage
                            );
                            if (values?.performanceEvaluation.evaluationDate) {
                              null;
                            } else {
                              setFieldValue(`performanceEvaluation.evaluationDate`, '');
                            }
                            handleChange(e);
                          }}
                          onBlur={(e: any) => {
                            setFieldTouched(`performanceEvaluation.earnedMultipier`, true);
                          }}
                          errors={errors}
                          touched={touched}
                          errorValue={errors?.performanceEvaluation?.earnedMultipier}
                          touchedValue={touched?.performanceEvaluation?.earnedMultipier}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                    </div>
                  </ModalBodyComponent>
                  <ModalFooterComponent
                    modalStatus={modalStatus}
                    loading={isLoading}
                    isEditShow={rowData?.approvalStatus !== 'Rejected' ? true : false}
                    isTmc={modalStatus === 'View' && id ? true : false}
                    onCloseModal={() => {
                      closeModal();
                    }}
                    valueChanged={_.isEqual(values, initialValue || initialData?.data)}
                    onEditModal={() => {
                      setModalStatus('Edit');
                    }}
                    customAlertMessage={
                      'Your changes will not be saved. Are you sure you want to close?'
                    }
                  />
                </Form>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};

export default PerformanceRulesModal;
