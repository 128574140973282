import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';
import { rem } from '../../../utils/style';

export const TaxSettlementContainerHeading = styled.div`
  margin-bottom: ${rem(8)};
`;

export const TaxSettlementContainer = styled.div`
  background: ${COLORS.White};
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};
  padding: ${rem(18)} ${rem(18)} ${rem(32)} ${rem(18)};
  margin-bottom: ${rem(62)};

  .finch-connected--icon {
    font-size: 13px;
    color: ${COLORS.Green};
  }

  .disconnect-button {
    /* text-decoration: underline; */
    color: ${COLORS.Green};
    /* cursor: pointer; */

    /* &:hover {
      color: rgb(56, 77, 214);
    } */
  }
`;
