import { useQuery } from 'react-query';
import { TradePreClearanceService } from '../../services/wealthlane-customer-services';
import { type TableParamsType } from '../../types/common';

const useGetTradePreClearanceList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): any => {
  const { departmentId, jobTitleId, employeeTypeId, filter, sorting, skipCount, maxResultCount } =
    tableInfo;
  const tradePreClearanceList = useQuery(
    [
      'getTradePreClearanceList',
      departmentId,
      jobTitleId,
      employeeTypeId,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      const tradePreClearanceData = await handleRequest(
        TradePreClearanceService.getApiCustomerApiAppTradePreClearanceTradePreClearanceList(
          departmentId,
          jobTitleId,
          employeeTypeId,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
      const filteredTradePreClearanceList = tradePreClearanceData?.items?.map((item) => ({
        ...item,
        id: item.participantId,
      }));
      return { ...tradePreClearanceData, items: filteredTradePreClearanceList };
    }
  );
  return tradePreClearanceList;
};

export default useGetTradePreClearanceList;
