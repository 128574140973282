import { type ReactNode, useState, useEffect } from 'react';
import { AlertPopupContainer } from './alertPopup.styled';

import Heading from '../typography';
import Button from '../button';
import { Transition } from 'react-transition-group';
import { FormWrapper } from '../input-text/input.styled';
import { isEmpty } from 'lodash';
import { COLORS } from '../../constants/style/color';

interface AlertPopupType {
  isShow: boolean;
  closeModal?: () => void;
  handleSubmit?: any;
  title?: string | ReactNode;
  ConfirmTitle?: string | ReactNode;
  modalStatus?: string | ReactNode;
  setRemark?: any;
  remark?: any;
  loading?: boolean;
  customTitle?: string | ReactNode;
  width?: string;
  display?: boolean;
  dynamicReport?: boolean;
  icon?: boolean;
  taskRefrenceNo?: string;
  subTitle?: string;
}

const AlertPopup = ({
  isShow,
  title,
  ConfirmTitle,
  modalStatus,
  closeModal,
  handleSubmit,
  remark,
  setRemark,
  loading,
  customTitle,
  width,
  display = true,
  dynamicReport = false,
  icon = true,
  taskRefrenceNo,
  subTitle,
}: AlertPopupType): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isShow) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  }, [isShow]);

  return (
    <Transition in={true} timeout={250}>
      {(state) => (
        <AlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className='popupDialog'>
                <div className='popupContent'>
                  <Heading
                    variant='h5'
                    className='mb-5'
                    title={
                      customTitle != null
                        ? customTitle
                        : modalStatus === 'View Remarks'
                        ? 'Rejection Reason'
                        : `Are you sure you want to ${
                            modalStatus === 'Approval'
                              ? 'approve'
                              : modalStatus === 'Reject'
                              ? 'reject'
                              : modalStatus === 'Delete'
                              ? 'delete'
                              : modalStatus === 'Deactivate'
                              ? 'deactivate'
                              : modalStatus === 'Cancel'
                              ? 'cancel'
                              : 'confirm'
                          } ${typeof title === 'string' ? title : ''}?`
                    }
                  />
                  {dynamicReport && (
                    <div className='flex justify-center'>
                      <Heading
                        variant='h5'
                        title={`Here's the task reference number ${
                          taskRefrenceNo ? taskRefrenceNo : ''
                        }`}
                        className='font-semibold'
                      />
                    </div>
                  )}
                  {subTitle && (
                    <div className='flex justify-center'>
                      <Heading variant='h6' title={subTitle} color={COLORS?.Gray} />
                    </div>
                  )}
                  {(modalStatus === 'Reject' || modalStatus === 'View Remarks') && (
                    <FormWrapper>
                      <textarea
                        name='remarks'
                        placeholder='Enter Reason'
                        className={!isEmpty(errorMessage) ? 'border-red-600' : 'reject-view'}
                        style={{ minHeight: '100px', minWidth: '430px', resize: 'none' }}
                        defaultValue={remark}
                        disabled={modalStatus === 'View Remarks'}
                        onChange={(e) => {
                          setRemark(e.target.value);
                          setErrorMessage('');
                        }}></textarea>
                      {errorMessage !== '' && (
                        <Heading variant='body-xs' title={'Required Field'} className={'mt-2'} />
                      )}
                    </FormWrapper>
                  )}
                  <div className='popupButtons'>
                    {closeModal && (
                      <Button
                        variant='primary-dark'
                        title={
                          dynamicReport
                            ? 'Keep Editing'
                            : display
                            ? modalStatus === 'View Remarks'
                              ? 'Close'
                              : 'No'
                            : 'Close'
                        }
                        onClick={closeModal}
                      />
                    )}
                    {display
                      ? modalStatus !== 'View Remarks' && (
                          <Button
                            variant='primary'
                            title={ConfirmTitle ?? 'Confirm'}
                            onClick={() => {
                              modalStatus === 'Reject'
                                ? !isEmpty(remark)
                                  ? handleSubmit()
                                  : setErrorMessage('Required Field')
                                : handleSubmit();
                            }}
                            icon={
                              icon ? (
                                <span className='material-symbols-outlined'>check_circle</span>
                              ) : (
                                ''
                              )
                            }
                            loading={loading}
                          />
                        )
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertPopupContainer>
      )}
    </Transition>
  );
};

export default AlertPopup;
