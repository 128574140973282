import React, { type PropsWithChildren, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { selectNotify } from '../../pages/auth/notify';
import { ModelBody } from './modal.styled';

interface ModalBodyComponentType {
  showFooter?: boolean;
  isNotification?: boolean;
  isCenter?: boolean;
  className?: string;
  closeModal?: any;
  subTitle?: boolean;
}

const ModalBodyComponent = ({
  className,
  showFooter = true,
  isCenter,
  children,
  closeModal,
  isNotification = false,
  subTitle,
}: PropsWithChildren<ModalBodyComponentType>): JSX.Element => {
  const showNotify = useSelector(selectNotify);
  const clicking = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: Event): void {
      const checkValue = (event?.target as HTMLElement).className?.includes('notification-modal')
        ? false
        : Boolean(clicking.current) &&
          !(clicking.current as HTMLElement).contains(event.target as Node);
      if (checkValue) {
        closeModal?.();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [clicking]);

  return (
    <motion.section
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.8 }}>
      <ModelBody
        showNotify={showNotify}
        showFooter={showFooter}
        subTitle={subTitle}
        isNotification={isNotification}
        ref={clicking}
        className={`${className ?? ''} ${
          isCenter ?? false ? 'flex justify-center items-center' : ''
        }`}>
        {children}
      </ModelBody>
    </motion.section>
  );
};

export default ModalBodyComponent;
