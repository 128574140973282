/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_Common_DropdownDto } from '../models/Wealthlane_AwardService_Dtos_Common_DropdownDto';
import type { Wealthlane_AwardService_Dtos_Common_IntDropdownDto } from '../models/Wealthlane_AwardService_Dtos_Common_IntDropdownDto';
import type { Wealthlane_AwardService_Dtos_PerformanceRule_AddPerformanceRulesDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceRule_AddPerformanceRulesDto';
import type { Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRulesByIdDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRulesByIdDto';
import type { Wealthlane_AwardService_Dtos_PerformanceRule_TogglePerformanceRuleDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceRule_TogglePerformanceRuleDto';
import type { Wealthlane_AwardService_Dtos_PerformanceRule_UpdatePerformanceRulesDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceRule_UpdatePerformanceRulesDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PerformanceRuleService {

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_DropdownDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPerformanceRulePerformanceRuleTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_DropdownDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/performance-rule/performance-rule-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_IntDropdownDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPerformanceRuleRuleAvailableStatusList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_IntDropdownDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/performance-rule/rule-available-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_IntDropdownDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPerformanceRuleRuleApprovalStatusList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_IntDropdownDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/performance-rule/rule-approval-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_IntDropdownDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPerformanceRuleRuleEarnedlStatusList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_IntDropdownDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/performance-rule/rule-earnedl-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_DropdownDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPerformanceRuleAvailablePerformanceRules(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_DropdownDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/performance-rule/available-performance-rules',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantId 
     * @returns Wealthlane_AwardService_Dtos_Common_DropdownDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPerformanceRuleAvailablePerformanceRulesForGrant(
grantId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_DropdownDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/performance-rule/available-performance-rules-for-grant/{grantId}',
            path: {
                'grantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPerformanceRulePerformanceRule(
requestBody?: Wealthlane_AwardService_Dtos_PerformanceRule_AddPerformanceRulesDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/performance-rule/performance-rule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppPerformanceRulePerformanceRule(
requestBody?: Wealthlane_AwardService_Dtos_PerformanceRule_UpdatePerformanceRulesDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/performance-rule/performance-rule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppPerformanceRulePerformanceRule(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/performance-rule/{id}/performance-rule',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPerformanceRuleTogglePerformanceRuleAvailableStatus(
requestBody?: Wealthlane_AwardService_Dtos_PerformanceRule_TogglePerformanceRuleDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/performance-rule/toggle-performance-rule-available-status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRulesByIdDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPerformanceRulePerformanceRulesById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRulesByIdDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/performance-rule/{id}/performance-rules-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param evaluationPeriodFrom 
     * @param evaluationPeriodTo 
     * @param approvalStatus 
     * @param ruleStatus 
     * @param earnedStatus 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPerformanceRulePerformanceRuleList(
evaluationPeriodFrom?: string,
evaluationPeriodTo?: string,
approvalStatus?: Array<number>,
ruleStatus?: number,
earnedStatus?: number,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/performance-rule/performance-rule-list',
            query: {
                'EvaluationPeriodFrom': evaluationPeriodFrom,
                'EvaluationPeriodTo': evaluationPeriodTo,
                'ApprovalStatus': approvalStatus,
                'RuleStatus': ruleStatus,
                'EarnedStatus': earnedStatus,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
