import { useState } from 'react';
import { useMousePositionAsTrigger } from 'react-laag';
import Button from '../../../components/button';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import PopoverModalComponent from '../../../components/modal/popoverModal';
import Search from '../../../components/search/search';
import Table from '../../../components/table';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { PageHeader } from '../../../constants/style/common.styled';
import { AdminRolesHeader } from '../../../constants/table-headers/rolesHeader';
import { useBoolean, useGetRoleList, useWealthlaneCustomerApi } from '../../../hooks';
import {
  CustomerRoleService,
  CustomerUserService,
} from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { getNonFieldLevelPermissions, getFieldLevelPermissions } from '../../../utils';
import RoleModal from './roleModal';
import RolesPopoverContent from './rolesPopoverContent';
import { SettingRoleButton } from './systemRoleStyled';
import { toast } from 'react-toastify';
import ToastComponent from '../../../components/toast';
import { useQueryClient } from 'react-query';
import RolesConfirmModal from './rolesConfirmModal';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import UserPopoverContent from './userPopoverContent';
import { ROLE } from '../../../constants/common';

export const rolesInitialValues = {
  data: InitialValuesForm.addSystemRoleForm,
  id: '',
};

interface AllUserInfoType {
  fullName: string;
  title: string;
  department: string;
}

const SystemRoles = (): JSX.Element => {
  const queryClient = useQueryClient();
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isConfirm, setConfirm] = useState(false);
  const [isAssigned, setAssigned] = useState(false);

  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [rolesInitialData, setRolesInitialData] = useState(rolesInitialValues);
  const [allPermission, setAllPermission] = useState({});
  const [allUsers, setAllUsers] = useState<AllUserInfoType[]>([]);
  const [popoverInfo, setPopoverInfo] = useState<string>('Role');

  const { handleRequest } = useWealthlaneCustomerApi();
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmModalOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);
  const { data, status } = useGetRoleList(handleRequest, tableInfo);
  const userAuthList = useSelector(selectAuthRoutesList);

  const {
    hasMousePosition, // did we get a mouse-position from the event-handler
    resetMousePosition, // reset the mouse-position to `null`, essentially closing the menu
    handleMouseEvent, // event-handler we will use below
    trigger, // information regarding positioning we can provide to `useLayer`
  } = useMousePositionAsTrigger();

  const actions = (rowData: Record<string, any>): any => [
    {
      action: 'View',
      onClick: (id: string, rowData?: any) => {
        setModalStatus('View');
        getRolesData(id, rowData?.isDefaultAdminRole);
        openModal();
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string, rowData?: any) => {
        setModalStatus('Edit');
        getRolesData(id, rowData?.isDefaultAdminRole);
        openModal();
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      loading,
      onClick: (id: string) => {
        setId(id);
        handleDeleteRoles(id);
      },
    },
  ];

  const getRolesData = (id: string, isDefaultAdmin?: boolean): void => {
    setLoading(true);
    handleRequest(
      CustomerRoleService.getApiCustomerApiAppCustomerRoleCustomerRoleByCustomerRoleId(id)
    )
      .then((data: any) => {
        if (data != null) {
          const filteredData = {
            ...data,
            isDefaultAdmin,
            permissions: (data.permissions as boolean)
              ? getNonFieldLevelPermissions(data.permissions).reduce(
                  (a: any, v: any) => ({ ...a, [v.replaceAll('.', '-')]: true }),
                  {}
                )
              : {},
            fieldLevelPermissions: (data.permissions as boolean)
              ? getFieldLevelPermissions(data.permissions).reduce(
                  (a: any, v: any) => ({ ...a, [v.replaceAll('.', '-')]: true }),
                  {}
                )
              : {},
          };

          const filterData = data.permissions.reduce((a: any, v: any) => ({ ...a, [v]: true }), {});

          setRolesInitialData({ id, data: filteredData });
          setAllPermission(filterData);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const getUserData = (id: string): void => {
    setLoading(true);
    handleRequest(CustomerUserService.getApiCustomerApiAppCustomerUserCustomerUserListByRoleId(id))
      .then((data) => {
        if (data != null) {
          const userInfo = data?.map((item) => {
            return {
              fullName: item.fullName != null ? item.fullName : '',
              title: item.customerTitle != null ? item.customerTitle : '',
              department: item.customerDepartment != null ? item.customerDepartment : '',
            };
          });
          setAllUsers(userInfo);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteRoles = (id: string): void => {
    setLoading(true);
    handleRequest(
      CustomerRoleService.deleteApiCustomerApiAppCustomerRoleCustomerRole(id, isConfirm)
    )
      .then((data: any) => {
        if (data != null) {
          if (isConfirm) {
            setLoading(false);
            setConfirm(false);
            closeConfirmModal();
            toast.success(<ToastComponent label='Success' message='Roles Deleted.' />);
            void queryClient.invalidateQueries('getRoles');
          } else {
            setAssigned(data.isAssigned);
            setConfirm(true);
            setLoading(false);
            openConfirmModal();
          }
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleShow = (id: string, e: any): void => {
    setPopoverInfo('Role');
    getRolesData(id);
    handleMouseEvent(e);
  };

  const handleUserShow = (id: string, e: any): void => {
    setPopoverInfo('User');
    getUserData(id);
    handleMouseEvent(e);
  };

  return (
    <>
      <PageHeader>
        <Heading variant='h3' title='System Roles' />
        <Heading
          variant='body-s'
          title={`Current list of Wealthlane system access roles for ${String(
            userAuthList?.currentTenant?.name ?? ''
          )}.  `}
        />
      </PageHeader>
      <div className='grid sm:grid-cols-12 justify-between gap-4 sm:gap-0'>
        <div className='lg:col-span-3 col-span-6'>
          <Search
            placeholder='Search'
            searchValue={searchQuery}
            onSearch={(value: string) => {
              setSearchQuery(value);
            }}
            setTableInfo={setTableInfo}
            tableInfo={tableInfo}
          />
        </div>
        <SettingRoleButton className='lg:col-start-10 lg:col-span-3 col-span-6'>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add Role'}
            icon={'add_circle_outline'}
            onClick={() => {
              setRolesInitialData(rolesInitialValues);
              setModalStatus('Add');
              openModal();
            }}
          />
        </SettingRoleButton>
        <AnimationWrapper>
          {isModalOpen && (
            <RoleModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              modalStatus={modalStatus}
              rolesInitialData={rolesInitialData}
              setModalStatus={setModalStatus}
              setRolesInitialData={() => {
                setRolesInitialData(rolesInitialValues);
              }}
              loading={loading}
            />
          )}
        </AnimationWrapper>

        <AnimationWrapper>
          {isConfirmModalOpen && (
            <RolesConfirmModal
              isShow={isConfirmModalOpen}
              handleClose={() => {
                closeConfirmModal();
                setConfirm(false);
              }}
              modalStatus={isAssigned}
              handleSubmit={() => {
                handleDeleteRoles(id);
              }}
              loading={loading}
            />
          )}
        </AnimationWrapper>
      </div>

      <PopoverModalComponent
        children={
          popoverInfo === ROLE ? (
            <RolesPopoverContent permissionData={allPermission} loading={loading} />
          ) : (
            <UserPopoverContent data={allUsers} loading={loading} />
          )
        }
        hasMousePosition={hasMousePosition}
        trigger={trigger}
        popoverClassName='roles-popovers'
        resetMousePosition={resetMousePosition}
      />
      <Table
        columns={AdminRolesHeader(
          actions,
          handleShow,
          handleUserShow,
          hasMousePosition,
          resetMousePosition
        )}
        data={data?.items ?? []}
        loading={status}
        showPagination
        totalCounts={data?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </>
  );
};

export default SystemRoles;
