import { type UseQueryResult, useQuery } from 'react-query';
import {
  TradePreClearanceTransactionPeriodConfigurationService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationDto,
} from '../../services/wealthlane-award-settlement-services';

const useGetTransactionPeriodConfig = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationDto
  | undefined,
  unknown
> => {
  const transactionPeriodConfig = useQuery(['getTransactionPeriodConfig'], async () => {
    return await handleRequest(
      TradePreClearanceTransactionPeriodConfigurationService.getApiAwardSettlementApiAppTradePreClearanceTransactionPeriodConfigurationTradePreClearanceTransactionPeriodConfiguration()
    );
  });

  return transactionPeriodConfig;
};

export default useGetTransactionPeriodConfig;
