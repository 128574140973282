import styled from 'styled-components';
import { rem } from '../../utils/style';
import { COLORS } from '../../constants/style/color';

interface ProgressBarType {
  progress: number;
  bgColor: string;
}

export const ProgressBar = styled.div<ProgressBarType>`
  height: 8px;
  width: 100%;
  background-color: #e5eaf5;
  border-radius: 100px;
  margin: 0.5rem;

  .childBar {
    height: 100%;
    width: ${(props) => `${props.progress}%`};
    background-color: ${(props) => props.bgColor};
    border-radius: ${rem(40)};
    text-align: right;
  }

  .loading-progress {
    display: flex;
    justify-content: space-between;
    .progresstext {
      margin-top: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${COLORS.Black};
    }
  }
`;

export const Progress = styled.div`
  .svg-pi-wrapper {
    position: relative;
  }

  .svg-pi {
    transform: rotate(-90deg); /* Fix the orientation */
  }

  /* Animated spinner version */
  .svg-pi-indicator--spinner {
    animation: spinner 0.75s linear infinite;
    transform-origin: center;
  }

  .svg-pi-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .svg-pi-label__loading {
    opacity: 0.5;
    font-size: 0.75em;
  }

  .svg-pi-label__progress {
    font-size: 10px;
    font-weight: bold;
  }

  .svg-pi-label__loading,
  .svg-pi-label__progress {
    display: block;
  }

  /* Spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
