/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  RetirementEligibilityConfigurationService,
  type Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto,
  type Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_SaveRetirementEligibilityConfigurationRequestDto,
} from '../../services/wealthlane-award-settlement-services';

const usePostRetirementEligibility = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  status: string | null | undefined
): UseMutationResult<
  Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto | undefined,
  unknown,
  Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_SaveRetirementEligibilityConfigurationRequestDto,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (
      payload: Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_SaveRetirementEligibilityConfigurationRequestDto
    ) => {
      return await handleRequest(
        RetirementEligibilityConfigurationService.postApiAwardSettlementApiAppRetirementEligibilityConfigurationSaveRetirementEligibilityConfiguration(
          payload
        )
      );
    },

    {
      onSuccess: async (response) => {
        if (response?.code === 200) {
          toast.success(
            <ToastComponent
              label='Success'
              message={`Retirement eligibility ${status != null ? 'updated' : 'saved'}.`}
            />
          );
          await queryClient.invalidateQueries('getRetirementEligibilityData');
        } else {
          toast.error(
            <ToastComponent label='Error' message={`${String(response?.message ?? '')}`} />
          );
          await queryClient.invalidateQueries('getRetirementEligibilityData');
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostRetirementEligibility;
