/* eslint-disable camelcase */
// import { isEmpty } from 'lodash';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  ReleaseService,
  Wealthlane_AwardService_Dtos_Release_CreateReleaseDto,
  Wealthlane_AwardService_Dtos_Release_UpdateReleaseDto,
} from '../../services/wealthlane-award-services';

const usePostTransactionAddToRelease = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  selectedTableData?: any,
  setSelectedId?: any,
  setNumberOfInclude?: any,
  numberOfInclude?: any,
  modalStatus?: string,
  releaseId?: any,
  setSelectedTableData?: any,
  selectedId?: string,
  setInitialValue?: any
): UseMutationResult<
  | Wealthlane_AwardService_Dtos_Release_CreateReleaseDto
  | Wealthlane_AwardService_Dtos_Release_UpdateReleaseDto
  | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      const newPayload = {
        ...payload,
        vesting: selectedTableData ? selectedTableData : [],
      };

      if (modalStatus === 'Edit' || selectedId) {
        return await handleRequest(
          ReleaseService.putApiAwardApiAppReleaseRelease(
            releaseId ? releaseId : selectedId,
            newPayload
          )
        );
      } else {
        return await handleRequest(ReleaseService.postApiAwardApiAppReleaseRelease(newPayload));
      }
    },
    {
      onSuccess: async (response: any) => {
        if (response != null) {
          toast.success(
            <ToastComponent
              label='Success'
              message={`${selectedTableData.length} items added to the release.`}
            />
          );
          setInitialValue(response);
          await queryClient.invalidateQueries('getTransactionReleaseRsuVestingList');

          if (numberOfInclude) {
            setNumberOfInclude(numberOfInclude + selectedTableData.length);
          } else {
            setNumberOfInclude(selectedTableData.length);
          }

          setSelectedId(response?.id);
          setSelectedTableData([]);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostTransactionAddToRelease;
