/* eslint-disable camelcase */
import { type UseMutationResult, useMutation } from 'react-query';
import {
  OptionAwardService,
  PsuAwardService,
  RsuAwardService,
  type Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportResponseDTO,
  type Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportResponseDTO,
  type Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportResponseDTO,
  // type Wealthlane_AwardService_Dtos_VestingTemplate_VestingImportResponseDTO,
  // VestingTemplateService,
} from '../../services/wealthlane-award-services';
import {
  ParticipantService,
  type Wealthlane_CustomerService_Dtos_Participant_ImportParticipantResponseDto,
} from '../../services/wealthlane-customer-services';

const usePostFileImport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setImportedData: any,
  requestedModule: string,
  setImportStatus?: any,
  setCounter?: any
): UseMutationResult<
  | Wealthlane_AwardService_Dtos_RSUAward_RSUAwardImportResponseDTO
  | Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportResponseDTO
  | Wealthlane_AwardService_Dtos_OptionAward_OptionAwardImportResponseDTO
  | Wealthlane_CustomerService_Dtos_Participant_ImportParticipantResponseDto
  // | Wealthlane_AwardService_Dtos_VestingTemplate_VestingImportResponseDTO
  | undefined,
  unknown,
  any,
  unknown
> => {
  return useMutation(
    async (payload: any) => {
      if (requestedModule.includes('RSU')) {
        return await handleRequest(
          RsuAwardService.postApiAwardApiAppRSUAwardValidateBulkRSu(payload)
        );
      } else if (requestedModule.includes('PSU')) {
        return await handleRequest(
          PsuAwardService.postApiAwardApiAppPSUAwardValidateBulkPSu(payload)
        );
      } else if (requestedModule.includes('Option')) {
        return await handleRequest(
          OptionAwardService.postApiAwardApiAppOptionAwardValidateBulkOptionAwards(payload)
        );
        // } else if (requestedModule.includes('VestingTemplate')) {
        //   return await handleRequest(
        //     VestingTemplateService.postApiAwardApiAppVestingTemplateValidateBulkImport(payload)
        //   );
      } else {
        return await handleRequest(
          ParticipantService.postApiCustomerApiAppParticipantVerifyBulkParticipantsForImport(
            payload
          )
          // ParticipantService.postApiCustomerApiAppParticipantImportBulkParticipants(payload)
        );
      }
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          // if (requestedModule !== 'RSU') {
          setImportedData(response?.id);
          setImportStatus(response);
          setCounter(1);
          // } else {
          //   setImportedData(response);
          // }
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostFileImport;
