import { useMutation } from 'react-query';
import {
  BlackoutService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackOutGroupDto,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutTitleDto,
} from '../../services/wealthlane-customer-services';

const useGetTradingBlackoutConfigView = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setBlackoutConfigView: any
): any => {
  return useMutation(
    async (data: { id: string; modalStatus?: string }) => {
      return await handleRequest(
        BlackoutService.getApiCustomerApiAppBlackoutBlackoutById(data?.id)
      );
    },
    {
      onSuccess: (response: any, context: { id: string; modalStatus: string }) => {
        if (response != null) {
          const filteredResponse = {
            ...response,
            startDate: context?.modalStatus?.includes('Repurpose') ? null : response?.startDate,
            endDate: context?.modalStatus?.includes('Repurpose') ? null : response?.endDate,
            blackoutGroups: response.blackoutGroups.map(
              // eslint-disable-next-line camelcase
              (group: Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackOutGroupDto) => ({
                ...group,
                groupData: true,
                blackOutJobTitles: group?.blackOutJobTitles?.map(
                  // eslint-disable-next-line camelcase
                  (item: Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutTitleDto) =>
                    item.customerTitleId
                ),
              })
            ),
          };
          setBlackoutConfigView(filteredResponse);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetTradingBlackoutConfigView;
