/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_TerminationReport_TerminationReportDto_Wealthlane_ReportService_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_TerminationReport_TerminationReportDto_Wealthlane_ReportService_Application_Contracts';
import type { Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto } from '../models/Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TerminationReportService {

    /**
     * @param terminatedFromDate 
     * @param terminatedToDate 
     * @param employeeType 
     * @param columnPreferences 
     * @param columnPreferencesSystemName 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_TerminationReport_TerminationReportDto_Wealthlane_ReportService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiReportApiAppTerminationReportTerminationReportList(
terminatedFromDate?: string,
terminatedToDate?: string,
employeeType?: string,
columnPreferences?: Array<string>,
columnPreferencesSystemName?: Array<string>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_TerminationReport_TerminationReportDto_Wealthlane_ReportService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/termination-report/termination-report-list',
            query: {
                'TerminatedFromDate': terminatedFromDate,
                'TerminatedToDate': terminatedToDate,
                'EmployeeType': employeeType,
                'ColumnPreferences': columnPreferences,
                'ColumnPreferencesSystemName': columnPreferencesSystemName,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param terminatedFromDate 
     * @param terminatedToDate 
     * @param employeeType 
     * @param columnPreferences 
     * @param columnPreferencesSystemName 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto Success
     * @throws ApiError
     */
    public static getApiReportApiAppTerminationReportTerminationReport(
terminatedFromDate?: string,
terminatedToDate?: string,
employeeType?: string,
columnPreferences?: Array<string>,
columnPreferencesSystemName?: Array<string>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/termination-report/termination-report',
            query: {
                'TerminatedFromDate': terminatedFromDate,
                'TerminatedToDate': terminatedToDate,
                'EmployeeType': employeeType,
                'ColumnPreferences': columnPreferences,
                'ColumnPreferencesSystemName': columnPreferencesSystemName,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
