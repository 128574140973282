/* eslint-disable camelcase */
import { FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import FormSelect from '../../../../components/dropdown/formSelect';
import InputText from '../../../../components/input-text/inputText';
import ModalComponent from '../../../../components/modal/modal';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';
import {
  type Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto,
  ZipCodeLookUpService,
} from '../../../../services/wealthlane-customer-services';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import {
  useGetCountryOptions,
  useGetStateOptions,
  usePostCompanyProfile,
  useWealthlaneCustomerApi,
} from '../../../../hooks';
import _ from 'lodash';
import Button from '../../../../components/button';
import { FormDivider } from '../profile.styled';
import ToastComponent from '../../../../components/toast';
import Heading from '../../../../components/typography';
import Tag from '../../../../components/lozenge/tags';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { COLORS } from '../../../../constants/style/color';
import { toast } from 'react-toastify';
import { ADDITIONAL_ADDRESS_VALIDATION } from '../../../../utils/validations/validations';

interface AdditionalAddressModalType {
  initialData?: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto;
  loading: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
}

const initialInfo = {
  companyAddressList: {
    countryId: null,
    addressLine1: '',
    addressLine2: '',
    stateId: null,
    cityText: '',
    stateText: '',
    zipCode: '',
    taxId: '',
  },
};

const AdditionalAddressModal = ({
  initialData,
  loading,
  isModalOpen,
  closeModal,
}: AdditionalAddressModalType): JSX.Element => {
  const [countryId, setCountryId] = useState<string>('');
  const { handleRequest } = useWealthlaneCustomerApi();

  const { data: countryOptions } = useGetCountryOptions(handleRequest);
  const { data: stateOptions } = useGetStateOptions(handleRequest, countryId);

  const { mutate, isLoading } = usePostCompanyProfile(handleRequest, closeModal);
  const handleSubmit = (
    values: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto
  ): void => {
    const payload = {
      id: values.companyId ?? null,
      name: values.companyName,
      ticker: values.ticker,
      industrySectorId: values.industrySectorId ?? null,
      taxId: values.taxId ?? null,
      primaryStockExchangeId: values.primaryStockExchangeId ?? null,
      dateOfIncorporation: values.dateOfIncorporation,
      stateOfIncorporationId: values.stateOfIncorporationId ?? null,
      countryOfIncorporationId: values.countryOfIncorporationId ?? null,
      cusip: values.cusip,
      sicCode: values.sicCode,
      cityText: values.city,
      stateText: values.state,
      stateOfIncorporationText: values.stateOfIncorporation,
      businessDescription: values.businessDescription,
      websiteUrl: values.websiteUrl,
      headQuarterCountryId: values.countryId ?? null,
      headQuarterAddressLine1: values.addressLine1,
      headQuarterAddressLine2: values.addressLine2,
      headQuarterStateId: values.stateId ?? null,
      headQuarterCityText: values.city ?? null,
      headQuarterStateText: values.state ?? null,
      headQuarterZipCode: `${values.zipCode ?? ''}`,
      domainNames: values.domainNames,
      isEnabled: true,
      companyAddressList:
        values?.companyAddressList != null &&
        values?.companyAddressList?.length > 0 &&
        (values?.companyAddressList[0].countryId as unknown as boolean)
          ? values?.companyAddressList?.map((item) => {
              return {
                countryId: item.countryId != '' ? item.countryId : null,
                countryText: item.countryText,
                addressLine1: item.addressLine1,
                addressLine2: item.addressLine2,
                stateId: item.stateId != '' ? item.stateId : null,
                cityText: item.cityText,
                stateText: item.stateText,
                zipCode: item.zipCode,
                taxId: item.taxId != '' ? item.taxId : null,
                id: values.companyId ?? null,
              };
            })
          : [],
    };
    mutate(payload);
  };

  const handleZipCode = (
    zipCode: string,
    values: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    index?: number
  ): void => {
    const isOther = countryOptions?.find(
      (item) =>
        item.value ===
        (index != null ? values?.companyAddressList[index]?.countryId : values.headQuarterCountryId)
    )?.isOtherCountry;

    setFieldValue(
      index != null ? `companyAddressList.${index}.zipCode` : 'headQuarterZipCode',
      zipCode
    );

    if (!(isOther ?? false) && zipCode.split('').length === 5) {
      handleRequest(ZipCodeLookUpService.getApiCustomerApiAppZipCodeLookUpZipCodeLookUp(zipCode))
        .then((data: any) => {
          if (data?.error !== null) {
            setFieldValue(`companyAddressList.${index}.zipCodeErrorMessage`, data?.error?.description.replaceAll('.', ''));
          } else if (data != null) {
            const capitalCity =
              data.city != null
                ? data.city
                    ?.toLowerCase()
                    .split(' ')
                    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                : '';
            setFieldValue(
              index != null ? `companyAddressList.${index}.stateText` : 'headQuarterStateText',
              data.state != null ? data.state : ''
            );
            setFieldValue(
              index != null ? `companyAddressList.${index}.cityText` : 'headQuarterCityText',
              capitalCity
            );
            setFieldValue(
              index != null ? `companyAddressList.${index}.stateId` : 'headQuarterStateId',
              data.stateId != null ? data.stateId : ''
            );
          }
        })
        .catch((error: any) => {
          console.log('error', error);
        });
    }
  };

  return (
    <>
      {initialData != null && (
        <ModalComponent
          show={isModalOpen}
          closeModal={closeModal}
          size='lg'
          loading={loading}
          title='Additional Address Details'
          children={
            <Formik
              enableReinitialize
              initialValues={initialData}
              validationSchema={ADDITIONAL_ADDRESS_VALIDATION}
              onSubmit={(values) => {
                handleSubmit(values);
              }}>
              {({ touched, handleChange, setFieldValue, errors, values, setFieldTouched }: any) => {
                return (
                  <Form>
                    <ModalBodyComponent>
                      {Boolean(values.companyAddressList) && (
                        <div className='form-wrapper pt-0'>
                          <FieldArray
                            name='companyAddressList'
                            render={(arrayHelpers) => {
                              return (
                                <TransitionGroup className='todo-list'>
                                  {Boolean(values?.companyAddressList) &&
                                    values.companyAddressList.length > 0 &&
                                    values.companyAddressList.map((item: any, index: number) => (
                                      <CSSTransition<undefined>
                                        addEndListener={(node: HTMLElement, done: () => void) => {
                                          node.addEventListener('transitionend', done, false);
                                        }}
                                        classNames='fade'>
                                        <>
                                          <div className='flex justify-between items-center'>
                                            <Tag
                                              variant='success'
                                              title={`Additional Address #${index + 1}`}
                                              className='mb-6'
                                            />
                                            {values?.companyAddressList?.length > 1 && (
                                              <div
                                                className='flex items-center ap-2'
                                                role='button'
                                                onClick={() => {
                                                  toast.success(
                                                    <ToastComponent
                                                      label={'Success'}
                                                      message={'Additional address deleted'}
                                                    />
                                                  );
                                                  arrayHelpers.remove(index);
                                                }}>
                                                <span className='material-symbols-outlined text-red-600'>
                                                  delete
                                                </span>
                                                <Heading
                                                  variant='body-s'
                                                  title='Delete'
                                                  color={`${COLORS.Gray}`}
                                                />
                                              </div>
                                            )}
                                          </div>
                                          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-x-8'>
                                            <div className='col-span-1 lg:col-span-6'>
                                              <InputText
                                                type='text'
                                                label='Address Line 1'
                                                name={`companyAddressList.${index}.addressLine1`}
                                                onChange={handleChange}
                                                errorValue={
                                                  errors?.companyAddressList?.[index]?.addressLine1
                                                }
                                                touchedValue={
                                                  touched?.companyAddressList?.[index]?.addressLine1
                                                }
                                                errors={errors}
                                                touched={touched}
                                                required
                                              />
                                            </div>
                                            <div className='col-span-1 lg:col-span-6'>
                                              <InputText
                                                type='text'
                                                label='Address Line 2'
                                                name={`companyAddressList.${index}.addressLine2`}
                                                onChange={handleChange}
                                                errorValue={
                                                  errors?.companyAddressList?.[index]?.addressLine2
                                                }
                                                touchedValue={
                                                  touched?.companyAddressList?.[index]?.addressLine2
                                                }
                                                errors={errors}
                                                touched={touched}
                                              />
                                            </div>
                                            <div className='col-span-1 lg:col-span-6'>
                                              <InputText
                                                type='text'
                                                label='Tax ID'
                                                name={`companyAddressList.${index}.taxId`}
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                errorValue={
                                                  errors?.companyAddressList?.[index]?.taxId
                                                }
                                                touchedValue={
                                                  touched?.companyAddressList?.[index]?.taxId
                                                }
                                                required
                                              />
                                            </div>
                                            <div className='col-span-1 lg:col-span-6'>
                                              <FormSelect
                                                type='text'
                                                label='Country'
                                                name={`companyAddressList.${index}.countryId`}
                                                onChange={(value: string) => {
                                                  const isOther = countryOptions?.find(
                                                    (obj) => obj?.value === value
                                                  )?.isOtherCountry;
                                                  if (!(isOther ?? false)) {
                                                    setCountryId(value);
                                                  }
                                                  setFieldValue(
                                                    `companyAddressList.${index}.isOtherCountry`,
                                                    isOther
                                                  );
                                                  setFieldValue(
                                                    `companyAddressList.${index}.countryId`,
                                                    value
                                                  );
                                                  setFieldValue(
                                                    `companyAddressList.${index}.stateText`,
                                                    ''
                                                  );
                                                  setFieldValue(
                                                    `companyAddressList.${index}.cityText`,
                                                    ''
                                                  );
                                                  setFieldValue(
                                                    `companyAddressList.${index}.stateId`,
                                                    ''
                                                  );
                                                  setFieldValue(
                                                    `companyAddressList.${index}.zipCode`,
                                                    ''
                                                  );
                                                }}
                                                errorValue={
                                                  errors?.companyAddressList?.[index]?.countryId
                                                }
                                                touchedValue={
                                                  touched?.companyAddressList?.[index]?.countryId
                                                }
                                                arrValue={
                                                  values?.companyAddressList?.[index].countryId
                                                }
                                                errors={errors}
                                                touched={touched}
                                                options={countryOptions}
                                                required={true}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                              />
                                            </div>
                                            <div className='col-span-1 lg:col-span-4'>
                                              <InputText
                                                type='string'
                                                label='ZIP or Postal Code'
                                                name={`companyAddressList.${index}.zipCode`}
                                                onChange={(e: any) => {
                                                  setFieldTouched(`companyAddressList.${index}.zipCode`, true);
                                                  handleZipCode(
                                                    e.target.value,
                                                    values,
                                                    setFieldValue,
                                                    index
                                                  );
                                                }}
                                                errorValue={
                                                  errors?.companyAddressList?.[index]?.zipCode
                                                }
                                                touchedValue={
                                                  touched?.companyAddressList?.[index]?.zipCode
                                                }
                                                errors={errors}
                                                touched={touched}
                                                disabled={
                                                  !(values.companyAddressList[index]
                                                    .countryId as boolean)
                                                }
                                                required
                                              />
                                            </div>
                                            {countryOptions?.find(
                                              (item: any) =>
                                                item.value ===
                                                values.companyAddressList[index].countryId
                                            )?.isOtherCountry ?? false ? (
                                              <div className='col-span-1 lg:col-span-4'>
                                                <InputText
                                                  type='text'
                                                  label='State'
                                                  name={`companyAddressList.${index}.stateText`}
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      `companyAddressList.${index}.stateText`,
                                                      e.target.value
                                                    );
                                                    setFieldValue(
                                                      `companyAddressList.${index}.cityText`,
                                                      ''
                                                    );
                                                  }}
                                                  errorValue={
                                                    errors?.companyAddressList?.[index]?.stateText
                                                  }
                                                  touchedValue={
                                                    touched?.companyAddressList?.[index]?.stateText
                                                  }
                                                  errors={errors}
                                                  touched={touched}
                                                  disabled={
                                                    !(values.companyAddressList[index]
                                                      .countryId as boolean)
                                                  }
                                                  required
                                                />
                                              </div>
                                            ) : (
                                              <div className='col-span-1 lg:col-span-4'>
                                                <FormSelect
                                                  type='text'
                                                  label='State'
                                                  name={`companyAddressList.${index}.stateId`}
                                                  onChange={(value: string) => {
                                                    setFieldValue(
                                                      `companyAddressList.${index}.stateId`,
                                                      value
                                                    );
                                                    setFieldValue(
                                                      `companyAddressList.${index}.cityText`,
                                                      ''
                                                    );
                                                  }}
                                                  errorValue={
                                                    errors?.companyAddressList?.[index]?.stateId
                                                  }
                                                  touchedValue={
                                                    touched?.companyAddressList?.[index]?.stateId
                                                  }
                                                  arrValue={
                                                    values?.companyAddressList?.[index].stateId
                                                  }
                                                  errors={errors}
                                                  touched={touched}
                                                  options={stateOptions}
                                                  required={true}
                                                  values={values}
                                                  disabled={
                                                    !(values.companyAddressList[index]
                                                      .countryId as boolean)
                                                  }
                                                  setFieldValue={setFieldValue}
                                                />
                                              </div>
                                            )}
                                            <div className='col-span-1 lg:col-span-4'>
                                              <InputText
                                                type='text'
                                                label='City'
                                                name={`companyAddressList.${index}.cityText`}
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                errorValue={
                                                  errors?.companyAddressList?.[index]?.cityText
                                                }
                                                touchedValue={
                                                  touched?.companyAddressList?.[index]?.cityText
                                                }
                                                disabled={
                                                  !(values.companyAddressList[index]
                                                    .countryId as boolean)
                                                }
                                                required
                                              />
                                            </div>
                                          </div>
                                          <FormDivider />
                                        </>
                                      </CSSTransition>
                                    ))}

                                  <Button
                                    title='Add Additional Address'
                                    type='button'
                                    variant='primary-dark'
                                    icon='group_add'
                                    btnClassName='w-full'
                                    onClick={() => {
                                      arrayHelpers.push(initialInfo.companyAddressList);
                                    }}
                                  />
                                </TransitionGroup>
                              );
                            }}
                          />
                        </div>
                      )}
                    </ModalBodyComponent>
                    <ModalFooterComponent
                      modalStatus='Edit'
                      onCloseModal={() => {
                        closeModal();
                      }}
                      valueChanged={_.isEqual(values, initialData)}
                      loading={isLoading}
                    />
                  </Form>
                );
              }}
            </Formik>
          }
        />
      )}
    </>
  );
};

export default AdditionalAddressModal;
