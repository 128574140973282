/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionExercise_AdminExerciseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionExercise_AdminExerciseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseRequestDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseRequestDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseResponseDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseResponseDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_FillFundedAmountForCashExcerciseDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_FillFundedAmountForCashExcerciseDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseDetailDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseDetailDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseListDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseListDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseTransactionViewDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseTransactionViewDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_StatusDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_StatusDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_UpdateFMVOptionExerciseDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_UpdateFMVOptionExerciseDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto } from '../models/Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto';
import type { Wealthlane_AwardService_Transaction_Transaction } from '../models/Wealthlane_AwardService_Transaction_Transaction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OptionExerciseService {

    /**
     * @param awardType 
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionExerciseExercisableAwards(
awardType?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-exercise/exercisable-awards',
            query: {
                'awardType': awardType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionExerciseCalculateExercise(
requestBody?: Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-exercise/calculate-exercise',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionExerciseOptionExerciseRequest(
requestBody?: Array<Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-exercise/option-exercise-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_StatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionExerciseSettlementTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionExercise_StatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-exercise/settlement-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionExerciseId 
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseDetailDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionExerciseOptionExerciseById(
optionExerciseId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-exercise/option-exercise-by-id/{OptionExerciseId}',
            path: {
                'OptionExerciseId': optionExerciseId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param settlementTypeIds 
     * @param grantIds 
     * @param recordTypes 
     * @param fromDate 
     * @param toDate 
     * @param showExercisedListOnly 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionExercise_AdminExerciseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionExerciseOptionExercisedList(
settlementTypeIds?: Array<string>,
grantIds?: Array<string>,
recordTypes?: Array<string>,
fromDate?: string,
toDate?: string,
showExercisedListOnly?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionExercise_AdminExerciseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-exercise/option-exercised-list',
            query: {
                'SettlementTypeIds': settlementTypeIds,
                'GrantIds': grantIds,
                'RecordTypes': recordTypes,
                'FromDate': fromDate,
                'ToDate': toDate,
                'ShowExercisedListOnly': showExercisedListOnly,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionExerciseTaxDetailsByTransactionId(
transactionId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-exercise/tax-details-by-transaction-id/{TransactionId}',
            path: {
                'TransactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppOptionExerciseApplicableTaxes(
requestBody?: Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/option-exercise/applicable-taxes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param exerciseId 
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseTransactionViewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionExerciseTransactionDetailByExerciseId(
exerciseId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseTransactionViewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-exercise/transaction-detail-by-exercise-id/{ExerciseId}',
            path: {
                'ExerciseId': exerciseId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionExerciseReadyToTransfer(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-exercise/ready-to-transfer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionExerciseFillFundedAmountForCashExcercise(
requestBody?: Wealthlane_AwardService_Dtos_OptionExercise_FillFundedAmountForCashExcerciseDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-exercise/fill-funded-amount-for-cash-excercise',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionExerciseCalculateOptionExerciseTax(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-exercise/calculate-option-exercise-tax',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param fmv 
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppOptionExerciseFMVByTransactionId(
fmv: number,
requestBody?: Wealthlane_AwardService_Dtos_OptionExercise_UpdateFMVOptionExerciseDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/option-exercise/f-mVBy-transaction-id',
            query: {
                'FMV': fmv,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_StatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionExerciseExerciseStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionExercise_StatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-exercise/exercise-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param exerciseId 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionExerciseCalculateOptionExerciseTaxForSingleRecord(
exerciseId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-exercise/calculate-option-exercise-tax-for-single-record/{ExerciseId}',
            path: {
                'ExerciseId': exerciseId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionExerciseRaiseTransactionUpdatedEvent(
requestBody?: Wealthlane_AwardService_Transaction_Transaction,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-exercise/raise-transaction-updated-event',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionExerciseRaiseTransactionAddedEvent(
requestBody?: Wealthlane_AwardService_Transaction_Transaction,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-exercise/raise-transaction-added-event',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionExerciseExerciseListByAwardId(
awardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-exercise/exercise-list-by-award-id/{AwardId}',
            path: {
                'AwardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
