import { Transition } from 'react-transition-group';
import { AlertPopupContainer } from '../../../components/alert-popup/alertPopup.styled';
import Button from '../../../components/button';
import Heading from '../../../components/typography';

interface RolesConfirmModalType {
  isShow: boolean;
  handleClose: () => void;
  handleSubmit?: any;
  modalStatus?: boolean;
  loading?: boolean;
}

const RolesConfirmModal = ({
  isShow,
  modalStatus,
  handleClose,
  handleSubmit,
  loading,
}: RolesConfirmModalType): JSX.Element => {
  return (
    <Transition in={isShow} timeout={250}>
      {(state) => (
        <AlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className='popupDialog'>
                <div className='popupContent'>
                  <Heading
                    variant='h5'
                    className='mb-5'
                    title={`${
                      (modalStatus as boolean) ? 'System Roles is already in use.' : ''
                    } Are you sure you want to delete this System Role?`}
                  />

                  <div className='popupButtons'>
                    <Button
                      variant='primary-dark'
                      title='Close'
                      onClick={() => {
                        handleClose();
                      }}
                    />
                    <Button
                      variant='primary'
                      title='Confirm'
                      onClick={handleSubmit}
                      icon={<span className='material-symbols-outlined'>check_circle</span>}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertPopupContainer>
      )}
    </Transition>
  );
};

export default RolesConfirmModal;
