/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_CustomerDepartment_CreateCustomerDepartmentDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerDepartment_CreateCustomerDepartmentDto';
import type { Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto';
import type { Wealthlane_CustomerService_Dtos_CustomerDepartment_UpdateCustomerDepartmentDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerDepartment_UpdateCustomerDepartmentDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerDepartmentService {

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerDepartmentCustomerDepartment(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerDepartment_CreateCustomerDepartmentDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-department/customer-department',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerDepartment_UpdateCustomerDepartmentDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppCustomerDepartmentCustomerDepartment(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerDepartment_UpdateCustomerDepartmentDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerDepartment_UpdateCustomerDepartmentDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/customer-department/customer-department',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerDepartmentId 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppCustomerDepartmentCustomerDepartment(
customerDepartmentId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/customer-department/customer-department/{customerDepartmentId}',
            path: {
                'customerDepartmentId': customerDepartmentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerDepartmentId 
     * @returns Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerDepartmentCustomerDepartment(
customerDepartmentId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-department/customer-department/{customerDepartmentId}',
            path: {
                'customerDepartmentId': customerDepartmentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerDepartmentByCompanyId(
id: string,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-department/{id}/by-company-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerDepartmentCustomerDepartmentList(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-department/customer-department-list',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerDepartmentCustomerDepartments(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerDepartment_CustomerDepartmentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-department/customer-departments',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
