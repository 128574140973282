/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  TradePreClearanceService,
  type Wealthlane_CustomerService_Dtos_Response_ResponseDto,
  type Wealthlane_CustomerService_Dtos_TradePreClearance_CreateOrUpdateTradePreClearanceDto,
} from '../../services/wealthlane-customer-services';

const usePostTradePreClearance = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void
): UseMutationResult<
  Wealthlane_CustomerService_Dtos_Response_ResponseDto | undefined,
  unknown,
  Wealthlane_CustomerService_Dtos_TradePreClearance_CreateOrUpdateTradePreClearanceDto,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: Wealthlane_CustomerService_Dtos_TradePreClearance_CreateOrUpdateTradePreClearanceDto
    ) => {
      return await handleRequest(
        TradePreClearanceService.postApiCustomerApiAppTradePreClearanceSaveTradePreClearance(
          payload
        )
      );
    },
    {
      onSuccess: async (response) => {
        if (response != null) {
          await queryClient.invalidateQueries('getTradePreClearanceList');
          closeModal();
          toast.success(<ToastComponent label='Success' message={`Trade pre clearance added.`} />);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostTradePreClearance;
