import React from 'react';
import { ErrorMessage, type FormikErrors } from 'formik';
import Select, { components } from 'react-select';
import { FormWrapper, Label } from '../input-text/input.styled';
import { DropdownContainer } from './dropdown.styled';
import { COLORS } from '../../constants/style/color';
import { type OptionType } from '../../types/common';
import { getRgbaValue, rem } from '../../utils/style';
import Tooltip from '../tooltip';
import { useMediaQuery } from '../../hooks';
import CheckFieldLevelPermissions from '../../utils/permission/checkFieldLevelPermission';
import { isNull } from 'lodash';

interface FormSelectProps {
  containerClassName?: string;
  label?: string | null;
  name: string;
  type?: string;
  placeholder?: string;
  onChange?: any;
  errors?: FormikErrors<any> | any;
  touched?: any;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  options: OptionType[] | undefined;
  allOptions?: OptionType[] | undefined;
  onBlur?: any;
  values?: any;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  arrValue?: any;
  defaultValue?: OptionType;
  errorValue?: any;
  touchedValue?: any;
  isClearable?: boolean | undefined;
  isFormik?: boolean;
  isTable?: boolean;
  inputType?: 'View' | string;
  viewName?: string;
  width?: string;
  menuWidth?: string;
  tooltipText?: string;
  fieldLabelName?: string;
  isEdit?: boolean;
  borderRadius?: boolean;
  takeName?: boolean;
}

const FormSelect = React.memo((props: FormSelectProps) => {
  const isMobile = useMediaQuery(512);
  const {
    containerClassName,
    label,
    name,
    type,
    errors,
    touched,
    onChange,
    required,
    options,
    allOptions,
    placeholder,
    disabled,
    values,
    onBlur,
    setFieldValue,
    multiple,
    arrValue,
    errorValue,
    touchedValue,
    defaultValue,
    isClearable,
    isFormik = true,
    isTable = false,
    inputType,
    viewName,
    width,
    menuWidth,
    tooltipText,
    fieldLabelName,
    isEdit,
    borderRadius,
    takeName = false,
    ...rest
  } = props;

  const MobileWidth = isMobile ? '100%' : width;
  const hasError = !!(
    (!(disabled ?? false) &&
      Boolean(errors) &&
      Boolean(touched) &&
      Boolean(errors[name]) &&
      Boolean(touched[name])) ||
    (Boolean(errorValue) && Boolean(touchedValue))
  );

  const selectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderRadius: 6,
        // padding: 2,
        borderTopRightRadius: (borderRadius as boolean) ? 0 : 6,
        borderBottomRightRadius: (borderRadius as boolean) ? 0 : 6,
        fontWeight: 400,
        fontSize: `${rem(15)}`,
        height: `auto`,
        transition: `all .2s linear`,
        border:
          state.isFocused !== false && state.isFocused != null
            ? `1px solid ${COLORS.SpanishGray} !important`
            : hasError
            ? `1px solid ${COLORS.Red} !important`
            : `${rem(1)} solid   ${
                borderRadius ? COLORS.CulturedWhite : COLORS.LightGray
              } !important`,
        boxShadow: `${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.DefaultShadow}`,
        width: MobileWidth ?? '',
        ':hover': {
          border: `1px solid ${COLORS.Blue} !important`,
          cursor: 'pointer',
        },
      };
    },
    valueContainer: (styles: any, state: any) => {
      return {
        ...styles,
        height: state.children[0]?.length > 0 ? 'auto' : 38,
        paddingLeft: /* multiple ? 16 : */ 16,
        paddingRight: 5,
        gap: 4,
      };
    },
    singleValue: (styles: any) => {
      return {
        ...styles,
        color: `${COLORS.Black}`,
      };
    },
    placeholder: (styles: any, state: any) => {
      return {
        ...styles,
        padding: 0,
        color:
          fieldLabelName &&
          CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled) != null &&
          CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled) !== false
            ? `${COLORS.Black}`
            : state.isFocused !== false && state.isFocused != null
            ? `${COLORS.SpanishGray}`
            : `${COLORS.SpanishGray}`,
        whiteSpace: 'nowrap',
      };
    },
    menu: (styles: any, state: any) => {
      return {
        ...styles,
        background: 'transparent',
        boxShadow: `${rem(0)} ${rem(2)} ${rem(4)} transparent`,
      };
    },
    menuList: (styles: any, state: any) => {
      return {
        ...styles,
        maxHeight: `${rem(256)}`,
        fontSize: `${rem(14)}`,
        padding: `${rem(10)}`,
        borderRadius: `${rem(4)}`,
        // boxShadow: `${rem(0)} ${rem(2)} ${rem(20)} ${COLORS.SoftShadow}`,
        width: 'fit-content',
        minWidth: '100%',
        background: COLORS.White,
        border:
          state.isFocused !== false && state.isFocused != null
            ? `1px solid ${COLORS.SpanishGray} !important`
            : // : hasError
              // ? `1px solid ${COLORS.Red} !important`
              `${rem(1)} solid ${COLORS.LightGray} !important`,
        boxShadow: `${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.DefaultShadow}`,

        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '7px',
        },

        '::-webkit-scrollbar-thumb': {
          background: '#969696',
          borderRadius: '7px',
        },

        '::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      };
    },
    indicatorsContainer: (styles: any) => {
      return {
        ...styles,
        height: 38,
        minHeight: 38,
      };
    },
    indicatorSeparator: (styles: any) => {
      return {
        ...styles,
        height: 15,
        marginTop: 4,
        display: 'none',
      };
    },
    option: (styles: any, state: any) => {
      return {
        ...styles,
        backgroundColor:
          state.isFocused !== false && state.isFocused != null
            ? `${getRgbaValue('#f3f3f3cc', 0.8)}`
            : null,
        color:
          state.isFocused !== false && state.isFocused != null
            ? `${COLORS.Blue}`
            : `${COLORS.Black}`,
        fontWeight: state.isFocused !== false && state.isFocused != null ? 500 : 400,
        borderRadius: `${rem(4)}`,
        whiteSpace: 'nowrap',
      };
    },
    multiValue: (styles: any) => {
      return {
        ...styles,
        height: `{auto}`,
        marginBottom: 0,
        marginTop: 0,
        marginLeft: 0,
        borderRadius: 4,
        // marginRight: 8,
      };
    },
    menuPortal: (styles: any) => ({
      ...styles,
      zIndex: 999,
      width: menuWidth != null ? menuWidth : styles?.width,
    }),
    multiValueLabel: (styles: any) => {
      return {
        ...styles,
        fontSize: 16,
        lineHeight: `${rem(20)}`,
        padding: `${rem(6)} ${rem(4)}`,
      };
    },
  };

  const DropdownIndicator = (props: any): JSX.Element => {
    return (
      <components.DropdownIndicator {...props}>
        <span className='material-symbols-rounded'>arrow_drop_down</span>
      </components.DropdownIndicator>
    );
  };
  return (
    <FormWrapper className={`${containerClassName ?? ''} `}>
      {label != null && label !== '' && (
        <Label htmlFor={name} className={`${inputType === 'View' ? 'label-view' : ''} `}>
          {label}
          {!required && <span> (Optional)</span>}{' '}
          {tooltipText != null && (
            <Tooltip tooltipText={tooltipText} variant='dark' size='sm'>
              <span className='material-symbols-sharp'>info</span>
            </Tooltip>
          )}
        </Label>
      )}
      <DropdownContainer style={{ width: width ?? 'auto' }}>
        {multiple ?? false ? (
          <Select
            {...rest}
            id='color'
            menuPosition='fixed'
            menuPlacement='auto'
            // menuPosition='absolute'
            menuShouldBlockScroll
            styles={selectStyles}
            name={name}
            placeholder={placeholder}
            options={options}
            closeMenuOnSelect={true}
            isClearable={false}
            onChange={(option) => {
              const selectIds =
                Array.isArray(option) && option?.map((item: { value: any }) => item?.value);
              setFieldValue?.(name, selectIds);
              onChange(selectIds);
            }}
            components={{ DropdownIndicator }}
            value={options?.filter((obj) => values?.includes(obj?.value))}
            isMulti={multiple}
            isDisabled={disabled}
            defaultValue={defaultValue}
            menuPortalTarget={document.body}
            minMenuHeight={200}
            maxMenuHeight={300}
          />
        ) : (
          <>
            {inputType === 'View' ? (
              <Select
                {...rest}
                id='color'
                menuPosition='fixed'
                menuPlacement='auto'
                // menuPosition='absolute'
                menuShouldBlockScroll
                // menuShouldScrollIntoView
                placeholder={
                  fieldLabelName != null &&
                  CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                    ? arrValue == null
                      ? 'xxxxx'
                      : placeholder
                    : placeholder
                }
                styles={selectStyles}
                name={name}
                isClearable={isClearable}
                options={options}
                components={{ DropdownIndicator }}
                isDisabled={
                  fieldLabelName != null
                    ? CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                    : disabled
                }
                value={
                  arrValue != null || values?.[name] != null
                    ? options?.find((obj) => obj?.value === (arrValue ?? values?.[name]))
                    : null
                }
                onChange={(option) => {
                  setFieldValue?.(name, option?.value);
                  onChange(option?.value);
                }}
                menuPortalTarget={document.body}
                minMenuHeight={200}
                maxMenuHeight={300}
              />
            ) : (
              <Select
                {...rest}
                id='color'
                menuPosition='fixed'
                menuPlacement='auto'
                // menuPosition='absolute'
                menuShouldBlockScroll
                placeholder={
                  fieldLabelName != null &&
                  CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                    ? arrValue == null
                      ? 'xxxxx'
                      : placeholder
                    : placeholder
                }
                styles={selectStyles}
                name={name}
                isClearable={isClearable}
                options={options}
                components={{ DropdownIndicator }}
                isDisabled={
                  fieldLabelName != null
                    ? CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                    : disabled
                }
                value={
                  allOptions != null && arrValue != null
                    ? allOptions?.find((obj) => obj?.value === (arrValue ?? values?.[name]))
                    : arrValue != null || Boolean(values?.[name])
                    ? options?.find((obj) => obj?.value === (arrValue ?? values?.[name]))
                    : null
                }
                onChange={(option) => {
                  takeName
                    ? setFieldValue?.(name, option?.label)
                    : setFieldValue?.(name, option?.value);
                  onChange(option?.value);
                }}
                menuPortalTarget={document.body}
                defaultValue={defaultValue}
                minMenuHeight={200}
                maxMenuHeight={300}
              />
            )}
          </>
        )}
        {!isTable && isFormik && (
          <ErrorMessage name={name} component='div' className='field-error-message' />
        )}
      </DropdownContainer>
    </FormWrapper>
  );
});

export default FormSelect;
