import styled, { css } from 'styled-components';
import { COLORS } from '../../../constants/style/color';
import { rem } from '../../../utils/style';

interface estimationType {
  array?: number;
}

export const EstimationTrackingAccordingContainer = styled.div`
  background: ${COLORS.Grey400Transparent};
  /* opacity: 0.5; */
  border-bottom: 1px solid ${COLORS.LightGray};
  border-radius: 6px 6px 0px 0px;
  padding: ${rem(13)} ${rem(15)};
  font-weight: 500;
`;

export const EstimationTrackingAccordingValue = styled.div<estimationType>`
  max-height: 375px;
  overflow-y: visible;
  ${(props) => {
    if (props?.array && props?.array > 4) {
      return css`
        overflow-y: scroll;
      `;
    }
  }}
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  background: ${COLORS.Grey100Transparent};
  border-radius: 0px 0px 6px 6px;
  padding: ${rem(21)} ${rem(32)};
`;
export const EstimationTrackingHeaders = styled.div`
  padding-bottom: ${rem(15)};
  border-bottom: 1px solid ${COLORS.LightGray};
`;
export const EstimationTrackingValue = styled.div`
  padding: ${rem(21)} ${rem(0)} ${rem(0)} ${rem(0)};
  border-bottom: 1px solid ${COLORS.LightGray};
  &:last-child {
    border-bottom: 0;
  }
`;
