import { type UseMutationResult, useMutation } from 'react-query';
import { ParticipantService } from '../../services/wealthlane-award-services';

const useGetAwardedParticipantList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setModalData: any,
  openModal: () => void
): UseMutationResult<any, unknown, void, unknown> => {
  return useMutation(
    async () => {
      return await handleRequest(
        ParticipantService.getApiAwardApiAppParticipantAwardedParticipants()
      );
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          setModalData(response);
          openModal();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetAwardedParticipantList;
