import { type UseQueryResult, useQuery } from 'react-query';
import { OptionAwardsPreferencesService } from '../../services/wealthlane-award-services';

const useGetTablePreferences = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableName: string
): UseQueryResult<string[] | undefined, unknown> => {
  const tablePreferances = useQuery(['getTablePreferences', tableName], async () => {
    return await handleRequest(
      OptionAwardsPreferencesService.getApiAwardApiAppOptionAwardsPreferencesOptionAwardsPreferences(
        tableName
      )
    );
  });

  return tablePreferances;
};

export default useGetTablePreferences;
