/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto } from '../models/Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto';
import type { Wealthlane_AwardService_Dtos_RSUAwardApproval_RSUAwardApprovalDto } from '../models/Wealthlane_AwardService_Dtos_RSUAwardApproval_RSUAwardApprovalDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RsuAwardApprovalService {

    /**
     * @param approvalStatusIds 
     * @param fromDate 
     * @param toDate 
     * @param employeeStatuses 
     * @param rsuStatus 
     * @param rsuAcceptance 
     * @param createdFromDate 
     * @param createdToDate 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAwardList(
approvalStatusIds?: Array<number>,
fromDate?: string,
toDate?: string,
employeeStatuses?: Array<number>,
rsuStatus?: number,
rsuAcceptance?: number,
createdFromDate?: string,
createdToDate?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_RsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAward-list',
            query: {
                'ApprovalStatusIds': approvalStatusIds,
                'FromDate': fromDate,
                'ToDate': toDate,
                'EmployeeStatuses': employeeStatuses,
                'RSUStatus': rsuStatus,
                'RSUAcceptance': rsuAcceptance,
                'CreatedFromDate': createdFromDate,
                'CreatedToDate': createdToDate,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardApprovalApproveRSuAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_RSUAwardApproval_RSUAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward-approval/approve-rSUAwards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardApprovalApproveBulkRSu(
requestBody?: Array<Wealthlane_AwardService_Dtos_RSUAwardApproval_RSUAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward-approval/approve-bulk-rSU',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardApprovalRejectBulkRSu(
requestBody?: Array<Wealthlane_AwardService_Dtos_RSUAwardApproval_RSUAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward-approval/reject-bulk-rSU',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardApprovalRejectRSuAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_RSUAwardApproval_RSUAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward-approval/reject-rSUAwards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAwardStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAward-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalEmployeeStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/employee-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUStatuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAcceptances(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAcceptances',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAwardOverview(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAward-overview/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAwardVestingSchedule(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAward-vesting-schedule/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAwardDocuments(
rsuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAward-documents/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardApprovalExportRSuAwardVestingSchedule(
rsuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward-approval/export-rSUAward-vesting-schedule/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppRSUAwardApprovalDownloadDocument(
requestBody?: Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/r-sUAward-approval/download-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAwardStatusById(
rsuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAward-status-by-id/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAwardSettlementHistory(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAward-settlement-history/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppRSUAwardApprovalRSUAwardNumbers(
rsuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/r-sUAward-approval/r-sUAward-numbers/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
