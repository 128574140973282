import { type UseQueryResult, useQuery } from 'react-query';
import { CountryService } from '../../services/wealthlane-customer-services';

const useGetCountryCode = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
      isOtherCountry: boolean;
    }>
  | undefined,
  unknown
> => {
  const countryCodeOptions = useQuery(
    ['getCountriesCode'],
    async () => {
      return await handleRequest(CountryService.getApiCustomerApiAppCountryCountries());
    },
    {
      select: (data) => {
        if (data != null) {
          data.unshift(
            data.splice(
              data.findIndex((item) => item.isOtherCountry === false),
              1
            )[0]
          );
          return data
            ?.filter(
              (notEmpty: any) =>
                notEmpty?.abbreviation !== null || notEmpty?.countryCallingCode !== null
            )
            .map((item) => {
              return {
                label:
                  `(${String(item.countryCallingCode ?? '')}) ${String(item.name ?? '')}` ?? '',
                value: item.id ?? '',
                isOtherCountry: item.isOtherCountry ?? false,
              };
            });
        }
      },
    }
  );

  return countryCodeOptions;
};

export default useGetCountryCode;
