/* eslint-disable camelcase */
import { type UseMutationResult, useMutation } from 'react-query';
import {
  OptionAwardService,
  RsuAwardService,
  type Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateResponseDto,
  type Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportResponseDTO,
} from '../../services/wealthlane-award-services';
import {
  ParticipantService,
  type Wealthlane_CustomerService_Dtos_Participant_ImportParticipantResponseDto,
} from '../../services/wealthlane-customer-services';

const usePutFileImport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setImportedData: any,
  requestedModule: string
): UseMutationResult<
  | Wealthlane_CustomerService_Dtos_Participant_ImportParticipantResponseDto
  | Wealthlane_AwardService_Dtos_RSUAward_RSUAwardUpdateImportResponseDTO
  | Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBulkUpdateResponseDto
  | undefined,
  unknown,
  any,
  unknown
> => {
  return useMutation(
    async (payload: any) => {
      if (requestedModule.includes('RSU')) {
        return await handleRequest(
          RsuAwardService.postApiAwardApiAppRSUAwardImportRsuAwardsForUpdate(payload)
        );
      } else if (requestedModule.includes('Option')) {
        return await handleRequest(
          OptionAwardService.postApiAwardApiAppOptionAwardOptionAwardsBulkUpdate(payload)
        );
      } else {
        return await handleRequest(
          ParticipantService.postApiCustomerApiAppParticipantImportParticipants(payload)
        );
      }
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          setImportedData(response);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePutFileImport;
