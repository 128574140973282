import { type ErrorEvent, type SuccessEvent, useFinchConnect } from 'react-finch-connect';
import { SettingSystemUserHeader } from '../system-users/systemUserStyled';
import Heading from '../../../components/typography';
import { COLORS } from '../../../constants/style/color';
import { TaxSettlementContainer } from '../settlement-config/settlementConfigStyled';
import Button from '../../../components/button';
import {
  useBoolean,
  useGetIntegrationProvider,
  usePostAuthorizationCode,
  usePostCheckFinchConnection,
  usePostRevokeAuthorization,
  useWealthlaneHRIntegrationApi,
} from '../../../hooks';
import FinchConfirmationModal from './confirmationModal';
import Spinners from '../../../components/spinner';
import { FinchInfo } from './finch.styled';

const FinchComponent = (): JSX.Element => {
  const { handleRequest } = useWealthlaneHRIntegrationApi();
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);

  const { data } = useGetIntegrationProvider(handleRequest);
  const { mutate, isLoading: loading } = usePostAuthorizationCode(handleRequest);
  const { data: isConnected, status } = usePostCheckFinchConnection(handleRequest);
  const { mutate: revoke, isLoading } = usePostRevokeAuthorization(handleRequest);

  const onSuccess = ({ code }: SuccessEvent): void => {
    const payload = {
      authorizationCode: code,
      userId: data?.userId ?? '',
    };
    mutate(payload);
  };
  const onError = ({ errorMessage }: ErrorEvent): void => {
    console.error(errorMessage);
  };
  const onClose = (): void => {
    console.log('User exited Finch Connect');
  };

  // 1. Initialize Finch Connect
  const { open } = useFinchConnect({
    clientId: data?.clientId ?? '',
    products: data?.products ?? [],
    onSuccess,
    onError,
    onClose,
    sandbox: true,
  });

  const finchInformation = [
    'Read basic Company Data.',
    'Read company directory and organization structure.',
    'Read individual data, excluding income and employment.',
    'Read individual employment and income data.',
    'Read payroll and contractor related payments by the company',
    'Read detailed pay statements for each employee.',
  ];

  const checkIcon = <span className='material-symbols-outlined text-3xl'>check_circle</span>;

  return (
    <>
      <SettingSystemUserHeader>
        <Heading variant='h3' title='HR Payroll Integration' />
        <Heading
          variant='body-s'
          title='Secure your account & manage your password'
          color={COLORS.Gray}
        />
      </SettingSystemUserHeader>
      <TaxSettlementContainer className='pb-8'>
        {status === 'loading' && <Spinners />}
        {status === 'success' && (
          <>
            <div className='flex justify-between items-center'>
              <div>
                <Heading variant='h4' title='Finch Integration' className='mb-2' />
                {/* <div className='flex gap-1'>
                {(isConnected?.connected ?? false) && (
                  <span className='material-symbols-outlined finch-connected--icon'>
                    check_circle
                  </span>
                )}
                <Heading
                  variant='body-xs'
                  title={isConnected?.connected ?? false ? 'Connected' : 'Not Connected'}
                  color={COLORS.Gray}
                />
              </div> */}
              </div>
              {isConnected?.connected ?? false ? (
                <div className='flex gap-5'>
                  <Heading
                    variant='body-s'
                    title={
                      isLoading ? <Spinners size='sm' /> : <>{checkIcon} Integration Successful</>
                    }
                    className='disconnect-button gap-x-2'
                    // onClick={() => {
                    //   revoke();
                    // }}
                  />
                  <Button
                    title='Remove Integration'
                    variant='primary'
                    onClick={() => {
                      openModal();
                    }}
                    loading={loading}
                    disabled={data == null}
                  />
                </div>
              ) : (
                <Button
                  title='Integrate with Finch'
                  variant='primary'
                  onClick={() => {
                    openModal();
                  }}
                  loading={loading}
                  disabled={data == null}
                />
              )}
            </div>
            <FinchInfo>
              <div className=''>
                <div className='flex gap-x-4 mb-2'>
                  <Heading
                    variant='body-xs'
                    title='Connecting to FINCH will give permissions to access:'
                    className='info'
                  />
                </div>
                {finchInformation?.map((item: string) => (
                  <div className='flex gap-x-4'>
                    <div className={`icon`}>{checkIcon}</div>
                    <Heading variant='body-xs' title={`${item}`} className='info' />
                  </div>
                ))}
              </div>
            </FinchInfo>
          </>
        )}
      </TaxSettlementContainer>
      <FinchConfirmationModal
        isShow={isModalOpen}
        handleOpen={() => {
          closeModal();
          isConnected?.connected === true ? revoke() : open();
        }}
        closeModal={closeModal}
        connection={isConnected?.connected}
        title={`${
          isConnected?.connected === true
            ? 'remove integration'
            : 'connect your payroll and HR system'
        }`}
      />
    </>
  );
};

export default FinchComponent;
