import { Form, Formik } from 'formik';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import ModalBody from '../../../components/modal/modalBodyComponent';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import CustomTextArea from '../../../components/text-area/textArea';
import { usePostDepartment } from '../../../hooks';
import { type ModalStatusType } from '../../../types/common';
import { DEPARTMENT_VALIDATION } from '../../../utils/validations/validations';
import _ from 'lodash';

interface DepartmentModalType {
  isModalOpen: boolean;
  closeModal: () => void;
  setModalStatus: (value: ModalStatusType) => void;
  initialData: {
    data: {
      name: string;
      description: string;
    };
    id: string;
    modalStatus: ModalStatusType;
  };
  loading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
}

const DepartmentModal = ({
  isModalOpen,
  closeModal,
  initialData,
  setModalStatus,
  loading,
  handleRequest,
}: DepartmentModalType): JSX.Element => {
  const { mutate, isLoading } = usePostDepartment(handleRequest, closeModal, initialData.id);

  return (
    <Formik
      enableReinitialize
      initialValues={initialData.data}
      validationSchema={DEPARTMENT_VALIDATION}
      onSubmit={(values) => {
        mutate(values);
      }}>
      {({ touched, handleChange, errors, values }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            loading={loading}
            size='md'
            valueEdited={_.isEqual(values, initialData.data)}
            title={`${initialData.modalStatus} Department`}
            showToolTip={true}
            tooltipText={`Permissions allow a company to control its users’ access levels to the module and its fields depending on their roles and functions. Users can have multiple levels of access such as creating and editing a module, to read-only or none at all.`}
            children={
              <Form>
                <ModalBody>
                  <InputText
                    type='text'
                    label='Name'
                    name='name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    inputType={initialData.modalStatus}
                    disabled={initialData.modalStatus === 'View'}
                    required
                  />
                  <CustomTextArea
                    label='Description'
                    rows='5'
                    name='description'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    disabled={initialData.modalStatus === 'View'}
                  />
                </ModalBody>
                <ModalFooterComponent
                  modalStatus={initialData.modalStatus}
                  onCloseModal={() => {
                    closeModal();
                  }}
                  onEditModal={() => {
                    setModalStatus('Edit');
                  }}
                  valueChanged={_.isEqual(values, initialData.data)}
                  loading={isLoading}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default DepartmentModal;
