/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_ReportService_Dtos_ReportTablePrefrence_PutTablePreferencesRequest } from '../models/Wealthlane_ReportService_Dtos_ReportTablePrefrence_PutTablePreferencesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportTablePreferenceService {

    /**
     * @param reportName 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiReportApiAppReportTablePreferenceTablePreferences(
reportName?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/report-table-preference/table-preferences',
            query: {
                'reportName': reportName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static putApiReportApiAppReportTablePreferenceTablePreferences(
requestBody?: Wealthlane_ReportService_Dtos_ReportTablePrefrence_PutTablePreferencesRequest,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/report/api/app/report-table-preference/table-preferences',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
