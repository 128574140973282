/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_BankAccountVerificationTypeDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_BankAccountVerificationTypeDto';
import type { Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankForViewDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankForViewDto';
import type { Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken } from '../models/Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken';
import type { Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto';
import type { Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyBankService {

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppCompanyBankLinkToken(): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/company-bank/link-token',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_BankAccountVerificationTypeDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppCompanyBankVerificationTypeList(): CancelablePromise<Array<Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_BankAccountVerificationTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/company-bank/verification-type-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto Success
     * @throws ApiError
     */
    public static postApiFinanceIntegrationApiAppCompanyBankSavePublicTokenMetaData(
requestBody?: Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/finance-integration/api/app/company-bank/save-public-token-meta-data',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiFinanceIntegrationApiAppCompanyBankMakeTaxAccount(
companyBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/finance-integration/api/app/company-bank/make-tax-account/{companyBankId}',
            path: {
                'companyBankId': companyBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiFinanceIntegrationApiAppCompanyBankMakeExerciseAccount(
companyBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/finance-integration/api/app/company-bank/make-exercise-account/{companyBankId}',
            path: {
                'companyBankId': companyBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiFinanceIntegrationApiAppCompanyBankMakeExerciseAndTaxAccount(
companyBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/finance-integration/api/app/company-bank/make-exercise-and-tax-account/{companyBankId}',
            path: {
                'companyBankId': companyBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppCompanyBankCompanyBankList(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/company-bank/company-bank-list',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankForViewDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppCompanyBankCompanyBankForView(
companyBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankForViewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/company-bank/company-bank-for-view/{companyBankId}',
            path: {
                'companyBankId': companyBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiFinanceIntegrationApiAppCompanyBankVerifyManualBankAccount(
requestBody?: Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_SavePublicTokenMetaDataDto,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/finance-integration/api/app/company-bank/verify-manual-bank-account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppCompanyBankLinkTokenForManualBankAccountVerification(
companyBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/company-bank/link-token-for-manual-bank-account-verification/{companyBankId}',
            path: {
                'companyBankId': companyBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiFinanceIntegrationApiAppCompanyBankCompanyBankAccount(
companyBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/finance-integration/api/app/company-bank/company-bank-account/{companyBankId}',
            path: {
                'companyBankId': companyBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
