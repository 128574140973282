/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_PrefrencePostDto } from '../models/Wealthlane_AwardService_Dtos_PrefrencePostDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OptionAwardsPreferencesService {

    /**
     * @param tableName 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardsPreferencesOptionAwardsPreferences(
tableName?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-awards-preferences/option-awards-preferences',
            query: {
                'tableName': tableName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static putApiAwardApiAppOptionAwardsPreferencesOptionAwardsPreferences(
requestBody?: Wealthlane_AwardService_Dtos_PrefrencePostDto,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/option-awards-preferences/option-awards-preferences',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
