import { type UseQueryResult, useQuery } from 'react-query';
import {
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto,
  DefaultSettlementConfigurationService,
} from '../../services/wealthlane-award-settlement-services';

const useGetDefaultSettlementConfiguration = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto[]
  | undefined,
  unknown
> => {
  const getDefaultSettlementConfigurationList = useQuery(
    ['getDefaultSettlementConfigurationList'],
    async () => {
      return await handleRequest(
        DefaultSettlementConfigurationService.getApiAwardSettlementApiAppDefaultSettlementConfigurationDefaultSettlementConfigurations()
      );
    }
  );
  return getDefaultSettlementConfigurationList;
};

export default useGetDefaultSettlementConfiguration;
