import Tag from '../../components/lozenge/tags';
import { ActionButton } from '../../components/vertical-dot/popper';
import { TableBodyCellColumn } from '../style/table.styled';

export const GrantManagementHeader = (action: any): any[] => {
  return [
    {
      Header: 'Grant Name',
      accessor: 'grantName',
    },
    {
      Header: 'Grant #',
      accessor: 'uniqueGrantNo',
      Cell: ({ row }: any) => {
        return <TableBodyCellColumn>{row.original.uniqueGrantNo}</TableBodyCellColumn>;
      },
    },
    {
      Header: ' Grant Type',
      accessor: 'planTypeName',
      width: 100,
      Cell: ({ row }: any) => (
        <Tag
          variant={
            row.original.planTypeName === 'RSU'
              ? 'success'
              : row.original.planTypeName === 'ISO'
              ? 'primary'
              : row.original.planTypeName === 'PSU'
              ? 'pink-tag'
              : 'info'
          }
          title={row.original.planTypeName}
        />
      ),
    },

    {
      Header: 'Vesting Schedule',
      accessor: 'vestingScheduleName',
    },
    {
      Header: 'Plan Name',
      accessor: 'planName',
    },
    {
      Header: 'Grant Vest Status',
      accessor: 'grantStatus',
      Cell: ({ row }: any) => (
        <Tag
          variant={
            row.original.grantStatus === 'Outstanding'
              ? 'success'
              : row.original.grantStatus === 'Pending'
              ? 'info'
              : 'primary'
          }
          title={row.original.grantStatus}
        />
      ),
    },
    {
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={action}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
