import React, { useEffect, EffectCallback, useState } from 'react';
import { Label } from '../input-text/input.styled';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import { isEmpty } from 'lodash';

interface RichTextType {
  name: string;
  setFieldValue: (name: string, value: string) => void;
  value: string;
  error?: any;
  touched?: any;
  initialContentState?: any;
  label?: string;
}

const RichTextEditor = ({
  name,
  setFieldValue,
  value,
  error,
  touched,
  label,
  initialContentState,
}: RichTextType): JSX.Element => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks)
    )
  );
  const onEditorStateChange = (editorState: any): void => {
    setEditorState(editorState);
  };
  useEffect(() => {
    if (value !== '') {
      setFieldValue(name, value);
    }
  }, [value]);

  return (
    <div>
      <>
        <Label>{label ? label : 'Description'}</Label>
        <Editor
          data
          editorState={editorState}
          placeholder='Your text here...'
          toolbarClassName='toolbarClassName'
          wrapperClassName={`wrapperClassName  ${
            Boolean(error) && Boolean(touched) ? 'border border-red-700' : 'border'
          }`}
          editorClassName='editorClassName px-4 min-h-[80px] text-[16px] '
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['inline', 'list'],
            inline: {
              options: ['bold', 'italic', 'underline'],
              bold: { className: 'customBold' },
            },

            textAlign: { inDropdown: true },
          }}
          onChange={(event: any, editor: any) => {
            // setText(isEmpty(event.blocks[0].text));
            !isEmpty(event.blocks[0].text)
              ? setFieldValue(name, draftToHtml(convertToRaw(editorState.getCurrentContent())))
              : setFieldValue(name, '');
          }}
        />
      </>
    </div>
  );
};

export default RichTextEditor;
