import React from 'react';
import { Progress } from './progressBar.styled';

const ProgressBarCustom = (props: any): JSX.Element => {
  const {
    size = 38,
    progress = 0,
    trackWidth = 5,
    trackColor = `#ddd`,
    indicatorWidth = 5,
    indicatorColor = `#07c`,
    indicatorCap = `round`,
    label = `Loading...`,
    labelColor = `#333`,
    spinnerMode = false,
    spinnerSpeed = 1,
  } = props;

  const center = size / 2;
  const radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  const hideLabel = !!(
    size < 100 ||
    (label !== undefined && label.length === 0) ||
    (spinnerMode !== undefined && spinnerMode !== null)
  );

  return (
    <>
      <Progress>
        <div className='svg-pi-wrapper' style={{ width: size, height: size }}>
          <svg className='svg-pi' style={{ width: size, height: size }}>
            <circle
              className='svg-pi-track'
              cx={center}
              cy={center}
              fill='transparent'
              r={radius}
              stroke={trackColor}
              strokeWidth={trackWidth}
            />
            <circle
              className={`svg-pi-indicator ${
                spinnerMode != null ? 'svg-pi-indicator--spinner' : ''
              }`}
              style={{ animationDuration: `${spinnerSpeed * 1000}` }}
              cx={center}
              cy={center}
              fill='transparent'
              r={radius}
              stroke={indicatorColor}
              strokeWidth={indicatorWidth}
              strokeDasharray={dashArray}
              strokeDashoffset={dashOffset}
              strokeLinecap={indicatorCap}
            />
          </svg>

          {!hideLabel && (
            <div className='svg-pi-label' style={{ color: labelColor }}>
              <span className='svg-pi-label__loading'>{label}</span>
              {(spinnerMode == null || spinnerMode === false) && (
                <span className='svg-pi-label__progress'>
                  {`${(progress > 100 ? 100 : progress) as number}%`}
                </span>
              )}
            </div>
          )}
        </div>
      </Progress>
    </>
  );
};

export default ProgressBarCustom;
