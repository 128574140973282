import { type UseQueryResult, useQuery } from 'react-query';
import { EmployeeTypeService } from '../../services/wealthlane-customer-services';

const useGetEmployeeTypeList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const participantEmployeeTypeList = useQuery(
    ['getParticipantEmployeeTypeList'],
    async () => {
      return await handleRequest(
        EmployeeTypeService.getApiCustomerApiAppEmployeeTypeEmployeeTypesList()
      );
    },
    {
      select: (data) => {
        return data?.items?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return participantEmployeeTypeList;
};

export default useGetEmployeeTypeList;
