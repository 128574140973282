/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardSettlementService_Dtos_DefaultSettlementConfigurationAuditTrail_GetDefaultSettlementConfigurationAuditTrailDto_Wealthlane_AwardSettlementService_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardSettlementService_Dtos_DefaultSettlementConfigurationAuditTrail_GetDefaultSettlementConfigurationAuditTrailDto_Wealthlane_AwardSettlementService_Application_Contracts';
import type { Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto } from '../models/Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto';
import type { Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_SaveDefaultSettlementConfigurationRequestDto } from '../models/Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_SaveDefaultSettlementConfigurationRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultSettlementConfigurationService {

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardSettlementService_Dtos_DefaultSettlementConfigurationAuditTrail_GetDefaultSettlementConfigurationAuditTrailDto_Wealthlane_AwardSettlementService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppDefaultSettlementConfigurationDefaultSettlementConfigurationAuditTrails(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardSettlementService_Dtos_DefaultSettlementConfigurationAuditTrail_GetDefaultSettlementConfigurationAuditTrailDto_Wealthlane_AwardSettlementService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/default-settlement-configuration/default-settlement-configuration-audit-trails',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppDefaultSettlementConfigurationDefaultSettlementConfigurations(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/default-settlement-configuration/default-settlement-configurations',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppDefaultSettlementConfigurationSaveDefaultSettlementConfiguration(
requestBody?: Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_SaveDefaultSettlementConfigurationRequestDto,
): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/default-settlement-configuration/save-default-settlement-configuration',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
