/* eslint-disable camelcase */
import { type UseQueryResult, useQuery } from 'react-query';
import {
  TaxItemApprovalService,
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-tax-services';
import { type TableParamsType } from '../../types/common';
import {
  TaskManagerConsoleService,
  Volo_Abp_Application_Dtos_PagedResultDto_1,
} from '../../services/wealthlane-task-manager-services';

const useGetTmcList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<Volo_Abp_Application_Dtos_PagedResultDto_1 | undefined, unknown> => {
  const {
    createdFromDate,
    createdToDate,
    taskTypeId,
    reportStatusId,
    createdById,
    filter,
    sorting,
    skipCount,
    maxResultCount,
  } = tableInfo;
  const tmcList = useQuery(['getTmcList', tableInfo], async () => {
    return await handleRequest(
      TaskManagerConsoleService.getApiTaskmanagerApiAppTaskManagerConsoleTaskManagerConsoleList(
        createdFromDate,
        createdToDate,
        taskTypeId,
        reportStatusId,
        createdById,
        filter,
        sorting,
        skipCount,
        maxResultCount
      )
    );
  });

  return tmcList;
};

export default useGetTmcList;
