/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_CustomerRole_CreateCustomerRoleDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerRole_CreateCustomerRoleDto';
import type { Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto';
import type { Wealthlane_CustomerService_Dtos_CustomerRole_DeleteCustomerRoleResponseDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerRole_DeleteCustomerRoleResponseDto';
import type { Wealthlane_CustomerService_Dtos_CustomerRole_UpdateCustomerRoleDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerRole_UpdateCustomerRoleDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerRoleService {

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerRoleCustomerRole(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerRole_CreateCustomerRoleDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-role/customer-role',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerRoleId 
     * @param isConfirm 
     * @returns Wealthlane_CustomerService_Dtos_CustomerRole_DeleteCustomerRoleResponseDto Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppCustomerRoleCustomerRole(
customerRoleId?: string,
isConfirm?: boolean,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerRole_DeleteCustomerRoleResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/customer-role/customer-role',
            query: {
                'CustomerRoleId': customerRoleId,
                'IsConfirm': isConfirm,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerRoleCustomerRole(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-role/customer-role',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppCustomerRoleCustomerRole(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerRole_UpdateCustomerRoleDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/customer-role/customer-role',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerRoleCustomerRoleByCustomerRoleId(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-role/{id}/customer-role-by-customer-role-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerRoleCustomerRoleByCustomerRoleIdForCustomerUserApproval(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-role/{id}/customer-role-by-customer-role-id-for-customer-user-approval',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
