import React from 'react';
import Heading from '../../../components/typography';
import { ProfilePic } from './profile.styled';
import ProfilePicImage from '../../../assets/images/profilepic.png';
import { useGetProfileImage, usePostCompanyProfileLogo } from '../../../hooks';

interface ProfileUploadType {
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
}

const ProfileUpload = ({ handleRequest }: ProfileUploadType): JSX.Element => {
  const { data: ProfileImage } = useGetProfileImage(handleRequest);
  const { mutate } = usePostCompanyProfileLogo(handleRequest);

  const handleProfileChange = (e: any): void => {
    const payload = {
      CompanyLogo: e.target.files[0],
    };

    mutate(payload);
  };

  return (
    <ProfilePic>
      <label className='' role='button'>
        <span className='material-symbols-outlined icon'>add_a_photo</span>
        <Heading variant='body-xs' title='Change' className='profilePicText' />
        <input type='file' accept='.jpg, .png, .jpeg' onChange={handleProfileChange} />
      </label>

      {(ProfileImage?.content as unknown as boolean) ? (
        <img
          src={`data:image/png;base64,${ProfileImage?.content ?? ''}`}
          alt={`${ProfileImage?.name ?? ''}`}
        />
      ) : (
        <img src={ProfilePicImage} alt='User Profile' />
      )}
    </ProfilePic>
  );
};

export default ProfileUpload;
