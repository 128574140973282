/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_TaskManagerService_Dtos_TaskType_TaskTypeDto } from '../models/Wealthlane_TaskManagerService_Dtos_TaskType_TaskTypeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaskTypeService {

    /**
     * @returns Wealthlane_TaskManagerService_Dtos_TaskType_TaskTypeDto Success
     * @throws ApiError
     */
    public static getApiTaskmanagerApiAppTaskTypeTaskTypeList(): CancelablePromise<Array<Wealthlane_TaskManagerService_Dtos_TaskType_TaskTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/taskmanager/api/app/task-type/task-type-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
