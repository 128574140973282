import { type UseQueryResult, useQuery } from 'react-query';
import { PsuAwardService } from '../../services/wealthlane-award-services';

const useGetPsuAcceptance = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const psuAcceptanceList = useQuery(
    ['getPsuAcceptanceList'],
    async () => {
      return await handleRequest(PsuAwardService.getApiAwardApiAppPSUAwardPSUAcceptances());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return psuAcceptanceList;
};

export default useGetPsuAcceptance;
