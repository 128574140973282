import { getShadow, rem } from '../../utils/style';
import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/style/color';

interface StyledTabPanelType {
  active: boolean;
}
interface TabHeaderContainerType {
  variant?: 'default' | 'primary' | 'fit-content';
}

interface StylizedTabType {
  variant?: 'default' | 'primary' | 'fit-content';
  size: 'md' | 'lg';
}

export const TabContent = styled.div`
  max-width: 100%;
  margin: 0;
`;

export const TabHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${rem(24)};

  @media (max-width: 512px) {
    width: 100%;
  }

  .filter-slider {
    pointer-events: none;
    position: absolute;
    padding: 0.3rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;

export const StyledTabPanel = styled.div<StyledTabPanelType>`
  display: ${(p) => (p.active ? 'flex' : 'none')};
  font-size: 4rem;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const TabsHolder = styled.div<TabHeaderContainerType>`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 3px;
  gap: 0.2rem;
  width: 100%;

  &.tab-vertical {
    flex-direction: column;
    background-color: ${COLORS.Gray1020};
  }

  @media (min-width: 512px) {
    flex-direction: row;
    width: max-content;
  }

  &.centered {
    margin: 0 auto;
  }

  ${(props) => {
    if (props.variant === 'primary') {
      return css`
        background-color: ${COLORS.White};
        @include ${getShadow({ variant: 'soft-wide' })};
      `;
    }
    if (props.variant === 'default') {
      return css`
        background-color: ${COLORS.OffWhite};
      `;
    }
    if (props.variant === 'fit-content') {
      return css`
        background-color: ${COLORS.OffWhite};
      `;
    }
  }};
`;

export const StylizedTab = styled.div<StylizedTabType>`
  font-weight: 500;
  text-align: left;
  position: relative;
  z-index: 0;
  cursor: pointer;

  border-radius: ${rem(6)};
  transition: all 0.2s ease-in-out;
  padding: ${(props) => (props.size === 'lg' ? `${rem(11)} ${rem(14)}` : `${rem(10)} ${rem(17)}`)};

  .underline-slider {
    height: 44px;
    background: ${COLORS.White};
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;

    &.underline-slider-small {
      height: 34px;
    }
  }

  ${(props) => {
    if (props.variant === 'default') {
      return css`
        &:hover,
        &.active {
          .underline-slider {
            background-color: ${COLORS.White};
            box-shadow: ${rem(0)} ${rem(2)} ${rem(3)} ${COLORS.DefaultShadow};
          }

          span {
            color: ${COLORS.Purple};
            font-weight: 500;
          }
        }
      `;
    }

    if (props.variant === 'fit-content') {
      return css`
        &:hover,
        &.active {
          .underline-slider {
            background-color: ${COLORS.White};
            box-shadow: ${rem(0)} ${rem(2)} ${rem(3)} ${COLORS.DefaultShadow};
          }

          span {
            color: ${COLORS.Purple};
            font-weight: 500;
          }
        }
      `;
    }

    if (props.variant === 'primary') {
      return css`
        &:hover,
        &.active span {
          color: ${COLORS.Purple};
          font-weight: 500;
        }

        &:hover,
        &.active .underline-slider {
          background-color: ${COLORS.LightPurple};
          box-shadow: ${rem(0)} ${rem(2)} ${rem(3)} ${COLORS.DefaultShadow};
        }
      `;
    }
  }};

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;
