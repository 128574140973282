import { type UseQueryResult, useQuery } from 'react-query';

import {
  RsuAwardService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto,
} from '../../services/wealthlane-award-services';

const useGetRsuAwardOverview = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  awardType?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto | undefined,
  unknown
> => {
  const optionAwardDetails = useQuery(['rsuAwardDetails', id], async () => {
    if (id && awardType === 'RSU') {
      return await handleRequest(
        RsuAwardService.getApiAwardApiAppRSUAwardRSUAwardDetailByRSuVestingDateId(id)
      );
    }
  });

  return optionAwardDetails;
};

export default useGetRsuAwardOverview;
