/* eslint-disable camelcase */
// import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { isEmpty } from 'lodash';
import { PlanService, ReleaseService } from '../../services/wealthlane-award-services';

const useGetCheckPlanGrantDocumentName = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  type?: string | number | null,
  //   id: string | null | undefined,
  setCheckNameError?: any
) => {
  //   const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      const newPayload = {
        documentName: payload,
        documentType: type,
        // planId: id,
      };
      if (!isEmpty(payload)) {
        return await handleRequest(
          PlanService.postApiAwardApiAppPlanCheckIfExistPlanGrantDocumentName(payload)
        );
      }
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          setCheckNameError(response);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetCheckPlanGrantDocumentName;
