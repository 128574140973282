/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { type ModalStatusType, type OptionType } from '../../../../types/common';
import ModalComponent from '../../../../components/modal/modal';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';
import InputText from '../../../../components/input-text/inputText';
import _, { isEmpty } from 'lodash';
import FormSelect from '../../../../components/dropdown/formSelect';
import * as Yup from 'yup';
import InitialValuesForm from '../../../../constants/initialValues';
import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import {
  useGetTaxCityOptions,
  useGetTaxCountryOptions,
  useGetTaxJuridictionLevel,
  useGetTaxPeriodAll,
  useGetTaxStateOptions,
  usePostTaxSetup,
} from '../../../../hooks';
import {
  JurisdictionLevelComponent,
  TaxBracketComponent,
  UserDefinedCriteriaComponent,
} from './tax-block';
import ApplicableComponent from './tax-block/applicableComponent';
import {
  type Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemRequestDto,
  type Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto,
  type Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto,
} from '../../../../services/wealthlane-tax-services';
import { TAX_SETUP_VALIDATION } from '../../../../utils/validations/validations';
import useGetTaxNameOptions from '../../../../hooks/get/useGetTaxNameOptions';
import useGetTaxCountryOptionsWithJuridiction from '../../../../hooks/get/useGetTaxCountryOptionsWithJuridiction';
import useGetTaxStateOptionsByJurisdiction from '../../../../hooks/get/useGetTaxStateOptionsByJurisdiction';
import { DescriptionType, initialDescription } from './taxConstantTypes';

interface TaxSetupType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  loading?: boolean;
  initialValue?: Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto | null;
}

const TaxSetupModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  setModalStatus,
  loading,
  initialValue,
}: TaxSetupType): JSX.Element => {
  const optionState = {
    juridictionLevelId: initialValue?.jurisdictionLevelId ?? '',
    countryId: initialValue?.countryId ?? '',
    stateId: initialValue?.stateId ?? '',
  };
  const [optionSelectedState, setOptionSelectedState] =
    useState<Record<string, string>>(optionState);

  const { handleRequest } = useWealthlaneTaxApi();
  const { data: taxPeriodOption } = useGetTaxPeriodAll(handleRequest);
  const { data: juridictionLevelOptions } = useGetTaxJuridictionLevel(handleRequest);
  const [descriptionData, setDescriptionData] = useState<DescriptionType[]>([]);
  const { data: countryOptions } = useGetTaxCountryOptionsWithJuridiction(
    handleRequest,
    optionSelectedState?.juridictionLevelId
  );
  const { data: stateOptions } = useGetTaxStateOptionsByJurisdiction(
    handleRequest,
    optionSelectedState?.juridictionLevelId,
    optionSelectedState?.countryId !== '' ? optionSelectedState?.countryId : initialValue?.countryId
  );
  const { data: cityOptions } = useGetTaxCityOptions(
    handleRequest,
    optionSelectedState?.stateId !== '' ? optionSelectedState?.stateId : initialValue?.stateId
  );
  const [metaValidation, setMetaValidation] = useState<
    Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | undefined
  >();

  const [applicableName, setApplicableName] = useState<string>('');
  const { data: parentTax } = useGetTaxNameOptions(handleRequest);

  const { mutate, isLoading } = usePostTaxSetup(handleRequest, closeModal, modalStatus);
  const handleSubmit = (
    values: Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemRequestDto
  ): void => {
    mutate(values);
  };

  // useEffect(() => {
  //   optionSelectedState?.stateArrayIndex !== undefined &&
  //     setStateArrayOptions({
  //       ...stateArrayOptions,
  //       [`${optionSelectedState?.stateArrayIndex}`]: stateOptions,
  //     });
  // }, [optionSelectedState?.stateArrayIndex, stateOptions]);

  // useEffect(() => {
  //   optionSelectedState?.cityArrayIndex !== undefined &&
  //     setCityArrayOptions({
  //       ...cityArrayOptions,
  //       [`${optionSelectedState?.cityArrayIndex}`]: cityOptions,
  //     });
  // }, [optionSelectedState?.cityArrayIndex, cityOptions]);
  const [filteredValue, setFilteredValue] = useState<any>();

  const groupData = () => {
    let group =
      descriptionData !== undefined &&
      descriptionData[0]?.field !== undefined &&
      descriptionData[0]?.groupLogicalOperator !== undefined &&
      _(descriptionData)
        .groupBy('groupLogicalOperator')
        .map((data, groupLogicalOperator) => {
          let field = _(data)
            ?.groupBy('field')
            ?.map((item, field) => {
              let equality = _(item)
                ?.groupBy('equalityOperator')
                ?.map((equals, equalityOperator) => {
                  let name = equals.map((country: any) => country.name);
                  return { equalityOperator, name };
                })
                .value();
              return { field, equality };
            })
            .value();
          return {
            field,
            groupLogicalOperator,
          };
        })
        .value();
    setFilteredValue(group);
    return group;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValue ?? InitialValuesForm.taxSetup}
      validationSchema={Yup.object().shape(TAX_SETUP_VALIDATION(metaValidation, applicableName))}
      onSubmit={(values: any) => {
        // const udcPayload = values?.userDefinedCriteria !== null && {
        //   ...values.userDefinedCriteria,
        //   Conditions: values?.userDefinedCriteria?.Conditions?.map((item: any, i: number) => ({
        //     ...item,
        //     Field: values?.userDefinedCriteria?.Conditions[i]?.SubCriteria?.Conditions[0]?.Field,
        //     Value: values?.userDefinedCriteria?.Conditions[i]?.SubCriteria?.Conditions[0]?.Value,
        //     Name: values?.userDefinedCriteria?.Conditions[i]?.SubCriteria?.Conditions[0]?.Name,
        //     EqualityOperatorType:
        //       values?.userDefinedCriteria?.Conditions[i]?.SubCriteria?.Conditions[0]
        //         ?.EqualityOperatorType,
        //     SubCriteria: {
        //       ...values?.userDefinedCriteria?.Conditions[i]?.SubCriteria,
        //       Conditions:
        //         item?.SubCriteria?.Conditions?.length > 1
        //           ? item?.SubCriteria?.Conditions?.slice(1)
        //           : [],
        //     },
        //   })),
        // };

        const payload = {
          name: values?.name,
          jurisdictionLevelId: values?.jurisdictionLevelId,
          taxPeriodId: values?.taxPeriodId,
          taxCode: values?.taxCode,
          equityCompensationLevelId: values?.equityCompensationLevelId,
          countryId: values?.countryId,
          stateId: values?.stateId,
          stateText: '',
          cityId: values?.cityId,
          participantId: values?.participantId,
          planId: values?.planId,
          grantId: values?.grantId,
          awardId: values?.awardId,
          transactionId: values?.transactionId,
          id: modalStatus === 'Add' ? null : !isEmpty(values?.id) ? values?.id : null,
          taxBrackets: values?.taxBrackets?.map((item: any) => ({
            lowerLimit: Number(String(item?.lowerLimit)?.replaceAll(',', '') ?? ''),
            taxRate: Number(String(item?.taxRate)?.replaceAll(',', '') ?? ''),
            // upperLimit: Number(String(item?.upperLimit)?.replaceAll(',', '') ?? ''),
          })),
          userDefinedCriteria:
            values?.userDefinedCriteria !== null
              ? values?.userDefinedCriteria?.Conditions[0]?.SubCriteria?.Conditions[0]?.Field ===
                null ??
                values?.userDefinedCriteria?.Conditions[0]?.SubCriteria?.Conditions[0]?.Field === ''
                ? null
                : JSON.stringify(values?.userDefinedCriteria)
              : null,
        };

        handleSubmit(payload);
      }}>
      {({ touched, handleChange, errors, setFieldValue, setValues, setTouched, values }: any) => {
        const metaData: Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | undefined =
          juridictionLevelOptions?.filter(
            (item: OptionType) => item?.value === values?.jurisdictionLevelId
          )[0]?.metadata;

        useEffect(() => {
          setMetaValidation(metaData);
        }, [metaData]);

        useEffect(() => {
          groupData();
        }, [descriptionData, values]);

        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            loading={loading}
            modalStatus={modalStatus}
            valueEdited={_.isEqual(
              values,
              initialValue ? initialValue : InitialValuesForm.taxSetup
            )}
            size='lg'
            title={`${modalStatus === 'Add' ? 'Create' : modalStatus} Tax Setup`}
            children={
              <Form>
                <ModalBodyComponent>
                  <div className=''>
                    <div className='grid lg:grid-cols-12 md:grid-cols-3 sm:grid-cols-1 gap-x-8'>
                      <div className='lg:col-span-5  md:grid-cols-3  sm:col-span-1'>
                        <InputText
                          type='text'
                          label='Name'
                          name='name'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                      <div className='lg:col-span-3 sm:col-span-1'>
                        <FormSelect
                          label={'Tax Period'}
                          name='taxPeriodId'
                          containerClassName='formGroup'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={taxPeriodOption}
                          values={values}
                          setFieldValue={setFieldValue}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          menuWidth={'300px'}
                          required
                        />
                      </div>
                      <div className='lg:col-span-4 sm:col-span-1'>
                        <FormSelect
                          type='text'
                          label={'Jurisdiction Level'}
                          name='jurisdictionLevelId'
                          containerClassName='formGroup'
                          onChange={(e: any) => {
                            const meta = juridictionLevelOptions?.filter(
                              (item: OptionType) => item?.value === e
                            );
                            setOptionSelectedState({
                              ...optionSelectedState,
                              countryId:
                                meta[0]?.label?.includes('State') || meta[0]?.label === 'Local'
                                  ? ''
                                  : optionSelectedState?.countryId,
                              stateId: '',
                            });

                            setFieldValue('countryId', null);
                            setFieldValue('stateId', null);
                            setFieldValue('cityId', null);
                            setFieldValue(
                              'userDefinedCriteria',
                              meta[0]?.metadata?.allowUserDefinedCriteria
                                ? InitialValuesForm?.taxSetup?.userDefinedCriteria
                                : null
                            );
                            setDescriptionData([])
                            setOptionSelectedState({
                              ...optionSelectedState,
                              juridictionLevelId: e,
                              countryId: '',
                              stateId: '',
                            });
                            handleChange(e);
                          }}
                          errors={errors}
                          touched={touched}
                          options={juridictionLevelOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                      {values?.parentTaxItemId !== undefined && values?.parentTaxItemId !== null && (
                        <div className='lg:col-span-4 sm:col-span-1'>
                          <FormSelect
                            type='text'
                            label={'Parent Tax'}
                            name='parentTaxItemId'
                            containerClassName='formGroup'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={parentTax}
                            values={values}
                            setFieldValue={setFieldValue}
                            inputType={modalStatus}
                            disabled
                            required
                          />
                        </div>
                      )}
                    </div>

                    {/* Jurisdiction Level */}
                    {Boolean(values?.jurisdictionLevelId) && (
                      <JurisdictionLevelComponent
                        metaData={metaData}
                        countryOptions={countryOptions}
                        stateOptions={stateOptions}
                        cityOptions={cityOptions}
                        setOptionSelectedState={setOptionSelectedState}
                        optionSelectedState={optionSelectedState}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        modalStatus={modalStatus}
                        initialValue={initialValue}
                        descriptionData={descriptionData}
                        filteredValue={filteredValue}
                      />
                    )}
                    {/* User Defined Criteria */}
                    {(metaData?.allowUserDefinedCriteria ?? false) && (
                      <UserDefinedCriteriaComponent
                        handleRequest={handleRequest}
                        metaData={metaData}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        modalStatus={modalStatus}
                        jurisdictionOptions={juridictionLevelOptions}
                        setDescriptionData={setDescriptionData}
                        descriptionData={descriptionData}
                      />
                    )}

                    {/* Tax Bracket */}
                    <TaxBracketComponent
                      metaData={metaData}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                      modalStatus={modalStatus}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                      setTouched={setTouched}
                    />

                    {/* Applicable TO */}
                    <ApplicableComponent
                      setApplicableName={setApplicableName}
                      handleRequest={handleRequest}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                      modalStatus={modalStatus}
                    />
                  </div>
                </ModalBodyComponent>
                <ModalFooterComponent
                  modalStatus={modalStatus}
                  loading={isLoading}
                  titleCollection={{ reviewTitle: 'Review & Confirm', cancelTitle: 'Go Back' }}
                  valueChanged={_.isEqual(
                    values,
                    initialValue != null ? initialValue : InitialValuesForm.taxSetup
                  )}
                  onCloseModal={() => {
                    closeModal();
                  }}
                  onEditModal={() => {
                    values?.statusSystemName === 'Created' && setModalStatus('Edit');
                  }}
                  isEditShow={values?.statusSystemName === 'Created'}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default TaxSetupModal;
