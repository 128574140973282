import { type UseQueryResult, useQuery } from 'react-query';
import { OptionAwardService } from '../../services/wealthlane-award-services';
import { capitalizeFirstLetter } from '../../utils/global';

const useGetOptionAwardsEmployee = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      participantName: string;
      employeeId: string;
      firstName: string;
      middleName: string;
      lastName: string;
      participantId: string | number;
      department: string;
      title: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const OptionAwardsEmployee = useQuery(
    ['getOptionAwardsEmployee'],
    async () => {
      return await handleRequest(OptionAwardService.getApiAwardApiAppOptionAwardEmployeeIds());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.participantName ?? '',
            participantName: item.participantName ?? '',
            employeeId: item.employeeId ?? '',
            firstName: item.firstName != null ? capitalizeFirstLetter(item.firstName) : '',
            middleName: item.middleName != null ? capitalizeFirstLetter(item.middleName) : '',
            lastName: item.lastName != null ? capitalizeFirstLetter(item.lastName) : '',
            participantId: item.participantId ?? '',
            department: item.department ?? '',
            title: item.jobTitle ?? '',
            value: item.participantId ?? '',
          };
        });
      },
    }
  );

  return OptionAwardsEmployee;
};

export default useGetOptionAwardsEmployee;
