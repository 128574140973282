import { type UseQueryResult, useQuery } from 'react-query';
import { CountryService } from '../../services/wealthlane-tax-services';

const useGetTaxCountryOptionsWithJuridiction = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  jurisdictionLevelId: string
): UseQueryResult<any> => {
  const taxCountriesOptions = useQuery(
    ['getTaxCountriesByJurisdictionId', jurisdictionLevelId],
    async () => {
      if (jurisdictionLevelId) {
        return await handleRequest(
          CountryService.getApiTaxApiAppCountryCountriesByJurisdiction(jurisdictionLevelId)
        );
      } else {
        return [];
      }
    },
    {
      select: (data) => {
        if (data != null) {
          data?.unshift(
            data?.splice(
              data?.findIndex((item) => item?.name === 'United States Of America'),
              1
            )[0]
          );
          return data?.filter((country)=> country?.id !== null)?.map((item) => {
            return {
              label: item?.name ?? '',
              value: item?.id ?? '',
              countryCodeValue: item?.countryCallingCode ?? '',
              isOtherCountry: item?.isOtherCountry ?? false,
              abbreviation: item?.abbreviation,
            };
          });
        }
      },
    }
  );

  return taxCountriesOptions;
};

export default useGetTaxCountryOptionsWithJuridiction;
