/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_Plan_PlanDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanListDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanListDto';
import type { Wealthlane_AwardService_Dtos_Plan_PlanStatusDto } from '../models/Wealthlane_AwardService_Dtos_Plan_PlanStatusDto';
import type { Wealthlane_AwardService_Dtos_PlanApproval_PlanApprovalDto } from '../models/Wealthlane_AwardService_Dtos_PlanApproval_PlanApprovalDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlanApprovalService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPlanApprovalApprovePlan(
requestBody?: Wealthlane_AwardService_Dtos_PlanApproval_PlanApprovalDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/plan-approval/approve-plan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanApprovalPlanStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Plan_PlanStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan-approval/plan-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanApprovalPlanList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Plan_PlanListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan-approval/plan-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPlanApprovalRejectPlan(
requestBody?: Wealthlane_AwardService_Dtos_PlanApproval_PlanApprovalDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/plan-approval/reject-plan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Plan_PlanDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPlanApprovalPlanById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Plan_PlanDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/plan-approval/{id}/plan-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppPlanApprovalPlan(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/plan-approval/plan/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
