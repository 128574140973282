import { ErrorMessage, Field } from 'formik';
import React, { type ReactNode, useEffect, useState } from 'react';
import CheckFieldLevelPermissions from '../../utils/permission/checkFieldLevelPermission';
import Tag from '../lozenge/tags';
import { FormWrapper, Label } from './input.styled';
import { MaterialIconFilled } from '../../utils/global';
import ClickableTooltip from '../tooltip/clickTooltip';
import { isNull } from 'lodash';

interface InputTypeProps {
  label?: string;
  name: any;
  type?: string | number;
  className?: string;
  labelClassName?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string | number | null | undefined;
  avoidKey?: any;
  onKeyUp?: any;
  defaultValue?: number | string;
  errors?: any;
  touched?: any;
  placeholder?: string;
  width?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  // onBlur?: any;
  // why do we need onChange here
  onChange: any;

  // change to boolean on all fields
  inputType?: boolean | string;
  errorValue?: any;
  touchedValue?: any;
  note?: string | ReactNode;
  tooltipText?: string;
  pattern?: string;
  fieldLabelName?: string;
  isEdit?: boolean;
  onBlur?: any;
  onFocus?: any;
  onClick?: any;
}

const InputText = React.memo((props: InputTypeProps) => {
  const [show, setShow] = useState<boolean>(false);
  const {
    label,
    name,
    type,
    className,
    labelClassName,
    required,
    onKeyUp,
    avoidKey,
    value,
    defaultValue,
    setFieldValue,
    inputType,
    errors,
    touched,
    placeholder,
    pattern,
    width,
    errorValue,
    touchedValue,
    note,
    tooltipText,
    fieldLabelName,
    isEdit,
    disabled,
    onBlur,
    onFocus,
    onClick,
    ...rest
  } = props;

  useEffect(() => {
    setFieldValue?.(name, defaultValue);
  }, [defaultValue]);

  const showType = type === 'password' && show ? 'text' : type;

  const hasError =
    (Boolean(errors) && Boolean(touched) && Boolean(errors[name]) && Boolean(touched[name])) ||
    (Boolean(errorValue) && Boolean(touchedValue))
      ? 'is-invalid'
      : '';

  const isTypePassword = type === 'password' ? 'input-password' : '';
  return (
    <FormWrapper className={className}>
      {label != null && label !== '' && (
        <Label
          htmlFor={name}
          className={`${labelClassName ?? ''} ${tooltipText != null ? 'tooltipView' : ''}`}>
          {label} {!(required ?? false) && <span>(Optional)</span>}{' '}
          {tooltipText != null && (
            <ClickableTooltip tooltipText={tooltipText} variant='dark' size='sm'>
              <span className='material-symbols-sharp ' style={MaterialIconFilled}>
                info
              </span>
            </ClickableTooltip>
          )}
        </Label>
      )}
      <div className='relative'>
        {onBlur ? (
          <Field
            name={name}
            label={label}
            className={`${hasError} ${isTypePassword}`}
            type={showType ?? 'text'}
            autoComplete='off'
            as={'input'}
            onFocus={onFocus && onFocus}
            onBlur={onBlur && onBlur}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            defaultValue={defaultValue}
            style={{ width: width ?? 'auto' }}
            pattern={pattern}
            disabled={
              fieldLabelName != null && fieldLabelName !== ''
                ? CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                : disabled
            }
            {...(Boolean(avoidKey) && { avoidKey })}
            {...(Boolean(value) && { value })}
            {...rest}
          />
        ) : (
          <Field
            name={name}
            label={label}
            className={`${hasError} ${isTypePassword}`}
            type={showType ?? 'text'}
            autoComplete='off'
            as={'input'}
            onFocus={onFocus && onFocus}
            // onBlur={onBlur}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            defaultValue={defaultValue}
            style={{ width: width ?? 'auto' }}
            pattern={pattern}
            disabled={
              fieldLabelName != null && fieldLabelName !== ''
                ? CheckFieldLevelPermissions(fieldLabelName, isEdit, disabled)
                : disabled
            }
            {...(Boolean(avoidKey) && { avoidKey })}
            {...(Boolean(value) && { value })}
            {...rest}
          />
        )}
        {type === 'password' && (
          <span
            className='material-symbols-outlined password-icon'
            onClick={() => {
              setShow(!show);
            }}>
            {`visibility${show ? '' : '_off'}`}
          </span>
        )}
        {type === 'days' && <Tag variant='default' title='DAYS' className='year' />}
        {type === 'year' && <Tag variant='default' title='YEAR' className='year' />}
        {type === 'percent' && <Tag variant='default' title='%' className='year' />}

        {Boolean(note) && <div className='inputNote'>{note}</div>}
      </div>

      <ErrorMessage name={name} component='div' className='error-message' />
    </FormWrapper>
  );
});

export default InputText;
