import Tag from '../../components/lozenge/tags';
import { ActionButton } from '../../components/vertical-dot/popper';
import { convertNumberWithCommas } from '../../utils/global';
import { Display, LineBreak } from '../style/common.styled';

export const udfHeader = (actions: any): any[] => {
  return [
    {
      Header: 'Field Name',
      accessor: 'fieldName',
      width: 300,
    },
    {
      Header: 'Field Type',
      accessor: 'fieldType',
      width: 300,
    },
    {
      Header: 'Field Property',
      accessor: 'isMandatory',
      width: 300,
      Cell: ({ row }: any) => (row.original.isMandatory === true ? 'Mandatory' : 'Non-mandatory'),
    },
    {
      Header: 'Order Number',
      accessor: 'orderNumber',
      width: 300,
      Cell: ({ row }: any) => {
        return (
          <Display>
            <Tag
              variant='default'
              title={
                (row.original.orderNumber as boolean)
                  ? convertNumberWithCommas(row.original.orderNumber)
                  : '-'
              }
            />
          </Display>
        );
      },
    },
    {
      Header: 'Module',
      accessor: 'modules',
      width: 300,
      Cell: ({ row }: any) => {
        return <LineBreak> {row.original.modules.split(',').join('\r\n ')}</LineBreak>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 200,
      Cell: ({ row }: any) => (
        <Tag
          variant={row.original.status === 1 ? 'success' : 'error'}
          title={row.original.status === 1 ? 'Active' : 'Inactive'}
        />
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={actions}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
