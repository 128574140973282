/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import {
  OptionExerciseService,
  type Wealthlane_AwardService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-award-services';

const usePostExerciseListCalculateTax = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  Wealthlane_AwardService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        OptionExerciseService.postApiAwardApiAppOptionExerciseCalculateOptionExerciseTax(payload)
      );
    },
    {
      onSuccess: async (response: any) => {
        if (response != null) {
          await queryClient.invalidateQueries('getReleaseIncludedList');
          toast.success(<ToastComponent label='Success' message='Tax Calculated' />);
          queryClient.invalidateQueries('getOptionExercisedList');
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostExerciseListCalculateTax;
