/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Participant_ParticipantDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Participant_ParticipantDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_ParticipantTermination_AwardDetail } from '../models/Wealthlane_AwardService_Dtos_ParticipantTermination_AwardDetail';
import type { Wealthlane_AwardService_Dtos_ParticipantTermination_AwardStatusDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTermination_AwardStatusDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDetailDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDetailDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationRequestDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationRequestDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTermination_TerminationTypeDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTermination_TerminationTypeDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantService {

    /**
     * @param employeeStatusIds 
     * @param employeeType 
     * @param accountStatus 
     * @param isEmailSend 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Participant_ParticipantDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantParticipantsList(
employeeStatusIds?: Array<number>,
employeeType?: string,
accountStatus?: string,
isEmailSend?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Participant_ParticipantDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/participants-list',
            query: {
                'EmployeeStatusIds': employeeStatusIds,
                'EmployeeType': employeeType,
                'AccountStatus': accountStatus,
                'IsEmailSend': isEmailSend,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param employeeStatusIds 
     * @param employeeType 
     * @param accountStatus 
     * @param isEmailSend 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Participant_ParticipantDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantParticipantsApprovalList(
employeeStatusIds?: Array<number>,
employeeType?: string,
accountStatus?: string,
isEmailSend?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Participant_ParticipantDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/participants-approval-list',
            query: {
                'EmployeeStatusIds': employeeStatusIds,
                'EmployeeType': employeeType,
                'AccountStatus': accountStatus,
                'IsEmailSend': isEmailSend,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTerminateParticipant(
requestBody?: Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant/terminate-participant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTerminatedParticipantsList(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/terminated-participants-list',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTermination_AwardStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantAwardStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTermination_AwardStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/award-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTermination_TerminationTypeDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTerminationTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTermination_TerminationTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/termination-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param participantId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTermination_AwardDetail Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantAwardDetails(
participantId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTermination_AwardDetail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/award-details/{participantId}',
            path: {
                'participantId': participantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param participantId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDetailDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantParticipantTermination(
participantId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/participant-termination/{participantId}',
            path: {
                'participantId': participantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantAwardedParticipants(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/awarded-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantAwardedUnRegisteredParticipants(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/awarded-un-registered-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTerminatedParticipants(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant/terminated-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
