// import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import Button from '../../../components/button';
import Table from '../../../components/table';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import { TermAndConditionHeader } from '../../../constants/table-headers/termAndConditionHeader';
import { useBoolean, useGetTermAndCondition, useWealthlaneCustomerApi } from '../../../hooks';
import { TermsAndConditionsService } from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import {
  SettingSystemUserButton,
  SettingSystemUserHeader,
  SystemUserContainer,
} from '../system-users/systemUserStyled';
import TermAndConditionModel from './termAndConditionModel';
import { useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';

interface Terms {
  id: string;
  name: string;
  documentContent: string;
  isActive: string;
  documentList: any;
  document: never[];
}

export const termAndConditionInitialValues = {
  data: InitialValuesForm.termAndCondition as Terms,
  id: '',
};

const TermAndCondition = (): JSX.Element => {
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [termAndConditionInitialData, setTermAndConditionInitialData] = useState<
    typeof termAndConditionInitialValues
  >(termAndConditionInitialValues);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const { isLoading, handleRequest } = useWealthlaneCustomerApi();
  const { data: termAndConditionList, status: load } = useGetTermAndCondition(handleRequest);
  const [dataRow, setDataRow] = useState<string>();
  const queryClient = useQueryClient();

  const actions = [
    {
      action: 'View',
      onClick: (id: string, rowData: any) => {
        setModalStatus('View');
        getTermAndConditionData(id);
        openModal();
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string, rowData: any) => {
        setModalStatus('Edit');
        getTermAndConditionData(id);
        openModal();
      },
    },
  ];

  const getTermAndConditionData = (id: string, status?: string, values?: any): void => {
    void handleRequest(
      TermsAndConditionsService.getApiCustomerApiAppTermsAndConditionsById(id)
    ).then((data: any) => {
      if (data != null) {
        const filteredData = {
          id: data.id,
          name: data.name,
          pageId: data.pageId,
          agreementStatement: data.agreementStatement,
          isActive: data.isActive === true ? 'Active' : 'InActive',
          documentContent: data.documentContent,
          document: [],
          documentList: [
            {
              file: {
                name: data?.fileName,
                uniqueName: data?.id,
              },
            },
          ],
        };

        setTermAndConditionInitialData({ id, data: filteredData });
      }
    });
  };

  return (
    <SystemUserContainer>
      <SettingSystemUserHeader>
        <Heading variant='h3' title='T&Cs, Regulatory & Other Legal Documents List' />
        <Heading
          variant='body-s'
          title={`List of legal documents used in the Wealthlane system.`}
          color={`${COLORS.Gray}`}
        />
      </SettingSystemUserHeader>

      <SettingSystemUserButton className='col-span-2 flex justify-end'>
        <Button
          as='Create'
          variant={'primary'}
          title={'Add Terms & Conditions'}
          icon={'add_circle_outline'}
          onClick={() => {
            setTermAndConditionInitialData(termAndConditionInitialValues);
            setModalStatus('Add');
            setDataRow('');
            openModal();
          }}
        />
      </SettingSystemUserButton>

      {isModalOpen && (
        <TermAndConditionModel
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          termAndConditionInitialData={termAndConditionInitialData}
          isLoading={isLoading}
          tableInfo={tableInfo}
          setTableInfo={setTableInfo}
          dataRow={dataRow}
        />
      )}
      <Table
        columns={TermAndConditionHeader(actions)}
        data={termAndConditionList ?? []}
        loading={load}
        showPagination={false}
        totalCounts={1000}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </SystemUserContainer>
  );
};

export default TermAndCondition;
