import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerUserService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto,
} from '../../services/wealthlane-customer-services';

const useGetCustomerUserDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  hasPermission?: boolean
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto | undefined,
  unknown
> => {
  const customerUserDetails = useQuery(['customerUserDetails'], async () => {
    if (hasPermission ?? false) {
      return await handleRequest(
        CustomerUserService.getApiCustomerApiAppCustomerUserCustomerUser()
      );
    }
  });

  return customerUserDetails;
};

export default useGetCustomerUserDetails;
