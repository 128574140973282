/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_CustomReport_CustomReportListDto_Wealthlane_ReportService_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_CustomReport_CustomReportListDto_Wealthlane_ReportService_Application_Contracts';
import type { Wealthlane_ReportService_Dtos_CustomReport_CustomReportDetailDto } from '../models/Wealthlane_ReportService_Dtos_CustomReport_CustomReportDetailDto';
import type { Wealthlane_ReportService_Dtos_CustomReport_CustomReportFileResultDto } from '../models/Wealthlane_ReportService_Dtos_CustomReport_CustomReportFileResultDto';
import type { Wealthlane_ReportService_Dtos_CustomReport_CustomReportPostDto } from '../models/Wealthlane_ReportService_Dtos_CustomReport_CustomReportPostDto';
import type { Wealthlane_ReportService_Dtos_CustomReport_CustomReportRenameDto } from '../models/Wealthlane_ReportService_Dtos_CustomReport_CustomReportRenameDto';
import type { Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto } from '../models/Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto';
import type { Wealthlane_ReportService_Dtos_CustomReport_CustomReportSaveAndRunResponseDto } from '../models/Wealthlane_ReportService_Dtos_CustomReport_CustomReportSaveAndRunResponseDto';
import type { Wealthlane_ReportService_Dtos_CustomReport_CustomReportUpdateDto } from '../models/Wealthlane_ReportService_Dtos_CustomReport_CustomReportUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomReportsService {

    /**
     * Temp api to Validates standard report configuration
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppCustomReportsValidateCustomReports(
requestBody?: Wealthlane_ReportService_Dtos_CustomReport_CustomReportPostDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/custom-reports/validate-custom-reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Adds a new custom report configuration based on standard report configuration
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppCustomReportsCustomReports(
requestBody?: Wealthlane_ReportService_Dtos_CustomReport_CustomReportPostDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/custom-reports/custom-reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Updates existing custom report configuration
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto Success
     * @throws ApiError
     */
    public static putApiReportApiAppCustomReportsCustomReports(
requestBody?: Wealthlane_ReportService_Dtos_CustomReport_CustomReportUpdateDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/report/api/app/custom-reports/custom-reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Remanes existing custom report to new non existing name
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppCustomReportsRenameReport(
requestBody?: Wealthlane_ReportService_Dtos_CustomReport_CustomReportRenameDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/custom-reports/rename-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Check if report new report name is duplicate or not
     * @param reportName 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppCustomReportsCheckDuplicateReportName(
reportName?: string,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/custom-reports/check-duplicate-report-name',
            query: {
                'reportName': reportName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Get list of all custom reports
     * @param fromCreatedDate 
     * @param toCreatedDate 
     * @param categoryIds 
     * @param areaIds 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_CustomReport_CustomReportListDto_Wealthlane_ReportService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiReportApiAppCustomReportsCustomReportList(
fromCreatedDate?: string,
toCreatedDate?: string,
categoryIds?: Array<string>,
areaIds?: Array<string>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_CustomReport_CustomReportListDto_Wealthlane_ReportService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/custom-reports/custom-report-list',
            query: {
                'FromCreatedDate': fromCreatedDate,
                'ToCreatedDate': toCreatedDate,
                'CategoryIds': categoryIds,
                'AreaIds': areaIds,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Get custom report by id
     * @param id 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportDetailDto Success
     * @throws ApiError
     */
    public static getApiReportApiAppCustomReportsCustomReportById(
id: string,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/custom-reports/{id}/custom-report-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Delete custom report by id
     * @param id 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto Success
     * @throws ApiError
     */
    public static deleteApiReportApiAppCustomReportsCustomReportById(
id: string,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/report/api/app/custom-reports/{id}/custom-report-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Submit and run custom report with given parameters
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportSaveAndRunResponseDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppCustomReportsSubmitAndRunReport(
requestBody?: Wealthlane_ReportService_Dtos_CustomReport_CustomReportPostDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportSaveAndRunResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/custom-reports/submit-and-run-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Gives custom report output file by report name
     * @param fileName 
     * @returns Wealthlane_ReportService_Dtos_CustomReport_CustomReportFileResultDto Success
     * @throws ApiError
     */
    public static getApiReportApiAppCustomReportsCustumReportFileByName(
fileName?: string,
): CancelablePromise<Wealthlane_ReportService_Dtos_CustomReport_CustomReportFileResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/custom-reports/custum-report-file-by-name',
            query: {
                'fileName': fileName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
