/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  BlackoutService,
  type Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutDto,
  type Wealthlane_CustomerService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-customer-services';

const usePostTradingBlackoutConfig = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void
): UseMutationResult<
  Wealthlane_CustomerService_Dtos_Response_ResponseDto | undefined,
  unknown,
  Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutDto,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutDto) => {
      if (payload.id != null && payload.id !== '') {
        return await handleRequest(BlackoutService.putApiCustomerApiAppBlackoutBlackout(payload));
      } else {
        return await handleRequest(BlackoutService.postApiCustomerApiAppBlackoutBlackout(payload));
      }
    },
    {
      onSuccess: async (
        response,
        payload: Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutDto
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries('getTradingBlackoutConfigList');
          closeModal();
          toast.success(
            <ToastComponent
              label='Success'
              message={`Blackout configuration ${
                payload.id != null && payload.id !== '' ? 'edited' : 'added'
              }.`}
            />
          );
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostTradingBlackoutConfig;
