/* eslint-disable camelcase */
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import FormSelect from '../../../../components/dropdown/formSelect';
import InputText from '../../../../components/input-text/inputText';
import ModalComponent from '../../../../components/modal/modal';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';
import { getNonUsCountryValidation, getUsCountryValidation } from '../../../../utils/global';
import { HEADQUARTER_VALIDATION } from '../../../../utils/validations/validations';
import {
  type Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto,
  ZipCodeLookUpService,
} from '../../../../services/wealthlane-customer-services';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import { commonValidation } from '../../../../utils/validations/validationRule';
import {
  useGetCountryOptions,
  useGetStateOptions,
  usePostCompanyProfile,
  useWealthlaneCustomerApi,
} from '../../../../hooks';
import _ from 'lodash';

interface HeadquarterModalType {
  initialData?: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto;
  loading: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
}

const HeadquarterModal = ({
  initialData,
  loading,
  isModalOpen,
  closeModal,
}: HeadquarterModalType): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const [validationState, setValidationState] = useState<any>(HEADQUARTER_VALIDATION);

  const { data: countryOptions } = useGetCountryOptions(handleRequest);
  const { data: stateOptions } = useGetStateOptions(handleRequest);

  const { mutate, isLoading } = usePostCompanyProfile(handleRequest, closeModal);
  const handleSubmit = (
    values: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto
  ): void => {
    const payload = {
      id: values.companyId ?? null,
      name: values.companyName,
      ticker: values.ticker,
      industrySectorId: values.industrySectorId ?? null,
      taxId: values.taxId ?? null,
      primaryStockExchangeId: values.primaryStockExchangeId ?? null,
      dateOfIncorporation: values.dateOfIncorporation,
      stateOfIncorporationId: values.stateOfIncorporationId ?? null,
      countryOfIncorporationId: values.countryOfIncorporationId ?? null,
      cusip: values.cusip,
      sicCode: values.sicCode,
      cityText: values.city,
      stateText: values.state,
      stateOfIncorporationText: values.stateOfIncorporation,
      businessDescription: values.businessDescription,
      websiteUrl: values.websiteUrl,
      headQuarterCountryId: values.countryId ?? null,
      headQuarterAddressLine1: values.addressLine1,
      headQuarterAddressLine2: values.addressLine2,
      headQuarterStateId: values.stateId ? values.stateId : null,
      headQuarterCityText: values.city ?? null,
      headQuarterStateText: values.state ?? null,
      headQuarterZipCode: `${values.zipCode ?? ''}`,
      domainNames: values.domainNames,
      isEnabled: true,
      companyAddressList:
        values?.companyAddressList != null &&
          values?.companyAddressList?.length > 0 &&
          (values?.companyAddressList[0].countryId as unknown as boolean)
          ? values?.companyAddressList?.map((item) => {
            return {
              countryId: item.countryId,
              countryText: item.countryText,
              addressLine1: item.addressLine1,
              addressLine2: item.addressLine2,
              stateId: item.stateId,
              cityText: item.cityText,
              stateText: item.stateText,
              zipCode: item.zipCode,
              taxId: item.taxId,
              id: values.companyId ?? null,
            };
          })
          : [],
    };
    mutate(payload);
  };

  const handleZipCode = (
    zipCode: string,
    values: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => void
  ): void => {
    const isOther = countryOptions?.find(
      (item) => item.value === values?.countryId
    )?.isOtherCountry;
    setFieldValue('zipCode', zipCode);
    setFieldValue('state', '');
    setFieldValue('city', '');
    setFieldValue('stateId', '');

    if (!(isOther ?? false) && zipCode.split('').length === 5) {
      handleRequest(ZipCodeLookUpService.getApiCustomerApiAppZipCodeLookUpZipCodeLookUp(zipCode))
        .then((data: any) => {
          if (data?.error !== null) {
            setFieldValue(`zipCodeErrorMessage`, data?.error?.description.replaceAll('.', ''));
          } else if (data != null) {
            const capitalCity = data.city
              ?.toLowerCase()
              .split(' ')
              .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            setFieldValue('state', data.state);
            setFieldValue('city', capitalCity);
            setFieldValue('stateId', data.stateId);
            setTimeout(() => setFieldTouched('city', true));
          } else {
            setFieldValue('state', '');
            setFieldValue('city', '');
            setFieldValue('stateId', '');
          }
        })
        .catch((error: any) => {
          console.log('error', error);
        });
    }
  };

  return (
    <>
      {initialData != null && (
        <Formik
          enableReinitialize
          initialValues={initialData}
          validationSchema={Yup.object().shape(validationState)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}>
          {({ touched, handleChange, setFieldValue, setFieldTouched, errors, values }) => {
            return (
              <ModalComponent
                show={isModalOpen}
                closeModal={closeModal}
                size='md'
                loading={loading}
                title='Headquarter Address Details'
                valueEdited={_.isEqual(values, initialData)}
                children={
                  <Form>
                    <ModalBodyComponent>
                      <div className='grid grid-cols-2 gap-x-8'>
                        <div className='col-span-2'>
                          <InputText
                            label='Address Line 1'
                            name='addressLine1'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-span-2'>
                          <InputText
                            label='Address Line 2'
                            name='addressLine2'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <FormSelect
                          label='Country'
                          name='countryId'
                          onChange={(value: string) => {
                            const isData = countryOptions?.find((item) => item.value === value);
                            setValidationState({
                              ...validationState,
                              state: getNonUsCountryValidation(isData?.isOtherCountry, 'State'),
                              stateId: getUsCountryValidation(isData?.isOtherCountry),
                              zipCode:
                                isData?.isOtherCountry === true
                                  ? commonValidation({
                                    required: true,
                                    digits: true,
                                    noBlank: true,
                                  })
                                  : commonValidation({
                                    required: true,
                                    digits: true,
                                    noBlank: true,
                                  })
                                    .min(5, 'Must be 5 digits')
                                    .max(5, 'Must be 5 digits').when('zipCodeErrorMessage', (value: any, schema: any) => {
                                      if (value != null && value != undefined) {
                                        return schema.test(
                                          'zipCodeValidation', // Name for the test
                                          value, // Custom error message
                                          (val: any) => val === true // Test function
                                        );
                                      }
                                      return schema;
                                    }),
                            });

                            setFieldValue('countryId', value);
                            setFieldValue('state', '');
                            setFieldValue('city', '');
                            setFieldValue('stateId', '');
                            setFieldValue('zipCode', '');
                          }}
                          errors={errors}
                          touched={touched}
                          options={countryOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                          placeholder='Select'
                          required
                        />
                        <InputText
                          type='number'
                          label='ZIP Code'
                          name='zipCode'
                          onChange={(e: any) => {
                            setFieldTouched(`zipCode`, true);
                            setFieldValue(`zipCodeErrorMessage`, null)
                            handleZipCode(e.target.value, values, setFieldValue, setFieldTouched);
                            const isData = countryOptions?.find(
                              (item) => item.value === values.countryId
                            );
                            setValidationState({
                              ...validationState,
                              state: getNonUsCountryValidation(isData?.isOtherCountry, 'State'),
                              stateId: getUsCountryValidation(isData?.isOtherCountry),
                              zipCode:
                                isData?.isOtherCountry === true
                                  ? commonValidation({
                                    required: true,
                                    digits: true,
                                    noBlank: true,
                                  })
                                  : commonValidation({
                                    required: true,
                                    digits: true,
                                    noBlank: true,
                                  })
                                    .min(5, 'Must be 5 digits')
                                    .max(5, 'Must be 5 digits').when('zipCodeErrorMessage', (value: any, schema: any) => {
                                      if (value != null && value != undefined) {
                                        return schema.test(
                                          'zipCodeValidation', // Name for the test
                                          value, // Custom error message
                                          (val: any) => val === true // Test function
                                        );
                                      }
                                      return schema;
                                    }),
                            });
                          }}
                          errors={errors}
                          touched={touched}
                          disabled={!(values?.countryId as unknown as boolean)}
                          required
                        />
                        {!(
                          countryOptions?.find((item) => item.value === values?.countryId)
                            ?.isOtherCountry ?? false
                        ) ? (
                          <FormSelect
                            label='State'
                            name='stateId'
                            onChange={(value: string) => {
                              setFieldValue('stateId', value);
                              setFieldValue('city', '');
                            }}
                            errors={errors}
                            touched={touched}
                            options={stateOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            placeholder='Select'
                            disabled={!(values?.countryId as unknown as boolean)}
                            required
                          />
                        ) : (
                          <InputText
                            label='State'
                            name='state'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={!(values?.countryId as unknown as boolean)}
                            required
                          />
                        )}
                        <InputText
                          label='City'
                          name='city'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled={!(values?.countryId as unknown as boolean)}
                          required
                        />
                      </div>
                    </ModalBodyComponent>
                    <ModalFooterComponent
                      modalStatus='Edit'
                      onCloseModal={() => {
                        closeModal();
                      }}
                      valueChanged={_.isEqual(values, initialData)}
                      loading={isLoading}
                    />
                  </Form>
                }
              />
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default HeadquarterModal;
