import { Form, Formik } from 'formik';
import CustomDatePicker from '../../../components/date-picker/customDatePicker';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
import InputText from '../../../components/input-text/inputText';
import { type ModalStatusType, type OptionType } from '../../../types/common';
import ModalComponent from '../../../components/modal/modal';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import FormSelect from '../../../components/dropdown/formSelect';
import { useWealthlaneCustomerApi } from '../../../hooks';
import useGetParticipantBroadcastMessageTemplates from '../../../hooks/get/useGetParticipantBroadcastMessageTemplates';
import usePostParticipantBroadcastMessage from '../../../hooks/post/usePostParticipantBroadcastMessage';
import _, { isEmpty } from 'lodash';
import { PARTICIPANT_BROADCAST_MESSAGE_VALIDATION } from '../../../utils/validations/validations';

interface TradePreClearanceType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  loading: boolean;
  initialData: { data: any; id: string };
}

const ParticipantBroadcastModel = ({
  isModalOpen,
  closeModal,
  modalStatus,
  setModalStatus,
  loading,
  initialData,
}: TradePreClearanceType): JSX.Element => {
  // const [searchQuery, setSearchQuery] = useState('');
  // const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data: TemplateList, isLoading: loader } =
    useGetParticipantBroadcastMessageTemplates(handleRequest);

  const { mutate, isLoading } = usePostParticipantBroadcastMessage(
    handleRequest,
    closeModal,
    initialData.id
  );
  const handleSubmit = (values: any): void => {
    mutate(values);
  };
  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [modalStatus]);
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 1);

  return (
    <Formik
      enableReinitialize
      initialValues={initialData.data}
      validationSchema={PARTICIPANT_BROADCAST_MESSAGE_VALIDATION}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            loading={loading || loader}
            size='md'
            title={`${modalStatus === 'Add' ? 'Create' : modalStatus} Broadcast Message`}
            valueEdited={_.isEqual(values, initialData.data)}
            children={
              <Form>
                <ModalBodyComponent>
                  <div className='flex flex-col justify-between'>
                    <div>
                      <InputText
                        type='text'
                        label='Title'
                        name='title'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        inputType={modalStatus}
                        disabled={modalStatus === 'View'}
                        required
                      />
                      <FormSelect
                        type='text'
                        label={'Message Template'}
                        name='messageTemplateId'
                        viewName='messageTemplate'
                        containerClassName='formGroup'
                        onChange={(value: string) => {
                          const isData = TemplateList?.filter((item: any) =>
                            value?.includes(item.value)
                          );
                          const filterData = isData?.map((item: OptionType) => item.label);
                          setFieldValue('messageTemplateId', value);
                          setFieldValue('messageTemplate', filterData?.[0]);
                          setFieldValue('message', '');
                        }}
                        errors={modalStatus === 'View' ? {} : errors}
                        touched={touched}
                        options={TemplateList}
                        values={
                          (modalStatus === 'View' || modalStatus === 'Edit') &&
                          isEmpty(values?.messageTemplateId)
                            ? // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                              setFieldValue('messageTemplateId', '11')
                            : values
                        }
                        setFieldValue={setFieldValue}
                        inputType={modalStatus}
                        disabled={modalStatus === 'View'}
                      />
                      {Boolean(values) &&
                        (values?.messageTemplateId === '11' ||
                          ((modalStatus === 'View' || modalStatus === 'Edit') &&
                            isEmpty(values?.messageTemplateId))) && (
                          <div>
                            <InputText
                              type='text'
                              label='Custom Message'
                              name='message'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              inputType={modalStatus}
                              disabled={modalStatus === 'View'}
                              required
                            />

                            <div className='flex gap-x-8 items-start mb-4 '>
                              <CustomCheckBox
                                name='addToTemplate'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                label={'Add to template'}
                                // id={`${data.planTypeCategory}.sellAll`}
                                checked={values?.addToTemplate}
                                disabled={modalStatus === 'View'}
                              />

                              {/* <RadioButtonWrapper
                              label={'aaaa'}
                              className='checkboxWrapper'
                              // id={item.value}
                              onClick={() => handleSelect(item)}
                            >
                              <input
                                type='checkbox'
                                // id={item.value}
                                // checked={checkedList.includes(item.value)}
                              />
                            </RadioButtonWrapper> */}
                              {/* <Heading variant='body-s' title='Add to template' /> */}
                            </div>
                          </div>
                        )}

                      <div className='flex gap-x-8'>
                        <CustomDatePicker
                          label='Start Date'
                          arrValue={values?.startDate}
                          name={`startDate`}
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          // values={values}
                          errorValue={errors?.startDate}
                          touchedValue={touched?.startDate}
                          min={new Date()}
                          defaultValue={new Date()}
                          disabled={
                            !!(
                              modalStatus === 'View' ||
                              (modalStatus === 'Edit' && new Date(values.startDate) < todayDate)
                            )
                          }
                          fieldLabelName='test'
                          required
                        />
                        <CustomDatePicker
                          label='End Date'
                          name='endDate'
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          errorValue={errors?.endDate}
                          touchedValue={touched?.endDate}
                          arrValue={values?.endDate}
                          // values={values}
                          min={values?.startDate}
                          defaultValue={new Date()}
                          disabled={!!(modalStatus.length > 0 && modalStatus === 'View')}
                          fieldLabelName='test'
                          required
                        />
                      </div>
                    </div>
                  </div>
                </ModalBodyComponent>
                <ModalFooterComponent
                  modalStatus={modalStatus}
                  loading={isLoading}
                  onCloseModal={() => {
                    closeModal();
                  }}
                  onEditModal={() => {
                    setModalStatus('Edit');
                  }}
                  valueChanged={_.isEqual(values, initialData.data)}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default ParticipantBroadcastModel;
