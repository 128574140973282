import { type UseQueryResult, useQuery } from 'react-query';
import {
  ApprovalProcessService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_ApprovalProcess_ApprovalProcessDto,
} from '../../services/wealthlane-customer-services';

const useGetApprovalProcessList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_ApprovalProcess_ApprovalProcessDto[] | undefined,
  unknown
> => {
  const approvalProcessList = useQuery(['getApprovalProcessList'], async () => {
    return await handleRequest(
      ApprovalProcessService.getApiCustomerApiAppApprovalProcessApprovalProcess()
    );
  });

  return approvalProcessList;
};

export default useGetApprovalProcessList;
