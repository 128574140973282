import { type UseQueryResult, useQuery } from 'react-query';

import {
  ReleaseService,

  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Release_ReleaseItemDetailDto,
} from '../../services/wealthlane-award-services';
import { isEmpty } from 'lodash';

const useGetTransactionIncludeReleaseItemDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Release_ReleaseItemDetailDto | undefined,
  unknown
> => {
  const transactionIncludeReleaseItemDetails = useQuery(
    ['getTransactionIncludeReleaseItemDetails', id],
    async () => {
      if (id) {
        return await handleRequest(ReleaseService.getApiAwardApiAppReleaseReleaseItemDetail(id));
      }
    }
  );

  return transactionIncludeReleaseItemDetails;
};

export default useGetTransactionIncludeReleaseItemDetails;
