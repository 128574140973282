/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  CustomerUserService,
  type Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto,
} from '../../services/wealthlane-customer-services';

const usePostSystemUsers = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  id?: string
): UseMutationResult<
  Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      if (id != null && id !== '') {
        return await handleRequest(
          CustomerUserService.putApiCustomerApiAppCustomerUserCustomerUser({
            ...payload,
            id,
          })
        );
      } else {
        return await handleRequest(
          CustomerUserService.postApiCustomerApiAppCustomerUserCustomerUser(payload)
        );
      }
    },
    {
      onSuccess: async (response) => {
        if (response != null) {
          closeModal();
          toast.success(
            <ToastComponent
              label='Success'
              message={`User ${id != null && id !== '' ? 'edited' : 'added'}`}
            />
          );
          await queryClient.invalidateQueries('getAllCustomerUserList');
          if (id == null) {
            await queryClient.invalidateQueries('getCustomerUserCustomerUserList');
          }
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostSystemUsers;
