import React from 'react';
import { SkeletonCircleMain, SkeletonWrapper } from './skeleton.Styled';

interface SkeletonCircleType {
  size?: 'sm' | 'md' | 'lg' | 'table';
  shape?: 'square' | 'rounded' | 'circle';
  className?: string;
  containerClassName?: string;
}

const SkeletonCircle = ({
  size = 'table',
  shape = 'rounded',
  className,
  containerClassName,
}: SkeletonCircleType): JSX.Element => {
  return (
    <SkeletonWrapper className={containerClassName}>
      <SkeletonCircleMain size={size} shape={shape} className={className} />
    </SkeletonWrapper>
  );
};

export default SkeletonCircle;
