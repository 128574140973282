import { type UseQueryResult, useQuery } from 'react-query';

import {
  TaxCalculationService,
  Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction,
  Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto,
} from '../../services/wealthlane-tax-services';

const useGetParticipantTaxViewDetail = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  payload: Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction
): UseQueryResult<
  Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto[] | undefined,
  unknown
> => {
  const TaxViewDetails = useQuery(['taxViewDetail'], async () => {
    return await handleRequest(
      TaxCalculationService.postApiTaxApiAppTaxCalculationParticipantTaxDetailForCompany(payload)
    );
  });

  return TaxViewDetails;
};

export default useGetParticipantTaxViewDetail;
