import React, { useState, useRef, useEffect } from 'react';
import { DropdownContainer } from './customDropdown.style';
import Button from '../button';
import { TableBodyCellColumn } from '../../constants/style/table.styled';
import { RadioButtonWrapper } from '../radio-button/radioButton.styled';
import NoDataFound from '../no-data-found';

interface CustomOptionType {
  label: string;
  value: string;
}

interface CustomDropdownWithIconType {
  data: CustomOptionType[];
  checkedList: string[];
  handleUser: (columnValue: string) => void;
  handleSubmit?: () => void;
  width?: string;
  padding?: string;
  loading?: boolean;
}

const CustomDropdownWithIcon = ({
  data,
  checkedList,
  handleUser,
  width,
  handleSubmit,
  loading,
  padding,
}: CustomDropdownWithIconType): JSX.Element => {
  const [customList, setCustomList] = useState<CustomOptionType[]>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setCustomList(data);
    !(loading ?? false) && setShow(false);
  }, [data, loading]);

  const handleShow = (): void => {
    setShow(!show);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (
        wrapperRef.current != null &&
        !wrapperRef.current.contains(event.target as Node) &&
        show
      ) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, show]);

  return (
    <div className='formField'>
      <DropdownContainer width={width}>
        <div className={`dropdownContainer ${show ? 'active' : ''}`}>
          <Button
            id='dropdownId'
            title='Add Columns'
            icon='add_circle_outline'
            type='button'
            variant='secondary-gray'
            onClick={() => {
              handleShow();
            }}
          />

          {show && (
            <div className='dropdownListContainer left-auto' ref={wrapperRef}>
              {customList.length > 0 && (
                <>
                  <div className='dropdownListWrapper'>
                    <div className={`dropdownSearchContainer`}>
                      {customList.map((item) => (
                        <div
                          className='dropdownSearchItem'
                          onClick={() => {
                            handleUser(item.value);
                          }}>
                          <RadioButtonWrapper
                            label={item.label}
                            className='checkboxWrapper'
                            id={item.value}>
                            <input
                              type='checkbox'
                              id={item.value}
                              checked={checkedList.includes(item.value)}
                            />
                          </RadioButtonWrapper>
                          <label htmlFor={item.value} className={`dropdownSearchItem-label`}>
                            <TableBodyCellColumn>
                              <div className='cellTitle'>{item.label}</div>
                            </TableBodyCellColumn>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='flex justify-end dropdownListWrapperButton'>
                    <Button
                      variant='primary'
                      title='Save'
                      loading={loading}
                      onClick={handleSubmit}
                    />
                  </div>
                </>
              )}
              {customList.length === 0 && (
                <div className='flex justify-center items-center h-full'>
                  <NoDataFound message='No Data Found' />
                </div>
              )}
            </div>
          )}
        </div>
      </DropdownContainer>
    </div>
  );
};

export default CustomDropdownWithIcon;
