/* eslint-disable camelcase */
// import { isEmpty } from 'lodash';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';

import {
  CustomReportsService,
  Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto,
} from '../../services/wealthlane-report-services';
import { isEmpty } from 'lodash';

const usePostCheckReportName = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  reportName: string | null | undefined,
  setCheckNameError?: any
): UseMutationResult<
  Wealthlane_ReportService_Dtos_CustomReport_CustomReportResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  //   const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      if (!isEmpty(reportName || payload)) {
        return await handleRequest(
          CustomReportsService.postApiReportApiAppCustomReportsCheckDuplicateReportName(payload)
        );
      }
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          setCheckNameError(response);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostCheckReportName;
