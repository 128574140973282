import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDetailDto,
} from '../../services/wealthlane-award-services';

const useGetParticipantTerminationById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_ParticipantTermination_ParticipantTerminationDetailDto | undefined,
  unknown
> => {
  const ParticipantTerminationById = useQuery(['getParticipantTerminationById'], async () => {
    if (id.length > 0) {
      return await handleRequest(
        ParticipantService.getApiAwardApiAppParticipantParticipantTermination(id)
      );
    }
  });

  return ParticipantTerminationById;
};

export default useGetParticipantTerminationById;
