import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import { CustomReportsService } from '../../services/wealthlane-report-services';

const usePostValidateCustomReports = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  openSaveAsModal?: () => void,
  checkName?: any
): UseMutationResult<boolean | undefined, unknown, any, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      const newPayload = { ...payload, reportConfigurationId: payload.id };
      return await handleRequest(
        CustomReportsService.postApiReportApiAppCustomReportsValidateCustomReports(newPayload)
      );
    },
    {
      onSuccess: async (response: any) => {
        if (response != null && response !== false) {
          await queryClient.invalidateQueries('validateCustomReports');
          openSaveAsModal && openSaveAsModal();
          checkName && checkName();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostValidateCustomReports;
