import styled from 'styled-components';
import { rem } from '../../../utils/style';
import { COLORS } from '../../../constants/style/color';

export const SettingRoleButton = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${rem(24)};
`;

export const UserPopoverContentWrapper = styled.div`
  .content-scroll {
    min-height: ${rem(41)};
    max-height: ${rem(339)};
    overflow: auto;

    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.SpanishGray};
      border-radius: 7px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .content-loader {
      height: ${rem(45)};
      width: ${rem(45)};
    }

    .cellTitle {
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      font-weight: 500;
      margin-bottom: 4px;
      display: inline-block;
    }
  }
`;
