import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useMousePositionAsTrigger } from 'react-laag';
import Button from '../../../components/button';
import FormSelect from '../../../components/dropdown/formSelect';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import PopoverModalComponent from '../../../components/modal/popoverModal';
import Search from '../../../components/search/search';
import Table from '../../../components/table';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import { SystemUserHeader } from '../../../constants/table-headers/systemUserHeader';
import {
  useAdvanceBoolean,
  useBoolean,
  useGetAllCustomerUserList,
  useGetSystemUserStatusList,
  useWealthlaneCustomerApi,
} from '../../../hooks';
import {
  CustomerRoleService,
  CustomerUserService,
} from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import RolesPopoverContent from '../system-roles/rolesPopoverContent';
import {
  SettingSystemUserButton,
  SettingSystemUserHeader,
  SystemUserContainer,
} from './systemUserStyled';
import UserModel from './userModel';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import { toast } from 'react-toastify';
import ToastComponent from '../../../components/toast';
import { useQueryClient } from 'react-query';
import { UserStatusOptions } from '../../../constants/common';
import Filter from '../../../components/filter';
import { ResetFilterWrapper } from '../../../constants/style/common.styled';
import DeactivatePopup from './deactivateModal';
import AlertPopup from '../../../components/alert-popup';

export const usersInitialValues = {
  data: InitialValuesForm.addSystemUserForm,
  id: '',
};
export const remarksInitialValues = {
  remarks: '',
  id: '',
};

const SystemUsers = (): JSX.Element => {
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
    customValue,
    setCustomValue,
  } = useAdvanceBoolean<string>(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [usersInitialData, setUsersInitialData] = useState(usersInitialValues);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [status, setStatus] = useState<number[]>([]);
  const [allPermission, setAllPermission] = useState({});
  const { isLoading, handleRequest } = useWealthlaneCustomerApi();
  const userAuthList = useSelector(selectAuthRoutesList);

  // const [checkedValues, setCheckedValues] = useState<any>();
  const [remark, setRemark] = useState<string>();
  const [userStatusRow, setUserStatusRow] = useState<boolean>();

  const { data: getCustomerUserCustomerUserList, status: load } = useGetAllCustomerUserList(
    handleRequest,
    tableInfo,
    status
  );
  const { data } = useGetSystemUserStatusList(handleRequest);

  const [dataRow, setDataRow] = useState<any>();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);

  const actions = [
    {
      action: 'View',
      onClick: (id: string, rowData: any) => {
        setDataRow(rowData.status);
        setModalStatus('View');
        setUserStatusRow(rowData.isEnabled);
        getUserData(id, rowData?.isDefaultAdmin);
        setUserStatusRow(rowData.isEnabled);
        setIsEditForm(true);
        openModal();
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string, rowData: any) => {
        setDataRow(rowData.status);
        setModalStatus('Edit');
        getUserDataForUpdate(id, rowData?.isDefaultAdmin);
        setIsEditForm(true);

        openModal();
      },
    },
    {
      action: 'Approve',
      as: 'Approval',
      onClick: (id: string) => {
        setModalStatus('Approval');
        setCustomValue(id);
        openConfirmModal();
      },
    },
    {
      action: <div className='delete-label'>Deactivate</div>,
      as: 'Deactivate',
      onClick: (id: string) => {
        setModalStatus('Deactivate');
        setCustomValue(id);
        openConfirmModal();
      },
    },
    {
      action: 'Activate',
      as: 'Edit',
      onClick: (id: string) => {
        setModalStatus('Activate');
        setCustomValue(id);
        openConfirmModal();
      },
    },
    {
      action: 'Reject',
      as: 'Approval',
      onClick: (id: string) => {
        setModalStatus('Reject');
        setCustomValue(id);
        openConfirmModal();
      },
    },
    {
      action: 'View Comments',
      onClick: (id: string, rowData: any) => {
        setModalStatus('View Remarks');
        setDataRow(rowData);
        setCustomValue(id);
        getRemarksData(id, rowData);
        openConfirmModal();
      },
    },
    {
      action: 'Resend Mail',
      onClick: (id: string) => {
        handleResendMail(id);
      },
    },
  ];

  const onApprovePrimaryClick = (values: Record<string, any>): void => {
    getUserData(customValue, modalStatus, values);
  };

  const getUserData = (id: string, status?: string, values?: any): void => {
    if (status === 'Deactivate') {
      setLoading(true);
      handleRequest(
        CustomerUserService.postApiCustomerApiAppCustomerUserDeactivateCustomerUser({
          customerUserId: id,
          deactivationNote: remark as string,
        })
      )
        .then((response: any) => {
          if (response != null) {
            toast.success(<ToastComponent label={'Success'} message={'User Deactivated'} />);
            void queryClient.invalidateQueries('getAllCustomerUserList');
            closeConfirmModal();
            // setCheckedValues([]);
            // setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (status === 'Activate') {
      setLoading(true);
      handleRequest(
        CustomerUserService.postApiCustomerApiAppCustomerUserReActivateCustomerUser({
          customerUserId: id,
        })
      )
        .then((response: any) => {
          if (response != null) {
            toast.success(<ToastComponent label={'Success'} message={'User Activated'} />);
            void queryClient.invalidateQueries('getAllCustomerUserList');
            closeConfirmModal();
            // setCheckedValues([]);
            // setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      void handleRequest(
        CustomerUserService.getApiCustomerApiAppCustomerUserCustomerUserById(id)
      ).then((data: any) => {
        if (data != null) {
          const filteredData = {
            ...data,
            isDefaultAdmin: status,
          };

          setUsersInitialData({ id, data: filteredData });
        }
      });
    }
  };

  const handleResendMail = (id: string): void => {
    handleRequest(
      CustomerUserService.postApiCustomerApiAppCustomerUserResendEmailToCustomerUser(id)
    ).then((data: any) => {
      if (data) {
        toast.success(<ToastComponent label={'Success'} message={'Email resend'} />);
      }
    });
  };

  const getUserDataForUpdate = (id: string, status?: boolean, values?: any): void => {
    void handleRequest(
      CustomerUserService.getApiCustomerApiAppCustomerUserCustomerUserForUpdateById(id)
    ).then((data: any) => {
      if (data != null) {
        const filteredData = {
          ...data,
          isDefaultAdmin: status,
        };

        setUsersInitialData({ id, data: filteredData });
      }
    });
  };
  // };

  const getRemarksData = (id: string, rowdata: any): void => {
    if (rowdata?.isEnabled === false) {
      void handleRequest(
        CustomerUserService.getApiCustomerApiAppCustomerUserDeactivationRemarks(id)
      ).then((data: string | undefined) => {
        if (data != null) {
          setRemark(data);

          // setRemarksInitialData({ id, remarks: data });
        }
      });
    } else {
      void handleRequest(CustomerUserService.getApiCustomerApiAppCustomerUserRemarks(id)).then(
        (data: string | undefined) => {
          if (data != null) {
            setRemark(data);

            // setRemarksInitialData({ id, remarks: data });
          }
        }
      );
    }
  };

  // To get System Roles Data
  const {
    hasMousePosition, // did we get a mouse-position from the event-handler
    resetMousePosition, // reset the mouse-position to `null`, essentially closing the menu
    handleMouseEvent, // event-handler we will use below
    trigger, // information regarding positioning we can provide to `useLayer`
  } = useMousePositionAsTrigger();

  const getRolesData = (id: string): void => {
    setLoading(true);
    handleRequest(
      CustomerRoleService.getApiCustomerApiAppCustomerRoleCustomerRoleByCustomerRoleId(id)
    )
      .then((data: any) => {
        if (data != null) {
          const filterData = data.permissions.reduce((a: any, v: any) => ({ ...a, [v]: true }), {});
          setAllPermission(filterData);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const handleShow = (id: string, e: any): void => {
    if (id) {
      getRolesData(id);
    } else {
      setAllPermission([]);
    }
    handleMouseEvent(e);
  };

  return (
    <SystemUserContainer>
      <SettingSystemUserHeader>
        <Heading variant='h3' title='System Users' />
        <Heading
          variant='body-s'
          title={`${String(
            userAuthList?.currentTenant?.name ?? ''
          )} users with access to the Wealthlane system.`}
          color={`${COLORS.Gray}`}
        />
      </SettingSystemUserHeader>
      <div className='grid xl:grid-cols-12 items-start gap-2'>
        <div className='xl:col-span-10 grid lg:grid-cols-10 gap-2 '>
          <div className='lg:col-span-3'>
            <Search
              placeholder='Search'
              searchValue={searchQuery}
              onSearch={(value: string) => {
                setSearchQuery(value);
              }}
              setTableInfo={setTableInfo}
              tableInfo={tableInfo}
            />
          </div>
          <div className='col-span-1 md:col-span-2 lg:col-span-2 flex gap-2'>
            <Filter setOpen={setOpen} open={open} className='block'>
              <Heading variant='body-xs' title={'Filter By:'} className={'filter-label'} />
              <div className='grid sm:grid-cols-2 gap-x-5'>
                <FormSelect
                  type='text'
                  name=''
                  placeholder='Approval Status'
                  onChange={(value: number[]) => {
                    if (value[0] === 0) value.shift();
                    if (value.includes(0)) value = [0];
                    if (isEmpty(value)) value = [];
                    setStatus(value);
                  }}
                  multiple
                  options={data}
                  values={status}
                  // width='305px'
                  isFormik={false}
                />
                <FormSelect
                  type='text'
                  name=''
                  placeholder='User Status'
                  options={UserStatusOptions}
                  onChange={(value: boolean[]) => {
                    setTableInfo({ ...tableInfo, userStatus: value });
                  }}
                  values={
                    tableInfo.userStatus
                      ? tableInfo.userStatus
                      : [UserStatusOptions?.find((item) => item?.label === 'Active')?.value]
                  }
                  arrValue={tableInfo.userStatus}
                  multiple
                  isFormik={false}
                />
              </div>
            </Filter>
            <div className='col-span-5 lg:col-span-2 items-start gap-x-1'>
              <ResetFilterWrapper
                onClick={() => {
                  setTableInfo(InitialValuesForm.tableParams);
                  setStatus([]);
                  setSearchQuery('');
                  void queryClient.invalidateQueries('getAllCustomerUserList');
                }}>
                <span className='material-symbols-outlined'>settings_backup_restore</span>
                <Heading variant='body-s' title='Reset Filter' />
              </ResetFilterWrapper>
            </div>
          </div>

          {/* <div className='flex gap-x-4'>
            <FormSelect
              type='text'
              name=''
              placeholder='Approval Status'
              onChange={(value: number[]) => {
                if (value[0] === 0) value.shift();
                if (value.includes(0)) value = [0];
                if (isEmpty(value)) value = [];
                setStatus(value);
              }}
              multiple
              options={data}
              values={status}
              width='305px'
              isFormik={false}
            />
            <FormSelect
              type='text'
              name=''
              placeholder='User Status'
              onChange={(value: any) => {
                setUserStatus(value);
              }}
              options={UserStatusOptions}
              arrValue={userStatus ? 'Active' : 'Deactivated'}
              width='200px'
              isFormik={false}
            />
          </div> */}
        </div>
        <SettingSystemUserButton className='xl:col-span-2 flex justify-start lg:justify-end'>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add user'}
            icon={'add_circle_outline'}
            onClick={() => {
              setUsersInitialData(usersInitialValues);
              setModalStatus('Add');
              setIsEditForm(false);
              setDataRow('');
              openModal();
            }}
          />
        </SettingSystemUserButton>

        {isConfirmOpen &&
          modalStatus !== 'Deactivate' &&
          dataRow?.status &&
          dataRow?.status === 'Rejected' && (
            <AlertPopup
              isShow={isConfirmOpen}
              closeModal={closeConfirmModal}
              modalStatus={modalStatus}
              title={'this System User'}
              handleSubmit={onApprovePrimaryClick}
              setRemark={setRemark}
              remark={remark}
              loading={loading}
            />
          )}
        {isConfirmOpen && modalStatus === 'Activate' && (
          <AlertPopup
            isShow={isConfirmOpen}
            closeModal={closeConfirmModal}
            modalStatus={modalStatus}
            // title={'this System User'}
            customTitle={'Are you sure you want to activate this System User?'}
            handleSubmit={onApprovePrimaryClick}
            setRemark={setRemark}
            remark={remark}
            loading={loading}
          />
        )}
        {isConfirmOpen && (dataRow?.isEnabled === false || modalStatus === 'Deactivate') && (
          <DeactivatePopup
            isShow={isConfirmOpen}
            closeModal={closeConfirmModal}
            modalStatus={modalStatus}
            title='user'
            handleSubmit={onApprovePrimaryClick}
            setRemark={setRemark}
            remark={remark}
            loading={loading}
            dataRow={dataRow}
          />
        )}

        <AnimationWrapper>
          {isModalOpen && (
            <UserModel
              isModalOpen={isModalOpen}
              isEditForm={isEditForm}
              closeModal={closeModal}
              modalStatus={modalStatus}
              setModalStatus={setModalStatus}
              usersInitialData={usersInitialData}
              isLoading={isLoading}
              tableInfo={tableInfo}
              setTableInfo={setTableInfo}
              dataRow={dataRow}
              userStatusRow={userStatusRow}
            />
          )}
        </AnimationWrapper>
      </div>
      <PopoverModalComponent
        children={<RolesPopoverContent permissionData={allPermission} loading={loading} />}
        hasMousePosition={hasMousePosition}
        trigger={trigger}
        resetMousePosition={resetMousePosition}
        popoverClassName='roles-popovers'
      />

      <Table
        columns={SystemUserHeader(actions, handleShow, hasMousePosition, resetMousePosition)}
        data={getCustomerUserCustomerUserList?.items ?? []}
        loading={load}
        showPagination
        totalCounts={getCustomerUserCustomerUserList?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </SystemUserContainer>
  );
};

export default SystemUsers;
