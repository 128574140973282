import { Transition } from 'react-transition-group';
import { AlertPopupContainer } from '../../../components/alert-popup/alertPopup.styled';
import Heading from '../../../components/typography';
import Button from '../../../components/button';

interface FinchConfirmationModalType {
  isShow: boolean;
  closeModal: () => void;
  handleOpen: () => void;
  loading?: boolean;
  title?: string;
  connection?: boolean;
}

const FinchConfirmationModal = ({
  isShow,
  closeModal,
  handleOpen,
  loading,
  title,
  connection,
}: FinchConfirmationModalType): JSX.Element => {
  return (
    <Transition in={isShow} timeout={250}>
      {(state) => (
        <AlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className='popupDialog'>
                <div className='popupContent'>
                  <Heading
                    variant='h5'
                    className='mb-5'
                    title={`Are you sure you want to ${title}?`}
                  />
                  <div className='popupButtons'>
                    <Button
                      variant='primary-dark'
                      title='No'
                      onClick={() => {
                        closeModal();
                      }}
                    />
                    <Button
                      variant='primary'
                      title={`Yes, ${connection === true ? 'Remove' : 'Connect'}`}
                      onClick={() => {
                        handleOpen();
                      }}
                      icon={<span className='material-symbols-outlined'>check_circle</span>}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertPopupContainer>
      )}
    </Transition>
  );
};

export default FinchConfirmationModal;
