import { type UseQueryResult, useQuery } from 'react-query';

import {
  PsuAwardService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto,
} from '../../services/wealthlane-award-services';

const useGetPsuAwardOverview = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  awardType?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto | undefined,
  unknown
> => {
  const psuAwardDetails = useQuery(['psuAwardDetails', id], async () => {
    if (id && awardType === 'PSU') {
      return await handleRequest(
        PsuAwardService.getApiAwardApiAppPSUAwardPSUAwardDetailByPSuVestingDateId(id)
      );
    }
  });

  return psuAwardDetails;
};

export default useGetPsuAwardOverview;
