import { ErrorMessage, Form, Formik, type FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import { TermsAndConditionsService } from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { useQueryClient } from 'react-query';
import ToastComponent from '../../../components/toast';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import FormSelect from '../../../components/dropdown/formSelect';
import { ActiveOptions } from '../../../constants/common';
import { Label, UploadWrapper } from '../../../components/input-text/input.styled';
import { handleOpenFile } from '../../../utils/global';
import Spinners from '../../../components/spinner';
import ImageViewerSingle from '../../../components/input-file/inputFileWithViewSingle';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import RichTextEditor from '../../../components/rich-text-editor';
import { TERMS_AND_CONDITIONS_VALIDATION } from '../../../utils/validations/validations';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import { useWealthlaneCustomerApi } from '../../../hooks';
import useGetTermAndConditionPageList from '../../../hooks/get/useGetTermAndConditionPageList';
import _, { isEmpty } from 'lodash';
import Heading from '../../../components/typography';
import { COLORS } from '../../../constants/style/color';

interface TermAndConditionType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus?: any;
  status?: number;
  termAndConditionInitialData: { data: any; id: string };
  isLoading: boolean;
  tableInfo: TableParamsType;
  setTableInfo: any;
  dataRow?: string;
}

const TermAndConditionModel = ({
  isModalOpen,
  closeModal,
  modalStatus,
  status,
  setModalStatus,
  termAndConditionInitialData,
  isLoading,
  tableInfo,
  setTableInfo,
  dataRow,
}: TermAndConditionType): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data: pageOption } = useGetTermAndConditionPageList(handleRequest);
  const [removedId, setRemovedId] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [isDocumentRemoved, setIsDocumentRemoved] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleSubmit = (values: any, actions: FormikHelpers<any>): void => {
    setLoading(true);
    const documentList = termAndConditionInitialData.data?.document.map((item: any) => {
      return {
        documentFileName: item.file.name,
        documentUniqueName: item.file.uniqueName,
        isDeleted: removedId.includes(item.file.uniqueName),
      };
    });
    const indexValues: Record<string, string | number | boolean> = {};
    documentList?.map((item: Record<string, string>, index: number) => {
      Object.keys(item).forEach((k, i) => {
        indexValues[`documentDetails[${index}].${k}`] = item[k];
      });
      return null;
    });

    const payload = {
      name: values.name,
      pageId: values.pageId,
      agreementStatement: values.agreementStatement,
      id: termAndConditionInitialData.data.id,
      documentContent: values.documentContent,
      document: !(values.document?.length as boolean) ? '' : values.document,
      isActive: values?.isActive === 'Active',
      ...indexValues,
    };

    if (termAndConditionInitialData.data?.id as boolean) {
      handleRequest(
        TermsAndConditionsService.putApiCustomerApiAppTermsAndConditions({
          ...(payload as any),
          isDocumentRemoved: !(values.documentList?.length as boolean),
        })
      )
        .then((response) => {
          if (response != null) {
            closeModal();
            toast.success(<ToastComponent label='Success' message={'Terms & Conditions edited'} />);
            queryClient.invalidateQueries('getTermAndConditionList');
            setLoading(false);

            // actions.resetForm();
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          // actions.resetForm();
        });
    } else {
      handleRequest(
        TermsAndConditionsService.postApiCustomerApiAppTermsAndConditions(payload as any)
      )
        .then((response: any) => {
          if (response != null) {
            closeModal();
            toast.success(<ToastComponent label='Success' message={'Terms & Conditions added'} />);
            void queryClient.invalidateQueries('getTermAndConditionList');
            setLoading(false);
            // actions.resetForm();
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    }
  };

  const handleImageOpen = (id: string): void => {
    handleRequest(
      TermsAndConditionsService.postApiCustomerApiAppTermsAndConditionsDownloadDocument(id)
    )
      .then((data: any) => {
        handleOpenFile(data.name, data.content);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };
  if (modalStatus === 'Edit' && isLoading) return <></>;

  return (
    <Formik
      enableReinitialize
      initialValues={termAndConditionInitialData.data}
      validationSchema={TERMS_AND_CONDITIONS_VALIDATION}
      onSubmit={handleSubmit}>
      {({
        touched,
        handleChange,
        errors,
        resetForm,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        values,
      }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            title={`${modalStatus} T&Cs, Regulatory & Other Legal Documents`}
            handleSubmit={handleSubmit}
            loading={isLoading}
            valueEdited={_.isEqual(values, termAndConditionInitialData.data)}
            size='lg'
            children={
              <>
                {isLoading && isEmpty(termAndConditionInitialData.data.id) ? (
                  <Spinners />
                ) : (
                  <div className='flex flex-col justify-between'>
                    <div>
                      <Form>
                        <ModalBodyComponent>
                          <fieldset>
                            <div className='grid sm:grid-cols-12 gap-x-8'>
                              <div className='sm:col-span-5'>
                                <FormSelect
                                  name={'pageId'}
                                  label='Page'
                                  viewName='page'
                                  containerClassName='formGroup'
                                  setFieldValue={setFieldValue}
                                  onChange={handleChange}
                                  placeholder={'Select'}
                                  options={pageOption}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  inputType={modalStatus}
                                  disabled={modalStatus === 'View'}
                                  required
                                />
                              </div>
                              <InputText
                                type='text'
                                label='Name'
                                className='col-span-7'
                                name='name'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                required
                              />
                            </div>
                            <div className='grid sm:grid-cols-12 gap-x-8'>
                              <InputText
                                type='text'
                                label='Agreement Statement '
                                className='col-span-9'
                                name='agreementStatement'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                required
                              />
                              <div className='sm:col-span-3'>
                                <FormSelect
                                  name={'isActive'}
                                  label='Status'
                                  viewName='isActive'
                                  containerClassName='formGroup'
                                  setFieldValue={setFieldValue}
                                  onChange={handleChange}
                                  placeholder={'Select'}
                                  options={ActiveOptions}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  inputType={modalStatus}
                                  disabled={modalStatus === 'View'}
                                  required
                                />
                              </div>
                            </div>
                            <div className='grid grid-cols-12'>
                              {modalStatus !== 'View' ? (
                                <div className='col-span-12'>
                                  <>
                                    <RichTextEditor
                                      name='documentContent'
                                      setFieldValue={setFieldValue}
                                      value={
                                        values?.documentContent != null
                                          ? values?.documentContent
                                          : ''
                                      }
                                      error={errors?.documentContent}
                                      touched={touched?.document}
                                    />
                                  </>
                                  <ErrorMessage
                                    name='documentContent'
                                    component='div'
                                    className='error-message'
                                  />
                                </div>
                              ) : (
                                <div className='col-span-12'>
                                  <Label>Description (Optional)</Label>

                                  <div
                                    dangerouslySetInnerHTML={{ __html: values?.documentContent }}
                                    className={`border p-2 text-2xl rounded-lg bg-[#F3F3F3] descriptionOutput`}
                                  />
                                </div>
                              )}
                            </div>

                            <div className='gap-3 justify-between mt-8'>
                              <div>
                                <Label htmlFor={'document'} className={'pb-1'}>
                                  {modalStatus === 'View' ? '' : 'Upload'} Document
                                </Label>
                                {modalStatus === 'View' &&
                                (values.documentList[0]?.file.name === undefined ||
                                  values.documentList[0]?.file.name === null) ? (
                                  <UploadWrapper className='flex justify-center'>
                                    <Heading
                                      variant='h6'
                                      title='No document to show'
                                      color={COLORS.SpanishGray}
                                      className='flex justify-center'
                                    />
                                  </UploadWrapper>
                                ) : (
                                  <ImageViewerSingle
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    modalStatus={modalStatus}
                                    values={values}
                                    errors={errors}
                                    errorValue={errors?.documentContent}
                                    touchedValue={touched?.document}
                                    touched={touched}
                                    name='document'
                                    listingName='documentList'
                                    loading={loading}
                                    removedId={removedId}
                                    handleImageOpen={handleImageOpen}
                                    setRemovedId={setRemovedId}
                                    uploadLimit={1}
                                    isSingleFile={true}
                                    setFieldError={setFieldError}
                                  />
                                )}
                              </div>
                            </div>
                          </fieldset>
                        </ModalBodyComponent>
                        <ModalFooterComponent
                          modalStatus={modalStatus}
                          onCloseModal={() => {
                            closeModal();
                          }}
                          valueChanged={_.isEqual(values, termAndConditionInitialData.data)}
                          onEditModal={() => setModalStatus('Edit')}
                          loading={loading}
                        />
                      </Form>
                    </div>
                  </div>
                )}
              </>
            }
          />
        );
      }}
    </Formik>
  );
};

export default TermAndConditionModel;
