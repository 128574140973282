import Tag from '../../components/lozenge/tags';
import { ActionButton } from '../../components/vertical-dot/popper';
import { setDashAsEmptyValue } from '../../utils/global';
import { COLORS } from '../style/color';
import { TableBodyCellColumn } from '../style/table.styled';

export const tradePreClearanceHeader = (actions: any): any[] => {
  return [
    {
      Header: 'Participant Details',
      accessor: 'participantName',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>{row.original.participantName}</div>
            <div className='cellSubtitle'>
              <Tag
                variant='default'
                title={`Emp. ID : ${String(row.original.employeeId ?? '')}`}
                color={`${COLORS.Gray}`}
              />
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Employee Type',
      accessor: 'employeeType',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {setDashAsEmptyValue(row.original.employeeType)}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Department',
      accessor: 'department',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>{setDashAsEmptyValue(row.original.department)}</TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Job Title',
      accessor: 'jobTitle',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>{setDashAsEmptyValue(row.original.jobTitle)}</TableBodyCellColumn>
        );
      },
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={actions}
          id={row.original.customerUserId}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
