import { type UseQueryResult, useQuery } from 'react-query';
import { RsuAwardService } from '../../services/wealthlane-award-services';
import { capitalizeFirstLetter } from '../../utils/global';

const useGetRsuAwardsEmployee = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      participantName: string;
      firstName: string;
      middleName: string;
      lastName: string;
      participantId: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const RsuAwardsEmployee = useQuery(
    ['getRsuAwardsEmployee'],
    async () => {
      return await handleRequest(RsuAwardService.getApiAwardApiAppRSUAwardEmployeeIds());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.employeeId ?? '',
            participantName: item.participantName ?? '',
            firstName: item.firstName != null ? capitalizeFirstLetter(item.firstName) : '',
            middleName: item.middleName != null ? capitalizeFirstLetter(item.middleName) : '',
            lastName: item.lastName != null ? capitalizeFirstLetter(item.lastName) : '',
            participantId: item.participantId ?? '',
            value: item.participantId ?? '',
          };
        });
      },
    }
  );

  return RsuAwardsEmployee;
};

export default useGetRsuAwardsEmployee;
