import { rem } from '../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';

export const NoData = styled.div`
  span.icon {
    font-size: 60px;
    color: ${COLORS.Gray70};
    margin-bottom: ${rem(10)};
  }
`;
