/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_PerformanceEvaluation_AddPerformanceEvaluationDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceEvaluation_AddPerformanceEvaluationDto';
import type { Wealthlane_AwardService_Dtos_PerformanceEvaluation_UpdatePerformanceEvaluationDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceEvaluation_UpdatePerformanceEvaluationDto';
import type { Wealthlane_AwardService_Dtos_PerformanceRule_ApplyMultiplierResponseDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceRule_ApplyMultiplierResponseDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PerformanceEvaluationService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPerformanceEvaluationPerformanceEvaluation(
requestBody?: Wealthlane_AwardService_Dtos_PerformanceEvaluation_AddPerformanceEvaluationDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/performance-evaluation/performance-evaluation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppPerformanceEvaluationPerformanceEvaluation(
requestBody?: Wealthlane_AwardService_Dtos_PerformanceEvaluation_UpdatePerformanceEvaluationDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/performance-evaluation/performance-evaluation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_PerformanceRule_ApplyMultiplierResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPerformanceEvaluationApplyEarnedMultiplier(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_AwardService_Dtos_PerformanceRule_ApplyMultiplierResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/performance-evaluation/apply-earned-multiplier',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
