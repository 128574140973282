/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_Common_VestingCalculationResultDto } from '../models/Wealthlane_AwardService_Dtos_Common_VestingCalculationResultDto';
import type { Wealthlane_AwardService_Dtos_Common_VestingTemplateCalculationDto } from '../models/Wealthlane_AwardService_Dtos_Common_VestingTemplateCalculationDto';
import type { Wealthlane_AwardService_Dtos_Common_VestingTemplateCalculationRequestDto } from '../models/Wealthlane_AwardService_Dtos_Common_VestingTemplateCalculationRequestDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_VestingTemplate_CreateVestingTemplateDto } from '../models/Wealthlane_AwardService_Dtos_VestingTemplate_CreateVestingTemplateDto';
import type { Wealthlane_AwardService_Dtos_VestingTemplate_UpdateVestingTemplateDto } from '../models/Wealthlane_AwardService_Dtos_VestingTemplate_UpdateVestingTemplateDto';
import type { Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateDto } from '../models/Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateDto';
import type { Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto } from '../models/Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto';
import type { Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForViewDto } from '../models/Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForViewDto';
import type { Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateImportResponseDTO } from '../models/Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateImportResponseDTO';
import type { Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateSampleFileBlobDto } from '../models/Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateSampleFileBlobDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VestingTemplateService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppVestingTemplateVestingTemplate(
requestBody?: Wealthlane_AwardService_Dtos_VestingTemplate_CreateVestingTemplateDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/vesting-template/vesting-template',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_VestingTemplate_UpdateVestingTemplateDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppVestingTemplateVestingTemplate(
requestBody?: Wealthlane_AwardService_Dtos_VestingTemplate_UpdateVestingTemplateDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_VestingTemplate_UpdateVestingTemplateDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/vesting-template/vesting-template',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingTemplateId 
     * @returns Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppVestingTemplateVestingTemplate(
vestingTemplateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/vesting-template/vesting-template/{vestingTemplateId}',
            path: {
                'vestingTemplateId': vestingTemplateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingTemplateId 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppVestingTemplateVestingTemplate(
vestingTemplateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/vesting-template/vesting-template/{vestingTemplateId}',
            path: {
                'vestingTemplateId': vestingTemplateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingTemplateId 
     * @returns Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForViewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppVestingTemplateVestingTemplateForView(
vestingTemplateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForViewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/vesting-template/vesting-template-for-view/{vestingTemplateId}',
            path: {
                'vestingTemplateId': vestingTemplateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingTypeIds 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppVestingTemplateVestingTemplateList(
vestingTypeIds?: Array<string>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/vesting-template/vesting-template-list',
            query: {
                'VestingTypeIds': vestingTypeIds,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppVestingTemplateVestingTemplateAllList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/vesting-template/vesting-template-all-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Common_VestingCalculationResultDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppVestingTemplateVestingCalculationTest(
requestBody?: Wealthlane_AwardService_Dtos_Common_VestingTemplateCalculationDto,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_VestingCalculationResultDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/vesting-template/vesting-calculation-test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Common_VestingCalculationResultDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppVestingTemplateVestingCalculationPreview(
requestBody?: Wealthlane_AwardService_Dtos_Common_VestingTemplateCalculationRequestDto,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_VestingCalculationResultDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/vesting-template/vesting-calculation-preview',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppVestingTemplateUploadVestingTemplateSample(
formData?: {
document?: Blob;
},
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/vesting-template/upload-vesting-template-sample',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateSampleFileBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppVestingTemplateDownloadVestingTemplateSample(): CancelablePromise<Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateSampleFileBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/vesting-template/download-vesting-template-sample',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateImportResponseDTO Success
     * @throws ApiError
     */
    public static postApiAwardApiAppVestingTemplateImportBulkVestingTemplateExcel(
formData?: {
File: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateImportResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/vesting-template/import-bulk-vesting-template-excel',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
