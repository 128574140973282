/* eslint-disable camelcase */
import { type UseQueryResult, useQuery } from 'react-query';
import {
  TaxItemApprovalService,
  type Wealthlane_TaxService_Dtos_TaxItemApprovalHistory_TaxItemApprovalHistoryDto,
} from '../../services/wealthlane-tax-services';

const useGetTaxApproverHistory = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  Wealthlane_TaxService_Dtos_TaxItemApprovalHistory_TaxItemApprovalHistoryDto[] | undefined,
  unknown
> => {
  const taxApproverHistory = useQuery(['getTaxApproverHistory', id], async () => {
    if (id != null && id !== '') {
      return await handleRequest(
        TaxItemApprovalService.getApiTaxApiAppTaxItemApprovalTaxItemHistoryById(id)
      );
    }
  });

  return taxApproverHistory;
};

export default useGetTaxApproverHistory;
