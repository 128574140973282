import { type UseQueryResult, useQuery } from 'react-query';
import {
  EsopElectionWindowConfigurationService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto,
} from '../../services/wealthlane-award-settlement-services';

const useGetEsopElectionWindowConfiguration = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const getEsopElectionWindowConfigurationList = useQuery(
    ['getEsopElectionWindowConfigurationList'],
    async () => {
      return await handleRequest(
        EsopElectionWindowConfigurationService.getApiAwardSettlementApiAppESOpElectionWindowConfigurationESOpElectionWindowConfigurations()
      );
    },
    {
      cacheTime: 1000 * 60 * 5,
    }
  );
  return getEsopElectionWindowConfigurationList;
};

export default useGetEsopElectionWindowConfiguration;
