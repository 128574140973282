import { type UseQueryResult, useQuery } from 'react-query';
import { GrantService, PlanTypeCategoryService } from '../../services/wealthlane-award-services';
import { log } from 'console';
import { useEffect } from 'react';

const useGetTransactionGrantList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  plaTypeCategoryIds?: string[]
): UseQueryResult<any, unknown> => {
  const transactionGrantList = useQuery(
    ['getTransactionGrantList'],
    async () => {
      return await handleRequest(GrantService.getApiAwardApiAppGrantGrants(plaTypeCategoryIds));
    },
    {
      select: (data: any) => {
        return data?.map((item: any) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return transactionGrantList;
};

export default useGetTransactionGrantList;
