import { useSelector } from 'react-redux';
import { getFilteredPermissionData } from '..';
import { selectAuthRoutesList } from '../../pages/configuration/routeSlice';

// Checks Field Level Permission
const CheckFieldLevelPermissions = (
  fieldName?: string,
  isEdit?: boolean,
  disabled?: boolean
): any => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const { fieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {}
  );

  const getAllPermission =
    fieldName != null && fieldName !== '' && fieldLevelPermissionsGroups[fieldName] != null
      ? fieldLevelPermissionsGroups[fieldName].map((item: any) => item.text)
      : [];

  const isDisabled =
    disabled !== undefined ?? false
      ? disabled
      : isEdit ?? false
      ? getAllPermission.includes('Edit') === true
        ? null
        : getAllPermission.includes('View') === true
        ? true
        : fieldName != null && fieldName !== '' && getAllPermission.length === 0
        ? true
        : null
      : (disabled != null && disabled !== false) || null;

  return isDisabled;
};

export default CheckFieldLevelPermissions;
