/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_NotificationService_DTOs_GetEmailNotificationDto_Wealthlane_NotificationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_NotificationService_DTOs_GetEmailNotificationDto_Wealthlane_NotificationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_NotificationService_DTOs_BellNotificationDto } from '../models/Wealthlane_NotificationService_DTOs_BellNotificationDto';
import type { Wealthlane_Shared_Hosting_Etos_Notification_AddNotificationEto } from '../models/Wealthlane_Shared_Hosting_Etos_Notification_AddNotificationEto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiNotificationApiAppNotificationNotification(
requestBody?: Wealthlane_Shared_Hosting_Etos_Notification_AddNotificationEto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/api/app/notification/notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_NotificationService_DTOs_BellNotificationDto Success
     * @throws ApiError
     */
    public static getApiNotificationApiAppNotificationNotifications(): CancelablePromise<Array<Wealthlane_NotificationService_DTOs_BellNotificationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/api/app/notification/notifications',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiNotificationApiAppNotificationNotifications(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/notification/api/app/notification/notifications',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param notificationId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static putApiNotificationApiAppNotificationNotificationSeen(
notificationId: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/notification/api/app/notification/notification-seen/{notificationId}',
            path: {
                'notificationId': notificationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getApiNotificationApiAppNotificationUnseenNotificationsCount(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/api/app/notification/unseen-notifications-count',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId 
     * @param message 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiNotificationApiAppNotificationTestBellNotification(
userId: string,
message?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/api/app/notification/test-bell-notification/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'message': message,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static postApiNotificationApiAppNotificationTestEmailNotification(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/api/app/notification/test-email-notification',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param notificationId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiNotificationApiAppNotificationNotification(
notificationId: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/notification/api/app/notification/notification/{notificationId}',
            path: {
                'notificationId': notificationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param title 
     * @param notificationMedium 
     * @param notificationType 
     * @param isSent 
     * @param isSeen 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_NotificationService_DTOs_GetEmailNotificationDto_Wealthlane_NotificationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiNotificationApiAppNotificationEmailNotificationList(
title?: string,
notificationMedium?: string,
notificationType?: string,
isSent?: boolean,
isSeen?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_NotificationService_DTOs_GetEmailNotificationDto_Wealthlane_NotificationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/api/app/notification/email-notification-list',
            query: {
                'Title': title,
                'NotificationMedium': notificationMedium,
                'NotificationType': notificationType,
                'IsSent': isSent,
                'IsSeen': isSeen,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static postApiNotificationApiAppNotificationTestForgotPasswordEmailNotification(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/api/app/notification/test-forgot-password-email-notification',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
