/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  PerformanceRuleService,
  Wealthlane_AwardService_Dtos_PerformanceRule_AddPerformanceRulesDto,
} from '../../services/wealthlane-award-services';
import { Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutDto } from '../../services/wealthlane-customer-services';

const usePostPerformanceRules = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal?: any,
  id?: string,
  ruleId?: string,
  setModalStatus?: any
): UseMutationResult<
  Wealthlane_AwardService_Dtos_PerformanceRule_AddPerformanceRulesDto | undefined,
  unknown,
  any,
  Wealthlane_CustomerService_Dtos_Blackout_CreateUpdateBlackoutDto
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      if ((id != null && id !== '') || (ruleId != null && ruleId !== '')) {
        return await handleRequest(
          PerformanceRuleService.putApiAwardApiAppPerformanceRulePerformanceRule(payload)
        );
      } else {
        return await handleRequest(
          PerformanceRuleService.postApiAwardApiAppPerformanceRulePerformanceRule(payload)
        );
      }
    },
    {
      onSuccess: async (response: any) => {
        if (response != null) {
          await queryClient.invalidateQueries('getPerformanceRulesList');
          await queryClient.invalidateQueries('getAvailablePerformanceRules');
          closeModal();
          toast.success(
            <ToastComponent
              label='Success'
              message={`Performance Rule ${id ? 'Edited' : 'Added'} `}
            />
          );
          if (ruleId) {
            setModalStatus('View');
          }
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostPerformanceRules;
