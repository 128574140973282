import { useEffect, useState } from 'react';
import { PopoverModelWrapper } from '../../components/modal/modal.styled';
import Heading from '../../components/typography';
import { ActionButton } from '../../components/vertical-dot/popper';
import { TableBodyCellColumn } from '../style/table.styled';
import { COLORS } from '../style/color';

export const AdminRolesHeader = (
  action: any,
  handleShow: (id: string, event: any) => void,
  handleUserShow: (id: string, event: any) => void,
  hasMousePosition: boolean,
  resetMousePosition: () => void
): any[] => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (!(event.target as HTMLElement).className.includes('roles-popovers')) {
        resetMousePosition();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resetMousePosition]);
  return [
    {
      Header: 'Role Names',
      accessor: 'name',
      width: 200,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='roles-popovers'>
          <Heading
            variant='body-s'
            title={(row.original.roleName as boolean) ? row.original.roleName : '-'}
            onClick={(e: any) => {
              setTitle(`${String(row.original.roleName ?? '')}-${String(row.id ?? '')}`);
              handleShow(row.original.id, e);
            }}
            className={`roles-popovers title ${
              hasMousePosition &&
              title === `${String(row.original.roleName ?? '')}-${String(row.id ?? '')}`
                ? 'active'
                : ''
            }`}
          />
        </PopoverModelWrapper>
      ),
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 500,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {(row.original.description as boolean) ? row.original.description : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: ' No. of Users',
      accessor: 'numberOfUsers',
      width: 500,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='roles-popovers'>
          <Heading
            variant='body-s'
            title={(row.original.numberOfUsers as boolean) ? row.original.numberOfUsers : '-'}
            onClick={(e: any) => {
              setTitle(`${String(row.original.numberOfUsers ?? '')}-${String(row.id ?? '')}`);
              handleUserShow(row.original.id, e);
            }}
            className={`roles-popovers border px-2 text-[12px] bg-[${
              COLORS.OffWhite
            }] rounded-[4px] ${
              hasMousePosition &&
              title === `${String(row.original.numberOfUsers ?? '')}-${String(row.id ?? '')}`
                ? 'active'
                : ''
            }`}
          />
        </PopoverModelWrapper>
      ),
    },
    // {
    //   Header: 'Permissions',
    //   accessor: 'permissions',
    //   width: '500px',
    //   Cell: ({ row }: any) => {
    //     const permissionsData = getFilteredPermissionData(row.original.permissions);
    //     return (
    //       <>
    //         {permissionsData &&
    //           Object.keys(permissionsData.nonFieldLevelPermissionsGroups).map((data: string) => (
    //             <p>
    //               {data.replaceAll(/([A-Z])/g, ' $1').trim()} :{' '}
    //               <span>
    //                 {permissionsData.nonFieldLevelPermissionsGroups[`${data}`].map(
    //                   (itemData: any, index: number) => (
    //                     <>
    //                       {itemData.text}
    //                       {permissionsData.nonFieldLevelPermissionsGroups[`${data}`].length !==
    //                         index + 1 && ', '}
    //                     </>
    //                   )
    //                 )}
    //               </span>
    //             </p>
    //           ))}
    //         {permissionsData &&
    //           Object.keys(permissionsData.fieldLevelPermissionsGroups).map((data: string) => (
    //             <p>
    //               {data} :{' '}
    //               <span>
    //                 {permissionsData.fieldLevelPermissionsGroups[`${data}`].map(
    //                   (itemData: any, index: number) => (
    //                     <>
    //                       {itemData.text}
    //                       {permissionsData.fieldLevelPermissionsGroups[`${data}`].length !==
    //                         index + 1 && ', '}
    //                     </>
    //                   )
    //                 )}
    //               </span>
    //             </p>
    //           ))}
    //       </>
    //     );
    //   },
    // },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={action(row.original)}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
