import moment from 'moment';
import Tag from '../../components/lozenge/tags';
import { ActionButton } from '../../components/vertical-dot/popper';
import { DATE_FORMAT } from '../common';
import { Display } from '../style/common.styled';
import { TableBodyCellColumn } from '../style/table.styled';
import { getActionList } from '../../utils/global';

export const TradingBlackoutConfigurationHeader = (actions: any): any[] => {
  const getActions = (rowData: Record<string, string>): any => {
    let newActions = [];
    const startDateExceed = moment(moment(rowData?.startDate).format(DATE_FORMAT)).isSameOrBefore(
      moment().format(DATE_FORMAT),
      'day'
    );
    const endDateExceed = moment(moment(rowData?.endDate).format(DATE_FORMAT)).isBefore(
      moment().format(DATE_FORMAT),
      'day'
    );

    const endDateFuture = moment(moment(rowData?.endDate).format(DATE_FORMAT)).isSameOrAfter(
      moment().format(DATE_FORMAT),
      'day'
    );

    if (startDateExceed && endDateExceed) {
      newActions = getActionList(actions).filter(
        (item: any) => !['Edit', 'Delete'].includes(item.action)
      );
    } else if (startDateExceed && endDateFuture) {
      newActions = getActionList(actions).filter((item: any) => !['Delete'].includes(item.action));
    } else {
      newActions = actions;
    }
    return newActions;
  };

  return [
    {
      Header: 'Blackout Title',
      accessor: 'blackoutTitle',
      width: 300,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div>
              {row.original.blackoutTitle != null && row.original.blackoutTitle !== ''
                ? row.original.blackoutTitle
                : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },

    {
      Header: 'Time Duration',
      accessor: 'timeDuration',
      width: 300,
      center: true,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <Display>
            <Tag
              variant={'default'}
              title={`${moment(row.original.startDate).format(DATE_FORMAT)} - ${moment(
                row.original.endDate
              ).format(DATE_FORMAT)}`}
            />
          </Display>
        );
      },
    },
    {
      Header: 'Eligible For',
      accessor: 'eligibleForId',
      width: 150,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.eligibleFor != null && row.original.eligibleFor !== ''
              ? row.original.eligibleFor
              : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Last Modified',
      accessor: 'lastModifiedDate',
      width: 150,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.lastModifiedDate != null
              ? moment(row.original.lastModifiedDate).format(DATE_FORMAT)
              : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={getActions(row.original)}
          id={row.original.customerUserId}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
