import { type UseQueryResult, useQuery } from 'react-query';
import { EquityCompensationLevelService } from '../../services/wealthlane-tax-services';

const useGetTaxApplicableOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<any> => {
  const taxApplicableOptions = useQuery(
    ['getApplicableOptions'],
    async () => {
      return await handleRequest(
        EquityCompensationLevelService.getApiTaxApiAppEquityCompensationLevelEquityCompensationList()
      );
    },
    {
      select: (data) => {
        return data
          ?.filter((option) => option.displayName !== 'Vendor')
          .map((item) => {
            return {
              label: item.displayName ?? '',
              systemName: item.systemName ?? '',
              value: item.id ?? '',
            };
          });
      },
      // cacheTime: 1000 * 60 * 5,
    }
  );

  return taxApplicableOptions;
};

export default useGetTaxApplicableOptions;
