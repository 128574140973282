import { type UseQueryResult, useQuery } from 'react-query';
import { BlackoutService } from '../../services/wealthlane-customer-services';

const useTradingBlackoutEligibilityOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const tradingBlackoutEligibilityOptions = useQuery(
    ['getTradingBlackoutEligibilityOptions'],
    async () => {
      return await handleRequest(
        BlackoutService.getApiCustomerApiAppBlackoutBlackoutEligiblityStatuses()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return tradingBlackoutEligibilityOptions;
};

export default useTradingBlackoutEligibilityOptions;
