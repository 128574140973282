import styled from 'styled-components';
import { rem } from '../../../utils/style';
import { COLORS } from '../../../constants/style/color';

export const FinchInfo = styled.div`
  margin: 15px 0;
  padding: ${rem(14)};
  background-color: ${COLORS.OffWhite};
  border-radius: ${rem(6)};
`;
