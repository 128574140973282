import styled from 'styled-components';
import { rem } from '../../../../utils/style';
import { COLORS } from '../../../../constants/style/color';
export const TaxBracketAccordingContainer = styled.div`
  background: ${COLORS.Grey400Transparent};
  /* opacity: 0.5; */
  border-bottom: 1px solid ${COLORS.LightGray};
  border-radius: 6px 6px 0px 0px;
  padding: ${rem(13)} ${rem(15)};
  font-weight: 500;
`;

export const TaxBracketAccordingValue = styled.div`
  height: auto;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  background: ${COLORS.Grey100Transparent};
  border-radius: 0px 0px 6px 6px;
  padding: ${rem(21)} ${rem(32)};
`;
export const TaxBracketHeaders = styled.div`
  padding-bottom: ${rem(15)};
  border-bottom: 1px solid ${COLORS.LightGray};
`;
export const TaxBracketValue = styled.div`
  padding: ${rem(21)} ${rem(0)} ${rem(0)} ${rem(0)};
  border-bottom: 1px solid ${COLORS.LightGray};
  &:last-child {
    border-bottom: 0;
  }
`;
export const UserDefinedCriteria = styled.div`
  padding: ${rem(10)} ${rem(12)};
  background-color: ${COLORS.TagShadow};
  border-radius: 6px;
  width: 100%;
`;

export const ListWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  ul {
    /* max-width: 400px; */
    margin: 0 auto;
    list-style-type: none;
    margin: 0;
    font-family: sans-serif;
    padding: 30px 0px;
  }
  li {
    display: flex;
    align-items: center;
  }
  ul li {
    padding: 0 0 20px 30px;
    position: relative;
    margin: 0;
  }
  ul li:after {
    position: absolute;
    top: 15px;
    left: 0;
    content: '';
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
    text-align: center;
    line-height: 24px;
    background: #5bbcc9;
  }
  ul li:before {
    position: absolute;
    left: 4px;
    top: 15px;
    content: '';
    height: 100%;
    width: 0;
    border-left: 2px solid #5bbcc9;
  }
  ul li:last-of-type:before {
    border: none;
  }
  ul li:last-of-type div {
    border: none;
  }
`;

export const ListContent = styled.div`
  border-bottom: 1px solid rgba(233, 233, 233, 0.5);
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    margin: 0;
    color: #000000;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    color: #7a7a7a;
    width: 162px;
  }
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;

    color: #333333;
  }
  div {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
  }
`;
export const ListLink = styled.div`
  display: flex;
  justify-content: end;

  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    text-align: right;

    color: #071360;
  }
`;
export const ViewData = styled.div`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${COLORS.CulturedWhite}; ;
`;

export const TaxImportWrapper = styled.div`
  display: flex;
  margin: 10px 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .download-template {
    justify-content: center;
    align-items: center;
  }

  .import-file-Name {
    margin-top: 15px;
    font-size: large;
    word-break: break-all;
  }
`;
