/* eslint-disable camelcase */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto } from '../../services/wealthlane-customer-services';
import type { RootState } from '../../store/store';

interface RouteState {
  currentUserRoutesList: any[];
  currentUserAuthList: Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto;
}

const initialState: RouteState = {
  currentUserRoutesList: [],
  currentUserAuthList: {},
};

const slice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setRouteList: (
      state,
      {
        payload: { currentUserRoutesList, currentUserAuthList },
      }: PayloadAction<{ currentUserRoutesList: any[]; currentUserAuthList: any }>
    ) => {
      state.currentUserAuthList = currentUserAuthList;
      state.currentUserRoutesList = currentUserRoutesList;
    },
  },
});

export const { setRouteList } = slice.actions;

export default slice.reducer;

export const selectAuthRoutesList = (state: RootState): any => state?.route.currentUserAuthList;

export const selectUserRoutesList = (state: RootState): any => state?.route.currentUserRoutesList;
