/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../company-profile/profile.styled';
import Heading from '../../../../components/typography';
import { COLORS } from '../../../../constants/style/color';
import Search from '../../../../components/search/search';
import { type ModalStatusType, type TableParamsType } from '../../../../types/common';
import InitialValuesForm from '../../../../constants/initialValues';
import { ResetFilterWrapper } from '../../../../constants/style/common.styled';
import { SettingSystemUserButton } from '../../system-users/systemUserStyled';
import Button from '../../../../components/button';
import {
  useAdvanceBoolean,
  useBoolean,
  useGetTaxApprovalStatusList,
  // useGetOverrideTaxData,
  useGetTaxPeriodAll,
  useGetTaxSetupList,
} from '../../../../hooks';
import FormSelect from '../../../../components/dropdown/formSelect';
import Table from '../../../../components/table';
import AnimationWrapper from '../../../../components/animation/animationWrapper';
import TaxSetupModal from './taxSetupModal';
import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import TaxOverrideModal from './taxOverrideModal';
import RequestModal from './requestModal';
import {
  TaxItemApprovalService,
  TaxItemService,
  Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationUsingImportDto,
  type Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto,
} from '../../../../services/wealthlane-tax-services';
import { toast } from 'react-toastify';
import ToastComponent from '../../../../components/toast';
import { MutationCache, useMutation, useQueryClient } from 'react-query';
import useGetTaxSetupViewByTaxId from '../../../../hooks/get/useGetTaxSetupViewByTaxId';
import { TaxSetupHeader } from '../../../../constants/table-headers/taxSetupHeader';
import TaxImportModal from './taxImportModal';
import { isEmpty } from 'lodash';
import usePostTaxCalculationImport from '../../../../hooks/post/usePostTaxCalculationImport';
import Filter from '../../../../components/filter';

const TaxSetup = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { handleRequest, error: apiError } = useWealthlaneTaxApi();

  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>('');

  const { data: taxPeriodOption } = useGetTaxPeriodAll(handleRequest);
  const { data: taxStatusOptions } = useGetTaxApprovalStatusList(handleRequest);
  const { data: taxList, status } = useGetTaxSetupList(handleRequest, tableInfo);

  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isOpenImport,
    setFalse: closeImport,
    setTrue: openImport,
    customValue: taxItemId,
    setCustomValue: setTaxItemId,
  } = useAdvanceBoolean(false);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [dataRow, setDataRow] = useState<string>();
  const [initialValue, setInitialValue] =
    useState<Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto | null>();

  const { mutate, isLoading } = useGetTaxSetupViewByTaxId(handleRequest, setInitialValue);
  const [remark, setRemark] = useState<string>();
  const [open, setOpen] = useState<boolean>(false)

  const {
    value: isModalOpenOverrideTax,
    setFalse: closeModalOverrideTax,
    setTrue: openModalOverrideTax,
  } = useBoolean(false);
  const {
    value: isRequestModalOpen,
    setFalse: closeRequestModal,
    setTrue: openRequestModal,
  } = useBoolean(false);

  const [importedFile, setImportFile] = useState<any>();
  const [calculatedData, setCalculatedData] = useState<any>();

  const handleDelete = (id: string): void => {
    setLoading(true);
    void handleRequest(TaxItemService.deleteApiTaxApiAppTaxItemTaxItem(id))
      .then((response) => {
        if (response != null) {
          void queryClient.invalidateQueries('getTaxSetupList');
          toast.success(<ToastComponent label='Success' message={response.message} />);
        }
      })
      .finally(() => {
        setLoading(false);
        closeRequestModal();
      });
  };
  const handleDeactivateTax = (id: string): void => {
    setLoading(true);
    void handleRequest(
      TaxItemService.postApiTaxApiAppTaxItemTaxItemDeactivateSendForApproval(remark, [id])
    )
      .then((response) => {
        if (response != null) {
          void queryClient.invalidateQueries('getTaxSetupList');
          toast.success(<ToastComponent label='Success' message={response.message} />);
        }
      })
      .finally(() => {
        setLoading(false);
        closeRequestModal();
        setRemark('');
      });
  };

  const actions = [
    {
      action: 'View',
      as: 'View',
      onClick: (id: string) => {
        setModalStatus('View');
        mutate(id);
        openModal();
      },
    },
    {
      action: 'Individual Tax',
      onClick: (id: string) => {
        setTaxItemId(id);
        openImport();
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string) => {
        setModalStatus('Edit');
        mutate(id);
        openModal();
      },
    },
    {
      action: 'Make a Copy',
      as: 'Create',
      onClick: (id: string) => {
        setModalStatus('Add');
        mutate(id);
        openModal();
      },
    },
    {
      action: 'Activate',
      onClick: (id: string, rowData: any) => {
        setId(id);
        setDataRow(rowData);
        openRequestModal();
      },
    },
    {
      action: 'Request for Approval',
      onClick: (id: string, rowData: any) => {
        setId(id);
        setDataRow(rowData);
        openRequestModal();
      },
    },
    {
      action: 'Override Tax',
      onClick: (id: string) => {
        mutate(id);
        setModalStatus('Override');

        openModalOverrideTax();
      },
    },
    {
      action: 'View Comment',
      onClick: async (id: string, rowData: Record<string, string>) => {
        setModalStatus('View Remarks');
        void getRejectionRemark(id);
      },
    },
    {
      action: <div className='delete-label'>Deactivate</div>,
      onClick: (id: string) => {
        setId(id);
        setModalStatus('Deactivate');
        openRequestModal();
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      onClick: (id: string) => {
        setId(id);
        setModalStatus('Delete');
        openRequestModal();
      },
    },
  ];

  const getRejectionRemark = async (id: string): Promise<void> => {
    await handleRequest(TaxItemApprovalService.getApiTaxApiAppTaxItemApprovalRemarksByTaxItemId(id))
      .then((data: any) => {
        if (data != null) {
          setRemark(data);
          openRequestModal();
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const onRequest = (/* values: Record<string, any> */): void => {
    modalStatus === 'Delete'
      ? handleDelete(id)
      : modalStatus === 'Deactivate'
        ? handleDeactivateTax(id)
        : getTaxData(id, modalStatus);
  };

  const getTaxData = (id: string, status?: string): void => {
    setLoading(true);
    handleRequest(TaxItemService.postApiTaxApiAppTaxItemSendForApproval([id]))
      .then((response: any) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message={response.message} />);
          void queryClient.invalidateQueries('getTaxSetupList');
          closeModal();
          closeRequestModal();
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        closeRequestModal();
      });
  };

  function fileImport(event: any): void {
    if (!isEmpty(event.target.files)) {
      setImportFile(event.target.files[0]);
      event.target.value = null;
      setCalculatedData(null);
    }
  }
  const {
    mutate: taxImportCalculation,
    data: calculateData,
    isLoading: fileUploadLoader,
  } = usePostTaxCalculationImport(handleRequest);

  useEffect(() => {
    calculateData !== undefined && setCalculatedData(calculateData);
  }, [calculateData]);

  useEffect(() => {
    if (isOpenImport === false) {
      document.getElementsByTagName('html')[0].style.overflow = 'unset';
    }
  }, [isOpenImport]);

  const handleSubmit = () => {
    const payload = {
      file: importedFile,
      taxItemId: taxItemId,
    };
    taxImportCalculation(payload);
  };


  return (
    <div>
      <PageHeader>
        <Heading variant='h3' title={`Tax Setup`} />
        <Heading
          variant='body-s'
          title={`View all tax data in your company.`}
          color={`${COLORS.Gray}`}
        />
      </PageHeader>
      <div className='grid xl:grid-cols-12 items-start gap-5'>
        <div className='xl:col-span-10 grid lg:grid-cols-10 gap-2 '>
          <div className='col-span-2 lg:col-span-6 flex lg:gap-1.5'>
            <div className='col-span-6 lg:col-span-2'>
              <Search
                placeholder='Search'
                searchValue={searchQuery}
                onSearch={(value: string) => {
                  setSearchQuery(value);
                }}
                setTableInfo={setTableInfo}
                tableInfo={tableInfo}
              />
            </div>
            <Filter setOpen={setOpen} open={open} className='gap-5'>
              <Heading variant='body-xs' title={'Filter By:'} className={'filter-label'} />
              <div className='grid sm:grid-cols-2 gap-x-5'>
                <FormSelect
                  type='text'
                  name=''
                  placeholder='Select Tax Period'
                  onChange={(value: string) => {
                    setTableInfo({ ...tableInfo, taxPeriodId: value });
                  }}
                  options={taxPeriodOption}
                  values={tableInfo.taxPeriodId}
                  arrValue={tableInfo.taxPeriodId}
                  isFormik={false}
                />
                <FormSelect
                  type='text'
                  name=''
                  placeholder='Select Tax Status'
                  onChange={(value: number) => {
                    setTableInfo({ ...tableInfo, taxApprovalStatusId: value });
                  }}
                  options={taxStatusOptions}
                  values={tableInfo.taxApprovalStatusId}
                  arrValue={tableInfo.taxApprovalStatusId}
                  isFormik={false}
                />
              </div>
            </Filter>

            <div className='sm:col-span-6 lg:col-span-2 items-start gap-x-1'>
              <ResetFilterWrapper
                onClick={() => {
                  setTableInfo(InitialValuesForm.tableParams);
                  setSearchQuery('');
                }}>
                <span className='material-symbols-outlined'>settings_backup_restore</span>
                <Heading variant='body-s' title='Reset Filter' />
              </ResetFilterWrapper>
            </div>
          </div>
        </div>
        <SettingSystemUserButton className='xl:col-span-2 flex justify-start lg:justify-end gap-5'>
          <Button
            as='Create'
            variant={'primary-outline'}
            title={'Check Scenario'}
            onClick={() => {
              openImport();
            }}
          />
          <Button
            as='Create'
            variant={'primary'}
            title={'Create New Tax Item'}
            icon={'add_circle_outline'}
            onClick={() => {
              setModalStatus('Add');
              openModal();
            }}
          />
        </SettingSystemUserButton>


      </div>
      <Table
        columns={TaxSetupHeader(actions)}
        data={taxList?.items ?? []}
        loading={status}
        showPagination
        totalCounts={taxList?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
      <AnimationWrapper>
        {isModalOpen && (
          <TaxSetupModal
            isModalOpen={isModalOpen}
            closeModal={() => {
              closeModal();
              setInitialValue(null);
              setModalStatus('Add');
            }}
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            initialValue={initialValue}
            loading={isLoading}
          />
        )}
      </AnimationWrapper>
      <AnimationWrapper>
        {isRequestModalOpen && (
          <RequestModal
            isShow={isRequestModalOpen}
            closeModal={() => {
              closeRequestModal();
              setModalStatus('Add');
            }}
            modalStatus={modalStatus}
            title={`${modalStatus === 'Delete'
              ? 'delete this tax setup'
              : modalStatus === 'Deactivate'
                ? 'deactivate this tax setup'
                : ''
              }`}
            setRemark={setRemark}
            remark={remark}
            handleSubmit={onRequest}
            loading={loading}
            dataRow={dataRow}
          />
        )}
      </AnimationWrapper>
      <AnimationWrapper>
        {isModalOpenOverrideTax && (
          <TaxOverrideModal
            isModalOpen={isModalOpenOverrideTax}
            closeModal={() => { setInitialValue(null); closeModalOverrideTax() }}
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            initialValue={initialValue}
          />
        )}
      </AnimationWrapper>

      <AnimationWrapper>
        {isOpenImport && (
          <TaxImportModal
            isModalOpen={isOpenImport}
            fileImport={fileImport}
            handleSubmit={handleSubmit}
            closeModal={() => {
              closeImport();
              setImportFile(null);
              setCalculatedData(null);
            }}
            error={apiError}
            isLoading={fileUploadLoader}
            data={calculatedData}
            importedFileName={importedFile?.name}
          />
        )}
      </AnimationWrapper>
    </div>
  );
};

export default TaxSetup;
