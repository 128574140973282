import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import {
  UserDefinedFieldsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsDto,
} from '../../services/wealthlane-customer-services';
import ToastComponent from '../../components/toast';

const usePostAddUdf = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  closeConfirmModal: () => void
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        UserDefinedFieldsService.postApiCustomerApiAppUserDefinedFieldsUserDefinedFields(payload)
      );
    },
    {
      onSuccess: async (
        // eslint-disable-next-line camelcase
        response: Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsDto | undefined
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries('getUserDefinedFieldsList');
          closeModal();
          closeConfirmModal();
          toast.success(<ToastComponent label='Success' message='UDF added.' />);
        }
      },
      onError: (err) => {
        console.log('error', err);
        closeConfirmModal();
      },
    }
  );
};

export default usePostAddUdf;
