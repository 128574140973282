import { type UseQueryResult, useQuery } from 'react-query';
import { StateService } from '../../services/wealthlane-customer-services';

const useGetStateOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const stateOptions = useQuery(
    ['getStates', id],
    async () => {
      if (id != null && id !== '') {
        return await handleRequest(StateService.getApiCustomerApiAppStateByCountry(id));
      }
      return await handleRequest(StateService.getApiCustomerApiAppStateStates());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
      cacheTime: 1000 * 60 * 5,
    }
  );

  return stateOptions;
};

export default useGetStateOptions;
