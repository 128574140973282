import React, { useState } from 'react';
import { type ModalStatusType } from '../../types/common';
import Button from '../button';
import { ModelFooter } from './modal.styled';
import AlertPopup from '../alert-popup';

interface ModalFooterComponentType {
  onCloseModal: () => void;
  handleSubmit?: () => void;
  onEditModal?: any;
  loading?: boolean;
  editTitle?: string;
  addTitle?: string;
  modalStatus?: ModalStatusType;
  isEditShow?: boolean;
  onApprove?: () => void;
  onReject?: () => void;
  isExport?: boolean;
  icon?: string;
  isLoading?: boolean;
  confirmValues?: boolean;
  urlName?: string;
  titleCollection?: Record<string, string>;
  valueChanged?: boolean;
  approverView?: boolean;
  buttonTitle?: string;
  onClickButton?: () => void;
  checkError?: boolean;
  isTmc?: boolean;
  customAlertMessage?: string;
}

const ModalFooterComponent = ({
  modalStatus,
  loading,
  editTitle,
  addTitle,
  onCloseModal,
  handleSubmit,
  onEditModal,
  isEditShow = true,
  onApprove,
  onReject,
  isExport,
  isLoading,
  confirmValues = false,
  icon,
  urlName,
  titleCollection,
  valueChanged = true,
  approverView = false,
  checkError = false,
  isTmc = false,
  buttonTitle,
  onClickButton,
  customAlertMessage,
}: ModalFooterComponentType): JSX.Element => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  return (
    <>
      <ModelFooter>
        <div className='footer-button'>
          <Button
            title={
              titleCollection?.cancelTitle != null && (modalStatus?.includes('Review') ?? false)
                ? titleCollection?.cancelTitle
                : 'Cancel'
            }
            type='button'
            variant='primary-dark'
            onClick={() => {
              modalStatus !== 'View'
                ? valueChanged
                  ? onCloseModal()
                  : setOpenAlert(true)
                : onCloseModal();
            }}
          />
          {isEditShow && modalStatus === 'View' && (
            <Button
              title={`Edit ${editTitle ?? ''}`}
              loading={loading}
              as='Edit'
              hasId={urlName}
              type='submit'
              variant='primary-outline'
              icon='edit'
              onClick={onEditModal}
            />
          )}
          {(isTmc ?? false) && (
            <Button
              title={`Edit ${editTitle ?? ''}`}
              loading={loading}
              hasId={urlName}
              type='submit'
              variant='primary-outline'
              icon='edit'
              onClick={onEditModal}
            />
          )}
          {(isExport ?? false) && (
            <Button
              title={`Export ${editTitle ?? ''}`}
              type='button'
              variant={isExport ?? false ? 'primary' : 'primary-outline'}
              icon={icon ?? 'edit'}
              loading={isLoading}
              onClick={handleSubmit}
            />
          )}
          {(modalStatus === 'Edit' || modalStatus === 'Add') && !confirmValues && (
            <Button
              title={buttonTitle ? buttonTitle : `Save ${addTitle ?? ''}`}
              loading={isLoading ?? loading}
              type='submit'
              variant='primary'
              disabled={!!((modalStatus === 'Edit' && valueChanged) || checkError)}
              icon={icon ?? 'check_circle'}
              onClick={onClickButton}
            />
          )}
          {approverView && (
            <div className='flex gap-x-5'>
              <Button
                onClick={onApprove}
                title={`Approve`}
                loading={loading}
                type='button'
                variant={'approve'}
                icon=''
              />
              <Button
                onClick={onReject}
                title={`Reject`}
                loading={loading}
                type='button'
                variant={'primary-danger'}
                icon=''
              />
            </div>
          )}
          {confirmValues && (
            <Button
              title={`Review & Confirm`}
              loading={loading}
              type='submit'
              variant='primary'
              icon='check_circle'
            />
          )}
        </div>
      </ModelFooter>
      {openAlert && (
        <AlertPopup
          isShow={openAlert}
          closeModal={() => {
            setOpenAlert(false);
          }}
          customTitle={
            customAlertMessage
              ? customAlertMessage
              : 'Your changes will not be saved. Are you sure you want to close?'
          }
          handleSubmit={async () => {
            setOpenAlert(false);
            onCloseModal();
          }}
          // loading={loading}
        />
      )}
    </>
  );
};

export default ModalFooterComponent;
