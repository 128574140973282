/* eslint-disable camelcase */
import { UseQueryResult, useQuery } from 'react-query';
import { type TableParamsType } from '../../types/common';
import {
  TaxItemService,
  Wealthlane_TaxService_Dtos_TaxItemDto_VendorTaxItemIdsListDto,
} from '../../services/wealthlane-tax-services';

const useGetTaxPeriodFromWealthlaneList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  Wealthlane_TaxService_Dtos_TaxItemDto_VendorTaxItemIdsListDto[] | undefined,
  unknown
> => {
  const { vendorTaxPeriodId, jurisdictionLevelId } = tableInfo;
  const taxPeriodTableForWealthlaneList = useQuery(
    ['getTaxPeriodTableForWealthlaneList', vendorTaxPeriodId, jurisdictionLevelId],
    async () => {
      return await handleRequest(
        TaxItemService.getApiTaxApiAppTaxItemVendorTaxListForCompany(
          vendorTaxPeriodId,
          jurisdictionLevelId
        )
      );
    }
  );

  return taxPeriodTableForWealthlaneList;
};

export default useGetTaxPeriodFromWealthlaneList;
