import { PageHeader, PageWrapper } from './profile.styled';
import Heading from '../../../components/typography';
import Tabs from '../../../components/tabs';
import CompanyComponent from './company';
import HeadquarterComponent from './headquarter';
import ContactComponent from './contacts';
import { useGetCompanyProfileDetails, useWealthlaneCustomerApi } from '../../../hooks';
import ProfileUpload from './profileUpload';
import { useState } from 'react';

const CompanyProfile = (): JSX.Element => {
  const [openTab, setOpenTab] = useState(0);
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data, isLoading } = useGetCompanyProfileDetails(handleRequest);

  const items = [
    {
      name: 'Company Information',
      content: <CompanyComponent data={data} loading={isLoading} />,
    },
    {
      name: 'Headquarter Address Details',
      content: <HeadquarterComponent data={data} loading={isLoading} />,
    },
    {
      name: 'Key Contacts',
      content: <ContactComponent data={data} loading={isLoading} handleRequest={handleRequest} />,
    },
  ];

  return (
    <PageWrapper>
      <PageHeader>
        <Heading variant='h3' title='Company Profile' />
        <Heading variant='body-s' title='Secure your account & manage your password' />
      </PageHeader>
      <div className='flex flex-col xl:flex-row page'>
        <div>
          <ProfileUpload handleRequest={handleRequest} />
          <Heading variant='h3' title={data?.companyName} fontWeight={600} />
        </div>
        <div className='profile-info grid'>
          <Tabs
            hasTabContent
            tabIndex={openTab}
            setTabIndex={(index) => {
              setOpenTab(index);
            }}
            items={items}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default CompanyProfile;
