/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_CustomerService_Dtos_CustomerKeyContact_CreateOrUpdateCustomerKeyContactDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerKeyContact_CreateOrUpdateCustomerKeyContactDto';
import type { Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto';
import type { Wealthlane_CustomerService_Dtos_CustomerKeyContactType_CustomerKeyContactTypeDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerKeyContactType_CustomerKeyContactTypeDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto';
import type { Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyDto } from '../models/Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerCompanyService {

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppCustomerCompanyCompanyProfile(
requestBody?: Wealthlane_CustomerService_Dtos_VendorCompany_UpdateCompanyDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/customer-company/company-profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyId 
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerCompanyCompanyProfileById(
companyId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CompanyDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-company/company-profile-by-id',
            query: {
                'CompanyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerCompanyCompanyDetailForView(): CancelablePromise<Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-company/company-detail-for-view',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerCompanyKeyContacts(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-company/key-contacts',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerKeyContactType_CustomerKeyContactTypeDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerCompanyKeyContactTypes(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerKeyContactType_CustomerKeyContactTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-company/key-contact-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerCompanySaveKeyContact(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerKeyContact_CreateOrUpdateCustomerKeyContactDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-company/save-key-contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
