/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_StandardReport_StandardReportListDto_Wealthlane_ReportService_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_StandardReport_StandardReportListDto_Wealthlane_ReportService_Application_Contracts';
import type { Wealthlane_ReportService_Dtos_StandardReport_StandardReportDetailDto } from '../models/Wealthlane_ReportService_Dtos_StandardReport_StandardReportDetailDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StandardReportsService {

    /**
     * Gets the standard report list for wealthlane user
     * @param fromCreatedDate 
     * @param toCreatedDate 
     * @param categoryIds 
     * @param areaIds 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_StandardReport_StandardReportListDto_Wealthlane_ReportService_Application_Contracts Request Sucessfully
     * @throws ApiError
     */
    public static getApiReportApiAppStandardReportsStandardReportList(
fromCreatedDate?: string,
toCreatedDate?: string,
categoryIds?: Array<string>,
areaIds?: Array<string>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_StandardReport_StandardReportListDto_Wealthlane_ReportService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/standard-reports/standard-report-list',
            query: {
                'FromCreatedDate': fromCreatedDate,
                'ToCreatedDate': toCreatedDate,
                'CategoryIds': categoryIds,
                'AreaIds': areaIds,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Gets the standard report by id for wealthlane user
     * @param id 
     * @returns Wealthlane_ReportService_Dtos_StandardReport_StandardReportDetailDto Request Sucessfully
     * @throws ApiError
     */
    public static getApiReportApiAppStandardReportsStandardReportById(
id: string,
): CancelablePromise<Wealthlane_ReportService_Dtos_StandardReport_StandardReportDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/standard-reports/{id}/standard-report-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Gets the standard report by id for customer user
     * @param fromCreatedDate 
     * @param toCreatedDate 
     * @param categoryIds 
     * @param areaIds 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_StandardReport_StandardReportListDto_Wealthlane_ReportService_Application_Contracts Request Sucessfully
     * @throws ApiError
     */
    public static getApiReportApiAppStandardReportsStandardReportListForCompany(
fromCreatedDate?: string,
toCreatedDate?: string,
categoryIds?: Array<string>,
areaIds?: Array<string>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_StandardReport_StandardReportListDto_Wealthlane_ReportService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/standard-reports/standard-report-list-for-company',
            query: {
                'FromCreatedDate': fromCreatedDate,
                'ToCreatedDate': toCreatedDate,
                'CategoryIds': categoryIds,
                'AreaIds': areaIds,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * Gets the standard report by id for customer user
     * @param id 
     * @returns Wealthlane_ReportService_Dtos_StandardReport_StandardReportDetailDto Request Sucessfully
     * @throws ApiError
     */
    public static getApiReportApiAppStandardReportsStandardReportByIdForCompany(
id: string,
): CancelablePromise<Wealthlane_ReportService_Dtos_StandardReport_StandardReportDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/standard-reports/{id}/standard-report-by-id-for-company',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
