import { type UseQueryResult, useQuery } from 'react-query';
import { ParticipantApprovalService } from '../../services/wealthlane-customer-services';

const useParticipantApprovalStatusOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const participantApprovalOptions = useQuery(
    ['getParticipantApprovalStatus'],
    async () => {
      return await handleRequest(
        ParticipantApprovalService.getApiCustomerApiAppParticipantApprovalParticipantStatuses()
      );
    },
    {
      select: (data) => {
        const options = data?.map((item) => {
          return {
            label: item.statusName ?? '',
            value: item.id ?? '',
          };
        });
        const sortOptions = options?.concat({ label: 'All', value: 0 }).sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });
        return sortOptions;
      },
    }
  );

  return participantApprovalOptions;
};

export default useParticipantApprovalStatusOptions;
