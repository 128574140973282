import { type UseQueryResult, useQuery } from 'react-query';
import * as wealthlaneCustomerServices from '../../services/wealthlane-customer-services';
import {
  DisbursementService,
  TradeOrderService,
} from '../../services/wealthlane-award-settlement-services';

const useGetDisbursementContainerStatus = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const DisbursementContainerStatus = useQuery(
    ['getDisbursementContainerStatus'],
    async () => {
      return await handleRequest(
        DisbursementService.getApiAwardSettlementApiAppDisbursementDisbursementContainerStatus()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return DisbursementContainerStatus;
};

export default useGetDisbursementContainerStatus;
