import { type UseQueryResult, useQuery } from 'react-query';
import {
  PsuAwardApprovalService,
  RsuAwardApprovalService,
} from '../../services/wealthlane-award-services';

const useGetPsuApprovalAwardNumber = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string,
  isApprover: boolean | undefined
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const psuApprovalAwardNumberOptions = useQuery(
    ['getPsuApprovalAwardNumberOptions', id],
    async () => {
      return isApprover ?? false
        ? await handleRequest(
            PsuAwardApprovalService.getApiAwardApiAppPSUAwardApprovalPSUAwardNumbers(id)
          )
        : [];
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.awardNumber ?? '',
            value: item.psuAwardId ?? '',
          };
        });
      },
    }
  );

  return psuApprovalAwardNumberOptions;
};

export default useGetPsuApprovalAwardNumber;
