/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_GetEmailEnableToParticipantDto } from '../models/Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_GetEmailEnableToParticipantDto';
import type { Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_UpdateEmailEnableToParticipantDto } from '../models/Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_UpdateEmailEnableToParticipantDto';
import type { Wealthlane_NotificationService_DTOs_ResponseDto } from '../models/Wealthlane_NotificationService_DTOs_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyNotificationConfigurationService {

    /**
     * @param requestBody 
     * @returns Wealthlane_NotificationService_DTOs_ResponseDto Success
     * @throws ApiError
     */
    public static postApiNotificationApiAppCompanyNotificationConfigurationToggleEmailToParticipant(
requestBody?: Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_UpdateEmailEnableToParticipantDto,
): CancelablePromise<Wealthlane_NotificationService_DTOs_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/api/app/company-notification-configuration/toggle-email-to-participant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_GetEmailEnableToParticipantDto Success
     * @throws ApiError
     */
    public static getApiNotificationApiAppCompanyNotificationConfigurationCheckEmailToParticipant(): CancelablePromise<Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_GetEmailEnableToParticipantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/api/app/company-notification-configuration/check-email-to-participant',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
