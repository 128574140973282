import { motion } from 'framer-motion';
import AnimationWrapper from '../animation/animationWrapper';
import Heading from '../typography';
import { StylizedTab, TabContent, TabHeaderContainer, TabsHolder } from './tabs.styled';

interface tabsType {
  items: any[];
  centered?: boolean;
  hasTabContent?: boolean;
  tabIndex: number;
  isVertical?: boolean;
  variant?: 'default' | 'primary' | 'fit-content';
  size?: 'md' | 'lg';
  setTabIndex: (value: number) => void;
  className?: string;
}

const Tabs = ({
  items,
  centered,
  tabIndex,
  size = 'md',
  variant,
  isVertical,
  hasTabContent,
  className,
  setTabIndex,
}: tabsType): JSX.Element => {
  return (
    <>
      <TabHeaderContainer className={className}>
        <TabsHolder
          variant={variant ?? 'default'}
          className={`${isVertical ?? false ? 'tab-vertical' : ''} ${
            centered ?? false ? 'centered' : ''
          }`}>
          {items?.map((items: any, i: number) => {
            const isActive = i === tabIndex;
            return (
              <StylizedTab
                key={i}
                className={`${
                  items?.disabled != null && items?.disabled !== false ? 'disabled' : ''
                }${isActive ? 'active' : ''}`}
                onClick={() => {
                  setTabIndex(i);
                }}
                variant={variant ?? 'default'}
                size={size}>
                <Heading variant={size === 'lg' ? 'body' : 'body-xs'} title={items?.name} />
                {isActive && (
                  <motion.div
                    className={`underline-slider ${size === 'md' ? 'underline-slider-small' : ''}`}
                    layoutId='underline-slider'
                  />
                )}
              </StylizedTab>
            );
          })}
        </TabsHolder>
      </TabHeaderContainer>

      {(hasTabContent ?? false) && (
        <AnimationWrapper>
          {items?.map((items: any, i: number) =>
            tabIndex === i ? (
              <motion.section
                key={`tab-index-new-${tabIndex}`}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                <TabContent key={`tabs-${i}`}>{items.content} </TabContent>
              </motion.section>
            ) : (
              ''
            )
          )}
        </AnimationWrapper>
      )}

      {/* <TabContentComponent value={tabIndex}>
        {items &&
          items.map((items: any, i: number) => (
            <TabContent key={`tabs-${i}`}>{items.content}</TabContent>
          ))}
      </TabContentComponent> */}
    </>
  );
};

export default Tabs;
