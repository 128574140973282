import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { DropdownList, ListClose } from './verticalDot.style';
import CheckPermissions from '../../utils/permission/checkPermission';
import Spinners from '../spinner/index';
import { usePopper } from 'react-popper';
import AnimationWrapper from '../animation/animationWrapper';
import ActionAnimation from '../animation/action';

export interface PopperContainerType {
  actions?: any[];
  id: string | number | undefined;
  rowData?: Record<string, string>;
  referenceElement?: any;
  show: boolean;
  counter?: number;
  handleShow: (value?: boolean) => void;
  doubleTable?: boolean;
}

export const Portal = ({ children }: any): JSX.Element => {
  return createPortal(children, document.body);
};

const PopperContainer = ({
  actions,
  id,
  rowData,
  referenceElement,
  show,
  handleShow,
  doubleTable = false,
}: PopperContainerType): JSX.Element => {
  const [popperElement, setPopperElement] = useState<any>();
  const [visible, setVisibility] = useState(show);
  const { styles, attributes } = usePopper(referenceElement.current, popperElement, {
    placement: 'bottom-end',
  });

  useEffect(() => {
    setVisibility(show);
  }, [show]);

  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (
        Boolean(popperElement) &&
        !(popperElement as HTMLElement).contains(event.target as Node)
      ) {
        setVisibility(false);
        handleShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperElement]);

  return (
    <Portal>
      <AnimationWrapper>
        {visible && (
          <div
            className={doubleTable ? 'testDrop' : 'z-10'}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}>
            <DropdownList>
              <ActionAnimation className='dropdown'>
                <ListClose
                  onClick={() => {
                    handleShow();
                  }}
                  className='cursor-pointer'>
                  <span className='material-symbols-outlined'>close</span>
                </ListClose>
                <ul>
                  {actions?.map((action, index) => (
                    <>
                      <CheckPermissions type={action.as} urlName={action.hasId}>
                        {action.loading === true ? (
                          <li key={index} className={'loader-wrapper'}>
                            <Spinners size='sm' />
                          </li>
                        ) : (
                          <li key={index} onClick={() => action.onClick(id, rowData)}>
                            {action.action}
                          </li>
                        )}
                      </CheckPermissions>
                    </>
                  ))}
                </ul>
              </ActionAnimation>
            </DropdownList>
          </div>
        )}
      </AnimationWrapper>
    </Portal>
  );
};

export default memo(PopperContainer);
