import { type UseQueryResult, useQuery } from 'react-query';
import { CountryService } from '../../services/wealthlane-tax-services';

const useGetTaxCountryOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<any> => {
  const taxCountriesOptions = useQuery(
    ['getTaxCountries'],
    async () => {
      return await handleRequest(CountryService.getApiTaxApiAppCountryCountries());
    },
    {
      select: (data) => {
        if (data != null) {
          data.unshift(
            data.splice(
              data.findIndex((item) => item.name === 'United States Of America'),
              1
            )[0]
          );
          return data
            ?.filter((country) => country?.id !== null)
            ?.map((item) => {
              return {
                label: item.name ?? '',
                value: item.id ?? '',
                countryCodeValue: item.countryCallingCode ?? '',
                isOtherCountry: item.isOtherCountry ?? false,
                abbreviation: item.abbreviation,
              };
            });
        }
      },
    }
  );

  return taxCountriesOptions;
};

export default useGetTaxCountryOptions;
