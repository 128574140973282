import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import { IntegrationProviderService } from '../../services/wealthlane-hr-integration-services';

const usePostRevokeAuthorization = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<boolean | undefined, unknown, void, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      return await handleRequest(
        IntegrationProviderService.postApiHrintegrationApiAppIntegrationProviderRevokeAuthorization()
      );
    },
    {
      onSuccess: (response) => {
        void queryClient.invalidateQueries('postCheckFinchConnection');
        toast.success(<ToastComponent label='Success' message='Finch Disconnected' />);
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostRevokeAuthorization;
