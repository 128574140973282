import Heading from '../typography';
import { LozengeWrapper } from './lozenge.styled';

interface LozengeType {
  variant: 'default' | 'primary' | 'success' | 'error' | 'info';
  title: string | null | undefined;
  isActive?: boolean;
  className?: string;
  handleIconClick?: () => void;
  disabled?: boolean;
}

const Lozenge = ({
  variant,
  title,
  className,
  isActive,
  handleIconClick,
  disabled,
}: LozengeType): JSX.Element => {
  return (
    <LozengeWrapper variant={variant} className={className}>
      <Heading variant='body' title={title} />
      {!(disabled ?? false) && (
        <span
          className={`material-symbols-outlined ${isActive ?? false ? 'active' : ''}`}
          onClick={() => {
            handleIconClick && handleIconClick();
          }}>
          close
        </span>
      )}
    </LozengeWrapper>
  );
};

export default Lozenge;
