import Button from '../button';
import { useRef, useEffect, useState, type PropsWithChildren } from 'react';
import { FilterContainer, FilterDropdown } from './filter.styled';
import { Portal } from '../vertical-dot/popperContainer';
import { usePopper } from 'react-popper';

interface FilterType {
  open: boolean;
  setOpen: (value: boolean) => void;
  size?: 'lg' | 'md' | 'sm';
  className?: string;
  wrapperRef?: any;
  flip?: boolean;
}

const Filter = ({
  children,
  open,
  setOpen,
  className,
  size,
  flip = true,
}: PropsWithChildren<FilterType>): JSX.Element => {
  const wrapperRef = useRef<any>(null);

  return (
    <FilterContainer className={className}>
      <div ref={wrapperRef}>
        <Button
          title=''
          variant='primary-dark'
          onClick={() => {
            setOpen(!open);
          }}
          icon={'tune'}
        />
      </div>
      <PortalContainer
        size={size}
        children={children}
        open={open}
        setOpen={setOpen}
        wrapperRef={wrapperRef}
        flip={flip}
      />
    </FilterContainer>
  );
};

export default Filter;

const PortalContainer = ({
  children,
  open,
  size,
  setOpen,
  wrapperRef,
  flip,
}: PropsWithChildren<FilterType>): JSX.Element => {
  const [visible, setVisibility] = useState(open);

  useEffect(() => {
    setVisibility(open);
  }, [open]);

  const [popperElement, setPopperElement] = useState<any>();
  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (popperElement != null && !(popperElement as HTMLElement).contains(event.target as Node)) {
        setVisibility(false);
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperElement]);

  const { styles, attributes } = usePopper(wrapperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'flip',
        enabled: flip,
        options: {
          fallbackPlacements: ['top'], // Ensure popper appears below reference even if there's no space
        },
      },
    ],
  });
  return (
    <Portal>
      {visible && (
        <FilterDropdown
          size={size ?? 'md'}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          {children}
        </FilterDropdown>
      )}
    </Portal>
  );
};
