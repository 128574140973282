import { type UseQueryResult, useQuery } from 'react-query';
import {
  PlanApprovalService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Plan_PlanListDto,
} from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetPlanManagementApprovalList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  status?: number[]
  // eslint-disable-next-line camelcase
): UseQueryResult<Wealthlane_AwardService_Dtos_Plan_PlanListDto[] | undefined, unknown> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;
  const planApprovalData = useQuery(
    ['getPlanManagementApprovalList', status, filter, sorting, skipCount, maxResultCount],
    async () => {
      // if (status === 5) {
      //   status = undefined;
      // }
      return await handleRequest(PlanApprovalService.getApiAwardApiAppPlanApprovalPlanList());
    },
    {
      keepPreviousData: true,
    }
  );

  return planApprovalData;
};

export default useGetPlanManagementApprovalList;
