import { type UseQueryResult, useQuery } from 'react-query';
import { CountryService } from '../../services/wealthlane-customer-services';

const useGetCountryOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
      countryCodeValue: string;
      isOtherCountry: boolean;
    }>
  | undefined,
  unknown
> => {
  const countriesOptions = useQuery(
    ['getCountries'],
    async () => {
      return await handleRequest(CountryService.getApiCustomerApiAppCountryCountries());
    },
    {
      select: (data) => {
        if (data != null) {
          data.unshift(
            data.splice(
              data.findIndex((item) => item.name === 'United States Of America'),
              1
            )[0]
          );
          return data?.map((item) => {
            return {
              label: item.name ?? '',
              value: item.id ?? '',
              countryCodeValue: item.countryCallingCode ?? '',
              isOtherCountry: item.isOtherCountry ?? false,
              abbreviation: item?.abbreviation,
            };
          });
        }
      },
    }
  );

  return countriesOptions;
};

export default useGetCountryOptions;
