/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_CompanyTaxSetupListDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_CompanyTaxSetupListDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_TaxService_Dtos_Response_ResponseDto } from '../models/Wealthlane_TaxService_Dtos_Response_ResponseDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxBracketDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxBracketDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemOverrideDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemOverrideDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemRequestDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemRequestDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemIdsListDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemIdsListDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_UpdateTaxBracketDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_UpdateTaxBracketDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_UpdateTaxItemDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_UpdateTaxItemDto';
import type { Wealthlane_TaxService_Dtos_TaxItemDto_VendorTaxItemIdsListDto } from '../models/Wealthlane_TaxService_Dtos_TaxItemDto_VendorTaxItemIdsListDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxItemService {

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxItemTaxItem(
requestBody?: Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemRequestDto,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-item/tax-item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiTaxApiAppTaxItemTaxItem(
requestBody?: Wealthlane_TaxService_Dtos_TaxItemDto_UpdateTaxItemDto,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tax/api/app/tax-item/tax-item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxItemTaxItemOverride(
requestBody?: Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemOverrideDto,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-item/tax-item-override',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxItemId 
     * @returns Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemTaxItemById(
taxItemId: string,
): CancelablePromise<Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/tax-item-by-id/{TaxItemId}',
            path: {
                'TaxItemId': taxItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxPeriodId 
     * @param taxApprovalStatusId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemTaxItemList(
taxPeriodId?: string,
taxApprovalStatusId?: number,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/tax-item-list',
            query: {
                'TaxPeriodId': taxPeriodId,
                'TaxApprovalStatusId': taxApprovalStatusId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemIdsListDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemApprovedTaxItemIdsList(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemIdsListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/approved-tax-item-ids-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxPeriodId 
     * @param taxApprovalStatusId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemPagedTaxItemListForApproval(
taxPeriodId?: string,
taxApprovalStatusId?: number,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/paged-tax-item-list-for-approval',
            query: {
                'TaxPeriodId': taxPeriodId,
                'TaxApprovalStatusId': taxApprovalStatusId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxItemId 
     * @returns Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxBracketDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemTaxBracketListById(
taxItemId: string,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxBracketDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/tax-bracket-list-by-id/{taxItemId}',
            path: {
                'taxItemId': taxItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiTaxApiAppTaxItemTaxBracketListById(
requestBody?: Wealthlane_TaxService_Dtos_TaxItemDto_UpdateTaxBracketDto,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tax/api/app/tax-item/tax-bracket-list-by-id',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxItemSendForApproval(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-item/send-for-approval',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxItemId 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiTaxApiAppTaxItemTaxItem(
taxItemId: string,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/tax/api/app/tax-item/tax-item/{taxItemId}',
            path: {
                'taxItemId': taxItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param remarks 
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxItemTaxItemDeactivateSendForApproval(
remarks?: string,
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-item/tax-item-deactivate-send-for-approval',
            query: {
                'Remarks': remarks,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param thisYearTax 
     * @param nextYearTax 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_CompanyTaxSetupListDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemCompanyTaxSetupList(
thisYearTax?: boolean,
nextYearTax?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_CompanyTaxSetupListDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/company-tax-setup-list',
            query: {
                'ThisYearTax': thisYearTax,
                'NextYearTax': nextYearTax,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vendorTaxPeriodId 
     * @param jurisdictionId 
     * @returns Wealthlane_TaxService_Dtos_TaxItemDto_VendorTaxItemIdsListDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemVendorTaxListForCompany(
vendorTaxPeriodId?: string,
jurisdictionId?: string,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxItemDto_VendorTaxItemIdsListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/vendor-tax-list-for-company',
            query: {
                'VendorTaxPeriodId': vendorTaxPeriodId,
                'JurisdictionId': jurisdictionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxItemId 
     * @returns Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemVendorTaxItemByIdForCompany(
taxItemId: string,
): CancelablePromise<Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/vendor-tax-item-by-id-for-company/{TaxItemId}',
            path: {
                'TaxItemId': taxItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxItemVendorTaxCopyByCompany(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-item/vendor-tax-copy-by-company',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemIdsListDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxItemVendorTaxPeriodListForCompany(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemIdsListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-item/vendor-tax-period-list-for-company',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
