import { type UseQueryResult, useQuery } from 'react-query';
import {
  PerformanceRuleService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRulesByIdDto,
} from '../../services/wealthlane-award-services';

const useGetPerformanceRuleById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRulesByIdDto | undefined,
  unknown
> => {
  const performanceRuleById = useQuery(
    ['getPerformanceRuleById'],
    async () => {
      if (id) {
        return await handleRequest(
          PerformanceRuleService.getApiAwardApiAppPerformanceRulePerformanceRulesById(id)
        );
      }
    }
    // {
    //   enabled: !(id.length === 0),
    // }
  );

  return performanceRuleById;
};

export default useGetPerformanceRuleById;
