import { type UseQueryResult, useQuery } from 'react-query';
import {
  PerformanceRuleApprovalService,
  PerformanceRuleService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetPerformanceRuleApprovalList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    createdFromDate: evaluationPeriodFrom,
    createdToDate: evaluationPeriodTo,
    approvalStatusId: approvalStatus,
    ruleStatusId: ruleStatus,
    earnedStatus: earnedStatus,
    filter,
    sorting,
    skipCount,
    maxResultCount,
  } = tableInfo;
  const performanceRulesApprovalList = useQuery(
    [
      'getPerformanceRulesApprovalList',
      evaluationPeriodFrom,
      evaluationPeriodTo,
      approvalStatus,
      ruleStatus,
      earnedStatus,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        PerformanceRuleApprovalService.getApiAwardApiAppPerformanceRuleApprovalApprovalPerformanceRuleList(
          evaluationPeriodFrom,
          evaluationPeriodTo,
          approvalStatus,
          ruleStatus,
          earnedStatus,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return performanceRulesApprovalList;
};

export default useGetPerformanceRuleApprovalList;
