/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto } from '../models/Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto';
import type { Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForAdminViewDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForAdminViewDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto';
import type { Wealthlane_AwardService_Dtos_PSUAwardApproval_PSUAwardApprovalDto } from '../models/Wealthlane_AwardService_Dtos_PSUAwardApproval_PSUAwardApprovalDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PsuAwardApprovalService {

    /**
     * @param approvalStatusIds 
     * @param fromDate 
     * @param toDate 
     * @param employeeStatuses 
     * @param psuStatus 
     * @param psuAcceptance 
     * @param createdFromDate 
     * @param createdToDate 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAwardList(
approvalStatusIds?: Array<number>,
fromDate?: string,
toDate?: string,
employeeStatuses?: Array<number>,
psuStatus?: number,
psuAcceptance?: number,
createdFromDate?: string,
createdToDate?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAward-list',
            query: {
                'ApprovalStatusIds': approvalStatusIds,
                'FromDate': fromDate,
                'ToDate': toDate,
                'EmployeeStatuses': employeeStatuses,
                'PSUStatus': psuStatus,
                'PSUAcceptance': psuAcceptance,
                'CreatedFromDate': createdFromDate,
                'CreatedToDate': createdToDate,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardApprovalApprovePSuAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_PSUAwardApproval_PSUAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward-approval/approve-pSUAwards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardApprovalApproveBulkPSu(
requestBody?: Array<Wealthlane_AwardService_Dtos_PSUAwardApproval_PSUAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward-approval/approve-bulk-pSU',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardApprovalRejectBulkPSu(
requestBody?: Array<Wealthlane_AwardService_Dtos_PSUAwardApproval_PSUAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward-approval/reject-bulk-pSU',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardApprovalRejectPSuAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_PSUAwardApproval_PSUAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward-approval/reject-pSUAwards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForAdminViewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPerformanceRuleForApproverDetail(
awardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForAdminViewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/performance-rule-for-approver-detail/{awardId}',
            path: {
                'awardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAwardStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAward-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalEmployeeStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/employee-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUStatuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAcceptances(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAcceptances',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAwardOverview(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAward-overview/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAwardVestingSchedule(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAward-vesting-schedule/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAwardDocuments(
psuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAward-documents/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardApprovalExportPSuAwardVestingSchedule(
psuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward-approval/export-pSUAward-vesting-schedule/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardApprovalDownloadDocument(
requestBody?: Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward-approval/download-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAwardStatusById(
psuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAward-status-by-id/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAwardSettlementHistory(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAward-settlement-history/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardApprovalPSUAwardNumbers(
psuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward-approval/p-sUAward-numbers/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
