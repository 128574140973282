import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';
import { getRgbaValue, rem } from '../../../utils/style';

export const TradePreClearanceContainer = styled.div`
  .error-message {
    font-weight: 400;
    font-size: ${rem(12)};
    color: ${COLORS.Gray};
    transition: all 0.2s linear;
    margin-bottom: ${rem(12)};
  }

  .individual {
    &-title {
      margin-top: ${rem(12)};
      margin-bottom: ${rem(24)};
    }
    &-check,
    &-selected {
      margin-top: ${rem(24)};
    }

    &-heading {
      margin-bottom: ${rem(4)};
    }
  }
  .group {
    &-title {
      margin-top: ${rem(12)};
      margin-bottom: ${rem(4)};
    }
    &-sub-title {
      margin-bottom: ${rem(24)};
    }
  }
`;
export const TradePreClearanceSubLeftContainer = styled.div`
  padding-left: ${rem(24)};
  border-left: 0.5px solid ${COLORS.CulturedWhite};
`;
export const TradePreClearanceSubRightContainer = styled.div`
  padding-right: ${rem(24)};
  border-right: 0.5px solid ${COLORS.CulturedWhite};
`;
export const ClearanceDateContainer = styled.div`
  margin: 18px 0;
  padding-bottom: 14px;
  border-bottom: 1px solid ${COLORS.CulturedWhite};
`;
export const RadioButtonContainer = styled.div`
  margin-bottom: ${rem(18)};
`;

export const PreClearanceRequestFilterWrapper = styled.div`
  display: flex;
  gap: ${rem(6)};
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: ${rem(23)};
  align-items: center;

  .search-wrap {
    width: 100%;
  }

  .search-option {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .search-wrap {
      width: ${rem(350)};
    }

    .search-option {
      width: ${rem(150)};
    }
  }
`;

export const PreClearanceComponent = styled.div`
  margin-bottom: ${rem(32)};

  h5 {
    margin-bottom: ${rem(12)};
    color: ${COLORS.Gray};
  }

  .title {
    font-weight: 500;
    white-space: nowrap;
  }

  .preClearance-name {
    font-size: 11px;
    line-height: 14px;
    border: 0.5px solid ${COLORS.CulturedWhite};
    border-radius: 6px;
    padding: ${rem(13)} ${rem(10)};

    span {
      font-weight: 600;
    }
  }

  .preClearance-grid {
    grid-column-gap: ${rem(18)};
    padding: ${rem(14)} 0;
    border-bottom: 1px solid ${COLORS.CulturedWhite};
  }

  .preClearance-wrapper {
    width: ${rem(900)};
    overflow-x: auto;
  }

  .preClearance-checkbox {
    transform: translateY(10px);
  }

  .preClearance-radio {
    .error-message {
      position: absolute;
      bottom: -6px;
      white-space: nowrap;
    }
  }
`;

export const PreClearanceRequestRejectModalWrapper = styled.div`
  h5 {
    margin-bottom: 35px;
  }

  input {
    background-color: ${COLORS.White};
    border: ${rem(1)} solid ${COLORS.LightGray};
    border-radius: ${rem(6)};
    padding: ${rem(11)} ${rem(12)};

    font-weight: 400;
    font-size: ${rem(15)};
    line-height: ${rem(16)};

    filter: drop-shadow(${rem(0)} ${rem(2)} ${rem(4)} ${getRgbaValue(`${COLORS.Gray400}`, 0.2)});
    transition: all 0.2s 0s ease;
    width: 100%;

    &:disabled {
      background-color: ${COLORS.OffWhite};
      border: ${rem(1)} solid ${COLORS.LightGray};
    }
  }

  .reject-Button {
    margin-top: 24px;
  }
`;
