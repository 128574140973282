/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityConfigurationDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityConfigurationDto';
import type { Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityCriteriaDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityCriteriaDto';
import type { Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_SaveRetirementEligibilityConfigurationRequestDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_SaveRetirementEligibilityConfigurationRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RetirementEligibilityConfigurationService {

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityConfigurationDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppRetirementEligibilityConfigurationRetirementEligibilityConfiguration(): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityConfigurationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/retirement-eligibility-configuration/retirement-eligibility-configuration',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppRetirementEligibilityConfigurationSaveRetirementEligibilityConfiguration(
requestBody?: Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_SaveRetirementEligibilityConfigurationRequestDto,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/retirement-eligibility-configuration/save-retirement-eligibility-configuration',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityCriteriaDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppRetirementEligibilityConfigurationRetirementEligibilityCriterias(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_RetirementEligibilityCriteriaDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/retirement-eligibility-configuration/retirement-eligibility-criterias',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
