/* eslint-disable camelcase */
import { type UseQueryResult, useQuery } from 'react-query';
import { type TableParamsType } from '../../types/common';
import {
  TaxPeriodService,
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-tax-services';

const useGetTaxPeriodList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { taxPeriodId, taxApprovalStatusId, filter, sorting, skipCount, maxResultCount } =
    tableInfo;
  const taxPeriodTableList = useQuery(['getTaxPeriodTableList', tableInfo], async () => {
    return await handleRequest(
      //   TaxPeriodService.getApiTaxApiAppTaxPeriodFinancialYear()
      TaxPeriodService.getApiTaxApiAppTaxPeriodPagedSortedList(
        taxPeriodId,
        taxApprovalStatusId,
        filter,
        sorting,
        skipCount,
        maxResultCount
      )
    );
  });

  return taxPeriodTableList;
};

export default useGetTaxPeriodList;
