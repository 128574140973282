import { type UseQueryResult, useQuery } from 'react-query';
import { UserLogInAndSecurityService } from '../../services/wealthlane-identity-services';

const useGetTwoFactorAuthenticationOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const twoFactorAuthenticationOptions = useQuery(
    ['getTwoFactorAuthenticationOptions'],
    async () => {
      return await handleRequest(
        UserLogInAndSecurityService.getApiIdentityApiAppUserLogInAndSecurityTwoFactorAuthenticationOptions()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.securityOption ?? '',
            value: item.optionId ?? '',
          };
        });
      },
    }
  );

  return twoFactorAuthenticationOptions;
};

export default useGetTwoFactorAuthenticationOptions;
