/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../company-profile/profile.styled';
import Heading from '../../../../components/typography';
import { COLORS } from '../../../../constants/style/color';
import { type TableParamsType } from '../../../../types/common';
import InitialValuesForm from '../../../../constants/initialValues';

import {
  useBoolean,
  useGetTaxItemListFromWealthlane,
  useGetTaxItemViewForWealthlane,
  useGetTaxJuridictionLevel,
  useGetTaxPeriodAll,
  useGetTaxPeriodVendor,
} from '../../../../hooks';
import FormSelect from '../../../../components/dropdown/formSelect';
import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import { CopyTaxDataBlock, LeftSideBar, LibraryContainer, RightSideBar } from './taxLibrary.styled';
import Search from '../../../../components/search/search';
import { ResetFilterWrapper } from '../../../../constants/style/common.styled';
import Filter from '../../../../components/filter';
import { RadioButtonWrapper } from '../../../../components/radio-button/radioButton.styled';
import { TableBodyCellColumn } from '../../../../constants/style/table.styled';
import Button from '../../../../components/button';
import {
  TaxItemService,
  Wealthlane_TaxService_Dtos_Response_ResponseDto,
  Wealthlane_TaxService_Dtos_TaxItemDto_VendorTaxItemIdsListDto,
} from '../../../../services/wealthlane-tax-services';
import NoDataFound from '../../../../components/no-data-found';
import { toast } from 'react-toastify';
import ToastComponent from '../../../../components/toast';
import { useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import Spinners from '../../../../components/spinner';
import AlertPopup from '../../../../components/alert-popup';
import TaxLibraryView from '../tax-library/taxLibraryView';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants/common';
const TaxLibrary = (): JSX.Element => {
  const { handleRequest, error } = useWealthlaneTaxApi();

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [viewItemId, setViewItemId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  let checked = false;
  const { data: taxPeriodVendorOptions } = useGetTaxPeriodVendor(handleRequest);
  const { data: juridictionLevelOptions } = useGetTaxJuridictionLevel(handleRequest);
  const { data: taxViewItemDetails, isLoading: viewLoader } = useGetTaxItemViewForWealthlane(
    handleRequest,
    viewItemId
  );
  const { data: taxItemFromWealthlaneList, isLoading: listLoader } =
    useGetTaxItemListFromWealthlane(handleRequest, tableInfo);
  const [copyItemList, setCopyItemList] = useState<
    Wealthlane_TaxService_Dtos_TaxItemDto_VendorTaxItemIdsListDto[] | undefined | null
  >(null);
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isToastOpen,
    setFalse: closeToastModal,
    setTrue: openToastModal,
  } = useBoolean(false);

  useEffect(() => {
    if (taxItemFromWealthlaneList !== undefined) {
      setCopyItemList(taxItemFromWealthlaneList);
    }
  }, [taxItemFromWealthlaneList]);

  useEffect(() => {
    if (searchQuery === '') {
      setCopyItemList(taxItemFromWealthlaneList);
    }
  }, [searchQuery]);

  useEffect(() => {
    setCopyItemList(null);
  }, [tableInfo?.jurisdictionLevelId, tableInfo?.vendorTaxPeriodId]);

  if (
    copyItemList !== undefined &&
    copyItemList !== null &&
    checkedValues.length === copyItemList?.length &&
    checkedValues.length !== 0
  ) {
    checked = true;
  }

  const handleSearch = (value: string): void => {
    const searchedData = taxItemFromWealthlaneList?.filter((item: any) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setCopyItemList(searchedData);
  };
  const handleSubmit = () => {
    setLoading(true);
    handleRequest(TaxItemService.postApiTaxApiAppTaxItemVendorTaxCopyByCompany(checkedValues))
      .then((response: Wealthlane_TaxService_Dtos_Response_ResponseDto | any) => {
        if (response) {
          openToastModal();
          setMessage(response?.message);
          closeModal();
          openToastModal();
          setCheckedValues([]);
          setViewItemId('');
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        closeModal();
        openToastModal();
      })
      .finally(() => {
        setLoading(false);
        closeModal();
        openToastModal();
      });
  };

  const handleCheckAll = (checked: boolean): void => {
    const keys: any = checked
      ? !isEmpty(checkedValues)
        ? []
        : copyItemList?.map((items: any) => items.id)
      : [];

    setCheckedValues(keys);
  };

  const handleCheck = (id: string) => {
    const keys: any = !checkedValues.includes(id)
      ? [...checkedValues, id]
      : checkedValues.filter((item) => item !== id);

    setCheckedValues(keys);
  };

  return (
    <div>
      <PageHeader>
        <Heading variant='h3' title={`Tax Library`} />
        <Heading
          variant='body-s'
          title={`View all tax items in wealthlane company.`}
          color={`${COLORS.Gray}`}
        />
      </PageHeader>
      <LibraryContainer>
        <LeftSideBar className='flex flex-col justify-between gap-y-5'>
          <div>
            <div className='flex flex-row gap-x-5'>
              <Search
                placeholder='Search'
                searchValue={searchQuery}
                onSearch={(value: string) => {
                  setSearchQuery(value);
                  handleSearch(value);
                  setCheckedValues([]);
                }}
                setTableInfo={setTableInfo}
                tableInfo={tableInfo}
              />

              <Filter setOpen={setOpen} open={open} className='p-0 m-0'>
                <div className='flex justify-between'>
                  <Heading variant='body-xs' title='Filter By:' className='filter-label mb-0' />
                  <div className='mb-4 lg:mb-2'>
                    <ResetFilterWrapper
                      onClick={() => {
                        setTableInfo({
                          ...InitialValuesForm.tableParams,
                          taxPeriodId: tableInfo.taxPeriodId,
                          filter: '',
                        });
                        setSearchQuery('');
                        setCheckedValues([]);
                        setOpen(false);
                        checked = false;
                      }}>
                      <span className='material-symbols-outlined'>settings_backup_restore</span>
                      <Heading variant='body-s' title='Reset Filter' />
                    </ResetFilterWrapper>
                  </div>
                </div>
                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-5'>
                  <FormSelect
                    type='text'
                    name=''
                    placeholder='Tax Period'
                    onChange={(value: string) => {
                      setTableInfo({ ...tableInfo, vendorTaxPeriodId: value });
                    }}
                    options={taxPeriodVendorOptions}
                    values={tableInfo.vendorTaxPeriodId}
                    arrValue={tableInfo.vendorTaxPeriodId}
                    isFormik={false}
                  />
                  <FormSelect
                    type='text'
                    name=''
                    placeholder='Jurisdiction Level'
                    onChange={(value: string) => {
                      setTableInfo({ ...tableInfo, jurisdictionLevelId: value });
                    }}
                    options={juridictionLevelOptions}
                    values={tableInfo.jurisdictionLevelId}
                    arrValue={tableInfo.jurisdictionLevelId}
                    isFormik={false}
                  />
                </div>
              </Filter>
            </div>
            <div className='tax-item'>
              {listLoader ? (
                <Spinners containerClassName='h-[550px]' />
              ) : copyItemList !== undefined &&
                copyItemList !== null &&
                copyItemList?.length > 0 ? (
                <div
                  className={`tax-library-radio-wrapper-select-all pl-0 border-b-2 `}
                  key={'Select All'}>
                  <RadioButtonWrapper
                    label={'Select All'}
                    className={`${copyItemList !== undefined &&
                      copyItemList !== null &&
                      checkedValues.length < copyItemList?.length &&
                      checkedValues.length > 0
                      ? 'checkboxWrapper block partialSelect'
                      : 'checkboxWrapper block'
                      }`}
                    id={'Select All'}>
                    <input
                      type='checkbox'
                      id={'Select All'}
                      checked={checked}
                      onChange={(event: any) => {
                        handleCheckAll(event.target.checked);
                      }}
                    />
                  </RadioButtonWrapper>
                  <label htmlFor={'Select All'} className='tax-library-radio--label'>
                    <TableBodyCellColumn>
                      <div className='cellTitle'>{'Select All'}</div>
                    </TableBodyCellColumn>
                  </label>
                </div>
              ) : (
                <div className='flex justify-center items-center h-full'>
                  <NoDataFound message='No Data Found' />
                </div>
              )}
              <CopyTaxDataBlock
                style={{
                  height: checkedValues?.length >= 1 ? '510px' : '550px',
                }}>
                {copyItemList !== undefined &&
                  (copyItemList?.map((item: any, index: number) => {
                    return (
                      <div
                        className={`tax-library-radio-wrapper mt-3 ${viewItemId === item?.id ? 'active' : ''
                          }`}
                        key={index}>
                        <RadioButtonWrapper
                          label={item?.name}
                          className='checkboxWrapper'
                          id={item?.id}
                          onClick={() => handleCheck(item?.id)}>
                          <input
                            type='checkbox'
                            id={item?.name}
                            className='z-10'
                            checked={checkedValues.some((item2: any) => item2 === item.id)}
                          />
                        </RadioButtonWrapper>
                        <label
                          htmlFor={item?.id}
                          className='tax-library-radio--label'
                          onClick={() => {
                            setViewItemId(item?.id);
                          }}>
                          <TableBodyCellColumn>
                            <div
                              className='cellTitle'
                              style={{
                                color: `${item?.nameAlreadyExists ? COLORS.Iris : COLORS.Black}`,
                              }}>
                              {item?.name}
                            </div>
                            <div className='cellSubtitle' style={{ color: COLORS.Black }}>
                              {moment(item?.effectiveFromDate).format(DATE_FORMAT)} -{' '}
                              {moment(item?.effectiveToDate).format(DATE_FORMAT)}
                            </div>
                            <div className='cellSubtitle'>{item?.jurisdictionName}</div>
                          </TableBodyCellColumn>
                        </label>
                      </div>
                    );
                  }) as any)}
              </CopyTaxDataBlock>
            </div>
          </div>
          <div className='flex flex-col'>
            {checkedValues?.length > 0 && (
              <div className='flex justify-center copy-tax-button'>
                <Button
                  variant={'primary'}
                  title={'Copy Selected Taxes'}
                  icon='add_circle_outline'
                  btnClassName='w-full'
                  onClick={() => {
                    openModal();
                  }}
                  loading={loading}
                />
              </div>
            )}
            <div className='legend-block'>
              <Heading variant='body-xs' title='Index' />
              <div className='flex items-center'>
                <div className='duplicateIndex' />
                <Heading variant='body-xs' title='Exist in your company' />
              </div>
            </div>
          </div>
        </LeftSideBar>

        <RightSideBar className='w-full'>
          {viewLoader ? (
            <Spinners containerClassName='h-normal-screen' />
          ) : taxViewItemDetails !== undefined ? (
            <div className='view-block'>
              <div className='tax-header'>
                <Heading
                  variant='h4'
                  title={taxViewItemDetails?.name}
                  className='tax-header-title'
                />
                <Heading
                  className='tax-header-subtitle'
                  variant='body-s'
                  title={`This tax item currently exists in your company. If you decide to copy this tax item from the library to your company, your current tax item in your company will be updated with the details shown below.`}
                  color={`${COLORS.Gray}`}
                />
              </div>
              <div className='tax-body'>
                <TaxLibraryView viewData={taxViewItemDetails} />
                <div className='copy-button'>
                  <Button
                    variant='primary-outline'
                    title='Copy this tax'
                    onClick={() => {
                      taxViewItemDetails?.id && setCheckedValues([taxViewItemDetails?.id]);
                      openModal();
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className='flex justify-center items-center h-full'>
              <NoDataFound message='No Data Found' />
            </div>
          )}
        </RightSideBar>
        {isModalOpen && (
          <AlertPopup
            isShow={isModalOpen}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            width={'700px'}
            customTitle={
              <div className='confirm'>
                <Heading variant='h5' title='Preview' />

                <span className='confirm-title'>Copying Library Items</span>
                <span className='confirm-subtitle'>
                  You have selected {checkedValues?.length > 0 ? checkedValues?.length : '0'} item
                  {checkedValues?.length <= 1 ? '' : 's'} to copy from the library to your company.
                </span>

                <span className='confirm-title'>Updating Existing Items</span>
                <span className='confirm-subtitle'>
                  Note that these copied tax items will apply to the tax period defined by the
                  library. Any existing items in your company having the same name as the library
                  items will be updated.
                </span>

                <span className='confirm-title'>Confirm and Certify</span>
                <span className='confirm-subtitle'>
                  By selecting "Confirm", you are certifying that you have reviewed the items and
                  that they are applicable for your company. After copying them to your company, you
                  can choose to remove them or further modify them according to your needs.
                </span>

                <span className='confirm-title'>Consult Tax Advisor</span>
                <span className='confirm-subtitle'>
                  You understand that these library items are not recommendations for your tax
                  treatment. You should consult with your tax advisor for their suitability.
                </span>
              </div>
            }
            loading={loading}
          />
        )}

        {isToastOpen && (
          <AlertPopup
            isShow={isToastOpen}
            closeModal={() => {
              closeToastModal();
              void queryClient.invalidateQueries('getTaxPeriodTableForWealthlaneList');
            }}
            width={'500px'}
            display={false}
            customTitle={
              <div className='status'>
                <span
                  className='material-symbols-outlined'
                  style={{ color: `${error ? COLORS.Red : COLORS.Green}` }}>
                  {error ? 'block' : 'check_circle'}
                </span>
                <span className='status-message'>
                  {error
                    ? error?.body?.error?.message || error?.body?.error?.validationErrors[0].message
                    : message}
                </span>
              </div>
            }
            loading={loading}
          />
        )}
      </LibraryContainer>
    </div>
  );
};

export default TaxLibrary;
