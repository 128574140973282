import { ErrorMessage, Field } from 'formik';
import React from 'react';
import Heading from '../typography';

interface InputFileTypeProps {
  multiple?: boolean;
  label?: string;
  name: string;
  className?: string;
  disabled?: boolean;
  errors?: any;
  touched?: any;
  onChange: (event: any) => void;
  accept?: string;
  fileType?: string;
}

const InputFile = React.memo((props: InputFileTypeProps) => {
  const {
    label,
    name,
    errors,
    touched,
    className,
    disabled,
    onChange,
    multiple,
    fileType,
    ...rest
  } = props;

  return (
    <>
      <div className='uploadDetail'>
        <span className='material-symbols-outlined mr-1'>backup</span>
        <div>
          <Heading variant='body-xs' title='Click to Upload or Drag or Drop' />
          <Heading
            variant='body-xs'
            title={fileType ? fileType : '*PNG, JPG, JPEG, PDF, DOCX (Not more than 10 MB)'}
          />
        </div>
      </div>

      <Field
        name={name}
        label={label}
        type='file'
        multiple
        accept={props?.accept ?? '.pdf, .jpg, .jpeg, .png, .docx, .doc'}
        disabled={disabled}
        onChange={(event: any) => {
          onChange(event);
        }}
        value={undefined}
        {...rest}
      />
      {Boolean(errors) && (
        <ErrorMessage name={name} component='div' className='field-error-message mt-1' />
      )}
    </>
  );
});

export default InputFile;
