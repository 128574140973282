import { type UseMutationResult, useMutation } from 'react-query';
import {
  ParticipantsLoginLogService,
  // eslint-disable-next-line camelcase
  type Wealthlane_Dtos_ParticipantLoginLog_UniqueParticipantLoginsDto,
} from '../../services/wealthlane-identity-services';

const useGetUniqueParticipantLogins = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setModalData: any,
  openModal: () => void
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_Dtos_ParticipantLoginLog_UniqueParticipantLoginsDto[] | undefined,
  unknown,
  void,
  unknown
> => {
  return useMutation(
    async () => {
      return await handleRequest(
        ParticipantsLoginLogService.getApiIdentityApiAppParticipantsLoginLogUniqueParticipantLogins()
      );
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          setModalData(response);
          openModal();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetUniqueParticipantLogins;
