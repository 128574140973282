/* eslint-disable camelcase */
// import { isEmpty } from 'lodash';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  ParticipantBroadcastMessageService,
  type Wealthlane_CustomerService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-customer-services';
import { isEmpty } from 'lodash';

const usePostParticipantBroadcastMessage = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  id?: string
): UseMutationResult<
  Wealthlane_CustomerService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      const newPayload = {
        ...payload,
        addToTemplate: payload.messageTemplateId !== '11' ? false : payload.addToTemplate,
        message: payload.message != null ? payload.message : payload.messageTemplate,
        messageTemplateId: payload.messageTemplateId === '11' ? null : payload.messageTemplateId,
      };
      if (id != null && id !== '') {
        return await handleRequest(
          ParticipantBroadcastMessageService.putApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessage(
            {
              ...newPayload,
              id,
            }
          )
        );
      }
      return await handleRequest(
        ParticipantBroadcastMessageService.postApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessage(
          newPayload
        )
      );
    },
    {
      onSuccess: async (
        response: Wealthlane_CustomerService_Dtos_Response_ResponseDto | undefined
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries('getParticipantBroadcastMessageList');
          closeModal();
          toast.success(
            <ToastComponent
              label='Success'
              message={`Participant broadcast message ${
                isEmpty(id) ? 'added' : 'edited'
                // id != null && id != '' ? 'edited' : 'added' ? 'edited' : 'added'
              }.`}
            />
          );
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostParticipantBroadcastMessage;
