import styled from 'styled-components';
import { COLORS } from './color';
import { rem } from '../../utils/style';

interface TableType {
  alignItems?: string;
  wrap?: boolean;
}

export const TableBodyCellColumn = styled.div<TableType>`
  text-align: 'left';
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props?.alignItems ?? ''};
  align-items: ${(props) => props?.alignItems ?? ''};
  row-gap: 4px;

  .cellTitle {
    line-height: ${rem(18)};
    display: block;
    gap: 4px;
    max-width: 225px;
    white-space: ${(props) => (props?.wrap ?? false ? ' ' : 'nowrap' ?? '')};
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cellSubtitle {
    font-size: ${rem(12)};
    font-weight: 500;
    line-height: ${rem(14)};
    color: ${COLORS.Gray};
  }
`;

export const TableBodyCellColumnCenter = styled.div`
  text-align: center;
`;
