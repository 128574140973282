import { type PropsWithChildren } from 'react';
import { motion } from 'framer-motion';
import { ModelContainer } from '../../modal/modal.styled';
import { useSelector } from 'react-redux';
import { selectNotify } from '../../../pages/auth/notify';

interface ModalAnimationType {
  isNotification: boolean;
  closeModal: () => void;
}

const ModalAnimation = ({
  isNotification,
  children,
  closeModal,
}: PropsWithChildren<ModalAnimationType>): JSX.Element => {
  const showNotify = useSelector(selectNotify);

  const dropIn = {
    hidden: {
      x: '500px',
      opacity: 0,
      transition: {
        delay: 0.5,
      },
    },
    visible: {
      x: '0',
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
    exit: {
      x: '500px',
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  const backdropIn = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <ModelContainer showNotify={showNotify}>
      <motion.div
        variants={backdropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
        className={`backdrop ${isNotification ? 'active' : ''}`}>
        <motion.div variants={dropIn} initial='hidden' animate='visible' exit='exit'>
          {children}
        </motion.div>
      </motion.div>
    </ModelContainer>
  );
};

export default ModalAnimation;
