/* eslint-disable camelcase */
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputText from '../../../../components/input-text/inputText';
import ModalComponent from '../../../../components/modal/modal';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';
import { CONTACTS_VALIDATION } from '../../../../utils/validations/validations';
import { type Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto } from '../../../../services/wealthlane-customer-services';
import { Label } from '../../../../components/input-text/input.styled';
import FormSelect from '../../../../components/dropdown/formSelect';
import { phoneFormat } from '../../../../utils/global';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import {
  useGetCountryCode,
  usePostKeysContacts,
  useWealthlaneCustomerApi,
} from '../../../../hooks';
import _ from 'lodash';

interface ContactsModalType {
  loading: boolean;
  isTransferAgentContact: boolean;
  isModalOpen: boolean;
  modalTitle: string;
  data?: Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto;
  closeModal: () => void;
}

const ContactsModal = ({
  loading,
  isModalOpen,
  data,
  modalTitle,
  isTransferAgentContact,
  closeModal,
}: ContactsModalType): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data: countryCodeOptions } = useGetCountryCode(handleRequest);

  const { mutate, isLoading } = usePostKeysContacts(handleRequest, closeModal);
  const handleSubmit = (
    values: Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto
  ): void => {
    const payload = {
      ...values,
      isUpdate: !(values.id == null),
    };

    mutate(payload);
  };

  const initialData = {
    ...data,
    phoneIsOtherCountry: countryCodeOptions?.find(
      (obj) => obj?.value === data?.countryCallingCodeId
    )?.isOtherCountry,
    isTransferAgent: isTransferAgentContact,
  };

  return (
    <>
      {data != null && (
        <Formik
          enableReinitialize
          initialValues={initialData}
          validationSchema={Yup.object().shape(CONTACTS_VALIDATION)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}>
          {({ touched, handleChange, setFieldValue, setFieldTouched, errors, values }) => {
            return (
              <ModalComponent
                show={isModalOpen}
                closeModal={closeModal}
                size='md'
                loading={loading}
                title={modalTitle}
                valueEdited={_.isEqual(values, initialData)}
                children={
                  <Form>
                    <ModalBodyComponent>
                      <div className='grid grid-cols-2 gap-x-8'>
                        {isTransferAgentContact && (
                          <div className='col-span-2'>
                            <InputText
                              label='Transfer Agent ID'
                              name='transferAgentId'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                            />
                          </div>
                        )}
                        <div className='col-span-2'>
                          <InputText
                            label={`Name of the ${
                              isTransferAgentContact ? 'Transfer Agent' : 'Company'
                            }`}
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-span-2'>
                          <InputText
                            label='Email'
                            name='email'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-span-2'>
                          <Label htmlFor={'contactNumber'}>Mobile Number (Primary)</Label>
                          <div className='flex flex-row gap-x-[12px]'>
                            <FormSelect
                              name='countryCallingCodeId'
                              viewName='countryCallingCodeId'
                              onChange={(e: any) => {
                                const isOther = countryCodeOptions?.find(
                                  (obj) => obj?.value === e
                                )?.isOtherCountry;
                                handleChange(e);
                                setFieldValue('phone', '');
                                setFieldValue('phoneIsOtherCountry', isOther);
                                setFieldTouched('phone', false);
                              }}
                              setFieldValue={setFieldValue}
                              values={values}
                              arrValue={values?.countryCallingCodeId}
                              placeholder={'Select'}
                              errorValue={errors?.countryCallingCodeId}
                              touchedValue={touched?.countryCallingCodeId}
                              options={countryCodeOptions}
                              touched={touched}
                              errors={errors}
                              width={'220px'}
                              required
                            />
                            <InputText
                              type='text'
                              name='phone'
                              onChange={(e: any) => {
                                countryCodeOptions?.find(
                                  (obj) => obj?.value === values?.countryCallingCodeId
                                )?.isOtherCountry ?? false
                                  ? handleChange(e)
                                  : phoneFormat(`phone`, setFieldValue, e.target.value);
                              }}
                              errors={errors}
                              touched={touched}
                              inputType='contact'
                              width={'200px'}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </ModalBodyComponent>
                    <ModalFooterComponent
                      modalStatus='Edit'
                      onCloseModal={() => {
                        closeModal();
                      }}
                      valueChanged={_.isEqual(values, initialData)}
                      loading={isLoading}
                    />
                  </Form>
                }
              />
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ContactsModal;
