import React from 'react';
import { useLayer, Arrow } from 'react-laag';
import { type TooltipType } from '.';
import { COLORS } from '../../constants/style/color';
import Heading from '../typography';
import { TooltipComponentContainer, TooltipComponentWrapper } from './tooltip.styled';

const ClickableTooltip = ({ children, tooltipText, size, variant }: TooltipType): JSX.Element => {
  const [isOpen, setOpen] = React.useState(false);

  // helper function to close the menu
  const close = (): void => {
    setOpen(false);
  };

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-center', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
    possiblePlacements: ['top-center', 'bottom-center'],
  });

  // Again, we're using framer-motion for the transition effect
  return (
    <TooltipComponentContainer>
      <div
        className='tooltip-text'
        {...triggerProps}
        onClick={() => {
          setOpen(!isOpen);
        }}>
        {children}
      </div>
      {renderLayer(
        <>
          {isOpen && (
            <TooltipComponentWrapper
              size={size ?? 'md'}
              variant={variant ?? 'light'}
              className='tooltip-box'
              {...layerProps}>
              <Heading variant='body-s' title={tooltipText} />
              <Arrow
                {...arrowProps}
                backgroundColor={variant === 'dark' ? COLORS.Black : COLORS.White}
                borderColor={variant === 'dark' ? COLORS.Black : COLORS.White}
                borderWidth={1}
                size={6}
              />
            </TooltipComponentWrapper>
          )}
        </>
      )}
    </TooltipComponentContainer>
  );
};

export default ClickableTooltip;
