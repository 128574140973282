import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation } from 'react-query';
import ToastComponent from '../../components/toast';
import { PsuAwardService, RsuAwardService } from '../../services/wealthlane-award-services';
import { handleOpenFile } from '../../utils/global';

const usePostAdminDashboardPsuGrantList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  string | undefined,
  unknown,
  {
    maxResultCount?: number | undefined;
    skipCount?: number | undefined;
    sorting?: string | undefined;
    filter?: string | undefined;
    vestingDate?: string | undefined;
    grantId?: string | undefined;
  },
  unknown
> => {
  return useMutation(
    async (payload: {
      maxResultCount?: number;
      skipCount?: number;
      sorting?: string;
      filter?: string;
      vestingDate?: string;
      grantId?: string;
    }) => {
      return await handleRequest(
        PsuAwardService.postApiAwardApiAppPSUAwardExportAdminDashboardGrantList(payload)
      );
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message='Exported' />);
          handleOpenFile(`PSU-Vesting-Participant-Event-Details.xlsx`, response ?? '');
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostAdminDashboardPsuGrantList;
