import { type UseQueryResult, useQuery } from 'react-query';
import {
  IntegrationProviderService,
  // eslint-disable-next-line camelcase
  type Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_CheckConnectionDto,
} from '../../services/wealthlane-hr-integration-services';

const usePostCheckFinchConnection = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_CheckConnectionDto | undefined,
  unknown
> => {
  const checkFinchConnection = useQuery(['postCheckFinchConnection'], async () => {
    return await handleRequest(
      IntegrationProviderService.postApiHrintegrationApiAppIntegrationProviderCheckConnection()
    );
  });

  return checkFinchConnection;
};

export default usePostCheckFinchConnection;
