/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { type ModalStatusType, type OptionType } from '../../../../types/common';
import ModalComponent from '../../../../components/modal/modal';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';
import InputText from '../../../../components/input-text/inputText';
import _ from 'lodash';
import FormSelect from '../../../../components/dropdown/formSelect';
import InitialValuesForm from '../../../../constants/initialValues';
import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import { useGetTaxJuridictionLevel, useGetTaxPeriodAll } from '../../../../hooks';
import { TaxBracketComponent } from './tax-block';
import ApplicableComponent from './tax-block/applicableComponent';
import ToastComponent from '../../../../components/toast';
import {
  TaxItemService,
  type Wealthlane_TaxService_Dtos_Response_ResponseDto,
  type Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemOverrideDto,
  type Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto,
  type Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto,
} from '../../../../services/wealthlane-tax-services';
import { toast } from 'react-toastify';
import Spinners from '../../../../components/spinner';
import useGetTaxNameOptions from '../../../../hooks/get/useGetTaxNameOptions';
import { useQueryClient } from 'react-query';
import { TAX_SETUP_VALIDATION } from '../../../../utils/validations/validations';

interface TaxOverrideType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  initialValue?: Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto | null;
}

const TaxOverrideModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  setModalStatus,
  initialValue,
}: TaxOverrideType) => {
  const queryClient = useQueryClient();
  const { handleRequest } = useWealthlaneTaxApi();
  const { data: taxPeriodOption } = useGetTaxPeriodAll(handleRequest);
  const { data: juridictionLevelOptions } = useGetTaxJuridictionLevel(handleRequest);
  const [loading, setLoading] = useState(false);

  const { data: parentTax } = useGetTaxNameOptions(handleRequest);
  const [metaValidation, setMetaValidation] = useState<
    Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | undefined
  >();

  const [applicableName, setApplicableName] = useState<string>('');

  const handleSubmit = (values: Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemOverrideDto) => {
    setLoading(true);
    handleRequest(TaxItemService.postApiTaxApiAppTaxItemTaxItemOverride(values))
      .then((response: Wealthlane_TaxService_Dtos_Response_ResponseDto | undefined) => {
        if (response != null) {
          closeModal();
          toast.success(<ToastComponent label='Success' message={response?.message} />);
          void queryClient.invalidateQueries('getTaxSetupList');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        initialValue != null
          ? {
              ...initialValue,
              name: '',
            }
          : InitialValuesForm.taxSetup
      }
      validationSchema={Yup.object().shape(TAX_SETUP_VALIDATION(metaValidation, applicableName))}
      onSubmit={(values) => {
        const payload = {
          ...values,
          taxBrackets: values?.taxBrackets?.map((item: any) => ({
            lowerLimit: Number(String(item?.lowerLimit)?.replaceAll(',', '') ?? ''),
            taxRate: Number(String(item?.taxRate)?.replaceAll(',', '') ?? ''),
            upperLimit: Number(String(item?.upperLimit)?.replaceAll(',', '') ?? ''),
          })),
          parentTaxItemId: initialValue?.id,
        };
        handleSubmit(payload);
      }}>
      {({
        touched,
        handleChange,
        errors,
        setFieldValue,
        setValues,
        setFieldTouched,
        values,
      }: any) => {
        const metaData: Wealthlane_TaxService_Dtos_TaxItemDto_JsonDto | undefined =
          juridictionLevelOptions?.filter(
            (item: OptionType) => item?.value === values?.jurisdictionLevelId
          )[0]?.metadata;

        useEffect(() => {
          setMetaValidation(metaData);
        }, [metaData]);

        console.log(errors);
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            // loading={loading}
            valueEdited={_.isEqual(values, InitialValuesForm.taxSetup)}
            size='lg'
            title={`Override Tax`}
            // title={`${modalStatus === 'Add' ? 'Create' : modalStatus} Tax Period`}
            children={
              <Form>
                <ModalBodyComponent>
                  <>
                    {initialValue == null ? (
                      <Spinners className='mt-[35%]' />
                    ) : (
                      <div className=''>
                        <div className='grid grid-cols-12 gap-x-8'>
                          <div className='col-span-5'>
                            <InputText
                              type='text'
                              label='Name'
                              name='name'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              inputType={modalStatus}
                              required
                            />
                          </div>
                          <div className='col-span-3'>
                            <FormSelect
                              type='text'
                              label={'Tax period'}
                              name='taxPeriodId'
                              containerClassName='formGroup'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={taxPeriodOption}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled
                              required
                            />
                          </div>
                          <div className='col-span-4'>
                            <FormSelect
                              type='text'
                              label={'Jurisdiction Level'}
                              name='jurisdictionLevelId'
                              containerClassName='formGroup'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={juridictionLevelOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled
                              required
                            />
                          </div>
                          <div className='col-span-4'>
                            <FormSelect
                              type='text'
                              label={'Parent Tax'}
                              name='id'
                              containerClassName='formGroup'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={parentTax}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled
                              required
                            />
                          </div>
                        </div>

                        {/* Tax Bracket */}
                        <TaxBracketComponent
                          metaData={metaData}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          values={values}
                          modalStatus={modalStatus}
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          setFieldTouched={setFieldTouched}
                        />
                        {/* <TaxBracketComponent
                          metaData={metaData}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          modalStatus={modalStatus}
                        /> */}

                        {/* Applicable TO */}
                        <ApplicableComponent
                          setApplicableName={setApplicableName}
                          handleRequest={handleRequest}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          modalStatus={modalStatus}
                        />
                      </div>
                    )}
                  </>
                </ModalBodyComponent>
                <ModalFooterComponent
                  modalStatus={'Add'}
                  loading={loading}
                  valueChanged={_.isEqual(values, initialValue)}
                  onCloseModal={() => {
                    closeModal();
                  }}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default TaxOverrideModal;
