import { type UseQueryResult, useQuery } from 'react-query';
import { SortOrderTypeService } from '../../services/wealthlane-report-services';
import { StatusDropdownAppServicesService } from '../../services/wealthlane-award-services';

const useGetRoundingType = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const roundingTypeList = useQuery(
    ['getRoundingTypeList'],
    async () => {
      return await handleRequest(
        StatusDropdownAppServicesService.getApiAwardApiAppStatusDropdownAppServicesRoundingTypes()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.displayName ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return roundingTypeList;
};

export default useGetRoundingType;
