import { type UseQueryResult, useQuery } from 'react-query';
import {
  RsuAwardService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetUpcomingRsuVestingList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { sorting, skipCount, maxResultCount, filter } = tableInfo;
  const upcomingRsuVestingList = useQuery(
    ['getUpcomingRsuVestingList', sorting, skipCount, maxResultCount, filter],
    async () => {
      return await handleRequest(
        RsuAwardService.getApiAwardApiAppRSUAwardUpcomingVestingGrants(
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return upcomingRsuVestingList;
};

export default useGetUpcomingRsuVestingList;
