/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import {
  OptionExerciseService,
  Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto,
} from '../../services/wealthlane-award-services';

const usePutUpdateTaxDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setModalStatus?: any
): UseMutationResult<
  Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      const newPayload = {
        ...payload,
        taxDetails: payload.taxDetails.map((detail: any) => ({
          ...detail,
          taxRate: Number(String(detail?.taxRate)?.replaceAll(',', '') ?? ''),
          taxAmount: Number(String(detail?.taxAmount)?.replaceAll(',', '') ?? ''),
        })),
      };

      return await handleRequest(
        OptionExerciseService.putApiAwardApiAppOptionExerciseApplicableTaxes(newPayload)
      );
    },
    {
      onSuccess: async (response: any) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message='Tax edited.' />);
          setModalStatus('View');
          await queryClient.invalidateQueries('getOptionExerciseTaxDetail');
          await queryClient.invalidateQueries('getTransactionIncludeTax');
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePutUpdateTaxDetails;
