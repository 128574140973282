import { type UseQueryResult, useQuery } from 'react-query';
import {
  CompanyNotificationConfigurationService,
  type Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_GetEmailEnableToParticipantDto,
} from '../../services/wealthlane-notification-services';

const useGetCompanyNotificationConfig = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_NotificationService_DTOs_CompanyNotificationConfigurationAppService_GetEmailEnableToParticipantDto
  | undefined,
  unknown
> => {
  const companyNotificationConfig = useQuery(
    ['getCompanyNotificationConfig'],
    async () => {
      return await handleRequest(
        CompanyNotificationConfigurationService.getApiNotificationApiAppCompanyNotificationConfigurationCheckEmailToParticipant()
      );
    },
    {
      cacheTime: 1000 * 60 * 5,
    }
  );

  return companyNotificationConfig;
};

export default useGetCompanyNotificationConfig;
