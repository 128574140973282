import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FormSelect from '../../../components/dropdown/formSelect';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import { type ModalStatusType } from '../../../types/common';
import { GrantService, PlanService } from '../../../services/wealthlane-award-services';
import { ImagePreview, Label, UploadWrapper } from '../../../components/input-text/input.styled';
import { GRANT_MANAGEMENT_VALIDATION } from '../../../utils/validations/validations';
import { useMutation, useQueryClient } from 'react-query';
import { MaterialIconFilled, handleOpenFile } from '../../../utils/global';
import ImageViewer from '../../../components/input-file/inputFileWithView';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import ToastComponent from '../../../components/toast';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import Heading from '../../../components/typography';
import Tag from '../../../components/lozenge/tags';
import {
  useBoolean,
  useGetAvailablePerformanceRules,
  useGetGrantPlanTypeList,
  useGetGrantVestingTemplateList,
  useGetPlanGrantDocumentList,
  useGetPlanGrantDocumentTypes,
  useGetVestingTypeOptions,
  useWealthlaneAwardApi,
} from '../../../hooks';
import Button from '../../../components/button';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import VestingModal from '../vesting-templates/vestingModal';
import CustomDatePicker from '../../../components/date-picker/customDatePicker';
import { ActiveOptions } from '../../../constants/common';
import RichTextEditor from '../../../components/rich-text-editor';
import ImageViewerSingle from '../../../components/input-file/inputFileWithViewSingle';
import { COLORS } from '../../../constants/style/color';
import {
  Divider,
  DocumentContainer,
  SwitchContainer,
} from '../plan-management/planManagement.styled';
import _, { isEmpty } from 'lodash';
import ClickableTooltip from '../../../components/tooltip/clickTooltip';
import PerformanceRulesModal from '../performance-rule/performanceRulesModal';
import InitialValuesForm from '../../../constants/initialValues';
import NewVestingModal from '../vesting-templates/newVestingModal';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
import GrantDocumentModel from '../plan-management/grantDocumentModel';

const initialPerformanceRuleValues = {
  data: InitialValuesForm.performanceRule,
  id: '',
};

interface GrantModalType {
  awardLoader?: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;

  initialData: {
    data: any;
    id: string;
    planId: string | undefined;
  };
  planInitialData?: any;
}

const GrantManagementModal = ({
  awardLoader,
  isModalOpen,
  closeModal,
  modalStatus,
  setModalStatus,
  initialData,
  planInitialData,
}: GrantModalType): JSX.Element => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [removedId, setRemovedId] = useState<string[]>([]);
  const {
    value: isModalOpenVesting,
    setFalse: closeModalVesting,
    setTrue: openModalVesting,
  } = useBoolean(false, true);
  const {
    value: isModalOpenPerformanceRule,
    setFalse: closeModalPerformanceRule,
    setTrue: openModalPerformanceRule,
  } = useBoolean(false, true);
  const { handleRequest } = useWealthlaneAwardApi();
  const { data: grantVestingOptions } = useGetGrantVestingTemplateList(handleRequest);

  const { data: grantPlanOptions } = useGetGrantPlanTypeList(handleRequest, initialData.planId);
  const { data: vestingTypeOptions } = useGetVestingTypeOptions(handleRequest);

  const { data: AvailablePerformanceRulesOptions } = useGetAvailablePerformanceRules(
    handleRequest,
    initialData?.id
  );

  const [toggle, setToggle] = useState<boolean>(
    !isEmpty(initialData.data?.termsAndConditionTextName) ? true : false
  );

  const { data: grantAgreementList } = useGetPlanGrantDocumentList(
    handleRequest,
    initialData?.planId,
    1
  );

  const { data: grantNoticeList } = useGetPlanGrantDocumentList(
    handleRequest,
    initialData?.planId,
    2
  );
  const { data: termsAndCondition } = useGetPlanGrantDocumentList(
    handleRequest,
    initialData?.planId,
    3
  );
  const { data: termsAndConditionText } = useGetPlanGrantDocumentList(
    handleRequest,
    initialData?.planId,
    4
  );

  const useSubmitGrant = (): any => {
    return useMutation(
      async (payload: any) => {
        const newPayload = {
          ...payload,
          planTypeName: grantPlanOptions?.filter(
            (item) => item.value === payload?.planTypeName
          )?.[0]?.label,
          performanceRuleName: AvailablePerformanceRulesOptions?.filter(
            (item) => item.value === payload?.performanceRuleName
          )?.[0]?.label,
          vestingTemplateName: grantVestingOptions?.filter(
            (item) => item.value === payload?.vestingTemplateName
          )?.[0]?.label,
          grantAgreementDocumentName: grantAgreementList?.filter(
            (item) => item?.value === payload?.grantAgreementDocumentName
          )?.[0]?.label,
          grantNoticeDocumentName: grantNoticeList?.filter(
            (item) => item?.value === payload?.grantNoticeDocumentName
          )?.[0]?.label,
          termsAndConditionDocumentName: termsAndCondition?.filter(
            (item) => item?.value === payload?.termsAndConditionDocumentName
          )?.[0]?.label,
          termsAndConditionTextName: termsAndConditionText?.filter(
            (item) => item?.value === payload?.termsAndConditionTextName
          )?.[0]?.label,
          isTermsAndConditionsDocumentRemoved: !(payload.termsAndConditionsDocumentName
            ?.length as boolean),
        };

        if (initialData.id != null && initialData.id !== '') {
          return await handleRequest(GrantService.putApiAwardApiAppGrantGrant(newPayload));
        }
        return await handleRequest(GrantService.postApiAwardApiAppGrantGrant(newPayload));
      },
      {
        onSuccess: (response: any) => {
          if (response != null) {
            void queryClient.invalidateQueries('getGrantList');
            void queryClient.invalidateQueries('getPlanManagementList');
            toast.success(
              <ToastComponent
                label={'Success'}
                message={`Grant ${
                  initialData.id != null && initialData.id !== '' ? 'edited' : 'added'
                } `}
              />
            );
            closeModal();
          }
        },
        onError: (err) => {
          console.log('error', err);
        },
      }
    );
  };

  const { mutate, isLoading: grantLoader } = useSubmitGrant();
  const handleSubmit = (values: any, resetForm: any): void => {
    // const grantDocumentList = initialData.data?.documentNameList.map((item: any) => {
    //   return {
    //     grantDocumentFileName: item.file.name,
    //     grantDocumentUniqueName: item.id,
    //     isDeleted: removedId.includes(item.id),
    //   };
    // });

    // const indexValues: Record<string, string | number | boolean> = {};
    // grantDocumentList?.map((item: Record<string, string>, index: number) => {
    //   Object.keys(item).forEach((k, i) => {
    //     indexValues[`grantDocumentDetails[${index}].${k}`] = item[k];
    //   });
    //   return null;
    // });
    // const termAndConditionDocumentList = initialData.data?.termsAndConditionsDocumentName.map(
    //   (item: any) => {
    //     return {
    //       termsAndConditionsDocumentFileName: item.file.name,
    //       termsAndConditionsDocumentUniqueName: item.id,
    //       isDeleted: removedId.includes(item.id),
    //     };
    //   }
    // );

    // const termAndConditionIndexValues: Record<string, string | number | boolean> = {};
    // termAndConditionDocumentList?.map((item: Record<string, string>, index: number) => {
    //   Object.keys(item).forEach((k, i) => {
    //     termAndConditionIndexValues[`termAndConditionDocumentDetails[${index}].${k}`] = item[k];
    //   });
    //   return null;
    // });

    const payload = {
      // grantName: values.grantName,
      // id: values.id,
      ...values,
      planId: initialData.planId,
      planGrantDocuments: documentDto ? documentDto?.filter((item: any) => !item?.isDefault) : [],
      // planTypeId: values.planTypeId,
      // grantDocument: values.grantDocument,
      // termsAndConditionsDocument: values?.termsAndConditionsDocument,
      // termsAndConditionsDocumentContent: values?.termsAndConditionsDocumentContent,
      // termsAndConditionsAgreementStatement: values?.termsAndConditionsAgreementStatement,
      // agreementStatementStatusName: values?.agreementStatementStatusName === 'Active',
      // approvedDate: values?.approvedDate,
      // vestingScheduleId: values.vestingScheduleId,
      // performanceRuleId: values.performanceRuleId,
      // termsAndConditionsDocumentName: values?.termsAndConditionsDocumentName,
      // ...indexValues,
      // ...termAndConditionIndexValues,
    };

    mutate(payload);
    // resetForm();
  };

  // const handleImageOpen = (name: string): void => {
  //   setLoading(true);
  //   handleRequest(
  //     GrantService.postApiAwardApiAppGrantDownloadGrantDocument({
  //       grantDocumentUniqueName: name,
  //       grantId: initialData.id,
  //     })
  //   )
  //     .then((data: any) => {
  //       handleOpenFile(data.name, data.content);
  //     })
  //     .catch((error: any) => {
  //       console.log('error', error);
  //     });
  //   setLoading(false);
  // };

  // const handleImageOpenTermAndConditions = (name: string): void => {
  //   setLoading(true);
  //   handleRequest(
  //     GrantService.postApiAwardApiAppGrantDownloadTermsAndConditionsDocument(
  //       initialData?.data?.grantTermsAndConditionsId
  //     )
  //   )
  //     .then((data: any) => {
  //       handleOpenFile(data.name, data.content);
  //     })
  //     .catch((error: any) => {
  //       console.log('error', error);
  //     });
  //   setLoading(false);
  // };

  const {
    value: isModalGrantOpen,
    setFalse: closeModalGrant,
    setTrue: openModalGrant,
  } = useBoolean(false, true);
  const [modelName, setModelName] = useState<string>();
  const [documentDto, setDocumentDto] = useState<any>([]);
  const [documentType, setDocumentType] = useState<string | number | null>();
  const [deletedGrantDocument, setDeletedGrantDocument] = useState<string[]>([]);

  const planGrantDocumentType = useGetPlanGrantDocumentTypes(handleRequest);

  const getValueByLabel = (label: string) => {
    const item = planGrantDocumentType?.data?.find((item) => item.label === label);
    return item ? setDocumentType(item.value) : null;
  };

  const handleGrantDocumentImageOpen = (id: string): void => {
    handleRequest(
      PlanService.postApiAwardApiAppPlanDownloadPlanGrantDocument({
        planId: planInitialData.id,
        id: id,
      })
    )
      .then((data: any) => {
        handleOpenFile(data.name, data.content);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleDeleteGrantDocument = (id: string) => {
    setDeletedGrantDocument((prevState) => [...prevState, id]);
  };

  const [filteredGrantAgreementDocument, setFilteredGrantAgreementDocument] = useState([]);
  const [filteredGrantNoticeDocument, setFilteredGrantNoticeDocument] = useState([]);
  const [filteredTermsAndConditionDocument, setFilteredTermsAndConditionDocument] = useState([]);
  const [filteredTermsAndConditionText, setFilteredTermsAndConditionText] = useState([]);
  // const [filteredTermsAndCondition, setFilteredTermsAndCondition] = useState([]);
  const convertToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
    };
    reader.onerror = (error) => {
      console.error('Error converting file to base64:', error);
    };
  };

  const getDocument = (label: string) => {
    // Find the ID corresponding to the label
    const id =
      planGrantDocumentType?.data &&
      planGrantDocumentType?.data.find((item: any) => item.label === label)?.value;
    // Filter data from A based on the grantDocumentType
    return documentDto.filter((item: any) => item.grantDocumentType === id);
  };

  useEffect(() => {
    if (initialData?.data?.dataplanGrantDocuments?.length > 0) {
      setDocumentDto(
        initialData?.data?.planGrantDocuments?.map((doc: any) => ({
          ...doc,
          grantDocumentName: doc?.documentName,
          grantDocumentType: doc?.documentTypeId,
        }))
      );
    }
  }, [initialData?.data?.planGrantDocuments?.length]);

  useEffect(() => {
    setFilteredGrantAgreementDocument(() => getDocument('Grant Agreement Document'));
    setFilteredGrantNoticeDocument(() => getDocument('Grant Notice Document'));
    setFilteredTermsAndConditionDocument(() => getDocument('Terms And Condition Document'));
    setFilteredTermsAndConditionText(() => getDocument('Terms And Condition Text'));
    if (documentDto) {
      convertToBase64(documentDto?.[0]?.document?.[0]);
    }
  }, [documentDto, planGrantDocumentType?.data]);

  // useEffect(() => {
  //   setFilteredTermsAndCondition([
  //     ...filteredTermsAndConditionDocument,
  //     ...filteredTermsAndConditionText,
  //   ]);
  // }, [filteredTermsAndConditionDocument, filteredTermsAndConditionText]);

  const removeObjectsByName = (array: any, name: string) => {
    return array.filter((item: any) => item.grantDocumentName !== name);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData.data}
        validationSchema={GRANT_MANAGEMENT_VALIDATION}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions.resetForm);
        }}>
        {({
          touched,
          handleChange,
          errors,
          setFieldTouched,
          setFieldValue,
          setFieldError,
          values,
        }) => {
          useEffect(() => {
            setFieldValue(
              'grantAgreementDocumentName',
              filteredGrantAgreementDocument?.length > 0 ? '' : values?.grantAgreementDocumentName
            );
            setFieldValue(
              'grantNoticeDocumentName',
              filteredGrantNoticeDocument?.length > 0 ? '' : values?.grantNoticeDocumentName
            );
            setFieldValue(
              'termsAndConditionDocumentName',
              filteredTermsAndConditionDocument?.length > 0
                ? ''
                : values?.termsAndConditionDocumentName
            );
            setFieldValue(
              'termsAndConditionTextName',
              filteredTermsAndConditionText?.length > 0 ? '' : values?.termsAndConditionTextName
            );
          }, [
            filteredGrantAgreementDocument?.length,
            filteredGrantNoticeDocument?.length,
            filteredTermsAndConditionDocument?.length,
            filteredTermsAndConditionText?.length,
          ]);

          return (
            <ModalComponent
              show={isModalOpen}
              closeModal={closeModal}
              loading={awardLoader}
              title={`${modalStatus} Grant`}
              valueEdited={_.isEqual(values, initialData.data)}
              size='lg'
              children={
                <>
                  <Form>
                    <ModalBodyComponent>
                      {modalStatus !== 'Add' && (
                        <div className='flex gap-x-5 mb-5'>
                          <Heading
                            variant='h4'
                            title={
                              (initialData?.data?.planName as boolean)
                                ? initialData?.data?.planName
                                : planInitialData?.data?.name
                            }
                          />
                          <Tag
                            variant='primary'
                            title={`#${
                              (initialData?.data?.uniquePlanNo as boolean)
                                ? String(initialData?.data?.uniquePlanNo ?? '')
                                : String(planInitialData?.data?.uniquePlanNo ?? '')
                            }`}
                          />
                        </div>
                      )}
                      <div className='mb-4 grid grid-cols-1 sm:grid-cols-2 gap-x-3 xl:gap-x-5'>
                        <InputText
                          type='text'
                          label='Grant Name'
                          name='grantName'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View' || initialData?.data?.isGrantInUse}
                          required
                        />
                        <FormSelect
                          label='Grant Type'
                          name='planTypeName'
                          viewName='planTypeName'
                          onChange={(e: any) => {
                            // const planTypeName = grantPlanOptions?.filter(
                            //   (item: any) => item.value === e
                            // )?.[0]?.label;
                            handleChange(e);
                            // setFieldValue('planTypeName', planTypeName);
                          }}
                          errors={errors}
                          touched={touched}
                          options={grantPlanOptions}
                          placeholder='Select Type'
                          values={values}
                          setFieldValue={setFieldValue}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View' || initialData?.data?.isGrantInUse}
                          containerClassName='formGroup '
                          width='184px'
                          required
                        />
                        <div className='flex items-center gap-x-5'>
                          <CustomDatePicker
                            label={
                              <div className='flex items-center gap-x-4'>
                                Grant Date
                                <ClickableTooltip
                                  tooltipText={'This date represents board approval of the grant.'}
                                  variant='dark'
                                  size='sm'>
                                  <span
                                    className='material-symbols-sharp mt-2 text-[18px]'
                                    style={MaterialIconFilled}>
                                    info
                                  </span>
                                </ClickableTooltip>
                              </div>
                            }
                            name='approvedDate'
                            onChange={handleChange}
                            arrValue={values?.approvalDate}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            errors={errors}
                            touched={touched}
                            values={values}
                            // max={new Date()}
                            defaultValue={new Date()}
                            disabled={modalStatus === 'View' || initialData?.data?.isGrantInUse}
                            required
                          />
                        </div>
                        <div className='flex items-center'>
                          <CustomCheckBox
                            name='approvalRequired'
                            onChange={(e: any) => {
                              handleChange(e);
                            }}
                            errors={errors}
                            touched={touched}
                            className={'individual-check mt-2'}
                            labelClassName={'whitespace-normal'}
                            disabled={modalStatus === 'View' || initialData?.data?.isGrantInUse}
                            checked={values?.approvalRequired}
                            id='acceptCheck'
                            label={
                              <Heading
                                variant='body-xs'
                                className='mt-1'
                                title={'Require Acceptance'}
                              />
                            }
                            required
                          />
                        </div>

                        {(grantPlanOptions?.filter(
                          (item) => item.value === values?.planTypeName
                        )?.[0]?.label === 'PSU' ||
                          values?.planTypeName === 'PSU') && (
                          <div className='sm:col-span-2 '>
                            <div
                              className={`${
                                modalStatus === 'View' ? 'grid grid-cols-3 items-center gap-4' : ''
                              }`}>
                              <FormSelect
                                label='Performance Rule'
                                name='performanceRuleName'
                                viewName='performanceRuleName'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                placeholder='Select Performance Rule'
                                options={AvailablePerformanceRulesOptions}
                                values={values}
                                setFieldValue={setFieldValue}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View' || initialData?.data?.isGrantInUse}
                                containerClassName='formGroup col-span-2 mb-0'
                                // takeName={true}
                                // width='184px'
                                required
                              />
                              {modalStatus === 'View' && (
                                <span
                                  className='view-link mt-8'
                                  onClick={() => openModalPerformanceRule()}>
                                  View rule
                                </span>
                              )}
                            </div>
                            <div className='my-8'>
                              <Button
                                as='Create'
                                variant={'primary-outline'}
                                title={'Add performance rule'}
                                icon={'add_circle_outline'}
                                type='button'
                                onClick={() => {
                                  openModalPerformanceRule();
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div className='sm:col-span-2 '>
                          <div>
                            <FormSelect
                              label='Vesting Template'
                              name='vestingTemplateName'
                              viewName='vestingScheduleName'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              placeholder='Select Template'
                              options={grantVestingOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled={modalStatus === 'View' || initialData?.data?.isGrantInUse}
                              containerClassName='formGroup '
                              // width='184px'
                              // takeName={true}
                              required
                            />
                          </div>
                          <div className='my-8'>
                            <Button
                              as='Create'
                              variant={'primary-outline'}
                              title={'Add vesting template'}
                              icon={'add_circle_outline'}
                              type='button'
                              onClick={() => {
                                openModalVesting();
                              }}
                            />
                          </div>
                        </div>

                        <div className='sm:col-span-2 '>
                          <div
                            className={`${
                              modalStatus === 'View' ? 'grid grid-cols-3 items-center gap-4' : ''
                            }`}>
                            <FormSelect
                              label='Grant Agreement'
                              name='grantAgreementDocumentName'
                              viewName='grantAgreementDocumentName'
                              onChange={(e: string) => {
                                const removeDtoFile = documentDto.filter(
                                  (item: any) => item.grantDocumentType !== 1
                                );
                                setDocumentDto(removeDtoFile);
                                handleChange(e);
                              }}
                              errors={errors}
                              touched={touched}
                              placeholder='Select Grant Agreement'
                              options={grantAgreementList}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled={modalStatus === 'View' || initialData?.data?.isGrantInUse}
                              containerClassName='formGroup col-span-2 mb-0'
                              // width='184px'
                              // takeName={true}
                              required
                            />
                          </div>
                          <div className='my-8'>
                            {filteredGrantAgreementDocument &&
                              filteredGrantAgreementDocument?.length > 0 && (
                                <DocumentContainer>
                                  {filteredGrantAgreementDocument?.map((details: any) => (
                                    <ImagePreview key={1} className='cursor-pointer'>
                                      <div
                                        className='image-wrapper cursor-pointer'
                                        title={'Grant Agreement V1'}
                                        // className='cursor-pointer'
                                        onClick={() => handleGrantDocumentImageOpen(details?.id)}>
                                        <>
                                          <span className='material-symbols-outlined image-icon'>
                                            draft
                                          </span>
                                          <span className='image-filename'>{`${details?.grantDocumentName}`}</span>
                                        </>
                                      </div>

                                      <div>
                                        <div
                                          className='image-cross'
                                          onClick={() => {
                                            const removeFile = removeObjectsByName(
                                              filteredGrantAgreementDocument,
                                              details?.grantDocumentName
                                            );
                                            const removeDtoFile = removeObjectsByName(
                                              documentDto,
                                              details?.grantDocumentName
                                            );
                                            // const removeFile = values[listingName]?.filter(
                                            //   (item: any) => item.id !== value.id
                                            // );
                                            // setFieldValue(listingName, removeFile);
                                            // setRemovedId(removedId.concat(value.id));
                                            setFilteredGrantAgreementDocument(removeFile);
                                            handleDeleteGrantDocument(details?.id);
                                            setDocumentDto(removeDtoFile);
                                          }}>
                                          <span className='material-symbols-outlined image-delete'>
                                            delete
                                          </span>
                                        </div>
                                      </div>
                                    </ImagePreview>
                                  ))}
                                </DocumentContainer>
                              )}

                            {modalStatus !== 'View' && (
                              <Button
                                // as={'Create' | 'Edit'}
                                type='button'
                                variant={'primary-outline'}
                                title={'Add Grant Agreement'}
                                icon={'add_circle_outline'}
                                onClick={() => {
                                  openModalGrant();
                                  setModelName('Grant Agreement');
                                  getValueByLabel('Grant Agreement Document');
                                }}
                                disabled={initialData?.data?.isGrantInUse}
                              />
                            )}
                          </div>
                        </div>

                        <div className='sm:col-span-2 '>
                          <div
                            className={`${
                              modalStatus === 'View' ? 'grid grid-cols-3 items-center gap-4' : ''
                            }`}>
                            <FormSelect
                              label='Grant Notice'
                              name='grantNoticeDocumentName'
                              viewName='grantNoticeDocumentName'
                              onChange={(e: string) => {
                                const removeDtoFile = documentDto.filter(
                                  (item: any) => item.grantDocumentType !== 2
                                );
                                setDocumentDto(removeDtoFile);
                                handleChange(e);
                              }}
                              errors={errors}
                              touched={touched}
                              placeholder='Select Grant Notice'
                              options={grantNoticeList}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled={modalStatus === 'View' || initialData?.data?.isGrantInUse}
                              containerClassName='formGroup col-span-2 mb-0'
                              // takeName={true}
                              // width='184px'
                              required
                            />
                          </div>
                          <div className='my-8'>
                            {filteredGrantNoticeDocument &&
                              filteredGrantNoticeDocument?.length > 0 && (
                                <DocumentContainer>
                                  {filteredGrantNoticeDocument?.map((details: any) => (
                                    <ImagePreview
                                      key={1}
                                      className='cursor-pointer'
                                      onClick={() =>
                                        /*  handleImageOpen(value.file.uniqueName) */ console.log(
                                          'val'
                                        )
                                      }>
                                      <div
                                        className='image-wrapper cursor-pointer'
                                        title={'Grant Agreement V1'}
                                        // className='cursor-pointer'
                                        onClick={() => handleGrantDocumentImageOpen(details?.id)}>
                                        <>
                                          <span className='material-symbols-outlined image-icon'>
                                            draft
                                          </span>
                                          <span className='image-filename'>{`${details?.grantDocumentName}`}</span>
                                        </>
                                      </div>

                                      <div>
                                        <div
                                          className='image-cross'
                                          onClick={() => {
                                            const removeFile = removeObjectsByName(
                                              filteredGrantNoticeDocument,
                                              details?.grantDocumentName
                                            );
                                            const removeDtoFile = removeObjectsByName(
                                              documentDto,
                                              details?.grantDocumentName
                                            );
                                            setFilteredGrantNoticeDocument(removeFile);
                                            handleDeleteGrantDocument(details?.id);
                                            setDocumentDto(removeDtoFile);
                                          }}>
                                          <span className='material-symbols-outlined image-delete'>
                                            delete
                                          </span>
                                        </div>
                                      </div>
                                    </ImagePreview>
                                  ))}
                                </DocumentContainer>
                              )}
                            {modalStatus !== 'View' && (
                              <Button
                                // as='Create'
                                type='button'
                                variant={'primary-outline'}
                                title={'Add Grant Notice'}
                                icon={'add_circle_outline'}
                                onClick={() => {
                                  openModalGrant();
                                  setModelName('Grant Notice');
                                  getValueByLabel('Grant Notice Document');
                                }}
                                disabled={initialData?.data?.isGrantInUse}
                              />
                            )}
                          </div>
                        </div>

                        {/* <div className='sm:col-span-2 mt-'>
                          <Label htmlFor={'grantDocument'} className={'pb-1'}>
                            {modalStatus === 'View' ? '' : 'Upload'} Grant Documents{' '}
                          </Label>
                          <ImageViewer
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            modalStatus={modalStatus}
                            values={values}
                            errors={errors}
                            touched={touched}
                            name='grantDocument'
                            listingName='documentNameList'
                            accept='.pdf, .jpg, .jpeg, .png, .docx, .doc'
                            loading={loading}
                            removedId={removedId}
                            handleImageOpen={handleImageOpen}
                            setRemovedId={setRemovedId}
                          />
                        </div> */}
                      </div>
                      <>
                        <Divider />
                        <Heading variant='h5' title='Terms & Condition' color={COLORS.Gray600} />
                        <div className='grid sm:grid-cols-12 gap-x-8 mt-5'>
                          <InputText
                            type='text'
                            label='Agreement Statement '
                            className='col-span-9'
                            name='agreementStatement'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            required
                          />
                          <div className='sm:col-span-3'>
                            <FormSelect
                              name={'agreementStatementStatusName'}
                              label='Status'
                              viewName='isActive'
                              containerClassName='formGroup'
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              placeholder={'Select'}
                              options={ActiveOptions}
                              touched={touched}
                              errors={errors}
                              values={values}
                              inputType={modalStatus}
                              disabled={modalStatus === 'View'}
                              // takeName={true}
                              required
                            />
                          </div>
                        </div>

                        <SwitchContainer>
                          <div className='flex gap-2'>
                            <label className='switch'>
                              <input
                                type='checkbox'
                                onChange={(e: any) => {
                                  setToggle(e.target.checked);
                                  setDocumentType(e.target.checked ? 4 : 3);
                                  const requiredNumber = e.target.checked ? 3 : 4;

                                  const removeDtoFile = documentDto.filter(
                                    (item: any) => item.grantDocumentType !== requiredNumber
                                  );

                                  setDocumentDto(removeDtoFile);
                                  setFieldValue(
                                    'termsAndConditionDocumentName',
                                    e.target.checked && ''
                                  );
                                  setFieldValue(
                                    'termsAndConditionTextName',
                                    e.target.checked === false && ''
                                  );

                                  // CompanyNotificationConfig(e.target.checked);
                                }}
                                checked={toggle}
                                disabled={modalStatus === 'View'}
                              />
                              <span className='slider round'></span>
                            </label>
                            <Heading title={'Enable Terms & Condition Text'} variant='body' />
                          </div>
                        </SwitchContainer>

                        <div className='sm:col-span-2 '>
                          {!toggle ? (
                            <div>
                              <FormSelect
                                label='Terms and Condition Document'
                                name='termsAndConditionDocumentName'
                                viewName='termsAndConditionDocumentName'
                                onChange={(e: string) => {
                                  const removeDtoFile = documentDto.filter(
                                    (item: any) => item.grantDocumentType !== 3
                                  );
                                  setDocumentDto(removeDtoFile);
                                  handleChange(e);
                                }}
                                errors={errors}
                                touched={touched}
                                placeholder='Select Terms and Condition Document'
                                options={termsAndCondition}
                                values={values}
                                setFieldValue={setFieldValue}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                containerClassName='formGroup col-span-2 mb-0'
                                // width='184px'
                                // takeName={true}
                                required
                              />
                              <div className='mt-8'>
                                {filteredTermsAndConditionDocument &&
                                  filteredTermsAndConditionDocument?.length > 0 && (
                                    <DocumentContainer>
                                      {filteredTermsAndConditionDocument?.map((details: any) => (
                                        <ImagePreview
                                          key={1}
                                          className='cursor-pointer'
                                          onClick={() =>
                                            /*  handleImageOpen(value.file.uniqueName) */ console.log(
                                              'val'
                                            )
                                          }>
                                          <div
                                            className='image-wrapper cursor-pointer'
                                            title={'Grant Agreement V1'}
                                            /*   // className='cursor-pointer'
                                          onClick={() =>
                                            handleGrantAgreementImageOpen(details?.id)
                                          } */
                                          >
                                            <>
                                              <span className='material-symbols-outlined image-icon'>
                                                draft
                                              </span>
                                              <span className='image-filename'>{`${details?.grantDocumentName}`}</span>
                                            </>
                                          </div>

                                          <div>
                                            <div
                                              className='image-cross'
                                              onClick={() => {
                                                const removeFile = removeObjectsByName(
                                                  filteredGrantAgreementDocument,
                                                  details?.grantDocumentName
                                                );
                                                const removeDtoFile = removeObjectsByName(
                                                  documentDto,
                                                  details?.grantDocumentName
                                                );
                                                setFilteredGrantNoticeDocument(removeFile);
                                                handleDeleteGrantDocument(details?.id);
                                                setDocumentDto(removeDtoFile);
                                              }}>
                                              <span className='material-symbols-outlined image-delete'>
                                                delete
                                              </span>
                                            </div>
                                          </div>
                                        </ImagePreview>
                                      ))}
                                    </DocumentContainer>
                                  )}
                                {modalStatus !== 'View' && (
                                  <Button
                                    // as='Create'
                                    type='button'
                                    variant={'primary-outline'}
                                    title={'Add Terms and Conditions Document'}
                                    icon={'add_circle_outline'}
                                    onClick={() => {
                                      openModalGrant();
                                      setModelName('Term and Condition');
                                      setDocumentType(3);
                                      getValueByLabel('Terms And Condition Document');
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <FormSelect
                                label='Terms and Condition Text'
                                name='termsAndConditionTextName'
                                viewName='termsAndConditionTextName'
                                onChange={(e: string) => {
                                  const removeDtoFile = documentDto.filter(
                                    (item: any) => item.grantDocumentType !== 4
                                  );
                                  setDocumentDto(removeDtoFile);
                                  handleChange(e);
                                }}
                                errors={errors}
                                touched={touched}
                                placeholder='Select Terms and Condition Text'
                                options={termsAndConditionText}
                                values={values}
                                setFieldValue={setFieldValue}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                containerClassName='formGroup col-span-2 mb-0'
                                // width='184px'
                                // takeName={true}
                                required
                              />

                              <div className='mt-8'>
                                {filteredTermsAndConditionText &&
                                  filteredTermsAndConditionText?.length > 0 && (
                                    <DocumentContainer>
                                      {filteredTermsAndConditionText?.map((details: any) => (
                                        <ImagePreview
                                          key={1}
                                          className='cursor-pointer'
                                          onClick={() =>
                                            /*  handleImageOpen(value.file.uniqueName) */ console.log(
                                              'val'
                                            )
                                          }>
                                          <div
                                            className='image-wrapper cursor-pointer'
                                            title={'Grant Agreement V1'}
                                            // className='cursor-pointer'
                                            /* onClick={() => handleGrantAgreementImageOpen(details?.id)} */
                                          >
                                            <>
                                              <span className='material-symbols-outlined image-icon'>
                                                draft
                                              </span>
                                              <span className='image-filename'>{`${details?.grantDocumentName}`}</span>
                                            </>
                                          </div>

                                          <div>
                                            <div
                                              className='image-cross'
                                              onClick={() => {
                                                const removeFile = removeObjectsByName(
                                                  filteredGrantAgreementDocument,
                                                  details?.grantDocumentName
                                                );
                                                const removeDtoFile = removeObjectsByName(
                                                  documentDto,
                                                  details?.grantDocumentName
                                                );
                                                setFilteredGrantNoticeDocument(removeFile);
                                                handleDeleteGrantDocument(details?.id);
                                                setDocumentDto(removeDtoFile);
                                              }}>
                                              <span className='material-symbols-outlined image-delete'>
                                                delete
                                              </span>
                                            </div>
                                          </div>
                                        </ImagePreview>
                                      ))}
                                    </DocumentContainer>
                                  )}
                                {modalStatus !== 'View' && (
                                  <Button
                                    // as='Create'
                                    type='button'
                                    variant={'primary-outline'}
                                    title={'Add Terms and Conditions Text'}
                                    icon={'add_circle_outline'}
                                    onClick={() => {
                                      openModalGrant();
                                      setModelName('Term and Condition Text');
                                      setDocumentType(3);
                                      getValueByLabel('Terms And Condition Text');
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        {/* <div className='grid grid-cols-12'>
                          {modalStatus !== 'View' ? (
                            <div className='col-span-12'>
                              <>
                                <RichTextEditor
                                  name='termsAndConditionsDocumentContent'
                                  setFieldValue={setFieldValue}
                                  value={
                                    (values?.termsAndConditionsDocumentContent as boolean)
                                      ? values?.termsAndConditionsDocumentContent
                                      : ''
                                  }
                                  error={errors?.termsAndConditionsDocumentContent}
                                  touched={touched?.termsAndConditionsDocument}
                                />
                              </>
                              <ErrorMessage
                                name='termsAndConditionsDocumentContent'
                                component='div'
                                className='error-message'
                              />
                            </div>
                          ) : (
                            <div className='col-span-12'>
                              <Label>Description</Label>

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: values?.termsAndConditionsDocumentContent,
                                }}
                                className={`border p-2 text-2xl rounded-lg bg-[#F3F3F3] descriptionOutput`}
                              />
                            </div>
                          )}
                        </div>

                        <div className='gap-3 justify-between mt-8'>
                          <div>
                            <Label htmlFor={'document'} className={'pb-1'}>
                              {modalStatus === 'View' ? '' : 'Upload Terms & Condition'} Document
                            </Label>
                            {modalStatus === 'View' &&
                            (values.termsAndConditionsDocumentName[0]?.file.name === undefined ||
                              values.termsAndConditionsDocumentName[0]?.file.name === null) ? (
                              <UploadWrapper className='flex justify-center'>
                                <Heading
                                  variant='h6'
                                  title='No document to show'
                                  color={COLORS.SpanishGray}
                                  className='flex justify-center'
                                />
                              </UploadWrapper>
                            ) : (
                              <ImageViewerSingle
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                modalStatus={modalStatus}
                                values={values}
                                errors={errors}
                                errorValue={errors?.termsAndConditionsDocumentContent}
                                touchedValue={touched?.termsAndConditionsDocument}
                                touched={touched}
                                name='termsAndConditionsDocument'
                                listingName='termsAndConditionsDocumentName'
                                loading={loading}
                                removedId={removedId}
                                handleImageOpen={handleImageOpenTermAndConditions}
                                setRemovedId={setRemovedId}
                                uploadLimit={1}
                                isSingleFile={true}
                                setFieldError={setFieldError}
                              />
                            )}
                          </div>
                        </div> */}
                      </>
                    </ModalBodyComponent>

                    <ModalFooterComponent
                      modalStatus={modalStatus}
                      loading={grantLoader}
                      urlName='plan-management'
                      onCloseModal={() => {
                        closeModal();
                      }}
                      onEditModal={() => {
                        setModalStatus('Edit');
                      }}
                      isEditShow={true}
                      valueChanged={_.isEqual(values, initialData.data)}
                      confirmValues={false}
                    />
                  </Form>
                </>
              }
            />
          );
        }}
      </Formik>
      <AnimationWrapper>
        {isModalOpenVesting && (
          <NewVestingModal
            isModalOpen={isModalOpenVesting}
            closeModal={closeModalVesting}
            modalStatus={modalStatus}
            modalLoading={false}
            initialData={initialData}
            vestingTypeOptions={vestingTypeOptions}
            setModalStatus={setModalStatus}
          />
        )}
      </AnimationWrapper>
      <AnimationWrapper>
        {isModalOpenPerformanceRule && (
          <PerformanceRulesModal
            isModalOpen={isModalOpenPerformanceRule}
            closeModal={closeModalPerformanceRule}
            modalStatus={modalStatus}
            // modalLoading={modalLoading}
            initialData={initialPerformanceRuleValues}
            // vestingTypeOptions={vestingTypeOptions}
            setModalStatus={setModalStatus}
            id={initialData?.data?.performanceRuleId}
            // rowData={rowData}
          />
        )}

        <AnimationWrapper>
          {isModalGrantOpen && (
            <GrantDocumentModel
              isModalOpen={isModalGrantOpen}
              closeModal={closeModalGrant}
              modalStatus={modalStatus}
              initialData={planInitialData}
              id={initialData?.data?.id}
              modelName={modelName}
              documentDto={documentDto}
              setDocumentDto={setDocumentDto}
              documentType={documentType}
              setDocumentType={setDocumentType}
            />
          )}
        </AnimationWrapper>
      </AnimationWrapper>
    </>
  );
};

export default GrantManagementModal;
