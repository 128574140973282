import { useEffect, useState } from 'react';
import Spinners from '../../../components/spinner';
import Heading from '../../../components/typography';
import {
  useGetApprovalProcessList,
  useGetAllCustomerApprovalProcess,
  useGetCustomerUserList,
  useSubmitApprovalConfig,
} from '../../../hooks';
import useWealthlaneCustomerApi from '../../../hooks/useWealthlaneCustomerApi';
import { type UserType } from '../../../types/common';
import { FormDivider } from '../system-roles/roles.styled';
import { SettingSystemUserHeader } from '../system-users/systemUserStyled';
import {
  ConfigContainer,
  ApproverSelectGroup,
  ApproverSelectContainer,
  ApprovalConfigLoader,
} from './approvalConfig.styled';
import Lozenge from '../../../components/lozenge/index';
import { Label } from '../../../components/input-text/input.styled';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import CustomDropdown from '../../../components/custom-dropdown';
import { areArraysEqual } from '../../../utils/global';

interface ApprovalConfigType {
  approvalProcessId: string;
  name: string;
  permission: string;
  customerUserDtos: any;
}

const ApprovalConfig = (): JSX.Element => {
  const [initialValues, setInitialValues] = useState<ApprovalConfigType[]>();
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<string>('');
  const [isCurrentUser, setCurrentUser] = useState<boolean>(false);

  const { handleRequest } = useWealthlaneCustomerApi();
  const { data: customerUserOptions, isLoading: userListLoader } =
    useGetCustomerUserList(handleRequest);
  const { data: customerApprovalProcessList, isLoading: listLoader } =
    useGetAllCustomerApprovalProcess(handleRequest);
  const { data, isLoading } = useGetApprovalProcessList(handleRequest);
  const userAuthList = useSelector(selectAuthRoutesList);

  useEffect(() => {
    if (data != null && customerApprovalProcessList != null) {
      const newData = data.map((item) => {
        const processList =
          customerApprovalProcessList.find((processItem) => processItem.name === item.name)
            ?.customerUserDtos ?? [];

        const processListOptions = processList.map((item) => item.customerUserId) ?? [];
        return {
          name: item.name ?? '',
          permission: item.permission ?? '',
          approvalProcessId: item.id ?? '',
          customerUserDtos: processListOptions,
        };
      });

      setInitialValues(newData);
    }
  }, [data, customerApprovalProcessList]);

  useEffect(() => {
    if (dataChanged) {
      handleSubmit(selectedRow);
    }
  }, [initialValues]);

  const { mutate, isLoading: isSubmitting } = useSubmitApprovalConfig(handleRequest, isCurrentUser);
  const handleSubmit = (value: string): void => {
    const payload = initialValues?.map((item) => {
      return {
        ...item,
        customerUserDtos: item.customerUserDtos.map((userIds: string) => {
          return {
            customerUserId: userIds,
            fullName: customerUserOptions?.find((option) => option.value === userIds)?.label,
          };
        }),
      };
    });

    const currentApprovalPayload = payload?.filter((item) => item.name === value)[0];
    const filterPayload = payload?.map((item: any) => {
      return {
        ...item,
        customerUserDtos: item.customerUserDtos.map((customDtos: any) => customDtos.customerUserId),
      };
    });

    const oldApprovalValue = customerApprovalProcessList?.filter((item) => item.name === value)[0]
      ?.customerUserDtos;
    const currentApprovalValue = filterPayload?.filter((item) => item.name === value)[0]
      ?.customerUserDtos;

    const filterOldApprovalValue = oldApprovalValue?.map((item) => item.customerUserId || '') ?? [];
    const isApprovalValueEqual = !areArraysEqual(filterOldApprovalValue, currentApprovalValue);

    setDataChanged(false);
    isApprovalValueEqual && mutate(currentApprovalPayload);
  };

  const handleUser = (userData: UserType, userId: string | undefined): void => {
    const getUser = initialValues?.map((item) => {
      if (item.approvalProcessId === userId) {
        const userInfo = item.customerUserDtos;
        userInfo.includes(userData.value) === false
          ? userInfo.push(userData.value)
          : userInfo.splice(userInfo.indexOf(userData.value), 1);
        return {
          ...item,
          customerUserDtos: userInfo,
        };
      } else {
        return item;
      }
    });
    setInitialValues(getUser);
  };

  useEffect(() => {
    if (isSubmitting) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'unset';
    }
  }, [isSubmitting]);

  return (
    <>
      {isSubmitting && (
        <ApprovalConfigLoader>
          <Spinners size='lg' />
        </ApprovalConfigLoader>
      )}
      <SettingSystemUserHeader>
        <Heading variant='h3' title='System Approvers' />
        <Heading
          variant='body-s'
          title={`List of ${String(
            userAuthList?.currentTenant?.name ?? ''
          )} employees authorized to approve certain activities on and user access of the Wealthlane system. `}
        />
      </SettingSystemUserHeader>
      <ConfigContainer className='relative'>
        {isLoading && <Spinners />}
        {!isLoading && (
          <div className='grid lg:grid-cols-12 gap-5'>
            {data?.map((item, index) => (
              <>
                <div className='lg:col-span-4 left-space'>
                  <CustomDropdown
                    title={item.name ?? ''}
                    placeholder='Select Users'
                    data={customerUserOptions /* ?.filter((item) => !item?.isDefaultApprover) */}
                    loading={userListLoader}
                    handleUser={(userInfo) => {
                      const isUser = userInfo.userId === userAuthList.currentUser.id;
                      if (isUser) {
                        setCurrentUser(isUser);
                      }
                      handleUser(userInfo, item.id);
                    }}
                    checkedList={initialValues?.[index].customerUserDtos ?? []}
                    handleSubmit={(value: string) => handleSubmit(value)}
                    disabled={listLoader /* || item?.name?.includes('System User') */}
                    cssStyles={{
                      contentHeight: '290px',
                      width: '700px',
                      height: '380px',
                      column: 2,
                      disabledContainer: !!(
                        listLoader /* || item?.name?.includes('System User') */ ?? false
                      ),
                    }}
                  />
                </div>
                <div className='lg:col-span-8 right-space'>
                  <ApproverSelectContainer>
                    <Label className='bold'>User</Label>
                    <ApproverSelectGroup
                    /*  className={`${
                        item?.name?.includes('System User') === true ? 'field-disabled' : ''
                      }`} */
                    >
                      {customerUserOptions
                        ?.filter((option) =>
                          initialValues?.[index].customerUserDtos.includes(option.value)
                        )
                        ?.map((mapped) => (
                          <div>
                            <Lozenge
                              variant='default'
                              title={mapped.label}
                              disabled={listLoader /*  || item?.name?.includes('System User') */}
                              handleIconClick={() => {
                                const filterData = initialValues?.map((data) => {
                                  if (data.approvalProcessId === item.id) {
                                    return {
                                      ...data,
                                      customerUserDtos: data.customerUserDtos.filter(
                                        (user: string) => user !== mapped.value
                                      ),
                                    };
                                  } else {
                                    return data;
                                  }
                                });

                                const isUser = mapped.userId === userAuthList.currentUser.id;
                                setCurrentUser(isUser);
                                setSelectedRow(item.name || '');
                                setInitialValues(filterData as any);
                                setDataChanged(true);
                              }}
                            />
                          </div>
                        ))}
                    </ApproverSelectGroup>
                  </ApproverSelectContainer>
                </div>
                {!(data.length - 1 === index) && (
                  <div className='lg:col-span-12'>
                    <FormDivider />
                  </div>
                )}
              </>
            ))}
          </div>
        )}
      </ConfigContainer>
    </>
  );
};

export default ApprovalConfig;
