import { type UseQueryResult, useQuery } from 'react-query';
import {
  TaskManagerConsoleService,
  Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleByIdResultDto,
} from '../../services/wealthlane-task-manager-services';

const useGetTmcViewById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_TaskManagerService_Dtos_TaskManagerConsole_TaskManagerConsoleByIdResultDto | undefined,
  unknown
> => {
  const getTmcViewById = useQuery(['getTmcViewById', id], async () => {
    if (id) {
      return await handleRequest(
        TaskManagerConsoleService.getApiTaskmanagerApiAppTaskManagerConsoleTaskManagerConsoleById(
          id
        )
      );
    }
  });
  return getTmcViewById;
};

export default useGetTmcViewById;
