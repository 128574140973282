import { type UseQueryResult, useQuery } from 'react-query';
import { ParticipantBroadcastMessageService } from '../../services/wealthlane-customer-services';

const useGetParticipantBroadcastMessageTemplates = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const participantBroadcastMessageTemplates = useQuery(
    ['getParticipantBroadcastMessageTemplates'],
    async () => {
      return await handleRequest(
        ParticipantBroadcastMessageService.getApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessageTemplates()
      );
    },
    {
      select: (data) => {
        const options = data?.map((item) => {
          return {
            label: item.messageTemplate ?? '',
            value: item.messageTemplateId ?? '',
          };
        });
        const customOptions = options
          ?.concat({ label: `⊕ Custom Message`, value: '11' })
          .sort(function (a, b) {
            return a.label.localeCompare(b.label);
          });
        return customOptions;
      },
    }
  );

  return participantBroadcastMessageTemplates;
};

export default useGetParticipantBroadcastMessageTemplates;
