import { type UseQueryResult, useQuery } from 'react-query';
import { PerformanceRuleService } from '../../services/wealthlane-award-services';

const useGetAvailablePerformanceRules = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  grantId?: string
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const availablePerformanceRules = useQuery(
    ['getAvailablePerformanceRules'],
    async () => {
      if (grantId) {
        return await handleRequest(
          PerformanceRuleService.getApiAwardApiAppPerformanceRuleAvailablePerformanceRulesForGrant(
            grantId
          )
        );
      } else {
        return await handleRequest(
          PerformanceRuleService.getApiAwardApiAppPerformanceRuleAvailablePerformanceRules()
        );
      }
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return availablePerformanceRules;
};

export default useGetAvailablePerformanceRules;
