import { isEmpty } from 'lodash';
import { type UseQueryResult, useQuery } from 'react-query';
import {
  PsuAwardApprovalService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetPsuAwardsParticipantApprovalList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    approvalStatusId,
    optionAcceptance: psuAcceptance,
    fromDate,
    toDate,
    createdFromDate,
    createdToDate,
    filter,
    sorting,
    skipCount,
    maxResultCount,
    employeeStatusId,
  } = tableInfo;
  const psuStatus = 0;

  const PsuAwardsParticipantApprovalData = useQuery(
    [
      'getPsuAwardsParticipantApprovalList',
      approvalStatusId,
      fromDate,
      toDate,
      employeeStatusId,
      psuStatus,
      psuAcceptance,
      createdFromDate,
      createdToDate,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        PsuAwardApprovalService.getApiAwardApiAppPSUAwardApprovalPSUAwardList(
          !isEmpty(approvalStatusId) ? approvalStatusId : [0],
          fromDate,
          toDate,
          employeeStatusId ?? [0],
          psuStatus,
          psuAcceptance,
          createdFromDate,
          createdToDate,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return PsuAwardsParticipantApprovalData;
};

export default useGetPsuAwardsParticipantApprovalList;
