import styled from 'styled-components';
import { COLORS } from '../../../../../constants/style/color';
import { rem } from '../../../../../utils/style';

export const ElectionWindowWrapper = styled.div`
  .election-head {
    padding-bottom: ${rem(8)};
    color: ${COLORS.Silver};
  }
  /* padding-bottom: ${rem(32)}; */
`;

export const ElectionCardBlock = styled.div`
  background: ${COLORS.White};
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};
  padding: ${rem(18)};
  margin-bottom: ${rem(62)};

  .electionInput {
    padding: 0 ${rem(12)};

    .error-message {
      font-weight: 400;
      font-size: ${rem(12)};
      line-height: ${rem(14)};
      color: ${COLORS.Gray};
      margin-top: ${rem(6)};
      transition: all 0.2s linear;
    }
  }
`;
