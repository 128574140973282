import { ErrorMessage, Form, Formik } from 'formik';
import ModalComponent from '../../../components/modal/modal';
import * as Yup from 'yup';
import CustomerRadio from '../../../components/radio-button/customRadio';
import { ErrorMessageWrapper } from '../../../constants/style/common.styled';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import { BankRadioWrapper } from './bank.styled';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import _ from 'lodash';

interface AddBankAccountType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalLoading: boolean;
  Loading: boolean;
  initialValue: {
    bankAccount: string;
  };
  handleSubmit: (values: { bankAccount: string }) => void;
}

const AddBankAccountModal = ({
  isModalOpen,
  closeModal,
  modalLoading,
  Loading,
  initialValue,
  handleSubmit,
}: AddBankAccountType): JSX.Element => {
  const options = [
    {
      label: 'Tax Only',
      value: 'makeTaxAccount',
    },
    {
      label: 'Exercise Only',
      value: 'makeExerciseAccount',
    },
    {
      label: 'Both',
      value: 'both',
    },
  ];
  return (
    <Formik
      enableReinitialize
      initialValues={initialValue}
      validationSchema={Yup.object().shape({
        bankAccount: Yup.string().required('Required field').nullable(),
      })}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({ errors, values, touched, handleChange, setFieldValue }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            loading={modalLoading}
            valueEdited={_.isEqual(values, initialValue)}
            size='sm'
            closeModal={closeModal}
            title='Change Account Purpose'
            placement='center'
            children={
              <Form>
                <ModalBodyComponent>
                  <BankRadioWrapper>
                    {options.map((item) => (
                      <div
                        className={`radio-wrap ${
                          Boolean(errors) &&
                          Boolean(touched) &&
                          errors.bankAccount != null &&
                          (touched.bankAccount ?? false)
                            ? 'is-invalid'
                            : ''
                        } ${values.bankAccount === item.value ? 'valid' : ''}`}
                        onClick={() => {
                          setFieldValue('bankAccount', item.value);
                        }}>
                        <CustomerRadio
                          name='bankAccount'
                          label={item.label}
                          onChange={handleChange}
                          id={item.label}
                          value={item.value}
                          errors={errors}
                          checked={values.bankAccount === item.value}
                        />
                      </div>
                    ))}
                    <ErrorMessageWrapper className='mt-2'>
                      <ErrorMessage
                        name='bankAccount'
                        component='div'
                        className='field-error-message'
                      />
                    </ErrorMessageWrapper>
                  </BankRadioWrapper>
                </ModalBodyComponent>
                <ModalFooterComponent
                  modalStatus='Add'
                  onCloseModal={() => {
                    closeModal();
                  }}
                  isEditShow={false}
                  loading={Loading}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default AddBankAccountModal;
