import { FieldArray, type FormikErrors, type FormikTouched, type FormikValues } from 'formik';
import React from 'react';
import Button from '../../../../components/button';
import FormSelect from '../../../../components/dropdown/formSelect';
import Heading from '../../../../components/typography';
import { COLORS } from '../../../../constants/style/color';
import { type OptionType, type UserType } from '../../../../types/common';
import CustomDropdown from '../../../../components/custom-dropdown';

interface GroupProps {
  errors: FormikErrors<any> | any;
  touched: FormikTouched<any> | any;
  values: FormikValues;
  modalStatus: string;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  departmentOptions?: OptionType[];
  employeeTypeOptions?: OptionType[];
  jobTitleOptions?: UserType[];
  setFieldValue: any;
  setFieldTouched: any;
  handleChange: any;
}

const GroupOptions = ({
  errors,
  touched,
  values,
  modalStatus,
  departmentOptions,
  employeeTypeOptions,
  jobTitleOptions,
  handleRequest,
  handleChange,
  setFieldValue,
  setFieldTouched,
}: GroupProps): JSX.Element => {
  const handleUser = (userData: UserType, index: number): void => {
    const userInfo = values?.blackoutGroups[index]?.blackOutJobTitles;
    if (!(userInfo.includes(userData.value) === true)) {
      userInfo.push(userData.value);
      setFieldValue(`blackoutGroups.${index}.groupData`, true);
    } else {
      userInfo.splice(userInfo.indexOf(userData.value), 1);
      setFieldValue(`blackoutGroups.${index}.groupData`, false);
    }

    setFieldValue(`blackoutGroups.${index}.blackOutJobTitles`, [...userInfo]);
    setTimeout(() => setFieldTouched(`blackoutGroups.${index}.blackOutJobTitles`, true));
  };
  return (
    <div className='col-span-12'>
      <Heading variant='h5' title='Add Participant Groups' className='group-title' />
      <Heading
        variant='body'
        title='Select one or more below'
        className='group-sub-title'
        color={COLORS.Gray}
      />
      <div className='grid grid-cols-10 gap-x-8'>
        <FieldArray
          name='blackoutGroups'
          render={(arrayHelpers) => {
            return (
              <>
                {Boolean(values?.blackoutGroups) &&
                  values.blackoutGroups.length > 0 &&
                  values.blackoutGroups.map((friend: any, index: number) => (
                    <>
                      <FormSelect
                        containerClassName='col-span-3'
                        label='Department'
                        placeholder='Select'
                        values={values}
                        name={`blackoutGroups.${index}.customerDepartmentId`}
                        errorValue={errors?.blackoutGroups?.[index]?.customerDepartmentId}
                        touchedValue={touched?.blackoutGroups?.[index]?.customerDepartmentId}
                        arrValue={values?.blackoutGroups[index]?.customerDepartmentId}
                        setFieldValue={setFieldValue}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(`blackoutGroups.${index}.groupData`, true);
                          setTimeout(() =>
                            setFieldTouched(`blackoutGroups.${index}.customerDepartmentId`, true)
                          );
                        }}
                        errors={errors}
                        touched={touched}
                        allOptions={departmentOptions}
                        options={departmentOptions?.filter((item) => {
                          const filteredData = values.blackoutGroups.map(
                            (option: Record<string, string | string[]>) =>
                              option.customerDepartmentId
                          );
                          return !(filteredData.includes(item.value) === true);
                        })}
                        disabled={modalStatus === 'View' && true}
                        required
                      />
                      <FormSelect
                        containerClassName='col-span-3'
                        label='Employee Type '
                        placeholder='Select'
                        name={`blackoutGroups.${index}.employeeTypeId`}
                        arrValue={values?.blackoutGroups[index]?.employeeTypeId}
                        errorValue={errors?.blackoutGroups?.[index]?.employeeTypeId}
                        touchedValue={touched?.blackoutGroups?.[index]?.employeeTypeId}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(`blackoutGroups.${index}.groupData`, true);
                          setTimeout(() =>
                            setFieldTouched(`blackoutGroups.${index}.employeeTypeId`, true)
                          );
                        }}
                        errors={errors}
                        touched={touched}
                        allOptions={employeeTypeOptions}
                        options={employeeTypeOptions?.filter((item) => {
                          const filteredData = values.blackoutGroups.map(
                            (option: Record<string, string | string[]>) => option.employeeTypeId
                          );
                          return !(filteredData.includes(item.value) === true);
                        })}
                        values={values}
                        setFieldValue={setFieldValue}
                        disabled={modalStatus === 'View' && true}
                        required
                      />
                      <div className='col-span-3'>
                        <CustomDropdown
                          title={'Job Title'}
                          data={jobTitleOptions}
                          errorMessage={errors?.blackoutGroups?.[index]?.blackOutJobTitles}
                          touchedValue={touched?.blackoutGroups?.[index]?.blackOutJobTitles}
                          handleUser={(e) => {
                            handleUser(e, index);
                          }}
                          checkedList={values?.blackoutGroups[index]?.blackOutJobTitles ?? []}
                          cssStyles={{
                            width: '225px',
                            height:
                              jobTitleOptions != null && jobTitleOptions?.length > 5
                                ? '230px'
                                : 'auto',
                            contentHeight:
                              jobTitleOptions != null && jobTitleOptions?.length > 5
                                ? '200px'
                                : 'auto',
                            padding: '0',
                            verticalGap: '8',
                            column: 1,
                          }}
                          toggleItems={{
                            showSearch: false,
                            showTotal: false,
                            displaySelected: true,
                          }}
                          disabled={modalStatus === 'View' && true}
                        />
                      </div>
                      {modalStatus === 'View'
                        ? null
                        : values.blackoutGroups.length > 1 && (
                            <div
                              className='flex items-center ap-2'
                              role='button'
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}>
                              <span className='material-symbols-outlined text-red-600'>delete</span>
                              <Heading variant='body-s' title='Delete' color={`${COLORS.Gray}`} />
                            </div>
                          )}
                    </>
                  ))}
                {modalStatus !== 'View' && (
                  <div className='col-span-12'>
                    <Button
                      title='Add another criteria'
                      type='button'
                      variant='primary-dark'
                      icon='group_add'
                      btnClassName='w-full'
                      onClick={() => {
                        arrayHelpers.push({
                          id: null,
                          customerDepartmentId: null,
                          employeeTypeId: null,
                          blackOutJobTitles: [],
                          groupData: false,
                        });
                      }}
                    />
                  </div>
                )}
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

export default GroupOptions;
