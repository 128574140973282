/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CreateUpdateCustomerApprovalProcessDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CreateUpdateCustomerApprovalProcessDto';
import type { Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CustomerApprovalProcessDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CustomerApprovalProcessDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerApprovalProcessService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CustomerApprovalProcessDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerApprovalProcess(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CustomerApprovalProcessDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-approval-process',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CreateUpdateCustomerApprovalProcessDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppCustomerApprovalProcessCustomerApprovalProcess(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CreateUpdateCustomerApprovalProcessDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CreateUpdateCustomerApprovalProcessDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/customer-approval-process/customer-approval-process',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
