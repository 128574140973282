import { type UseQueryResult, useQuery } from 'react-query';
import { CityService } from '../../services/wealthlane-tax-services';

const useGetTaxCityOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string | null
): UseQueryResult<any> => {
  const taxCityOptions = useQuery(
    ['getTaxCities', id],
    async () => {
      if (id !== undefined && id !== null) {
        return await handleRequest(CityService.getApiTaxApiAppCityCityByState(id));
      } else {
        return [];
      }

    },
    {
      select: (data) => {
        return data?.filter((city)=> city?.id !== null)?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return taxCityOptions;
};

export default useGetTaxCityOptions;
