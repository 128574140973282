import { COLORS } from '../../constants/style/color';
import { getShadow, rem } from '../../utils/style';
import styled, { css } from 'styled-components';
import { Display } from '../../constants/style/common.styled';

interface ViewType {
  variant?: 'default' | 'primary' | 'success' | 'error' | 'info' | 'dark';
  size?: 'sm' | 'md' | 'lg' | 'Custom' | 'fixed-height';
}

export const DocViewWrapper = styled(Display)<ViewType>`
  border-radius: ${rem(4)};
  border-width: ${rem(1)};
  border-style: solid;
  width: 100%;
  display: flex;
  gap: ${rem(8)};
  flex-direction: column;
  column-gap: ${rem(9)};

  .icon-size {
    font-size: ${rem(16)};
  }

  span {
    font-weight: 400;
  }

  ${(props) => {
    if (props.variant === 'default') {
      return css`
        background-color: ${COLORS.OffWhite};
        border-color: ${COLORS.OffWhite};
        @include ${getShadow({ variant: 'tag-white' })};
      `;
    }

    if (props.variant === 'primary') {
      return css`
        background-color: ${COLORS.LightPurple};
        border-color: ${COLORS.LightPurple};

        /* span { */
        color: ${COLORS.Purple};
        /* } */
      `;
    }

    if (props.variant === 'success') {
      return css`
        border-color: ${COLORS.LightGreen};
        background-color: ${COLORS.LightGreen};

        span {
          color: ${COLORS.Green};
        }
      `;
    }

    if (props.variant === 'error') {
      return css`
        border-color: ${COLORS.LightRed};
        background-color: ${COLORS.LightRed};

        span {
          color: ${COLORS.Red};
        }
      `;
    }

    if (props.variant === 'info') {
      return css`
        border-color: ${COLORS.Magnolia};
        background-color: ${COLORS.Magnolia};
        transition: all 300ms ease-in-out;
        span {
          color: ${COLORS.Iris};
        }
        &:hover {
          border-color: ${COLORS.Iris};
        }
      `;
    }
    if (props.variant === 'dark') {
      return css`
        border-color: ${COLORS.OffWhite};
        background-color: ${COLORS.OffWhite};
        transition: all 300ms ease-in-out;
      `;
    }
  }};

  ${(props) => {
    if (props.size === 'sm') {
      return css`
        width: fit-content;
        padding: ${rem(8)} ${rem(15)};
      `;
    }
    if (props.size === 'md') {
      return css`
        padding: ${rem(12)};
      `;
    }
    if (props.size === 'lg') {
      return css`
        padding: ${rem(15)} ${rem(18)};
      `;
    }
    if (props.size === 'Custom') {
      return css`
        padding: ${rem(16)} ${rem(24)};
      `;
    }
    if (props.size === 'fixed-height') {
      return css`
        height:  100%;

        @media(min-width: 512px) {
           height:  ${rem(200)}};
        }
      `;
    }
  }};
`;
