import { type UseMutationResult, useMutation } from 'react-query';
import {
  UserDefinedFieldsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsGetDto,
} from '../../services/wealthlane-customer-services';

const useGetUdfById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  openModal: () => void,
  setInitialUdfState: any
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsGetDto | undefined,
  unknown,
  string,
  unknown
> => {
  return useMutation(
    async (id: string) => {
      return await handleRequest(
        UserDefinedFieldsService.getApiCustomerApiAppUserDefinedFieldsUserDefinedFieldById(id)
      );
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          const resp = {
            ...response,
            isMandatory: response.isMandatory === true ? 'Yes' : 'No',
            moduleId: response.moduleId.map((item: Record<string, number | string>) => item.id)[0],
          };
          setInitialUdfState(resp);
          openModal();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetUdfById;
