import Tag from '../../components/lozenge/tags';
import { ActionButton } from '../../components/vertical-dot/popper';

export const TermAndConditionHeader = (actions: any): any[] => {
  const getActions = (rowData: Record<string, string>): any => {
    let newActions = [];
    if (rowData.status === 'Pending') {
      newActions = actions.filter((item: any) => item.action !== 'View Remarks');
    } else if (rowData.status === 'Approved') {
      newActions = actions.filter((item: any) => item.action !== 'View Remarks');
    } else if (rowData.status === 'Rejected') {
      newActions = actions.filter((item: any) => ['View Remarks', 'View'].includes(item.action));
    } else {
      newActions = actions;
    }

    if (rowData.customerRole === 'Admin' || rowData.customerTitle === '') {
      newActions = actions.filter((item: any) => ['View'].includes(item.action));
    }
    return newActions;
  };

  return [
    {
      Header: 'Name',
      accessor: 'name',
      width: 300,
      sortable: false,
    },
    {
      Header: 'Page Name',
      accessor: 'page',
      width: 300,
      sortable: false,
    },
    {
      Header: 'Status',
      accessor: 'statusId',
      width: 200,
      sortable: false,
      Cell: ({ row }: any) => (
        <Tag
          variant={row.original.isActive === true ? 'success' : 'error'}
          title={row.original.isActive === true ? 'Active' : 'Inactive'}
        />
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={getActions(row.original)}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
