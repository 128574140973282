import { type UseQueryResult, useQuery } from 'react-query';
import { RsuAwardService } from '../../services/wealthlane-award-services';

const useGetRsuAwardNumber = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string,
  isApprover: boolean | undefined
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const rsuAwardNumberOptions = useQuery(
    ['getRsuAwardNumberOptions', id, isApprover],
    async () => {
      return !(isApprover ?? false)
        ? await handleRequest(RsuAwardService.getApiAwardApiAppRSUAwardRSUAwardNumbers(id))
        : [];
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.awardNumber ?? '',
            value: item.rsuAwardId ?? '',
          };
        });
      },
    }
  );

  return rsuAwardNumberOptions;
};

export default useGetRsuAwardNumber;
