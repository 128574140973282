import { type ReactNode } from 'react';
import { DocViewWrapper } from './viewUploadedDoc.styled';

interface TagType {
  variant?: 'default' | 'primary' | 'success' | 'error' | 'info' | 'dark';
  size?: 'sm' | 'md' | 'lg' | 'Custom' | 'fixed-height';
  icon?: string;
  children: ReactNode;
  className?: string;
  handleOnClick?: () => void;
}

const ViewUploadedDoc = ({
  variant,
  className,
  size,
  icon,
  children,
  handleOnClick,
}: TagType): JSX.Element => {
  return (
    <DocViewWrapper
      variant={variant}
      className={className ?? 'default'}
      size={size ?? 'md'}
      onClick={handleOnClick}>
      {icon != null && <span className='material-symbols-outlined icon-size'>{icon}</span>}
      {children}
    </DocViewWrapper>
  );
};

export default ViewUploadedDoc;
