import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { getScreenViewHeight, getViewHeight, rem } from '../../utils/style';

interface SettingContentType {
  img: string;
  isOpen: boolean;
}

interface SettingSideBarType {
  hasNotify?: boolean;
}

export const SettingList = styled.div`
  padding: 0 70px;
  margin: auto;
  h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: ${COLORS.Blue};
  }
  li {
    list-style: none;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
  }
`;

export const SettingMain = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 44px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${COLORS.Gray80};

  h5 {
    color: ${COLORS.Gray60};
    span {
      color: ${COLORS.Gray50};
    }
  }
`;

export const SettingWrap = styled.div`
  li {
    list-style: none;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 2px;
    color: ${COLORS.Gray60};
    padding: 3px 10px;
    cursor: pointer;
    border-radius: 3px;

    &:hover,
    &.list-active {
      background-color: ${COLORS.Gray1010};
    }
  }
`;

export const SettingMainContainer = styled.div`
  padding-top: 40px;
`;

export const SettingMainContainerLine = styled.div`
  border-top: 1.5px solid ${COLORS.Gray80};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    height: 3px;
    width: 100%;
    background-color: ${COLORS.Blue};
  }
`;

export const CompanyProfileDetails = styled.div`
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${COLORS.Gray50};
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${COLORS.Gray60};
    word-break: break-all;
  }
`;

export const RolesList = styled.div`
  font-size: 12px;
  line-height: 15px;
  p {
    font-weight: 500;
    color: ${COLORS.Gray60};
    margin-bottom: 3px;
  }

  span {
    font-weight: 500;
    color: ${COLORS.Gray1000};
  }
`;

export const ProfileImageUploader = styled.div`
  span {
    color: ${COLORS.Gray50};
  }
`;

export const SettingSideBar = styled.div<SettingSideBarType>`
  position: fixed;
  top: ${(props) => (props.hasNotify ?? false ? '101px' : '67px')};
  z-index: 100;
  padding: 0;
  transition: all 300ms ease-in;
  height: 100%;

  .stickySidebar {
    height: ${(props) =>
      props.hasNotify ?? false
        ? `${getScreenViewHeight('101px')}`
        : `${getScreenViewHeight('67px')}`};
    overflow-x: hidden;
    overflow-y: auto;

    border-right: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 2;
    background: ${COLORS.White};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.SpanishGray};
      border-radius: 7px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .sidebarListing {
      padding: ${rem(22)} ${rem(12)};

      .sidebar-top {
        padding: 0 ${rem(8)} ${rem(2)} ${rem(8)};

        h4 {
          font-weight: 500;
          margin-bottom: ${rem(14)};
          line-height: ${rem(38)};
        }

        .sidebar-search input {
          border-color: ${COLORS.LightGray};
          background-color: ${COLORS.OffWhite};

          &::placeholder {
            color: ${COLORS.SpanishGray};
          }
        }
      }
    }
  }

  &.sidebar-Open {
    left: 0;
  }

  &.sidebar-Close {
    left: -218px;
  }

  .sidebarIcon-close {
    font-size: ${rem(18)};
    border: 1px solid ${COLORS.LightGray};
    height: ${rem(38)};
    width: ${rem(38)};
    border-radius: 50%;
    background-color: ${COLORS.OffWhite};

    position: absolute;
    right: -18px;
    top: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    z-index: 100;
    transform: rotate(180deg);
    transition: transform 200ms ease;

    &.isOpen {
      transform: rotate(0deg);
    }
  }
`;

export const SubSetting = styled.div`
  .subSetting-wrap {
    margin-top: ${rem(18)};
  }

  .sidebar-collapse {
    padding: ${rem(8)} ${rem(10)};
    display: flex;
    align-items: center;
    gap: ${rem(4)};
    cursor: pointer;
    border-radius: ${rem(4)};
    margin-bottom: ${rem(2)};

    span {
      color: ${COLORS.Black};
      font-weight: 500;

      &.collapse-icon {
        font-size: ${rem(16)};
      }

      &.heading-icon {
        font-size: ${rem(20)};
      }
    }

    &:hover {
      background-color: ${COLORS.OffWhite};
    }
  }
`;
export const SubSettingList = styled.div`
  span {
    margin-top: 0;
    margin-bottom: ${rem(4)};
    transition: all 0.2s ease-in-out;
    padding: ${rem(6)} ${rem(12)};
    border-radius: ${rem(4)};
    cursor: pointer;
    color: ${COLORS.Gray};

    &:hover,
    &.active {
      background-color: ${COLORS.LightBlue};
      color: ${COLORS.Iris};
      font-weight: 500;
    }
  }
`;

export const SettingHelpBox = styled.div`
  /* margin-top: ${rem(57)}; */
  span {
    padding: ${rem(8)} ${rem(10)};
    &:hover {
      background-color: ${COLORS.OffWhite};
      font-weight: 600;
      border-radius: ${rem(4)};
    }
  }
`;

export const SettingDivider = styled.div`
  margin-top: ${rem(4)};
  margin-bottom: ${rem(24)};
  border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};
`;

export const SettingFooter = styled.div`
  min-height: 57px;

  border-top: 1px solid #ededed;
  padding: ${rem(0)} ${rem(12)};
  display: flex;
  align-items: center;
`;

export const SettingContent = styled.div<SettingContentType>`
  flex: 1 1 0;
  padding: ${rem(30)} ${rem(26)} ${rem(30)} ${rem(54)};
  position: relative;
  transition: all 300ms ease;
  width: 100%;

  @media (min-width: 768px) {
    padding: ${rem(42)} ${rem(52)};
    margin-left: 240px;
    width: calc(100vw - 240px);
  }

  &:before {
    content: '';
    position: fixed;
    top: 67px;
    left: 240px;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: ${(props) => `url(${props.img}) no-repeat`};
    height: ${getViewHeight()};
    left: ${(props) => (props.isOpen ? '240px' : '18px')};
  }
`;
