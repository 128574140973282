import moment from 'moment';
import { useState, useEffect } from 'react';
import {
  NotificationService,
  // eslint-disable-next-line camelcase
  type Wealthlane_NotificationService_DTOs_BellNotificationDto,
} from '../../../services/wealthlane-notification-services';
import ModalComponent from '../../modal/modal';
import Spinners from '../../spinner';
import Heading from '../../typography';
import { NotificationContainer } from './notification.styled';
import FlashMessage from '../../flash-message';
import ModalBodyComponent from '../../modal/modalBodyComponent';
import { useBoolean, useGetUnseenNotification, usePostSeenNotification } from '../../../hooks';
import { toast } from 'react-toastify';
import ToastComponent from '../../toast';
import { useQueryClient } from 'react-query';
import SlideLeftAnimation from '../../animation/slideLeft';
import { NotificationRouteList } from '../../../constants/notificationRouteList';
import { useNavigate } from 'react-router-dom';
import AlertPopup from '../../alert-popup';

interface NotificationModalType {
  // eslint-disable-next-line camelcase
  newMessage: Wealthlane_NotificationService_DTOs_BellNotificationDto;
  isModalOpen: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  closeModal: () => void;
}

interface NotificationCardType {
  // eslint-disable-next-line camelcase
  notificationData: Wealthlane_NotificationService_DTOs_BellNotificationDto;
}

const NotificationModal = ({
  newMessage,
  isModalOpen,
  handleRequest,
  closeModal,
}: NotificationModalType): JSX.Element => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [notificationId, setNotificationId] = useState('');
  const [show, setShow] = useState('');
  const [allNotification, setAllNotification] = useState<
    // eslint-disable-next-line camelcase
    Wealthlane_NotificationService_DTOs_BellNotificationDto[]
  >([]);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);
  const { data, isLoading } = useGetUnseenNotification(handleRequest);

  useEffect(() => {
    if (data != null) {
      setAllNotification([...data]);
    }
  }, [data]);

  useEffect(() => {
    if (newMessage != null) {
      setAllNotification([newMessage, ...allNotification]);
    }
  }, [newMessage]);

  const { mutate } = usePostSeenNotification(handleRequest);
  const handleNotificationSeen = (id?: string): void => {
    mutate(id);
  };
  const handleDeleteAllNotification = (): void => {
    closeConfirmModal();
    handleRequest(NotificationService.deleteApiNotificationApiAppNotificationNotifications())
      .then((data?: boolean) => {
        if (data ?? false) {
          closeConfirmModal();
          setShow('All');
          setTimeout(() => {
            setAllNotification([]);
            setShow('');
            toast.success(<ToastComponent label='Success' message='All Notification Deleted.' />);
            void queryClient.invalidateQueries('getGetUnseenNotificationList');
            void queryClient.invalidateQueries('getGetUnseenNotificationCountList');
          }, 1000);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };
  const handleDeleteNotification = (id: string): void => {
    setNotificationId(id);
    handleRequest(NotificationService.deleteApiNotificationApiAppNotificationNotification(id))
      .then((notifyData?: boolean) => {
        if (notifyData ?? false) {
          const filterData = data?.filter((item) => item.notificationId !== id);
          setNotificationId('');
          setShow(id);

          setTimeout(() => {
            if (filterData != null) {
              setAllNotification([...filterData]);
            }
            setShow('');
            void queryClient.invalidateQueries('getGetUnseenNotificationCountList');
            void queryClient.invalidateQueries('getGetUnseenNotificationList');
          }, 1000);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        setNotificationId('');
      });
  };

  const redirectNotification = (notifyTitle: string | null | undefined): void => {
    const notificationUrl = NotificationRouteList?.find(
      (item: { title: string; url: string }) => item?.title === notifyTitle
    )?.url;
    notificationUrl != null && navigate(notificationUrl);
    closeModal();
  };

  const NotificationCard = ({ notificationData }: NotificationCardType): JSX.Element => {
    return (
      <SlideLeftAnimation isOpen={notificationData.notificationId === show ? true : show === 'All'}>
        <div
          className={`notification ${
            notificationData.isSeen ?? false ? 'notification-seen' : ''
          } flex justify-between items-start`}>
          <div>
            <div
              onClick={() => {
                redirectNotification(notificationData?.notificationRedirectionPage);
                handleNotificationSeen(notificationData.notificationId);
              }}>
              <Heading
                title={<div dangerouslySetInnerHTML={{ __html: notificationData.message ?? '' }} />}
                variant='body'
                className='notification-title'
              />
            </div>
            <Heading
              title={`${moment(notificationData.notificationDateTime).format(
                'DD MMM. YYYY h:mm A'
              )}`}
              variant='body-xs'
              className='notification-date'
            />
          </div>
          <div>
            {notificationId === notificationData.notificationId ? (
              <div className='notification-loader'>
                <Spinners size='sm' />
              </div>
            ) : (
              <span
                className='notification-delete material-symbols-sharp'
                onClick={() => {
                  handleDeleteNotification(notificationData.notificationId ?? '');
                }}>
                Delete
              </span>
            )}
          </div>
        </div>
      </SlideLeftAnimation>
    );
  };

  return (
    <ModalComponent
      show={isModalOpen}
      size='sm'
      closeModal={closeModal}
      title='Notifications'
      loading={isLoading}
      outsideClickable
      clearAll={allNotification.length > 0}
      isNotification
      clearAllNotification={openConfirmModal}
      notificationModalClassName={'notification-modal'}
      children={
        <ModalBodyComponent
          showFooter={false}
          isNotification
          className='p-0'
          closeModal={closeModal}>
          {data != null && data.length > 0 ? (
            <NotificationContainer>
              {allNotification.map((notification) => (
                <NotificationCard notificationData={notification} />
              ))}
            </NotificationContainer>
          ) : (
            <FlashMessage size='md' message='Your currently have no notifications' type='info' />
          )}
          {isConfirmOpen && (
            <AlertPopup
              isShow={isConfirmOpen}
              closeModal={closeConfirmModal}
              customTitle={'Are you sure you want to clear all notifications ?'}
              handleSubmit={handleDeleteAllNotification}
              // loading={loading}
            />
          )}
        </ModalBodyComponent>
      }
    />
  );
};

export default NotificationModal;
