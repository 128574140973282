/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationDto } from '../models/Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationDto';
import type { Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationRequestDto } from '../models/Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationRequestDto';
import type { Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TransactionPeriodTypeDto } from '../models/Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TransactionPeriodTypeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TradePreClearanceTransactionPeriodConfigurationService {

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppTradePreClearanceTransactionPeriodConfigurationTradePreClearanceTransactionPeriodConfiguration(): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/trade-pre-clearance-transaction-period-configuration/trade-pre-clearance-transaction-period-configuration',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppTradePreClearanceTransactionPeriodConfigurationSavePreClearanceTransactionPeriodConfig(
requestBody?: Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TradePreClearanceTransactionPeriodConfigurationRequestDto,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/trade-pre-clearance-transaction-period-configuration/save-pre-clearance-transaction-period-config',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TransactionPeriodTypeDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppTradePreClearanceTransactionPeriodConfigurationTransactionPeriodTypes(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_TradePreClearanceTransactionPeriodConfiguration_TransactionPeriodTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/trade-pre-clearance-transaction-period-configuration/transaction-period-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
