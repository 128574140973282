import { type UseQueryResult, useQuery } from 'react-query';
import {
  PerformanceRuleApprovalService,
  PerformanceRuleService,
} from '../../services/wealthlane-award-services';

const useGetApproverPerformanceRuleApprovalStatus = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const ApproverPerformanceRuleApprovalStatus = useQuery(
    ['getApproverPerformanceRuleApprovalStatus'],
    async () => {
      return await handleRequest(
        PerformanceRuleApprovalService.getApiAwardApiAppPerformanceRuleApprovalRuleApprovalStatusList()
      );
    },
    {
      select: (data) => {
        const rule = data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
        const sortOptions = rule?.concat({ label: 'All', value: 0 }).sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });
        return sortOptions;
      },
    }
  );

  return ApproverPerformanceRuleApprovalStatus;
};

export default useGetApproverPerformanceRuleApprovalStatus;
