import { type UseQueryResult, useQuery } from 'react-query';
import {
  OptionAwardService,
  ReleaseService,
  RsuAwardService,
  TransferAllocateService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto,
  type Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto,
  PsuAwardService,
} from '../../services/wealthlane-award-services';

const useGetTransferAndAllocateAwards = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  awardType?: any
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto
  | Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto
  | undefined,
  unknown
> => {
  const transferAndAllocateAwards = useQuery(
    ['getTransferAndAllocateAwards', id, awardType],
    async () => {
      if (id && awardType === 'RSU') {
        return await handleRequest(
          RsuAwardService.getApiAwardApiAppRSUAwardRSUAwardDetailByRSuVestingDateId(id)
        );
      } else if (id && awardType === 'ESOP') {
        return await handleRequest(
          OptionAwardService.getApiAwardApiAppOptionAwardOptionAwardDetailByOptionAwardId(id)
        );
      } else if (id && awardType === 'PSU') {
        return await handleRequest(
          PsuAwardService.getApiAwardApiAppPSUAwardPSUAwardDetailByPSuVestingDateId(id)
        );
      }
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          return response;
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );

  return transferAndAllocateAwards;
};

export default useGetTransferAndAllocateAwards;
