/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink, type PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Button from '../../../components/button';
import Spinners from '../../../components/spinner';
import Table from '../../../components/table';
import InitialValuesForm from '../../../constants/initialValues';
import { BankAccountTemplatesHeader } from '../../../constants/table-headers/bankAccountHeader';
import {
  useBoolean,
  useGetCompanyBankList,
  usePostBankAccount,
  usePostBankAccountManualToken,
  useWealthlaneFinanceIntegrationApi,
} from '../../../hooks';
import {
  CompanyBankService,
  type Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken,
} from '../../../services/wealthlane-finance-integration-services';
import BankAccountModal from './bankAccountModal';
import AddBankAccountModal from './addBankAccount';
import { SettingSystemUserButton } from '../system-users/systemUserStyled';
import Heading from '../../../components/typography';
import { type TableParamsType } from '../../../types/common';
import { PageHeader } from '../../../constants/style/common.styled';
import ToastComponent from '../../../components/toast';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import ConfirmationModal from './confirmationModal';

type PlaidLinkOnSuccess = (
  public_token: string,
  metadata: PlaidLinkOnSuccessMetadata,
  sessionId: string
) => void;

const BankAccount = (): JSX.Element => {
  const [id, setId] = useState('');
  const queryClient = useQueryClient();
  const [isManual, setIsManual] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [loading, setLoading] = useState('idle');
  const [purposeLoading, setPurposeLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openPlaid, setOpenPlaid] = useState(false);
  const [token, setToken] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [tableData, setTableData] = useState<any>([]);
  const [purposeInfo, setPurposeInfo] = useState<{ bankAccount: string }>({ bankAccount: '' });
  const [verificationData, setVerificationData] = useState<any>({});
  const { handleRequest } = useWealthlaneFinanceIntegrationApi();
  const [tableInfo, setTableInfo] = useState<TableParamsType>({
    ...InitialValuesForm.tableParams,
    maxResultCount: 1000,
  });

  const { data: tableInfoData, status } = useGetCompanyBankList(handleRequest, tableInfo);

  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmModalOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);

  const {
    value: isModalVerificationOpen,
    setFalse: closeVerificationModal,
    setTrue: openVerificationModal,
  } = useBoolean(false);

  const { mutate, isLoading } = usePostBankAccount(handleRequest, closeVerificationModal);
  const { mutate: BankMutate } = usePostBankAccountManualToken(
    handleRequest,
    openVerificationModal,
    setMainLoading
  );
  const userAuthList = useSelector(selectAuthRoutesList);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata, sessionId) => {
    const payload = {
      publicToken,
      institutionName:
        metadata.institution?.name != null && metadata.institution?.name !== ''
          ? metadata.institution?.name
          : null,
      institutionId:
        metadata.institution?.institution_id != null && metadata.institution?.institution_id !== ''
          ? metadata.institution?.institution_id
          : null,
      sessionId: sessionId,
      linkAccounts: metadata.accounts.map((account) => {
        return {
          accountId: account.id,
          accountName: account.name,
          accountSubType: account.subtype,
          accountType: account.type,
          accountMask: account.mask,
          verificationStatus: account.verification_status,
        };
      }),
    };

    if (payload.linkAccounts[0].verificationStatus === 'manually_verified') {
      setMainLoading(true);
      BankMutate(payload);
    } else if (payload.linkAccounts[0].verificationStatus === 'pending_manual_verification') {
      mutate(payload);
    } else {
      openVerificationModal();
      setVerificationData(payload);
    }
    setIsManual(false);
  }, []);

  const handleSubmit = (values: { bankAccount: string }): void => {
    if (isVerify) {
      if (values.bankAccount === 'makeTaxAccount') {
        makeTaxAccount();
      } else if (values.bankAccount === 'makeExerciseAccount') {
        makeExerciseAccount();
      } else {
        makeBothAccount();
      }
    } else {
      const payload = {
        ...verificationData,
        makeTaxAccount: values.bankAccount !== 'makeExerciseAccount',
        makeExerciseAccount: values.bankAccount !== 'makeTaxAccount',
      };
      mutate(payload);
      setIsManual(false);
    }
  };

  const actions = [
    {
      action: 'View',
      onClick: (id: string) => {
        setId(id);
        openModal();
      },
    },
    {
      action: 'Change Account Purpose',
      as: 'Edit',
      onClick: (id: string) => {
        const getData = tableData?.find((item: any) => item.id === id);
        const isBank =
          Boolean(getData?.isTaxAccount) && Boolean(getData?.isExerciseAccount)
            ? 'both'
            : (getData?.isTaxAccount as boolean)
            ? 'makeTaxAccount'
            : (getData?.isExerciseAccount as boolean)
            ? 'makeExerciseAccount'
            : '';
        setPurposeInfo({
          bankAccount: isBank,
        });
        setId(id);
        setIsVerify(true);
        openVerificationModal();
      },
    },
    {
      action: 'Verify',
      onClick: (id: string) => {
        setId(id);
        handleManualToken(id);
        setIsManual(true);
        setIsVerify(true);
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      onClick: (id: string) => {
        openConfirmModal();
        setId(id);
      },
    },
  ];

  const { data: viewData, isLoading: viewLoading } = useQuery(
    ['getBankAccountViewData', id],
    async () => {
      return await handleRequest(
        CompanyBankService.getApiFinanceIntegrationApiAppCompanyBankCompanyBankForView(id)
      );
    },
    {
      enabled: !(id.length === 0),
    }
  );

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: (publicToken, metadata) => onSuccess(publicToken, metadata, sessionId),
    onExit: (error) => {
      if (error != null && (error.error_code === 'INVALID_LINK_TOKEN' ?? '')) {
        // handleOpenPlain();
      }
      if (error != null) {
        toast.error(
          <ToastComponent
            label='Error'
            message={`${error.error_code}, ${error.display_message ?? error.error_message}`}
          />
        );
      }
    },
    onEvent: (eventName, metadata) => {
      if (eventName === 'ERROR' && metadata.error_code != null) {
        toast.error(<ToastComponent label='Error' message={`${metadata.error_code}`} />);
      }
    },
  });

  const handleManualToken = (id: string): void => {
    setLoading('loading');
    handleRequest(
      CompanyBankService.getApiFinanceIntegrationApiAppCompanyBankLinkTokenForManualBankAccountVerification(
        id
      )
    )
      .then((data) => {
        if (data) {
          setToken(data.linkToken ?? '');
          setSessionId(data.sessionId ?? '');
          setLoading('success');
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        setLoading('error');
      });
  };

  useEffect(() => {
    if (tableInfoData != null) setTableData(tableInfoData?.items);
  }, [tableInfoData]);

  useEffect(() => {
    if (Boolean(token) && loading === 'success' && isManual && ready) {
      open();
    }
  }, [token, loading, ready, isManual, open]);

  // Tax Account API
  const makeTaxAccount = (): void => {
    setPurposeLoading(true);
    handleRequest(CompanyBankService.postApiFinanceIntegrationApiAppCompanyBankMakeTaxAccount(id))
      .then((data: any) => {
        if (data != null) {
          closeVerificationModal();
          void queryClient.invalidateQueries('getCompanyBankList');
          toast.success(<ToastComponent label='Success' message='Purpose Changed Successfully.' />);
          setMainLoading(false);
          setIsVerify(false);
          setPurposeLoading(false);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  // Exercise Account API
  const makeExerciseAccount = (): void => {
    setPurposeLoading(true);
    handleRequest(
      CompanyBankService.postApiFinanceIntegrationApiAppCompanyBankMakeExerciseAccount(id)
    )
      .then((data: any) => {
        if (data != null) {
          closeVerificationModal();
          void queryClient.invalidateQueries('getCompanyBankList');
          toast.success(<ToastComponent label='Success' message='Purpose Changed Successfully.' />);
          setMainLoading(false);
          setIsVerify(false);
          setPurposeLoading(false);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  // Tax & Exercise Account API
  const makeBothAccount = (): void => {
    setPurposeLoading(true);
    handleRequest(
      CompanyBankService.postApiFinanceIntegrationApiAppCompanyBankMakeExerciseAndTaxAccount(id)
    )
      .then((data: any) => {
        if (data != null) {
          closeVerificationModal();
          void queryClient.invalidateQueries('getCompanyBankList');
          toast.success(<ToastComponent label='Success' message='Purpose Changed Successfully.' />);
          setMainLoading(false);
          setIsVerify(false);
          setPurposeLoading(false);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleBankDelete = (): void => {
    setDeleteLoading(true);
    handleRequest(
      CompanyBankService.deleteApiFinanceIntegrationApiAppCompanyBankCompanyBankAccount(id)
    )
      .then((data: any) => {
        if (data != null) {
          toast.success(<ToastComponent label='Success' message='Bank Account Deleted.' />);
          void queryClient.invalidateQueries('getCompanyBankList');
          closeConfirmModal();
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        closeConfirmModal();
      })
      .finally(() => {
        closeConfirmModal();
        setDeleteLoading(false);
      });
  };

  const handleOpenPlain = (): void => {
    setTokenLoading(true);
    handleRequest(CompanyBankService.getApiFinanceIntegrationApiAppCompanyBankLinkToken())
      .then(
        (data?: Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken) => {
          if (data != null) {
            setToken(data?.linkToken ?? '');
            setSessionId(data?.sessionId ?? '');
            setTimeout(() => {
              setTokenLoading(false);
              setOpenPlaid(true);
            }, 500);
          }
        }
      )
      .catch((error: any) => {
        console.log('error', error);
        setTokenLoading(false);
      });
  };

  useEffect(() => {
    setMainLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (Boolean(token) && openPlaid && ready) {
      open();
      setOpenPlaid(false);
    }
  }, [token, openPlaid, ready, open]);

  return (
    <div>
      {mainLoading && <Spinners />}
      {!mainLoading && (
        <>
          <div className='grid lg:grid-cols-12'>
            <div className='lg:col-span-6'>
              <PageHeader>
                <Heading variant='h3' title='Bank Account' />
                <Heading
                  variant='body-s'
                  title={`List of ${String(
                    userAuthList?.currentTenant?.name ?? ''
                  )} bank accounts on Wealthlane system for tax withholding and exercises`}
                />
              </PageHeader>
            </div>
            <SettingSystemUserButton className='lg:col-span-6 flex justify-end items-center'>
              <Button
                as='Create'
                variant={'primary'}
                icon={'add_circle_outline'}
                title={'Add Bank Account'}
                onClick={() => {
                  handleOpenPlain();
                }}
                loading={tokenLoading}
              />
            </SettingSystemUserButton>
          </div>
          <Table
            columns={BankAccountTemplatesHeader(actions, tableData)}
            data={tableData ?? []}
            loading={status}
            totalCounts={tableInfoData?.totalCount ?? 100}
            tableInfo={tableInfo}
            setTableInfo={setTableInfo}
            showPagination
            showPaginationList={false}
            showRow={false}
          />

          {/* View Data Modal */}
          <AnimationWrapper>
            {isModalOpen && (
              <BankAccountModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                modalLoading={viewLoading}
                initialData={viewData ?? {}}
              />
            )}
          </AnimationWrapper>

          {/* Add Bank Modal */}
          <AnimationWrapper>
            {isModalVerificationOpen && (
              <AddBankAccountModal
                isModalOpen={isModalVerificationOpen}
                closeModal={closeVerificationModal}
                modalLoading={tokenLoading}
                initialValue={purposeInfo}
                handleSubmit={(values) => {
                  handleSubmit(values);
                }}
                Loading={purposeLoading}
              />
            )}

            {isConfirmModalOpen && (
              <ConfirmationModal
                isModalOpen={isConfirmModalOpen}
                confirmLoading={deleteLoading}
                handleConfirmClick={() => {
                  handleBankDelete();
                }}
                closeConfirmModal={() => {
                  closeConfirmModal();
                  setDeleteLoading(false);
                }}
              />
            )}
          </AnimationWrapper>
        </>
      )}
    </div>
  );
};

export default BankAccount;
