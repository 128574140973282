import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../../../components/table';
import InitialValuesForm from '../../../constants/initialValues';
import { HeadingMain } from '../../../constants/style/common.styled';
import { GrantManagementHeader } from '../../../constants/table-headers/grantManagementHeader';
import { GrantService } from '../../../services/wealthlane-award-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { SUPPORTED_FORMATS } from '../../../constants/common';
import { getFileExtension } from '../../../utils/global';
import Search from '../../../components/search/search';
import { useBoolean, useGetGrantList, useWealthlaneAwardApi } from '../../../hooks';

const initialValues = {
  data: InitialValuesForm.grantManagement,
  id: '',
  planId: '',
};

const GrantManagementList = (): JSX.Element => {
  const { id: planID } = useParams();
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [searchQuery, setSearchQuery] = useState('');
  const [, setModalStatus] = useState<ModalStatusType>('Create');
  const [, setInitialData] = useState({ ...initialValues, planId: planID });
  const { setTrue: openModal } = useBoolean(false);
  const grantTypeId = '';

  const { handleRequest } = useWealthlaneAwardApi();
  const { data, status } = useGetGrantList(handleRequest, tableInfo, planID, grantTypeId);

  const actions = [
    {
      action: 'View',
      onClick: (id: string) => {
        setModalStatus('View');
        getGrantViewData(id);
      },
    },
  ];

  const getGrantViewData = (id: string): any => {
    handleRequest(GrantService.getApiAwardApiAppGrantGrantById(id))
      .then((data: any) => {
        if (data != null) {
          const filteredData = {
            id: data.id,
            grantName: data.grantName,
            planTypeId: data.planTypeId,
            vestingScheduleId: data.vestingScheduleId,
            performanceRuleId: data.performanceRuleId,
            vestingScheduleName: data.vestingScheduleName,

            grantDocument: [],
            planTypeName: data.planTypeName,
            documentNameList: data.documentNameList?.map((item: any) => {
              const fileName = item.grantDocumentFileName;
              return {
                file: {
                  name: item.grantDocumentFileName,
                  uniqueName: item.grantDocumentUniqueName,
                  type: SUPPORTED_FORMATS.find((item) => item.type === getFileExtension(fileName))
                    ?.format,
                },
                id: item.grantDocumentUniqueName,
                src: '',
              };
            }),
            approvedDate: data?.approvedDate,
            termsAndConditionsDocument: [],
            isActive: data.isActive === true ? 'Active' : 'InActive',
            termsAndConditionsAgreementStatement: data.termsAndConditionsAgreementStatement,
            termsAndConditionsDocumentContent: data.termsAndConditionsDocumentContent,
            termsAndConditionsDocumentName: [
              {
                file: {
                  name: data.termsAndConditionsDocumentName?.termsAndConditionsDocumentFileName,
                  uniqueName:
                    data.termsAndConditionsDocumentName?.termsAndConditionsDocumentUniqueName,
                },
              },
            ],
          };
          setInitialData({ id, data: filteredData as any, planId: planID });
          openModal();
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  return (
    <div>
      <div className='flex flex-col sm:flex-row justify-between sm:items-center mb-2.5 gap-3'>
        <HeadingMain className='mb-0'>Grant List</HeadingMain>
        <div className='flex items-center gap-x-5'>
          <Search
            placeholder='Search Keywords'
            searchValue={searchQuery}
            onSearch={(value: string) => {
              setSearchQuery(value);
            }}
            setTableInfo={setTableInfo}
            tableInfo={tableInfo}
          />
        </div>
      </div>
      <div className='overflow-x-auto'>
        <Table
          columns={GrantManagementHeader(actions)}
          data={data?.items ?? []}
          loading={status}
          showPagination
          tableInfo={tableInfo}
          setTableInfo={setTableInfo}
          totalCounts={data?.totalCount ?? 10}
        />
      </div>
      {/* {isModalOpen && (
        <GrantManagementModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          initialData={initialData}
        />
      )} */}
    </div>
  );
};

export default GrantManagementList;
