/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
const InitialValuesForm = {
  udfInitialForm: {
    fieldName: '',
    fieldType: '',
    isMandatory: null,
    moduleId: '',
    orderNumber: null,
    status: null,
  },
  editCompanyProfileForm: {
    name: '',
    ticker: '',
    industrySectorId: '',
    primaryStockExchange: '',
    dateOfIncorporation: '',
    stateOfIncorporationId: '',
    taxId: '',
    businessDescription: '',
    address: '',
    addressLine1: '',
    addressLine2: '',
    cityText: '',
    stateId: '',
    zipCode: '',
    CUSIP: '',
    sicCode: '',
    isEnabled: true,
  },
  addSystemRoleForm: {
    roleName: '',
    description: '',
    isEnabled: true,
    permissions: {},
    fieldLevelPermissions: {},
  },
  departmentForm: {
    name: '',
    description: '',
  },
  jobTitleForm: {
    name: '',
    description: '',
  },
  employeeTypesForm: {
    name: '',
    description: '',
    isTaxEnable: false,
  },
  addSystemUserForm: {
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    customerTitleId: null,
    employeeId: '',
    customerDepartmentId: null,
    customerRoleId: null,
    phone: '',
    countryCallingCodeId: '',
    phoneIsOtherCountry: false,
  },
  tableParams: {
    filter: '',
    sorting: '',
    skipCount: 0,
    maxResultCount: 10,
  },

  rsuFilter: {
    fromDate: null,
    toDate: null,
    approvalStatusId: null,
  },
  retirementEligibility: {
    id: null,
    eligibilityCriteriaId: '',
    ageYear: null,
    ageMonth: null,
    tenureCompletedYear: null,
    tenureCompletedMonth: null,
  },
  participantBroadcastMessage: {
    title: '',
    messageTemplateId: '',
    startDate: '',
    endDate: '',
    addToTemplate: false,
  },
  optionAwards: {
    employee: [
      {
        employeeId: '',
        participantId: '',
        participantName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        planId: '',
        planName: '',
        planTypeId: '',
        planType: '',
        grantId: '',
        grantName: '',
        grantPrice: '',
        quantity: '',
        marketValueAtGrant: '',
        grantDate: null,
        vestingStartDate: null,
        excercisePrice: '',
        remarks: '',
        vestingSchedule: '',
        vestingTemplateId: '',
        grantReason: '',
      },
    ],
  },

  rsuAwards: {
    employee: [
      {
        employeeId: '',
        participantId: '',
        participantName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        grantId: '',
        grantName: '',
        grantDate: null,
        vestingStartDate: null,
        quantity: '',
        grantPrice: '',
        marketValueAtGrant: '',
        planId: '',
        planName: '',
        planTypeId: '',
        planType: '',
        remarks: '',
        vestingSchedule: '',
        vestingTemplateId: '',
        grantReason: '',
      },
    ],
  },

  tradePreClearance: {
    id: '',
    optionType: '',
    participantDashboardText: 'Hello',
    isIndividual: false,
    isGroup: false,
    selectAll: false,
    participantIds: [],
    tradePreClearanceGroups: [
      { departmentId: null, employeeTypeId: null, jobTitleIds: [], groupData: false },
    ],
  },

  tradingBlackoutConfig: {
    id: null,
    blackoutTitle: '',
    message: '',
    startDate: null,
    endDate: null,
    blackoutEligibilityId: null,
    blackoutGroups: [
      {
        id: null,
        groupData: false,
        customerDepartmentId: null,
        employeeTypeId: null,
        blackOutJobTitles: [],
      },
    ],
  },

  participants: {
    participant: [
      {
        payrollId: null,
        employeeId: null,
        firstName: '',
        middleName: '',
        lastName: '',
        contactNumberCountryCode: '',
        isOtherCountryPhone: false,
        ssn: '',
        titleId: null,
        divisionId: null,
        email: '',
        costCenter: '',
        dateOfBirth: '',
        hireDate: '',
        countryId: null,
        workNumber: '',
        workNumberCountryCodeId: null,
        employeeTypeId: null,
        employmentCountryId: null,
        employmentStateId: null,
        employmentCityText: '',
        employmentZipCode: '',
        employmentAddressLine1: '',
        employmentAddressLine2: '',
        residentStatusId: null,
        residentStatusName: '',
        isOtherCountry: false,
        participantOtherCountry: false,
      },
    ],
    terminateParticipant: {
      participantId: '',
      terminationTypeId: '',
      awardStatusId: '',
      terminationDate: '',
      awardEffectiveDate: '',
      remarks: '',
      awardDetails: [
        {
          participantId: '',
          rsuAwardId: '',
          optionAwardId: '',
          awardNumber: '',
          planTypeCategoryId: '',
          planTypeCategory: '',
          outstandingUnits: 0,
          applicableUnits: null,
          awardEffectiveDate: '',
          awardStatusId: '',
          awardStatusName: '',
        },
      ],

      // terminationTypeId: '',
      // terminationDate: '',
      // awardStatusId: '',
      // awardEffectiveDate: '',
      // remarks: '',
      // individualAwards: false,
      // awardDetails: [
      //   {
      //     awardEffectiveDate: '',
      //   },
      // ],
    },
  },

  remarksInitialValues: {
    remarks: '',
    id: '' || [],
  },

  approvalConfig: {
    customerUserDtos: [],
  },
  planManagementModel: {
    name: '',
    numberOfSharesAuthorized: '',
    numberOfSharesUnAvailable: '',
    numberOfSharesAvailable: '',
    // numberOfSharesIssued: '',
    description: '',
    planTypeCategoryIds: [],
    planDocument: [
      // {
      //   fileName: '',
      //   document: '',
      // },
    ],
    eligibleShareClass: '',
    boardApprovalDate: '',
    shareHolderApprovalDate: '',
    planTerm: '',
    evergreenPlan: '',
    evergreenCriteria: '',
    deathExpirationOption: '',
    disablityExpirationOption: '',
    retirementExpirationOption: '',
    volutaryTerminationOption: '',
    involutaryTerminationOption: '',
    withACause: '',
    planNameList: [],
    planEffectiveDate: '',
    optionExpirationDate: '',
    planGrantDocumentsNameList: [],
    planGrantDocuments: [
      // {
      //   grantDocumentName: '',
      //   description: '',
      //   document: '',
      //   grantDocumentType: null,
      //   grantTermsAndConditionText: '',
      // },
    ],
  },
  vestingTemplate: {
    name: '' || null || undefined,
    summary: '' || null,
    vestingTypeId: null,
    lengthInMonths: null,
    amountOneInPercentage: 0 || null,
    vestsAfterInMonths: null,
    amountTwoInPercentage: 0 || null,
    vestsInMonths: 0 || null,
    intervalInMonths: 0 || null,
    roundingTypeId: null,
    periodStartDate: '',
    // planTypeCategoryId: '',
  },
  previewVestingTemplate: {
    vestStartDate: '',
    awardShares: 0 || null,
    vestingTemplateId: '',
  },
  grantManagement: {
    sn: null,
    importType: '',
    grantName: '',
    planTypeName: '',
    approvedDate: '',
    vestingTemplateName: '',
    performanceRuleName: '',
    approvalRequired: false,
    grantAgreementDocumentName: '',
    grantNoticeDocumentName: '',
    agreementStatement: '',
    agreementStatementStatusName: '',
    termsAndConditionDocumentName: '',
    termsAndConditionTextName: '',
    planId: null,
    id: null,
    planGrantDocuments: [
      /*  {
        grantDocumentName: '',
        description: '',
        fileExtension: '',
        document: '',
        grantDocumentType: 0,
        grantTermsAndConditionText: '',
      }, */
    ],
  },
  // grantManagement: {
  //   planTypeName: '',
  //   grantName: '',
  //   vestingScheduleId: '',
  //   performanceRuleId: '',
  //   vestingScheduleName: '',
  //   documentNameList: [],
  //   grantDocument: [],
  //   termsAndConditionsDocumentContent: '',
  //   approvedDate: '',
  //   isActive: '',
  //   termsAndConditionsDocumentName: [],
  //   termsAndConditionsDocument: [],
  //   termsAndConditionsAgreementStatement: '',
  //   name: '' || null || undefined,
  //   summary: '' || null,
  //   vestingTypeId: '',
  //   lengthInMonths: 0,
  //   amountOneInPercentage: 0 || null,
  //   vestsAfterInMonths: null,
  //   amountTwoInPercentage: 0 || null,
  //   vestsInMonths: 0 || null,
  //   intervalInMonths: 0 || null,
  //   roundingTypeId: null,
  // },
  awardSettlementConfig: {
    RSU: {
      sellAll: '',
      sellToCover: '',
      cashExercise: '',
    },
    ESOP: {
      sellAll: '',
      sellToCover: '',
      cashExercise: '',
    },
  },
  termAndCondition: {
    id: '',
    name: '',
    documentContent: '',
    isActive: '',
    documentList: [],
    document: [],
    pageId: '',
    agreementStatement: '',
  },
  Headquarter: {
    addressLine1: '',
    addressLine2: '',
    countryId: '',
    stateId: '',
    cityId: '',
    state: '',
    city: '',
    zipCode: '',
  },
  changePassword: {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  },

  taxPeriod: {
    name: '',
    effectiveFrom: '',
    effectiveTill: '',
  },

  taxSetup: {
    name: '',
    jurisdictionLevelId: '',
    taxPeriodId: '',
    taxCode: '',
    equityCompensationLevelId: '',
    equityCompensationLevelName: '',
    countryId: null,
    stateId: null,
    stateText: '',
    cityId: null,
    participantId: null,
    planId: null,
    grantId: null,
    awardId: null,
    transactionId: null,
    userDefinedCriteria: {
      LogicalOperatorType: 'And',
      Conditions: [
        {
          Hash: null,
          EqualityOperatorType: null,
          Field: null,
          Value: null,
          Name: null,
          SubCriteria: {
            LogicalOperatorType: null,
            Conditions: [
              {
                Hash: null,
                EqualityOperatorType: null,
                Field: null,
                Value: '',
                Name: null,
                SubCriteria: null,
              },
            ],
          },
        },
      ],
    },
    taxBrackets: [
      {
        taxRate: '',
        lowerLimit: '',
        upperLimit: '',
      },
    ],
    // userDefinedGroup: [
    //   {
    //     condition: '',
    //     userDefinedRules: [
    //       {
    //         rule: '',
    //         subRule: '',
    //       },
    //     ],
    //   },
    // ],
  },
  taxOverride: {
    id: '',
    name: '',
    jurisdictionLevelId: '',
    taxPeriodId: '',
    taxCode: '',
    equityCompensationLevelId: '',
    countryId: null,
    stateId: null,
    stateText: '',
    cityId: null,
    participantId: null,
    planId: null,
    grantId: null,
    transactionId: null,

    taxBrackets: [
      {
        taxRate: '',
        lowerLimit: '',
        upperLimit: '',
      },
    ],
  },
  reportSaveAs: {
    reportName: '',
  },
  renameCustomizeReport: {
    reportName: '',
  },
  releaseForm: {
    name: '',
    expectedReleaseDate: '',
    description: '',
  },
  transferAndAllocateForm: {
    name: '',
    description: '',
  },

  transactionTaxDetails: {
    details: [
      {
        taxName: '',
        taxRate: '',
        value: '',
      },
    ],
  },
  updateTax: {
    id: null,
    transactionId: null,
    taxDetails: [
      {
        id: null,
        taxName: '',
        taxRate: null,
        taxAmount: null,
        isDeleted: false,
      },
    ],
    taxDetailsDeleted: [],
  },
  performanceRule: {
    ruleName: '',
    ruleTypeId: '',
    isAvailable: false,
    evaluationPeriodStartDate: '',
    evaluationPeriodEndDate: '',
    minimumPercentage: null,
    maximumPercentage: null,
    ruleDescription: '',
    ruleSummary: '',
    showRuleDisplayToParticipant: false,
    showPerformanceTrackingToParticipant: false,
    estimatePerformanceTrackings: [],
    performanceEvaluation: null,
  },
  grantDocumentModel: {
    grantDocumentName: '',
    description: '',
    documentNameList: [],
    document: [],
    grantTermsAndConditionText: '',
  },
  grantTermAndConditionModel: {
    termsAndConditionsDocumentContent: '',
    isActive: '',
    termsAndConditionsDocumentName: [],
    termsAndConditionsDocument: [],
    termsAndConditionsAgreementStatement: '',
  },
};

export default InitialValuesForm;
