/* eslint-disable camelcase */
// import { isEmpty } from 'lodash';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  OptionExerciseService,
  ReleaseService,
  Wealthlane_AwardService_Dtos_Release_CreateReleaseDto,
  Wealthlane_AwardService_Dtos_Release_UpdateReleaseDto,
} from '../../services/wealthlane-award-services';

const usePostOptionExerciseReadyToTransfer = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setCheckedValues?: any
): UseMutationResult<Array<string> | undefined, unknown, any, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        OptionExerciseService.postApiAwardApiAppOptionExerciseReadyToTransfer(payload)
      );
    },
    {
      onSuccess: async (response: any) => {
        if (response != null) {
          toast.success(
            <ToastComponent label='Success' message={`Sent to Transfer and Allocate`} />
          );
          setCheckedValues([]);
          await queryClient.invalidateQueries('getOptionExercisedList');
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostOptionExerciseReadyToTransfer;
