import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { OptionAwardService } from '../../services/wealthlane-award-services';
import { UserDefinedFieldValuesService } from '../../services/wealthlane-customer-services';
import useWealthlaneCustomerApi from '../useWealthlaneCustomerApi';
import { CommaFormatted } from '../../utils/global';
import useGetUDFByModuleId from './useGetUDFByModuleId';

const useGetOptionAwardForUpdateById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const { data: UdfFields } = useGetUDFByModuleId(handleRequest, 3);
  const navigate = useNavigate();
  const { handleRequest: customerHandleRequest } = useWealthlaneCustomerApi();
  return useMutation(
    async (id: string) => {
      return await Promise.all([
        handleRequest(OptionAwardService.getApiAwardApiAppOptionAwardOptionAwardForUpdateById(id)),
        customerHandleRequest(
          UserDefinedFieldValuesService.getApiCustomerApiAppUserDefinedFieldValuesUserDefinedFieldValues(
            3,
            id
          )
        ),
      ]);
    },
    {
      onSuccess: (response: any, context: string) => {
        if (response != null) {
          const udfAddedLater = UdfFields?.filter(
            (value: any) =>
              !(response[1]?.some(
                (value2: any) => value.id === value2.userDefinedFieldId
              ) as boolean)
          );
          const filteredData = response[1]?.map((udfFields: any) => {
            const fieldName = udfFields?.fieldName?.replaceAll(' ', '');
            const udfName: any =
              Boolean(fieldName) &&
              String(fieldName.charAt(0).toLowerCase()) + String(fieldName.slice(1));
            const udfPayload = {
              [udfName]: udfFields?.value,
              [`${udfName as string}Id`]: udfFields?.id,
            };
            return udfPayload;
          });

          const udfAddedLaterPayload = udfAddedLater?.map((item: any) => {
            const fieldName = item?.fieldName?.replaceAll(' ', '');
            const udfName: any =
              fieldName != null && fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
            const udfAddedLaterPayload = {
              [udfName]: '',
            };
            return udfAddedLaterPayload;
          });
          const newUdfResp =
            !isEmpty(udfAddedLaterPayload) &&
            udfAddedLaterPayload?.reduce((e: any, r: any) => ({
              ...e,
              ...r,
            }));
          const udfValuesResp =
            !isEmpty(filteredData) &&
            filteredData?.reduce((e: any, r: any) => ({
              ...e,
              ...r,
            }));
          if (response[0] != null) {
            navigate('/awards/option-awards/edit', {
              state: {
                formStatus: 'Edit',
                employee: [
                  {
                    ...response[0],
                    marketValueAtGrant: CommaFormatted(response[0].marketValueAtGrant),
                    quantity: CommaFormatted(response[0].quantity),
                    grantPrice: CommaFormatted(response[0].grantPrice),
                    excercisePrice: CommaFormatted(response[0].excercisePrice),
                    ...udfValuesResp,
                    ...newUdfResp,
                  },
                ],
              },
            });
          }
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetOptionAwardForUpdateById;
