import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { PageHeader } from '../company-profile/profile.styled';
import Heading from '../../../components/typography';
import Search from '../../../components/search/search';
import InitialValuesForm from '../../../constants/initialValues';
import { ModalStatusType, TableParamsType } from '../../../types/common';
import FormSelect from '../../../components/dropdown/formSelect';
import { InvitationBlock, ResetFilterWrapper } from '../../../constants/style/common.styled';
import { SettingRoleButton } from '../system-roles/systemRoleStyled';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { PerformanceRuleHeader } from '../../../constants/table-headers/performanceRuleHeader';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import PerformanceRulesModal from './performanceRulesModal';
import {
  useAdvanceBoolean,
  useBoolean,
  useGetPerformanceRuleApprovalStatus,
  useGetPerformanceRuleAvailableStatus,
  useGetPerformanceRuleEarnedStatus,
  useGetPerformanceRulesList,
  useWealthlaneAwardApi,
} from '../../../hooks';
import {
  PerformanceEvaluationService,
  PerformanceRuleService,
} from '../../../services/wealthlane-award-services';
import { toast } from 'react-toastify';
import ToastComponent from '../../../components/toast';
import AlertPopup from '../../../components/alert-popup';
import Filter from '../../../components/filter';
import CustomDatePicker from '../../../components/date-picker/customDatePicker';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/common';
import { isEmpty, isNull } from 'lodash';

const initialValues = {
  data: InitialValuesForm.performanceRule,
  id: '',
};

const PerformanceRules = (): JSX.Element => {
  const { handleRequest } = useWealthlaneAwardApi();

  //   const [vestingType, setVestingType] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  //   const [popoverData, setPopoverData] =
  //     useState<Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto>({});
  //   const [modalLoading, setModalLoading] = useState(false);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [initialData, setInitialData] = useState(initialValues);
  const [rowData, setRowData] = useState<any>();
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const [open, setOpen] = useState(false);

  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
    customValue,
    setCustomValue,
  } = useAdvanceBoolean<string>(false);
  const {
    value: isConfirmApplyOpen,
    setFalse: closeConfirmApplyModal,
    setTrue: openConfirmApplyModal,
    customValue: customApplyValue,
    setCustomValue: setCustomApplyValue,
  } = useAdvanceBoolean<string>(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [earnMultiplierLoading, setEarnMultiplierLoading] = useState(false);

  const {
    data: performanceRulesList,
    isLoading,
    status,
  } = useGetPerformanceRulesList(handleRequest, tableInfo);
  const { data: availableStatusOption } = useGetPerformanceRuleAvailableStatus(handleRequest);
  const { data: approvalStatusOption } = useGetPerformanceRuleApprovalStatus(handleRequest);
  const { data: earnedStatusOption } = useGetPerformanceRuleEarnedStatus(handleRequest);
  const [remark, setRemark] = useState<string>();
  const [remarksInitialData, setRemarksInitialData] = useState<any>(
    InitialValuesForm.remarksInitialValues
  );
  const [checkedValues, setCheckedValues] = useState<any>([]);

  const queryClient = useQueryClient();

  const actions = [
    {
      action: 'View',
      onClick: (id: string, rowData: any) => {
        setModalStatus('View');
        setRowData(rowData);
        openModal();
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string, rowData: any) => {
        setModalStatus('Edit');
        setRowData(rowData);
        openModal();
      },
    },
    {
      action: 'Available',
      onClick: (id: string, rowData: any) => {
        enableDisablePerformanceRule(id, true);
      },
    },
    {
      action: 'Unavailable',
      onClick: (id: string, rowData: any) => {
        enableDisablePerformanceRule(id, false);
      },
    },
    {
      action: 'Apply Earn Multiplier',
      onClick: (id: string, rowData: any) => {
        openConfirmApplyModal();
        setCustomApplyValue([id]);
      },
    },
    {
      action: 'View Comments',
      onClick: async (id: string, rowData: Record<string, string>) => {
        setModalStatus('View Remarks');
        setRemark(rowData?.remarks);
        setRemarksInitialData({ ...remarksInitialData, remarks: rowData?.remarks });
        openConfirmModal();
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      onClick: (id: string) => {
        setModalStatus('Delete');
        openConfirmModal();
        setCustomValue(id);
      },
    },
  ];

  const handleChecked = (values: string[]): void => {
    setCheckedValues(values);
  };

  const enableDisablePerformanceRule = (id: string, status: boolean): void => {
    const payload = {
      id: id,
      isAvailable: status,
    };
    handleRequest(
      PerformanceRuleService.postApiAwardApiAppPerformanceRuleTogglePerformanceRuleAvailableStatus(
        payload
      )
    )
      .then((data: any) => {
        if (data != null) {
          toast.success(
            <ToastComponent label={'Success'} message={'Performance Rule Availability Changed.'} />
          );
          void queryClient.invalidateQueries('getPerformanceRulesList');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const onDeleteClick = (values: Record<string, any>): void => {
    deletePerformanceRule(customValue);
  };

  const deletePerformanceRule = (id: string): void => {
    setModalLoading(true);
    handleRequest(PerformanceRuleService.deleteApiAwardApiAppPerformanceRulePerformanceRule(id))
      .then((data: any) => {
        if (data != null) {
          toast.success(<ToastComponent label={'Success'} message={'Performance rule deleted.'} />);
          void queryClient.invalidateQueries('getPerformanceRulesList');
          setModalLoading(false);
          closeConfirmModal();
        }
      })
      .catch(() => {
        setModalLoading(false);
        closeConfirmModal();
      })
      .finally(() => {
        setModalLoading(false);
        closeConfirmModal();
      });
  };

  const onApplyMultiplier = (values: Record<string, any>): void => {
    onClickApplyEarnedMultiplier(customApplyValue);
  };

  const onClickApplyEarnedMultiplier = (checkedValues: string[]) => {
    setEarnMultiplierLoading(true);
    handleRequest(
      PerformanceEvaluationService.postApiAwardApiAppPerformanceEvaluationApplyEarnedMultiplier(
        checkedValues
      )
    )
      .then((data: any) => {
        if (data != null) {
          toast.success(
            <ToastComponent label={'Success'} message={'Earned Multiplier applied.'} />
          );
          void queryClient.invalidateQueries('getPerformanceRulesList');
          setEarnMultiplierLoading(false);
          closeConfirmApplyModal();
        }
      })
      .catch(() => {
        setEarnMultiplierLoading(false);
        closeConfirmApplyModal();
      })
      .finally(() => {
        setEarnMultiplierLoading(false);
        closeConfirmApplyModal();
      });
  };

  return (
    <>
      <PageHeader>
        <Heading variant='h3' title='Performance Rules' />
        <Heading variant='body-s' title=' List of performance rule for your company.' />
      </PageHeader>
      <div className='grid lg:grid-cols-12 gap-4 lg:gap-1.5 items-start'>
        <div className='lg:col-span-3'>
          <Search
            placeholder='Search'
            searchValue={searchQuery}
            onSearch={(value: string) => {
              setSearchQuery(value);
            }}
            setTableInfo={setTableInfo}
            tableInfo={tableInfo}
          />
        </div>
        <div className='lg:col-span-3 flex gap-4'>
          <Filter setOpen={setOpen} open={open} className='block'>
            <Heading variant='body-xs' title={'Filter By:'} className={'filter-label'} />
            <div className='grid sm:grid-cols-2 gap-x-5'>
              <CustomDatePicker
                name='createdFromDate'
                placeholder='Evaluation Period From'
                onChange={(value: string) => {
                  setTableInfo({
                    ...tableInfo,
                    createdFromDate: !isNull(value) ? moment(value).format(DATE_FORMAT) : '',
                    createdToDate: '',
                  });
                }}
                required
                arrValue={tableInfo.createdFromDate}
                isFormik={false}
              />
              <CustomDatePicker
                name='createdToDate'
                placeholder='Evaluation Period To'
                onChange={(value: string) => {
                  setTableInfo({
                    ...tableInfo,
                    createdToDate: !isNull(value) ? moment(value).format(DATE_FORMAT) : '',
                  });
                }}
                required
                min={new Date(tableInfo.createdFromDate ?? '')}
                // max={new Date()}
                arrValue={tableInfo.createdToDate}
                isFormik={false}
              />
              <FormSelect
                type='text'
                name='availableStatus'
                placeholder='Available Status'
                onChange={(value: number) => {
                  setTableInfo({ ...tableInfo, ruleStatusId: value });
                }}
                options={availableStatusOption ?? []}
                values={tableInfo.ruleStatusId}
                arrValue={tableInfo.ruleStatusId}
                containerClassName='formGroup'
                isFormik={false}
              />
              <FormSelect
                type='text'
                name='earnedStatus'
                placeholder='Earned Status'
                onChange={(value: number) => {
                  setTableInfo({ ...tableInfo, earnedStatus: value });
                }}
                options={earnedStatusOption ?? []}
                values={tableInfo.earnedStatus}
                arrValue={tableInfo.earnedStatus}
                containerClassName='formGroup'
                isFormik={false}
              />
              <FormSelect
                type='text'
                name='approvalStatus'
                placeholder='Approval Status'
                onChange={(value: number[]) => {
                  setTableInfo({ ...tableInfo, approvalStatusId: value });
                }}
                options={approvalStatusOption ?? []}
                values={tableInfo.approvalStatusId}
                arrValue={tableInfo.approvalStatusId}
                containerClassName='formGroup mb-0'
                multiple={true}
                isFormik={false}
              />
            </div>
          </Filter>
          <div>
            <ResetFilterWrapper
              onClick={() => {
                setTableInfo(InitialValuesForm.tableParams);
                setSearchQuery('');
              }}>
              <span className='material-symbols-outlined'>settings_backup_restore</span>
              <Heading variant='body-s' title='Reset Filter' />
            </ResetFilterWrapper>
          </div>
        </div>
        <SettingRoleButton className='lg:col-span-6 flex justify-end'>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add Performance Rule'}
            onClick={() => {
              setModalStatus('Add');
              setInitialData(initialValues);
              setRowData([]);

              openModal();
            }}
            icon='add_circle'
          />
        </SettingRoleButton>
      </div>

      <InvitationBlock>
        {!isEmpty(checkedValues) && (
          <>
            <Button
              variant={'secondary-outline'}
              title={'Apply Earn Multiplier'}
              icon={'add'}
              onClick={async () => {
                openConfirmApplyModal();
                setCustomApplyValue(checkedValues);
              }}
            />

            <Heading
              variant='body-xs'
              title={`${String(checkedValues.length ?? '')} Rule${
                checkedValues.length > 1 ? 's' : ''
              } Selected`}
            />
          </>
        )}
      </InvitationBlock>
      <Table
        columns={PerformanceRuleHeader(actions)}
        data={performanceRulesList?.items ?? []}
        totalCounts={performanceRulesList?.totalCount ?? 50}
        loading={status}
        showPagination
        showCheckBox={true}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        isTableFor={'RsuAwards'}
        onChecked={handleChecked}
        checkedValues={checkedValues}
        showColumn={false}
        tableColumnList={[]}
        selectedColumnList={[]}
        handleTableColumn={(value: string) => {
          console.log('value', value);
        }}
      />

      {isConfirmApplyOpen && (
        <AlertPopup
          isShow={isConfirmApplyOpen}
          closeModal={closeConfirmApplyModal}
          modalStatus={modalStatus}
          customTitle={'Are you sure want to apply this earned multiplier?'}
          // subTitle='Once the multiplier is applied the rule is not available for update.'
          handleSubmit={onApplyMultiplier}
          loading={earnMultiplierLoading}
        />
      )}
      {isConfirmOpen && (
        <AlertPopup
          isShow={isConfirmOpen}
          closeModal={closeConfirmModal}
          modalStatus={modalStatus}
          title={'this Performance Rule'}
          handleSubmit={onDeleteClick}
          setRemark={setRemark}
          remark={remark}
          loading={modalLoading}
        />
      )}

      <AnimationWrapper>
        {isModalOpen && (
          <PerformanceRulesModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            modalStatus={modalStatus}
            initialData={initialData}
            setModalStatus={setModalStatus}
            rowData={rowData}
          />
        )}
      </AnimationWrapper>
    </>
  );
};

export default PerformanceRules;
