import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils/style';

interface DisableType {
  disable?: boolean;
}

export const Dot = styled.span<DisableType>`
  position: relative;
  span {
    cursor: pointer;
    ${(props) => {
      if (props.disable) {
        return css`
          color: #dadadafa;
        `;
      } else {
        return css`
          color: ${COLORS.Purple};
        `;
      }
    }}

    font-size: ${rem(28)};
    opacity: 0.7;
    font-weight: 800;
    transition: all 0.2s linear;
    &.active {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }
`;
export const ButtonMenu = styled.span`
  position: relative;
`;

export const DropdownList = styled.div`
  .dropdown {
    background-color: ${COLORS.White};

    box-shadow: ${rem(0)} ${rem(2)} ${rem(20)} rgba(109, 109, 109, 0.15);
    border-radius: ${rem(6)};
    z-index: 5;

    ul {
      list-style: none;
      position: relative;
      padding: 10px 0;
    }
  }

  li {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: ${rem(14)};
    color: ${COLORS.Black};
    cursor: pointer;
    min-width: 140px;
    padding: 0.8rem 0.8rem;
    margin: 0px 10px 0 10px;
    padding: 0.8rem 0.8rem;
    text-align: left;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${COLORS.OffWhite};
      font-weight: 600;
      border-radius: ${rem(4)};
      cursor: pointer;
    }
    &.loader-wrapper {
      padding: 10px 5px;
    }

    &.loader-wrapper:hover {
      background-color: ${COLORS.White};
    }
  }
`;

export const DotList = styled.div`
  position: absolute;
  background-color: ${COLORS.White};
  bottom: -${rem(25)};
  right: ${rem(15)};
  box-shadow: ${rem(0)} ${rem(2)} ${rem(20)} rgba(109, 109, 109, 0.15);
  border-radius: ${rem(6)};
  z-index: 5;
  transform: translateX(-6%);

  ul {
    list-style: none;
    position: relative;
    padding: 10px 0;
  }

  li {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: ${rem(14)};
    color: ${COLORS.Black};
    cursor: pointer;
    min-width: 140px;
    /* margin: 4px 2px; */
    padding: 0.8rem 0.8rem;
    margin: 0px 10px 0 10px;
    padding: 0.8rem 0.8rem;
    text-align: left;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${COLORS.OffWhite};
      font-weight: 600;
      border-radius: ${rem(4)};
      cursor: pointer;
    }
    &.loader-wrapper {
      padding: 10px 5px;
    }

    &.loader-wrapper:hover {
      background-color: ${COLORS.White};
    }
  }
`;

export const ListClose = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  width: ${rem(20)};
  height: ${rem(20)};
  z-index: 99;
  position: absolute;
  top: -${rem(10)};
  right: -${rem(10)};
  background-color: ${COLORS.White};
  box-shadow: 0px 2px 20px rgba(109, 109, 109, 0.15);
  padding: 6px;

  span {
    font-size: 10px;
    color: ${COLORS.NewPurple} !important;
    line-height: 8px;
  }
`;
