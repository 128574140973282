import { type FormikErrors, type FormikTouched, type FormikValues } from 'formik';
import Lozenge from '../../../../components/lozenge';
import CustomCheckBox from '../../../../components/radio-button/customCheckBox';
import Heading from '../../../../components/typography';
import { COLORS } from '../../../../constants/style/color';
import useGetTradeaAllParticipant from '../../../../hooks/get/useGetTradeaAllParticipant';
import { type OptionType, type UserType } from '../../../../types/common';
import {
  ApproverSelectContainer,
  ApproverSelectGroup,
} from '../../approval-config/approvalConfig.styled';
import CustomDropdown from '../../../../components/custom-dropdown';
import { isEmpty } from 'lodash';

interface IndividualProps {
  errors: FormikErrors<any> | any;
  touched: FormikTouched<any> | any;
  values: FormikValues;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  setFieldValue: any;
  handleChange: any;
}

const IndividualOptions = ({
  errors,
  touched,
  values,
  handleRequest,
  handleChange,
  setFieldValue,
}: IndividualProps): JSX.Element => {
  const { data: allParticipantOptions, isLoading } = useGetTradeaAllParticipant(handleRequest);

  const handleUser = (userData: UserType): void => {
    const userInfo = values?.participantIds;
    !(userInfo.includes(userData.value) === true)
      ? userInfo.push(userData.value)
      : userInfo.splice(userInfo.indexOf(userData.value), 1);
    setFieldValue('participantIds', [...userInfo]);
  };

  return (
    <>
      <div className='col-span-8'>
        <Heading variant='h5' title='Add Individual Participant' className='individual-title' />
        <CustomDropdown
          title={'Select Individual Participant'}
          placeholder='Select'
          data={allParticipantOptions}
          loading={isLoading}
          handleUser={(e) => {
            handleUser(e);
          }}
          checkedList={values?.participantIds ?? []}
          disabled={!!(values?.selectAll as boolean)}
          errorMessage={errors?.participantIds}
          touchedValue={touched?.participantIds}
          cssStyles={{
            contentHeight: '200px',
            height: '320px',
            width: '420px',
            disabledContainer: !!(values?.selectAll as boolean),
          }}
        />
        <CustomCheckBox
          name='selectAll'
          onChange={(e: any) => {
            setFieldValue(
              'participantIds',
              e?.target.checked === true
                ? allParticipantOptions?.map(
                  (allParticipantOptions: OptionType) => allParticipantOptions.value
                )
                : []
            );
            handleChange(e);
          }}
          errors={errors}
          touched={touched}
          className={'individual-check'}
          labelClassName={'whitespace-normal'}
          disabled={isEmpty(allParticipantOptions)}
          checked={values?.selectAll}
          id='acceptCheck'
          label={
            <Heading
              variant='body-xs'
              className='mt-1'
              color={COLORS.Gray}
              title={'Select all employees'}
            />
          }
          required
        />
      </div>
      <div className='col-span-12'>
        <div className='individual-selected'>
          <ApproverSelectContainer>
            <Heading
              variant='body'
              className='individual-heading mt-1'
              color={COLORS.Gray}
              title={'Selected Individual Participants'}
            />
            <ApproverSelectGroup>
              {!(values?.selectAll as boolean) &&
                allParticipantOptions
                  ?.filter((option) => values?.participantIds.includes(option.value))
                  ?.map((mapped) => (
                    <div>
                      <Lozenge
                        variant='default'
                        title={mapped?.label}
                        handleIconClick={() => {
                          const filterData = values?.participantIds.filter(
                            (user: string) => user !== mapped.value
                          );
                          setFieldValue('participantIds', [...filterData]);
                        }}
                      />
                    </div>
                  ))}
            </ApproverSelectGroup>
          </ApproverSelectContainer>
        </div>
      </div>
    </>
  );
};

export default IndividualOptions;
