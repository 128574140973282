/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportDto_Wealthlane_ReportService_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportDto_Wealthlane_ReportService_Application_Contracts';
import type { Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto } from '../models/Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto';
import type { Wealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportRequestDto } from '../models/Wealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RetirementEligibilityReportService {

    /**
     * @param requestBody 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportDto_Wealthlane_ReportService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiReportApiAppRetirementEligibilityReportGetRetirementEligibilityList(
requestBody?: Wealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportRequestDto,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportDto_Wealthlane_ReportService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/retirement-eligibility-report/get-retirement-eligibility-list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppRetirementEligibilityReportGetRetirementEligibilityReport(
requestBody?: Wealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportRequestDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/retirement-eligibility-report/get-retirement-eligibility-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
