import { type UseQueryResult, useQuery } from 'react-query';

import {
  OptionAwardService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto,
} from '../../services/wealthlane-award-services';

const useGetOptionAwardOverview = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto | undefined,
  unknown
> => {
  const optionAwardDetails = useQuery(['optionAwardDetails', id], async () => {
    if (id) {
      return await handleRequest(
        OptionAwardService.getApiAwardApiAppOptionAwardOptionAwardOverview(id)
      );
    }
  });

  return optionAwardDetails;
};

export default useGetOptionAwardOverview;
