import moment from 'moment';
import { ActionButton } from '../../components/vertical-dot/popper';
import { DATE_FORMAT } from '../common';
import { TableBodyCellColumn } from '../style/table.styled';

export const ParticipantBroadcastHeader = (actions: any): any[] => {
  const getActions = (rowData: Record<string, string>): any => {
    const newActions = actions;
    // if (rowData.status === 'Pending') {
    //   newActions = actions.filter((item: any) => item.action !== 'View Comments');
    // } else if (rowData.status === 'Approved') {
    //   newActions = actions.filter((item: any) => ['Edit', 'View'].includes(item.action));
    // } else if (rowData.status === 'Rejected') {
    //   newActions = actions.filter((item: any) => ['View Comments', 'View'].includes(item.action));
    // } else {
    //   newActions = actions;
    // }

    // if (rowData.isDefaultAdmin) {
    //   newActions = actions.filter((item: any) => ['View'].includes(item.action));
    // }
    return newActions;
  };

  return [
    {
      Header: 'Title',
      accessor: 'title',
      width: 200,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>
              {row.original.title != null && row.original.title !== '' ? row.original.title : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Message',
      accessor: 'message',
      width: 200,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.message != null && row.original.message !== ''
              ? row.original.message
              : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Time Duration',
      accessor: 'timeDuration',
      width: 200,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {`${moment(row.original.startDate).format(DATE_FORMAT)} - ${moment(
              row.original.endDate
            ).format(DATE_FORMAT)}` ?? '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={getActions(row.original)}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
