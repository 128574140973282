import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { getRgbaValue, rem } from '../../utils/style';

interface PDFDivType {
  top: string;
  left: string;
  width?: string;
  letterSpacing?: string;
}

interface infoType {
  information?: boolean;
}

export const FilterBlock = styled.div`
  margin: ${rem(25)} 0;
`;
export const InputField = styled.div`
  display: flex;
  gap: ${rem(9)};

  .spinnerContainer {
    width: 18px;
    height: 18px;
  }

  @media (min-width: 512px) {
    flex-direction: row;
  }

  .input-file-container {
    position: relative;
    font-weight: 500;
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    border: ${rem(1)} solid ${getRgbaValue(`${COLORS.Purple}`, 1)};

    position: relative;
    border-radius: ${rem(6)};
    padding: ${rem(6)} ${rem(8)};
    transition: all 300ms ease;

    color: ${COLORS.Purple};

    &:not(:disabled):hover {
      box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${getRgbaValue(`${COLORS.Iris}`, 0.24)};
    }

    &:not(:disabled).active {
      box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${getRgbaValue(`${COLORS.Iris}`, 0.44)};
    }

    &:disabled {
      border: 1px solid ${getRgbaValue(`${COLORS.Purple}`, 0.2)};
      color: ${getRgbaValue(`${COLORS.Purple}`, 0.5)};
    }
  }

  .input-file-trigger {
    display: block;
    font-weight: 500;
  }

  .input-file {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }
`;

export const ImportWrapper = styled.div<infoType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${(props) => (props?.information ? '' : '100%')};
  width: 100%;

  .upload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${rem(0)};
  }

  .success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${rem(0)};
    top: 0;

    h3 {
      padding: ${rem(24)} 0 ${rem(12)} 0;
    }
  }

  .info-wrapper {
    width: 100%;
    .info-detail {
      padding: ${rem(24)} 0;
      justify-content: left;
      align-items: flex-start;
      ul {
        padding-left: ${rem(20)};
        list-style: disc;
      }

      .marginBottom {
        margin-bottom: ${rem(4)};
      }

      .info-list {
        padding-left: ${rem(3)};
        text-align: left;
      }
    }
  }

  .error-wrapper {
    width: 100%;
    .error-detail {
      padding: ${rem(24)} 0;
      ul {
        padding-left: ${rem(20)};
        list-style: disc;
      }

      .marginBottom {
        margin-bottom: ${rem(4)};
      }

      .error-list {
        padding-left: ${rem(3)};
        color: ${COLORS.Red};
      }
    }

    .import-section {
      padding: ${rem(24)} 0;
      border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};

      .input-file-container {
        margin-top: ${rem(12)};
      }
    }
  }
  h2 {
    padding-bottom: 8px;
  }

  h5 {
    padding-bottom: 40px;
  }
`;

export const ParticipantMain = styled.div`
  display: flex;
  min-height: 70vh;
  padding: ${rem(16)} ${rem(32)};
`;

export const DownloadWrapper = styled.span`
  display: flex;
  height: 20px;
  width: 30px;
  padding-right: 10px;
  align-self: center;
  color: ${COLORS.Secondary};
`;

export const ParticipantsDetail = styled.div`
  /* padding-top: ${rem(30)}; */
  .title {
    margin-bottom: 18px;
    color: ${COLORS.Gray};
  }
  .rowDetail {
    /* border-bottom: 1px solid ${COLORS.CulturedWhite}; */
  }
`;

export const ParticipantDivider = styled.div`
  /* margin-top: ${rem(4)}; */
  margin-bottom: ${rem(18)};
  border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};
`;

export const ParticipantViewDivider = styled.div`
  margin-bottom: ${rem(18)};
  border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};
`;

export const SingleDetailBlock = styled.div`
  padding-bottom: ${rem(18)};
  display: flex;
  width: 100%;
  align-items: center;

  .material-symbols-outlined,
  .material-symbols-sharp {
    font-size: ${rem(16)};
    color: ${COLORS.SpanishGray};
    margin-left: ${rem(5)};
  }

  .list {
    border-bottom: 1px solid ${COLORS.CulturedWhite};
    gap: ${rem(50)};
    align-items: flex-start;

    &:last-child {
      border-bottom: none;
    }

    &-icon {
      display: flex;
      align-items: center;
      gap: ${rem(5)};
      padding: ${rem(16)} 0;

      .material-symbols-outlined {
        font-size: ${rem(20)};
        color: ${COLORS.SpanishGray};
      }
    }

    &-content {
      padding: ${rem(16)} 0;

      .profile-date {
        margin-top: ${rem(12)};
      }

      .profile-url {
        font-size: ${rem(14)};
        line-height: ${rem(18)};
        color: ${COLORS.Iris};
        text-decoration: underline;

        &:hover {
          color: ${COLORS.Blue};
        }
      }
    }
  }

  .material-symbols-outlined-success {
    color: ${COLORS.Green};
  }

  .material-symbols-outlined-default {
    color: ${COLORS.SpanishGray};
  }

  .material-symbols-outlined-error {
    color: ${COLORS.Red};
  }

  .material-symbols-outlined-success,
  .material-symbols-outlined-default,
  .material-symbols-outlined-error {
    font-size: ${rem(20)};
  }

  .profile-employeeWrapper {
    display: flex;
    flex-direction: column;
    gap: ${rem(5)};
    padding: ${rem(18)} ${rem(0)};
    width: 100%;
  }

  .profile-mainTitle {
    font-weight: 500;
    font-size: ${rem(16)};
    color: ${COLORS.Black};
  }
  .profile-subTitle {
    font-weight: 400;
    font-size: ${rem(14)};

    color: ${COLORS.Gray};
  }

  > span {
    font-size: 24px;
    color: ${COLORS.SpanishGray};
    flex-direction: column;
    margin-right: ${rem(10)};
  }
  .detailLabel {
    margin-bottom: 4px;
    color: ${COLORS.Gray};
  }
`;

export const PDFWrapper = styled.div`
  /* position: 'relative';
  width: '445px'; */
`;

export const PDFDiv = styled.div<PDFDivType>`
  margin: 0;
  font-size: ${rem(6)};
  line-height: ${rem(6)};
  color: ${COLORS.Black};
  font-weight: 500;
  background-color: transparent;
  position: absolute;

  top: ${(props: PDFDivType) => props.top ?? '0px'};
  left: ${(props: PDFDivType) => props.left ?? '0px'};
  width: ${(props: PDFDivType) => props.width ?? '100px'};
  letter-spacing: ${(props: PDFDivType) => props.letterSpacing ?? 'auto'};

  img {
    color: ${COLORS.Black};

    &.sign {
      height: ${rem(10)};
    }
  }
`;
export const GovernmentUploadedDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${rem(8)};
  row-gap: ${rem(8)};
  margin-top: ${rem(24)};
`;

// terminate modal
export const TerminatePurpleContainer = styled.div`
  background: ${COLORS.LightPurple};
  border: 1px solid ${COLORS.PurpleBorder};
  border-radius: 6px;
  padding: ${rem(18)} ${rem(20)};
  margin-bottom: ${rem(32)};
`;
export const OverrideAccordingContainer = styled.div`
  background: ${COLORS.Grey400Transparent};
  /* opacity: 0.5; */
  border-bottom: 1px solid ${COLORS.LightGray};
  border-radius: 6px 6px 0px 0px;
  padding: ${rem(13)} ${rem(15)};
  font-weight: 500;
`;
export const OverrideAccordingValue = styled.div`
  height: 370px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  background: ${COLORS.Grey100Transparent};
  border-radius: 0px 0px 6px 6px;
  padding: ${rem(21)} ${rem(32)};
`;
export const OverrideHeaders = styled.div`
  padding-bottom: ${rem(15)};
  border-bottom: 1px solid ${COLORS.LightGray};
`;
export const OverrideValue = styled.div`
  padding: ${rem(21)} ${rem(0)} ${rem(0)} ${rem(0)};
  border-bottom: 1px solid ${COLORS.LightGray};
  &:last-child {
    border-bottom: 0;
  }
`;
