import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFilteredPermissionData } from '..';
import { ComponentsNames } from '../../constants/innerRoutesList';
import { selectAuthRoutesList } from '../../pages/configuration/routeSlice';
import { type ModalStatusType } from '../../types/common';

interface Props {
  type?: ModalStatusType;
  urlName?: string;
  children: React.ReactNode;
}

// Checks Permission
const CheckPermissions = ({ type, urlName, children }: Props): JSX.Element => {
  const location = useLocation();
  const url = location.pathname.split('/');

  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {}
  );

  const getUrlName =
    urlName != null && urlName !== ''
      ? ComponentsNames?.find((item) => item.url === urlName)?.name
      : ComponentsNames?.find((item) => item.url === url[url.length - 1])?.name;

  const hasPermission =
    getUrlName != null &&
    getUrlName !== '' &&
    nonFieldLevelPermissionsGroups[getUrlName]?.some((item: any) => item.text === type);

  return <>{type != null ? Boolean(hasPermission) && children : children}</>;
};

export default CheckPermissions;
