import { isEmpty } from 'lodash';
import { ActionButton } from '../../components/vertical-dot/popper';

export const DepartmentHeader = (action: any): any[] => {
  return [
    {
      Header: 'Department Names',
      accessor: 'name',
      width: 300,
      Cell: ({ row }: any) => {
        return <>{!isEmpty(row.original.name) ? row.original.name : '-'}</>;
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 300,
      sortable: false,
      Cell: ({ row }: any) => {
        return <>{!isEmpty(row.original.description) ? row.original.description : '-'}</>;
      },
    },
    {
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={action}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
