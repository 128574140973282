import { type UseQueryResult, useQuery } from 'react-query';
import {
  ReleaseService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto,
} from '../../services/wealthlane-award-services';
import { convertNumberWithCommas } from '../../utils/global';

const useGetTransactionIncludeTax = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  setInitialValue?: any
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto | undefined,
  unknown
> => {
  const transactionIncludeTax = useQuery(
    ['getTransactionIncludeTax', id],
    async () => {
      if (id) {
        return await handleRequest(
          ReleaseService.getApiAwardApiAppReleaseTaxDetailsByReleaseItemId(id)
        );
      }
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          const formattedTaxDetails = {
            ...response,
            taxDetails: response?.taxDetails?.map((taxDetail: any) => ({
              ...taxDetail,
              taxRate: convertNumberWithCommas(Number(taxDetail.taxRate).toFixed(2), true),
              taxAmount: convertNumberWithCommas(Number(taxDetail.taxAmount).toFixed(2), true),
            })),
          };

          setInitialValue(formattedTaxDetails);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );

  return transactionIncludeTax;
};

export default useGetTransactionIncludeTax;
