import { type UseQueryResult, useQuery } from 'react-query';
import { OptionAwardService } from '../../services/wealthlane-award-services';

const useGetOptionAwardEmployeeStatus = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const optionAwardEmployeeStatus = useQuery(
    ['getOptionAwardEmployeeStatus'],
    async () => {
      return await handleRequest(OptionAwardService.getApiAwardApiAppOptionAwardEmployeeStatuses());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return optionAwardEmployeeStatus;
};

export default useGetOptionAwardEmployeeStatus;
