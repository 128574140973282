import { useQuery } from 'react-query';
import { CustomerCompanyService } from '../../services/wealthlane-customer-services';

const useGetCompanyProfileDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const companyProfileDetailsData = useQuery(
    ['companyProfileDetails'],
    async () => {
      return await handleRequest(
        CustomerCompanyService.getApiCustomerApiAppCustomerCompanyCompanyDetailForView()
      );
    },
    {
      cacheTime: 1000 * 60 * 5,
      select: (data) => {
        if (data != null) {
          return {
            ...data,
            companyAddressList:
              data?.companyAddressList != null && data?.companyAddressList?.length > 0
                ? data.companyAddressList
                : [
                    {
                      countryId: null,
                      countryText: '',
                      addressLine1: '',
                      addressLine2: '',
                      stateId: null,
                      cityText: '',
                      stateText: '',
                      zipCode: '',
                      taxId: null,
                      id: null,
                    },
                  ],
          };
        }
      },
    }
  );

  return companyProfileDetailsData;
};

export default useGetCompanyProfileDetails;
