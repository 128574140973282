import { ImagePreview, ImagePreviewWrapper, UploadWrapper } from '../input-text/input.styled';
import InputFile from './inputFile';
import { type ModalStatusType } from '../../types/common';
import { useEffect, useState } from 'react';
import { ErrorMessageWrapper } from '../../constants/style/common.styled';
import { ErrorMessage } from 'formik';
import ProgressBarCustom from '../progress-bar/progressBar';

interface ImageViewerType {
  setFieldValue: any;
  setFieldTouched: any;
  modalStatus: ModalStatusType;
  values: any;
  errors: any;
  touched: any;
  name: string;
  listingName: string;
  loading: boolean;
  removedId: string[];
  handleImageOpen: any;
  accept?: string;
  setRemovedId: (value: string[]) => void;
  isBase64?: boolean;
}

const ImageViewer = ({
  setFieldValue,
  setFieldTouched,
  modalStatus,
  values,
  errors,
  touched,
  name,
  listingName,
  loading,
  removedId,
  handleImageOpen,
  accept,
  setRemovedId,
  isBase64 = false,
}: ImageViewerType): JSX.Element => {
  const [uploadFiles, setUploadFiles] = useState<any>([]);

  const [progressLoading, setProgressLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const loadingDuration = 500; // 3 seconds
  const [base64, setBase64] = useState<any>([]);

  useEffect(() => {
    if (progressLoading) {
      const loadingTimeout = setTimeout(() => {
        if (progress >= 100) return;
        setProgress(progress + 1);
      }, loadingDuration / 100);
      if (progress === 100) {
        setProgressLoading(false);
        setProgress(0);
        setUploadFiles(uploadFiles.map((item: any) => ({ ...item, loading: false })));
      }
      return () => {
        clearTimeout(loadingTimeout);
      };
    }
  }, [progress, progressLoading]);

  const convertToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result;
      let index = result && (result as any)?.indexOf('base64') + 7;
      let base64String = result?.slice(index);

      setBase64((prevItems: any) => [
        ...base64,
        { fileName: file?.name, document: base64String, fileSize: file?.size, type: file?.type },
      ]);
      // (prevItems) => [...prevItems, newItem];
      // setBase64({ ...base64, name: file?.name, base64: base64String });
    };
    reader.onerror = (error) => {
      console.error('Error converting file to base64:', error);
    };
  };

  const handleSetFiles = (event: any): void => {
    setProgressLoading(true);
    const checkFile = event.target.files;
    const fileNames = Object.keys(checkFile)?.map((index) => checkFile[index].lastModified);
    const checkFiles = uploadFiles?.find((item: { file: File; id: number; src: string }) => {
      return fileNames.includes(item.id);
    });
    convertToBase64(event.target.files[0]);
    if (checkFiles == null || checkFiles === false) {
      const arrFiles = Array.from(event.target.files);
      const multipleFiles = arrFiles?.map((file: any, index: number) => {
        const src = window.URL.createObjectURL(file);
        return { file, id: file.lastModified, src, loading: !false };
      });
      setUploadFiles((prevImages: any) => prevImages.concat(multipleFiles));
      event.target.value = null;
    }
  };

  useEffect(() => {
    const filterUploadFiles = uploadFiles?.map(
      (item: { file: File; id: number; src: string }) => item.file
    );

    if (isBase64) {
      setFieldValue(name, base64);
    } else {
      setFieldValue(name, filterUploadFiles);
    }
  }, [uploadFiles]);

  return (
    <>
      <UploadWrapper
        className={`${Boolean(errors[name]) && Boolean(touched[name]) ? 'is-invalid' : ''}`}>
        {modalStatus === 'View'
          ? Boolean(values[listingName]) &&
            values[listingName].map((value: any, key: number) => (
              <ImagePreview
                key={key}
                className='cursor-pointer'
                onClick={() => handleImageOpen(value.file.uniqueName)}>
                <div className='image-wrapper' title={value.file.name}>
                  <>
                    <span className='material-symbols-outlined image-icon'>draft</span>
                    <span className='image-filename'>{value.file.name}</span>
                  </>
                </div>

                {/* <div>
                <div
                  className='image-cross'
                  onClick={() => {
                    const removeFile = values[listingName]?.filter(
                      (item: any) => item.id !== value.id
                    );
                    setFieldValue(listingName, removeFile);
                    setRemovedId(removedId.concat(value.id));
                  }}>
                  <span className='material-symbols-outlined image-delete'>delete</span>
                </div>
              </div> */}
              </ImagePreview>
              // <ImageViewWrapper className='mt-2'>
              //   <div
              //     className='view-wrapper cursor-pointer'
              //     onClick={() => !loading && handleImageOpen(value.file.uniqueName)}>
              //     <p className='text-xs'>{value.file.name}</p>
              //     <div className='flex items-center gap-4'>
              //       {modalStatus !== 'View' && (
              //         <span
              //           className='cursor-pointer'
              //           onClick={() => {
              //             const removeFile = values[listingName]?.filter(
              //               (item: any) => item.id !== value.id
              //             );
              //             setFieldValue(listingName, removeFile);
              //             setRemovedId(removedId.concat(value.id));
              //           }}>
              //           <Cross />
              //         </span>
              //       )}
              //       <div
              //         className='cursor-pointer w-3.5'
              //         onClick={() => !loading && handleImageOpen(value.file.uniqueName)}>
              //         <ViewIcon />
              //       </div>
              //     </div>
              //   </div>
              // </ImageViewWrapper>
            ))
          : modalStatus === 'Edit'
          ? values[listingName]?.map((value: any, key: number) => (
              <ImagePreview key={key}>
                <div
                  className='image-wrapper cursor-pointer'
                  title={value.file.name}
                  // className='cursor-pointer'
                  onClick={() => handleImageOpen(value.file.uniqueName)}>
                  <>
                    <span className='material-symbols-outlined image-icon'>draft</span>
                    <span className='image-filename'>{value.file.name}</span>
                  </>
                </div>

                <div>
                  <div
                    className='image-cross'
                    onClick={() => {
                      const removeFile = values[listingName]?.filter(
                        (item: any) => item.id !== value.id
                      );
                      setFieldValue(listingName, removeFile);
                      setRemovedId(removedId.concat(value.id));
                    }}>
                    <span className='material-symbols-outlined image-delete'>delete</span>
                  </div>
                </div>
              </ImagePreview>
            ))
          : ''}
        <ImagePreviewWrapper>
          {uploadFiles?.map((value: any, key: number) => (
            <ImagePreview key={key}>
              <div className='image-wrapper' title={value.file.name}>
                {Boolean(value.src) && (
                  <>
                    {value.loading != null && value.loading !== false ? (
                      <ProgressBarCustom progress={progress} trackWidth={5} indicatorWidth={5} />
                    ) : (
                      <span className='material-symbols-outlined image-icon'>draft</span>
                    )}
                    <span className='image-filename'>{value.file.name}</span>
                  </>
                )}
              </div>
              <div>
                {modalStatus !== 'View' && (
                  <div
                    className='image-cross'
                    onClick={() => {
                      const removeNameFile = uploadFiles?.filter(
                        (item: any) => item.id !== value.id
                      );

                      const filteredA = base64.filter((itemA: any) =>
                        removeNameFile.some((itemB: any) => itemB.file.name === itemA.fileName)
                      );

                      setUploadFiles(removeNameFile);
                      setFieldValue(name, removeNameFile);
                      setBase64(filteredA);
                      setTimeout(() => setFieldTouched(name, true));
                    }}>
                    <span className='material-symbols-outlined image-delete'>delete</span>
                  </div>
                )}
              </div>
            </ImagePreview>
          ))}
        </ImagePreviewWrapper>
        {modalStatus !== 'View' && (
          <InputFile
            name={name}
            onChange={(event: any) => {
              handleSetFiles(event);
            }}
            errors={null}
            accept={accept}
            touched={touched}
          />
        )}
        <ErrorMessageWrapper className='mt-1'>
          <ErrorMessage name={name} component='div' className='field-error-message mt-1' />
        </ErrorMessageWrapper>
      </UploadWrapper>
    </>
  );
};

export default ImageViewer;
