/* eslint-disable camelcase */
import React, { useState } from 'react';
import AnimationWrapper from '../../../../components/animation/animationWrapper';
import Button from '../../../../components/button';
import { SkeletonCircle, SkeletonLine } from '../../../../components/skeleton';
import Heading from '../../../../components/typography';
import {
  useBoolean,
  useGetCompanyKeyContacts,
  useGetCompanyKeyContactTypes,
} from '../../../../hooks';
import { type Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto } from '../../../../services/wealthlane-customer-services';
import { getProfileListData, type ProfileComponentType } from '../company';
import { ProfileContainer, ProfileContentWrapper } from '../profile.styled';
import ContactsModal from './contactsModal';

type ContactComponentType = {
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
} & ProfileComponentType;

const ContactComponent = ({ data, loading, handleRequest }: ContactComponentType): JSX.Element => {
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalData, setModalData] =
    useState<Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto>();
  const [isTransferAgent, setTransferAgent] = useState<boolean>(false);
  const { data: KeyContacts } = useGetCompanyKeyContacts(handleRequest);
  const { data: ContactTypes, isLoading } = useGetCompanyKeyContactTypes(handleRequest);
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);

  const serviceManagerInfo = data?.vendorDesignationUsers?.find(
    (item) => item.vendorDesignationName === 'Service Manager'
  );

  const relationshipManagerInfo = data?.vendorDesignationUsers?.find(
    (item) => item.vendorDesignationName === 'Relationship Manager'
  );

  const ContactInfo = [
    {
      title: 'Super Admin',
      icon: 'account_circle',
      isContent: false,
      content: getProfileListData(
        (data as unknown as boolean)
          ? `${String(data?.adminFirstName ?? '')} ${String(data?.adminMiddleName ?? '')} ${String(
              data?.adminLastName ?? ''
            )} `
          : ''
      ),
    },
    {
      title: 'Contact Number',
      icon: 'smartphone',
      isContent: false,
      content: getProfileListData(data?.adminPhone),
    },
    {
      title: 'Email',
      icon: 'mail',
      isContent: false,
      content: getProfileListData(data?.adminEmail),
    },
    {
      title: 'Wealthlane Service Manager',
      icon: 'admin_panel_settings',
      isContent: false,
      content: getProfileListData(
        (serviceManagerInfo as unknown as boolean)
          ? `${String(serviceManagerInfo?.firstName ?? '')} ${String(
              serviceManagerInfo?.middleName ?? ''
            )} ${String(serviceManagerInfo?.lastName ?? '')}${
              !(
                (serviceManagerInfo?.firstName as unknown as boolean) ||
                (serviceManagerInfo?.middleName as unknown as boolean) ||
                (serviceManagerInfo?.lastName as unknown as boolean)
              )
                ? '-'
                : ''
            }`
          : ''
      ),
    },
    {
      title: 'Wealthlane Relationship Manager',
      icon: 'supervisor_account',
      isContent: false,
      content: getProfileListData(
        (relationshipManagerInfo as unknown as boolean)
          ? `${String(relationshipManagerInfo?.firstName ?? '')} ${String(
              relationshipManagerInfo?.middleName ?? ''
            )} ${String(relationshipManagerInfo?.lastName ?? '')} `
          : ''
      ),
    },
  ];

  return (
    <ProfileContentWrapper>
      <div className='profile-header'>
        <Heading title='Key Contacts' variant='h5' />
      </div>
      <ProfileContainer>
        {(loading || isLoading) && (
          <>
            {ContactInfo.map((item) => (
              <div className='list list-loading'>
                <div className='list-icon'>
                  <SkeletonCircle size='table' shape='circle' />
                </div>
                <div className='list-content grid grid-cols-12'>
                  <SkeletonLine size='table' containerClassName='col-span-3' />
                  <SkeletonLine size='table' containerClassName='col-span-9' />
                </div>
              </div>
            ))}
          </>
        )}
        {!(loading || isLoading) && (
          <>
            {ContactInfo.map((item) => (
              <div className='list grid sm:grid-cols-4 lg:grid-cols-12'>
                <div className='list-icon sm:col-span-2 lg:col-span-4'>
                  <span className='material-symbols-outlined'>{item.icon}</span>
                  <Heading title={item.title} variant='body-s' />
                </div>
                <div className='list-content sm:col-span-2 lg:col-span-8'>
                  {item.isContent ? (
                    <>{item.content}</>
                  ) : (
                    <Heading title={item.content} variant='body-s' />
                  )}
                </div>
              </div>
            ))}
            {ContactTypes?.map((contactItem, index) => {
              const keyData = KeyContacts?.find(
                (item) => item.customerKeyContactTypeId === contactItem.id
              );
              const icons = ['support_agent', 'contacts', 'store'];
              return (
                <div className='list grid sm:grid-cols-4 lg:grid-cols-12'>
                  <div className='list-icon sm:col-span-2 lg:col-span-4'>
                    <span className='material-symbols-outlined'>{icons[index]}</span>
                    <Heading title={contactItem.name} variant='body-s' />
                  </div>
                  <div className='list-content sm:col-span-2 lg:col-span-8 flex items-start justify-between'>
                    <div>
                      <Heading title={getProfileListData(keyData?.name)} variant='body-s' />
                      {(keyData?.transferAgentId as unknown as boolean) && (
                        <Heading
                          title={getProfileListData(keyData?.transferAgentId)}
                          variant='body-s'
                        />
                      )}
                      {(keyData?.countryCallingCode as unknown as boolean) && (
                        <Heading
                          title={getProfileListData(
                            `${String(keyData?.countryCallingCode) + ' ' + String(keyData?.phone)}`
                          )}
                          variant='body-s'
                        />
                      )}
                      <Heading title={getProfileListData(keyData?.email)} variant='body-s' />
                    </div>
                    <Button
                      as='Edit'
                      variant='secondary-dark'
                      title='Edit'
                      icon='edit'
                      btnClassName='border-0'
                      onClick={() => {
                        setModalTitle(contactItem?.name ?? '');
                        setTransferAgent(
                          !!(contactItem?.name?.toLocaleLowerCase().includes('transfer') ?? false)
                        );
                        setModalData({
                          countryCallingCode: keyData?.countryCallingCode,
                          countryCallingCodeId: keyData?.countryCallingCodeId,
                          customerKeyContactType: keyData?.customerKeyContactType,
                          customerKeyContactTypeId:
                            keyData?.customerKeyContactTypeId ?? contactItem.id,
                          email: keyData?.email,
                          id: keyData?.id,
                          name: keyData?.name,
                          phone: keyData?.phone,
                          transferAgentId: keyData?.transferAgentId,
                        });
                        openModal();
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}

        <AnimationWrapper>
          {isModalOpen && (
            <ContactsModal
              loading={loading}
              data={modalData}
              modalTitle={modalTitle}
              isTransferAgentContact={isTransferAgent}
              isModalOpen={isModalOpen}
              closeModal={closeModal}
            />
          )}
        </AnimationWrapper>
      </ProfileContainer>
    </ProfileContentWrapper>
  );
};

export default ContactComponent;
