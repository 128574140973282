import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Table from '../../../components/table';
import InitialValuesForm from '../../../constants/initialValues';
import { GrantManagementHeader } from '../../../constants/table-headers/grantManagementHeader';
import {
  useBoolean,
  useGetGrantList,
  useGetGrantPlanNameList,
  useGetGrantPlanTypeList,
  useWealthlaneAwardApi,
} from '../../../hooks';
import { GrantService } from '../../../services/wealthlane-award-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import GrantManagementModal from './grantModal';
import { SUPPORTED_FORMATS } from '../../../constants/common';
import { getFileExtension } from '../../../utils/global';
import { SettingSystemUserButton, SettingSystemUserHeader } from '../system-users/systemUserStyled';
import Heading from '../../../components/typography';
import { COLORS } from '../../../constants/style/color';
import FormSelect from '../../../components/dropdown/formSelect';
import { GoBackBlock, ResetFilterWrapper } from '../../../constants/style/common.styled';
import ToastComponent from '../../../components/toast';
import { toast } from 'react-toastify';
import AlertPopup from '../../../components/alert-popup';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import Filter from '../../../components/filter';

const initialValues = {
  data: InitialValuesForm.grantManagement,
  id: '',
  planId: '',
};

const GrantManagement = (): JSX.Element => {
  const { handleRequest, error: exception } = useWealthlaneAwardApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: planID } = useParams();
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [initialData, setInitialData] = useState({ ...initialValues, planId: planID });
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [grantType, setGrantType] = useState('');
  const { state }: any = useLocation();

  const [planName, setPlanName] = useState(state?.planId ?? '');
  const [loading, setLoading] = useState(false);
  const [selectedGrant, setSelectedGrant] = useState('');

  const { data: planNames } = useGetGrantPlanNameList(handleRequest);
  const userAuthList = useSelector(selectAuthRoutesList);

  const { data: grantList, status } = useGetGrantList(
    handleRequest,
    tableInfo,
    planName,
    grantType
  );

  const { data: grantPlanOptions } = useGetGrantPlanTypeList(handleRequest, initialData.planId);
  const [open, setOpen] = useState(false);

  const actions = [
    {
      action: 'View',
      onClick: (id: string) => {
        setModalStatus('View');
        getGrantViewData(id);
      },
    },
    {
      action: 'Edit',
      onClick: (id: string) => {
        setModalStatus('Edit');
        getGrantViewData(id);
        // updateGrantViewData(id);
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      onClick: (id: string) => {
        setModalStatus('Delete');
        setSelectedGrant(id);
        openConfirmModal();
      },
    },
  ];

  useEffect(() => {
    if (exception != null && modalStatus === 'Add') {
      if (exception?.body?.error as boolean) {
        navigate('page-not-found');
      }
    }
  }, [exception, grantList]);

  const getGrantViewData = (id: string): void => {
    handleRequest(GrantService.getApiAwardApiAppGrantGrantById(id))
      .then((data: any) => {
        if (data != null) {
          const filteredData = {
            id: data.id,
            grantName: data.grantName,
            planTypeId: data.planTypeId,
            planTypeName: data?.planTypeId,
            performanceRuleId: data.performanceRuleId,
            performanceRuleName: data.performanceRuleId,
            vestingScheduleId: data.vestingScheduleId,
            vestingScheduleName: data.vestingScheduleId,
            grantAgreementDocumentName: data?.grantAgreementDocumentId,
            grantNoticeDocumentName: data?.grantNoticeDocumentId,
            agreementStatement: data?.agreementStatement,
            agreementStatementStatusName:
              data?.agreementStatementStatusName === 'active' ? 'Active' : 'InActive',
            vestingTemplateName: data?.vestingScheduleId,
            termsAndConditionDocumentName: data?.termsAndConditionDocumentId,
            termsAndConditionTextId: data?.termsAndConditionTextId,
            termsAndConditionTextName: data?.termsAndConditionTextId,
            approvalRequired: data?.approvalRequired,
            planGrantDocuments: data?.planGrantDocuments?.map((doc: any) => ({
              ...doc,
              isDefault: true,
            })),

            grantDocument: [],
            // planTypeName: data.planTypeName,
            uniquePlanNo: data?.uniquePlanNo,
            planName: data?.planName,
            isGrantInUse: data?.grantInUse,
            documentNameList: data.documentNameList?.map((item: any) => {
              const fileName = item.grantDocumentFileName;
              return {
                file: {
                  name: item.grantDocumentFileName,
                  uniqueName: item.grantDocumentUniqueName,
                  type: SUPPORTED_FORMATS.find((item) => item.type === getFileExtension(fileName))
                    ?.format,
                },
                id: item.grantDocumentUniqueName,
                src: '',
              };
            }),
            // grantTermsAndConditionsId: data?.grantTermsAndConditionsId,
            approvedDate: data?.approvedDate,
            termsAndConditionsDocument: [],
            // isActive: data.isActive === true ? 'Active' : 'InActive',
            termsAndConditionsAgreementStatement: data.termsAndConditionsAgreementStatement,
            termsAndConditionsDocumentContent: data.termsAndConditionsDocumentContent,
            // termsAndConditionsDocumentName: [
            //   {
            //     file: {
            //       name: data.termsAndConditionsDocumentName?.termsAndConditionsDocumentFileName,
            //       uniqueName:
            //         data.termsAndConditionsDocumentName?.termsAndConditionsDocumentUniqueName,
            //     },
            //   },
            // ],
          };

          setInitialData({ id, data: filteredData as any, planId: data?.planId });
          openModal();
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  // const updateGrantViewData = (id: string): any => {
  //   handleRequest(GrantService.getApiAwardApiAppGrantGrantByIdForUpdate(id))
  //     .then((data: any) => {
  //       if (data != null) {
  //         const filteredData = {
  //           id: data.id,
  //           grantName: data.grantName,
  //           planTypeId: data.planTypeId,
  //           performanceRuleId: data.performanceRuleId,
  //           vestingScheduleId: data.vestingScheduleId,
  //           vestingScheduleName: data.vestingScheduleName,
  //           grantDocument: [],
  //           planTypeName: data.planTypeName,
  //           uniquePlanNo: data?.uniquePlanNo,
  //           planName: data?.planName,
  //           isGrantInUse: data?.isGrantInUse,
  //           documentNameList: data.documentNameList?.map((item: any) => {
  //             const fileName = item.grantDocumentFileName;
  //             return {
  //               file: {
  //                 name: item.grantDocumentFileName,
  //                 uniqueName: item.grantDocumentUniqueName,
  //                 type: SUPPORTED_FORMATS.find((item) => item.type === getFileExtension(fileName))
  //                   ?.format,
  //               },
  //               id: item.grantDocumentUniqueName,
  //               src: '',
  //             };
  //           }),
  //           approvedDate: data?.approvedDate,
  //           termsAndConditionsDocument: [],
  //           isActive: data.isActive === true ? 'Active' : 'InActive',
  //           termsAndConditionsAgreementStatement: data.termsAndConditionsAgreementStatement,
  //           termsAndConditionsDocumentContent: data.termsAndConditionsDocumentContent,
  //           termsAndConditionsDocumentName: [
  //             {
  //               file: {
  //                 name: data.termsAndConditionsDocumentName?.termsAndConditionsDocumentFileName,
  //                 uniqueName:
  //                   data.termsAndConditionsDocumentName?.termsAndConditionsDocumentUniqueName,
  //               },
  //             },
  //           ],
  //         };
  //         setInitialData({ id, data: filteredData as any, planId: data?.planId });
  //         openModal();
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log('error', error);
  //     });
  // };

  const handleDelete = (): void => {
    setLoading(true);
    handleRequest(GrantService.deleteApiAwardApiAppGrantGrant(selectedGrant))
      .then((data: any) => {
        if (data != null) {
          toast.success(<ToastComponent label='Success' message='Grant deleted' />);
          closeConfirmModal();
          void queryClient.invalidateQueries('getGrantList');
          void queryClient.invalidateQueries('getPlanManagementList');
          setLoading(false);
        } else {
          setLoading(false);
          closeConfirmModal();
        }
      })
      .catch(() => {
        setLoading(false);
        closeConfirmModal();
      });
  };

  return (
    <div>
      <div>
        <GoBackBlock
          onClick={() => {
            navigate('/settings/organization-settings/plan-management');
          }}>
          <span className='material-symbols-outlined'>arrow_back</span>
          <Heading className='goBack' variant='body-s' title='Go back' />
        </GoBackBlock>
        <SettingSystemUserHeader>
          <Heading variant='h3' title='Grant List' />
          <Heading
            variant='body-s'
            title={`List of ${
              (userAuthList?.currentTenant?.name as boolean)
                ? String(userAuthList?.currentTenant?.name ?? '')
                : ''
            }’s equity grants on Wealthlane system.`}
            color={`${COLORS.Gray}`}
          />
        </SettingSystemUserHeader>

        <div className='flex gap-x-4 mb-10'>
          <div className='col-span-1 md:col-span-1 lg:col-span-1 '>
            <Filter setOpen={setOpen} open={open}>
              <Heading variant='body-xs' title={'Filter By:'} className={'filter-label'} />
              <div className='grid sm:grid-cols-2 gap-x-5'>
                <FormSelect
                  type='text'
                  name='planName'
                  placeholder='Plan Name'
                  viewName='customerRole'
                  onChange={(value: string) => {
                    setTableInfo(InitialValuesForm.tableParams);
                    setPlanName(value);
                  }}
                  options={planNames}
                  values={planName}
                  arrValue={planName}
                  containerClassName='formGroup'
                  isFormik={false}
                  required
                />
                <FormSelect
                  type='text'
                  name='grantType'
                  placeholder='Grant Type'
                  onChange={(value: string) => {
                    setTableInfo(InitialValuesForm.tableParams);
                    setGrantType(value);
                  }}
                  options={grantPlanOptions}
                  values={grantType}
                  arrValue={grantType}
                  containerClassName='formGroup'
                  isFormik={false}
                  required
                />
              </div>
            </Filter>
          </div>
          {/* <div className='sm:col-span-2 lg:col-span-2 '>
            <FormSelect
              type='text'
              name='planName'
              placeholder='Plan Name'
              viewName='customerRole'
              onChange={(value: string) => {
                setTableInfo(InitialValuesForm.tableParams);
                setPlanName(value);
              }}
              options={planNames}
              values={planName}
              arrValue={planName}
              containerClassName='formGroup'
              isFormik={false}
              required
            />
          </div>
          <div className='sm:col-span-2 lg:col-span-2'>
            <FormSelect
              type='text'
              name='grantType'
              placeholder='Grant Type'
              onChange={(value: string) => {
                setTableInfo(InitialValuesForm.tableParams);
                setGrantType(value);
              }}
              options={grantPlanOptions}
              values={grantType}
              arrValue={grantType}
              containerClassName='formGroup'
              isFormik={false}
              required
            />
          </div> */}

          <div className='sm:col-span-2 lg:col-span-2'>
            <ResetFilterWrapper
              onClick={() => {
                setPlanName('');
                setGrantType('');
                setTableInfo(InitialValuesForm.tableParams);
              }}>
              <span className='material-symbols-outlined'>settings_backup_restore</span>
              <Heading variant='body-s' title='Reset Filter' />
            </ResetFilterWrapper>
          </div>
          <SettingSystemUserButton className='col-span-2 flex justify-end'>
            {/* <Button
              as='Create'
              variant={'primary'}
              title={'Add Grant'}
              icon={'add_circle_outline'}
              onClick={() => {
                setInitialData({ ...initialValues, planId: planID });
                setModalStatus('Add');
                openModal();
              }}
            /> */}
          </SettingSystemUserButton>
        </div>
        {/* <div className='flex items-center gap-x-5'>
          <Button
            variant={'primary'}
            title={'Add a Grant'}
            onClick={() => {
              setInitialData({ ...initialValues, planId: planID });
              setModalStatus('Create');
              openModal();
            }}
          />
        </div> */}
      </div>
      <div className='overflow-x-auto'>
        <Table
          columns={GrantManagementHeader(actions)}
          data={grantList?.items ?? []}
          loading={status}
          // tableInfo={InitialValuesForm.tableParams}
          showPagination
          tableInfo={tableInfo}
          setTableInfo={setTableInfo}
          totalCounts={grantList?.totalCount ?? 10}
        />
      </div>

      {isConfirmOpen && (
        <AlertPopup
          isShow={isConfirmOpen}
          closeModal={closeConfirmModal}
          modalStatus={modalStatus}
          title={'this grant'}
          handleSubmit={handleDelete}
          loading={loading}
        />
      )}
      {isModalOpen && (
        <GrantManagementModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          initialData={initialData}
        />
      )}
    </div>
  );
};

export default GrantManagement;
