import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { ModalStatusType } from '../../../types/common';
import ModalComponent from '../../../components/modal/modal';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import InputText from '../../../components/input-text/inputText';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import { Label } from '../../../components/input-text/input.styled';
import ImageViewer from '../../../components/input-file/inputFileWithView';
import { useEffect, useState } from 'react';
import CustomTextArea from '../../../components/text-area/textArea';
import InitialValuesForm from '../../../constants/initialValues';
import ImageViewerSingle from '../../../components/input-file/inputFileWithViewSingle';
import { isEmpty, values } from 'lodash';
import { useGetCheckPlanGrantDocumentName, useWealthlaneAwardApi } from '../../../hooks';
import { SwitchContainer } from './planManagement.styled';
import Heading from '../../../components/typography';
import RichTextEditor from '../../../components/rich-text-editor';
import { PLAN_DOCUMENT_VALIDATION } from '../../../utils/validations/validations';

interface GrantDocumentModelType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  //   setModalStatus: (value: ModalStatusType) => void;
  initialData: {
    data: any;
    id: string;
  };

  rowData?: any;
  id?: string;
  modelName?: string;
  documentDto?: any;
  setDocumentDto: any;
  documentType?: string | number | null;
  setDocumentType?: any;
}

const GrantDocumentModel = ({
  isModalOpen,
  closeModal,
  modalStatus,
  initialData,
  //   setModalStatus,
  rowData,
  id,
  modelName,
  documentDto,
  setDocumentDto,
  documentType,
  setDocumentType,
}: GrantDocumentModelType): JSX.Element => {
  const [removedId, setRemovedId] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleRequest } = useWealthlaneAwardApi();
  const [checkNameError, setCheckNameError] = useState();
  const [toggle, setToggle] = useState<boolean>(false);

  const { mutate: checkName } = useGetCheckPlanGrantDocumentName(
    handleRequest,
    documentType,
    setCheckNameError
  );

  const handleImageOpen = (name: string): void => {
    setLoading(true);

    setLoading(false);
  };

  const handleSubmit = (
    values: any,
    documentType?: string | number | null,
    documentDto?: any
  ): void => {
    const ext =
      documentType !== 4 ? '.' + values?.document?.[0]?.[0]?.name?.split('.')?.reverse()[0] : '';
    setLoading(true);
    setDocumentDto([
      ...documentDto,
      {
        ...values,
        grantDocumentName: values?.grantDocumentName + ext,
        document: values?.document?.[0]?.base64String,
        grantDocumentType: toggle ? 4 : documentType,
        fileExtension: ext,
      },
    ]);

    closeModal();
    setLoading(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={InitialValuesForm?.grantDocumentModel}
      validationSchema={PLAN_DOCUMENT_VALIDATION}
      onSubmit={(values) => {
        handleSubmit(values, documentType, documentDto);
      }}>
      {({
        touched,
        handleChange,
        errors,
        setFieldValue,
        setFieldTouched,
        values,
        setTouched,
        setFieldError,
      }: any) => {
        useEffect(() => {
          if (documentType === 4) {
            setFieldValue('text', true);
          }
        }, []);

        return (
          <>
            <ModalComponent
              show={isModalOpen}
              closeModal={closeModal}
              loading={false}
              size='lg'
              //   valueEdited={_.isEqual(
              //     values,
              //     modalStatus !== 'Add' ? initialValue : initialData?.data
              //   )}
              customAlertMessage={'Your changes will not be saved. Are you sure you want to close?'}
              title={`Add ${modelName}`}
              children={
                <Form>
                  <ModalBodyComponent>
                    <div>
                      <InputText
                        type='text'
                        label='Name'
                        name='grantDocumentName'
                        onChange={(e: any) => {
                          // checkName && checkName(e.target.value);
                          handleChange(e);
                        }}
                        errors={errors}
                        touched={touched}
                        //   inputType={modalStatus}
                        //   disabled={modalStatus === 'View'}
                        required
                      />
                      <CustomTextArea
                        label='Description'
                        name='description'
                        onChange={handleChange}
                        errors={errors}
                        inputType={modalStatus}
                        disabled={modalStatus === 'View'}
                        touched={touched}
                        rows='5'
                      />

                      {documentType === 4 ? (
                        <div className='grid grid-cols-12'>
                          {modalStatus !== 'View' ? (
                            <div className='col-span-12'>
                              <>
                                <RichTextEditor
                                  name='grantTermsAndConditionText'
                                  label='Terms and Condition Text'
                                  setFieldValue={setFieldValue}
                                  value={
                                    (values?.grantTermsAndConditionText as boolean)
                                      ? values?.grantTermsAndConditionText
                                      : ''
                                  }
                                  error={errors?.grantTermsAndConditionText}
                                  touched={touched?.grantTermsAndConditionText}
                                />
                              </>
                              <ErrorMessage
                                name='grantTermsAndConditionText'
                                component='div'
                                className='error-message'
                              />
                            </div>
                          ) : (
                            <div className='col-span-12'>
                              <Label>Description</Label>

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: values?.grantTermsAndConditionText,
                                }}
                                className={`border p-2 text-2xl rounded-lg bg-[#F3F3F3] descriptionOutput`}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <Label htmlFor={'document'} className={'pb-1'}>
                            {modalStatus === 'View' ? '' : 'Upload'} Documents{' '}
                          </Label>
                          <ImageViewerSingle
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            modalStatus={modalStatus}
                            values={values}
                            errors={errors}
                            touched={touched}
                            errorValue={errors?.document}
                            touchedValue={touched?.document}
                            name='document'
                            listingName='documentNameList'
                            accept='.pdf, .jpg, .jpeg, .png, .docx, .doc'
                            loading={loading}
                            removedId={removedId}
                            handleImageOpen={handleImageOpen}
                            setRemovedId={setRemovedId}
                            uploadLimit={1}
                            isSingleFile={true}
                            setFieldError={setFieldError}
                            isBase64={true}
                          />
                        </>
                      )}
                    </div>
                  </ModalBodyComponent>
                  <ModalFooterComponent
                    modalStatus={modalStatus}
                    checkError={false}
                    loading={false}
                    isEditShow={true}
                    // isButton={true}
                    buttonTitle='Add'
                    // isTmc={modalStatus === 'View' && id ? true : false}
                    onCloseModal={() => {
                      closeModal();
                    }}
                    valueChanged={false}
                    // onEditModal={() => {
                    //   setModalStatus('Edit');
                    // }}
                    customAlertMessage={
                      'Your changes will not be saved. Are you sure you want to close?'
                    }
                  />
                </Form>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};

export default GrantDocumentModel;
