import { type UseQueryResult, useQuery } from 'react-query';
import { PsuAwardService, RsuAwardService } from '../../services/wealthlane-award-services';

const useGetPsuAwardsGrant = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      planTypeId: string;
      planTypeName: string;
      vestingScheduleId: string;
      vestingScheduleName: string;
      planId: string;
      planName: string;
      value: string;
      uniqueGrantNo: string;
    }>
  | undefined,
  unknown
> => {
  const PsuAwardsGrant = useQuery(
    ['getPsuAwardsGrant'],
    async () => {
      return await handleRequest(PsuAwardService.getApiAwardApiAppPSUAwardGrantDetails());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.grantName ?? '',
            planTypeId: item.planTypeId ?? '',
            planTypeName: item.planTypeName ?? '',
            vestingScheduleId: item.vestingScheduleId ?? '',
            vestingScheduleName: item.vestingScheduleName ?? '',
            planId: item.planId ?? '',
            planName: item.planName ?? '',
            performanceRuleName: item.performanceRuleName ?? '',
            performanceRuleId: item.performanceRuleId ?? '',
            value: item.grantId ?? '',
            uniqueGrantNo: item.uniqueGrantNo ?? '',
            grantIssuedDate: item.grantIssuedDate ?? '',
          };
        });
      },
    }
  );

  return PsuAwardsGrant;
};

export default useGetPsuAwardsGrant;
