import _ from 'lodash';
import { type OptionType } from '../types/common';

export const getOptionLabel = (options: OptionType[], value: string | undefined | null): any => {
  return options.find((option) => option.value === value)?.label;
};

// Format data to objects
export const restructureNonFieldPermissions = (
  data: string[],
  permissionIdentifier: string
): any => {
  return data?.map((item: string) => {
    const permissionParts = item.replaceAll(permissionIdentifier, '').split('.');
    return {
      groupKey: permissionParts[0],
      key: item,
      text: permissionParts.length > 1 ? permissionParts[1] : 'View',
    };
  });
};

// Filter data which has permissionIdentifier
const getCustomerPolicies = (policies: any, permissionIdentifier: string): string[] =>
  Object.keys(policies)
    .map((item) => {
      return item;
    })
    .filter((item) => {
      return item.startsWith(permissionIdentifier);
    });

// Filter data which don't have FieldLevel
export const getNonFieldLevelPermissions = (permissions: any): any => {
  return permissions.filter((item: any) => {
    return item.includes('.FieldLevel.') === false;
  });
};

// Filter data which have FieldLevel
export const getFieldLevelPermissions = (permissions: any): any => {
  return permissions.filter((item: any) => {
    return item.includes('.FieldLevel.');
  });
};

// Converts Strings Data with value to groups objects
export const getFilteredPermissionData = (policies: any, permissionInfo?: string): any => {
  const permissionIdentifier = permissionInfo ?? 'CustomerService.Customer.';
  const permissionIdentifierField = 'CustomerService.Customer.FieldLevel.';

  const customerPolicies = getCustomerPolicies(policies, permissionIdentifier);

  const nonFieldLevelPermissions = getNonFieldLevelPermissions(customerPolicies);
  const fieldLevelPermissions = getFieldLevelPermissions(customerPolicies);

  const nonFieldLevelPermissionsData = restructureNonFieldPermissions(
    nonFieldLevelPermissions,
    permissionIdentifier
  );
  const fieldLevelPermissionsData = restructureNonFieldPermissions(
    fieldLevelPermissions,
    permissionIdentifierField
  );

  const nonFieldLevelPermissionsGroups = _.groupBy(
    nonFieldLevelPermissionsData,
    (item) => item.groupKey
  );

  const fieldLevelPermissionsGroups = _.groupBy(fieldLevelPermissionsData, (item) => item.groupKey);

  return { nonFieldLevelPermissionsGroups, fieldLevelPermissionsGroups };
};
