import { type UseQueryResult, useQuery } from 'react-query';
import {
  RetirementEligibilityReportService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportDto_Wealthlane_ReportService_Application_Contracts,
} from '../../services/wealthlane-report-services';
import { type RetirementEligibilityReportType, type TableParamsType } from '../../types/common';

const useGetRetirementEligibilityList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  filterInfo: RetirementEligibilityReportType,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RetirementEligilibity_RetirementEligibilityReportDto_Wealthlane_ReportService_Application_Contracts
  | undefined,
  unknown
> => {
  const {
    eligibleFromDate,
    eligibleToDate,
    employeeStatus,
    employeeTypeId,
    residentCountryId,
    residentState,
    residentStateId,
    workCountryId,
    workState,
    workStateId,
    departmentId,
    columnPreferences,
    columnPreferencesSystemName,
  } = filterInfo;

  const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const retirementEligibilityList = useQuery(
    [
      'getRetirementEligibilityList',
      eligibleFromDate,
      eligibleToDate,
      employeeStatus,
      employeeTypeId,
      residentCountryId,
      residentState,
      residentStateId,
      workCountryId,
      workState,
      workStateId,
      departmentId,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      const payload: any = {
        eligibleFromDate,
        eligibleToDate,
        employeeStatus,
        employeeTypeId,
        residentCountryId,
        residentState,
        residentStateId,
        workCountryId,
        workState,
        workStateId,
        departmentId,
        columnPreferences,
        columnPreferencesSystemName,
        filter,
        sorting,
        skipCount,
        maxResultCount,
      };

      Object.keys(payload).forEach((key) => {
        payload[key] = payload[key] !== '' ? payload[key] : null;
      });
      return await handleRequest(
        RetirementEligibilityReportService.postApiReportApiAppRetirementEligibilityReportGetRetirementEligibilityList(
          payload
        )
      );
    }
  );
  return retirementEligibilityList;
};

export default useGetRetirementEligibilityList;
