/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AuditLogs_AuditLogDto } from '../models/Wealthlane_AuditLogs_AuditLogDto';
import type { Wealthlane_AuditLogs_EntityChangeDto } from '../models/Wealthlane_AuditLogs_EntityChangeDto';
import type { Wealthlane_AuditLogs_ParticipantEntityDto } from '../models/Wealthlane_AuditLogs_ParticipantEntityDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuditLogService {

    /**
     * @param entityId 
     * @returns Wealthlane_AuditLogs_EntityChangeDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppAuditLogParticipantEntityChanges(
entityId: string,
): CancelablePromise<Array<Wealthlane_AuditLogs_EntityChangeDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/audit-log/participant-entity-changes/{entityId}',
            path: {
                'entityId': entityId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param entityId 
     * @param entityTypeFullName 
     * @returns Wealthlane_AuditLogs_EntityChangeDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppAuditLogEntityChanges(
entityId: string,
entityTypeFullName?: string,
): CancelablePromise<Array<Wealthlane_AuditLogs_EntityChangeDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/audit-log/entity-changes/{entityId}',
            path: {
                'entityId': entityId,
            },
            query: {
                'entityTypeFullName': entityTypeFullName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param entityChangeId 
     * @returns Wealthlane_AuditLogs_AuditLogDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppAuditLogParticipantEntityChange(
entityChangeId: string,
): CancelablePromise<Wealthlane_AuditLogs_AuditLogDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/audit-log/participant-entity-change/{entityChangeId}',
            path: {
                'entityChangeId': entityChangeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param entityChangeId 
     * @param entityTypeFullName 
     * @returns Wealthlane_AuditLogs_AuditLogDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppAuditLogEntityChange(
entityChangeId: string,
entityTypeFullName?: string,
): CancelablePromise<Wealthlane_AuditLogs_AuditLogDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/audit-log/entity-change/{entityChangeId}',
            path: {
                'entityChangeId': entityChangeId,
            },
            query: {
                'entityTypeFullName': entityTypeFullName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AuditLogs_ParticipantEntityDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppAuditLogEntityList(): CancelablePromise<Array<Wealthlane_AuditLogs_ParticipantEntityDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/audit-log/entity-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
