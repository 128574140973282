/* eslint-disable camelcase */
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import Button from '../../../components/button';
import FormSelect from '../../../components/dropdown/formSelect';
import { Label } from '../../../components/input-text/input.styled';
import InputText from '../../../components/input-text/inputText';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import Spinners from '../../../components/spinner';
import useWealthlaneAwardSettlementApi from '../../../hooks/useWealthlaneAwardSettlement';
import { type Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_SaveRetirementEligibilityConfigurationRequestDto } from '../../../services/wealthlane-award-settlement-services';
import {
  TaxSettlementContainer,
  TaxSettlementContainerHeading,
} from '../settlement-config/settlementConfigStyled';
import { setFormikFieldToNull } from '../../../utils/global';
import useGetRetirementEligibilityData from '../../../hooks/get/useGetRetirementEligibilityData';
import useEligibilityCriteriaOptions from '../../../hooks/get/useEligibilityCriteriaOptions';
import usePostRetirementEligibility from '../../../hooks/post/usePostRetirementEligibility';
import { RETIREMENT_ELIGIBILITY_VALIDATION } from '../../../utils/validations/validations';
// import _, { isEmpty } from 'lodash';

const RetirementEligibility = (): JSX.Element => {
  const { handleRequest } = useWealthlaneAwardSettlementApi();
  const { data, isLoading } = useGetRetirementEligibilityData(handleRequest);
  const { data: eligibilityCriteriaOptions } = useEligibilityCriteriaOptions(handleRequest);
  const [eligibilityCriteria, setEligibilityCriteria] = useState<string>();

  const { mutate, isLoading: submitLoader } = usePostRetirementEligibility(
    handleRequest,
    data?.id != null && data?.id !== '' ? 'Edit' : ''
  );
  const handleSubmit = (
    payload: Wealthlane_AwardSettlementService_Dtos_RetirementEligibilityConfiguration_SaveRetirementEligibilityConfigurationRequestDto
  ): void => {
    const filteredValue = Object.fromEntries(
      Object.entries(payload).map(([k, v]) => [k, v === '' ? null : v ?? null])
    );

    const payloadData = {
      ...filteredValue,
      id: data?.id,
    };
    delete filteredValue?.eligibilityOption;
    mutate(data?.id != null && data?.id !== '' ? payloadData : filteredValue);
  };

  const eligibility = eligibilityCriteriaOptions?.find(
    (item) => item.value === data?.eligibilityCriteriaId
  );

  useEffect(() => {
    eligibility?.label === 'Age only' || eligibility?.label === 'Federal retirement age'
      ? setEligibilityCriteria('Age')
      : eligibility?.label === 'Tenure only'
      ? setEligibilityCriteria('Tenure')
      : eligibility?.label?.includes('Age and tenure') === true
      ? setEligibilityCriteria('Both')
      : setEligibilityCriteria('');
  }, [data]);
  // const [initialData1, setInitialData1] = useState<any<()

  // useEffect(()=> {

  // })

  // const [defaultData, setDefaultData] = useState<any>(InitialValuesForm.retirementEligibility);

  // const getInitialData = () => {
  //   if (data) {
  //     return {
  //       ...data,
  //       // eligibilityCriteriaId: data?.id ? data?.eligibilityCriteriaId : '',
  //       eligibilityOption:
  //         eligibility?.label === 'Age only' || eligibility?.label === 'Federal retirement age'
  //           ? 'Age'
  //           : eligibility?.label === 'Tenure only'
  //           ? 'Tenure'
  //           : eligibility?.label?.includes('Age and tenure')
  //           ? 'Both'
  //           : '',
  //     };
  //   } else {
  //     return InitialValuesForm.retirementEligibility;
  //   }
  // };

  // useEffect(() => {
  //   if (data?.id) {
  //     setDefaultData({
  //       ...data,
  //       eligibilityCriteriaId: data?.id ? data?.eligibilityCriteriaId : '',
  //       eligibilityOption:
  //         eligibility?.label === 'Age only' || eligibility?.label === 'Federal retirement age'
  //           ? 'Age'
  //           : eligibility?.label === 'Tenure only'
  //           ? 'Tenure'
  //           : eligibility?.label?.includes('Age and tenure')
  //           ? 'Both'
  //           : '',
  //     });
  //   }
  // }, [data]);
  const initialData =
    {
      ...data,
      eligibilityCriteriaId: data?.id != null && data?.id !== '' ? data?.eligibilityCriteriaId : '',
      eligibilityOption:
        eligibility?.label === 'Age only' || eligibility?.label === 'Federal retirement age'
          ? 'Age'
          : eligibility?.label === 'Tenure only'
          ? 'Tenure'
          : eligibility?.label?.includes('Age and tenure') === true
          ? 'Both'
          : '',
    } ?? InitialValuesForm.retirementEligibility;
  return (
    <>
      {isLoading ? (
        <Spinners containerClassName='h-normal-screen' />
      ) : (
        <>
          <TaxSettlementContainerHeading>
            <Heading variant='h4' title='Retirement Configuration' color={COLORS.Silver} />
          </TaxSettlementContainerHeading>
          <TaxSettlementContainer>
            {data != null && (
              <Formik
                initialValues={initialData}
                validationSchema={Yup.object().shape(RETIREMENT_ELIGIBILITY_VALIDATION)}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}>
                {({ touched, handleChange, errors, setFieldValue, setErrors, values }) => (
                  <Form>
                    <div className='grid grid-cols-3 gap-x-[20px]'>
                      <FormSelect
                        label={'Eligibility Criteria'}
                        name='eligibilityCriteriaId'
                        onChange={async (event: any) => {
                          handleChange(event);
                          setFieldValue('eligibilityCriteriaId', event);

                          // Filter value in select option
                          const selectedCriteria = eligibilityCriteriaOptions?.find(
                            (item) => item.value === event
                          );
                          setFieldValue('eligibilityCriteria', selectedCriteria?.label);
                          // Onchange set all value to null
                          Object.keys(data)
                            ?.filter(
                              (item) =>
                                item !== 'eligibilityCriteriaId' &&
                                item !== 'id' &&
                                item !== 'eligibilityCriteria'
                            )
                            .map((item) => setFormikFieldToNull(item, setFieldValue));

                          setFieldValue(
                            'eligibilityOption',
                            selectedCriteria?.label === 'Age only' ||
                              selectedCriteria?.label === 'Federal retirement age'
                              ? 'Age'
                              : selectedCriteria?.label === 'Tenure only'
                              ? 'Tenure'
                              : 'Both'
                          );

                          selectedCriteria?.label === 'Age only' ||
                          selectedCriteria?.label === 'Federal retirement age'
                            ? setEligibilityCriteria('Age')
                            : selectedCriteria?.label === 'Tenure only'
                            ? setEligibilityCriteria('Tenure')
                            : setEligibilityCriteria('Both');
                        }}
                        errors={errors}
                        touched={touched}
                        options={eligibilityCriteriaOptions}
                        values={values?.eligibilityCriteriaId}
                        arrValue={values?.eligibilityCriteriaId}
                        required
                      />
                    </div>
                    <div className='grid sm:grid-cols-5 lg:grid-cols-10 gap-x-4'>
                      {((eligibilityCriteria?.includes('Age') as boolean) ||
                        (eligibilityCriteria?.includes('Both') as boolean)) && (
                        <div className='col-span-5'>
                          <div className='grid grid-cols-5'>
                            <div className='w-full'>
                              <Label>Age</Label>
                              <div className='flex flex-row gap-x-5'>
                                <InputText
                                  type='Number'
                                  name='ageYear'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  value={values?.ageYear}
                                  required
                                />

                                <Heading
                                  variant='body-field'
                                  title='Year'
                                  color={`${COLORS.SpanishGray}`}
                                  className='items-center mb-6'
                                />

                                <InputText
                                  type='Number'
                                  name='ageMonth'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  errorValue={errors?.ageYear}
                                  touchedValue={touched?.ageYear}
                                  required
                                />
                                <Heading
                                  variant='body-field'
                                  title='Month'
                                  color={`${COLORS.SpanishGray}`}
                                  className='items-center mb-6'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(eligibilityCriteria?.includes('Tenure') === true ||
                        eligibilityCriteria?.includes('Both') === true) && (
                        <div className='col-span-5'>
                          <div className='grid grid-cols-5'>
                            <div className='w-full'>
                              <Label>Tenure Completed</Label>
                              <div className='flex flex-row gap-x-5'>
                                <InputText
                                  type='Number'
                                  name='tenureCompletedYear'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />

                                <Heading
                                  variant='body-field'
                                  title='Year'
                                  color={`${COLORS.SpanishGray}`}
                                  className='items-center mb-6'
                                />

                                <InputText
                                  type='Number'
                                  name='tenureCompletedMonth'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  errorValue={errors?.tenureCompletedYear}
                                  touchedValue={touched?.tenureCompletedYear}
                                  required
                                />
                                <Heading
                                  variant='body-field'
                                  title='Month'
                                  color={`${COLORS.SpanishGray}`}
                                  className='items-center mb-6'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=''>
                      <Button
                        title='Save'
                        type='submit'
                        variant='primary'
                        loading={submitLoader}
                        // disabled={_.isEqual(values, initialData)}
                        icon='check_circle'
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </TaxSettlementContainer>
        </>
      )}
    </>
  );
};

export default RetirementEligibility;
