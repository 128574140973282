import React, { useState } from 'react';
import Heading from '../../../../components/typography';
import { PageHeader } from '../../../../constants/style/common.styled';
import { COLORS } from '../../../../constants/style/color';
import Button from '../../../../components/button';
import { taxPeriodHeader } from '../../../../constants/table-headers/taxPeriodHeader';
import Table from '../../../../components/table';
import { type ModalStatusType, type TableParamsType } from '../../../../types/common';
import InitialValuesForm from '../../../../constants/initialValues';
import AnimationWrapper from '../../../../components/animation/animationWrapper';
import TaxYearModal from './taxYearModal';
import { useBoolean } from '../../../../hooks';
import useGetTaxPeriodList from '../../../../hooks/get/useGetTaxPeriodList';
import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import { uniq, uniqBy } from 'lodash';

const TaxYear = (): JSX.Element => {
  const { handleRequest } = useWealthlaneTaxApi();
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Edit');
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const [isHovering, setIsHovering] = useState<string>('');
  const { data, status } = useGetTaxPeriodList(handleRequest, tableInfo);
  const [initialValue, setInitialValue] = useState<any>(InitialValuesForm.taxPeriod);

  const taxPeriodList = uniqBy(data?.items, (list) => list?.name)

  const action = [
    {
      action: 'Edit',
      onClick: (id: string, rowData: any) => {
        setInitialValue(rowData);
        openModal();
      },
    },
  ];
  return (
    <div>
      <div className='flex justify-between items-center'>
        <PageHeader>
          <Heading variant='h3' title={`Tax Period`} />
          <Heading
            variant='body-s'
            title={`View all the users present in your company.`}
            color={`${COLORS.Gray}`}
          />
        </PageHeader>
        <Button
          variant={'primary'}
          title={'Create Tax Period'}
          icon='add_circle_outline'
          onClick={() => {
            openModal();
            setModalStatus('Add');
            setInitialValue(InitialValuesForm.taxPeriod);
          }}
        // loading={loading}
        />
      </div>
      <Table
        columns={taxPeriodHeader(action)}
        data={data?.items ?? []}
        loading={status}
        totalCounts={data?.totalCount ?? 10}
        showPagination={true}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        setIsHovering={setIsHovering}
      />

      <AnimationWrapper>
        {isModalOpen && (
          <TaxYearModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            loading={false}
            initialValue={initialValue}
            setInitialValue={setInitialValue}
          // federalTaxDetails={federalTaxListByFinancialYear}
          // operationYear={operationYear}

          // tableInfo={tableInfo}
          // setTableInfo={setTableInfo}
          //   dataRow={dataRow}
          />
        )}
      </AnimationWrapper>
    </div>
  );
};

export default TaxYear;
