import { rem } from '../../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';

export const VestingModalWrapper = styled.div`
  .vest-info {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: ${rem(5)};
    padding-bottom: ${rem(10)};
    border-bottom: 1px solid ${COLORS.Gray10};

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    @media (min-width: 512px) {
      flex-direction: row;
      gap: ${rem(0)};
      padding-bottom: 0;
      border-bottom: none;
    }

    span {
      color: ${COLORS.Gray};
    }

    .info {
      font-weight: 600;
      color: ${COLORS.Purple};
      padding-right: ${rem(4)};
    }
  }

  .cliff-info {
    margin: ${rem(8)} 0 ${rem(10)} 0;

    span {
      color: ${COLORS.Silver};
    }
  }
`;
