/* eslint-disable camelcase */
import { Form, Formik } from 'formik';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import { type ModalStatusType, type OptionType } from '../../../types/common';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import FormSelect from '../../../components/dropdown/formSelect';
import { YesNoOptions } from '../../../constants/common';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import { useEffect } from 'react';
import {
  ApiError,
  type Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsGetDto,
} from '../../../services/wealthlane-customer-services';
import { UDF_VALIDATION } from '../../../utils/validations/validations';
import { useBoolean, usePostAddUdf, usePutUpdateUdf } from '../../../hooks';
import _ from 'lodash';
import CustomTextArea from '../../../components/text-area/textArea';
import AlertPopup from '../../../components/alert-popup';

interface UdfTypes {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  isLoading: boolean;
  initialData: Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsGetDto;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  statusOptions: OptionType[] | undefined;
  moduleOptions: OptionType[] | undefined;
  error?: ApiError;
}

const UdfModel = ({
  isModalOpen,
  closeModal,
  modalStatus,
  isLoading,
  initialData,
  handleRequest,
  statusOptions,
  moduleOptions,
  error,
}: UdfTypes): JSX.Element => {
  const {
    value: isConfirmModalOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false, true);

  // Mutate
  const { mutate: mutateAdd, isLoading: AddLoader } = usePostAddUdf(
    handleRequest,
    closeModal,
    closeConfirmModal
  );
  const { mutate: mutateEdit, isLoading: EditLoader } = usePutUpdateUdf(
    handleRequest,
    closeModal,
    closeConfirmModal
  );

  const handleSubmit = (values: any): void => {
    const payload = {
      ...values,
      moduleId: [values?.moduleId],
      fieldType: values.fieldType.toLowerCase(),
      isMandatory: values?.isMandatory === 'Yes',
    };
    modalStatus === 'Edit' ? mutateEdit(payload) : mutateAdd(payload);
  };

  const defaultData = { ...initialData, fieldType: 'Text' };

  useEffect(() => {
    if (error) {
      closeConfirmModal();
    }
  }, [error]);

  return (
    <Formik
      enableReinitialize
      initialValues={defaultData}
      validationSchema={UDF_VALIDATION}
      onSubmit={() => {
        openConfirmModal();
      }}>
      {({ touched, handleChange, errors, setFieldValue, values }: any) => {
        console?.log('hell', values, errors);
        return (
          <>
            <ModalComponent
              show={isModalOpen}
              closeModal={closeModal}
              title={`${modalStatus} User Defined Field`}
              handleSubmit={handleSubmit}
              valueEdited={_.isEqual(values, defaultData)}
              size='lg'
              children={
                <>
                  <Form>
                    <ModalBodyComponent>
                      <div className='flex sm:gap-6 flex-col'>
                        <div className='grid sm:grid-cols-3 gap-4 sm:gap-x-6'>
                          <FormSelect
                            name='moduleId'
                            label='Module'
                            containerClassName='formGroup'
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            placeholder={'Select Module'}
                            options={moduleOptions}
                            touched={touched}
                            errors={errors}
                            values={values}
                            required
                          />
                        </div>
                        <div className='grid sm:grid-cols-3 gap-4 sm:gap-x-6'>
                          <InputText
                            type='text'
                            label='Field Name'
                            name='fieldName'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            required
                          />
                          <InputText
                            type='text'
                            label='Field Type'
                            name='fieldType'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled
                            required
                          />
                          <FormSelect
                            name='isMandatory'
                            label='Is Mandatory?'
                            containerClassName='formGroup'
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            placeholder={'Select'}
                            options={YesNoOptions}
                            touched={touched}
                            errors={errors}
                            values={values}
                            required
                          />

                          {/* <UdfDropdown
                        title='Module'
                        handleSelect={(module: any) => {
                          handleSelect(module, setFieldValue);
                        }}
                        checkedList={checkedModules}
                        data={moduleOptions}
                        placeholder={isEmpty(placeHolderData) ? undefined : placeHolderData}
                        touched={touched}
                        errors={errors}
                      /> */}

                          <FormSelect
                            name='status'
                            label='Status'
                            containerClassName='formGroup'
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            placeholder={'Select Status'}
                            options={statusOptions}
                            touched={touched}
                            errors={errors}
                            values={values}
                            required
                          />
                          <InputText
                            type='number'
                            label='Order Number'
                            name='orderNumber'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            required
                          />
                          <CustomTextArea
                            label='Description'
                            containerClassName='lg:col-span-3 sm:col-span-3 col-span-1'
                            rows='5'
                            name='description'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </ModalBodyComponent>
                    <ModalFooterComponent
                      modalStatus={modalStatus}
                      loading={AddLoader || EditLoader}
                      onCloseModal={() => {
                        closeModal();
                      }}
                      valueChanged={_.isEqual(values, defaultData)}
                    />
                  </Form>
                </>
              }
            />
            {isConfirmModalOpen && (
              <AlertPopup
                isShow={isConfirmModalOpen}
                customTitle={`${
                  values?.isMandatory === 'Yes'
                    ? 'You have selected to create a new mandatory field. Note that all existing records currently do not have values for this field. You may want to review all existing records and provide a value for this field. Upon creation of this field, future new records created or updated of existing records will require a value for this field.'
                    : 'Are you sure you want to save this user defined field?'
                } `}
                closeModal={closeConfirmModal}
                handleSubmit={() => handleSubmit(values)}
                modalStatus={modalStatus}
                loading={isLoading}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default UdfModel;
