import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';
import { getRgbaValue, rem } from '../../../utils/style';

export const PlanContainer = styled.div`
  background: ${COLORS.White};
  padding: ${rem(20)};
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};
  margin-bottom: ${rem(16)};
  transition: all 0.2s ease-in-out;
  &:hover {
    border: ${rem(1)} solid ${COLORS.OffWhite};
    box-shadow: 0 2px 20px ${COLORS.SoftShadow};
  }

  @media (min-width: 512px) {
    padding: ${rem(20)} ${rem(36)} ${rem(32)} ${rem(20)};
  }
`;
export const PlanContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: ${rem(19)};
  border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};
  gap: ${rem(10)};

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    gap: ${rem(0)};
  }
`;

export const PlanHeaderTitle = styled.div`
  div {
    display: flex;
    column-gap: ${rem(8)};
    margin-bottom: ${rem(4)};
  }
`;

export const PlanHeaderButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};

  @media (min-width: 512px) {
    flex-direction: row;
    gap: ${rem(8)};
  }
`;

export const PlanContainerDetails = styled.div`
  column-gap: ${rem(15)};
  row-gap: ${rem(22)};
  margin-top: ${rem(22)};
`;

export const Divider = styled.div`
  margin: ${rem(22)} 0;
  border-top: ${rem(1)} solid ${COLORS.CulturedWhite};
`;

export const PlanUploadedDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${rem(8)};
  row-gap: ${rem(8)};
  margin-top: ${rem(24)};
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  .btnTag {
    /* border: none; */
    transition: all 300ms ease;
    span {
      transition: inherit;
      line-height: 8px;
    }
  }
  > button {
    position: relative;
    z-index: initial;
    transition: all 300ms ease;
    &:first-child {
      &:hover {
        color: ${COLORS.Iris};
        border-color: ${COLORS.Iris};
        .btnTag {
          border-color: ${COLORS.Iris};
          background: ${COLORS.Iris};
          span {
            color: ${COLORS.White};
          }
        }
        &:after {
          content: '';
          background: #969696;
          z-index: 10;
          background: ${COLORS.Iris};
        }
      }
      &:after {
        content: '';
        height: 107%;
        width: 1px;
        background: #d7d7d7;
        top: -1px;
        position: absolute;
        right: -1px;
        z-index: 0;
        transition: all 300ms ease;
      }
    }
    &:last-child {
      &:hover {
        color: ${COLORS.Purple};
        border-color: ${COLORS.Purple};
        &:after {
          content: '';
          background: #969696;
          z-index: 9;
          background: ${COLORS.Purple};
        }
      }
      &:after {
        content: '';
        height: 107%;
        width: 1px;
        background: #d7d7d7;
        top: -1px;
        position: absolute;
        left: -1px;
        /* z-index: 9999; */
        transition: all 300ms ease;
      }
    }

    &:not(:first-child) {
      margin-left: -1px;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:first-child {
      border-right-color: inherit;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-color: inherit;
    }
  }
`;

export const DocumentContainer = styled.div`
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  margin: ${rem(16)} ${rem(0)};
  padding: ${rem(8)} ${rem(8)} ${rem(16)} ${rem(8)};
`;

// plan MOdel

interface StatusBoxTypes {
  status: string;
}

export const StatusBox = styled.div<StatusBoxTypes>`
  display: flex;
  gap: 3px;
  align-items: center;
  margin-top: 4px;
  .material-symbols-outlined {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    font-size: 17px;
    color: ${(props) =>
      props.status === 'Rejected'
        ? COLORS.Red
        : props.status === 'Approved'
        ? COLORS.Green
        : props.status === 'Pending'
        ? COLORS.Iris
        : ''};
  }
`;

export const SwitchContainer = styled.div`
  margin-bottom: 18px;
  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background: ${COLORS.Green};
  }

  /* input:focus + .slider {
    background: ${COLORS.Green};
  } */

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const SmallButton = styled.div`
  position: relative;
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  border: ${rem(1)} solid ${getRgbaValue(`${COLORS.Purple}`, 1)};

  position: relative;
  border-radius: ${rem(6)};
  padding: ${rem(3)} ${rem(8)};
  transition: all 300ms ease;

  color: ${COLORS.Purple};

  &:not(:disabled):hover {
    box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${getRgbaValue(`${COLORS.Iris}`, 0.24)};
  }

  &:not(:disabled).active {
    box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${getRgbaValue(`${COLORS.Iris}`, 0.44)};
  }

  &:disabled {
    border: 1px solid ${getRgbaValue(`${COLORS.Purple}`, 0.2)};
    color: ${getRgbaValue(`${COLORS.Purple}`, 0.5)};
  }

  .spinner-container {
    width: ${rem(15)};
    height: ${rem(14)};
  }
`;
