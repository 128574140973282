/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import {
  OptionAwardService,
  type Wealthlane_AwardService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-award-services';

const usePostReleaseOptionAwards = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setCheckedValues: any,
  closeEmailAlert: () => void
): UseMutationResult<
  Wealthlane_AwardService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        OptionAwardService.postApiAwardApiAppOptionAwardSendParticipantsSignUpLinks(payload)
      );
    },
    {
      onSuccess: async (
        response: Wealthlane_AwardService_Dtos_Response_ResponseDto | undefined
      ) => {
        if (response != null) {
          closeEmailAlert();
          setCheckedValues([]);
          toast.success(<ToastComponent label={'Success'} message={'Email sent'} />);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
      onSettled: async () => {
        closeEmailAlert();
        await queryClient.invalidateQueries('getOptionParticipantList');
      },
    }
  );
};

export default usePostReleaseOptionAwards;
