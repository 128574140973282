import { type UseQueryResult, useQuery } from 'react-query';
import {
  BffService,
  // eslint-disable-next-line camelcase
  type Wealthlane_ReportService_Dtos_AdminDashboardDto,
} from '../../services/wealthlane-report-services';

const useGetAdminDashboard = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
  // eslint-disable-next-line camelcase
): UseQueryResult<Wealthlane_ReportService_Dtos_AdminDashboardDto | undefined, unknown> => {
  const adminDashboardList = useQuery(['getAdminDashboardList'], async () => {
    return await handleRequest(BffService.getApiReportApiAppBFFAdminDashboard());
  });

  return adminDashboardList;
};

export default useGetAdminDashboard;
