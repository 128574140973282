import styled from 'styled-components';
import { COLORS } from '../../../../constants/style/color';
import { getRgbaValue, rem } from '../../../../utils/style';
import { style } from '@motionone/dom';

export const LibraryContainer = styled.div`
  display: flex;
  background: ${COLORS.White};
  height: ${rem(758)};
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};

  .left-space {
    padding-left: ${rem(32)};

    @media (max-width: 1024px) {
      padding-right: ${rem(32)};
    }
  }

  .right-space {
    padding-right: ${rem(32)};

    @media (max-width: 1024px) {
      padding-left: ${rem(32)};
    }
  }

  .confirm {
    display: flex;
    flex-direction: column;
    line-height: 24px;
    &-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }
    &-subtitle {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 18px;
    }
  }

  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    .material-symbols-outlined {
      font-size: 120px;
      margin-bottom: 20px;
    }
    &-subtitle {
    }
  }
`;

export const RightSideBar = styled.div`
  padding: ${rem(25)} ${rem(32)};

  .view-block {
    height: 720px;
    max-height: 805px;
    overflow: auto;

    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.SpanishGray};
      border-radius: 7px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .tax-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 90%;
    padding-bottom: 12px;
    border-bottom: 1px solid ${COLORS.CulturedWhite};
  }

  .tax-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0px;

    .copy-button {
      display: flex;
      justify-content: center;
      margin: 40px;
    }
  }
`;

export const CopyTaxDataBlock = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const LeftSideBar = styled.div`
  max-width: ${rem(318)};
  padding: ${rem(10)} ${rem(14)};
  height: ${rem(758)};
  border-right: ${rem(1)} solid ${COLORS.LightGray};

  .copy-tax-button {
    width: 100%;
    padding: 14px;
    border-top: ${rem(1)} solid ${COLORS.LightGray};
  }

  .legend-block {
    display: flex;
    flex-direction: column;
    padding: ${rem(14)};
    gap: ${rem(5)};
    border-top: ${rem(1)} solid ${COLORS.LightGray};

    .duplicateIndex {
      width: ${rem(8)};
      height: ${rem(8)};
      margin-right: ${rem(4)};
      background-color: ${COLORS.Iris};
      border-radius: ${rem(100)};
    }
  }

  .tax-item {
    margin-top: 14px;
    width: 290px;
    height: 550px;
    flex-shrink: 0;
    border-radius: 10px;
    background: ${COLORS.White};
  }

  .tax-library-radio-wrapper-select-all {
    font-size: 16px;
    line-height: 22px;
    color: ${COLORS.Gray};
    position: relative;
    display: flex;
    align-items: center;
    padding: ${rem(12)};
    cursor: pointer;

    .checkboxWrapper {
      margin-right: 12px;

      input[type='checkbox']::before {
        border-radius: 3px;
      }

      input {
        width: 18px;
        height: 18px;
      }
    }

    .tax-library-radio--label {
      cursor: pointer;
      width: 100%;

      .cellTitle {
        overflow: visible;
      }
    }
  }

  .tax-library-radio-wrapper {
    font-size: 16px;
    line-height: 22px;
    color: ${COLORS.Gray};
    position: relative;
    display: flex;
    align-items: center;
    padding: ${rem(12)};

    cursor: pointer;

    .checkboxWrapper {
      margin-right: 12px;

      input[type='checkbox']::before {
        border-radius: 3px;
      }

      input {
        width: 18px;
        height: 18px;
      }
    }

    .tax-library-radio--label {
      cursor: pointer;
      width: 100%;

      .cellTitle {
        overflow: visible;
      }
    }

    :hover {
      background-color: ${getRgbaValue(COLORS.Gray, 0.1)};
      border-radius: ${rem(4)};
    }

    &.active {
      background-color: ${COLORS.LightPurple};
    }
  }

  @media (max-width: 1024px) {
    width: ${rem(318)};
  }

  @media (max-width: 768px) {
    width: ${rem(200)};
  }

  @media (max-width: 640px) {
    width: ${rem(150)};
  }
  @media (max-width: 512px) {
    width: ${rem(100)};
  }
`;
