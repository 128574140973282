import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { getRgbaValue, rem } from '../../utils/style';

interface DropdownType {
  // remove string as type
  width?: string;
  height?: string;
  padding?: string;
  outerPadding?: string;
  contentHeight?: string;
}

export const CustomizeDropdown = styled.div`
  .error-message {
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    color: ${COLORS.Gray};
    margin-top: ${rem(6)};
    transition: all 0.2s linear;
  }

  &:before {
    content: '';
    background: rgb(22 22 22 / 40%);
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.2s linear;
    opacity: 0;
    visibility: hidden;
  }
  &.active {
    &:before {
      content: '';
      opacity: 1;
      visibility: visible;
    }
  }

  .dropdownContainer {
    position: relative;
    &.active {
      z-index: 2;
      .dropdownListContainer {
        visibility: visible;
        height: auto;
        width: auto;
        min-width: 230px;
        max-width: 450px;
        max-height: 425px;
        opacity: 1;
        /* transition: max-height 0.7s, opacity 3s, visibility 4s ease; */
      }
    }

    label {
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      font-weight: 500;
      margin-bottom: 4px;
      display: inline-block;
    }

    .dropdownButton {
      width: 100%;
      height: 40px;
      border-radius: 6px;
      border: 1px solid ${COLORS.LightGray};
      padding: ${rem(12)} ${rem(14)};
      background: ${COLORS.White};
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.2s linear;

      &:hover {
        border: 0.1rem solid ${COLORS.Blue};
        outline: none;
      }
      &.is-invalid {
        border: 1px solid ${COLORS.Red};
      }
      .dropdownTitle {
        font-size: 15px;
        text-align: left;
        color: #969696;
      }
      .placeholderTitle {
        font-size: 15px;
        text-align: left;
        color: ${COLORS.Black};
      }
    }

    .dropdownListContainer {
      padding: ${rem(20)} ${rem(14)};
      height: auto;
      width: auto;
      background: ${COLORS.White};
      box-shadow: 0px 2px 20px ${COLORS.SoftShadow};
      border-radius: 11.2778px;
      margin-top: 8px;
      /* max-height: 0; */
      visibility: hidden;
      opacity: 0;
      transition: max-height 0.6s ease;
      position: absolute;
      z-index: 99;
      left: 0;

      @media (min-width: 512px) {
        width: 360px;
      }
    }

    .dropdownSearchContainer {
      padding: ${rem(12)} ${rem(10)};
      overflow-y: auto;
      padding-bottom: 0;
      min-height: 320px;
      height: 320px;
      max-height: 100%;

      &.containerSpace {
        margin-top: ${rem(8)};
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 7px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${COLORS.SpanishGray};
        border-radius: 7px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .dropdownSearchItem {
      font-size: 14px;
      line-height: 18px;
      padding-bottom: 10px;
      color: ${COLORS.Black};
      position: relative;
      display: flex;
      align-items: center;

      &:last-child {
        padding-bottom: 0px;
      }

      .checkboxWrapper {
        margin-right: 12px;

        input[type='checkbox']::before {
          border-radius: 3px;
        }

        input {
          width: 18px;
          height: 18px;
        }
      }

      &-label {
        cursor: pointer;
      }
    }

    .selectedDropdownListInfo {
      color: ${COLORS.Gray};
      margin-top: ${rem(12)};
    }
  }
`;

export const ElectionDropdownContainer = styled.div`
  .error-message {
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    color: ${COLORS.Gray};
    margin-top: ${rem(6)};
    transition: all 0.2s linear;
  }

  &:before {
    content: '';
    background: rgb(22 22 22 / 40%);
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.2s linear;
    opacity: 0;
    visibility: hidden;
  }
  &.active {
    &:before {
      content: '';
      opacity: 1;
      visibility: visible;
    }
  }

  .dropdownContainer {
    position: relative;
    &.active {
      z-index: 2;
      .dropdownListContainer {
        visibility: visible;
        height: fit-content;
        max-height: 425px;
        opacity: 1;
      }
    }

    label {
      font-size: ${rem(16)};
      display: inline-block;
    }

    .dropdownButton {
      width: 100%;
      height: 40px;
      border-radius: 6px;
      border: 1px solid ${COLORS.LightGray};
      padding: ${rem(12)} ${rem(14)};
      background: ${COLORS.White};
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.2s linear;

      &:hover {
        border: 0.1rem solid ${COLORS.Blue};
        outline: none;
      }
      &.is-invalid {
        border: 1px solid ${COLORS.Red};
      }
      .dropdownTitle {
        font-size: 15px;
        text-align: left;
        color: #969696;
      }

      .placeholderTitle {
        font-size: 15px;
        text-align: left;
        color: ${COLORS.Black};
      }
    }
    .dropdownListContainer {
      padding: ${rem(10)} ${rem(14)};
      height: 50px;
      width: 100%;
      background: ${COLORS.White};
      box-shadow: 0px 2px 20px ${COLORS.SoftShadow};
      border-radius: 11.2778px;
      margin-top: 8px;
      /* max-height: 0; */
      visibility: hidden;
      opacity: 0;
      transition: max-height 0.6s ease;
      position: absolute;
      z-index: 99;
      left: 0;
    }

    .dropdownSearchContainer {
      padding: ${rem(5)};
      margin-top: ${rem(10)};
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.SpanishGray};
      border-radius: 7px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .dropdownSearchItem {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 10px;
    color: ${COLORS.Black};
    position: relative;
    display: flex;
    align-items: center;

    .checkboxWrapper {
      margin-right: 12px;

      input[type='checkbox']::before {
        border-radius: 3px;
      }

      input {
        width: 18px;
        height: 18px;
      }
    }

    &-label {
      cursor: pointer;
    }
  }

  .selectedDropdownListInfo {
    color: ${COLORS.Gray};
    margin-top: ${rem(12)};
  }
`;
export const DropdownContainer: any = styled.div<DropdownType>`
  .disabled {
    pointer-events: none;
  }

  .error-message {
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    color: ${COLORS.Gray};
    margin-top: ${rem(6)};
    transition: all 0.2s linear;
  }

  &:before {
    content: '';
    background: rgb(22 22 22 / 40%);
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    transition: all 0.2s linear;
    opacity: 0;
    visibility: hidden;
  }
  &.active {
    z-index: 3;
    &:before {
      content: '';
      opacity: 1;
      visibility: visible;
    }
  }

  .dropdownContainer {
    position: relative;
    &.active {
      z-index: 3;
      .dropdownListContainer {
        visibility: visible;
        height: ${(props) => (props.height != null ? props.height : '320px')};
        max-height: 425px;
        opacity: 1;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 7px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${COLORS.SpanishGray};
          border-radius: 7px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }

    label {
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      font-weight: 500;
      margin-bottom: 4px;
      display: inline-block;
    }

    .dropdownButton {
      width: 100%;
      height: 40px;
      border-radius: 6px;
      border: 1px solid ${COLORS.LightGray};
      padding: ${rem(12)} ${rem(14)};
      background: ${COLORS.White};
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.2s linear;

      &:hover {
        border: 0.1rem solid ${COLORS.Blue};
        outline: none;
      }
      &.is-invalid {
        border: 1px solid ${COLORS.Red};
      }
      &.disabled-button {
        background-color: ${COLORS.OffWhite};
        pointer-events: none;
      }

      .dropdownTitle {
        font-size: 15px;
        text-align: left;
        color: #969696;
      }
      .placeholderTitle {
        font-size: 15px;
        text-align: left;
        color: ${COLORS.Black};
      }
    }

    .dropdownListContainer {
      padding: ${(props) =>
        props.outerPadding != null ? props.outerPadding : `${rem(15)} ${rem(14)}`};
      width: 100%;
      background: ${COLORS.White};
      box-shadow: 0px 2px 20px ${COLORS.SoftShadow};
      border-radius: 11.2778px;
      margin-top: 8px;
      visibility: hidden;
      opacity: 0;
      transition: max-height 0.6s ease;
      position: absolute;
      z-index: 99;
      left: 0;

      @media (min-width: 768px) {
        height: ${(props) => (props.height != null ? props.height : '800px')};
        width: ${(props) => (props.width != null ? props.width : '700px')};
      }
    }

    .dropdownListWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .dropdownSearchContainer {
      padding: ${(props) => (props.padding != null ? props.padding : `${rem(12)} ${rem(10)}`)};
      overflow-y: auto;
      padding-bottom: 0;
      /* min-height: ${(props) => (props.height != null ? props.height : '290px')}; */
      height: ${(props) => (props.contentHeight != null ? props.contentHeight : '290px')};
      max-height: 100%;

      &.containerSpace {
        margin-top: ${rem(8)};
        height: ${(props) => (props.contentHeight != null ? props.contentHeight : '')};
        min-height: ${(props) => (props.contentHeight != null ? props.contentHeight : '')};
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 7px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${COLORS.SpanishGray};
        border-radius: 7px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .dropdownSearchItem {
      font-size: 14px;
      line-height: 18px;
      color: ${COLORS.Black};
      position: relative;
      display: flex;
      align-items: center;
      padding: ${rem(3)};

      .checkboxWrapper {
        margin-right: 12px;

        input[type='checkbox']::before {
          border-radius: 3px;
        }

        input {
          width: 18px;
          height: 18px;
        }
      }

      &-label {
        cursor: pointer;
      }

      :hover {
        background-color: ${getRgbaValue('#f3f3f3cc', 0.8)};
        border-radius: ${rem(4)};
      }
    }

    .selectedDropdownListInfo {
      color: ${COLORS.Gray};
      margin-top: ${rem(12)};
    }

    /* &.expand-vert {    
    &.show {
      .modal-dialog {
        animation: modal-expand-vert-animation .5s ease;
      }
    }
    
    &.hiding {
      .modal-dialog {
        animation: modal-expand-vert-animation-out .25s ease;
      }
    }
  }
  @keyframes modal-expand-vert-animation {
  from {
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0deg);
  }
}

@keyframes modal-expand-vert-animation-out {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(90deg);
  }
} */
  }

  &.column-dropdown {
    .dropdownContainer.active .dropdownListContainer {
      height: ${rem(400)};
    }

    .dropdownListContainer {
      padding: ${rem(22)} 0 ${rem(20)} ${rem(6)};
    }

    .dropdownSearchContainer {
      padding: 0;
      height: 308px;
      border-bottom: 1px solid ${COLORS.CulturedWhite};
      margin-bottom: ${rem(14)};
    }

    .dropdownListWrapper {
      padding-right: ${rem(5)};
    }

    .dropdownSearchItem {
      margin-bottom: ${rem(8)};
      cursor: pointer;
      padding: ${rem(10)} ${rem(14)} ${rem(10)} ${rem(14)};
    }

    .dropdownContainer label {
      margin-bottom: 0;
    }

    .dropdownListWrapperButton {
      padding-right: ${rem(14)};
    }
  }
`;
