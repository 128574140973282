/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormSelect from '../../../components/dropdown/formSelect';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
import * as Yup from 'yup';
import {
  CustomerRoleService,
  type Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto,
} from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { SYSTEM_USERS_VALIDATION } from '../../../utils/validations/validations';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import { getFilteredPermissionData } from '../../../utils';
import Heading from '../../../components/typography';
import {
  CheckBoxFlex,
  FieldLevelPermissionsGroups,
  ListWrapper,
} from '../system-roles/roles.styled';
import { SettingDivider } from '../settings.styled';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import { Label } from '../../../components/input-text/input.styled';
import { HeadingMain } from '../../../constants/style/common.styled';
import Tag from '../../../components/lozenge/tags';
import {
  ROLES_PERMISSION_LIST,
  SORTED_FIELD_PERMISSION,
  SORTED_MODULE_PERMISSION,
} from '../../../constants/common';
import {
  capitalizeFirstLetter,
  handleFilterPermissionList,
  handleSortPermissionNames,
  phoneFormat,
} from '../../../utils/global';
import {
  useGetAllDepartments,
  useGetAllJobTitles,
  useGetCountryCode,
  usePostSystemUsers,
  useWealthlaneCustomerApi,
} from '../../../hooks';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import _, { isNull } from 'lodash';

interface AddUserType {
  isModalOpen: boolean;
  closeModal: () => void;
  isEditForm?: boolean;
  modalStatus: ModalStatusType;
  setModalStatus?: any;
  status?: number;
  usersInitialData: { data: any; id: string };
  isLoading: boolean;
  tableInfo: TableParamsType;
  setTableInfo: any;
  dataRow?: string;
  userStatusRow?: boolean;
}

const UserModel = ({
  isModalOpen,
  isEditForm,
  closeModal,
  modalStatus,
  setModalStatus,
  usersInitialData,
  isLoading,
  dataRow,
  userStatusRow,
}: AddUserType): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();

  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsGroups, fieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList.auth?.policies
  );

  const filterNonFieldLevelPermission = handleSortPermissionNames(
    handleFilterPermissionList(nonFieldLevelPermissionsGroups),
    SORTED_MODULE_PERMISSION
  );
  const filterFieldLevelPermission = handleSortPermissionNames(
    handleFilterPermissionList(fieldLevelPermissionsGroups),
    SORTED_FIELD_PERMISSION
  );

  const [role, setRole] = useState<
    Wealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleDto[] | null | undefined | any
  >();
  const [roleList, setRoleList] = useState([]);
  useEffect(() => {}, [role]);

  const CustomerTitleCustomerTitleList = useGetAllJobTitles(handleRequest);
  const CustomerDepartmentCustomerDepartmentList = useGetAllDepartments(handleRequest);
  const { data: countryCodeOptions } = useGetCountryCode(handleRequest);
  const [, setModalLoading] = useState<boolean | null>(null);
  const maxResultCount = 1000;
  const sorting = 'Name ASC';
  const filter = '';
  const skipCount = 0;
  const allFieldValues = userAuthList.localization?.values?.Wealthlane;

  const getData = (): void => {
    void handleRequest(
      CustomerRoleService.getApiCustomerApiAppCustomerRoleCustomerRole(
        filter,
        sorting,
        skipCount,
        maxResultCount
      )
    ).then((data: any) => {
      setRole(data.items);
      if (data != null) {
        const list = data?.items.map((data: any) => {
          return {
            label: data?.roleName,
            value: data.id,
            permissions: data.permissions ?? [],
          };
        });
        setRoleList(list);
      }
    });
  };
  interface Role {
    label: string;
    value: string;
  }

  useEffect(() => {
    if (roleList != null && roleList.length > 0) {
      const r: any = roleList.find((l: Role) => l.value === usersInitialData.data.customerRoleId);
      if (r !== undefined && r !== null) {
        // setSelectedRole(r.label);
      }
    }
  }, [usersInitialData, roleList]);

  useEffect(() => {
    getData();
  }, []);

  const { mutate, isLoading: postLoading } = usePostSystemUsers(
    handleRequest,
    closeModal,
    usersInitialData.id
  );
  const handleSubmit = (values: any): void => {
    const payload = {
      ...values,
      firstName: capitalizeFirstLetter(values.firstName),
      middleName: capitalizeFirstLetter(values.middleName),
      lastName: capitalizeFirstLetter(values.lastName),
    };
    mutate(payload);
  };

  const getPermissionList = (value: string): any => {
    const permissionList: any = roleList?.find((item: any) => item?.value === value);
    const filterData =
      permissionList?.permissions
        .map((item: string) => item.replaceAll('.', '-'))
        .reduce((a: any, v: any) => ({ ...a, [v]: true }), {}) ?? {};

    return filterData;
  };

  const defaultCountry = countryCodeOptions?.find((item: any) => item.isOtherCountry === false);

  const initialValue = {
    ...usersInitialData.data,
    permissions: getPermissionList(usersInitialData.data.customerRoleId) ?? [],
    countryCallingCodeId: (usersInitialData?.data?.countryCallingCodeId as boolean)
      ? usersInitialData?.data?.countryCallingCodeId
      : (modalStatus === 'Edit' || modalStatus === 'View') &&
        isNull(usersInitialData?.data?.countryCallingCodeId)
      ? null
      : defaultCountry?.value,
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValue}
      validationSchema={Yup.object().shape(SYSTEM_USERS_VALIDATION(isEditForm))}
      onSubmit={handleSubmit}>
      {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            title={`${modalStatus} System User`}
            loading={isLoading}
            valueEdited={_.isEqual(values, initialValue)}
            size='lg'
            children={
              <>
                <div className='flex flex-col justify-between'>
                  <div>
                    <Form>
                      <ModalBodyComponent>
                        <>
                          <fieldset>
                            <div className='grid gap-x-[14px] sm:grid-cols-2 lg:grid lg:grid-cols-3'>
                              <InputText
                                type='text'
                                label='First Name'
                                name='firstName'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='FirstMiddleLastName'
                                isEdit={modalStatus === 'Edit'}
                                required
                              />
                              <InputText
                                type='text'
                                label='Middle Name'
                                name='middleName'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='FirstMiddleLastName'
                                isEdit={modalStatus === 'Edit'}
                              />
                              <InputText
                                type='text'
                                label='Last Name'
                                name='lastName'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='FirstMiddleLastName'
                                isEdit={modalStatus === 'Edit'}
                                required
                              />
                            </div>
                            <div className='grid sm:grid-cols-2 gap-x-[14px]'>
                              <FormSelect
                                type='text'
                                label={'Title/Position'}
                                name='customerTitleId'
                                viewName='customerTitle'
                                containerClassName='formGroup'
                                onChange={handleChange}
                                errors={modalStatus === 'View' ? {} : errors}
                                touched={touched}
                                options={CustomerTitleCustomerTitleList?.data}
                                values={values}
                                setFieldValue={setFieldValue}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                arrValue={values?.customerTitleId}
                                fieldLabelName='JobTitle'
                                isEdit={modalStatus === 'Edit'}
                              />
                              <FormSelect
                                type='text'
                                label={'Department'}
                                name='customerDepartmentId'
                                viewName='customerDepartment'
                                containerClassName='formGroup'
                                onChange={handleChange}
                                errors={modalStatus === 'View' ? {} : errors}
                                touched={touched}
                                options={CustomerDepartmentCustomerDepartmentList?.data}
                                values={values}
                                setFieldValue={setFieldValue}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                fieldLabelName='Department'
                                isEdit={modalStatus === 'Edit'}
                                arrValue={values?.customerDepartmentId}
                              />
                            </div>
                            <div className='grid sm:grid-cols-12 gap-x-[20px] '>
                              <div className='sm:col-span-3'>
                                <InputText
                                  type='text'
                                  label='Employee ID'
                                  name='employeeId'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  inputType={modalStatus}
                                  disabled={modalStatus === 'View'}
                                  fieldLabelName='EmployeeId'
                                  isEdit={modalStatus === 'Edit'}
                                  required
                                />
                              </div>
                              <div className='flex gap-x-[20px]'>
                                <div className='flex-column'>
                                  <Label htmlFor={'contactNumber'}>
                                    Contact Number (Work) (Optional)
                                  </Label>
                                  <div className='flex flex-col sm:flex-row gap-x-[12px]'>
                                    <FormSelect
                                      name={`countryCallingCodeId`}
                                      viewName='countryCallingCode'
                                      onChange={(e: any) => {
                                        const isOther = countryCodeOptions?.find(
                                          (obj) => obj?.value === e
                                        )?.isOtherCountry;
                                        handleChange(e);
                                        setFieldValue('phone', '');
                                        setFieldValue('phoneIsOtherCountry', isOther);
                                        setFieldTouched('phone', false);
                                      }}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      arrValue={values?.countryCallingCodeId}
                                      placeholder={'Select'}
                                      errorValue={errors?.countryCallingCodeId}
                                      touchedValue={touched?.countryCallingCodeId}
                                      options={countryCodeOptions}
                                      touched={touched}
                                      errors={modalStatus === 'View' ? {} : errors}
                                      disabled={modalStatus === 'View'}
                                      width={'220px'}
                                      fieldLabelName='PhoneNumber'
                                      isEdit={modalStatus === 'Edit'}
                                      required
                                    />
                                    <InputText
                                      type='text'
                                      name={`phone`}
                                      onChange={(e: any) => {
                                        countryCodeOptions?.find(
                                          (obj) => obj?.value === values?.countryCallingCodeId
                                        )?.isOtherCountry ?? false
                                          ? handleChange(e)
                                          : phoneFormat(`phone`, setFieldValue, e.target.value);
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      inputType={'contact'}
                                      width={'200px'}
                                      disabled={modalStatus === 'View'}
                                      fieldLabelName='PhoneNumber'
                                      isEdit={modalStatus === 'Edit'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <SettingDivider></SettingDivider>

                          <div className='grid sm:grid-cols-2 mt-[24px] gap-x-[14px]'>
                            <FormSelect
                              type='text'
                              label={'System Role'}
                              name='customerRoleId'
                              viewName='customerRole'
                              // containerClassName='formGroup'
                              onChange={(value: string) => {
                                const permissionList: any = roleList?.find(
                                  (item: any) => item?.value === value
                                );
                                const filterData =
                                  permissionList?.permissions
                                    .map((item: string) => item.replaceAll('.', '-'))
                                    .reduce((a: any, v: any) => ({ ...a, [v]: true }), {}) ?? {};
                                setFieldValue('customerRoleId', value);
                                setFieldValue('permissions', filterData);
                              }}
                              errors={errors}
                              touched={touched}
                              options={roleList}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputType={modalStatus}
                              disabled={modalStatus === 'View'}
                              required
                            />
                            <InputText
                              type='email'
                              className='mb-0'
                              label='Work Email (Username)'
                              name='email'
                              onChange={handleChange}
                              errors={errors}
                              inputType={modalStatus}
                              touched={touched}
                              disabled={dataRow === 'Approved' || modalStatus === 'View'}
                              fieldLabelName='EmployeeEmail'
                              isEdit={modalStatus === 'Edit'}
                              required
                            />
                          </div>

                          {(values.customerRoleId as boolean) && (
                            <div className='borderTop'>
                              <div className='mb-4'>
                                <Label>View Only Permission</Label>
                              </div>
                              {(role as boolean) && role.length > 0 && (
                                <>
                                  <div className='table-wrapper'>
                                    <div className='grid sm:grid-cols-12 gap-x-5'>
                                      <div className='col-span-5'>
                                        <Tag variant='info' title='Module Level Permission' />
                                      </div>
                                      <div className='col-span-7 grid grid-cols-12'>
                                        <Heading
                                          variant='body-xs'
                                          title='All'
                                          className='col-span-2 roleSubTitle'
                                        />
                                        {ROLES_PERMISSION_LIST.map((col, i) => (
                                          <Heading
                                            key={`header-table-${i}`}
                                            variant='body-xs'
                                            title={col}
                                            className='col-span-2 roleSubTitle'
                                          />
                                        ))}
                                      </div>
                                    </div>
                                    {Object.keys(
                                      (role?.filter(
                                        (item: any) => item.id === values?.customerRoleId
                                      )[0]?.isDefaultAdminRole as boolean)
                                        ? filterNonFieldLevelPermission
                                        : handleFilterPermissionList(filterNonFieldLevelPermission)
                                    ).map((data: string) => {
                                      const allInfo = filterNonFieldLevelPermission[data].filter(
                                        (item: any) => !(item.isDisabled as boolean) && item
                                      );

                                      const checkRoles = allInfo?.map(
                                        (item: any) =>
                                          !!(values.permissions[
                                            item.key.replaceAll('.', '-')
                                          ] as boolean)
                                      );

                                      const labelKey =
                                        (allInfo as boolean) &&
                                        allInfo.length > 0 &&
                                        allInfo[0].key;
                                      return (
                                        <ListWrapper className='grid grid-cols-12 gap-x-5 items-center'>
                                          <HeadingMain className='col-span-5'>
                                            {(allFieldValues as boolean)
                                              ? allFieldValues[labelKey]
                                              : '-'}
                                          </HeadingMain>
                                          <CheckBoxFlex className='col-span-7 grid grid-cols-12'>
                                            <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                              <CustomCheckBox
                                                name={`nonFieldPermissions${data}All`}
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                disabled
                                                arrValue={checkRoles.every((v: boolean) => v)}
                                              />
                                            </div>
                                            {ROLES_PERMISSION_LIST.map((item, index) => {
                                              const itemData =
                                                filterNonFieldLevelPermission[`${data}`][index];

                                              return (
                                                <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                                  <CustomCheckBox
                                                    name={`permissions[${String(
                                                      itemData.key.replaceAll('.', '-') ?? ''
                                                    )}]`}
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    id={itemData.key}
                                                    arrValue={
                                                      (item === itemData.text &&
                                                        values.permissions[
                                                          itemData.key.replaceAll('.', '-')
                                                        ]) ||
                                                      false
                                                    }
                                                    disabled
                                                  />
                                                </div>
                                              );
                                            })}
                                          </CheckBoxFlex>
                                        </ListWrapper>
                                      );
                                    })}
                                  </div>
                                  <FieldLevelPermissionsGroups>
                                    <div className='grid grid-cols-12 gap-x-5 roleTitle'>
                                      <div className='col-span-5'>
                                        <Tag variant='info' title='Field Level Permission' />
                                      </div>
                                    </div>
                                    {Object.keys(filterFieldLevelPermission).map((data: string) => {
                                      const allInfo = filterFieldLevelPermission[data].filter(
                                        (item: any) => !(item.isDisabled as boolean) && item
                                      );

                                      const checkRoles = allInfo.map(
                                        (item: any) =>
                                          !!(values.permissions[
                                            item.key.replaceAll('.', '-')
                                          ] as boolean)
                                      );
                                      const labelKey =
                                        (allInfo as boolean) &&
                                        allInfo.length > 0 &&
                                        allInfo[0].key;
                                      return (
                                        <ListWrapper className='grid grid-cols-12 gap-x-5 items-center'>
                                          <HeadingMain className='col-span-5'>
                                            {(allFieldValues as boolean)
                                              ? allFieldValues[labelKey]
                                              : '-'}
                                          </HeadingMain>
                                          <CheckBoxFlex className='col-span-7 grid grid-cols-12'>
                                            <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                              <CustomCheckBox
                                                name={`permissions${data}All`}
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                disabled
                                                checked={checkRoles.every((v: boolean) => v)}
                                              />
                                            </div>
                                            {ROLES_PERMISSION_LIST.map((item, index) => {
                                              const itemData = handleFilterPermissionList(
                                                fieldLevelPermissionsGroups
                                              )[`${data}`][index];

                                              return (
                                                <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                                  <CustomCheckBox
                                                    name={`permissions[${String(
                                                      itemData.key.replaceAll('.', '-') ?? ''
                                                    )}]`}
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    id={itemData.key}
                                                    arrValue={
                                                      (item === itemData.text &&
                                                        values.permissions[
                                                          itemData.key.replaceAll('.', '-')
                                                        ]) ||
                                                      false
                                                    }
                                                    disabled
                                                  />
                                                </div>
                                              );
                                            })}
                                          </CheckBoxFlex>
                                        </ListWrapper>
                                      );
                                    })}
                                  </FieldLevelPermissionsGroups>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      </ModalBodyComponent>
                      {/* {dataRow === 'Rejected' ? (
                        <ModalFooterComponent
                          onCloseModal={() => {
                            closeModal();
                          }}
                        />
                      ) : ( */}
                      <ModalFooterComponent
                        modalStatus={modalStatus}
                        isEditShow={
                          /*  userStatusRow !== null
                              ? userStatusRow
                              :  */ !(usersInitialData.data.isDefaultAdmin as boolean)
                        }
                        onCloseModal={() => {
                          closeModal();
                        }}
                        loading={postLoading}
                        valueChanged={_.isEqual(values, initialValue)}
                        onEditModal={() => {
                          setModalLoading(true);
                          setModalStatus('Edit');
                        }}
                      />
                      {/* )} */}
                    </Form>
                  </div>
                </div>
              </>
            }
          />
        );
      }}
    </Formik>
  );
};

export default UserModel;
