import { useEffect, useRef, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg/LogoHeader.svg';
import {
  NavMenuContainer,
  NavMenuCon,
  NavMenuMobileContainer,
  ProfileDropdownContainer,
  ProfileDrop,
  ProfileDropdownMenu,
  DropDownMenuSuperAdmin,
  DropDownMenuAdmin,
  TaskSubMenuDropDown,
  MobileNavItem,
  ProfileDropWrapper,
  HeaderNotice,
  NavbarContainerMain,
} from './navbar.styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../pages/configuration/routeSlice';
import AuthService from '../../services/authService';
import CheckPermissions from '../../utils/permission/checkPermission';
import {
  EquityAwardsList,
  MyTaskRouteList,
  TransactionsList,
  ReportList,
  TaskManagementConsole,
} from '../../constants/innerRoutesList';
import { NavPermissionCheck } from '../../utils/global';
import { selectToken } from '../../pages/auth/authSlice';
import Heading from '../typography';
import Notification from './notification';
import AnimationWrapper from '../animation/animationWrapper';
import NotificationModal from './notification/notificationModal';
import { setNotify } from '../../pages/auth/notify';
import Button from '../button';
// eslint-disable-next-line camelcase
import { type Wealthlane_NotificationService_DTOs_BellNotificationDto } from '../../services/wealthlane-notification-services';
import useWealthlaneCustomerApi from '../../hooks/useWealthlaneCustomerApi';
import useGetCustomerUserDetails from '../../hooks/get/useGetCustomerUserDetails';
import useWealthlaneNotificationApi from '../../hooks/useWealthlaneNotificationApi';
import useBoolean from '../../hooks/custom-hooks/useBoolean';
import useGetUnseenNotificationCount from '../../hooks/get/useGetUnseenNotificationCount';
import Badge from '../badge';

interface NavbarType {
  isAuth: boolean;
  showNotify: boolean;
  isNotify: boolean;
}

const Navbar = ({ isAuth, showNotify, isNotify }: NavbarType): JSX.Element => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const userAuthList = useSelector(selectAuthRoutesList);
  const authService = new AuthService();
  const { handleRequest } = useWealthlaneNotificationApi();
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const { data: NotificationCount, refetch } = useGetUnseenNotificationCount(handleRequest, token);

  const [openSideNav, setOpenSideNav] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [toggleAwards, setToggleAwards] = useState(false);
  const [toggleTransactions, setToggleTransactions] = useState(false);
  const [toggleReports, setToggleReports] = useState(false);
  const [newMessage, setNewMessage] =
    // eslint-disable-next-line camelcase
    useState<Wealthlane_NotificationService_DTOs_BellNotificationDto>({});

  const allFieldValues = userAuthList.localization?.values?.Wealthlane;

  const [taskList, setTaskList] = useState(false);
  const onSideNavClick = (isClose: boolean): void => {
    setOpenSideNav(!openSideNav);
    if (isClose) {
      document.getElementsByTagName('html')[0].style.overflow = 'unset';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  };

  const filterMyTaskPermissionList = NavPermissionCheck(
    MyTaskRouteList,
    'CustomerService.ApprovalProcess.'
  );

  const filterEquityAwardsList = NavPermissionCheck(EquityAwardsList, 'CustomerService.Customer.');
  const filterTransactionsList = NavPermissionCheck(TransactionsList, 'CustomerService.Customer.');
  const filterTaskManagementConsoleList = NavPermissionCheck(
    TaskManagementConsole,
    'CustomerService.Customer.'
  );
  const filterReportList = NavPermissionCheck(ReportList, 'CustomerService.Customer.');

  const wrapperRef: any = useRef(null);
  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (
        Boolean(wrapperRef.current) &&
        !(wrapperRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setOpenSideNav(false);
        setToggleAwards(false);
        setTaskList(false);
        setOpen(false);
        setToggleReports(false);
        setToggleTransactions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (showNotify) {
      document.body.style.paddingTop = '101px';
    } else {
      document.body.style.paddingTop = '67px';
    }
  }, [showNotify]);

  const storageEventHandler = (): void => {
    dispatch(setNotify({ notify: localStorage.getItem('Notify') === 'Yes' }));
  };

  useEffect(() => {
    dispatch(setNotify({ notify: localStorage.getItem('Notify') === 'Yes' }));
    window.addEventListener('storage', storageEventHandler, false);
  }, []);

  const handleRemoveNotify = (): void => {
    localStorage.setItem('Notify', 'No');
    dispatch(setNotify({ notify: false }));
  };

  useEffect(() => {
    if (isModalOpen) setLoggedIn(false);
  }, [isModalOpen]);

  useEffect(() => {
    if (isLoggedIn && isNotify) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${
            process.env.REACT_APP_WEALTHLANE_API_ENDPOINT ?? ''
          }/api/notification/signalr-hubs/bellNotification`,
          {
            transport: signalR.HttpTransportType.LongPolling,
            accessTokenFactory: () => {
              return token ?? '';
            },
          }
        )
        .configureLogging(signalR.LogLevel.Information)
        .build();

      const start = async (): Promise<any> => {
        try {
          await connection.start();
        } catch (err) {
          setTimeout(() => {
            void start();
          }, 5000);
        }
      };

      connection.onclose(() => {
        void start();
      });
      connection.on('ReceiveBellNotification', (user, message) => {
        void refetch();
        setNewMessage(message);
      });
      // Start the connection.
      void start();
    }
  }, [isLoggedIn]);

  const { handleRequest: customerName } = useWealthlaneCustomerApi();

  const { data: userName } = useGetCustomerUserDetails(customerName, isAuth);
  return (
    <>
      {showNotify && (
        <HeaderNotice>
          <Heading
            variant='body-s'
            title={
              <span className='flex gap-x-2  '>
                Need help? Contact us at
                <a href='support@wealthlane.co' className='underline text-blue-600'>
                  support@wealthlane.co
                </a>
              </span>
            }
          />
          <span
            className='material-symbols-outlined closeIcon'
            onClick={() => {
              handleRemoveNotify();
            }}>
            close
          </span>
        </HeaderNotice>
      )}
      <NavbarContainerMain hasNotify={showNotify} className='nav'>
        <Link to='/'>
          <Logo className='nav-brand' />
        </Link>
        {isAuth && Boolean(token) && (
          <>
            <div className='nav-mobile items-center'>
              <Notification
                NotificationCount={NotificationCount}
                isModalOpen={isModalOpen}
                openModal={() => {
                  openModal();
                }}
              />
              <Button
                title='Menu'
                variant='secondary'
                icon='menu'
                onClick={() => {
                  onSideNavClick(false);
                }}
                btnClassName='nav-menu'
              />
              <NavMenuMobileContainer className={openSideNav ? 'top-0' : '-top-[120%] opacity-0'}>
                <MobileNavItem className='static navMenuItem'>
                  <Heading variant='h6' title='Menu' className='nav-mobile--heading' />
                  <div className='nav-items'>
                    <NavLink
                      to='/overview'
                      className={({ isActive }: { isActive: boolean }) =>
                        isActive ? 'active p-2' : ''
                      }
                      onClick={() => {
                        onSideNavClick(true);
                      }}>
                      <Heading variant='body' title='Overview' />
                    </NavLink>
                    {filterEquityAwardsList?.map(
                      (item: { url: string; permissionName: string; name: string }) => (
                        <NavLink
                          to={item.url}
                          onClick={() => {
                            onSideNavClick(true);
                          }}
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }>
                          <Heading variant='body' title={item.name} />
                        </NavLink>
                      )
                    )}
                    <NavLink
                      to='/participants'
                      className={({ isActive }: { isActive: boolean }) =>
                        isActive ? 'active p-2' : ''
                      }
                      onClick={() => {
                        onSideNavClick(true);
                      }}>
                      <Heading variant='body' title='Participants' />
                    </NavLink>
                    {filterTransactionsList?.map(
                      (item: { url: string; permissionName: string; name: string }) => (
                        <NavLink
                          to={item.url}
                          onClick={() => {
                            onSideNavClick(true);
                          }}
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }>
                          <Heading variant='body' title={item.name} />
                        </NavLink>
                      )
                    )}
                    <NavLink
                      to='/settings/organization-settings/company-profile'
                      className={({ isActive }: { isActive: boolean }) =>
                        isActive ? 'active p-2' : ''
                      }
                      onClick={() => {
                        onSideNavClick(true);
                      }}>
                      <Heading variant='body' title='Settings' />
                    </NavLink>
                  </div>
                </MobileNavItem>

                {filterMyTaskPermissionList != null && filterMyTaskPermissionList.length > 0 && (
                  <MobileNavItem className='static navMenuItem'>
                    <Heading variant='h6' title='My Task' className='nav-mobile--heading' />
                    <div className='nav-items'>
                      {filterMyTaskPermissionList.map((item: any) => (
                        <NavLink
                          to={item.url}
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }
                          onClick={() => {
                            onSideNavClick(true);
                          }}>
                          <Heading variant='body' title={item.name} />
                        </NavLink>
                      ))}
                    </div>
                  </MobileNavItem>
                )}
                <MobileNavItem className='static navMenuItem'>
                  <Heading
                    variant='h6'
                    title={userAuthList?.currentUser?.name}
                    className='nav-mobile--heading'
                  />
                  <div className='nav-items'>
                    <Heading
                      variant='body'
                      title='Log Out'
                      onClick={() => {
                        void authService.logout();
                      }}
                      className='cursor-pointer'
                    />
                    <div className='nav-close'>
                      <Button
                        title='Close'
                        variant='primary-dark'
                        icon='close'
                        onClick={() => {
                          onSideNavClick(true);
                        }}
                        btnClassName='nav-mobile--close'
                      />
                    </div>
                  </div>
                </MobileNavItem>
              </NavMenuMobileContainer>
            </div>
            <NavMenuContainer className='relative nav-main'>
              {/* <CheckPermissions type='View' urlName='overview'> */}
              <NavMenuCon className='static navMenuItem'>
                <NavLink
                  to='/overview'
                  className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}>
                  Overview
                </NavLink>
              </NavMenuCon>
              {/* </CheckPermissions> */}
              <CheckPermissions type='View' urlName='participants'>
                <NavMenuCon className='static navMenuItem'>
                  <NavLink
                    to='/participants'
                    className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}>
                    Participants
                  </NavLink>
                </NavMenuCon>
              </CheckPermissions>
              {filterEquityAwardsList != null && filterEquityAwardsList.length > 0 && (
                <div
                  className='flex items-center gap-x-2 relative'
                  onClick={() => {
                    setToggleAwards(!toggleAwards);
                  }}>
                  <NavMenuCon className='static navMenuItem'>
                    <NavLink
                      to='/awards'
                      className={({ isActive }) =>
                        isActive ? 'active pointer-events-none' : 'pointer-events-none'
                      }>
                      Awards Center
                    </NavLink>
                  </NavMenuCon>
                  {toggleAwards && (
                    <ProfileDropdownMenu ref={wrapperRef} className='nav-subMenu'>
                      {filterEquityAwardsList.map(
                        (item: { url: string; permissionName: string; name: string }) => (
                          <DropDownMenuAdmin>
                            <NavLink
                              to={item.url}
                              onClick={() => {
                                setToggleAwards(!toggleAwards);
                              }}>
                              <h1 className='para' role='button'>
                                {item.name}
                              </h1>
                            </NavLink>
                          </DropDownMenuAdmin>
                        )
                      )}
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}

              {filterReportList?.map(
                (item: { url: string; permissionName: string; name: string }) => (
                  <NavMenuCon className='static navMenuItem'>
                    <NavLink
                      to={item?.url}
                      className={({ isActive }: { isActive: boolean }) =>
                        isActive ? 'active' : ''
                      }>
                      <Heading variant='body' title={item.name} />
                    </NavLink>
                  </NavMenuCon>
                )
              )}
              {/* <div
                className='flex items-center gap-x-2 relative'
                onClick={() => {
                  setToggleReports(!toggleReports);
                }}>
                <NavMenuCon className='static navMenuItem'>
                  <NavLink
                    to='/reports'
                    className={({ isActive }) =>
                      isActive ? 'active pointer-events-none' : 'pointer-events-none'
                    }>
                    Reporting
                  </NavLink>
                </NavMenuCon>
                {toggleReports && (
                  <ProfileDropdownMenu ref={wrapperRef} className='nav-subMenu'>
                    {REPORTS_LIST.map((item) => (
                      <DropDownMenuAdmin>
                        <NavLink
                          to={item.url}
                          onClick={() => {
                            setToggleReports(!toggleReports);
                          }}>
                          <h1 className='para' role='button'>
                            {item.name}
                          </h1>
                        </NavLink>
                      </DropDownMenuAdmin>
                    ))}
                  </ProfileDropdownMenu>
                )}
              </div> */}
              {filterTaskManagementConsoleList?.map(
                (item: { url: string; permissionName: string; name: string }) => (
                  <NavMenuCon className='static navMenuItem'>
                    <NavLink
                      to={item?.url}
                      className={({ isActive }: { isActive: boolean }) =>
                        isActive ? 'active' : ''
                      }>
                      <Heading variant='body' title={item.name} />
                    </NavLink>
                  </NavMenuCon>
                )
              )}
              {filterTransactionsList != null && filterTransactionsList.length > 0 && (
                <div
                  className='flex items-center gap-x-2 relative'
                  onClick={() => {
                    setToggleTransactions(!toggleTransactions);
                  }}>
                  <NavMenuCon className='static navMenuItem'>
                    <NavLink
                      to='/transactions'
                      className={({ isActive }) =>
                        isActive ? 'active pointer-events-none' : 'pointer-events-none'
                      }>
                      Transactions
                    </NavLink>
                  </NavMenuCon>
                  {toggleTransactions && (
                    <ProfileDropdownMenu ref={wrapperRef} className='nav-subMenu'>
                      {filterTransactionsList.map(
                        (item: { url: string; permissionName: string; name: string }) => (
                          <DropDownMenuAdmin>
                            <NavLink
                              to={item.url}
                              onClick={() => {
                                setToggleTransactions(!toggleTransactions);
                              }}>
                              <h1 className='para' role='button'>
                                {item.name}
                              </h1>
                            </NavLink>
                          </DropDownMenuAdmin>
                        )
                      )}
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}

              <NavMenuCon className='static navMenuItem'>
                <NavLink
                  to='/settings'
                  className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}>
                  Settings
                </NavLink>
              </NavMenuCon>
            </NavMenuContainer>
            <ProfileDropdownContainer className='nav-main'>
              {/* {filterMyTaskPermissionList != null && filterMyTaskPermissionList.length > 0 && (
                <div className='flex items-center gap-x-3 border-r-[3px] relative'>
                  <NavMenuCon
                    className='static navMenuItem'
                    onClick={() => {
                      setTaskList(!taskList);
                    }}>
                    <Badge icon='task_alt' variant='primary' title='My Tasks' />
                  </NavMenuCon>
                  {taskList && (
                    <TaskSubMenuDropDown ref={wrapperRef}>
                      {filterMyTaskPermissionList.map((item: any) => {
                        const findKey =
                          allFieldValues != null &&
                          Object.keys(allFieldValues).find((keyVal) =>
                            keyVal.includes(item.permissionName)
                          );
                        return (
                          <DropDownMenuAdmin>
                            <NavLink
                              to={item.url}
                              onClick={() => {
                                setTaskList(!taskList);
                              }}
                              className='w-full flex justify-start'>
                              {findKey != null && findKey !== false && allFieldValues != null
                                ? Boolean(allFieldValues[findKey]) && item?.name
                                : '-'}
                            </NavLink>
                          </DropDownMenuAdmin>
                        );
                      })}
                    </TaskSubMenuDropDown>
                  )}
                </div>
              )} */}
              <Notification
                NotificationCount={NotificationCount}
                isModalOpen={isModalOpen}
                openModal={() => {
                  openModal();
                }}
              />
              <ProfileDropWrapper>
                <ProfileDrop
                  onClick={() => {
                    setOpen(!open);
                  }}>
                  <div className='font-semibold title'>
                    {userName?.firstName != null
                      ? String(userName?.firstName ?? '') + ' ' + String(userName?.lastName ?? '')
                      : ''}
                  </div>
                  <span>
                    <span className='material-symbols-rounded downIcon'>arrow_drop_down</span>
                  </span>
                </ProfileDrop>
                {open && (
                  <ProfileDropdownMenu ref={wrapperRef}>
                    <Link
                      to='/profile'
                      onClick={() => {
                        setOpen(false);
                      }}>
                      <DropDownMenuSuperAdmin>My Profile</DropDownMenuSuperAdmin>
                    </Link>
                    <Link
                      to='/notification'
                      onClick={() => {
                        setOpen(false);
                      }}>
                      <DropDownMenuSuperAdmin>Notification</DropDownMenuSuperAdmin>
                    </Link>
                    <Link
                      to='/security'
                      onClick={() => {
                        setOpen(false);
                      }}>
                      <DropDownMenuSuperAdmin>Security</DropDownMenuSuperAdmin>
                    </Link>
                    <DropDownMenuSuperAdmin
                      className='logoutBtn'
                      onClick={() => {
                        void authService.logout();
                        localStorage.removeItem('reportType');
                      }}>
                      <h1 className='para' role='button'>
                        Logout
                      </h1>
                    </DropDownMenuSuperAdmin>
                  </ProfileDropdownMenu>
                )}
              </ProfileDropWrapper>
            </ProfileDropdownContainer>
          </>
        )}
      </NavbarContainerMain>
      <AnimationWrapper>
        {isModalOpen && (
          <NotificationModal
            handleRequest={handleRequest}
            isModalOpen={isModalOpen}
            newMessage={newMessage}
            closeModal={closeModal}
          />
        )}
      </AnimationWrapper>
    </>
  );
};

export default Navbar;
