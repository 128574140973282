import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';
import { rem } from '../../../utils/style';

export const PageWrapper = styled.div`
  .page {
    gap: ${rem(32)};
    padding-top: ${rem(8)};

    h3 {
      max-width: ${rem(165)};
    }

    &-title {
      position: relative;
      padding-left: ${rem(12)};
      width: ${rem(164)};
      margin-bottom: ${rem(24)};

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: ${rem(3)};
        background-color: ${COLORS.Purple};
        left: 0;
      }

      h3 {
        font-weight: 600;
      }
    }

    .profile-info {
      flex-grow: 1;
    }
  }
`;

export const PageHeader = styled.div`
  margin-bottom: ${rem(24)};

  h3 {
    margin-bottom: ${rem(5)};
  }

  span {
    color: ${COLORS.Gray};
  }
`;

export const PersonalFormWrapper = styled.div`
  .profileForm-title {
    margin-bottom: ${rem(24)};
  }

  .profileForm-button {
    gap: ${rem(8)};
  }

  .profile-email-note {
    display: flex;
    align-items: center;
    gap: ${rem(2)};
    color: ${COLORS.Gray};

    .material-symbols-sharp {
      font-size: ${rem(16)};
    }

    a {
      padding: 0 ${rem(4)};
      color: ${COLORS.Iris};
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

export const ProfileFormContainer = styled.div`
  background: ${COLORS.White};

  border: 1px solid ${COLORS.CulturedWhite};
  border-radius: 6px;
  padding: ${rem(24)} 0 ${rem(22)} 0;

  .profileForm-title {
    color: ${COLORS.Silver};
    margin-bottom: ${rem(16)};
  }
`;

export const ProfileFormSideContainer = styled.div`
  padding: 0 ${rem(32)};

  .form-gap {
    margin-bottom: ${rem(24)};
  }
`;

export const ProfileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(18)};

  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      border-color: transparent;
    }
  }

  .fica-form {
    text-decoration: underline;
    line-height: ${rem(20)};
    font-weight: 500;
    color: rgba(56, 77, 214, 0.8);
    cursor: pointer;
  }
`;

export const ProfileContainer = styled.div`
  background: ${COLORS.White};
  box-shadow: 0px 2px 7px ${COLORS.CardShadow};
  border-radius: 6px;
  padding: ${rem(2)} ${rem(26)};

  .list {
    border-bottom: 1px solid ${COLORS.CulturedWhite};
    align-items: flex-start;

    @media (min-width: 1024px) {
      gap: ${rem(50)};
    }

    &:last-child {
      border-bottom: none;
    }

    &-icon {
      display: flex;
      align-items: center;
      gap: ${rem(5)};
      padding: ${rem(12)} 0;

      @media (min-width: 512px) {
        padding: ${rem(16)} 0;
      }

      .material-symbols-outlined {
        font-size: ${rem(20)};
        color: ${COLORS.SpanishGray};
      }
    }

    &-content {
      padding: ${rem(12)} 0;

      @media (min-width: 512px) {
        padding: ${rem(16)} 0;
      }

      .profile-date {
        margin-top: ${rem(12)};
      }

      .profile-url {
        font-size: ${rem(14)};
        line-height: ${rem(18)};
        color: ${COLORS.Iris};
        text-decoration: underline;

        &:hover {
          color: ${COLORS.Blue};
        }
      }

      .profile-text-gray {
        color: ${COLORS.SpanishGray};
      }
    }

    &.list-loading {
      display: flex;
      gap: 10px;

      .list-icon {
        width: 30px;
      }

      .list-content {
        gap: 10px;
        width: calc(100% - 40px);
      }
    }
  }

  &.profile-employee {
    padding: ${rem(6)} ${rem(26)};

    .profile-employeeWrapper {
      display: flex;
      flex-direction: column;
      gap: ${rem(5)};
      padding: ${rem(18)} ${rem(0)};
      width: 100%;
    }

    .profile-mainTitle {
      font-weight: 500;
    }

    .profile-subTitle {
      color: ${COLORS.Gray};
    }
  }

  .profile-toolTip span {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .profile-label {
    width: auto;
    flex-direction: row;
    padding: ${rem(8)} ${rem(15)};
  }

  .profile-spinners {
    margin: ${rem(16)} 0;
  }

  .material-symbols-outlined-success {
    color: ${COLORS.Green};
  }

  .material-symbols-outlined-default {
    color: ${COLORS.SpanishGray};
  }

  .material-symbols-outlined-error {
    color: ${COLORS.Red};
  }

  .material-symbols-outlined-success,
  .material-symbols-outlined-default,
  .material-symbols-outlined-error {
    font-size: ${rem(20)};
  }
`;

export const FormDivider = styled.div`
  margin: 1.4rem 0 4.2rem 0;
  border-top: 1px solid ${COLORS.CulturedWhite};
`;

export const ProfilePic = styled.div`
  width: ${rem(165)};
  height: ${rem(170)};
  border-radius: 6px;
  position: relative;
  border: 1px solid ${COLORS.CulturedWhite};
  border-radius: 6px;
  margin-bottom: 15px;
  background-color: ${COLORS.White};

  img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
  }

  label {
    display: inline-flex;
    align-items: center;
    background: ${COLORS.White};
    border: 1px solid ${COLORS.CulturedWhite};
    box-shadow: 0px 2px 7px ${COLORS.CardShadow};
    border-radius: 4px;
    padding: ${rem(5)} ${rem(6)};
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    .profilePicText {
      transition: all 0.3s ease-in-out 0s;
      line-height: 0;
    }
    &:hover {
      .profilePicText {
        color: ${COLORS.Purple};
        cursor: pointer;
      }
    }
    input {
      position: absolute;
      max-width: 100%;
      width: 100%;
      height: 100%;
      opacity: 0;
      top: 0;
      left: -9999px;
      /* z-index: 1; */
    }

    .icon {
      margin-right: 5px;
      font-size: ${rem(14)};
      cursor: pointer;
    }
  }

  .profile-loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.White};
    border-radius: 4px;
  }
`;
