import { type UseQueryResult, useQuery } from 'react-query';
import { ParticipantService } from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetParticipantApprovalList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<any, unknown> => {
  const {
    employeeStatusId,
    employeeType,
    accountStatus,
    emailStatus,
    filter,
    sorting,
    skipCount,
    maxResultCount,
  } = tableInfo;

  const ParticipantList = useQuery(
    [
      'getParticipantApprovalList',
      employeeStatusId,
      employeeType,
      accountStatus,
      emailStatus,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        ParticipantService.getApiAwardApiAppParticipantParticipantsApprovalList(
          employeeStatusId,
          employeeType,
          accountStatus,
          emailStatus,
          filter,
          sorting,
          skipCount,
          maxResultCount
        ).then((res: any) => ({
          ...res,
          items: res.items.map((participants: any) => ({
            ...participants,
            status: participants?.approvalStatus,
          })),
        }))
      );
    }
  );

  return ParticipantList;
};

export default useGetParticipantApprovalList;
