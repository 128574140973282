import { type UseQueryResult, useQuery } from 'react-query';
import { ReportCategoryService } from '../../services/wealthlane-report-services';

const useGetReportCategory = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const reportCategoryList = useQuery(
    ['getReportCategory'],
    async () => {
      return await handleRequest(
        ReportCategoryService.getApiReportApiAppReportCategoryReportCategories()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return reportCategoryList;
};

export default useGetReportCategory;
