import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Tag from '../../components/lozenge/tags';
import { PopoverModelWrapper } from '../../components/modal/modal.styled';
import Heading from '../../components/typography';
import { ActionButton } from '../../components/vertical-dot/popper';
import { DATE_FORMAT } from '../common';
import { Display } from '../style/common.styled';

export const VestingTemplatesHeader = (
  action: any,
  handleShow: (data: any, event: any) => void,
  hasMousePosition: boolean,
  resetMousePosition: () => void
): any[] => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (!(event.target as HTMLElement).className.includes('pop-overs')) {
        resetMousePosition();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resetMousePosition]);

  return [
    {
      Header: 'Created Date',
      accessor: 'creationTime',
      width: 100,
      Cell: ({ row }: any) => {
        return (
          <>
            {!isEmpty(row.original.createdDate)
              ? moment(row.original.createdDate).format(DATE_FORMAT)
              : '-'}
          </>
        );
      },
    },
    {
      Header: 'Vesting Template Name',
      accessor: 'name',
      width: 300,
      Cell: ({ row }: any) => (
        <PopoverModelWrapper className='pop-overs'>
          <Heading
            variant='body-s'
            title={row.original.name}
            onClick={(e: any) => {
              setTitle(row.original.name);
              handleShow(row.original, e);
            }}
            className={`pop-overs title ${
              hasMousePosition && title === row.original.name ? 'active' : ''
            }`}
          />
        </PopoverModelWrapper>
      ),
    },
    {
      Header: 'Vesting Type',
      accessor: 'vestingType',
      center: true,
      width: 200,
      Cell: ({ row }: any) => {
        const typeTitle =
          row.original.vestingType != null ? row.original.vestingType.split(' ')[0] : '';
        return (
          <Display>
            <Tag
              variant={
                typeTitle === 'Cliff' ? 'primary' : typeTitle === 'Step' ? 'info' : 'success'
              }
              title={typeTitle.toUpperCase()}
            />
          </Display>
        );
      },
    },
    // {
    //   Header: 'Award Type',
    //   accessor: 'planTypeCategory.Name',
    //   center: true,
    //   width: 100,
    //   Cell: ({ row }: any) => (
    //     <Display>
    //       <Tag variant='success' title={row.original.planType} />
    //     </Display>
    //   ),
    // },
    {
      Header: 'Full Vesting Period',
      accessor: 'lengthInMonths',
      center: true,
      width: 100,
      Cell: ({ row }: any) => (
        <Display>
          <Tag
            variant='default-light'
            title={`${String(row.original.lengthInMonths ?? '')} Months`}
          />
        </Display>
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={action}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
