import { type UseQueryResult, useQuery } from 'react-query';
import { OptionAwardService } from '../../services/wealthlane-award-services';

const useGetGrantOptionList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const grantOptionList = useQuery(
    ['getGrantOptionList'],
    async () => {
      return await handleRequest(
        OptionAwardService.getApiAwardApiAppOptionAwardUpcomingGrantList()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.grantName ?? '',
            value: item.grantId ?? '',
          };
        });
      },
    }
  );

  return grantOptionList;
};

export default useGetGrantOptionList;
