/* eslint-disable camelcase */
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../../components/button';
import InitialValuesForm from '../../../constants/initialValues';
import { type Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto } from '../../../services/wealthlane-award-settlement-services';
import _ from 'lodash';
import { SettingSystemUserHeader } from '../system-users/systemUserStyled';
import Heading from '../../../components/typography';
import { COLORS } from '../../../constants/style/color';
import { TaxSettlementContainer, TaxSettlementContainerHeading } from './settlementConfigStyled';
import FormSelect from '../../../components/dropdown/formSelect';
import ToastComponent from '../../../components/toast';
import Spinners from '../../../components/spinner';
import { useGetDefaultSettlementConfiguration } from '../../../hooks';
import ClickableTooltip from '../../../components/tooltip/clickTooltip';
import { MaterialIconFilled } from '../../../utils/global';
import CheckPermissionWithFieldName from '../../../utils/permission/checkPermissionWithFieldName';
import { UseMutateFunction } from 'react-query';
import { useEffect } from 'react';

export const rolesInitialValues = {
  data: InitialValuesForm.addSystemRoleForm,
  id: '',
};

interface SettlementConfigType {
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  mutate: UseMutateFunction<
    | Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto[]
    | undefined,
    unknown,
    any,
    unknown
  >;
  postLoading: boolean;
}

const SettlementConfig = ({
  handleRequest,
  mutate,
  postLoading,
}: SettlementConfigType): JSX.Element => {
  const { data, isLoading } = useGetDefaultSettlementConfiguration(handleRequest);
  const List = [
    {
      label: 'Sell All',
      value: 'sellAll',
    },
    {
      label: 'Sell To Cover',
      value: 'sellToCover',
    },
    {
      label: 'Withhold To Cover',
      value: 'holdToCover',
    },
  ];

  const transformObjectToFalse = (values: any, uniqueKey?: string): any => {
    const obj: any = {};
    Object.entries(values).forEach(([key, value]) => {
      if (uniqueKey !== key) {
        obj[key] = typeof value === 'boolean' ? false : value;
      } else {
        obj[key] = value;
      }
    });
    return obj;
  };

  const transformToOriginal = (values: any): any => {
    const refined: any = {};
    ['RSU', 'PSU', 'Options'].forEach((v) => {
      Object.keys(values[v]).forEach((item) => {
        refined[v] = {
          ...transformObjectToFalse(values[v], 'vesting'),
        };
        refined[v][refined[v].vesting] = true;
      });
    });

    return refined;
  };

  const handleSubmit = (values: any, data: any): any => {
    const transformedValue = transformToOriginal(values);
    const filterData = data.map((d: any) => {
      if (d.planTypeCategory === 'RSU') {
        return Object.assign(
          {},
          {
            ...transformObjectToFalse(d),
          },
          transformedValue.RSU
        );
      } else if (d.planTypeCategory === 'PSU') {
        return Object.assign(
          {},
          {
            ...transformObjectToFalse(d),
          },
          transformedValue.PSU
        );
      } else if (d.planTypeCategory === 'Options') {
        return Object.assign(
          {},
          {
            ...transformObjectToFalse(d),
          },
          transformedValue.Options
        );
      }
      return null;
    });

    const payload = {
      defaultSettlementConfigurations: filterData,
    };

    const payloadData = payload?.defaultSettlementConfigurations?.map((data: any) => ({
      sellAll: data.sellAll,
      sellToCover: data.sellToCover,
      holdToCover: data.holdToCover,
    }));

    const previousData = data?.map((data: any) => ({
      sellAll: data.sellAll,
      sellToCover: data.sellToCover,
      holdToCover: data.holdToCover,
    }));

    if (
      _.isEqual(payloadData[0], previousData[0]) &&
      _.isEqual(payloadData[1], previousData[1]) &&
      _.isEqual(payloadData[2], previousData[2])
    ) {
      toast.error(<ToastComponent label='Info' message={'No changes made'} />);
    } else {
      mutate(payload);
    }
  };

  const getSelectedValueFromObj = (values: any, v: any): any => {
    return Object.keys(values).find((k) => values[k] === true);
  };

  function getConfigInitialValues(
    data: Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto[]
  ): any {
    const obj: any = {};
    data?.forEach(
      (
        d: Wealthlane_AwardSettlementService_Dtos_DefaultSettlementConfiguration_DefaultSettlementConfigurationDto
      ) => {
        obj[`${String(d.planTypeCategory ?? '')}`] = {
          vesting: getSelectedValueFromObj(d, d.planTypeCategory),
        };
      }
    );
    return obj;
  }

  return (
    <CheckPermissionWithFieldName fieldName='SettlementConfigs'>
      <TaxSettlementContainerHeading className='flex gap-x-5'>
        <Heading
          variant='h4'
          title='Set Default Tax Settlement Method by Award Type'
          color={COLORS.Silver}
        />
        <ClickableTooltip
          tooltipText={
            'Selected method will be the default tax settlement option if participants do not elect one.  If only one tax settlement method is offered, then pick that as the default method.'
          }
          variant='dark'>
          <span className='material-symbols-sharp mt-3' style={MaterialIconFilled}>
            info
          </span>
        </ClickableTooltip>
      </TaxSettlementContainerHeading>

      <TaxSettlementContainer>
        {isLoading && <Spinners />}
        {!isLoading && (
          <Formik
            initialValues={getConfigInitialValues(data ?? [])}
            onSubmit={(values) => handleSubmit(values, data)}>
            {({ touched, handleChange, errors, values, setFieldValue }) => (
              <Form>
                <div className='flex flex-col sm:flex-row gap-x-[20px]'>
                  {_.orderBy(data, 'planTypeCategory', 'desc')?.map((data: any) => (
                    <>
                      <FormSelect
                        type='text'
                        label={`${
                          data.planTypeCategory === 'RSU'
                            ? 'RSUs Awards'
                            : data.planTypeCategory === 'PSU'
                            ? 'PSUs Awards'
                            : 'Stock Options'
                        }`}
                        name={`${String(data.planTypeCategory ?? '')}.vesting`}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={List}
                        values={values[data.planTypeCategory]}
                        arrValue={values[data.planTypeCategory].vesting}
                        setFieldValue={setFieldValue}
                        containerClassName='formGroup'
                        width='200px'
                        required
                      />
                    </>
                  ))}
                </div>

                <div className='TaxSettlementButton'>
                  <Button
                    title='Save'
                    type='submit'
                    as='Edit'
                    loading={postLoading || isLoading}
                    variant='primary'
                    icon='check_circle'
                    // disabled={_.isEqual(values, getConfigInitialValues(data ?? []))}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </TaxSettlementContainer>
    </CheckPermissionWithFieldName>
  );
};

export default SettlementConfig;
