import { useMutation } from 'react-query';
import { PsuAwardService } from '../../services/wealthlane-award-services';

const useGetUpcomingPsuVestingView = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setPsuVestingView: any,
  openModal?: () => void
): any => {
  return useMutation(
    async (rowData: { grantId: string; vestingDate: string }) => {
      return await handleRequest(
        PsuAwardService.getApiAwardApiAppPSUAwardAdminDashboardGrantView(
          rowData?.vestingDate,
          rowData?.grantId
        )
      );
    },
    {
      onSuccess: (response: any, context: { grantId: string; vestingDate: string }) => {
        if (response != null) {
          setPsuVestingView({
            ...response,
            grantId: context?.grantId,
            vestingDate: context?.vestingDate,
          });
          openModal?.();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetUpcomingPsuVestingView;
