/* eslint-disable camelcase */
import _ from 'lodash';
import { SettingSystemUserHeader } from '../system-users/systemUserStyled';
import Heading from '../../../components/typography';
import { COLORS } from '../../../constants/style/color';
import {
  RsuElectionConfig,
  SopElectionConfig,
} from '../configuration/components/election-window-config';
import CheckPermissionWithFieldName from '../../../utils/permission/checkPermissionWithFieldName';
import TradePreClearanceTransactionPeriodConfigs from '../trade-pre-clearance/period-config';
import SettlementConfig from './settlementConfig';
import { usePostSettlementConfig, useWealthlaneAwardSettlementApi } from '../../../hooks';
import { useState } from 'react';
import PsuElectionConfig from '../configuration/components/election-window-config/psuElectionConfig';

const SettlementConfigContainer = (): JSX.Element => {
  const { handleRequest } = useWealthlaneAwardSettlementApi();
  const { mutate, isLoading: postLoading } = usePostSettlementConfig(handleRequest);

  return (
    <div>
      <SettingSystemUserHeader>
        <Heading variant='h3' title='Settlement Configuration' />
        <Heading
          variant='body-s'
          title='Set up default and customized tax settlement configurations for award release and exercises.'
          color={COLORS.Gray}
        />
      </SettingSystemUserHeader>
      <div>
        <CheckPermissionWithFieldName fieldName='SettlementConfigs'>
          <SettlementConfig
            handleRequest={handleRequest}
            mutate={mutate}
            postLoading={postLoading}
          />
        </CheckPermissionWithFieldName>

        <CheckPermissionWithFieldName fieldName='ElectionWindowConfigurations'>
          <RsuElectionConfig refresher={postLoading} />
          <PsuElectionConfig refresher={postLoading} />
          <SopElectionConfig refresher={postLoading} />
        </CheckPermissionWithFieldName>

        <CheckPermissionWithFieldName fieldName='TradePreClearanceTransactionPeriodConfigs'>
          <TradePreClearanceTransactionPeriodConfigs />
        </CheckPermissionWithFieldName>
      </div>
    </div>
  );
};

export default SettlementConfigContainer;
