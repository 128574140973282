import { type UseQueryResult, useQuery } from 'react-query';
import { JurisdictionLevelService, TaxItemService } from '../../services/wealthlane-tax-services';

const useGetTaxPeriodVendor = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<any> => {
  const taxPeriodVendorOptions = useQuery(
    ['getTaxPeriodVendorOptions'],
    async () => {
      return await handleRequest(
        TaxItemService.getApiTaxApiAppTaxItemVendorTaxPeriodListForCompany()
      );
    },
    {
      select: (data) => {
        const juridctionLevel = data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });

        return juridctionLevel;
      },
    }
  );

  return taxPeriodVendorOptions;
};

export default useGetTaxPeriodVendor;
