/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RSUAward_RSUVestingReportDto_Wealthlane_ReportService_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RSUAward_RSUVestingReportDto_Wealthlane_ReportService_Application_Contracts';
import type { Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto } from '../models/Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto';
import type { Wealthlane_ReportService_Dtos_RSUAward_RSUVestingReportRequestDto } from '../models/Wealthlane_ReportService_Dtos_RSUAward_RSUVestingReportRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RsuAwardReportService {

    /**
     * @param requestBody 
     * @returns Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto Success
     * @throws ApiError
     */
    public static postApiReportApiAppRSUAwardReportGetExportRSuVestingReport(
requestBody?: Wealthlane_ReportService_Dtos_RSUAward_RSUVestingReportRequestDto,
): CancelablePromise<Wealthlane_ReportService_Dtos_ReportTablePrefrence_ExcelExportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/r-sUAward-report/get-export-rSUVesting-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RSUAward_RSUVestingReportDto_Wealthlane_ReportService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiReportApiAppRSUAwardReportGetRSuVestingList(
requestBody?: Wealthlane_ReportService_Dtos_RSUAward_RSUVestingReportRequestDto,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_RSUAward_RSUVestingReportDto_Wealthlane_ReportService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/r-sUAward-report/get-rSUVesting-list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
