import { type TableParamsType } from '../../types/common';
import { type UseQueryResult, useQuery, UseMutationResult, useMutation } from 'react-query';

import {
  ReleaseService,
  // eslint-disable-next-line camelcase
  Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { isEmpty } from 'lodash';

const useGetTransactionReleaseVestingList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  filterData: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    grantName,
    awardTypeId,
    createdFromDate: fromDate,
    createdToDate: toDate,
    settlementTypeIds,
  } = filterData;
  const { filter: searchKeyWord, sorting, skipCount, maxResultCount } = tableInfo;

  const transactionReleaseVestingList = useQuery(
    [
      'getTransactionReleaseRsuVestingList',
      searchKeyWord,
      sorting,
      skipCount,
      maxResultCount,
      grantName,
      awardTypeId,
      fromDate,
      toDate,
      settlementTypeIds,
    ],

    async () => {
      if (
        !isEmpty(searchKeyWord) ||
        !isEmpty(grantName) ||
        !isEmpty(awardTypeId) ||
        !isEmpty(fromDate) ||
        !isEmpty(toDate) ||
        !isEmpty(settlementTypeIds)
      ) {
        return await handleRequest(
          ReleaseService.getApiAwardApiAppReleaseVestingList(
            sorting,
            skipCount,
            maxResultCount,
            searchKeyWord,
            grantName as string,
            awardTypeId,
            fromDate,
            toDate,
            settlementTypeIds
          )
        );
      }
    },
    {
      select: (data) => {
        if (data != null) {
          return data;
        }
      },
    }
  );

  return transactionReleaseVestingList;
};

export default useGetTransactionReleaseVestingList;
