import { type UseQueryResult, useQuery } from 'react-query';
import { TermsAndConditionsService } from '../../services/wealthlane-customer-services/services/TermsAndConditionsService';
// eslint-disable-next-line camelcase
import { type Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto } from '../../services/wealthlane-customer-services';

const useGetTermAndCondition = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto[] | undefined,
  unknown
> => {
  const termAndConditionList = useQuery(
    ['getTermAndConditionList'],
    async () => {
      return await handleRequest(
        TermsAndConditionsService.getApiCustomerApiAppTermsAndConditionsTermsAndConditions()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return termAndConditionList;
};

export default useGetTermAndCondition;
