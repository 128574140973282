import { type UseQueryResult, useQuery } from 'react-query';
import {
  OptionAwardsService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAwards_OptionAwardsReportDto_Wealthlane_ReportService_Application_Contracts,
} from '../../services/wealthlane-report-services';
import { type OptionReportType, type TableParamsType } from '../../types/common';

const useGetOptionAwardExerciseReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  filterInfo: OptionReportType,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAwards_OptionAwardsReportDto_Wealthlane_ReportService_Application_Contracts
  | undefined,
  unknown
> => {
  const {
    dateType,
    exerciseFromDate,
    exerciseToDate,
    awardIssuedFromDate,
    awardIssuedToDate,
    grantId,
    planId,
    grantUniqueID,
    planUniqueID,
    awardTypeId,
    exercisedFromQuantity,
    exercisedToQuantity,
    employeeStatus,
    employeeTypeId,
    optionExpirationFromDate,
    optionExpirationToDate,
    residentCountryId,
    residentState,
    residentStateId,
    workCountryId,
    workState,
    workStateId,
    departmentId,
    isO100KPlus,
    disqualifyingDisposition,
  } = filterInfo;
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const optionAwardExerciseReport = useQuery(
    [
      'getOptionAwardExerciseReport',
      dateType,
      exerciseFromDate,
      exerciseToDate,
      awardIssuedFromDate,
      awardIssuedToDate,
      grantId,
      planId,
      grantUniqueID,
      planUniqueID,
      awardTypeId,
      exercisedFromQuantity,
      exercisedToQuantity,
      employeeStatus,
      employeeTypeId,
      optionExpirationFromDate,
      optionExpirationToDate,
      residentCountryId,
      residentState,
      residentStateId,
      workCountryId,
      workState,
      workStateId,
      departmentId,
      isO100KPlus,
      disqualifyingDisposition,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      const payload: any = {
        dateType,
        exerciseFromDate,
        exerciseToDate,
        awardIssuedFromDate,
        awardIssuedToDate,
        grantId,
        planId,
        grantUniqueID,
        planUniqueID,
        awardTypeId,
        exercisedFromQuantity,
        exercisedToQuantity,
        employeeStatus,
        employeeTypeId,
        optionExpirationFromDate,
        optionExpirationToDate,
        residentCountryId,
        residentState,
        residentStateId,
        workCountryId,
        workState,
        workStateId,
        departmentId,
        isO100KPlus,
        disqualifyingDisposition:
          disqualifyingDisposition != null ? (disqualifyingDisposition ? 'Yes' : 'No') : null,
        columnPreferences: [],
        columnPreferencesSystemName: [],
        filter,
        sorting,
        skipCount,
        maxResultCount,
      };

      Object.keys(payload).forEach((key, index) => {
        payload[key] = payload[key] !== '' ? payload[key] : null;
      });

      return await handleRequest(
        OptionAwardsService.postApiReportApiAppOptionAwardsGetOptionsAwardExerciseReport(payload)
      );
    }
  );
  return optionAwardExerciseReport;
};

export default useGetOptionAwardExerciseReport;
