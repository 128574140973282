/* eslint-disable camelcase */
// import { useMutation, useQueryClient } from 'react-query';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  UserLogInAndSecurityService,
  type Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto,
} from '../../services/wealthlane-identity-services';
import AuthService from '../../services/authService';

const usePostChangePassword = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void
): UseMutationResult<
  Wealthlane_Shared_Hosting_Dtos_UserLogInAndSecurity_UserLogInAndSecurityResponseDto | undefined,
  unknown,
  void,
  unknown
> => {
  const queryClient = useQueryClient();
  const authService = new AuthService();

  return useMutation(
    async (payload) => {
      return await handleRequest(
        UserLogInAndSecurityService.postApiIdentityApiAppUserLogInAndSecurityChangeUserPassword(
          payload as any
        )
      );
    },

    {
      onSuccess: async (response) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message='Password updated.' />);
          await queryClient.invalidateQueries('getUserLoginAndSecurity');
          closeModal();
          void authService.logout();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostChangePassword;
