import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_Participant_ParticipantReportDto_Wealthlane_ReportService_Application_Contracts,
} from '../../services/wealthlane-report-services';
import { type ParticipantReportType, type TableParamsType } from '../../types/common';

const useGetParticipantReportList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  filterInfo: ParticipantReportType,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_Participant_ParticipantReportDto_Wealthlane_ReportService_Application_Contracts
  | undefined,
  unknown
> => {
  const {
    hireFromDate,
    hireToDate,
    terminatedFromDate,
    terminatedToDate,
    retirementEligibilityFromDate,
    retirementEligibilityToDate,
    employeeStatus,
    employeeTypeId,
    accountActivationStatus,
    taxCertificationStatus,
    esppEligibility,
    ficaEligibility,
    residentCountryId,
    workCountryId,
    workState,
    workStateId,
    workCity,
    departmentId,
  } = filterInfo as any;
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const participantReportList = useQuery(
    [
      'getParticipantReportList',
      hireFromDate,
      hireToDate,
      terminatedFromDate,
      terminatedToDate,
      retirementEligibilityFromDate,
      retirementEligibilityToDate,
      employeeStatus,
      employeeTypeId,
      accountActivationStatus,
      taxCertificationStatus,
      esppEligibility,
      ficaEligibility,
      residentCountryId,
      workCountryId,
      workState,
      workStateId,
      workCity,
      departmentId,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      const columnPreferences: string[] = [];
      const columnPreferencesSystemName: string[] = [];

      const payload: any = {
        hireFromDate,
        hireToDate,
        terminatedFromDate,
        terminatedToDate,
        retirementEligibilityFromDate,
        retirementEligibilityToDate,
        employeeStatus,
        employeeTypeId,
        accountActivationStatus:
          accountActivationStatus === 'registered'
            ? true
            : accountActivationStatus === 'unregistered'
            ? false
            : null,
        taxCertificationStatus:
          taxCertificationStatus === 'certified'
            ? true
            : taxCertificationStatus === 'uncertified'
            ? false
            : null,
        esppEligibility: esppEligibility === 'yes' ? true : esppEligibility === 'no' ? false : null,
        ficaEligibility: ficaEligibility === 'yes' ? true : ficaEligibility === 'no' ? false : null,
        residentCountryId,
        workCountryId,
        workState,
        workStateId,
        workCity,
        departmentId,
        columnPreferences,
        columnPreferencesSystemName,
        filter,
        sorting,
        skipCount,
        maxResultCount,
      };

      Object.keys(payload).forEach((key, index) => {
        payload[key] = payload[key] !== '' ? payload[key] : null;
      });

      return await handleRequest(
        ParticipantService.postApiReportApiAppParticipantGetParticipantList(payload)
      );
    }
  );
  return participantReportList;
};

export default useGetParticipantReportList;
