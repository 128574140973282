import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import AlertPopup from '../../../components/alert-popup';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import Button from '../../../components/button';
import Table from '../../../components/table';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { ParticipantBroadcastHeader } from '../../../constants/table-headers/participantBroadcastHeader';
import { useAdvanceBoolean, useBoolean, useWealthlaneCustomerApi } from '../../../hooks';
import useGetParticipantBroadcastMessageList from '../../../hooks/get/useGetParticipantBroadcastMessageList';
import { ParticipantBroadcastMessageService } from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import {
  SettingSystemUserButton,
  SettingSystemUserHeader,
  SystemUserContainer,
} from '../system-users/systemUserStyled';
import ParticipantBroadcastModel from './participantBroadcastModel';

export const broadCastMessageInitialValues = {
  data: InitialValuesForm.participantBroadcastMessage,
  id: '',
};

const ParticipantBroadcast = (): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');

  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const { data: list, status } = useGetParticipantBroadcastMessageList(handleRequest, tableInfo);
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const [initialData, setInitialData] = useState(broadCastMessageInitialValues);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
    customValue,
    setCustomValue,
  } = useAdvanceBoolean<string>(false);
  const queryClient = useQueryClient();
  const [modalLoading, setModalLoading] = useState(false);

  const actions = [
    {
      action: 'View',
      onClick: (id: string) => {
        setModalStatus('View');
        getTemplateMessage(id);
        openModal();
      },
    },
    {
      action: 'Edit',
      // as: 'Edit',
      onClick: (id: string) => {
        setModalStatus('Edit');
        getTemplateMessage(id);
        openModal();
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      // as: 'Delete',
      onClick: (id: string) => {
        setModalStatus('Delete');
        setCustomValue(id);
        openConfirmModal();
      },
    },
  ];

  const getTemplateMessage = (id: string): any => {
    void handleRequest(
      ParticipantBroadcastMessageService.getApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessageById(
        id
      )
    ).then((data: any) => {
      if (data != null) {
        const filteredData = {
          ...data,
          messageTemplateId: data?.messageTemplateId !== null ? data?.messageTemplateId : '11',
        };
        setInitialData({ id, data: filteredData });
      }
    });
  };
  const onPrimaryClick = (values: Record<string, any>): void => {
    deleteBroadcastMessageById(customValue);
  };

  const deleteBroadcastMessageById = (id: string): void => {
    setModalLoading(true);
    handleRequest(
      ParticipantBroadcastMessageService.deleteApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessage(
        id
      )
    )
      .then((data: any) => {
        if (data != null) {
          toast.success(
            <ToastComponent label={'Success'} message={'Participant Broadcast Message deleted.'} />
          );
          void queryClient.invalidateQueries('getParticipantBroadcastMessageList');
          setModalLoading(false);
          closeConfirmModal();
        }
      })
      .catch(() => {
        setModalLoading(false);
        closeConfirmModal();
      })
      .finally(() => {
        setModalLoading(false);
        closeConfirmModal();
      });
  };

  return (
    <SystemUserContainer>
      <div className='flex justify-between items-center'>
        <SettingSystemUserHeader>
          <Heading variant='h3' title='Participant Broadcast' />
        </SettingSystemUserHeader>
        <SettingSystemUserButton className='xl:col-span-2 flex justify-start lg:justify-end'>
          <Button
            // as='Create'
            variant={'primary'}
            title={'Create Message'}
            icon={'add_circle_outline'}
            onClick={() => {
              setInitialData(broadCastMessageInitialValues);
              setModalStatus('Add');
              // setDataRow('');
              openModal();
            }}
          />
        </SettingSystemUserButton>
      </div>
      <AnimationWrapper>
        {isModalOpen && (
          <ParticipantBroadcastModel
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            initialData={initialData}
            loading={modalLoading}
            //   tableInfo={tableInfo}
            //   setTableInfo={setTableInfo}
            //   dataRow={dataRow}
          />
        )}
      </AnimationWrapper>
      {isConfirmOpen && (
        <AlertPopup
          isShow={isConfirmOpen}
          closeModal={closeConfirmModal}
          modalStatus={modalStatus}
          title={'this Message'}
          handleSubmit={onPrimaryClick}
          // setRemark={setRemark}
          // remark={remark}
          loading={modalLoading}
        />
      )}
      <Table
        columns={ParticipantBroadcastHeader(actions)}
        data={list?.items ?? []}
        loading={status}
        showPagination
        totalCounts={list?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </SystemUserContainer>
  );
};

export default ParticipantBroadcast;
