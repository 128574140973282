import { type UseQueryResult, useQuery } from 'react-query';
import {
  OptionExerciseService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionExercise_AdminExerciseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';
import { useEffect } from 'react';
import { isEqual } from 'lodash';
import InitialValuesForm from '../../constants/initialValues';

const useGetOptionExercisedList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  showExercisedListOnly: boolean
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionExercise_AdminExerciseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    settlementTypeIds,
    recordTypes,
    createdFromDate,
    createdToDate,
    filter,
    sorting,
    skipCount,
    maxResultCount,
    optionGrantName,
  } = tableInfo;
  const optionExercisedList = useQuery(
    [
      'getOptionExercisedList',
      settlementTypeIds,
      recordTypes,
      createdFromDate,
      filter,
      createdToDate,
      sorting,
      skipCount,
      maxResultCount,
      optionGrantName,
    ],
    async () => {
      return await handleRequest(
        OptionExerciseService.getApiAwardApiAppOptionExerciseOptionExercisedList(
          settlementTypeIds,
          optionGrantName,
          recordTypes,
          createdFromDate,
          createdToDate,
          showExercisedListOnly,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return optionExercisedList;
};

export default useGetOptionExercisedList;
