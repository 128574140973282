import { type UseQueryResult, useQuery } from 'react-query';
import { RetirementEligibilityConfigurationService } from '../../services/wealthlane-award-settlement-services';

const useEligibilityCriteriaOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const eligibilityCriteriaOptions = useQuery(
    ['getEligibilityCriteriaOptions'],
    async () => {
      return await handleRequest(
        RetirementEligibilityConfigurationService.getApiAwardSettlementApiAppRetirementEligibilityConfigurationRetirementEligibilityCriterias()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return eligibilityCriteriaOptions;
};

export default useEligibilityCriteriaOptions;
