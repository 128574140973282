/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  EmployeeTypeService,
  type Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto,
  type Wealthlane_CustomerService_Dtos_EmployeeType_UpdateEmployeeTypeDto,
} from '../../services/wealthlane-customer-services';

const usePostEmployeeTypes = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  id?: string
): UseMutationResult<
  | Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto
  | Wealthlane_CustomerService_Dtos_EmployeeType_UpdateEmployeeTypeDto
  | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      const newPayload = { ...payload };
      if (id != null && id !== '') {
        return await handleRequest(
          EmployeeTypeService.putApiCustomerApiAppEmployeeTypeEmployeeType({
            ...newPayload,
            id,
          })
        );
      }
      return await handleRequest(
        EmployeeTypeService.postApiCustomerApiAppEmployeeTypeEmployeeType(newPayload)
      );
    },
    {
      onSuccess: async (
        response:
          | Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto
          | Wealthlane_CustomerService_Dtos_EmployeeType_UpdateEmployeeTypeDto
          | undefined
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries('getEmployeeTypesListWithPagination');
          closeModal();
          toast.success(
            <ToastComponent
              label='Success'
              message={`Employee Types ${id != null && id !== '' ? 'edited.' : 'added.'}`}
            />
          );
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostEmployeeTypes;
