import { type PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

interface ActionAnimationType {
  className?: string;
}

const ActionAnimation = ({
  className,
  children,
}: PropsWithChildren<ActionAnimationType>): JSX.Element => {
  const dropIn = {
    hidden: {
      height: '0px',
      opacity: 0,
    },
    visible: {
      height: 'auto',
      opacity: 1,
      transition: {
        height: {
          duration: 0.4,
        },
        opacity: {
          duration: 0.2,
          delay: 0.1,
        },
      },
    },
    exit: {
      height: '0px',
      opacity: 0,
      transition: {
        height: {
          duration: 0.4,
        },
        opacity: {
          duration: 0.25,
        },
      },
    },
  };

  return (
    <motion.div
      onClick={(e: any) => e.stopPropagation()}
      variants={dropIn}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={className}>
      {children}
    </motion.div>
  );
};

export default ActionAnimation;
