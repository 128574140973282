import { type UseQueryResult, useQuery } from 'react-query';
import { OptionAwardService } from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetOptionsFileNameList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const { createdFromDate: fromDate, createdToDate: toDate } = tableInfo;
  const OptionsFileNameList = useQuery(
    ['getOptionsFileNameList', fromDate, toDate],
    async () => {
      return await handleRequest(
        OptionAwardService.getApiAwardApiAppOptionAwardFileNamesByDate(fromDate, toDate)
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item ?? '',
            value: item ?? '',
          };
        });
      },
    }
  );

  return OptionsFileNameList;
};

export default useGetOptionsFileNameList;
