import React, { useEffect } from 'react';
import { type TableParamsType } from '../../types/common';
import { SearchContainer } from './search.style';

interface SearchType {
  placeholder?: string;
  onSearch?: any;
  searchValue?: string;
  className?: string;
  setTableInfo?: any;
  setCheckedValues?: any;
  setSelectedTableData?: any;
  tableInfo?: TableParamsType;
}

const Search = ({
  placeholder,
  onSearch,
  searchValue = '',
  className,
  setTableInfo,
  tableInfo,
  setCheckedValues,
  setSelectedTableData,
  ...rest
}: SearchType): JSX.Element => {
  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && Boolean(onSearch)) {
      onSearch(searchValue);
    }
  };

  const handleClickSearch = (): void => {
    if (Boolean(onSearch) && searchValue.length > 0) {
      onSearch(searchValue);
    } else if (Boolean(onSearch) && searchValue === '') {
      onSearch(' ');
    }
  };

  const handleChangeSearch = (value: string): void => {
    onSearch(value);
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await setTableInfo({
        ...tableInfo,
        skipCount: 0,
        filter: searchValue,
      });
    };

    const timer = setTimeout(() => {
      void fetchData();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  return (
    <SearchContainer {...rest} className={className}>
      <input
        autoComplete='off'
        name='search'
        placeholder={placeholder}
        type='text'
        value={searchValue}
        onKeyUp={handleSearch}
        onChange={(e) => {
          handleChangeSearch(e.target.value);
        }}
      />

      {searchValue?.length > 0 ? (
        <span
          className='material-symbols-outlined close'
          onClick={() => {
            onSearch('');
            setCheckedValues('');
            setSelectedTableData([]);
          }}>
          close
        </span>
      ) : (
        <span className='material-symbols-outlined search' onClick={handleClickSearch}>
          search
        </span>
      )}
    </SearchContainer>
  );
};
export default Search;
