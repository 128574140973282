import { type UseQueryResult, useQuery } from 'react-query';
import {
  ReleaseService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Release_TransactionDetailDto,
} from '../../services/wealthlane-award-services';

const useGetTransactionIncludeTransactionDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Release_TransactionDetailDto | undefined,
  unknown
> => {
  const transactionIncludeTransactionDetails = useQuery(
    ['getTransactionIncludeTransactionDetails', id],
    async () => {
      if (id) {
        return await handleRequest(ReleaseService.getApiAwardApiAppReleaseTransactionDetail(id));
      }
    }
  );

  return transactionIncludeTransactionDetails;
};

export default useGetTransactionIncludeTransactionDetails;
