import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerCompanyService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_CustomerKeyContactType_CustomerKeyContactTypeDto,
} from '../../services/wealthlane-customer-services';

const useGetCompanyKeyContactTypes = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_CustomerKeyContactType_CustomerKeyContactTypeDto[] | undefined,
  unknown
> => {
  const companyKeyContactTypes = useQuery(
    ['getCompanyKeyContactTypes'],
    async () => {
      return await handleRequest(
        CustomerCompanyService.getApiCustomerApiAppCustomerCompanyKeyContactTypes()
      );
    },
    {
      cacheTime: 1000 * 60 * 5,
      select: (data) => {
        if (data != null) {
          const sortedData = data.sort((x, y) => {
            return x.name === 'Transfer Agent Contact'
              ? -1
              : y.name === 'Transfer Agent Contact'
              ? 1
              : 0;
          });

          return sortedData;
        }
      },
    }
  );

  return companyKeyContactTypes;
};

export default useGetCompanyKeyContactTypes;
