/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_TradePreClearance_TradePreClearanceDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_TradePreClearance_TradePreClearanceDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';
import type { Wealthlane_CustomerService_Dtos_TradePreClearance_CreateOrUpdateTradePreClearanceDto } from '../models/Wealthlane_CustomerService_Dtos_TradePreClearance_CreateOrUpdateTradePreClearanceDto';
import type { Wealthlane_CustomerService_Dtos_TradePreClearance_TradePreClearanceParticipantsDto } from '../models/Wealthlane_CustomerService_Dtos_TradePreClearance_TradePreClearanceParticipantsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TradePreClearanceService {

    /**
     * @param departmentId 
     * @param jobTitleId 
     * @param employeTypeId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_TradePreClearance_TradePreClearanceDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppTradePreClearanceTradePreClearanceList(
departmentId?: string,
jobTitleId?: string,
employeTypeId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_TradePreClearance_TradePreClearanceDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/trade-pre-clearance/trade-pre-clearance-list',
            query: {
                'DepartmentId': departmentId,
                'JobTitleId': jobTitleId,
                'EmployeTypeId': employeTypeId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppTradePreClearanceSaveTradePreClearance(
requestBody?: Wealthlane_CustomerService_Dtos_TradePreClearance_CreateOrUpdateTradePreClearanceDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/trade-pre-clearance/save-trade-pre-clearance',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_TradePreClearance_TradePreClearanceParticipantsDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppTradePreClearanceTradePreClearanceParticipantsList(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_TradePreClearance_TradePreClearanceParticipantsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/trade-pre-clearance/trade-pre-clearance-participants-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppTradePreClearanceRemovePreclearance(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/trade-pre-clearance/remove-preclearance',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
