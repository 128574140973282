/* eslint-disable camelcase */
import { isEmpty } from 'lodash';
import { type Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto } from '../../services/wealthlane-customer-services';
import { commonValidation } from '../../utils/validations/validationRule';
import useWealthlaneCustomerApi from '../useWealthlaneCustomerApi';
import useGetUDFByModuleId from './useGetUDFByModuleId';

export function useGetUdfFieldFiltered(): any {
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data: UdfFields } = useGetUDFByModuleId(handleRequest, 1);

  const udfNames = UdfFields?.filter(
    (item: Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto) =>
      item.isMandatory
  ).map(
    (udfRes: Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto) => {
      const fieldName = udfRes?.fieldName?.replaceAll(' ', '');
      const udfName = fieldName != null && fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
      return udfName;
    }
  );

  const reducedData =
    !isEmpty(udfNames) &&
    udfNames
      ?.map((item) => ({ [`${String(item ?? '')}`]: commonValidation({ required: true }) }))
      .reduce((a: any, b: any) => ({ ...a, ...b }));

  const udfInitialValues =
    !isEmpty(udfNames) &&
    udfNames?.map((item: any) => ({ [item]: '' })).reduce((a: any, b: any) => ({ ...a, ...b }));

  return { UdfFields, udfNames, reducedData, udfInitialValues };
}

export default useGetUdfFieldFiltered;
