import { type UseQueryResult, useQuery } from 'react-query';
import {
  TerminationReportService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_TerminationReport_TerminationReportDto_Wealthlane_ReportService_Application_Contracts,
} from '../../services/wealthlane-report-services';
import { type TableParamsType, type TerminationReportType } from '../../types/common';

const useGetTerminationReportList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  filterInfo: TerminationReportType,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_TerminationReport_TerminationReportDto_Wealthlane_ReportService_Application_Contracts
  | undefined,
  unknown
> => {
  const { terminatedFromDate, terminatedToDate, employeeType } = filterInfo;
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const terminationReportList = useQuery(
    [
      'getTerminationReportList',
      terminatedFromDate,
      terminatedToDate,
      employeeType,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        TerminationReportService.getApiReportApiAppTerminationReportTerminationReportList(
          terminatedFromDate,
          terminatedToDate,
          employeeType,
          [],
          [],
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );
  return terminationReportList;
};

export default useGetTerminationReportList;
