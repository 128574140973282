import { useMutation } from 'react-query';
import { OptionAwardService } from '../../services/wealthlane-award-services';

const useGetUpcomingOptionVestingView = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setRsuVestingView: any,
  openModal?: () => void
): any => {
  return useMutation(
    async (rowData: { grantId: string; vestingDate: string }) => {
      return await handleRequest(
        OptionAwardService.getApiAwardApiAppOptionAwardAdminDashboardGrantView(
          rowData?.vestingDate,
          rowData?.grantId
        )
      );
    },
    {
      onSuccess: (response: any, context: { grantId: string; vestingDate: string }) => {
        if (response != null) {
          setRsuVestingView({
            ...response,
            grantId: context?.grantId,
            vestingDate: context?.vestingDate,
          });
          openModal?.();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetUpcomingOptionVestingView;
