import { type UseQueryResult, useQuery } from 'react-query';
import {
  PsuAwardService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_AdminPsuDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { type TableParamsType } from '../../types/common';

const useGetPsuViewTableList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  grantId: string,
  vestingDate: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_AdminPsuDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;
  const psuViewTableList = useQuery(
    ['getPsuViewTableList', tableInfo, grantId, vestingDate],
    async () => {
      return await handleRequest(
        PsuAwardService.getApiAwardApiAppPSUAwardAdminDashboardGrantList(
          vestingDate,
          grantId,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return psuViewTableList;
};

export default useGetPsuViewTableList;
