import { type UseQueryResult, useQuery } from 'react-query';
import * as wealthlaneCustomerServices from '../../services/wealthlane-customer-services';
import { TradeOrderService } from '../../services/wealthlane-award-settlement-services';
import { TransferAllocateService } from '../../services/wealthlane-award-services';

const useGetTransferContainerStatus = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const transferContainerStatus = useQuery(
    ['getTransferContainerStatus'],
    async () => {
      return await handleRequest(
        TransferAllocateService.getApiAwardApiAppTransferAllocateContainerStatuses()
      );
    },
    {
      select: (data) => {
        const newData = data?.filter((item) => item.displayName !== 'Draft');
        return newData?.map((item) => {
          return {
            label: item.displayName ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return transferContainerStatus;
};

export default useGetTransferContainerStatus;
