import React, { useState } from 'react';
import {
  SettingSystemUserButton,
  SettingSystemUserHeader,
  SystemUserContainer,
} from '../system-users/systemUserStyled';
import Heading from '../../../components/typography';
import Table from '../../../components/table';
import { EmailLogHeader } from '../../../constants/table-headers/emailLogHeader';
import { TableParamsType } from '../../../types/common';
import InitialValuesForm from '../../../constants/initialValues';
import Filter from '../../../components/filter';
import FormSelect from '../../../components/dropdown/formSelect';
import { EmailStatusOptions, YesNoOptions } from '../../../constants/common';
import { ResetFilterWrapper } from '../../../constants/style/common.styled';
import { SwitchContainer } from './email.styled';
import {
  useGetCompanyNotificationConfig,
  useGetEmailNotificationList,
  useWealthlaneNotificationApi,
} from '../../../hooks';
import { toast } from 'react-toastify';
import ToastComponent from '../../../components/toast';
import { CompanyNotificationConfigurationService } from '../../../services/wealthlane-notification-services';
import { useQueryClient } from 'react-query';
import CheckPermissions from '../../../utils/permission/checkPermission';

const EmailLogs = () => {
  const { handleRequest } = useWealthlaneNotificationApi();
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [open, setOpen] = useState(false);

  const { data, status } = useGetEmailNotificationList(handleRequest, tableInfo);
  const { data: notificationStatus } = useGetCompanyNotificationConfig(handleRequest);
  const [toggle, setToggle] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const CompanyNotificationConfig = (data: boolean) => {
    const payload = { isEmailEnableToParticipant: data };
    handleRequest(
      CompanyNotificationConfigurationService.postApiNotificationApiAppCompanyNotificationConfigurationToggleEmailToParticipant(
        payload
      )
        .then((res: any) => {
          if (res?.success) {
            toast.success(<ToastComponent label='Success' message='Email service saved.' />);
            queryClient.invalidateQueries('getCompanyNotificationConfig');
          }
        })
        .catch((error: any) => {
          console.log('error', error);
        })
        .finally(() => {})
    );
  };

  return (
    <SystemUserContainer>
      <div className='flex justify-between items-center'>
        <SettingSystemUserHeader>
          <Heading variant='h3' title='Email Logs' />
        </SettingSystemUserHeader>
      </div>
      <div className='flex justify-between'>
        <div className='flex gap-x-4 mb-10'>
          <Filter setOpen={setOpen} open={open} className='block'>
            <Heading variant='body-xs' title={'Filter By:'} className={'filter-label'} />
            <div className='grid sm:grid-cols-3 gap-x-5'>
              <FormSelect
                type='text'
                name=''
                placeholder='Email Status'
                onChange={(value: boolean) => {
                  setTableInfo({ ...tableInfo, emailStatus: value, skipCount: 0 });
                }}
                options={EmailStatusOptions}
                values={tableInfo.emailStatus}
                arrValue={tableInfo.emailStatus}
                isFormik={false}
              />
            </div>
          </Filter>
          <div className='col-span-5 lg:col-span-2 items-start gap-x-1'>
            <ResetFilterWrapper
              onClick={() => {
                setTableInfo({
                  ...InitialValuesForm.tableParams,
                  maxResultCount: 50,
                });
              }}>
              <span className='material-symbols-outlined'>settings_backup_restore</span>
              <Heading variant='body-s' title='Reset Filter' />
            </ResetFilterWrapper>
          </div>
        </div>
        <CheckPermissions type={'Edit'}>
          <SwitchContainer>
            <div className='flex gap-2'>
              <label className='switch'>
                <input
                  type='checkbox'
                  onChange={(e: any) => {
                    setToggle(!notificationStatus?.isEmailEnableToParticipant);
                    CompanyNotificationConfig(e.target.checked);
                  }}
                  checked={toggle || notificationStatus?.isEmailEnableToParticipant}
                />
                <span className='slider round'></span>
              </label>
              <Heading title={'Enable/Disable Email'} variant='body' />
            </div>
          </SwitchContainer>
        </CheckPermissions>
      </div>

      {/* {isConfirmOpen && (
        <AlertPopup
          isShow={isConfirmOpen}
          closeModal={closeConfirmModal}
          modalStatus={modalStatus}
          title={'this Message'}
          handleSubmit={onPrimaryClick}
          // setRemark={setRemark}
          // remark={remark}
          loading={modalLoading}
        />
      )} */}
      <Table
        columns={EmailLogHeader}
        data={data?.items ?? []}
        loading={status}
        showPagination
        totalCounts={data?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </SystemUserContainer>
  );
};

export default EmailLogs;
