import { rem } from '../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DatePickerInput = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  font-size: ${rem(12)};
  background: ${COLORS.White};

  &.customDatepicker {
    .react-datepicker {
      font-size: 1rem;
      border-radius: 0.6rem;
      border: 0.1rem solid #ededed;
    }

    &.customDatepicker-active {
      z-index: 3;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: ${COLORS.Purple};

      &:hover {
        background-color: ${COLORS.Purple};
      }
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      /* color:#161616; */
    }
    .react-datepicker__day--outside-month {
      color: #d7d7d7;
      cursor: default !important;

      &:hover {
        background: transparent;
      }
    }

    .react-datepicker__day {
      border: 1px solid transparent;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: ${COLORS.Purple};
      outline: ${COLORS.Purple};
      border-radius: 5px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__day-names div,
    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
      color: white;
    }

    .react-datepicker-popper {
      z-index: 20;
    }

    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
      border-bottom-color: ${COLORS.Purple};
    }
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
      border-top-color: #ededed;
    }

    .react-datepicker__navigation--previous {
      border-right-color: #ffffff;
    }

    .react-datepicker__navigation--next {
      border-left-color: #ffffff;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: white;
      border-width: 4px;
    }

    .react-datepicker {
      &__header {
        background: ${COLORS.Purple};
        border: none;
      }
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2.7rem;
      line-height: 2.7rem;
    }

    .react-datepicker__day--keyboard-selected:focus-visible,
    .react-datepicker__navigation--previous:focus-visible,
    .react-datepicker__navigation--next:focus-visible,
    .react-datepicker__day--selected:focus-within {
      outline: 1px solid ${COLORS.Black}!important;
      border: 1px solid ${COLORS.Black}!important;
      border-radius: 5px;
    }

    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__current-month {
      font-size: 1.5rem;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      border-color: #ccc;
      border-style: solid;
      border-width: 3px 3px 0 0;
      content: '';
      display: block;
      height: 9px;
      position: absolute;
      top: 6px;
      width: 9px;
      top: 12px;
    }
  }

  .react-datepicker-wrapper {
    z-index: 1;
  }

  .react-datepicker {
    font-size: 1rem;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-calendar-picker-indicator {
    display: block;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    color: ${COLORS.Black};

    background-color: transparent;
    border: ${rem(1)} solid ${COLORS.LightGray};
    border-radius: ${rem(6)};
    padding: ${rem(11)} ${rem(12)};

    font-weight: 400;
    font-size: ${rem(15)};
    line-height: ${rem(16)};

    &[placeholder],
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    .DateInput_input::placeholder {
      color: ${COLORS.Gray60};
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
    }
    &.show-disabled::placeholder {
      color: ${COLORS.Black};
    }
  }

  input:before {
    color: white;
    background: none;
    display: block;
    content: '0';
    width: 15px;
    position: absolute;
    top: 12px;
    right: 6px;
  }

  .material-symbols-outlined {
    font-size: ${rem(20)};
    position: absolute;
    right: ${rem(12)};

    color: ${COLORS.Silver};
    cursor: pointer;
  }

  input {
    &:hover,
    &:focus {
      border: ${rem(1)} solid ${COLORS.Blue};
      outline: none;
    }

    .material-symbols-outlined {
      color: ${COLORS.Blue};
    }

    &:not(:disabled).is-invalid {
      border: ${rem(1)} solid ${COLORS.Red};
    }

    &::placeholder {
      font-weight: 400;
      color: ${COLORS.LightGray};
    }

    &:disabled {
      background-color: ${COLORS.OffWhite};
      border: ${rem(1)} solid ${COLORS.LightGray};
    }

    .error-message {
      font-weight: 500;
      font-size: ${rem(12)};
      line-height: ${rem(14)};
      color: ${COLORS.Gray};
      margin-top: ${rem(6)};
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      color: ${COLORS.SpanishGray};
    }
  }
`;

export const DateInput = styled.input`
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: gray;
  font-size: ${rem(12)};
  line-height: 1.5715;
  background-color: ${COLORS.White};
  background-image: none;
  border: 1px solid ${COLORS.Gray80};
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
`;
export const DateIconWrapper = styled.span`
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
  position: absolute;
  right: 8px;
  background-color: ${COLORS.White}; ;
`;

export const CustomDatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2px;
  gap: ${rem(10)};

  button {
    position: relative;
    height: ${rem(32)};
    width: ${rem(32)};

    &:focus-visible {
      outline: 1px solid ${COLORS.Black} !important;
      border: 1px solid ${COLORS.Black} !important;
      border-radius: 5px;
    }

    &::before {
      border-color: ${COLORS.White};
      border-style: solid;
      border-width: 3px 3px 0 0;
      content: '';
      display: block;
      height: 9px;
      position: absolute;
      width: 9px;
      top: 11px;
    }

    &.btn-prev {
      transform: translate(2px, -1px);
    }

    &.btn-next {
      transform: translate(-2px, -1px);
    }

    &.btn-prev::before {
      transform: rotate(225deg);
      right: 9px;
    }

    &.btn-next::before {
      transform: rotate(45deg);
      left: 9px;
    }

    &:disabled::before {
      border-color: #ccc;
    }
  }

  .customSelect {
    position: relative;
    cursor: pointer;
    background-color: #f3f3f3;
    border-radius: 6rem;
    display: flex;
    align-items: center;
    height: fit-content;
    z-index: 0;
    margin-right: ${rem(5)};
    margin-bottom: ${rem(3)};

    select {
      font-size: ${rem(10)};
      line-height: ${rem(14)};
      font-weight: 500;
      cursor: pointer;
      -webkit-appearance: none;
      background-color: transparent;
      z-index: 1;
      position: relative;
      padding: ${rem(4)} ${rem(20)} ${rem(4)} ${rem(10)};
      &:hover {
        outline: none;
        box-shadow: none;
      }

      &:focus-visible {
        border-radius: ${rem(60)};
      }
    }
    .material-symbols-rounded {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      color: #595959;
    }
  }
`;
