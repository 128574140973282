import { Form, Formik } from 'formik';
import React from 'react';
import Button from '../../../../components/button';
import FormSelect from '../../../../components/dropdown/formSelect';
import InputText from '../../../../components/input-text/inputText';
import Spinners from '../../../../components/spinner';
import Heading from '../../../../components/typography';
import {
  useGetTransactionPeriodConfig,
  useGetTransactionPeriodTypes,
  usePostPreClearancePeriodConfig,
  useWealthlaneAwardSettlementApi,
} from '../../../../hooks';
import { PRE_CLEARANCE_PERIOD_CONFIG } from '../../../../utils/validations/validations';
import {
  ElectionCardBlock,
  ElectionWindowWrapper,
} from '../../configuration/components/election-window-config/electionWindow.styled';
import { checkNaNValue } from '../../../../utils/global';
import { toast } from 'react-toastify';
import ToastComponent from '../../../../components/toast';

const TradePreClearanceTransactionPeriodConfigs = (): JSX.Element => {
  const { handleRequest } = useWealthlaneAwardSettlementApi();

  const { data, isLoading: configLoading } = useGetTransactionPeriodConfig(handleRequest);
  const { data: transactionPeriodOptions } = useGetTransactionPeriodTypes(handleRequest);
  const { mutate, isLoading } = usePostPreClearancePeriodConfig(handleRequest);
  const initialData = {
    id: data?.id ?? null,
    transactionPeriod: data?.transactionPeriod ?? '',
    transactionPeriodTypeId: data?.transactionPeriodTypeId ?? '',
  };

  const handleSubmit = (values: any, data: any): any => {
    if (
      values?.transactionPeriod === data?.transactionPeriod &&
      values?.transactionPeriodTypeId === data?.transactionPeriodTypeId
    ) {
      toast.error(<ToastComponent label='Info' message={'No changes made'} />);
    } else {
      mutate(values);
    }
  };

  return (
    <ElectionWindowWrapper>
      <Heading
        variant='h4'
        title={'Set Up Transaction Period for Pre Clearance'}
        className='election-head'
      />
      <ElectionCardBlock>
        {configLoading && <Spinners />}
        {!configLoading && (
          <Formik
            enableReinitialize
            initialValues={initialData}
            validationSchema={PRE_CLEARANCE_PERIOD_CONFIG}
            onSubmit={(values) => {
              handleSubmit(values, data);
            }}>
            {({ touched, handleChange, errors, values, setFieldValue }) => {
              return (
                <Form>
                  <div className=''>
                    <div className='grid lg:grid-cols-12  items-baseline gap-x-8'>
                      <div className='lg:col-span-2 '>
                        <InputText
                          type='text'
                          label='Transaction Period'
                          name='transactionPeriod'
                          onChange={(e: any) => {
                            checkNaNValue(e?.target?.value, 'transactionPeriod', setFieldValue);
                          }}
                          errors={errors}
                          touched={touched}
                          required
                        />
                      </div>
                      <div className='lg:col-span-2 lg:relative'>
                        <FormSelect
                          containerClassName='lg:absolute mt-[10px]'
                          type='text'
                          label=''
                          name='transactionPeriodTypeId'
                          viewName='transactionPeriodTypeId'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={transactionPeriodOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex'>
                    <Button
                      // btnClassName='translate-y-[36px]'
                      title='Save'
                      type='submit'
                      loading={isLoading}
                      // disabled={_.isEqual(initialData, values)}
                      variant='primary'
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </ElectionCardBlock>
    </ElectionWindowWrapper>
  );
};

export default TradePreClearanceTransactionPeriodConfigs;
