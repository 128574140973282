import { type UseQueryResult, useQuery } from 'react-query';
import {
  OptionAwardsService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAward_OptionAwardsVestingReportDto_Wealthlane_ReportService_Application_Contracts,
} from '../../services/wealthlane-report-services';
import { type OptionVestingReportType, type TableParamsType } from '../../types/common';

const useGetOptionAwardVestingReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  filterInfo: OptionVestingReportType,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_ReportService_Dtos_OptionAward_OptionAwardsVestingReportDto_Wealthlane_ReportService_Application_Contracts
  | undefined,
  unknown
> => {
  const {
    vestedDateType,
    vestedFromDate,
    vestedToDate,
    awardIssuedFromDate,
    awardIssuedToDate,
    awardTypeId,
    grantId,
    planId,
    grantUniqueId,
    planUniqueId,
    vestedFromQuantity,
    vestedToQuantity,
    vestedFromValue,
    vestedToValue,
    employeeStatus,
    employeeTypeId,
    optionExpirationFromDate,
    optionExpirationToDate,
    residentCountryId,
    residentState,
    residentStateId,
    workCountryId,
    workState,
    workStateId,
    departmentId,
  } = filterInfo;
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const optionAwardVestingReport = useQuery(
    [
      'getOptionAwardVestingReport',
      vestedDateType,
      vestedFromDate,
      vestedToDate,
      awardIssuedFromDate,
      awardIssuedToDate,
      awardTypeId,
      grantId,
      planId,
      grantUniqueId,
      planUniqueId,
      vestedFromQuantity,
      vestedToQuantity,
      vestedFromValue,
      vestedToValue,
      employeeStatus,
      employeeTypeId,
      optionExpirationFromDate,
      optionExpirationToDate,
      residentCountryId,
      residentState,
      residentStateId,
      workCountryId,
      workState,
      workStateId,
      departmentId,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      const payload: any = {
        vestedDateType: Number(vestedDateType),
        vestedFromDate,
        vestedToDate,
        awardIssuedFromDate,
        awardIssuedToDate,
        awardTypeId,
        grantId,
        planId,
        grantUniqueId,
        planUniqueId,
        vestedFromQuantity,
        vestedToQuantity,
        vestedFromValue,
        vestedToValue,
        employeeStatus,
        employeeTypeId,
        optionExpirationFromDate,
        optionExpirationToDate,
        residentCountryId,
        residentState,
        residentStateId,
        workCountryId,
        workState,
        workStateId,
        departmentId,
        columnPreferences: [],
        columnPreferencesSystemName: [],
        filter,
        sorting,
        skipCount,
        maxResultCount,
      };

      Object.keys(payload).forEach((key, index) => {
        payload[key] = payload[key] !== '' ? payload[key] : null;
      });

      return await handleRequest(
        OptionAwardsService.postApiReportApiAppOptionAwardsGetOptionsAwardVestingReport(payload)
      );
    }
  );
  return optionAwardVestingReport;
};

export default useGetOptionAwardVestingReport;
