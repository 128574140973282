/* eslint-disable camelcase */
import { type UseMutationResult, useMutation } from 'react-query';

import {
  CustomReportsService,
  Wealthlane_ReportService_Dtos_CustomReport_CustomReportPostDto,
  Wealthlane_ReportService_Dtos_CustomReport_CustomReportSaveAndRunResponseDto,
} from '../../services/wealthlane-report-services';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';

const usePostSubmitAndRunDynamicReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  openSubmitModal: () => void,
  setTaskReferanceNo?: any
): UseMutationResult<
  Wealthlane_ReportService_Dtos_CustomReport_CustomReportSaveAndRunResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  return useMutation(
    async (payload: any) => {
      const newPayload = { ...payload, reportConfigurationId: payload.id };

      return await handleRequest(
        CustomReportsService.postApiReportApiAppCustomReportsSubmitAndRunReport(newPayload)
      );
    },
    {
      onSuccess: async (
        response:
          | Wealthlane_ReportService_Dtos_CustomReport_CustomReportSaveAndRunResponseDto
          | undefined
      ) => {
        if (response && response?.isSuccess === true) {
          openSubmitModal();
          setTaskReferanceNo(response.taskRefrenceNo);
        } else if (response && response?.error) {
          toast.error(<ToastComponent label='Error' message={`${response?.error[0]}`} />);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostSubmitAndRunDynamicReport;
