import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerUserLogInAndSecurityService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserSecurityQuestionsAnswersDto,
} from '../../services/wealthlane-customer-services';

const useGetCustomerSecurityQuestionAnswer = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserSecurityQuestionsAnswersDto | undefined,
  unknown
> => {
  const CustomerSecurityQuestionAnswer = useQuery(
    ['getCustomerSecurityQuestionAnswer'],
    async () => {
      return await handleRequest(
        CustomerUserLogInAndSecurityService.getApiCustomerApiAppCustomerUserLogInAndSecurityCustomerUserSecurityQuestionsAnswers()
      );
    }
  );

  return CustomerSecurityQuestionAnswer;
};

export default useGetCustomerSecurityQuestionAnswer;
