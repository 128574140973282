import { isEmpty } from 'lodash';
import Button from '../../../../components/button';
import {
  CardBody,
  CardContainer,
  CardTitle,
  CardWrapper,
} from '../../../../components/card/taskCard.styled';
import ModalComponent from '../../../../components/modal/modal';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';
import Spinners from '../../../../components/spinner';
import Heading from '../../../../components/typography';
import { COLORS } from '../../../../constants/style/color';
import useWealthlaneTaxApi from '../../../../hooks/useWealthlaneTaxApi';
import {
  TaxCalculationService,
  Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto,
  Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationUsingImportDto,
} from '../../../../services/wealthlane-tax-services';
import { convertNumberWithCommas, handleOpenFile } from '../../../../utils/global';
import { ImportWrapper, InputField } from '../../../participants/participant.styled';
import { TaxImportWrapper } from './taxSetupStyled';
import NoDataFound from '../../../../components/no-data-found';
import ViewUploadedDoc from '../../../../components/view-uploaded-doc';

interface ModalType {
  isModalOpen: boolean;
  closeModal: () => void;
  isLoading?: boolean;
  handleSubmit: () => void;
  fileImport?: any;
  addLoading?: boolean;
  isEdit?: boolean;
  data?: Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationUsingImportDto[] | any;
  importedFileName?: string;
  error?: any;
}

const TaxImportModal = ({
  isModalOpen,
  closeModal,
  isLoading,
  handleSubmit,
  fileImport,
  importedFileName,
  addLoading,
  data,
  error,
  isEdit,
}: ModalType): JSX.Element => {
  const { handleRequest } = useWealthlaneTaxApi();
  const downloadTemplate = (name: string): void => {
    handleRequest(
      TaxCalculationService.postApiTaxApiAppTaxCalculationDownloadTaxCalculationTestSample()
    )
      .then((data: any) => {
        if (data != null) {
          handleOpenFile(`${name}.xlsx`, data.content);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };
  return (
    <ModalComponent
      show={isModalOpen}
      size={'md'}
      closeModal={closeModal}
      title={'Import Tax Scenario'}
      children={
        <>
          <ModalBodyComponent>
            <TaxImportWrapper>
              <div className='download-template w-full'>
                <Heading
                  variant={'body-s'}
                  title={'Quick & easy to use CSV template to import large data in an instant.'}
                />
                <div className='flex gap-x-4 mt-4'>
                  <Button
                    variant={'secondary-white'}
                    title={'Download Template'}
                    icon={'cloud_upload'}
                    onClick={() => {
                      downloadTemplate('Tax Scenario Template');
                    }}
                  />
                  <InputField className='flex'>
                    <div className='input-file-container'>
                      <input
                        className='input-file'
                        id='my-file'
                        type='file'
                        accept='.xlsx, .xls'
                        onChange={(e: any) => {
                          fileImport(e);
                        }}
                      />
                      <label
                        className='dt-btn dt-btn-outline-primary dt-btn-sm input-file-trigger text-center sm:text-left '
                        htmlFor='my-file'>
                        Import Bulk XLS
                      </label>
                    </div>
                  </InputField>
                </div>
                {/* <InputField className='flex justify-between items-center'>
                  <div>
                    <Button
                      variant={'primary'}
                      title={'Execute'}
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  </div>
                </InputField> */}
                <div className='import-file-Name'>
                  <span>
                    {importedFileName !== undefined ? importedFileName : 'No File Chosen'}
                  </span>
                </div>
              </div>
            </TaxImportWrapper>
            {isLoading && <Spinners containerClassName='' />}
            {!isLoading && data !== undefined && (
              <>
                {data !== undefined &&
                  data?.map(
                    (
                      allResult:
                        | Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationUsingImportDto
                        | any,
                      index: number
                    ) => (
                      <CardContainer size='xsm'>
                        <CardWrapper>
                          <CardTitle>
                            <h2>{`Row ${index + 1}`}</h2>
                          </CardTitle>
                          <CardBody className='m-0'>
                            {!isEmpty(allResult?.result) ? (
                              <div className='methodTable'>
                                <table>
                                  <thead>
                                    <tr>
                                      <td>
                                        <Heading
                                          variant={'body'}
                                          title={'Tax Name'}
                                          color={COLORS.SpanishGray}
                                        />
                                      </td>
                                      <td className='flex justify-end'>
                                        <Heading
                                          variant={'body'}
                                          title={'Tax Amount'}
                                          color={COLORS.SpanishGray}
                                        />
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {allResult?.result?.map(
                                      (
                                        taxResult: Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto
                                      ) => (
                                        <tr>
                                          <td>
                                            <Heading
                                              variant={'body-s'}
                                              title={taxResult?.taxName}
                                            />
                                          </td>
                                          <td>
                                            <Heading
                                              variant={'body-s'}
                                              title={`$${convertNumberWithCommas(
                                                taxResult?.taxAmount,
                                                true
                                              )}`}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <NoDataFound message='No Tax Found' />
                            )}
                          </CardBody>
                        </CardWrapper>
                      </CardContainer>
                    )
                  )}
              </>
            )}
            {error !== undefined && error !== null && !isEmpty(error?.body) && !isLoading && (
              <>
                <div className='error-wrapper'>
                  <ViewUploadedDoc
                    className='items-start'
                    variant='default'
                    children={
                      <>
                        <Heading
                          variant='body-xs'
                          title={`${
                            error?.body?.error?.validationErrors !== null
                              ? error?.body?.error?.validationErrors[0]?.message ===
                                  'Object reference not set to an instance of an object.' ||
                                error?.body?.error?.validationErrors[0]?.message ===
                                  'Index was outside the bounds of the array.'
                                ? 'Invalid template'
                                : (error?.body?.error?.validationErrors[0]?.message as string)
                              : error?.body?.error?.message
                          }`}
                        />
                      </>
                    }
                  />
                </div>
              </>
            )}
          </ModalBodyComponent>

          <ModalFooterComponent
            onCloseModal={() => {
              closeModal();
            }}
            onClickButton={() => {
              handleSubmit();
            }}
            modalStatus='Add'
            buttonTitle='Execute'
            icon=''
          />
        </>
      }
    />
  );
};

export default TaxImportModal;
