import { type UseQueryResult, useQuery } from 'react-query';
import { GrantService } from '../../services/wealthlane-award-services';

const useGetGrantPlanTypeList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string | undefined
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const grantPlanTypeList = useQuery(
    ['getGrantPlanTypeList', id],
    async () => {
      if (id != null && id !== '') {
        return await handleRequest(GrantService.getApiAwardApiAppGrantPlanTypesByPlanId(id));
      }
      return await handleRequest(GrantService.getApiAwardApiAppGrantPlanTypes());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return grantPlanTypeList;
};

export default useGetGrantPlanTypeList;
