import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils/style';

interface CardType {
  size?: 'xsm' | 'sm' | 'md-s' | 'md' | 'lg';
}

export const CardContainer = styled.div<CardType>`
  background: ${COLORS.White};
  width: 100%;
  border: 1px solid ${COLORS.CulturedWhite};
  border-radius: 6px;
  margin: 10px 0;
  padding: 0;
  ${(props) => {
    if (props.size === 'xsm') {
      return css`
        padding: ${rem(0)};
      `;
    }
    if (props.size === 'sm') {
      return css`
        padding: ${rem(18)};
      `;
    }
    if (props.size === 'md-s') {
      return css`
        padding: ${rem(20)} ${rem(16)};
      `;
    }
    if (props.size === 'md') {
      return css`
        padding: ${rem(24)} ${rem(32)};
      `;
    }
    if (props.size === 'lg') {
      return css`
        padding: ${rem(40)} ${rem(32)};
      `;
    }
  }}
`;

export const CardWrapper = styled.div`
  padding: 11px 20px;
`;
export const CardTitle = styled.div`
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
  padding-bottom: 10px;
  margin-bottom: 12px;
  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: ${COLORS.Blue};
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    color: ${COLORS.Gray1000};
  }
`;
export const CardBody = styled.div`
  margin-bottom: 17px;
  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: ${COLORS.Gray60};
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${COLORS.Gray50};
    margin: 0;
  }
  div {
    margin: 0 0 10px 0;
  }
`;
