import React, { useState, useRef } from 'react';
import Button from '../button';
import PopperContainer from './popperContainer';
import { ButtonMenu, Dot } from './verticalDot.style';
import { isEmpty } from 'lodash';

interface ActionButtonType {
  actions?: any[];
  id?: string | number | undefined;
  rowData?: Record<string, string>;
  counter?: number;
  as?: string;
  disable?: boolean;
  doubleTable?: boolean;
  buttonTitle?: string;
  icon?: string;
  variant?: string;
}

export const ActionButton = ({
  actions,
  id,
  rowData,
  counter,
  as,
  disable = false,
  doubleTable,
  buttonTitle,
  icon,
  variant,
}: ActionButtonType): JSX.Element => {
  const buttonRef = useRef<any>([]);
  const [show, setShow] = useState(false);
  const handleShow = (value?: boolean): void => {
    value ?? false ? setShow(value ?? false) : setShow(!show);
  };

  return (
    <>
      {as === 'Button' ? (
        <>
          <ButtonMenu ref={buttonRef}>
            <Button
              variant={variant ? variant : 'secondary-white'}
              title={buttonTitle ? buttonTitle : 'Plan Options'}
              icon={icon ? icon : 'edit'}
              onClick={() => {
                handleShow();
              }}
            />
          </ButtonMenu>
          <PopperContainer
            actions={actions}
            rowData={rowData}
            referenceElement={buttonRef}
            id={id}
            counter={counter}
            show={show}
            handleShow={handleShow}
          />
        </>
      ) : (
        <>
          <Dot key={counter} className='cursor-pointer' disable={disable}>
            <span
              className={`material-symbols-outlined ${show ? 'active' : ''}`}
              ref={buttonRef}
              onClick={() => {
                disable ? '' : handleShow();
              }}>
              more_vert
            </span>
          </Dot>
          <PopperContainer
            actions={actions}
            rowData={rowData}
            referenceElement={buttonRef}
            id={id}
            counter={counter}
            show={show}
            handleShow={handleShow}
            doubleTable={doubleTable}
          />
        </>
      )}
    </>
  );
};
