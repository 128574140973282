/* eslint-disable camelcase */
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import FormSelect from '../../../components/dropdown/formSelect';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import CustomTextArea from '../../../components/text-area/textArea';
import {
  VestingTemplateService,
  type Wealthlane_AwardService_Dtos_VestingTemplate_UpdateVestingTemplateDto,
} from '../../../services/wealthlane-award-services';
import { type ModalStatusType } from '../../../types/common';
import { VESTING_TEMPLATES_VALIDATION } from '../../../utils/validations/validations';
import { FormDivider } from '../system-roles/roles.styled';
import { setObjectNullValue } from '../../../utils/global';
import { DATE_FORMAT, VestingFrequencyOptions } from '../../../constants/common';
import ViewUploadedDoc from '../../../components/view-uploaded-doc';
import { VestingModalWrapper } from './vestingTemplates.styled';
import Heading from '../../../components/typography';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import ToastComponent from '../../../components/toast';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import { useGetRoundingType, useWealthlaneAwardApi } from '../../../hooks';
import _, { isEmpty } from 'lodash';
import CustomDatePicker from '../../../components/date-picker/customDatePicker';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
import { commonValidation } from '../../../utils/validations/validationRule';
import moment from 'moment';

interface VestingModalType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  modalLoading: boolean;
  vestingTypeOptions?: Array<{
    label: string;
    value: string;
    isCliffVesting: boolean | undefined;
    isStepVesting: boolean | undefined;
    isHybridVesting: boolean | undefined;
  }>;
  setModalStatus: (value: ModalStatusType) => void;
  initialData: {
    data: any;
    id: string;
  };
}

const NewVestingModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  initialData,
  modalLoading,
  vestingTypeOptions,
  setModalStatus,
}: VestingModalType): JSX.Element => {
  const [percentageOne, setPercentageOne] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const queryClient = useQueryClient();
  const [validationState, setValidationState] = useState<any>(VESTING_TEMPLATES_VALIDATION);
  const { handleRequest } = useWealthlaneAwardApi();

  const useSubmitVestingTemplates = (): any => {
    return useMutation(
      async (payload: Wealthlane_AwardService_Dtos_VestingTemplate_UpdateVestingTemplateDto) => {
        const newValue: any = setObjectNullValue(payload);
        if (initialData.id != null && initialData.id !== '') {
          return await handleRequest(
            VestingTemplateService.putApiAwardApiAppVestingTemplateVestingTemplate({
              ...newValue,
              id: initialData.id,
            })
          );
        }
        return await handleRequest(
          VestingTemplateService.postApiAwardApiAppVestingTemplateVestingTemplate(newValue)
        );
      },
      {
        onSuccess: (response, resetForm: any) => {
          if (response != null) {
            void queryClient.invalidateQueries('getVestingTemplateList');
            void queryClient.invalidateQueries('getGrantVestingTemplateList');
            closeModal();
            toast.success(
              <ToastComponent
                label='Success'
                message={`Vesting template ${
                  initialData.id != null && initialData.id !== '' ? 'edited' : 'added'
                } `}
              />
            );
            resetForm();
          }
        },
        onError: (err) => {
          console.log('error', err);
        },
      }
    );
  };

  const { mutate, isLoading } = useSubmitVestingTemplates();

  const handleSubmit = (values: any): void => {
    const vestsInMonths = Number(values.lengthInMonths) - Number(values.vestsAfterInMonths);

    const payload = {
      intervalInMonths: Number(values.intervalInMonths),
      amountOneInPercentage:
        values?.amountOneInPercentage !== '' ? values?.amountOneInPercentage : null,
      // amountTwoInPercentage: percentage ?? 0,
      lengthInMonths: values.lengthInMonths != '' ? Number(values.lengthInMonths) : null,
      name: values.name,
      summary: values.summary ?? null,
      vestingTypeId: values.vestingTypeId ?? null,
      vestsAfterInMonths:
        values.vestsAfterInMonths != '' ? Number(values.vestsAfterInMonths) : null,
      // planTypeCategoryId: values.planTypeCategoryId,
      roundingTypeId: vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
        ?.isCliffVesting
        ? 3
        : values?.roundingTypeId ?? null,
      periodStartDate: values?.periodStartDate ? values?.periodStartDate : null,
      vestAtEndOfMonth: values?.vestAtEndOfMonth ?? false,
      vestsInMonths: vestsInMonths ?? 0,
      fractionalDecimalDigit: 0,
    };

    mutate(payload as any);
  };

  useEffect(() => {
    if (initialData.data as boolean) {
      const { vestsAfterInMonths, lengthInMonths, intervalInMonths, amountOneInPercentage } =
        initialData.data;

      handlePercentageOne(vestsAfterInMonths, lengthInMonths);
      handlePercentageTwo(intervalInMonths, lengthInMonths);
      handlePercentageThree(
        intervalInMonths,
        lengthInMonths,
        Number(Number(amountOneInPercentage * 100).toFixed(2)),
        vestsAfterInMonths
      );
    }
  }, [initialData.data]);

  const handlePercentageOne = (value: string, lengthInMonths: number): void => {
    if (value != null && Number(value) > 0) {
      const calcValue = (Number(value) / Number(lengthInMonths)) * 100 ?? 0;

      setPercentageOne(Number(calcValue));
    }
  };

  const handlePercentageTwo = (value: string, lengthInMonths: number): void => {
    if (value != null && Number(value) > 0) {
      const newValue = (Number(value) / Number(lengthInMonths)) * 100 ?? 0;
      setPercentage(Number(newValue));
    }
  };
  const handlePercentageThree = (
    value: number,
    lengthInMonths: number,
    amountOneInPercentage: number,
    vestsAfterInMonths: number
  ): void => {
    const remainingPercentage = 100 - Number(amountOneInPercentage);
    const remainingMonth = Number(lengthInMonths) - Number(vestsAfterInMonths);
    const newValue = remainingPercentage / remainingMonth;

    const quarterlyRemainingMonth = remainingMonth / 3;
    const quarterlyValue = remainingPercentage / quarterlyRemainingMonth;
    const semiAnnuallyRemainingMonth = remainingMonth / 6;
    const semiAnnuallyValue = remainingPercentage / semiAnnuallyRemainingMonth;
    const AnnuallyRemainingMonth = remainingMonth / 12;
    const AnnuallyValue = remainingPercentage / AnnuallyRemainingMonth;

    if (amountOneInPercentage) {
      if (value === 3) {
        setPercentage(Number(quarterlyValue));
      } else if (value === 6) {
        setPercentage(Number(semiAnnuallyValue));
      } else if (value === 12) {
        setPercentage(Number(AnnuallyValue));
      } else {
        setPercentage(Number(newValue));
      }
    } else {
      const nonPercentageValue = (Number(value) / Number(lengthInMonths)) * 100 ?? 0;
      setPercentage(Number(nonPercentageValue));
    }

    // setPercentage(Number(newValue));
  };

  useEffect(() => {
    if (initialData.data as boolean) {
      const isData = vestingTypeOptions?.find(
        (item) => item.value === initialData.data?.vestingTypeId
      );
      if (isData?.isHybridVesting ?? false) {
        setValidationState({
          ...VESTING_TEMPLATES_VALIDATION,
          vestsAfterInMonths: Yup.number()
            .min(1, 'Must be greater than 0')
            .max(100, 'Maximum characters exceeds')
            .required('Required field')
            .test('Digits only', 'Invalid Number', (value: any) => /^\d+$/.test(value))
            .nullable(),
          intervalInMonths: Yup.string().required('Required field'),
        });
      }
      if (isData?.isStepVesting ?? false) {
        setValidationState({
          ...VESTING_TEMPLATES_VALIDATION,
          intervalInMonths: Yup.number()
            .min(1, 'Must be greater than 0')
            .max(100, 'Maximum characters exceeds')
            .required('Required field')
            .test('Digits only', 'Invalid Number', (value: any) => /^\d+$/.test(value))
            .nullable(),
        });
      }
      if (isData?.isCliffVesting ?? false) {
        setValidationState({
          ...VESTING_TEMPLATES_VALIDATION,
        });
      }
    }
  }, [initialData.data]);

  const { data: roundingTypeOptions } = useGetRoundingType(handleRequest);

  const [termPeriod, setTermPeriod] = useState(0);
  const [cliff, setCliff] = useState(0);
  const [stepSize, setStepSize] = useState<any>(0);

  const remainingDuration = termPeriod && cliff && termPeriod - cliff;
  const vestModulus = remainingDuration && stepSize && remainingDuration % stepSize;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialData.data,
        amountOneInPercentage: initialData?.data?.amountOneInPercentage
          ? Number(initialData?.data?.amountOneInPercentage * 100).toFixed(2)
          : '',
      }}
      validationSchema={Yup.object().shape({
        ...validationState,
      })}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({ touched, handleChange, errors, setFieldValue, setFieldTouched, values }) => {
        return (
          <VestingModalWrapper>
            <ModalComponent
              show={isModalOpen}
              closeModal={closeModal}
              loading={modalLoading}
              valueEdited={_.isEqual(values, initialData.data)}
              title={`${modalStatus === 'Add' ? 'Create New' : modalStatus} Vesting Template`}
              children={
                <Form>
                  <ModalBodyComponent>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5'>
                      <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                        <InputText
                          type='text'
                          label='Vesting Template Name'
                          name='name'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                      <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                        <CustomTextArea
                          label='Description'
                          name='summary'
                          onChange={handleChange}
                          errors={errors}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          touched={touched}
                          rows='5'
                        />
                      </div>
                      <div className='col-span-1 lg:col-span-2'>
                        <FormSelect
                          type='text'
                          label='Vesting Type'
                          name='vestingTypeId'
                          viewName='vestingType'
                          onChange={(value: string) => {
                            const isData = vestingTypeOptions?.find((item) => item.value === value);
                            if (isData?.isHybridVesting ?? false) {
                              setValidationState({
                                ...VESTING_TEMPLATES_VALIDATION,
                                vestsAfterInMonths: Yup.number()
                                  .min(1, 'Must be greater than 0')
                                  .max(100, 'Maximum characters exceeds')
                                  .required('Required field')
                                  .test('Digits only', 'Invalid Number', (value: any) =>
                                    /^\d+$/.test(value)
                                  )
                                  .nullable(),
                                intervalInMonths: Yup.string().required('Required field'),
                              });
                            }
                            if (isData?.isStepVesting ?? false) {
                              setValidationState({
                                ...VESTING_TEMPLATES_VALIDATION,
                                vestsAfterInMonths: Yup.number()
                                  .min(1, 'Must be greater than 0')
                                  .max(100, 'Maximum characters exceeds')
                                  .required('Required field')
                                  .test('Digits only', 'Invalid Number', (value: any) =>
                                    /^\d+$/.test(value)
                                  )
                                  .nullable(),
                              });
                            }
                            if (isData?.isCliffVesting ?? false) {
                              setValidationState({
                                ...VESTING_TEMPLATES_VALIDATION,
                                roundingTypeId: commonValidation({ required: false }).nullable(),
                              });
                            }
                            if (
                              !isData?.isCliffVesting &&
                              !isData?.isStepVesting &&
                              !isData?.isHybridVesting
                            ) {
                              setValidationState({
                                ...VESTING_TEMPLATES_VALIDATION,
                                intervalInMonths: Yup.string().required('Required field'),
                                // periodStartDate: Yup.date()
                                //   .required('Required field')
                                //   .typeError('Required field'),
                              });
                            }

                            setFieldValue('vestingType', value);
                            setFieldValue('vestingTypeId', value);
                            setFieldValue('lengthInMonths', '');
                            setFieldValue('intervalInMonths', '');
                            setFieldValue('vestsAfterInMonths', '');
                            setFieldValue('amountOneInPercentage', '');
                            setTimeout(() => {
                              setFieldTouched('vestingTypeId', true);
                              // setFieldTouched('roundingTypeId', true);
                            });
                            setPercentageOne(0);
                            setPercentage(0);
                            setTermPeriod(0);
                            setCliff(0);
                            setStepSize(0);
                          }}
                          errors={errors}
                          touched={touched}
                          options={vestingTypeOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                          inputType={modalStatus}
                          disabled={modalStatus === 'View'}
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <CustomCheckBox
                        name='vestAtEndOfMonth'
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        errors={errors}
                        touched={touched}
                        className={'individual-check mt-2'}
                        labelClassName={'whitespace-normal'}
                        disabled={modalStatus === 'View'}
                        checked={values?.vestAtEndOfMonth}
                        id='acceptCheck'
                        label={
                          <Heading
                            variant='body-xs'
                            className='mt-1'
                            title={'Vest At End of Prior Month'}
                          />
                        }
                        required
                      />
                    </div>
                    <FormDivider />
                    {Boolean(values.vestingTypeId) && (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        {!vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                          ?.isCliffVesting &&
                          !vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                            ?.isStepVesting &&
                          !vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                            ?.isHybridVesting && (
                            <div className='col-span-1 lg:col-span-2'>
                              <CustomDatePicker
                                label='Period Start Date'
                                name='periodStartDate'
                                onChange={handleChange}
                                arrValue={values?.periodStartDate}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                errors={errors}
                                touched={touched}
                                values={values}
                                defaultValue={new Date()}
                                required
                                disabled={modalStatus === 'View'}
                              />
                            </div>
                          )}
                        <div className='col-span-1 lg:col-span-2'>
                          <InputText
                            type='number'
                            label='Term Period'
                            name='lengthInMonths'
                            onChange={(e: any) => {
                              const isData = vestingTypeOptions?.find(
                                (item) => item.value === values.vestingTypeId
                              )?.isCliffVesting;

                              if (
                                vestingTypeOptions?.find(
                                  (item) => item.value === values.vestingTypeId
                                )?.isCliffVesting
                              ) {
                                setFieldValue('vestsAfterInMonths', e.target.value);
                                setFieldValue('amountOneInPercentage', 100);
                                setFieldValue('intervalInMonths', 1);
                              }
                              setFieldValue('lengthInMonths', e.target.value);
                              if (
                                !vestingTypeOptions?.find(
                                  (item) => item.value === values.vestingTypeId
                                )?.isCliffVesting
                              ) {
                                setFieldValue('intervalInMonths', '');
                                setFieldValue('vestsAfterInMonths', '');
                                setFieldValue('amountOneInPercentage', '');
                              }
                              setPercentageOne(0);
                              setPercentage(0);
                              setTermPeriod(e.target.value);
                            }}
                            errors={errors}
                            touched={touched}
                            inputType={modalStatus}
                            disabled={modalStatus === 'View'}
                            className={
                              vestingTypeOptions?.find(
                                (item) => item.value === values.vestingTypeId
                              )?.isCliffVesting ?? false
                                ? 'mb-0'
                                : ''
                            }
                            width='104px'
                            required
                          />
                        </div>
                        {(vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                          ?.isCliffVesting ??
                          false) && (
                          <div className='col-span-1 sm:col-span-2 lg:col-span-4 cliff-info'>
                            <Heading
                              variant='body-s'
                              title='After the specified vesting period the vesting will end.'
                              className='info'
                            />
                          </div>
                        )}
                        {vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                          ?.isStepVesting && (
                          <div className='col-span-1 lg:col-span-2'>
                            <InputText
                              type='number'
                              label={'Vesting Frequency (Month)'}
                              name='intervalInMonths'
                              onChange={(e: any) => {
                                if (
                                  vestingTypeOptions?.find(
                                    (item) => item.value === values.vestingTypeId
                                  )?.isStepVesting ??
                                  false
                                ) {
                                  setValidationState({
                                    ...validationState,
                                    intervalInMonths: Yup.number()
                                      .min(0, 'Must be greater than 0')
                                      .max(
                                        Number(values.lengthInMonths) - 1,
                                        `Please enter a valid ${
                                          vestingTypeOptions?.find(
                                            (item) => item.value === values.vestingTypeId
                                          )?.isHybridVesting ?? false
                                            ? 'Cliffs'
                                            : 'Vesting Frequency'
                                        } (Month) less than the Term Period`
                                      )
                                      .required('Required field')
                                      .test('Digits only', 'Must be whole number', (value: any) =>
                                        /^\d+$/.test(value)
                                      )
                                      .test((value, testContext: Yup.TestContext) => {
                                        return Number(values.lengthInMonths) % Number(value) !== 0
                                          ? testContext.createError({
                                              message:
                                                'Vesting Frequency is invalid, enter a number that is divisible to Term Period',
                                            })
                                          : true;
                                      })

                                      .nullable(),
                                  });
                                }

                                if (
                                  vestingTypeOptions?.find(
                                    (item) => item.value === values.vestingTypeId
                                  )?.isHybridVesting ??
                                  false
                                ) {
                                  setValidationState({
                                    ...validationState,
                                    vestsAfterInMonths: Yup.number()
                                      .max(
                                        Number(values.lengthInMonths) - 1,
                                        `Please enter a valid ${
                                          vestingTypeOptions?.find(
                                            (item) => item.value === values.vestingTypeId
                                          )?.isHybridVesting ?? false
                                            ? 'Cliffs'
                                            : 'Vesting Frequency'
                                        } (Month) less than the Term Period`
                                      )
                                      .required('Required field')
                                      .test('Digits only', 'Must be whole number', (value: any) =>
                                        /^\d+$/.test(value)
                                      )
                                      .nullable(),
                                  });
                                }

                                setFieldValue('vestsAfterInMonths', e.target.value);
                                setFieldValue('intervalInMonths', e.target.value);
                                setCliff(e.target.value);

                                handlePercentageOne(e.target.value, values.lengthInMonths);
                                handlePercentageTwo(e.target.value, values.lengthInMonths);
                                setTimeout(() => {
                                  setFieldTouched('vestsAfterInMonths', true);
                                });
                              }}
                              errors={errors}
                              touched={touched}
                              inputType={modalStatus}
                              disabled={
                                modalStatus === 'View' || !(values.lengthInMonths as boolean)
                              }
                              width='104px'
                              required
                            />
                          </div>
                        )}
                        {vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                          ?.isHybridVesting && (
                          <>
                            <div className='col-span-1 lg:col-span-1'>
                              <InputText
                                type='number'
                                label={`${
                                  vestingTypeOptions?.find(
                                    (item) => item.value === values.vestingTypeId
                                  )?.isHybridVesting ?? false
                                    ? 'Cliffs (Month)'
                                    : 'Vesting Frequency (Month)'
                                }`}
                                name='vestsAfterInMonths'
                                onChange={(e: any) => {
                                  if (
                                    vestingTypeOptions?.find(
                                      (item) => item.value === values.vestingTypeId
                                    )?.isStepVesting ??
                                    false
                                  ) {
                                    setValidationState({
                                      ...validationState,
                                      vestsAfterInMonths: Yup.number()
                                        .min(0, 'Must be greater than 0')
                                        .max(
                                          Number(values.lengthInMonths) - 1,
                                          `Please enter a valid ${
                                            vestingTypeOptions?.find(
                                              (item) => item.value === values.vestingTypeId
                                            )?.isHybridVesting ?? false
                                              ? 'Cliffs'
                                              : 'Vesting Frequency'
                                          } (Month) less than the Term Period`
                                        )
                                        .required('Required field')
                                        .test('Digits only', 'Must be whole number', (value: any) =>
                                          /^\d+$/.test(value)
                                        )
                                        .test((value, testContext: Yup.TestContext) => {
                                          return Number(values.lengthInMonths) % Number(value) !== 0
                                            ? testContext.createError({
                                                message:
                                                  'Vesting Frequency is invalid, enter a number that is divisible to Term Period',
                                              })
                                            : true;
                                        })

                                        .nullable(),
                                    });
                                  }

                                  if (
                                    vestingTypeOptions?.find(
                                      (item) => item.value === values.vestingTypeId
                                    )?.isHybridVesting ??
                                    false
                                  ) {
                                    setValidationState({
                                      ...validationState,
                                      vestsAfterInMonths: Yup.number()
                                        .max(
                                          Number(values.lengthInMonths) - 1,
                                          `Please enter a valid ${
                                            vestingTypeOptions?.find(
                                              (item) => item.value === values.vestingTypeId
                                            )?.isHybridVesting ?? false
                                              ? 'Cliffs'
                                              : 'Vesting Frequency'
                                          } (Month) less than the Term Period`
                                        )
                                        .required('Required field')
                                        .test('Digits only', 'Must be whole number', (value: any) =>
                                          /^\d+$/.test(value)
                                        )
                                        .nullable(),
                                    });
                                  }

                                  setFieldValue('vestsAfterInMonths', e.target.value);
                                  setFieldValue('intervalInMonths', '');
                                  setCliff(e.target.value);

                                  handlePercentageOne(e.target.value, values.lengthInMonths);
                                  setTimeout(() => {
                                    setFieldTouched('vestsAfterInMonths', true);
                                  });
                                }}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                disabled={
                                  modalStatus === 'View' || !(values.lengthInMonths as boolean)
                                }
                                width='104px'
                                required
                              />
                            </div>
                            <div className='col-span-1 lg:col-span-1'>
                              <InputText
                                type='number'
                                label={'Cliff Percent'}
                                name='amountOneInPercentage'
                                onChange={(e: any) => {
                                  handleChange(e);
                                  handlePercentageThree(
                                    values?.intervalInMonths,
                                    values.lengthInMonths,
                                    e.target.value,
                                    values?.vestsAfterInMonths
                                  );
                                }}
                                errors={errors}
                                touched={touched}
                                inputType={modalStatus}
                                width='104px'
                                disabled={modalStatus === 'View'}
                                required
                              />
                            </div>
                            <div className='col-span-1 lg:col-span-2'>
                              {(vestingTypeOptions?.find(
                                (item) => item.value === values.vestingTypeId
                              )?.isHybridVesting ??
                                false) && (
                                <FormSelect
                                  label='Vesting Frequency'
                                  name='intervalInMonths'
                                  viewName='intervalInMonths'
                                  onChange={(value: string) => {
                                    setFieldValue('intervalInMonths', value);
                                    setStepSize(value);
                                    handlePercentageThree(
                                      Number(value),
                                      values?.lengthInMonths,
                                      values?.amountOneInPercentage,
                                      values?.vestsAfterInMonths
                                    );
                                    // handlePercentageTwo(value, values.lengthInMonths);
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  options={VestingFrequencyOptions.filter((item) => {
                                    return (
                                      (Number(values.lengthInMonths) -
                                        Number(values.vestsAfterInMonths)) %
                                        Number(item.value) ===
                                      0
                                    );
                                  })}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  inputType={modalStatus}
                                  disabled={
                                    modalStatus === 'View'
                                      ? modalStatus === 'View'
                                      : typeof values.vestsAfterInMonths !== 'number'
                                      ? !(values.vestsAfterInMonths as boolean)
                                      : !(Number(values.vestsAfterInMonths) >= 0)
                                  }
                                  required
                                />
                              )}
                            </div>
                          </>
                        )}
                        {!vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                          ?.isCliffVesting &&
                          !vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                            ?.isStepVesting &&
                          !vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                            ?.isHybridVesting && (
                            <>
                              <div className='col-span-1 lg:col-span-1'>
                                <InputText
                                  type='number'
                                  label={'Cliff Percent'}
                                  name='amountOneInPercentage'
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    handlePercentageThree(
                                      values?.intervalInMonths,
                                      values.lengthInMonths,
                                      values?.amountOneInPercentage,
                                      values?.vestsAfterInMonths
                                    );
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  inputType={modalStatus}
                                  width='104px'
                                  disabled={modalStatus === 'View'}
                                  required
                                />
                              </div>
                              <div className='col-span-1 lg:col-span-2'>
                                {/* {modalStatus === 'Edit' || modalStatus === 'View' ? (
                                  VestingFrequencyOptions.filter((item) => {
                                    return (
                                      (Number(values.lengthInMonths) -
                                        Number(values.vestsAfterInMonths)) %
                                        Number(item.value) ===
                                      0
                                    );
                                  }).some((item) => item.value === values?.intervalInMonths) ? (
                                    <FormSelect
                                      label='Vesting Frequency'
                                      name='intervalInMonths'
                                      viewName='intervalInMonths'
                                      onChange={(value: string) => {
                                        setFieldValue('intervalInMonths', value);
                                        handlePercentageTwo(value, values.lengthInMonths);
                                        handlePercentageThree(
                                          Number(value),
                                          values.lengthInMonths,
                                          values?.amountOneInPercentage,
                                          values?.vestsAfterInMonths
                                        );
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      options={VestingFrequencyOptions.filter((item) => {
                                        return (
                                          (Number(values.lengthInMonths) -
                                            Number(values.vestsAfterInMonths)) %
                                            Number(item.value) ===
                                          0
                                        );
                                      })}
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      inputType={modalStatus}
                                      disabled={
                                        modalStatus === 'View' || values?.lengthInMonths === ''
                                      }
                                      required
                                    />
                                  ) : (
                                    <InputText
                                      type='number'
                                      label={'Vesting Frequency (Month)'}
                                      name='intervalInMonths'
                                      onChange={(e: any) => {
                                        if (
                                          vestingTypeOptions?.find(
                                            (item) => item.value === values.vestingTypeId
                                          )?.isStepVesting ??
                                          false
                                        ) {
                                          setValidationState({
                                            ...validationState,
                                            intervalInMonths: Yup.number()
                                              .min(0, 'Must be greater than 0')
                                              .max(
                                                Number(values.lengthInMonths) - 1,
                                                `Please enter a valid ${
                                                  vestingTypeOptions?.find(
                                                    (item) => item.value === values.vestingTypeId
                                                  )?.isHybridVesting ?? false
                                                    ? 'Cliffs'
                                                    : 'Vesting Frequency'
                                                } (Month) less than the Term Period`
                                              )
                                              .required('Required field')
                                              .test(
                                                'Digits only',
                                                'Must be whole number',
                                                (value: any) => /^\d+$/.test(value)
                                              )
                                              .test((value, testContext: Yup.TestContext) => {
                                                return Number(values.lengthInMonths) %
                                                  Number(value) !==
                                                  0
                                                  ? testContext.createError({
                                                      message:
                                                        'Vesting Frequency is invalid, enter a number that is divisible to Term Period',
                                                    })
                                                  : true;
                                              })

                                              .nullable(),
                                          });
                                        }

                                        if (
                                          vestingTypeOptions?.find(
                                            (item) => item.value === values.vestingTypeId
                                          )?.isHybridVesting ??
                                          false
                                        ) {
                                          setValidationState({
                                            ...validationState,
                                            vestsAfterInMonths: Yup.number()
                                              .max(
                                                Number(values.lengthInMonths) - 1,
                                                `Please enter a valid ${
                                                  vestingTypeOptions?.find(
                                                    (item) => item.value === values.vestingTypeId
                                                  )?.isHybridVesting ?? false
                                                    ? 'Cliffs'
                                                    : 'Vesting Frequency'
                                                } (Month) less than the Term Period`
                                              )
                                              .required('Required field')
                                              .test(
                                                'Digits only',
                                                'Must be whole number',
                                                (value: any) => /^\d+$/.test(value)
                                              )
                                              .nullable(),
                                          });
                                        }

                                        setFieldValue('vestsAfterInMonths', e.target.value);
                                        setFieldValue('intervalInMonths', e.target.value);
                                        setCliff(e.target.value);

                                        handlePercentageOne(e.target.value, values.lengthInMonths);
                                        handlePercentageTwo(e.target.value, values.lengthInMonths);
                                        setTimeout(() => {
                                          setFieldTouched('vestsAfterInMonths', true);
                                        });
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      inputType={modalStatus}
                                      disabled={
                                        modalStatus === 'View' ||
                                        !(values.lengthInMonths as boolean)
                                      }
                                      width='104px'
                                      required
                                    />
                                  )
                                ) : ( */}
                                <FormSelect
                                  label='Vesting Frequency'
                                  name='intervalInMonths'
                                  viewName='intervalInMonths'
                                  onChange={(value: string) => {
                                    setFieldValue('intervalInMonths', value);
                                    handlePercentageTwo(value, values.lengthInMonths);
                                    handlePercentageThree(
                                      Number(value),
                                      values.lengthInMonths,
                                      values?.amountOneInPercentage,
                                      values?.vestsAfterInMonths
                                    );
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  options={VestingFrequencyOptions.filter((item) => {
                                    return (
                                      (Number(values.lengthInMonths) -
                                        Number(values.vestsAfterInMonths)) %
                                        Number(item.value) ===
                                      0
                                    );
                                  })}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  inputType={modalStatus}
                                  disabled={modalStatus === 'View' || values?.lengthInMonths === ''}
                                  required
                                />
                                {/* )} */}
                              </div>
                            </>
                          )}
                      </div>
                    )}

                    {Boolean(values.vestingTypeId) &&
                      !vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                        ?.isCliffVesting && (
                        <>
                          <FormDivider />
                          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                            <div className='col-span-1 lg:col-span-2'>
                              <FormSelect
                                label='Rounding Type'
                                name='roundingTypeId'
                                viewName='roundingTypeId'
                                onChange={(value: string) => {
                                  setFieldValue('roundingTypeId', value);
                                }}
                                errors={errors}
                                touched={touched}
                                errorValue={errors?.roundingTypeId}
                                touchedValue={touched?.roundingTypeId}
                                options={roundingTypeOptions}
                                values={values}
                                setFieldValue={setFieldValue}
                                inputType={modalStatus}
                                disabled={modalStatus === 'View'}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )}
                    {(vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                      ?.isCliffVesting ??
                      false) &&
                    Number(values.lengthInMonths) > 0 &&
                    !(errors.lengthInMonths as unknown as boolean) ? (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                          <ViewUploadedDoc
                            variant='primary'
                            size='lg'
                            children={
                              <>
                                <div className='vest-info'>
                                  <Heading variant='body' title='100%' className='info' />
                                  <Heading
                                    variant='body'
                                    title={`of shares will vest after ${Number(
                                      values.lengthInMonths
                                    )} month${Number(values.lengthInMonths) > 1 ? 's' : ''}`}
                                  />
                                </div>
                              </>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {(vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                      ?.isCliffVesting ??
                      false) &&
                    (values.lengthInMonths as boolean) &&
                    (errors.lengthInMonths as unknown as boolean) &&
                    (touched.lengthInMonths as boolean) ? (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                          <ViewUploadedDoc
                            variant='primary'
                            size='lg'
                            children={
                              <div className='vest-info'>
                                <Heading
                                  variant='body'
                                  title='Invalid Data. Please try again.'
                                  className='info'
                                />
                              </div>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                      ?.isStepVesting &&
                    Number(values.lengthInMonths) > 0 &&
                    Number(values.intervalInMonths) >= 0 &&
                    !(errors.intervalInMonths as unknown as boolean) ? (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                          <ViewUploadedDoc
                            variant='primary'
                            size='lg'
                            children={
                              <>
                                <div className='vest-info'>
                                  <Heading
                                    variant='body'
                                    title={`${percentage.toFixed(2)}%`}
                                    className='info'
                                  />
                                  <Heading
                                    variant='body'
                                    title={`vest ${
                                      vestingTypeOptions?.find(
                                        (item) => item.value === values.vestingTypeId
                                      )?.isHybridVesting ?? false
                                        ? 'after the first'
                                        : 'every'
                                    } ${String(values.intervalInMonths ?? '')} month${
                                      Number(values.intervalInMonths) > 1 ? 's' : ''
                                    }`}
                                  />
                                </div>
                              </>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                      ?.isHybridVesting &&
                    Number(values.lengthInMonths) > 0 &&
                    Number(values.vestsAfterInMonths) >= 0 &&
                    !(errors.vestsAfterInMonths as unknown as boolean) ? (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                          <ViewUploadedDoc
                            variant='primary'
                            size='lg'
                            children={
                              <>
                                <div className='vest-info'>
                                  <Heading
                                    variant='body'
                                    title={`${
                                      values?.amountOneInPercentage
                                        ? Number(values?.amountOneInPercentage).toFixed(2)
                                        : percentageOne.toFixed(2)
                                    }%`}
                                    className='info'
                                  />
                                  <Heading
                                    variant='body'
                                    title={`vest ${
                                      vestingTypeOptions?.find(
                                        (item) => item.value === values.vestingTypeId
                                      )?.isHybridVesting ?? false
                                        ? 'after the first'
                                        : 'every'
                                    } ${String(values.vestsAfterInMonths ?? '')} month${
                                      Number(values.vestsAfterInMonths) > 1 ? 's' : ''
                                    }`}
                                  />
                                </div>
                                {(values.intervalInMonths as boolean) && (
                                  <div className='vest-info'>
                                    <Heading
                                      variant='body'
                                      title={`${percentage.toFixed(2)}%`}
                                      className='info'
                                    />
                                    <Heading
                                      variant='body'
                                      title={`vest every ${String(
                                        values.intervalInMonths ?? ''
                                      )} month${
                                        Number(values.intervalInMonths) > 1 ? 's' : ''
                                      } thereafter`}
                                    />
                                  </div>
                                )}
                              </>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {!vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                      ?.isCliffVesting &&
                    !vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                      ?.isStepVesting &&
                    !vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                      ?.isHybridVesting &&
                    Number(values.lengthInMonths) > 0 &&
                    Number(values.amountOneInPercentage) > 0 ? (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                          <ViewUploadedDoc
                            variant='primary'
                            size='lg'
                            children={
                              <>
                                {/* <div className='vest-info'>
                                  <Heading
                                    variant='body'
                                    title={`${values?.amountOneInPercentage}%`}
                                    className='info'
                                  />
                                  <Heading
                                    variant='body'
                                    title={`Vest ${
                                      vestingTypeOptions?.find(
                                        (item) => item.value === values.vestingTypeId
                                      )?.isHybridVesting ?? false
                                        ? 'after the first'
                                        : 'every'
                                    } ${String(values.vestsAfterInMonths ?? '')} month${
                                      Number(values.vestsAfterInMonths) > 1 ? 's' : ''
                                    }`}
                                  />
                                </div> */}
                                {(values.intervalInMonths as boolean) && (
                                  <div className='vest-info'>
                                    <Heading
                                      variant='body'
                                      title={`${values?.amountOneInPercentage}%`}
                                      className='info'
                                    />
                                    <Heading
                                      variant='body'
                                      title={
                                        values?.periodStartDate !== 'Invalid date' &&
                                        values?.periodStartDate
                                          ? ` on ${moment(values?.periodStartDate).format(
                                              DATE_FORMAT
                                            )}`
                                          : 'vesting start date'
                                      }
                                      // className='info'
                                    />
                                  </div>
                                )}
                                {(values.intervalInMonths as boolean) && (
                                  <div className='vest-info'>
                                    <Heading
                                      variant='body'
                                      title={`Vest every ${String(
                                        values.intervalInMonths ?? ''
                                      )} month${
                                        Number(values.intervalInMonths) > 1 ? 's' : ''
                                      } thereafter`}
                                    />
                                  </div>
                                )}
                              </>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {!(
                      vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                        ?.isCliffVesting ?? false
                    ) &&
                    Number(values.lengthInMonths) > 0 &&
                    (Number(values.vestsAfterInMonths) as unknown as boolean) &&
                    (errors.vestsAfterInMonths as unknown as boolean) ? (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                          <ViewUploadedDoc
                            variant='primary'
                            size='lg'
                            children={
                              <div className='vest-info'>
                                <Heading
                                  variant='body'
                                  title='Invalid Data. Please try again.'
                                  className='info'
                                />
                              </div>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {vestModulus < 0 ? (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                          <ViewUploadedDoc
                            variant='primary'
                            size='lg'
                            children={
                              <div className='vest-info'>
                                <Heading
                                  variant='body'
                                  title='Invalid Data. Please try again.'
                                  className='info'
                                />
                              </div>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {(vestingTypeOptions?.find((item) => item.value === values.vestingTypeId)
                      ?.isStepVesting ??
                      false) &&
                    Number(values.lengthInMonths) > 0 &&
                    (Number(values.intervalInMonths) as unknown as boolean) &&
                    (errors.intervalInMonths as unknown as boolean) ? (
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 form-gap'>
                        <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
                          <ViewUploadedDoc
                            variant='primary'
                            size='lg'
                            children={
                              <div className='vest-info'>
                                <Heading
                                  variant='body'
                                  title='Invalid Data. Please try again.'
                                  className='info'
                                />
                              </div>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </ModalBodyComponent>
                  <ModalFooterComponent
                    modalStatus={modalStatus}
                    loading={isLoading}
                    onCloseModal={() => {
                      closeModal();
                    }}
                    valueChanged={_.isEqual(values, initialData.data)}
                    onEditModal={() => {
                      setModalStatus('Edit');
                    }}
                  />
                </Form>
              }
            />
          </VestingModalWrapper>
        );
      }}
    </Formik>
  );
};

export default NewVestingModal;
