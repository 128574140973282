/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import Heading from '../../../../components/typography';
import { ProfileContainer, ProfileContentWrapper } from '../profile.styled';
import Button from '../../../../components/button';
import { type Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto } from '../../../../services/wealthlane-customer-services';
import moment from 'moment';
import CompanyModal from './companyModal';
import AnimationWrapper from '../../../../components/animation/animationWrapper';
import { SkeletonCircle, SkeletonLine } from '../../../../components/skeleton';
import { taxFormatWithDash } from '../../../../utils/global';
import { useBoolean } from '../../../../hooks';

export interface ProfileComponentType {
  data?: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto;
  loading: boolean;
}

export const getProfileListData = (
  fieldData: string | string[] | null | undefined
): JSX.Element => (
  <>
    <Heading
      title={(fieldData as unknown as boolean) || 'Not Specified'}
      variant='body-s'
      className={(fieldData as unknown as boolean) ? '' : 'profile-text-gray'}
    />
  </>
);

const CompanyComponent = ({ data, loading }: ProfileComponentType): JSX.Element => {
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);

  const COMPANY_DETAILS = [
    {
      title: 'Company Name',
      icon: 'apartment',
      isContent: false,
      content: getProfileListData(data?.companyName),
    },
    {
      title: 'Tax ID',
      icon: 'pin',
      isContent: false,
      content: getProfileListData(
        data?.taxId != null ? taxFormatWithDash(String(data?.taxId)) : ''
      ),
    },
    {
      title: 'Ticker',
      icon: 'confirmation_number',
      isContent: false,
      content: getProfileListData(data?.ticker),
    },
    {
      title: 'Primary Stock Exchange',
      icon: 'monitoring',
      isContent: false,
      content: getProfileListData(data?.primaryStockExchange),
    },
    {
      title: 'Month/Year of Incorporation (Optional)',
      icon: 'event',
      isContent: false,
      content: getProfileListData(
        (data?.dateOfIncorporation as unknown as boolean)
          ? moment(data?.dateOfIncorporation).format('MMMM, YYYY')
          : ''
      ),
    },
    {
      title: 'Country of Incorporation (Optional)',
      icon: 'flag',
      isContent: false,
      content: getProfileListData(data?.countryOfIncorporation),
    },
    {
      title: 'State of Incorporation (Optional)',
      icon: 'map',
      isContent: false,
      content: getProfileListData(data?.stateOfIncorporation),
    },
    {
      title: 'Industry (Optional)',
      icon: 'factory',
      isContent: false,
      content: getProfileListData(data?.industrySector),
    },
    {
      title: 'CUSIP Number (Optional)',
      icon: 'pin',
      isContent: false,
      content: getProfileListData(data?.cusip),
    },
    {
      title: 'SIC Code (Optional)',
      icon: 'pin',
      isContent: false,
      content: getProfileListData(data?.sicCode),
    },
    {
      title: 'Domain Names',
      icon: 'dns',
      isContent: false,
      content: getProfileListData(data?.domainNames?.join(', ')),
    },
    {
      title: 'Description (Optional)',
      icon: 'description',
      isContent: false,
      content: getProfileListData(data?.businessDescription),
    },
    {
      title: 'Website',
      icon: 'language',
      isContent: false,
      content: getProfileListData(data?.websiteUrl),
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <ProfileContentWrapper>
      <div className='profile-header'>
        <Heading title='Company Information' variant='h5' />
        <Button
          as='Edit'
          variant='secondary-dark'
          title='Edit'
          icon='edit'
          onClick={() => {
            openModal();
          }}
        />
      </div>
      <ProfileContainer>
        {loading && (
          <>
            {COMPANY_DETAILS.map((item) => (
              <div className='list list-loading'>
                <div className='list-icon'>
                  <SkeletonCircle size='table' shape='circle' />
                </div>
                <div className='list-content grid grid-cols-12'>
                  <SkeletonLine size='table' containerClassName='col-span-3' />
                  <SkeletonLine size='table' containerClassName='col-span-9' />
                </div>
              </div>
            ))}
          </>
        )}
        {!loading &&
          COMPANY_DETAILS.map((item) => (
            <div className='list grid sm:grid-cols-4 lg:grid-cols-12'>
              <div className='list-icon sm:col-span-2 lg:col-span-4'>
                <span className='material-symbols-outlined'>{item.icon}</span>
                <Heading title={item.title} variant='body-s' />
              </div>
              <div className='list-content sm:col-span-2 lg:col-span-8'>
                {item.isContent ? (
                  <>{item.content}</>
                ) : (
                  <Heading title={item.content} variant='body-s' />
                )}
              </div>
            </div>
          ))}
      </ProfileContainer>

      <AnimationWrapper>
        {isModalOpen && (
          <CompanyModal
            initialData={data}
            loading={loading}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
        )}
      </AnimationWrapper>
    </ProfileContentWrapper>
  );
};

export default CompanyComponent;
