import { useQuery } from 'react-query';
import { OptionExerciseService, ReleaseService } from '../../services/wealthlane-award-services';

const useGetOptionExerciseTransactionDetailById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
) => {
  const optionExerciseTransactionDetailById = useQuery(
    ['getOptionExerciseTransactionDetailById', id],
    async () => {
      if (id) {
        return await handleRequest(
          OptionExerciseService.getApiAwardApiAppOptionExerciseTransactionDetailByExerciseId(id)
        );
      }
    }
  );
  return optionExerciseTransactionDetailById;
};

export default useGetOptionExerciseTransactionDetailById;
