/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import Button from '../../../components/button';
import ConfirmationModal from '../../../components/modal/confirmationModal';
import Search from '../../../components/search/search';
import Table from '../../../components/table';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { JobTitleHeader } from '../../../constants/table-headers/jobTitleHeader';
import {
  useBoolean,
  useGetCustomerTitleListWithPagination,
  useWealthlaneCustomerApi,
} from '../../../hooks';
import {
  CustomerTitleService,
  type Wealthlane_CustomerService_Dtos_CustomerTitle_CustomerTitleDto,
} from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { PageHeader } from '../company-profile/profile.styled';
import { SettingRoleButton } from '../system-roles/systemRoleStyled';
import JobTitleModal from './jobTitleModal';

const jobTitleState = {
  data: InitialValuesForm.jobTitleForm,
  id: '',
  modalStatus: 'Add' as ModalStatusType,
  loading: false,
  searchQuery: '',
};

const JobTitleComponent = (): JSX.Element => {
  const [jobTitleInfo, setJobTitleInfo] = useState(jobTitleState);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmModalOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);

  // GET LIST
  const queryClient = useQueryClient();
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data, status } = useGetCustomerTitleListWithPagination(handleRequest, tableInfo);

  // TABLE ACTION
  const actions = [
    {
      action: 'View',
      onClick: (id: string) => {
        getJobTitleData(id, 'View');
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string) => {
        getJobTitleData(id, 'Edit');
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      loading: jobTitleInfo.loading,
      onClick: (id: string) => {
        setJobTitleInfo({
          ...jobTitleInfo,
          id,
        });
        openConfirmModal();
      },
    },
  ];

  const getJobTitleData = (id: string, type: ModalStatusType): void => {
    handleRequest(CustomerTitleService.getApiCustomerApiAppCustomerTitleCustomerTitle(id))
      .then((data?: Wealthlane_CustomerService_Dtos_CustomerTitle_CustomerTitleDto) => {
        if (data != null) {
          setJobTitleInfo({
            ...jobTitleInfo,
            modalStatus: type,
            id,
            data: {
              name: data.name ?? '',
              description: data.description ?? '',
            },
          });
          openModal();
        }
      })
      .catch(() => {});
  };

  const deleteJobTitleData = (): void => {
    setJobTitleInfo({ ...jobTitleInfo, loading: true });
    handleRequest(
      CustomerTitleService.deleteApiCustomerApiAppCustomerTitleCustomerTitle(jobTitleInfo.id)
    )
      .then((data?) => {
        if (data != null) {
          setJobTitleInfo({ ...jobTitleInfo, loading: false });
          toast.success(<ToastComponent label='Success' message='Job Title Deleted.' />);
          void queryClient.invalidateQueries('getCustomerTitleListWithPagination');
        }
      })
      .catch(() => {})
      .finally(() => {
        setJobTitleInfo({ ...jobTitleInfo, loading: false });
        closeConfirmModal();
      });
  };

  return (
    <>
      <PageHeader>
        <Heading variant='h3' title='Job Titles' />
        <Heading
          variant='body-s'
          title={`Current list of Wealthlane system access department for`}
        />
      </PageHeader>
      <div className='grid sm:grid-cols-12 justify-between gap-4 sm:gap-0'>
        <div className='lg:col-span-3 col-span-6'>
          <Search
            placeholder='Search'
            searchValue={jobTitleInfo.searchQuery}
            onSearch={(value: string) => {
              setJobTitleInfo({
                ...jobTitleInfo,
                searchQuery: value,
              });
            }}
            setTableInfo={setTableInfo}
            tableInfo={tableInfo}
          />
        </div>
        <SettingRoleButton className='lg:col-start-10 lg:col-span-3 col-span-6'>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add Job Title'}
            icon={'add_circle_outline'}
            onClick={() => {
              setJobTitleInfo({
                ...jobTitleInfo,
                data: InitialValuesForm.jobTitleForm,
                modalStatus: 'Add',
                id: '',
              });
              openModal();
            }}
          />
        </SettingRoleButton>
        <AnimationWrapper>
          {isModalOpen && (
            <JobTitleModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              initialData={jobTitleInfo}
              handleRequest={handleRequest}
              setModalStatus={(value) => {
                setJobTitleInfo({
                  ...jobTitleInfo,
                  modalStatus: value,
                });
              }}
              loading={jobTitleInfo.loading}
            />
          )}

          {isConfirmModalOpen && (
            <ConfirmationModal
              isShow={isConfirmModalOpen}
              handleClose={() => {
                closeConfirmModal();
              }}
              title='job title'
              handleSubmit={() => {
                deleteJobTitleData();
              }}
              loading={jobTitleInfo.loading}
            />
          )}
        </AnimationWrapper>
      </div>

      <Table
        columns={JobTitleHeader(actions)}
        data={data?.items ?? []}
        loading={status}
        showPagination
        totalCounts={data?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </>
  );
};

export default JobTitleComponent;
