/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_VestingType_VestingTypeDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_VestingType_VestingTypeDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_VestingType_CreateUpdateVestingTypeDto } from '../models/Wealthlane_AwardService_Dtos_VestingType_CreateUpdateVestingTypeDto';
import type { Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto } from '../models/Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VestingTypeService {

    /**
     * @returns Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppVestingTypeVestingTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/vesting-type/vesting-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppVestingType(
requestBody?: Wealthlane_AwardService_Dtos_VestingType_CreateUpdateVestingTypeDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/vesting-type',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_VestingType_VestingTypeDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppVestingType(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_VestingType_VestingTypeDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/vesting-type',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppVestingType(
id: string,
requestBody?: Wealthlane_AwardService_Dtos_VestingType_CreateUpdateVestingTypeDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/vesting-type/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppVestingType(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/vesting-type/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppVestingType1(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_VestingType_VestingTypeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/vesting-type/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
