import { Field } from 'formik';
import React from 'react';
import { Label } from '../input-text/input.styled';
import { ToggleWrapper } from './toggle.styled';

export interface ToggleProps {
  label?: string;
  name?: string;
  className?: string;
  errors?: any;
  touched?: any;
  labelClassName?: string;
  required?: boolean;
  value?: any;
  id?: string;
  arrValue?: any;
  disabled?: boolean;
  checked?: boolean;

  // why do we need onChange here
  onChange?: (e: React.ChangeEvent<any>) => void;
}

const CustomToggle = React.memo((props: ToggleProps) => {
  const {
    label,
    name,
    className,
    errors,
    touched,
    labelClassName,
    required,
    value,
    id,
    disabled,
    arrValue,
    checked,
    ...rest
  } = props;

  return (
    <ToggleWrapper label={label} className={className}>
      <Field
        name={name}
        id={id}
        label={label}
        // className={ errors[name] && touched[name] ? 'is-invalid' : ''}
        type='checkbox'
        disabled={disabled}
        checked={checked ?? arrValue}
        {...rest}
      />
      <label htmlFor={id} />
      {label != null && label !== '' && (
        <Label htmlFor={id} className={labelClassName}>
          {label} {(required ?? false) && <span className='required'>*</span>}
        </Label>
      )}
    </ToggleWrapper>
  );
});

export default CustomToggle;
