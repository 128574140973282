import { type TableParamsType } from '../../types/common';
import { type UseQueryResult, useQuery } from 'react-query';

import {
  ReleaseService,
  // eslint-disable-next-line camelcase
  Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';

const useGetReleaseIncludedList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  filterData: TableParamsType,
  selectedId: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    grantName: grantId,
    awardTypeId: awardTypeId,
    createdFromDate: fromDate,
    createdToDate: toDate,
    settlementTypeIds,
  } = filterData;
  const { filter: searchKeyWord, sorting, skipCount, maxResultCount } = tableInfo;
  const releaseIncludedList = useQuery(
    [
      'getReleaseIncludedList',
      searchKeyWord,
      sorting,
      skipCount,
      maxResultCount,
      grantId,
      fromDate,
      toDate,
      selectedId,
      awardTypeId,
      settlementTypeIds,
    ],
    async () => {
      if (selectedId) {
        return await handleRequest(
          ReleaseService.getApiAwardApiAppReleaseIncludedReleaseList(
            selectedId,
            sorting,
            skipCount,
            maxResultCount,
            searchKeyWord,
            grantId,
            awardTypeId,
            fromDate,
            toDate,
            settlementTypeIds
          )
        );
      }
    },
    {
      select: (data) => {
        if (data != null) {
          return data;
        }
      },
    }
  );

  return releaseIncludedList;
};

export default useGetReleaseIncludedList;
