import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Tag from '../../components/lozenge/tags';
import { PopoverModelWrapper } from '../../components/modal/modal.styled';
import Heading from '../../components/typography';
import { ActionButton } from '../../components/vertical-dot/popper';
import { DATE_FORMAT } from '../common';
import { Display } from '../style/common.styled';
import { TableBodyCellColumn } from '../style/table.styled';

export const PerformanceRuleHeader = (
  actions: any
  //   handleShow: (data: any, event: any) => void,
  //   hasMousePosition: boolean,
  //   resetMousePosition: () => void
): any[] => {
  const [title, setTitle] = useState('');
  const getActions = (rowData: Record<string, any>): any => {
    let newActions = [];
    if (rowData.approvalStatus === 'Pending' && rowData.availableStatus === 'Available') {
      newActions = actions.filter(
        (item: any) =>
          !['View Comments', 'Available', 'Apply Earn Multiplier'].includes(item.action)
      );
    } else if (rowData.approvalStatus === 'Pending' && rowData.availableStatus === 'Unavailable') {
      newActions = actions.filter(
        (item: any) =>
          !['Unavailable', 'View Comments', 'Apply Earn Multiplier'].includes(item.action)
      );
    } else if (rowData.approvalStatus === 'Approved' && rowData.availableStatus === 'Available') {
      newActions = actions.filter((item: any) =>
        ['Unavailable', 'View', 'Edit', 'Apply Earn Multiplier'].includes(item.action)
      );
    } else if (rowData.approvalStatus === 'Approved' && rowData.availableStatus === 'Unavailable') {
      newActions = actions.filter((item: any) =>
        ['Available', 'View', 'Edit', 'Apply Earn Multiplier'].includes(item.action)
      );
    } else if (rowData.approvalStatus === 'Rejected' && rowData.availableStatus === 'Available') {
      newActions = actions.filter((item: any) =>
        ['Unavailable', 'View', 'View Comments'].includes(item.action)
      );
    } else if (rowData.approvalStatus === 'Rejected' && rowData.availableStatus === 'Unavailable') {
      newActions = actions.filter((item: any) =>
        ['Available', 'View', 'View Comments'].includes(item.action)
      );
    } else if (rowData.approvalStatus === 'Pending') {
      newActions = actions.filter(
        (item: any) =>
          !['Available', 'Unavailable', 'View Comments', 'Apply Earn Multiplier'].includes(
            item.action
          )
      );
    } else if (rowData.approvalStatus === 'Approved') {
      newActions = actions.filter(
        (item: any) => ['View', 'Edit', 'Apply Earn Multiplier'].includes(item.action)
        // (item: any) => item.action === 'View' || item.action === 'View Grant'
      );
    } else if (rowData.approvalStatus === 'Rejected') {
      newActions = actions.filter((item: any) => ['View Comments', 'View'].includes(item.action));
    } else if (rowData.approvalStatus === 'Pending' && rowData.availableStatus === 'Available') {
      newActions = actions.filter(
        (item: any) => !['View Comments', 'Enable'].includes(item.action)
      );
    } else {
      newActions = actions;
    }
    return newActions;
  };

  return [
    {
      Header: 'Rule Name',
      accessor: 'ruleName',
      width: 100,
      Cell: ({ row }: any) => (
        <TableBodyCellColumn>
          <div className='cellTitle'>
            {row.original.ruleName != null && row.original.ruleName !== ''
              ? row.original.ruleName
              : '-'}
          </div>
        </TableBodyCellColumn>
      ),
    },
    {
      Header: 'Created Date',
      accessor: 'createDate',
      width: 100,
      Cell: ({ row }: any) => {
        return (
          <>
            {!isEmpty(row.original.createDate)
              ? moment(row.original.createDate).format(DATE_FORMAT)
              : '-'}
          </>
        );
      },
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      width: 100,
      Cell: ({ row }: any) => (
        <TableBodyCellColumn>
          <div className='cellTitle'>
            {row.original.createdBy != null && row.original.createdBy !== ''
              ? row.original.createdBy
              : '-'}
          </div>
        </TableBodyCellColumn>
      ),
    },
    {
      Header: 'Evaluation Start Date',
      accessor: 'EvaluationPeriodFrom',
      width: 200,
      Cell: ({ row }: any) => (
        <TableBodyCellColumn>
          <div className='cellTitle'>
            {!isEmpty(row.original.evaluationPeriodFrom)
              ? `${moment(row.original.evaluationPeriodFrom).format(DATE_FORMAT)}`
              : '-'}
          </div>
        </TableBodyCellColumn>
      ),
    },
    {
      Header: 'Evaluation End Date',
      accessor: 'evaluationPeriodTo',
      width: 200,
      Cell: ({ row }: any) => (
        <TableBodyCellColumn>
          <div className='cellTitle'>
            {!isEmpty(row.original.evaluationPeriodTo)
              ? `${moment(row.original.evaluationPeriodTo).format(DATE_FORMAT)}`
              : '-'}
          </div>
        </TableBodyCellColumn>
      ),
    },
    {
      Header: 'Minimum',
      accessor: 'minimumPercentage',
      width: 100,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>
              {row.original.minimumPercentage != null && row.original.minimumPercentage !== ''
                ? row.original.minimumPercentage + '%'
                : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Maximum',
      accessor: 'maximumPercentage',
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>
              {row.original.maximumPercentage != null && row.original.maximumPercentage !== ''
                ? row.original.maximumPercentage + '%'
                : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Approval Status',
      accessor: 'approvalStatus',
      center: true,
      width: 100,
      Cell: ({ row }: any) => (
        <Display>
          <Tag
            variant={
              row.original.approvalStatus === 'Approved'
                ? 'success'
                : row.original.approvalStatus === 'Rejected'
                ? 'error'
                : 'info'
            }
            title={(row.original.approvalStatus as boolean) ? row.original.approvalStatus : '-'}
          />
        </Display>
      ),
    },
    {
      Header: 'Available Status',
      accessor: 'availableStatus',
      center: true,
      width: 100,
      Cell: ({ row }: any) => (
        <Display>
          <Tag
            variant={
              row.original.availableStatus === 'Available'
                ? 'success'
                : row.original.availableStatus === 'Unavailable'
                ? 'error'
                : 'info'
            }
            title={(row.original.availableStatus as boolean) ? row.original.availableStatus : '-'}
          />
        </Display>
      ),
    },
    {
      Header: 'Earned Status',
      accessor: 'earnedStatus',
      center: true,
      width: 100,
      Cell: ({ row }: any) => (
        <Display>
          <Tag
            variant={row.original.earnedStatus === 'Earned' ? 'success' : 'info'}
            title={(row.original.earnedStatus as boolean) ? row.original.earnedStatus : '-'}
          />
        </Display>
      ),
    },
    // {
    //   Header: 'Award Type',
    //   accessor: 'planTypeCategory.Name',
    //   center: true,
    //   width: 100,
    //   Cell: ({ row }: any) => (
    //     <Display>
    //       <Tag variant='success' title={row.original.planType} />
    //     </Display>
    //   ),
    // },

    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={getActions(row?.original)}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
