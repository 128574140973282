import { type UseQueryResult, useQuery } from 'react-query';
import { TaxPeriodService } from '../../services/wealthlane-tax-services';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/common';

const useGetTaxPeriodAll = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<any> => {
  const taxPeriodOptions = useQuery(
    ['getTaxPeriodTableList'],
    async () => {
      return await handleRequest(TaxPeriodService.getApiTaxApiAppTaxPeriodTaxPeriod());
    },
    {
      select: (data) => {
        const taxPeriod = data?.map((item: any) => {
          return {
            label:
              `${String(item?.name ?? '')}${' '} (${moment(item?.effectiveFrom).format(
                DATE_FORMAT
              )}-${moment(item?.effectiveTill).format(DATE_FORMAT)})` ?? '',
            value: item.id ?? '',
          };
        });

        return taxPeriod;
      },
    }
  );

  return taxPeriodOptions;
};

export default useGetTaxPeriodAll;
