/* eslint-disable camelcase */
import { Form, Formik } from 'formik';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import { type Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankForViewDto } from '../../../services/wealthlane-finance-integration-services';
import { FormDivider } from '../system-roles/roles.styled';
import _, { values } from 'lodash';

interface BankAccountType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalLoading: boolean;
  initialData: Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankForViewDto;
}

const BankAccountModal = ({
  isModalOpen,
  closeModal,
  initialData,
  modalLoading,
}: BankAccountType): JSX.Element => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialData}
      onSubmit={() => {
        console.log('here');
      }}>
      <ModalComponent
        show={isModalOpen}
        closeModal={closeModal}
        loading={modalLoading}
        title={'View Bank Account'}
        valueEdited={_.isEqual(values, initialData)}
        size='lg'
        children={
          <Form>
            <ModalBodyComponent>
              <div className='mb-4 grid sm:grid-cols-2 lg:grid-cols-3 gap-x-8'>
                <div className='sm:col-span-2 lg:col-span-3'>
                  <InputText
                    type='text'
                    inputType='View'
                    label='Verification Type'
                    name='verificationType'
                    onChange={null}
                    className='mb-0'
                    disabled
                  />
                </div>
                <div className='sm:col-span-2 lg:col-span-3'>
                  <FormDivider />
                </div>
                <InputText
                  type='text'
                  inputType='View'
                  label='Bank Name'
                  name='institutionName'
                  onChange={null}
                  disabled
                />
                <InputText
                  type='text'
                  inputType='View'
                  label='Domiciled in'
                  name='domiciledIn'
                  onChange={null}
                  disabled
                />
                <InputText
                  type='text'
                  inputType='View'
                  label='Account Name'
                  name='accountName'
                  onChange={null}
                  disabled
                />
                <InputText
                  type='text'
                  inputType='View'
                  label='Account Number'
                  name='accountNumber'
                  onChange={null}
                  disabled
                />
                <InputText
                  type='text'
                  inputType='View'
                  label='Routing Number'
                  name='routingNumber'
                  onChange={null}
                  disabled
                />
                <InputText
                  type='text'
                  inputType='View'
                  label='Purpose of Bank'
                  name='bankPurpose'
                  onChange={null}
                  disabled
                />
                <InputText
                  type='text'
                  inputType='View'
                  label='Status'
                  name='status'
                  onChange={null}
                  disabled
                />
              </div>
            </ModalBodyComponent>
            <ModalFooterComponent
              modalStatus={'View'}
              isEditShow={false}
              onCloseModal={() => {
                closeModal();
              }}
              // onEditModal={() => setModalStatus('Edit')}
            />
          </Form>
        }
      />
    </Formik>
  );
};

export default BankAccountModal;
