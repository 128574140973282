/* eslint-disable camelcase */
import { FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import FormSelect from '../../../../components/dropdown/formSelect';
import InputText from '../../../../components/input-text/inputText';
import ModalComponent from '../../../../components/modal/modal';
import ModalFooterComponent from '../../../../components/modal/modalFooterComponent';
import {
  useGetCountryOptions,
  useGetIndustrySectorOptions,
  useGetPrimaryStockExchange,
  useGetStateOptions,
  usePostCompanyProfile,
  useWealthlaneCustomerApi,
} from '../../../../hooks';
import { COMPANY_PROFILE_FORM_VALIDATION } from '../../../../utils/validations/validations';
import { type Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto } from '../../../../services/wealthlane-customer-services';
import CustomTextArea from '../../../../components/text-area/textArea';
import CustomDatePicker from '../../../../components/date-picker/customDatePicker';
import ModalBodyComponent from '../../../../components/modal/modalBodyComponent';
import _, { isEmpty } from 'lodash';

interface CompanyModalType {
  initialData?: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto;
  loading: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
}

const CompanyModal = ({
  initialData,
  loading,
  isModalOpen,
  closeModal,
}: CompanyModalType): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const [countryId, setCountryId] = useState<string>('');

  const { data: countryOptions } = useGetCountryOptions(handleRequest);
  const { data: stateOptions } = useGetStateOptions(handleRequest, countryId);
  const { data: primaryStockExchangeOptions } = useGetPrimaryStockExchange(handleRequest);
  const { data: industrySectorOptions } = useGetIndustrySectorOptions(handleRequest);

  const { mutate, isLoading } = usePostCompanyProfile(handleRequest, closeModal);
  const handleSubmit = (
    values: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailDto
  ): void => {
    const payload = {
      id: !isEmpty(values.companyId) ? values.companyId : null,
      name: values.companyName,
      ticker: values.ticker,
      industrySectorId: !isEmpty(values.industrySectorId) ? values.industrySectorId : null,
      taxId: values.taxId,
      primaryStockExchangeId: !isEmpty(values.primaryStockExchangeId)
        ? values.primaryStockExchangeId
        : null,
      dateOfIncorporation: values.dateOfIncorporation,
      stateOfIncorporationId: !isEmpty(values.stateOfIncorporationId)
        ? values.stateOfIncorporationId
        : null,
      countryOfIncorporationId: !isEmpty(values.countryOfIncorporationId)
        ? values.countryOfIncorporationId
        : null,
      cusip: values.cusip,
      sicCode: values.sicCode,
      cityText: values.city,
      stateText: values.state,
      stateOfIncorporationText: values.stateOfIncorporation,
      businessDescription: values.businessDescription,
      websiteUrl: values.websiteUrl,
      // countryId: !isEmpty(values.countryId) ?values.countryId : null,
      // addressLine1: values.addressLine1,
      // addressLine2: values.addressLine2,
      // stateId: !isEmpty(values.stateId) ?values.stateId : null,
      // zipCode: `${values.zipCode}`,
      headQuarterCountryId: !isEmpty(values.countryId) ? values.countryId : null,
      headQuarterAddressLine1: values.addressLine1,
      headQuarterAddressLine2: values.addressLine2,
      headQuarterStateId: !isEmpty(values.stateId) ? values.stateId : null,
      headQuarterCityText: !isEmpty(values.city) ? values.city : null,
      headQuarterStateText: !isEmpty(values.state) ? values.state : null,
      headQuarterZipCode: `${values.zipCode ?? ''}`,
      domainNames: values.domainNames,
      isEnabled: true,
      companyAddressList:
        values?.companyAddressList != null &&
        values?.companyAddressList?.length > 0 &&
        values?.companyAddressList[0].countryId !== null
          ? values?.companyAddressList?.map((item) => {
              return {
                countryId: item.countryId,
                countryText: item.countryText,
                addressLine1: item.addressLine1,
                addressLine2: item.addressLine2,
                stateId: item.stateId,
                cityText: item.cityText,
                stateText: item.stateText,
                zipCode: item.zipCode,
                taxId: item.taxId,
                id: values.companyId ?? null,
              };
            })
          : [],
    };
    mutate(payload);
  };

  return (
    <>
      {initialData != null && (
        <Formik
          enableReinitialize
          initialValues={initialData}
          validationSchema={COMPANY_PROFILE_FORM_VALIDATION}
          onSubmit={(values) => {
            handleSubmit(values);
          }}>
          {({ touched, handleChange, setFieldValue, errors, values }) => {
            return (
              <ModalComponent
                show={isModalOpen}
                closeModal={closeModal}
                valueEdited={_.isEqual(values, initialData)}
                size='lg'
                loading={loading}
                title='Company Information'
                children={
                  <Form>
                    <ModalBodyComponent>
                      <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-8 items-baseline'>
                        <InputText
                          type='text'
                          label='Company Name'
                          name='companyName'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled
                          required
                        />
                        <InputText
                          type='text'
                          label='Tax ID'
                          name='taxId'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled
                          required
                        />
                        <InputText
                          type='text'
                          label='Ticker'
                          name='ticker'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled
                          required
                        />
                        <FormSelect
                          type='text'
                          label='Primary Stock Exchange'
                          name='primaryStockExchangeId'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={primaryStockExchangeOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                        <CustomDatePicker
                          label='Month/Year of Incorporation'
                          name='dateOfIncorporation'
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          values={values}
                          max={new Date()}
                          defaultValue={new Date()}
                          dateFormat='MM/yyyy'
                          placeholder='MM/YYYY'
                        />
                        {/* <CustomDatePickerWithYear
                          label='Date of Incorporation Year'
                          name='dateOfIncorporation'
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          values={values}
                          max={new Date()}
                          defaultValue={new Date()}
                        /> */}
                        <FormSelect
                          type='text'
                          label='Country of Incorporation (Optional)'
                          name='countryOfIncorporationId'
                          onChange={(value: string) => {
                            setFieldValue('countryOfIncorporationId', value);
                            setFieldValue('stateOfIncorporation', '');
                            setFieldValue('stateOfIncorporationId', '');
                            setCountryId(value);
                          }}
                          errors={errors}
                          touched={touched}
                          options={countryOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                        {countryOptions?.find(
                          (item) => item.value === values.countryOfIncorporationId
                        )?.isOtherCountry ?? false ? (
                          <>
                            <InputText
                              type='text'
                              label='State of Incorporation'
                              name='stateOfIncorporation'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </>
                        ) : (
                          <FormSelect
                            type='text'
                            label='State of Incorporation (Optional)'
                            name='stateOfIncorporationId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={stateOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        )}
                        <FormSelect
                          type='text'
                          label='Industry (Optional)'
                          name='industrySectorId'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={industrySectorOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                        <InputText
                          type='text'
                          label='Website'
                          name='websiteUrl'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                        />
                        <InputText
                          type='string'
                          label='CUSIP Number'
                          name='cusip'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                        <InputText
                          type='string'
                          label='SIC Code'
                          name='sicCode'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                        <div />
                        <FieldArray
                          name='domainNames'
                          render={(arrayHelpers) => {
                            return (
                              <>
                                {values?.domainNames != null &&
                                  values.domainNames.length > 0 &&
                                  values.domainNames.map((friend: any, index: number) => (
                                    <div className='col-span-1'>
                                      <div className='flex flex-row'>
                                        <div className='flex flex-row w-full'>
                                          <InputText
                                            type='text'
                                            label={`Domain name ${index + 1}`}
                                            name={`domainNames.${index}`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            className='w-full'
                                            disabled
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </>
                            );
                          }}
                        />
                        <div className='sm:col-span-2'>
                          <CustomTextArea
                            label='Description'
                            rows='5'
                            name='businessDescription'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </ModalBodyComponent>
                    <ModalFooterComponent
                      modalStatus='Edit'
                      onCloseModal={() => {
                        closeModal();
                      }}
                      valueChanged={_.isEqual(values, initialData)}
                      loading={isLoading}
                    />
                  </Form>
                }
              />
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default CompanyModal;
