/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  TaxItemService,
  type Wealthlane_TaxService_Dtos_Response_ResponseDto,
  type Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemRequestDto,
} from '../../services/wealthlane-tax-services';

const usePostTaxSetup = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  modalStatus: string
): UseMutationResult<
  Wealthlane_TaxService_Dtos_Response_ResponseDto | undefined,
  unknown,
  Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemRequestDto,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: Wealthlane_TaxService_Dtos_TaxItemDto_CreateTaxItemRequestDto) => {
      if (modalStatus === 'Edit') {
        return await handleRequest(TaxItemService.putApiTaxApiAppTaxItemTaxItem(payload));
      } else {
        return await handleRequest(TaxItemService.postApiTaxApiAppTaxItemTaxItem(payload));
      }
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          closeModal();
          void queryClient.invalidateQueries('getTaxSetupList');
          toast.success(<ToastComponent label='Success' message={`Tax ${modalStatus}ed.`} />);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostTaxSetup;
