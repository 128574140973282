import { type UseQueryResult, useQuery } from 'react-query';
import { VestingTypeService } from '../../services/wealthlane-award-services';

const useGetVestingTypeOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
      isCliffVesting: boolean | undefined;
      isStepVesting: boolean | undefined;
      isHybridVesting: boolean | undefined;
    }>
  | undefined,
  unknown
> => {
  const vestingTypeOptions = useQuery(
    ['getVestingTypeOptions'],
    async () => {
      return await handleRequest(VestingTypeService.getApiAwardApiAppVestingTypeVestingTypes());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
            isCliffVesting: item.isCliffVesting,
            isStepVesting: item.isStepVesting,
            isHybridVesting: item.isHybridVesting,
          };
        });
      },
    }
  );

  return vestingTypeOptions;
};

export default useGetVestingTypeOptions;
