import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { getViewHeight, rem } from '../../utils/style';
import { isEmpty } from 'lodash';

interface ModalType {
  size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl';
  showNotify: boolean;
  isNotification?: boolean;
  loading?: boolean;
  subTitle?: string;
}

interface ModalContainerType {
  showNotify: boolean;
}

interface ModelBodyType {
  showNotify: boolean;
  showFooter: boolean;
  isNotification: boolean;
  subTitle?: boolean;
}

export const ModelContainer = styled.div<ModalContainerType>`
  .backdrop {
    z-index: 999;
    position: fixed;
    top: ${(props) => (props.showNotify ? '102px' : '67px')};
    left: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: ${COLORS.ModalBackdrop};
    height: 100%;

    &.active {
      z-index: 1200;
    }
  }
`;

export const PopoverModelWrapper = styled.div`
  position: relative;
  display: inline-block;

  .title {
    cursor: pointer;
    display: inline-block;
    position: relative;

    &:hover,
    &.active {
      font-weight: 500;
      color: ${COLORS.Iris};
      text-decoration: underline;
    }
  }
`;

export const ModelBody = styled.div<ModelBodyType>`
  padding: 2.4rem 3.2rem;
  height: ${(props) =>
    props.isNotification && props.showNotify
      ? `${getViewHeight('204px')}`
      : props.isNotification && !props.showNotify
      ? `${getViewHeight('170px')}`
      : props.showNotify && props.showFooter && props?.subTitle
      ? `${getViewHeight('315px')}`
      : props.showNotify && props.showFooter
      ? `${getViewHeight('287px')}`
      : props.showNotify && !props.showFooter
      ? `${getViewHeight('204px')}`
      : !props.showNotify && props.showFooter && props?.subTitle
      ? `${getViewHeight('280px')}`
      : !props.showNotify && props.showFooter
      ? `${getViewHeight('253px')}`
      : `${getViewHeight('253px')}`};
  overflow-y: scroll;

  .borderTop {
    margin-top: 24px;
    padding-top: ${rem(24)};
    border-top: ${rem(1)} solid ${COLORS.CulturedWhite};
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .form-title-small {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${COLORS.Gray60};
    padding-bottom: 5px;
  }

  .table-wrapper {
    & > div:last-child {
      border-bottom: none;
    }
  }
  .roleTitle {
    margin-top: ${rem(32)};
  }

  .roleSubTitle {
    color: ${COLORS.Silver};
    justify-content: center;
    font-weight: 500;
  }

  .participantBlock {
    margin-bottom: 35px;
  }
`;

export const PopoverModelContainer = styled.div`
  background-color: ${COLORS.White};
  border-radius: 6px;
  box-shadow: ${rem(0)} ${rem(2)} ${rem(20)} ${COLORS.SoftShadow};
  padding: ${rem(18)};
  z-index: 999;

  max-height: ${rem(400)};
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ModelStyle = styled.div<ModalType>`
  position: relative;
  background: ${COLORS.White};
  height: auto;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;

  border-radius: ${rem(10)};
  margin: ${rem(16)};

  .icon-color {
    color: ${COLORS.Silver};
  }

  @media (min-width: 768px) {
    margin: ${rem(16)} ${rem(16)} ${rem(16)} auto;
  }

  ${(props) => {
    if (props.size === 'sm') {
      return css`
        @media (min-width: 768px) {
          width: 400px;
        }
      `;
    }
    if (props.size === 'md') {
      return css`
        @media (min-width: 768px) {
          width: 500px;
        }
      `;
    }
    if (props.size === 'lg') {
      return css`
        @media (min-width: 768px) {
          width: 700px;
        }
      `;
    }

    if (props.size === 'xl') {
      return css`
        @media (min-width: 768px) {
          width: 700px;
        }

        @media (min-width: 1024px) {
          width: 800px;
        }
      `;
    }
    if (props.size === 'xxl') {
      return css`
        @media (min-width: 768px) {
          width: 700px;
        }

        @media (min-width: 1024px) {
          width: 900px;
        }
      `;
    }
    if (props.size === '3xl') {
      return css`
        @media (min-width: 768px) {
          width: 700px;
        }

        @media (min-width: 1024px) {
          width: 1114px;
        }
      `;
    }
  }};

  .cross-icon {
    color: ${COLORS.Silver};
  }

  .modal-heading {
    display: flex;
    justify-content: space-between;
    border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};
    padding: ${rem(24)} ${rem(32)} ${rem(21)} ${rem(32)};
    align-items: flex-start;
    .header-text {
      flex-direction: column;
    }
    h4 {
      font-weight: 500;
      margin-bottom: ${rem(8)};
    }
  }

  .modal-body {
    position: relative;

    .modal-loader {
      min-height: ${(props) =>
        props.showNotify && !props?.loading && props?.subTitle !== undefined
          ? `${getViewHeight('315px')}`
          : props.showNotify && props?.loading && props?.subTitle === undefined
          ? `${getViewHeight('204px')}`
          : !props.showNotify && props?.loading && !props?.subTitle
          ? `${getViewHeight('170px')}`
          : !props.showNotify && !props?.loading && !props?.subTitle
          ? `${getViewHeight('253px')}`
          : `${getViewHeight('287px')}`};
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.SpanishGray};
      border-radius: 7px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .modal-close {
    display: flex;
    align-items: center;
    column-gap: ${rem(2)};
    cursor: pointer;
    span {
      transition: all 0.5s 0s ease;
    }
    &:hover {
      span {
        color: ${COLORS.Red};
      }
    }
  }
`;

export const ModelContentHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(24)};
  border-bottom: ${rem(1)} solid ${COLORS.CulturedWhite};
  padding-bottom: ${rem(21)};
  position: relative;
`;
export const ModelHeadingClose = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${rem(2)};
  cursor: pointer;
  span {
    color: ${COLORS.Silver};
  }
`;
export const ModelFooter = styled.div`
  box-sizing: border-box;
  width: 100%;
  right: 0;
  bottom: 0;
  border-top: ${rem(1)} solid ${COLORS.CulturedWhite};
  background-color: ${COLORS.White};
  padding: ${rem(20)} 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

  .footer-button {
    display: flex;
    justify-content: space-between;
    padding: 0px 32px;
    align-items: center;
  }
`;

// Add Admin User Css
export const ModelContent = styled.div`
  p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${COLORS.Gray50};
  }
`;

export const Grid = styled.div`
  gap: 20px;
  border-top: 1px solid ${COLORS.Gray50};
  padding-top: 16px;
`;

// Add Role Css
export const Title = styled.h6`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: ${COLORS.Purple};
`;

export const AlertModal = styled.div`
  h5 {
    font-size: 18px;
    color: ${COLORS.Purple};
    font-weight: 600;
    /* margin: 10px 0 20px 0; */
    margin-bottom: 20px;
  }

  h6 {
    color: ${COLORS.Gray90};
    font-size: 15px;
    margin-bottom: 50px;
  }
`;

export const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};

  .tag {
    margin-bottom: ${rem(12)};
  }

  .content {
    grid-column-gap: ${rem(60)};

    .title {
      color: ${COLORS.Gray};
      margin-bottom: ${rem(4)};
    }

    .comma-text {
      padding-right: 2px;
    }

    .only-text {
      padding-left: 2px;
    }
  }
`;

export const Clear = styled.div`
  background: ${COLORS.CulturedWhite};
  border-radius: 35px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 5px 18px;
  cursor: pointer;
`;
