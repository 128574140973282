import { type UseQueryResult, useQuery } from 'react-query';
import { OptionExerciseService } from '../../services/wealthlane-award-services';

const useGetOptionExerciseSettlementType = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const optionExerciseSettlementTypeOptions = useQuery(
    ['getOptionExerciseSettlementTypeOptions'],
    async () => {
      return await handleRequest(
        OptionExerciseService.getApiAwardApiAppOptionExerciseSettlementTypes()
      );
    },
    {
      select: (data) => {
        const options = data?.map((item) => {
          return {
            label: item.displayName ?? '',
            value: item.id ?? '',
          };
        });
        const customOptions = options?.sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });

        return customOptions;
      },
    }
  );

  return optionExerciseSettlementTypeOptions;
};

export default useGetOptionExerciseSettlementType;
