import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ToastComponent from '../components/toast';
import { selectToken } from '../pages/auth/authSlice';
import { type ApiError, OpenAPI } from '../services/wealthlane-tax-services';
import { useLocation } from 'react-router-dom';

export function useWealthlaneTaxApi(): {
  dismissError: () => void;
  error: ApiError | undefined;
  isLoading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
} {
  const token = useSelector(selectToken);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  OpenAPI.BASE = process.env.REACT_APP_WEALTHLANE_API_ENDPOINT ?? '';
  OpenAPI.TOKEN = token?.toString();

  const location = useLocation();

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsLoading(true);
    try {
      const response = await request;
      setError(undefined);
      if (response == null || response === false) {
        toast.error(<ToastComponent label='Error' message={'Unable to fetch data'} />, {
          toastId: 2,
        });
      }

      return response;
    } catch (exception: any) {
      setError(exception);
      if (!location?.pathname?.includes('tax-library')) {
        if (
          exception?.body?.error?.validationErrors != null &&
          exception?.body?.error?.validationErrors !== false
        ) {
          const error = exception?.body?.error?.validationErrors?.map((error: any) => {
            return error.message;
          });

          if (
            error.includes('OTP is invalid') === true ||
            error.includes('Invalid Template') === true ||
            error.includes('Only excel file can be uploaded') === true
          ) {
            //
          } else {
            toast.error(<ToastComponent label='Error' message={`${String(error ?? '')}`} />, {
              toastId: 1,
            });
          }
        } else if (
          exception?.body?.error?.message.includes('Index was outside the bounds of the array.') ===
          true
        ) {
          toast.error(<ToastComponent label='Error' message={'Invalid data format'} />);
        } else if (exception?.body?.error != null) {
          toast.error(
            <ToastComponent
              label='Error'
              message={`${String(exception?.body?.error?.message ?? '')}`}
            />
          );
        } else if (
          exception.status === 401 ||
          (exception.status === 403 && isEmpty(exception?.body))
        ) {
          /* empty */
        } else {
          toast.error(<ToastComponent label='Error' message={'Unable to fetch data'} />, {
            toastId: 2,
          });
        }
      } else if (
        exception?.body?.error?.message.includes('Index was outside the bounds of the array.') ===
        true
      ) {
        toast.error(<ToastComponent label='Error' message={'Invalid data format'} />);
      } else if (
        exception?.body?.error?.message.includes(
          'An internal error occurred during your request!'
        ) === true
      ) {
      } else if (exception?.body?.error != null) {
        toast.error(
          <ToastComponent
            label='Error'
            message={`${String(exception?.body?.error?.message ?? '')}`}
          />
        );
      } else if (
        exception.status === 401 ||
        (exception.status === 403 && isEmpty(exception?.body))
      ) {
        /* empty */
      } else {
        toast.error(<ToastComponent label='Error' message={'Unable to fetch data'} />, {
          toastId: 2,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  function dismissError(): void {
    setError(undefined);
  }

  return { dismissError, error, isLoading, handleRequest };
}

export default useWealthlaneTaxApi;
