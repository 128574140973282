import { type UseQueryResult, useQuery } from 'react-query';
import {
  GrantService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Grant_GrantDto,
} from '../../services/wealthlane-award-services';

const useGetGrantById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Grant_GrantDto | undefined,
  unknown
> => {
  const GrantById = useQuery(['getGrantById', id], async () => {
    if (id) {
      return await handleRequest(GrantService.getApiAwardApiAppGrantGrantById(id));
    }
  });

  return GrantById;
};

export default useGetGrantById;
