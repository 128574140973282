import { Transition } from 'react-transition-group';
import Button from '../../../components/button';
import { AlertPopupContainer } from '../../../components/alert-popup/alertPopup.styled';
import Heading from '../../../components/typography';

interface ConfirmationModalType {
  isModalOpen: boolean;
  confirmLoading: boolean;
  closeConfirmModal: () => void;
  handleConfirmClick: () => void;
}

const ConfirmationModal = ({
  isModalOpen,
  confirmLoading,
  closeConfirmModal,
  handleConfirmClick,
}: ConfirmationModalType): JSX.Element => {
  return (
    <Transition in={isModalOpen} timeout={250}>
      {(state) => (
        <AlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className='popupDialog'>
                <div className='popupContent'>
                  <Heading
                    variant='h5'
                    className='mb-5'
                    title='Are you sure you want to use delete this bank account?'
                  />
                  <div className='popupButtons'>
                    <Button
                      variant='primary-dark'
                      type='button'
                      title='No'
                      onClick={() => {
                        closeConfirmModal();
                      }}
                    />
                    <Button
                      variant='primary'
                      title='Confirm'
                      as='Delete'
                      onClick={() => {
                        handleConfirmClick();
                      }}
                      icon={<span className='material-symbols-outlined'>check_circle</span>}
                      loading={confirmLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertPopupContainer>
      )}
    </Transition>
  );
};

export default ConfirmationModal;
