import React, { type PropsWithChildren } from 'react';
import { AnimatePresence } from 'framer-motion';

const AnimationWrapper = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <AnimatePresence
      // Disable any initial animations on children that
      // are present when the component is first rendered
      initial={false}
      // Only render one component at a time.
      // The exiting component will finish its exit
      // animation before entering component is rendered
      mode='wait'
      // Fires when all exiting nodes have completed animating out
      onExitComplete={() => null}>
      {children}
    </AnimatePresence>
  );
};

export default AnimationWrapper;
