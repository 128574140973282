/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import FlashMessage from '../../../components/flash-message';
import { PopoverContentWrapper } from '../../../components/modal/modal.styled';
import Heading from '../../../components/typography';
import { type Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto } from '../../../services/wealthlane-award-services';

interface VestingTemplatePopoverType {
  data: Wealthlane_AwardService_Dtos_VestingTemplate_VestingTemplateForListDto;
}

const VestingTemplatePopoverContent = ({ data }: VestingTemplatePopoverType): JSX.Element => {
  const [percentage, setPercentage] = useState<any>();
  const newPercentage = data?.amountOneInPercentage && Number(data?.amountOneInPercentage * 100);

  const vestingFrequency = data?.intervalInMonths;

  const remainingPercentage = newPercentage && 100 - newPercentage;
  const remainingMonth = Number(data?.lengthInMonths) - Number(data?.vestsAfterInMonths);
  const newValue = remainingPercentage && remainingPercentage / remainingMonth;

  const quarterlyRemainingMonth = remainingMonth / 3;
  const quarterlyValue = remainingPercentage && remainingPercentage / quarterlyRemainingMonth;
  const semiAnnuallyRemainingMonth = remainingMonth / 6;
  const semiAnnuallyValue = remainingPercentage && remainingPercentage / semiAnnuallyRemainingMonth;
  const AnnuallyRemainingMonth = remainingMonth / 12;
  const AnnuallyValue = remainingPercentage && remainingPercentage / AnnuallyRemainingMonth;

  useEffect(() => {
    if (data?.amountOneInPercentage) {
      if (vestingFrequency === 3) {
        setPercentage(Number(quarterlyValue));
      } else if (vestingFrequency === 6) {
        setPercentage(Number(semiAnnuallyValue));
      } else if (vestingFrequency === 12) {
        setPercentage(Number(AnnuallyValue));
      } else {
        setPercentage(Number(newValue));
      }
    } else {
      const nonPercentageValue =
        (Number(vestingFrequency) / Number(data?.lengthInMonths)) * 100 ?? 0;
      setPercentage(Number(nonPercentageValue));
    }
  }, [data]);

  // setPercentage(Number(newValue));

  return (
    <PopoverContentWrapper className='pop-overs'>
      <div className={` pop-overs content grid gap-y-6 gap-x-10 grid-cols-2`}>
        <div className='content-info'>
          <Heading
            title={data.isCliffVesting ?? false ? 'Term Period' : 'Vesting Frequency'}
            variant='body-xs'
            className='pop-overs title'
          />
          <Heading
            title={`${String(
              data.isCliffVesting ?? false
                ? data.lengthInMonths
                : data.isHybridVesting ?? false
                ? data.intervalInMonths
                : (data.isCustomVesting || data.isStepVesting) ?? false
                ? data.intervalInMonths
                : data.vestsAfterInMonths
            )} month${String(
              Number(
                data.isCliffVesting ?? false
                  ? data.lengthInMonths
                  : data.isHybridVesting ?? false
                  ? data.intervalInMonths
                  : (data.isCustomVesting || data.isStepVesting) ?? false
                  ? data.intervalInMonths
                  : data.vestsAfterInMonths
              ) > 1
                ? 's'
                : ''
            )}`}
            variant='body-s'
            className='pop-overs'
          />
        </div>
        {(data.isHybridVesting ?? false) && (
          <div className='content-info pop-overs'>
            <Heading title='Cliff' variant='body-xs' className='title pop-overs' />
            <Heading
              title={`${String(data.vestsAfterInMonths)} month${
                Number(data.vestsAfterInMonths) > 1 ? 's' : ''
              }`}
              variant='body-s'
              className='pop-overs'
            />
          </div>
        )}
        <div className='content-info col-span-2'>
          <Heading title='Vest' variant='body-xs' className='title pop-overs' />
          {data?.isCliffVesting ?? false ? (
            <Heading
              variant='body-s'
              title={`100% of shares will vest after ${Number(data.lengthInMonths)} month${
                Number(data.lengthInMonths) > 1 ? 's' : ''
              }`}
            />
          ) : (
            <>
              {data?.isStepVesting ? (
                <Heading
                  variant='body-s'
                  title={`${
                    data.amountOneInPercentage === null
                      ? (
                          (Number(data?.intervalInMonths) / Number(data?.lengthInMonths)) *
                          100
                        ).toFixed(2)
                      : data.amountOneInPercentage && (data.amountOneInPercentage * 100).toFixed(2)
                  }% vest ${data?.isHybridVesting ?? false ? 'after the first' : 'every'} ${String(
                    data.intervalInMonths ?? ''
                  )} month${Number(data.intervalInMonths) > 1 ? 's' : ''}`}
                />
              ) : (
                <Heading
                  variant='body-s'
                  title={`${
                    data.amountOneInPercentage === null
                      ? (
                          (Number(data?.vestsAfterInMonths) / Number(data?.lengthInMonths)) *
                          100
                        ).toFixed(2)
                      : data.amountOneInPercentage && (data.amountOneInPercentage * 100).toFixed(2)
                  }% vest ${data?.isHybridVesting ?? false ? 'after the first' : 'every'} ${String(
                    data.vestsAfterInMonths ?? ''
                  )} month${Number(data.vestsAfterInMonths) > 1 ? 's' : ''}`}
                />
              )}

              {(data.isHybridVesting ?? false) && (
                <Heading
                  variant='body-s'
                  title={`${Number(percentage).toFixed(2) ?? 0}% vest every ${String(
                    data.intervalInMonths ?? ''
                  )} month${Number(data.intervalInMonths) > 1 ? 's' : ''} thereafter`}
                />
              )}
            </>
          )}
        </div>
      </div>
      {data != null && Object.keys(data).length === 0 && (
        <FlashMessage size='sm' message='No Data Found' type='info' popoverClassName='pop-overs' />
      )}
    </PopoverContentWrapper>
  );
};

export default VestingTemplatePopoverContent;
