/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_EmployeeType_CreateEmployeeTypeDto } from '../models/Wealthlane_CustomerService_Dtos_EmployeeType_CreateEmployeeTypeDto';
import type { Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto } from '../models/Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto';
import type { Wealthlane_CustomerService_Dtos_EmployeeType_UpdateEmployeeTypeDto } from '../models/Wealthlane_CustomerService_Dtos_EmployeeType_UpdateEmployeeTypeDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeTypeService {

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppEmployeeTypeEmployeeType(
requestBody?: Wealthlane_CustomerService_Dtos_EmployeeType_CreateEmployeeTypeDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/employee-type/employee-type',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_EmployeeType_UpdateEmployeeTypeDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppEmployeeTypeEmployeeType(
requestBody?: Wealthlane_CustomerService_Dtos_EmployeeType_UpdateEmployeeTypeDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_EmployeeType_UpdateEmployeeTypeDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/employee-type/employee-type',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param employeeTypeId 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppEmployeeTypeEmployeeType(
employeeTypeId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/employee-type/employee-type/{employeeTypeId}',
            path: {
                'employeeTypeId': employeeTypeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param employeeTypeId 
     * @returns Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppEmployeeTypeEmployeeType(
employeeTypeId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/employee-type/employee-type/{employeeTypeId}',
            path: {
                'employeeTypeId': employeeTypeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppEmployeeTypeEmployeeTypes(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/employee-type/employee-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param isTaxEnabled 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppEmployeeTypeEmployeeTypesList(
isTaxEnabled?: boolean,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/employee-type/employee-types-list',
            query: {
                'IsTaxEnabled': isTaxEnabled,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
