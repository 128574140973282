import React, { type ReactNode, useEffect, useState } from 'react';
import { Clear, ModelStyle } from './modal.styled';
import { COLORS } from '../../constants/style/color';
import Heading from '../typography';
import Spinners from '../spinner';
import ModalAnimation from '../animation/modal-animation';
import ClickableTooltip from '../tooltip/clickTooltip';
import { MaterialIconFilled } from '../../utils/global';
import { useSelector } from 'react-redux';
import { selectNotify } from '../../pages/auth/notify';
import AlertPopup from '../alert-popup';
import Tag from '../lozenge/tags';
import { isEmpty } from 'lodash';

interface ModalComponentType {
  show: boolean;
  closeModal: any;
  children: any;
  subTitle?: string;
  title?: string | ReactNode;
  className?: any;
  showHeader?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl';
  placement?: 'top' | 'center' | 'bottom';
  handleSubmit?: any;
  loading?: boolean;
  showToolTip?: boolean;
  tooltipText?: string;
  outsideClickable?: boolean;
  isNotification?: boolean;
  clearAll?: boolean;
  clearAllNotification?: any;
  notificationModalClassName?: string;
  valueEdited?: boolean;
  showTag?: string;
  modalStatus?: string;
  customAlertMessage?: string;
}

const ModalComponent = ({
  show,
  closeModal,
  children,
  title,
  subTitle,
  className,
  size,
  placement,
  loading = false,
  handleSubmit,
  showHeader = true,
  outsideClickable = false,
  isNotification = false,
  showToolTip,
  clearAll = false,
  tooltipText,
  clearAllNotification,
  notificationModalClassName,
  valueEdited = true,
  showTag,
  modalStatus,
  customAlertMessage,

  ...rest
}: ModalComponentType): JSX.Element => {
  const showNotify = useSelector(selectNotify);

  const [openAlert, setOpenAlert] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  }, [show]);

  return (
    <>
      <ModalAnimation
        isNotification={isNotification}
        closeModal={() => outsideClickable && closeModal()}>
        <ModelStyle
          size={size ?? 'md'}
          {...rest}
          className={className}
          showNotify={showNotify}
          loading={loading}
          subTitle={subTitle}>
          {showHeader && (
            <div className={`modal-heading ${notificationModalClassName ?? ''}`}>
              <div className={`header-text ${notificationModalClassName ?? ''}`}>
                <div
                  className={`grid ${
                    showToolTip ? 'grid-cols-3' : showTag && 'grid-cols-3'
                  } items-center gap-4`}>
                  <div
                    className={` col-span-2 flex items-center gap-x-5 ${
                      notificationModalClassName ?? ''
                    }`}>
                    {title != null && title !== '' && (
                      <Heading
                        variant='h4'
                        title={title}
                        className={`${notificationModalClassName ?? ''}${subTitle ? '' : 'mb-0'}`}
                      />
                    )}
                    {(showToolTip ?? false) && (
                      <ClickableTooltip tooltipText={tooltipText} variant='dark'>
                        <span className='material-symbols-sharp mt-1' style={MaterialIconFilled}>
                          info
                        </span>
                      </ClickableTooltip>
                    )}
                  </div>
                  {!isEmpty(showTag) && <Tag variant='info' title={`#${showTag}`} />}
                </div>

                {subTitle != null && <Heading variant='h6' title={subTitle} color={COLORS.Black} />}
              </div>
              {clearAll ? (
                <Clear
                  onClick={() => clearAllNotification()}
                  className={notificationModalClassName}>
                  Clear All
                </Clear>
              ) : (
                <div
                  onClick={() => {
                    modalStatus !== 'View'
                      ? valueEdited
                        ? closeModal()
                        : setOpenAlert(true)
                      : closeModal();
                  }}
                  className='modal-close'>
                  <span className='material-symbols-outlined icon-color'>cancel</span>
                  <Heading variant='body-s' title='Close' color={`${COLORS.Gray}`} />
                </div>
              )}
            </div>
          )}
          <div className='modal-body'>
            {loading ? (
              <div className='modal-loader'>
                <Spinners />
              </div>
            ) : (
              React.cloneElement(children, { show, closeModal })
            )}
          </div>
        </ModelStyle>
      </ModalAnimation>
      {openAlert && (
        <AlertPopup
          isShow={openAlert}
          closeModal={() => {
            setOpenAlert(false);
          }}
          customTitle={
            customAlertMessage
              ? customAlertMessage
              : 'Your changes will not be saved. Are you sure you want to close?'
          }
          handleSubmit={async () => {
            setOpenAlert(false);
            await closeModal();
          }}
          // loading={loading}
        />
      )}
    </>
  );
};

export default ModalComponent;
