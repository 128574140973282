import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { LabelNormal } from '../input-text/input.styled';
import { type RadioButtonProps } from './customRadio';
import { RadioButtonWrapper } from './radioButton.styled';

const CustomCheckBox = React.memo((props: RadioButtonProps) => {
  const {
    label,
    name,
    className,
    errors,
    touched,
    labelClassName,
    required,
    value,
    disabled,
    id,
    arrValue,
    checked,
    errorValue,
    touchedValue,
    ...rest
  } = props;

  const hasError =
    (Boolean(errors) && Boolean(touched) && Boolean(errors[name]) && Boolean(touched[name])) ||
    (Boolean(errorValue) && Boolean(touchedValue))
      ? 'is-invalid'
      : '';
  return (
    <>
      <RadioButtonWrapper className={className}>
        <Field
          name={name}
          id={id}
          label={label}
          type='checkbox'
          className={hasError}
          disabled={disabled}
          checked={checked ?? (arrValue !== undefined && arrValue)}
          {...rest}
        />
        {Boolean(label) && (
          <LabelNormal htmlFor={id} className={labelClassName}>
            {label} {!(required ?? false) && <span>(Optional)</span>}
          </LabelNormal>
        )}
      </RadioButtonWrapper>
      <ErrorMessage name={name} component='div' className='error-message' />
    </>
  );
});

export default CustomCheckBox;
