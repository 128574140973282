/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Participant_GetParticipantsDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Participant_GetParticipantsDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantApprovalDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantApprovalDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ParticipantStatusDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ParticipantStatusDto';
import type { Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto } from '../models/Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GetGovernmentDocumentBlobRequest } from '../models/Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GetGovernmentDocumentBlobRequest';
import type { Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantApprovalService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_Participant_ParticipantStatusDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantApprovalParticipantStatuses(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_Participant_ParticipantStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-approval/participant-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantApprovalApproveParticipants(
requestBody?: Array<Wealthlane_CustomerService_Dtos_Participant_ParticipantApprovalDto>,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-approval/approve-participants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantApprovalRejectParticipants(
requestBody?: Array<Wealthlane_CustomerService_Dtos_Participant_ParticipantApprovalDto>,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-approval/reject-participants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param employeeStatusIds 
     * @param employeeTypeId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Participant_GetParticipantsDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantApprovalParticipantsList(
employeeStatusIds?: Array<number>,
employeeTypeId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Participant_GetParticipantsDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-approval/participants-list',
            query: {
                'EmployeeStatusIds': employeeStatusIds,
                'EmployeeTypeId': employeeTypeId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantApprovalParticipantById(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ViewParticipantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-approval/participant-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantApprovalParticipantRemarks(
id: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-approval/participant-remarks/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantApprovalDownloadGovernmentDocument(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GetGovernmentDocumentBlobRequest,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantGovernmentDocument_GovernmentDocumentBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-approval/download-government-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantApprovalApproveBulkParticipants(
requestBody?: Array<Wealthlane_CustomerService_Dtos_Participant_ParticipantApprovalDto>,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-approval/approve-bulk-participants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantApprovalRejectBulkParticipants(
requestBody?: Array<Wealthlane_CustomerService_Dtos_Participant_ParticipantApprovalDto>,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Participant_ImportBulkParticipantResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-approval/reject-bulk-participants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
