import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerApprovalProcessService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CustomerApprovalProcessDto,
} from '../../services/wealthlane-customer-services';

const useGetAllCustomerApprovalProcess = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_CustomerApprovalProcess_CustomerApprovalProcessDto[] | undefined,
  unknown
> => {
  const allCustomerApprovalProcess = useQuery(['getAllCustomerApprovalProcess'], async () => {
    return await handleRequest(
      CustomerApprovalProcessService.getApiCustomerApiAppCustomerApprovalProcess()
    );
  });

  return allCustomerApprovalProcess;
};

export default useGetAllCustomerApprovalProcess;
