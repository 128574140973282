import { lazy } from 'react';

export const RouteList = [
  {
    path: '/overview',
    component: lazy(async () => await import('../pages/overview')),
    exact: true,
  },
  {
    path: '/settings',
    component: lazy(async () => await import('../pages/settings')),
    exact: true,
  },
  {
    path: '/settings/organization-settings/:slug',
    component: lazy(async () => await import('../pages/settings')),
  },
  {
    path: '/settings/organization-settings/:slug/:id',
    component: lazy(async () => await import('../pages/settings')),
  },
  {
    path: '/settings/organization-settings/vesting-templates/preview',
    component: lazy(async () => await import('../pages/settings/vesting-templates/preview')),
  },
  // {
  //   path: '/settings/organization-settings/election-window-config',
  //   component: lazy(
  //     async () => await import('../pages/settings/configuration/components/election-window-config')
  //   ),
  // },
  {
    path: '/participants',
    component: lazy(async () => await import('../pages/participants/index')),
    exact: true,
  },
  {
    path: '/participants/audit-log',
    component: lazy(async () => await import('../pages/participants/audit-logs/index')),
    exact: true,
  },
  {
    path: '/participants/form/:slug',
    component: lazy(async () => await import('../pages/participants/participantForm')),
    exact: true,
  },
  {
    path: '/task/participants-approval',
    component: lazy(async () => await import('../pages/participants/participantApproval')),
    exact: true,
  },
  {
    path: '/task/plan-approval',
    component: lazy(
      async () => await import('../pages/settings/plan-management/planManagementApproval')
    ),
    exact: true,
  },
  {
    path: '/task/pre-clearance-requests',
    component: lazy(async () => await import('../pages/settings/trade-pre-clearance/request')),
    exact: true,
  },
  {
    path: '/awards/rsu-awards',
    component: lazy(async () => await import('../pages/rsu-awards/index')),
    exact: true,
  },
  {
    path: '/awards/rsu-awards/:slug',
    component: lazy(async () => await import('../pages/rsu-awards/rsuAwardsForm')),
    exact: true,
  },
  {
    path: '/awards/psu-awards',
    component: lazy(async () => await import('../pages/psu-awards/index')),
    exact: true,
  },
  {
    path: '/awards/psu-awards/:slug',
    component: lazy(async () => await import('../pages/psu-awards/psuAwardsForm')),
    exact: true,
  },
  {
    path: '/awards/option-awards',
    component: lazy(async () => await import('../pages/option-awards/index')),
    exact: true,
  },
  {
    path: '/awards/option-awards/:slug',
    component: lazy(async () => await import('../pages/option-awards/optionsAwardsForm')),
    exact: true,
  },

  {
    path: '/task/rsu-awards-approval',
    component: lazy(async () => await import('../pages/rsu-awards/rsuAwardsApproval')),
    exact: true,
  },
  {
    path: '/task/psu-awards-approval',
    component: lazy(async () => await import('../pages/psu-awards/psuAwardsApproval')),
    exact: true,
  },
  {
    path: '/task/option-awards-approval',
    component: lazy(async () => await import('../pages/option-awards/optionsAwardsApproval')),
    exact: true,
  },
  {
    path: '/task/participants-approval',
    component: lazy(async () => await import('../pages/participants/participantApproval')),
    exact: true,
  },
  {
    path: '/task/settlement-config-approval',
    component: lazy(
      async () => await import('../pages/settings/settlement-config/settlementConfigApproval')
    ),
    exact: true,
  },
  {
    path: '/task/user-approval',
    component: lazy(async () => await import('../pages/settings/system-users/systemUserApproval')),
    exact: true,
  },
  {
    path: '/task/performance-rule-approval',
    component: lazy(
      async () => await import('../pages/settings/performance-rule/performanceRuleApproval')
    ),
    exact: true,
  },
  {
    path: '/task/rsu-vesting',
    component: lazy(async () => await import('../pages/overview/components/events/rsuEventPage')),
    exact: true,
  },
  {
    path: '/task/psu-vesting',
    component: lazy(async () => await import('../pages/overview/components/events/psuEventPage')),
    exact: true,
  },
  {
    path: '/task/option-vesting',
    component: lazy(
      async () => await import('../pages/overview/components/events/optionEventPage')
    ),
    exact: true,
  },
  // {
  //   path: '/task/federal-tax-approval',
  //   component: lazy(() => import('../pages/settings/Federal/FederalApprover')),
  //   exact: true,
  // },
  // {
  //   path: '/task/state-tax-approval',
  //   component: lazy(() => import('../pages/settings/tax/state/stateTaxApprover')),
  //   exact: true,
  // },
  // {
  //   path: '/task/local-tax-approval',
  //   component: lazy(() => import('../pages/settings/tax/local/localTaxApprover')),
  //   exact: true,
  // },
  {
    path: '/task-management-console',
    component: lazy(async () => await import('../pages/tmc')),
    exact: true,
  },
  {
    path: '/reports',
    component: lazy(async () => await import('../pages/reports/reports')),
    exact: true,
  },
  {
    path: '/reports/report-designer',
    component: lazy(async () => await import('../pages/reports/customize-report')),
    exact: true,
  },
  {
    path: '/reports/custom-report-designer',
    component: lazy(async () => await import('../pages/reports/customReportDesigner')),
    exact: true,
  },
  {
    path: '/reports/participant',
    component: lazy(async () => await import('../pages/reports/participant')),
    exact: true,
  },
  {
    path: '/reports/rsu-cancellation',
    component: lazy(async () => await import('../pages/reports/rsu-cancellation')),
    exact: true,
  },
  {
    path: '/reports/rsu-vesting',
    component: lazy(async () => await import('../pages/reports/rsu-vesting')),
    exact: true,
  },
  {
    path: '/reports/vesting',
    component: lazy(async () => await import('../pages/reports/vesting')),
    exact: true,
  },
  {
    path: '/reports/option',
    component: lazy(async () => await import('../pages/reports/option')),
    exact: true,
  },
  {
    path: '/reports/option-vesting',
    component: lazy(async () => await import('../pages/reports/option-vesting')),
    exact: true,
  },
  {
    path: '/reports/retirement-eligibility',
    component: lazy(async () => await import('../pages/reports/retirement-eligibility')),
    exact: true,
  },
  {
    path: '/reports/termination',
    component: lazy(async () => await import('../pages/reports/termination')),
    exact: true,
  },
  {
    path: '/transactions/release',
    component: lazy(async () => await import('../pages/transactions/release')),
    exact: true,
  },
  {
    path: '/transactions/release/form/:slug',
    component: lazy(async () => await import('../pages/transactions/release/releaseForm')),
    exact: true,
  },
  {
    path: '/transactions/release/view',
    component: lazy(async () => await import('../pages/transactions/release/view')),
    exact: true,
  },
  {
    path: '/transactions/exercise',
    component: lazy(async () => await import('../pages/exercise')),
    exact: true,
  },
  {
    path: '/transactions/transfer-and-allocate',
    component: lazy(async () => await import('../pages/transactions/transfer-and-allocate')),
    exact: true,
  },
  {
    path: '/transactions/transfer-and-allocate/view',
    component: lazy(async () => await import('../pages/transactions/transfer-and-allocate/view')),
    exact: true,
  },
  {
    path: '/transactions/export',
    component: lazy(async () => await import('../pages/transactions/export')),
    exact: true,
  },
  {
    path: '/transactions/transfer-and-allocate/form/:slug',
    component: lazy(
      async () =>
        await import('../pages/transactions/transfer-and-allocate/transferAndAllocateForm')
    ),
    exact: true,
  },
  {
    path: '/profile',
    component: lazy(async () => await import('../pages/profile')),
    exact: true,
  },
  {
    path: '/security',
    component: lazy(async () => await import('../pages/settings/login-and-security')),
    exact: true,
  },
  {
    path: '/design',
    component: lazy(async () => await import('../pages/test')),
    exact: true,
  },
  // {
  //   path: '/udf',
  //   component: lazy(() => import('../pages/settings/UDF')),
  //   exact: true,
  // },
  {
    path: '/task/tax-setup-approval',
    component: lazy(async () => await import('../pages/settings/tax/tax-setup/taxSetupApprover')),
    exact: true,
  },
  {
    path: '/task/tax-setup-approval/approver-history/:id',
    component: lazy(async () => await import('../pages/settings/tax/tax-setup/taxApproverHistory')),
    exact: true,
  },

  {
    path: '/notification',
    component: lazy(async () => await import('../pages/settings/notification')),
    exact: true,
  },

  {
    path: '/check-user',
    component: lazy(async () => await import('../pages/auth/checkUser')),
    exact: true,
  },

  // Page Not Found
  {
    path: '/page-not-found',
    component: lazy(async () => await import('../pages/pageNotFound')),
    exact: true,
  },
];
