import Tag from '../../components/lozenge/tags';
import ClickableTooltip from '../../components/tooltip/clickTooltip';
import { ActionButton } from '../../components/vertical-dot/popper';
// eslint-disable-next-line camelcase
import { type Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankListDto } from '../../services/wealthlane-finance-integration-services';
import { Display } from '../style/common.styled';
import { TableBodyCellColumn, TableBodyCellColumnCenter } from '../style/table.styled';

export const BankAccountTemplatesHeader = (
  action: any,
  // eslint-disable-next-line camelcase
  data?: Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_CompanyBankListDto[] | null
): any[] => {
  const getAccountNumber = (value: string): string => {
    const getSplitValue = value.split('').length;
    return value.slice(getSplitValue - 4, getSplitValue);
  };

  return [
    {
      Header: 'Bank Account Details',
      accessor: 'accountName',
      width: 300,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>
              {row.original.bankName != null && row.original.bankName !== ''
                ? row.original.bankName
                : '-'}
              <Tag
                variant='info'
                title={
                  row.original.accountName != null && row.original.accountName !== ''
                    ? row.original.accountName
                    : '-'
                }
              />
            </div>
            <div className='cellSubtitle'>
              {row.original.domiciledIn != null && row.original.domiciledIn !== ''
                ? row.original.domiciledIn
                : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Account Number',
      accessor: 'accountNumber',
      width: 200,
      center: true,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <ClickableTooltip
            tooltipText={
              row.original.accountNumber != null && row.original.accountNumber !== ''
                ? row.original.accountNumber
                : 'No Data Found'
            }
            variant='dark'>
            <TableBodyCellColumnCenter>
              {row.original.accountNumber != null
                ? `XXXXXXXX${getAccountNumber(row.original.accountNumber)}`
                : '-'}
            </TableBodyCellColumnCenter>
          </ClickableTooltip>
        );
      },
    },
    {
      Header: 'Routing Number',
      accessor: 'routingNumber',
      width: 200,
      center: true,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumnCenter>
            {row.original.routingNumber != null && row.original.routingNumber !== ''
              ? row.original.routingNumber
              : '-'}
          </TableBodyCellColumnCenter>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 200,
      center: true,
      Cell: ({ row }: any) => (
        <Display>
          <Tag
            variant={row.original.status === 'Verified' ? 'success' : 'error'}
            title={
              row.original.status != null && row.original.status !== '' ? row.original.status : '-'
            }
          />
        </Display>
      ),
    },
    {
      Header: 'Account Purpose',
      accessor: 'bankPurpose',
      width: 200,
      sortable: false,
      center: true,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumnCenter>
            {row.original.bankPurpose != null && row.original.bankPurpose !== ''
              ? row.original.bankPurpose
              : '-'}
          </TableBodyCellColumnCenter>
        );
      },
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => {
        const show = data?.find((item) => item.id === row.original.id);
        let filterAction = [...action];
        if (show?.verificationStatusId !== 0) {
          const filterData = filterAction.filter((item) => item.action !== 'Verify');
          filterAction = filterData;
        }

        return (
          <ActionButton
            actions={filterAction}
            id={row.original.id}
            rowData={row.original}
            counter={parseInt(row?.id)}
          />
        );
      },
    },
  ];
};
