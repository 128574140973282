import { ImagePreview, ImagePreviewWrapper, UploadWrapper } from '../input-text/input.styled';
import InputFile from './inputFile';
import { type ModalStatusType } from '../../types/common';
import { useEffect, useState } from 'react';
import ProgressBarCustom from '../progress-bar/progressBar';
import { isEmpty } from 'lodash';

interface ImageViewerType {
  setFieldValue: any;
  setFieldTouched: any;
  modalStatus: ModalStatusType;
  values: any;
  errors: any;
  touched: any;
  name: string;
  listingName: string;
  loading: boolean;
  removedId: string[];
  handleImageOpen: any;
  setRemovedId: (value: string[]) => void;
  uploadLimit?: number;
  setFieldError?: any;
  isSingleFile?: boolean;
  errorValue?: any;
  touchedValue?: any;
  isBase64?: boolean;
  accept?: string;
}

const ImageViewerSingle = ({
  setFieldValue,
  setFieldTouched,
  modalStatus,
  values,
  errors,
  touched,
  name,
  listingName,
  loading,
  removedId,
  handleImageOpen,
  setRemovedId,
  uploadLimit,
  setFieldError,
  isSingleFile,
  errorValue,
  touchedValue,
  isBase64 = false,
  accept,
}: ImageViewerType): JSX.Element => {
  const [uploadFiles, setUploadFiles] = useState<any>([]);
  const [progressLoading, setProgressLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const loadingDuration = 500; // 3 seconds
  const [base64, setBase64] = useState<any>('');

  useEffect(() => {
    if (progressLoading) {
      const loadingTimeout = setTimeout(() => {
        if (progress >= 100) return;
        setProgress(progress + 1);
      }, loadingDuration / 100);
      if (progress === 100) {
        setProgressLoading(false);
        setProgress(0);
        setUploadFiles(
          uploadFiles && uploadFiles?.map((item: any) => ({ ...item, loading: false }))
        );
      }
      return () => {
        clearTimeout(loadingTimeout);
      };
    }
  }, [progress, progressLoading]);

  const convertToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result;
      let index = result && (result as any)?.indexOf('base64') + 7;
      let base64String = result?.slice(index);

      setBase64(base64String);
    };
    reader.onerror = (error) => {
      console.error('Error converting file to base64:', error);
    };
  };

  const handleSetFiles = (event: any): void => {
    setProgressLoading(true);
    const checkFile = event.target.files;
    const fileNames = Object.keys(checkFile)?.map((index) => checkFile[index].lastModified);
    const checkFiles = uploadFiles?.find((item: { file: File; id: number; src: string }) => {
      return fileNames.includes(item.id);
    });
    convertToBase64(event.target.files[0]);
    if (checkFiles == null || checkFiles === false) {
      const arrFiles = Array.from(event.target.files);
      const multipleFiles = arrFiles?.map((file: any, index: number) => {
        const src = window.URL.createObjectURL(file);
        return { file, id: file.lastModified, src, loading: !false };
      });
      setUploadFiles((prevImages: any) => prevImages.concat(multipleFiles));
      event.target.value = null;
    }
  };

  useEffect(() => {
    const filterUploadFiles =
      uploadFiles && uploadFiles?.map((item: { file: File; id: number; src: string }) => item.file);

    if (isBase64) {
      setFieldValue(
        name,
        base64
          ? [
              {
                ...filterUploadFiles,
                base64String: base64,
                fileSize: filterUploadFiles?.[0]?.size,
                type: filterUploadFiles?.[0]?.type,
              },
            ]
          : []
      );
    } else {
      setFieldValue(name, filterUploadFiles);
    }
  }, [uploadFiles]);

  const changeFile = (event: any): void => {
    // let v = values;
    // debugger;

    if (isSingleFile ?? false) {
      // setFieldValue(values[listingName], []);
      setUploadFiles(
        event?.target?.files[0].name === uploadFiles[0]?.file?.name ? uploadFiles : []
      );
      handleSetFiles(event);
    } else {
      if (uploadLimit != null && uploadLimit !== uploadFiles?.length) {
        handleSetFiles(event);
      } else {
        setFieldError(name, `Upload limit reached`);
      }
    }
  };

  return (
    <>
      <UploadWrapper
        className={`${
          (Boolean(errors[name]) && Boolean(touched[name])) ||
          (Boolean(errorValue) && Boolean(touchedValue))
            ? 'is-invalid'
            : ''
        }`}>
        {modalStatus === 'View'
          ? values[listingName]?.map((value: any, key: number) => {
              return (
                <>
                  {!isEmpty(value.file.name) ? (
                    <ImagePreview
                      key={key}
                      className='cursor-pointer'
                      onClick={() => handleImageOpen(value.file.uniqueName)}>
                      <div className='image-wrapper' title={value.file.name}>
                        <>
                          <span className='material-symbols-outlined image-icon'>draft</span>
                          <span className='image-filename'>{value.file.name}</span>
                        </>
                      </div>

                      {/* <div>
                <div
                  className='image-cross'
                  onClick={() => {
                    const removeFile = values[listingName]?.filter(
                      (item: any) => item.id !== value.id
                    );
                    setFieldValue(listingName, removeFile);
                    setRemovedId(removedId.concat(value.id));
                  }}>
                  <span className='material-symbols-outlined image-delete'>delete</span>
                </div>
              </div> */}
                    </ImagePreview>
                  ) : (
                    ''
                  )}
                </>
                // <ImageViewWrapper className='mt-2'>
                //   <div
                //     className='view-wrapper cursor-pointer'
                //     onClick={() => !loading && handleImageOpen(value.file.uniqueName)}>
                //     <p className='text-xs'>{value.file.name}</p>
                //     <div className='flex items-center gap-4'>
                //       {modalStatus !== 'View' && (
                //         <span
                //           className='cursor-pointer'
                //           onClick={() => {
                //             const removeFile = values[listingName]?.filter(
                //               (item: any) => item.id !== value.id
                //             );
                //             setFieldValue(listingName, removeFile);
                //             setRemovedId(removedId.concat(value.id));
                //           }}>
                //           <Cross />
                //         </span>
                //       )}
                //       <div
                //         className='cursor-pointer w-3.5'
                //         onClick={() => !loading && handleImageOpen(value.file.uniqueName)}>
                //         <ViewIcon />
                //       </div>
                //     </div>
                //   </div>
                // </ImageViewWrapper>
              );
            })
          : modalStatus === 'Edit'
          ? (uploadFiles as any[])?.length === 0 &&
            values[listingName]?.map((value: any, key: number) => (
              <>
                {!isEmpty(value.file.name) && (
                  <ImagePreview key={key}>
                    <div
                      className='image-wrapper cursor-pointer'
                      title={value.file.name}
                      // className='cursor-pointer'
                      onClick={() => handleImageOpen(value.file.uniqueName)}>
                      <>
                        <span className='material-symbols-outlined image-icon'>draft</span>
                        <span className='image-filename'>{value.file.name}</span>
                      </>
                    </div>

                    <div>
                      <div
                        className='image-cross'
                        onClick={() => {
                          const removeFile = values[listingName]?.filter(
                            (item: any) => item.id !== value.id
                          );
                          setFieldValue(listingName, removeFile);
                          setRemovedId(removedId.concat(value.id));
                        }}>
                        <span className='material-symbols-outlined image-delete'>delete</span>
                      </div>
                    </div>
                  </ImagePreview>
                )}
              </>
            ))
          : ''}
        <ImagePreviewWrapper>
          {uploadFiles &&
            uploadFiles?.map((value: any, key: number) => (
              <ImagePreview key={key}>
                <div className='image-wrapper' title={value.file.name}>
                  {Boolean(value.src) && (
                    <>
                      {value.loading != null && value.loading !== false ? (
                        <ProgressBarCustom progress={progress} trackWidth={5} indicatorWidth={5} />
                      ) : (
                        <span className='material-symbols-outlined image-icon'>draft</span>
                      )}
                      <span className='image-filename'>{value.file.name}</span>
                    </>
                  )}
                </div>

                <div>
                  {modalStatus !== 'View' && (
                    <div
                      className='image-cross'
                      onClick={() => {
                        const removeNameFile = uploadFiles?.filter(
                          (item: any) => item.id !== value.id
                        );
                        if (isSingleFile ?? false) {
                          setFieldValue(listingName, []);
                          setUploadFiles([]);
                        } else {
                          setUploadFiles(removeNameFile);
                        }
                        setTimeout(() => setFieldTouched(name, true));
                        (isSingleFile ?? false) && setTimeout(() => setFieldError(name, ''));
                      }}>
                      <span className='material-symbols-outlined image-delete'>delete</span>
                    </div>
                  )}
                </div>
              </ImagePreview>
            ))}
        </ImagePreviewWrapper>
        {modalStatus !== 'View' && (
          <InputFile
            name={name}
            onChange={changeFile}
            errors={errors}
            touched={touched}
            accept={accept}
            // fileType={'*PDF only(Not more than 10 MB)'}
          />
        )}
        {/* <ErrorMessageWrapper className='mt-1'>
          <ErrorMessage name={name} component='div' className='field-error-message mt-1' />
        </ErrorMessageWrapper> */}
      </UploadWrapper>
    </>
  );
};

export default ImageViewerSingle;
