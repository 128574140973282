import { type UseQueryResult, useQuery } from 'react-query';
import { TradePreClearanceService } from '../../services/wealthlane-customer-services';

const useGetTradeaAllParticipant = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
      department: string;
      title: string;
    }>
  | undefined,
  unknown
> => {
  const tradeAllParticipantOptions = useQuery(
    ['useGetTradeaAllParticipantOptions'],
    async () => {
      return await handleRequest(
        TradePreClearanceService.getApiCustomerApiAppTradePreClearanceTradePreClearanceParticipantsList()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.participantName ?? '',
            value: item.participantId ?? '',
            department: item.department ?? '',
            title: item.jobTitle ?? '',
          };
        });
      },
    }
  );

  return tradeAllParticipantOptions;
};

export default useGetTradeaAllParticipant;
