import { FieldArray, type FormikErrors, type FormikTouched, type FormikValues } from 'formik';
import React from 'react';
import Button from '../../../../components/button';
import FormSelect from '../../../../components/dropdown/formSelect';
import Heading from '../../../../components/typography';
import { COLORS } from '../../../../constants/style/color';
import { type OptionType, type UserType } from '../../../../types/common';
import CustomDropdown from '../../../../components/custom-dropdown';

interface GroupProps {
  errors: FormikErrors<any> | any;
  touched: FormikTouched<any> | any;
  values: FormikValues;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
  departmentOptions?: OptionType[];
  employeeTypeOptions?: OptionType[];
  jobTitleOptions?: UserType[];
  setFieldValue: any;
  setFieldTouched: any;
  handleChange: any;
}

const GroupOptions = ({
  errors,
  touched,
  values,
  departmentOptions,
  employeeTypeOptions,
  jobTitleOptions,
  handleRequest,
  handleChange,
  setFieldValue,
  setFieldTouched,
}: GroupProps): JSX.Element => {
  const handleUser = (userData: UserType, index: number): void => {
    const userInfo = values?.tradePreClearanceGroups[index]?.jobTitleIds;
    if (!(userInfo.includes(userData.value) === true)) {
      userInfo.push(userData.value);
      setFieldValue(`tradePreClearanceGroups.${index}.groupData`, true);
    } else {
      userInfo.splice(userInfo.indexOf(userData.value), 1);
      setFieldValue(`tradePreClearanceGroups.${index}.groupData`, false);
    }
    setFieldValue(`tradePreClearanceGroups.${index}.jobTitleIds`, [...userInfo]);
    setTimeout(() => setFieldTouched(`tradePreClearanceGroups.${index}.jobTitleIds`, true));
  };
  return (
    <div className='col-span-12'>
      <Heading variant='h5' title='Add Participant Groups' className='group-title' />
      <Heading
        variant='body'
        title='Select one or more below'
        className='group-sub-title'
        color={COLORS.Gray}
      />
      <div className='grid grid-cols-10 gap-x-8'>
        <FieldArray
          name='tradePreClearanceGroups'
          render={(arrayHelpers) => {
            return (
              <>
                {(values?.tradePreClearanceGroups as boolean) &&
                  values.tradePreClearanceGroups.length > 0 &&
                  values.tradePreClearanceGroups.map((friend: any, index: number) => (
                    <>
                      <FormSelect
                        containerClassName='col-span-3'
                        label='Department'
                        placeholder='Select'
                        values={values}
                        name={`tradePreClearanceGroups.${index}.departmentId`}
                        errorValue={errors?.tradePreClearanceGroups?.[index]?.departmentId}
                        touchedValue={touched?.tradePreClearanceGroups?.[index]?.departmentId}
                        arrValue={values?.tradePreClearanceGroups[index]?.departmentId}
                        setFieldValue={setFieldValue}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(`tradePreClearanceGroups.${index}.groupData`, true);
                          setTimeout(() =>
                            setFieldTouched(`tradePreClearanceGroups.${index}.departmentId`, true)
                          );
                        }}
                        errors={errors}
                        touched={touched}
                        allOptions={departmentOptions}
                        options={departmentOptions?.filter((item) => {
                          const filteredData = values.tradePreClearanceGroups.map(
                            (option: Record<string, string | string[]>) => option.departmentId
                          );
                          return !(filteredData.includes(item.value) === true);
                        })}
                        required
                      />
                      <FormSelect
                        containerClassName='col-span-3'
                        label='Employee Type '
                        placeholder='Select'
                        name={`tradePreClearanceGroups.${index}.employeeTypeId`}
                        arrValue={values?.tradePreClearanceGroups[index]?.employeeTypeId}
                        errorValue={errors?.tradePreClearanceGroups?.[index]?.employeeTypeId}
                        touchedValue={touched?.tradePreClearanceGroups?.[index]?.employeeTypeId}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(`tradePreClearanceGroups.${index}.groupData`, true);
                          setTimeout(() =>
                            setFieldTouched(`tradePreClearanceGroups.${index}.employeeTypeId`, true)
                          );
                        }}
                        errors={errors}
                        touched={touched}
                        allOptions={employeeTypeOptions}
                        options={employeeTypeOptions?.filter((item) => {
                          const filteredData = values.tradePreClearanceGroups.map(
                            (option: Record<string, string | string[]>) => option.employeeTypeId
                          );
                          return !(filteredData.includes(item.value) === true);
                        })}
                        values={values}
                        setFieldValue={setFieldValue}
                        required
                      />
                      <div className='col-span-3'>
                        <CustomDropdown
                          title={'Job Title'}
                          data={jobTitleOptions}
                          errorMessage={errors?.tradePreClearanceGroups?.[index]?.employeeTypeId}
                          touchedValue={touched?.tradePreClearanceGroups?.[index]?.employeeTypeId}
                          handleUser={(e) => {
                            handleUser(e, index);
                          }}
                          checkedList={values?.tradePreClearanceGroups[index]?.jobTitleIds ?? []}
                          cssStyles={{
                            width: '225px',
                            height:
                              jobTitleOptions != null && jobTitleOptions?.length > 5
                                ? '230px'
                                : 'auto',
                            contentHeight:
                              jobTitleOptions != null && jobTitleOptions?.length > 5
                                ? '200px'
                                : 'auto',
                            padding: '0',
                            verticalGap: '8',
                            column: 1,
                          }}
                          toggleItems={{
                            showSearch: false,
                            showTotal: false,
                            displaySelected: true,
                          }}
                        />
                      </div>
                      {values.tradePreClearanceGroups.length > 1 && (
                        <div
                          className='flex items-center ap-2'
                          role='button'
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}>
                          <span className='material-symbols-outlined text-red-600'>delete</span>
                          <Heading variant='body-s' title='Delete' color={`${COLORS.Gray}`} />
                        </div>
                      )}
                    </>
                  ))}

                {/* {errors?.tradePreClearanceGroups && touched?.tradePreClearanceGroups && (
                  <div className='col-span-12'>
                    <div className='error-message'>{errors?.tradePreClearanceGroups}</div>
                  </div>
                )} */}
                <div className='col-span-12'>
                  <Button
                    title='Add another criteria'
                    type='button'
                    variant='primary-dark'
                    icon='group_add'
                    btnClassName='w-full'
                    onClick={() => {
                      arrayHelpers.push({
                        departmentId: null,
                        employeeTypeId: null,
                        jobTitleIds: [],
                        groupData: false,
                      });
                    }}
                  />
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

export default GroupOptions;
