import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import { CustomReportsService } from '../../services/wealthlane-report-services';
import { useNavigate } from 'react-router-dom';

const usePutReplaceReportName = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  checkNameError?: any
) => {
  const navigate = useNavigate();

  //   const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      const newPayload = {
        ...payload,
        reportName: payload?.reportName.trim(),
        id: checkNameError ? checkNameError?.duplicateReportId : payload?.id,
        reportConfigurationId: checkNameError ? checkNameError?.duplicateReportId : payload?.id,
      };

      return await handleRequest(
        CustomReportsService.putApiReportApiAppCustomReportsCustomReports(newPayload)
      );
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          closeModal();
          toast.success(<ToastComponent label='Success' message='Replaced' />);
          navigate('/reports');
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePutReplaceReportName;
