import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerTitleService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerTitle_CustomerTitleDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-customer-services';
import { type TableParamsType } from '../../types/common';

const useGetCustomerTitleListWithPagination = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo?: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerTitle_CustomerTitleDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo ?? {};
  const CustomerTitleList = useQuery(
    ['getCustomerTitleListWithPagination', filter, sorting, skipCount, maxResultCount],
    async () => {
      return await handleRequest(
        CustomerTitleService.getApiCustomerApiAppCustomerTitleCustomerTitleList(
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return CustomerTitleList;
};

export default useGetCustomerTitleListWithPagination;
