import styled, { css } from 'styled-components';
import { SecondaryButton } from '../../components/button/button.styled';
import { getViewHeight, rem } from '../../utils/style';
import { COLORS } from './color';

interface Types {
  alignItems?: any;
  justifyContent?: any;
  leading?: any;
  bgColor?: any;
  img?: any;
  paddingInfo?: any;
  hasNotify?: boolean;
}

interface ParticipantOverflowWrapperType {
  height?: string | number;
  hasOverFlow?: boolean;
}

export const LineBreak = styled.div`
  white-space: pre;
  line-height: ${rem(20)};
`;

export const Display = styled.div<Types>`
  display: flex;
  align-items: ${(props) => (props.alignItems != null ? props.alignItems : 'center')};
  justify-content: ${(props) => (props.justifyContent != null ? props.justifyContent : 'center')};
`;

export const ImportBlock = styled.div`
  margin: ${rem(25)} 0 ${rem(18)} 0;
`;

export const InvitationBlock = styled.div`
  display: flex;
  gap: ${rem(8)};
  margin: ${rem(18)} 0 ${rem(10)} 0;
`;

export const PageHeader = styled.div`
  margin-bottom: ${rem(25)};

  h3 {
    margin-bottom: ${rem(5)};
  }

  span {
    color: ${COLORS.Gray};
  }
`;

export const IndexHeader = styled.div``;

// Old Design
export const Loading = styled.div`
  margin-right: 10px;
`;

export const ModalHeading = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${COLORS.Blue};
`;

export const SignUpHeadingMain = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${COLORS.Blue};

  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const BigHeadingMain = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.Blue};

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const HeadingMain = styled.h5<Types>`
  font-weight: 500;
  font-size: 12px;
  line-height: ${(props) => (props.leading != null ? props.leading : '15px')};
  color: ${(props) => (props.color != null ? props.color : `${COLORS.Black}`)};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const PlainText = styled.h5<Types>`
  font-weight: 500;
  font-size: 15px;
  line-height: ${(props) => (props.leading != null ? props.leading : '19px')};
  color: ${(props) => (props.color != null ? props.color : `${COLORS.Gray60}`)};
  text-align: center;
  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const SubHeadingMain = styled.h6<Types>`
  font-weight: 700;
  font-size: 12px;
  line-height: ${(props) => (props.leading != null ? props.leading : '15px')};
  color: ${(props) => (props.color != null ? props.color : `${COLORS.Secondary}`)};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 12px;
  }
`;

export const WrapperContainer = styled.div<Types>`
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: ${(props) => `url(${String(props.img ?? '')})`};
    height: ${getViewHeight()};
  }

  @media (min-width: 768px) {
    padding: 37px;
  }

  @media (min-width: 1200px) {
    padding: ${(props) => (props.paddingInfo != null ? props.paddingInfo : '43px 120px')};
  }
`;

export const Container = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 720px;
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: calc(100% - 220px);
  }
`;

export const InnerContainer = styled.div<Types>`
  min-height: ${(props) =>
    props.hasNotify ?? false ? `${getViewHeight('101px')}` : `${getViewHeight('67px')}`};
  position: relative;

  &:before {
    content: '';
    position: fixed;
    top: ${(props) => (props.hasNotify ?? false ? '101px' : '67px')};
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: ${(props) => `url(${String(props.img ?? '')}) no-repeat`};
    height: ${getViewHeight()};
    background-size: cover;
  }
`;

export const ErrorMessageWrapper = styled.div`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  color: ${COLORS.Gray};
  margin-top: ${rem(6)};
  transition: all 0.2s linear;
`;

export const FormWrapper = styled.div`
  .form-title-small {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${COLORS.Gray60};
    padding-bottom: 5px;
  }
`;
export const TooltipWrapper = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
export const GoBackBlock = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
  cursor: pointer;
  .goBack {
    transition: all 0.2s linear;
  }
  .material-symbols-outlined {
    transition: all 0.2s linear;
    font-size: 20px;
    padding-right: 5px;
  }
  &:hover {
    color: ${COLORS.Purple};
    .material-symbols-outlined {
      transform: translateX(-2px);
    }
    .goBack {
      text-decoration: underline;
      color: ${COLORS.Purple};
    }
  }
`;
export const CardBlock = styled.div<ParticipantOverflowWrapperType>`
  background: ${COLORS.White};
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};
  margin: ${rem(12)} ${rem(0)} ${rem(44)} ${rem(0)};
  border-bottom: 1px solid ${COLORS.OffWhite};
  overflow-y: scroll !important;

  ${(props) => {
    return css`
      min-height: ${props?.height != null ? props.height : `${rem(550)}`};
      overflow-y: ${props?.hasOverFlow != null && props?.hasOverFlow ? 'initial' : 'auto'};
    `;
  }}

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.SpanishGray};
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ParticipantCard = styled.div`
  padding: ${rem(0)} ${rem(12)} ${rem(42)} ${rem(8)};
  margin-top: ${rem(32)} 0 ${rem(12)} 0;

  .cardTagCount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .card-form {
    padding: ${rem(28)} ${rem(12)} ${rem(0)} ${rem(24)};

    .employeeAddress {
      padding-bottom: ${rem(12)};
    }
    .otherInfo {
      padding: ${rem(16)} 0;
    }
  }
`;

export const ResetFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${rem(6)};
  margin-top: ${rem(8)};
  gap: ${rem(2)};
  width: 100%;
  cursor: pointer;
  transition: all 0.2s linear;
  &,
  & span {
    color: ${COLORS.Gray};
  }

  &:hover,
  &:hover span {
    color: ${COLORS.Purple};
  }
`;

export const PasswordDetailsContainer = styled.div`
  padding: ${rem(16)};
  background-color: ${COLORS.OffWhite};
  border: ${rem(1)} solid ${COLORS.LightGray};
  border-radius: ${rem(6)};

  .icon {
    color: ${COLORS.Red};
  }

  .icon-success {
    color: ${COLORS.Green};
  }
`;

export const PageNotFoundWrapper = styled.div`
  /* background-color: red; */
  height: ${getViewHeight('101px')};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-size: ${rem(127)};
    font-weight: 400;
    color: ${COLORS.LightGray};
    margin-bottom: ${rem(35)};
  }

  .page-btn {
    @include ${SecondaryButton(COLORS.Iris)};
  }

  h3 {
    color: ${COLORS.Gray};
    /* font-weight: 600; */
    margin-bottom: ${rem(12)};
  }

  h5 {
    color: ${COLORS.Gray};
    margin-bottom: ${rem(24)};
    font-weight: 400;
  }
`;
