import { Form, Formik } from 'formik';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
import { getFilteredPermissionData } from '../../../utils';
import { ROLES_VALIDATION } from '../../../utils/validations/validations';
import { type ModalStatusType } from '../../../types/common';
import { CheckBoxFlex, FieldLevelPermissionsGroups, ListWrapper } from './roles.styled';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../configuration/routeSlice';
import Tag from '../../../components/lozenge/tags';
import { HeadingMain } from '../../../constants/style/common.styled';
import Heading from '../../../components/typography';
import CustomTextArea from '../../../components/text-area/textArea';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import {
  ROLES_PERMISSION_LIST,
  SORTED_FIELD_PERMISSION,
  SORTED_MODULE_PERMISSION,
} from '../../../constants/common';
import { handleFilterPermissionList, handleSortPermissionNames } from '../../../utils/global';
import { usePostSystemRoles, useWealthlaneCustomerApi } from '../../../hooks';
import ModalBody from '../../../components/modal/modalBodyComponent';
import _ from 'lodash';
import useGetCustomerUserDetails from '../../../hooks/get/useGetCustomerUserDetails';

interface RoleModalType {
  isModalOpen: boolean;
  closeModal: () => void;
  modalStatus: ModalStatusType;
  setModalStatus: (value: ModalStatusType) => void;
  setRolesInitialData: () => void;
  rolesInitialData: { data: any; id: string };
  loading: boolean;
}

const RoleModal = ({
  isModalOpen,
  closeModal,
  modalStatus,
  rolesInitialData,
  setModalStatus,
  setRolesInitialData,
  loading,
}: RoleModalType): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data: customerDetail } = useGetCustomerUserDetails(handleRequest);

  const userAuthList = useSelector(selectAuthRoutesList);
  const { nonFieldLevelPermissionsGroups, fieldLevelPermissionsGroups } = getFilteredPermissionData(
    userAuthList.auth?.policies,
    'CustomerService.Customer.'
  );
  const allFieldValues = userAuthList.localization?.values?.Wealthlane;
  const filterNonFieldLevelPermission = handleSortPermissionNames(
    handleFilterPermissionList(nonFieldLevelPermissionsGroups),
    SORTED_MODULE_PERMISSION
  );
  const filterFieldLevelPermission = handleSortPermissionNames(
    handleFilterPermissionList(fieldLevelPermissionsGroups),
    SORTED_FIELD_PERMISSION
  );

  const { mutate, isLoading } = usePostSystemRoles(handleRequest, closeModal, rolesInitialData.id);
  const handleSubmit = (values: any): void => {
    delete values.permissions['CustomerService-Customer'];
    delete values.fieldLevelPermissions['CustomerService-Customer-FieldLevel'];
    const payload = {
      ...values,
      permissions: Object.keys(values?.permissions)
        .filter((x) => values.permissions[x] !== false)
        .map((item) => item.replaceAll('-', '.')),
      fieldLevelPermissions: Object.keys(values?.fieldLevelPermissions)
        .filter((x) => values.fieldLevelPermissions[x] !== false)
        .map((item) => item.replaceAll('-', '.')),
    };
    mutate(payload);
  };

  const handleCheckedAll = (
    key: string,
    setFieldValue: any,
    dataList: any,
    wrapperName: string,
    isCheckedAll: boolean
  ): void => {
    const allFields = handleFilterPermissionList(dataList)[key];
    const filterAllDisabledData = allFields.filter(
      (item: any) => !(item.isDisabled as boolean) && item
    );

    if (isCheckedAll) {
      allFields.forEach((item: any) =>
        setFieldValue(`${wrapperName}[${String(item.key.replaceAll('.', '-') ?? '')}]`, false)
      );
    } else {
      filterAllDisabledData.forEach((item: any) =>
        setFieldValue(`${wrapperName}[${String(item.key.replaceAll('.', '-') ?? '')}]`, true)
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={rolesInitialData.data}
      validationSchema={ROLES_VALIDATION}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({ touched, handleChange, setFieldValue, errors, values }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={() => {
              closeModal();
              setRolesInitialData();
            }}
            loading={loading}
            size='lg'
            valueEdited={_.isEqual(values, rolesInitialData?.data)}
            title={`${
              modalStatus === 'View'
                ? String(rolesInitialData.data.roleName ?? '')
                : `${modalStatus} System Role`
            }`}
            showToolTip={true}
            tooltipText={`Permissions allow a company to control its users’ access levels to the module and its fields depending on their roles and functions. Users can have multiple levels of access such as creating and editing a module, to read-only or none at all.`}
            children={
              <Form>
                <ModalBody>
                  <div className='grid sm:grid-cols-4 form-gap'>
                    <div className='sm:col-span-3'>
                      <InputText
                        type='text'
                        label='Role Name'
                        name='roleName'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        inputType={modalStatus}
                        disabled={modalStatus === 'View'}
                        required
                      />
                    </div>
                    <div className='sm:col-span-3'>
                      <CustomTextArea
                        label='Description'
                        name='description'
                        onChange={handleChange}
                        errors={errors}
                        inputType={modalStatus}
                        disabled={modalStatus === 'View'}
                        touched={touched}
                        rows='5'
                      />
                    </div>
                  </div>
                  <div className='table-wrapper'>
                    <div className='grid grid-cols-12 gap-x-5'>
                      <div className='col-span-5'>
                        <Tag variant='info' title='Module Level Permission List' />
                      </div>
                      <div className='col-span-7 grid grid-cols-12'>
                        <Heading
                          variant='body-xs'
                          title='All'
                          className='col-span-2 roleSubTitle'
                        />
                        {ROLES_PERMISSION_LIST.map((col, i) => (
                          <Heading
                            key={`header-table-${i}`}
                            variant='body-xs'
                            title={col}
                            className='col-span-2 roleSubTitle'
                          />
                        ))}
                      </div>
                    </div>
                    {Object.keys(filterNonFieldLevelPermission).map((data: string) => {
                      const allInfo = filterNonFieldLevelPermission[data].filter(
                        (item: any) => !(item.isDisabled as boolean) && item
                      );

                      const checkRoles = allInfo.map(
                        (item: any) =>
                          !!(values.permissions[item.key.replaceAll('.', '-')] as boolean)
                      );

                      const labelKey = Boolean(allInfo) && allInfo.length > 0 && allInfo[0].key;
                      return (
                        <ListWrapper className='grid grid-cols-12 gap-x-5 items-center'>
                          <HeadingMain className='col-span-5'>
                            {(allFieldValues as boolean) ? allFieldValues[labelKey] : '-'}
                          </HeadingMain>
                          <CheckBoxFlex className='col-span-7 grid grid-cols-12'>
                            <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                              <CustomCheckBox
                                name={`nonFieldPermissions${data}All`}
                                onChange={() => {
                                  handleCheckedAll(
                                    data,
                                    setFieldValue,
                                    nonFieldLevelPermissionsGroups,
                                    'permissions',
                                    checkRoles.every((v: boolean) => v)
                                  );
                                }}
                                errors={errors}
                                touched={touched}
                                disabled={modalStatus === 'View'}
                                checked={checkRoles.every((v: boolean) => v)}
                              />
                            </div>
                            {ROLES_PERMISSION_LIST.map((item, index) => {
                              const itemData = handleFilterPermissionList(
                                nonFieldLevelPermissionsGroups
                              )[`${data}`][index];
                              return (
                                <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                  <CustomCheckBox
                                    name={`permissions[${String(
                                      itemData.key.replaceAll('.', '-') ?? ''
                                    )}]`}
                                    onChange={(e: any) => {
                                      if (
                                        !(itemData.key.includes('View') === true) &&
                                        e.target.checked === true
                                      ) {
                                        const newValue = itemData.key
                                          .replaceAll('.', '-')
                                          .split('-');

                                        const filterValue = newValue
                                          .filter(
                                            (item: string, index: number) =>
                                              index !== newValue.length - 1 && item
                                          )
                                          .join('-');
                                        setFieldValue(
                                          `permissions[${String(filterValue ?? '')}]`,
                                          true
                                        );
                                      }

                                      setFieldValue(
                                        `permissions[${String(
                                          itemData.key.replaceAll('.', '-') ?? ''
                                        )}]`,
                                        e.target.checked
                                      );
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    id={itemData.key}
                                    arrValue={
                                      item === itemData.text &&
                                      values.permissions[itemData.key.replaceAll('.', '-')]
                                    }
                                    disabled={
                                      modalStatus === 'View' ||
                                      !(itemData.key.length as boolean) ||
                                      itemData.isDisabled
                                    }
                                  />
                                </div>
                              );
                            })}
                          </CheckBoxFlex>
                        </ListWrapper>
                      );
                    })}
                  </div>
                  <FieldLevelPermissionsGroups>
                    <div className='grid grid-cols-12 gap-x-5 roleTitle'>
                      <div className='col-span-5'>
                        <Tag variant='info' title='Field Level Permission' />
                      </div>
                    </div>
                    {Object.keys(filterFieldLevelPermission).map((data: string) => {
                      const allInfo = filterFieldLevelPermission[data].filter(
                        (item: any) => !(item.isDisabled as boolean) && item
                      );

                      const checkRoles = allInfo.map(
                        (item: any) =>
                          !!(values.fieldLevelPermissions[item.key.replaceAll('.', '-')] as boolean)
                      );
                      const labelKey = Boolean(allInfo) && allInfo.length > 0 && allInfo[0].key;
                      return (
                        <ListWrapper className='grid grid-cols-12 gap-x-5 items-center'>
                          <HeadingMain className='col-span-5'>
                            {(allFieldValues as boolean) ? allFieldValues[labelKey] : '-'}
                          </HeadingMain>
                          <CheckBoxFlex className='col-span-7 grid grid-cols-12'>
                            <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                              <CustomCheckBox
                                name={`fieldLevelPermissions${data}All`}
                                onChange={() => {
                                  handleCheckedAll(
                                    data,
                                    setFieldValue,
                                    fieldLevelPermissionsGroups,
                                    'fieldLevelPermissions',
                                    checkRoles.every((v: boolean) => v)
                                  );
                                }}
                                errors={errors}
                                touched={touched}
                                disabled={modalStatus === 'View'}
                                checked={checkRoles.every((v: boolean) => v)}
                              />
                            </div>
                            {ROLES_PERMISSION_LIST.map((item, index) => {
                              const itemData = handleFilterPermissionList(
                                fieldLevelPermissionsGroups
                              )[`${data}`][index];
                              return (
                                <div className='col-span-2 justify-center CheckboxCenterInRoles'>
                                  <CustomCheckBox
                                    name={`fieldLevelPermissions[${String(
                                      itemData.key.replaceAll('.', '-') ?? ''
                                    )}]`}
                                    onChange={(e: any) => {
                                      if (
                                        !(itemData.key.includes('View') === true) &&
                                        e.target.checked === true
                                      ) {
                                        const newValue = itemData.key
                                          .replaceAll('.', '-')
                                          .split('-');

                                        const filterValue = newValue
                                          .filter(
                                            (item: string, index: number) =>
                                              index !== newValue.length - 1 && item
                                          )
                                          .join('-');
                                        setFieldValue(
                                          `fieldLevelPermissions[${String(filterValue ?? '')}]`,
                                          true
                                        );
                                      }

                                      setFieldValue(
                                        `fieldLevelPermissions[${String(
                                          itemData.key.replaceAll('.', '-') ?? ''
                                        )}]`,
                                        e.target.checked
                                      );
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    id={itemData.key}
                                    arrValue={
                                      item === itemData.text &&
                                      values.fieldLevelPermissions[
                                        itemData.key.replaceAll('.', '-')
                                      ]
                                    }
                                    disabled={
                                      modalStatus === 'View' ||
                                      !(itemData.key.length as boolean) ||
                                      itemData.isDisabled
                                    }
                                  />
                                </div>
                              );
                            })}
                          </CheckBoxFlex>
                        </ListWrapper>
                      );
                    })}
                  </FieldLevelPermissionsGroups>
                </ModalBody>
                <ModalFooterComponent
                  modalStatus={modalStatus}
                  onCloseModal={() => {
                    closeModal();
                    setRolesInitialData();
                  }}
                  onEditModal={() => {
                    setModalStatus('Edit');
                  }}
                  loading={isLoading}
                  valueChanged={_.isEqual(values, rolesInitialData?.data)}
                  isEditShow={(rolesInitialData.data.isEditable as boolean) || false}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default RoleModal;
