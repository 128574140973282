/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import AlertPopup from '../../../components/alert-popup';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import Button from '../../../components/button';
import Table from '../../../components/table';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import { TradingBlackoutConfigurationHeader } from '../../../constants/table-headers/tradingBlackoutConfigurationHeader';
import {
  useAdvanceBoolean,
  useBoolean,
  useGetAllDepartments,
  useGetAllEmployeeType,
  useGetAllJobTitles,
  useGetTradingBlackoutConfigList,
  useGetTradingBlackoutConfigView,
  useWealthlaneCustomerApi,
} from '../../../hooks';
import {
  BlackoutService,
  type Wealthlane_CustomerService_Dtos_Blackout_BlackoutListDTO,
} from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import {
  SettingSystemUserButton,
  SettingSystemUserHeader,
  SystemUserContainer,
} from '../system-users/systemUserStyled';
import TradingBlackoutConfigurationModel from './tradingBlackoutConfigurationModel';

const TradingBlackoutConfiguration = (): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const queryClient = useQueryClient();
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [initialBlackoutConfig, setInitialBlackoutConfig] = useState(
    InitialValuesForm.tradingBlackoutConfig
  );
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
    setCustomValue,
    customValue,
  } = useAdvanceBoolean(false);

  const { data, status } = useGetTradingBlackoutConfigList(handleRequest, tableInfo);
  const { data: departmentOptions } = useGetAllDepartments(handleRequest);
  const { data: employeeTypeOptions } = useGetAllEmployeeType(handleRequest);
  const { data: jobTitleOptions } = useGetAllJobTitles(handleRequest);
  const { mutate: getBlackoutConfigView, isLoading: viewLoader } = useGetTradingBlackoutConfigView(
    handleRequest,
    setInitialBlackoutConfig
  );

  const actions = [
    {
      action: 'View',
      as: 'Create',
      onClick: (id: string, rowData: Wealthlane_CustomerService_Dtos_Blackout_BlackoutListDTO) => {
        setModalStatus('View');
        rowData.blackoutId != null &&
          rowData.blackoutId !== '' &&
          getBlackoutConfigView({ id: rowData.blackoutId, modalStatus: 'Add' });
        openModal();
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string, rowData: Wealthlane_CustomerService_Dtos_Blackout_BlackoutListDTO) => {
        setModalStatus('Edit');
        rowData.blackoutId != null &&
          rowData.blackoutId !== '' &&
          getBlackoutConfigView({ id: rowData.blackoutId, modalStatus: 'Edit' });
        openModal();
      },
    },
    {
      action: 'Repurpose',
      as: 'Create',
      onClick: (id: string, rowData: Wealthlane_CustomerService_Dtos_Blackout_BlackoutListDTO) => {
        setModalStatus('Add');
        rowData.blackoutId != null &&
          rowData.blackoutId !== '' &&
          getBlackoutConfigView({ id: rowData.blackoutId, modalStatus: 'Repurpose' });
        openModal();
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      onClick: async (id: string, rowData: any) => {
        setCustomValue(rowData?.blackoutId);
        setModalStatus('Delete');
        openConfirmModal();
      },
    },
  ];
  const useDeleteTradingBlackoutConfig = (): any => {
    return useMutation(
      async () => {
        return await handleRequest(
          BlackoutService.deleteApiCustomerApiAppBlackoutBlackout(customValue)
        );
      },
      {
        onSuccess: (response: any) => {
          if (response != null) {
            closeConfirmModal();
            setCustomValue('');
            toast.success(
              <ToastComponent
                label={'Success'}
                message={'Trading blackout configuration deleted.'}
              />
            );
            void queryClient.invalidateQueries('getTradingBlackoutConfigList');
          }
        },
        onError: (err) => {
          console.log('error', err);
        },
      }
    );
  };
  const { mutate, isLoading } = useDeleteTradingBlackoutConfig();

  return (
    <SystemUserContainer>
      <div className='flex justify-between items-center'>
        <SettingSystemUserHeader>
          <Heading variant='h3' title='Trading Blackout Configuration' />
          <Heading
            variant='body-s'
            title='Secure your account & manage your password.'
            color={`${COLORS.Gray}`}
          />
        </SettingSystemUserHeader>
        <SettingSystemUserButton className='xl:col-span-2 flex justify-start lg:justify-end'>
          <Button
            // as='Create'
            variant={'primary'}
            title={'Add Blackout'}
            icon={'add_circle_outline'}
            onClick={() => {
              setModalStatus('Add');
              openModal();
            }}
          />
        </SettingSystemUserButton>
      </div>

      <AnimationWrapper>
        {isModalOpen && (
          <TradingBlackoutConfigurationModel
            isModalOpen={isModalOpen}
            closeModal={() => {
              closeModal();
              setInitialBlackoutConfig({
                ...InitialValuesForm.tradingBlackoutConfig,
                blackoutGroups: [
                  {
                    id: null,
                    groupData: false,
                    customerDepartmentId: null,
                    employeeTypeId: null,
                    blackOutJobTitles: [],
                  },
                ],
              });
            }}
            modalStatus={modalStatus}
            loading={viewLoader}
            setModalStatus={setModalStatus}
            initialBlackoutConfig={initialBlackoutConfig}
            departmentOptions={departmentOptions}
            employeeTypeOptions={employeeTypeOptions}
            jobTitleOptions={jobTitleOptions}
          />
        )}
      </AnimationWrapper>
      <Table
        columns={TradingBlackoutConfigurationHeader(actions)}
        data={data?.items ?? []}
        loading={status}
        showPagination
        totalCounts={data?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
      {isConfirmOpen && (
        <AlertPopup
          isShow={isConfirmOpen}
          handleSubmit={() => mutate(customValue)}
          closeModal={closeConfirmModal}
          modalStatus={modalStatus}
          title={'this trading blackout configuration'}
          loading={isLoading}
        />
      )}
    </SystemUserContainer>
  );
};

export default TradingBlackoutConfiguration;
