import { type TableParamsType } from '../../types/common';
import { type UseQueryResult, useQuery } from 'react-query';

import {
  ReleaseService,
  // eslint-disable-next-line camelcase
  Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_GetReleaseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';

const useGetTransactionsReleaseList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  filter:TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_GetReleaseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    filter: searchKeyWord,
    sorting,
    skipCount,
    maxResultCount,
    name
  } = tableInfo;
  const {releaseDate:expectedRelseaseDate,statusId:releaseStatusId}= filter;
  const transactionsReleaseList = useQuery(
    ['getTransactionsReleaseList', searchKeyWord, sorting, skipCount, maxResultCount,expectedRelseaseDate,releaseStatusId],
    async () => {
      return await handleRequest(
        ReleaseService.getApiAwardApiAppReleaseReleaseList(
          sorting,
          skipCount,
          maxResultCount,
          searchKeyWord,
          name,
          expectedRelseaseDate,
          releaseStatusId
        )
      );
    },
    {
      select: (data) => {
        if (data != null) {
          return data;
        }
      },
    }
  );

  return transactionsReleaseList;
};

export default useGetTransactionsReleaseList;
