// import { COLORS } from '../../constants/style/Color';

import { COLORS } from '../../constants/style/color';
import Heading from '../typography';
import { NoData } from './noDataFound.styled';

interface NoDataFoundType {
  message?: string;
  icon?: string;
}

const NoDataFound = ({ message, icon }: NoDataFoundType): JSX.Element => {
  return (
    <NoData className='flex flex-col items-center justify-center h-full'>
      <span className='material-symbols-outlined icon'>info</span>
      {message != null && <Heading variant='body' title={message} color={`${COLORS.Gray}`} />}
    </NoData>
  );
};

export default NoDataFound;
