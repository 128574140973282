import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ReportTablePreferenceService } from '../../services/wealthlane-report-services';

const usePutReportTablePreferences = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<boolean | undefined, unknown, any, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        ReportTablePreferenceService.putApiReportApiAppReportTablePreferenceTablePreferences(
          payload
        )
      );
    },
    {
      onSuccess: async (response) => {
        if (response ?? false) {
          await queryClient.invalidateQueries('getReportTablePreferences');
        }
      },
    }
  );
};

export default usePutReportTablePreferences;
