import { isEmpty } from 'lodash';
import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerUserApprovalService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-customer-services';
import { type TableParamsType } from '../../types/common';

const useGetCustomerUserApprovalList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
  // isEnabled?: boolean
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerUser_CustomerUserDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    approvalStatusId,
    userStatus: isEnabled,
    filter,
    sorting,
    skipCount,
    maxResultCount,
  } = tableInfo;
  // const { filter, sorting, skipCount, maxResultCount } = tableInfo;

  const getCustomerUserCustomerUserApprovalList = useQuery(
    [
      'getCustomerUserCustomerUserApprovalList',
      approvalStatusId,
      isEnabled,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      // if (status === 5) {
      //   status = undefined;
      // }
      return await handleRequest(
        CustomerUserApprovalService.getApiCustomerApiAppCustomerUserApprovalCustomerUserList(
          !isEmpty(approvalStatusId) ? approvalStatusId : [0],
          isEnabled ? isEnabled : [true],
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
    // {
    //   keepPreviousData: true,
    // }
  );
  return getCustomerUserCustomerUserApprovalList;
};

export default useGetCustomerUserApprovalList;
