/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import {
  CustomerCompanyService,
  type Wealthlane_CustomerService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-customer-services';

const usePostKeysContacts = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void
): UseMutationResult<
  Wealthlane_CustomerService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        CustomerCompanyService.postApiCustomerApiAppCustomerCompanySaveKeyContact(payload)
      );
    },
    {
      onSuccess: async (
        response: Wealthlane_CustomerService_Dtos_Response_ResponseDto | undefined
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries('getCompanyKeyContactTypes');
          await queryClient.invalidateQueries('getCompanyKeyContacts');
          closeModal();
          toast.success(<ToastComponent label='Success' message='Contacts Updated!' />);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostKeysContacts;
