/* eslint-disable camelcase */
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  CompanyBankService,
  type Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-finance-integration-services';

const usePostBankAccountManualToken = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  openVerificationModal: any,
  setMainLoading: any
): UseMutationResult<
  Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        CompanyBankService.postApiFinanceIntegrationApiAppCompanyBankVerifyManualBankAccount(
          payload
        )
      );
    },
    {
      onSuccess: async (
        response: Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto | undefined
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries('getCompanyBankToken');
          toast.success(<ToastComponent label='Success' message='Bank Account Verified!' />);
          openVerificationModal();
          setMainLoading(false);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostBankAccountManualToken;
