import { type UseQueryResult, useQuery } from 'react-query';
import {
  RsuElectionWindowConfigurationService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto,
} from '../../services/wealthlane-award-settlement-services';

const useGetRSUElectionWindowConfiguration = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const getRSUElectionWindowConfigurationList = useQuery(
    ['getRSUElectionWindowConfigurationList'],
    async () => {
      return await handleRequest(
        RsuElectionWindowConfigurationService.getApiAwardSettlementApiAppRSUElectionWindowConfigurationRSUElectionWindowConfigurations()
      );
    },
    {
      cacheTime: 1000 * 60 * 5,
    }
  );
  return getRSUElectionWindowConfigurationList;
};

export default useGetRSUElectionWindowConfiguration;
