/* eslint-disable camelcase */
import { UseQueryResult, useQuery } from 'react-query';
import { type TableParamsType } from '../../types/common';
import {
  TaxItemService,
  Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto,
} from '../../services/wealthlane-tax-services';
import { CommaFormatted } from '../../utils/global';
import InitialValuesForm from '../../constants/initialValues';

const useGetTaxItemViewForWealthlane = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  itemId?: string
): UseQueryResult<Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto | undefined, unknown> => {
  const taxItemViewFromWealthlane = useQuery(['getTaxItemViewFromWealthlane', itemId], async () => {
    if (itemId) {
      return await handleRequest(
        TaxItemService.getApiTaxApiAppTaxItemVendorTaxItemByIdForCompany(itemId)
      ).then((response: Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto | any) => {
        if (response != null) {
          const sortedTaxBracket = response?.taxBrackets?.sort(
            (a: any, b: any) => Number(a.lowerLimit) - Number(b.lowerLimit)
          );
          const userDefined =
            response?.userDefinedCriteria !== null
              ? JSON.parse(String(response?.userDefinedCriteria?.replaceAll('/', '')))
              : null;

          const payload = {
            ...response,
            taxBrackets: sortedTaxBracket?.map((a: any, i: any) => ({
              ...a,
              lowerLimit: CommaFormatted(a?.lowerLimit),
              upperLimit:
                sortedTaxBracket[i + 1]?.lowerLimit !== undefined
                  ? CommaFormatted(sortedTaxBracket[i + 1]?.lowerLimit)
                  : '',
            })),
            userDefinedCriteria:
              response?.userDefinedCriteria !== null
                ? userDefined
                : InitialValuesForm?.taxSetup?.userDefinedCriteria,
          };
          return payload;
        }
      });
    }
  });

  return taxItemViewFromWealthlane;
};

export default useGetTaxItemViewForWealthlane;
