import { type UseQueryResult, useQuery } from 'react-query';
import { type OptionType } from '../../types/common';
import { TaskStatusService } from '../../services/wealthlane-task-manager-services';

const useGetTmcStatus = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  OptionType[] | undefined,
  unknown
> => {
  const getTmcStatus = useQuery(
    ['getTmcStatus'],
    async () => {
      return await handleRequest(
        TaskStatusService.getApiTaskmanagerApiAppTaskStatusTaskStatusList()
      );
    },

    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item?.taskStatusDisplayName ?? '',
            value: item?.taskStatusId ?? '',
          };
        });
      },
    }
  );

  return getTmcStatus;
};

export default useGetTmcStatus;
