/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionAward_OptionAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionAward_OptionAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto } from '../models/Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto';
import type { Wealthlane_AwardService_Dtos_OptionAwardApproval_OptionAwardApprovalDto } from '../models/Wealthlane_AwardService_Dtos_OptionAwardApproval_OptionAwardApprovalDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OptionAwardApprovalService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardApprovalApproveOptionAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_OptionAwardApproval_OptionAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award-approval/approve-option-awards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardApprovalRejectOptionAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_OptionAwardApproval_OptionAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award-approval/reject-option-awards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param approvalStatusIds 
     * @param fromDate 
     * @param toDate 
     * @param createdFromDate 
     * @param createdToDate 
     * @param employeeStatuses 
     * @param optionStatus 
     * @param optionAcceptance 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionAward_OptionAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardApprovalOptionAwardList(
approvalStatusIds?: Array<number>,
fromDate?: string,
toDate?: string,
createdFromDate?: string,
createdToDate?: string,
employeeStatuses?: Array<number>,
optionStatus?: number,
optionAcceptance?: number,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_OptionAward_OptionAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award-approval/option-award-list',
            query: {
                'ApprovalStatusIds': approvalStatusIds,
                'FromDate': fromDate,
                'ToDate': toDate,
                'CreatedFromDate': createdFromDate,
                'CreatedToDate': createdToDate,
                'EmployeeStatuses': employeeStatuses,
                'OptionStatus': optionStatus,
                'OptionAcceptance': optionAcceptance,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardApprovalOptionAwardOverview(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award-approval/option-award-overview/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardApprovalOptionAwardVestingSchedule(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award-approval/option-award-vesting-schedule/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppOptionAwardApprovalOptionAwardDocuments(
optionAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/option-award-approval/option-award-documents/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardApprovalDownloadDocument(
requestBody?: Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award-approval/download-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardApprovalApproveBulkOption(
requestBody?: Array<Wealthlane_AwardService_Dtos_OptionAwardApproval_OptionAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award-approval/approve-bulk-option',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppOptionAwardApprovalRejectBulkOption(
requestBody?: Array<Wealthlane_AwardService_Dtos_OptionAwardApproval_OptionAwardApprovalDto>,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/option-award-approval/reject-bulk-option',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
