import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerCompanyService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto,
} from '../../services/wealthlane-customer-services';

const useGetCompanyKeyContacts = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_CustomerKeyContact_CustomerKeyContactDto[] | undefined,
  unknown
> => {
  const companyKeyContacts = useQuery(
    ['getCompanyKeyContacts'],
    async () => {
      return await handleRequest(
        CustomerCompanyService.getApiCustomerApiAppCustomerCompanyKeyContacts()
      );
    },
    {
      cacheTime: 1000 * 60 * 5,
    }
  );

  return companyKeyContacts;
};

export default useGetCompanyKeyContacts;
