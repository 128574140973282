import { type UseQueryResult, useQuery } from 'react-query';
import {
  PerformanceRuleApprovalService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRulesByIdDto,
} from '../../services/wealthlane-award-services';

const useGetPerformanceRuleByIdApproval = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRulesByIdDto | undefined,
  unknown
> => {
  const performanceRuleByIdApproval = useQuery(
    ['getPerformanceRuleByIdApproval'],
    async () => {
      if (id) {
        return await handleRequest(
          PerformanceRuleApprovalService.getApiAwardApiAppPerformanceRuleApprovalApprovalPerformanceRulesById(
            id
          )
        );
      }
    }
    // {
    //   enabled: !(id.length === 0),
    // }
  );

  return performanceRuleByIdApproval;
};

export default useGetPerformanceRuleByIdApproval;
