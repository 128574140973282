import { useMutation, useQueryClient } from 'react-query';
import { CustomerApprovalProcessService } from '../../services/wealthlane-customer-services';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';

const useSubmitApprovalConfig = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  isCurrentUser: boolean
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        CustomerApprovalProcessService.putApiCustomerApiAppCustomerApprovalProcessCustomerApprovalProcess(
          payload
        )
      );
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          void queryClient.resetQueries('getAllCustomerApprovalProcess');
          toast.success(<ToastComponent label='Success' message='System approvers saved' />);
          void queryClient.invalidateQueries('getRoles');
          void queryClient.invalidateQueries('getAllCustomerApprovalProcess');
          void queryClient.invalidateQueries('getCustomerUserCustomerUserList');
          void queryClient.invalidateQueries('getApprovalProcessList');

          if (isCurrentUser) {
            window.dispatchEvent(new CustomEvent('PERMISSION_CHANGED', { detail: 'RELOAD' }));
          }
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useSubmitApprovalConfig;
