import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerRoleService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-customer-services';
import { type TableParamsType } from '../../types/common';

const useGetRoleList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_CustomerRole_CustomerRoleListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;
  const rolesData = useQuery(['getRoles', filter, sorting, skipCount, maxResultCount], async () => {
    return await handleRequest(
      CustomerRoleService.getApiCustomerApiAppCustomerRoleCustomerRole(
        filter,
        sorting,
        skipCount,
        maxResultCount
      )
    );
  });

  return rolesData;
};

export default useGetRoleList;
