import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ToastComponent from '../components/toast';
import { selectToken } from '../pages/auth/authSlice';
import { OpenAPI } from '../services/wealthlane-identity-services';

export function useWealthlaneIdentityOtpApi(): {
  dismissError: () => void;
  error: undefined;
  isLoading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
} {
  const token = useSelector(selectToken);
  const [error, setError] = useState<undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const OtpToken: any = sessionStorage.getItem('OtpToken');

  OpenAPI.BASE = process.env.REACT_APP_WEALTHLANE_API_ENDPOINT ?? '';
  OpenAPI.TOKEN = token?.toString();
  OpenAPI.HEADERS = {
    [`x-multipurpose-token`]: OtpToken,
  };

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsLoading(true);
    try {
      const response = await request;
      setError(undefined);

      return response;
    } catch (exception: any) {
      setError(exception);
      if (exception?.body?.error?.validationErrors != null) {
        const error = exception?.body?.error?.validationErrors?.map((error: any) => {
          return error.message;
        });
        if (
          error.includes('OTP is invalid') === true ||
          error.includes('Invalid Template') === true
        ) {
          //
        } else {
          toast.error(<ToastComponent label='Error' message={`${String(error)}`} />, {
            toastId: 1,
          });
        }
      } else if (exception?.body?.error != null) {
        toast.error(
          <ToastComponent
            label='Error'
            message={`${String(exception?.body?.error?.message ?? '')}`}
          />
        );
      } else if (
        exception.status === 401 ||
        (exception.status === 403 && isEmpty(exception?.body))
      ) {
        //
      } else {
        toast.error(<ToastComponent label='Error' message={'Unable to fetch data'} />, {
          toastId: 2,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  function dismissError(): void {
    setError(undefined);
  }

  return { dismissError, error, isLoading, handleRequest };
}

export default useWealthlaneIdentityOtpApi;
