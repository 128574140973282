import { type UseQueryResult, useQuery } from 'react-query';
import { CustomerUserService } from '../../services/wealthlane-customer-services';

const useGetCustomerUserList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
      department: string;
      title: string;
      isDefaultApprover: boolean;
      userId: string;
    }>
  | undefined,
  unknown
> => {
  const customerUserList = useQuery(
    ['getCustomerUserList'],
    async () => {
      return await handleRequest(
        CustomerUserService.getApiCustomerApiAppCustomerUserCustomerUsers()
      );
    },
    {
      keepPreviousData: true,
      select: (data) => {
        if (data != null) {
          return data?.map((item) => {
            return {
              label: item.fullName ?? '',
              value: item.customerUserId ?? '',
              department: item.customerDepartment ?? '',
              title: item.customerTitle ?? '',
              isDefaultApprover: item?.isDefaultApprover ?? false,
              userId: item.abpUserId ?? '',
            };
          });
        }
      },
    }
  );
  return customerUserList;
};

export default useGetCustomerUserList;
