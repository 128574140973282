// import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import AlertPopup from '../../../components/alert-popup';
import Button from '../../../components/button';
import FormSelect from '../../../components/dropdown/formSelect';
import Filter from '../../../components/filter';
import Table from '../../../components/table';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import { ResetFilterWrapper } from '../../../constants/style/common.styled';
import { udfHeader } from '../../../constants/table-headers/udfHeader';
import {
  useBoolean,
  useGetUdfById,
  useGetUdfModules,
  useGetUdfStatus,
  useGetUserDefinedFieldsList,
  useWealthlaneCustomerApi,
} from '../../../hooks';
import { UserDefinedFieldsService } from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { SettingSystemUserButton, SettingSystemUserHeader } from '../system-users/systemUserStyled';
import UdfModel from './udfModel';

const UserDefineFields = (): JSX.Element => {
  const queryClient = useQueryClient();

  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmModalOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);

  const { isLoading, handleRequest, error } = useWealthlaneCustomerApi();

  const { data: statusOptions } = useGetUdfStatus(handleRequest);
  const { data: moduleOptions } = useGetUdfModules(handleRequest);

  const [selectedUdfItem, setSelectedUdfItem] = useState('');

  const [initialUdfState, setInitialUdfState] = useState<any>(InitialValuesForm.udfInitialForm);
  const [modalStatus, setModalStatus] = useState<ModalStatusType>('Add');
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);

  const { data: UserDefinedList, status } = useGetUserDefinedFieldsList(handleRequest, tableInfo);
  const { mutate } = useGetUdfById(handleRequest, openModal, setInitialUdfState);
  const [open, setOpen] = useState(false);

  const actions = [
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string) => {
        setModalStatus('Edit');
        mutate(id);
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      onClick: (id: string) => {
        setModalStatus('Delete');
        setSelectedUdfItem(id);
        openConfirmModal();
      },
    },
  ];

  const handleDeleteUdf = (): void => {
    void handleRequest(
      UserDefinedFieldsService.deleteApiCustomerApiAppUserDefinedFields(selectedUdfItem)
    ).then((res) => {
      if (res != null) {
        void queryClient.invalidateQueries('getUserDefinedFieldsList');
        toast.success(<ToastComponent label='Success' message='UDF deleted.' />);
        closeConfirmModal();
      }
    });
  };

  return (
    <>
      <SettingSystemUserHeader>
        <Heading variant='h3' title='User Defined Fields' />
        <Heading variant='body-s' title={`Create Custom Fields`} color={`${COLORS.Gray}`} />
      </SettingSystemUserHeader>
      <div className='flex flex-col lg:flex-row justify-between '>
        <div className='flex gap-x-5'>
          <div className=''>
            <Filter setOpen={setOpen} open={open}>
              <Heading variant='body-xs' title={'Filter By:'} className={'filter-label'} />
              <div className='grid sm:grid-cols-2 gap-x-5'>
                <FormSelect
                  type='text'
                  name='moduleId'
                  placeholder='Module'
                  options={moduleOptions}
                  values={tableInfo.moduleId}
                  arrValue={tableInfo.moduleId}
                  onChange={(value: number) => {
                    setTableInfo({ ...tableInfo, moduleId: value });
                  }}
                  containerClassName='formGroup'
                  isFormik={false}
                />
                <FormSelect
                  type='text'
                  name='statusId'
                  placeholder='Status'
                  options={statusOptions}
                  values={tableInfo.statusId}
                  arrValue={tableInfo.statusId}
                  onChange={(value: number) => {
                    setTableInfo({ ...tableInfo, statusId: value });
                  }}
                  containerClassName='formGroup'
                  isFormik={false}
                  required
                />
              </div>
            </Filter>
          </div>
          <div className='flex items-start'>
            {/* <FormSelect
            type='text'
            name='moduleId'
            placeholder='Module'
            options={moduleOptions}
            values={tableInfo.moduleId}
            arrValue={tableInfo.moduleId}
            onChange={(value: number) => setTableInfo({ ...tableInfo, moduleId: value })}
            containerClassName='formGroup'
            isFormik={false}
          />
          <FormSelect
            type='text'
            name='statusId'
            placeholder='Status'
            options={statusOptions}
            values={tableInfo.statusId}
            arrValue={tableInfo.statusId}
            onChange={(value: number) => setTableInfo({ ...tableInfo, statusId: value })}
            containerClassName='formGroup'
            isFormik={false}
            required
          /> */}
            <ResetFilterWrapper
              className='flex items-center'
              onClick={() => {
                setTableInfo(InitialValuesForm.tableParams);
              }}>
              <span className='material-symbols-outlined'>settings_backup_restore</span>
              <Heading variant='body-s' title='Reset Filter' />
            </ResetFilterWrapper>
          </div>
        </div>
        <SettingSystemUserButton className=''>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add User Defined Field'}
            icon={'add_circle_outline'}
            onClick={() => {
              setModalStatus('Add');
              setInitialUdfState(InitialValuesForm.udfInitialForm);
              openModal();
            }}
          />
        </SettingSystemUserButton>
      </div>

      {isModalOpen && (
        <UdfModel
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          modalStatus={modalStatus}
          isLoading={isLoading}
          initialData={initialUdfState}
          handleRequest={handleRequest}
          statusOptions={statusOptions}
          moduleOptions={moduleOptions}
          error={error}
        />
      )}
      <Table
        columns={udfHeader(actions)}
        data={UserDefinedList?.items ?? []}
        totalCounts={UserDefinedList?.totalCount ?? 10}
        loading={status}
        showPagination
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />

      {isConfirmModalOpen && (
        <AlertPopup
          isShow={isConfirmModalOpen}
          title={'this UDF'}
          closeModal={closeConfirmModal}
          handleSubmit={handleDeleteUdf}
          modalStatus={modalStatus}
          loading={isLoading}
        />
      )}
    </>
  );
};

export default UserDefineFields;
