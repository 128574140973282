import { type UseQueryResult, useQuery } from 'react-query';
import { StateService } from '../../services/wealthlane-tax-services';
import { type OptionType } from '../../types/common';

const useGetTaxStateOptionsByJurisdiction = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  jurisdictionId?: string | null,
  id?: string | null
): UseQueryResult<
  // eslint-disable-next-line camelcase
  OptionType[] | undefined,
  unknown
> => {
  const taxStateOptionsByJurisdiction = useQuery(
    ['getTaxStatesByJurisdiction', id, jurisdictionId],
    async () => {
      if (
        id !== undefined &&
        id !== null &&
        id !== '' &&
        jurisdictionId !== undefined &&
        jurisdictionId !== null &&
        jurisdictionId !== ''
      ) {
        return await handleRequest(
          StateService.getApiTaxApiAppStateStateByJurisdiction(jurisdictionId, id)
        );
      } else {
        return [];
      }
    },
    {
      select: (data) => {
        return data?.filter((state)=> state?.id !== null)?.map((item) => {
          return {
            label: item?.name ?? '',
            value: item?.id ?? '',
            abbreviation: item?.abbreviation ?? '',
          };
        });
      },
      // cacheTime: 1000 * 60 * 5,
    }
  );

  return taxStateOptionsByJurisdiction;
};

export default useGetTaxStateOptionsByJurisdiction;
