import { type UseQueryResult, useQuery } from 'react-query';
import {
  UserLogInAndSecurityService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_UserLogInAndSecurity_UserLogInAndSecurityDto,
} from '../../services/wealthlane-customer-services';

const useGetUserLoginAndSecurity = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_UserLogInAndSecurity_UserLogInAndSecurityDto | undefined,
  unknown
> => {
  const userLoginAndSecurity = useQuery(['getUserLoginAndSecurity'], async () => {
    return await handleRequest(
      UserLogInAndSecurityService.getApiCustomerApiAppUserLogInAndSecurityUserLogInAndSecurity()
    );
  });
  return userLoginAndSecurity;
};

export default useGetUserLoginAndSecurity;
