import { type UseQueryResult, useQuery } from 'react-query';
import { PsuElectionWindowConfigurationService } from '../../services/wealthlane-award-settlement-services';

const useGetPSUElectionWindowConfiguration = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const getPSUElectionWindowConfigurationList = useQuery(
    ['getPSUElectionWindowConfigurationList'],
    async () => {
      return await handleRequest(
        PsuElectionWindowConfigurationService.getApiAwardSettlementApiAppPSUElectionWindowConfigurationPSUElectionWindowConfigurations()
      );
    },
    {
      cacheTime: 1000 * 60 * 5,
    }
  );
  return getPSUElectionWindowConfigurationList;
};

export default useGetPSUElectionWindowConfiguration;
