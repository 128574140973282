/* eslint-disable camelcase */
import { type UseQueryResult, useQuery } from 'react-query';
import {
  IntegrationProviderService,
  type Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_GetIntegrationProviderDto,
} from '../../services/wealthlane-hr-integration-services';

const useGetIntegrationProvider = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  Wealthlane_HRIntegrationService_Dtos_IntegrationProviders_GetIntegrationProviderDto | undefined,
  unknown
> => {
  const IntegrationProvider = useQuery(['getIntegrationProvider'], async () => {
    return await handleRequest(
      IntegrationProviderService.getApiHrintegrationApiAppIntegrationProviderIntegrationProvider()
    );
  });

  return IntegrationProvider;
};

export default useGetIntegrationProvider;
