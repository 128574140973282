/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserSecurityQuestionsAnswersDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserSecurityQuestionsAnswersDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUser_UpdateCustomerUserSecurityQuestionsAnswersDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUser_UpdateCustomerUserSecurityQuestionsAnswersDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserContactNumberDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserContactNumberDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserLogInPasswordDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserLogInPasswordDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserSecurityQuestionsDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserSecurityQuestionsDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInAndSecurityDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInAndSecurityDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_VerifyCustomerUserMultiPurposeSecurityQuestionAnswerDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_VerifyCustomerUserMultiPurposeSecurityQuestionAnswerDto';
import type { Wealthlane_CustomerService_Dtos_CustomerUserOTP_CustomerUserMultiPurposeOTPVerficationDto } from '../models/Wealthlane_CustomerService_Dtos_CustomerUserOTP_CustomerUserMultiPurposeOTPVerficationDto';
import type { Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto } from '../models/Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto';
import type { Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto } from '../models/Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerUserLogInAndSecurityService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInAndSecurityDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserLogInAndSecurityCustomerUserLogInAndSecurity(): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInAndSecurityDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user-log-in-and-security/customer-user-log-in-and-security',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserLogInAndSecurityValidateMultiPurposeToken(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-log-in-and-security/validate-multi-purpose-token',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserSecurityQuestionsAnswersDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserLogInAndSecurityCustomerUserSecurityQuestionsAnswers(): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_CustomerUserSecurityQuestionsAnswersDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user-log-in-and-security/customer-user-security-questions-answers',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_CustomerUser_UpdateCustomerUserSecurityQuestionsAnswersDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppCustomerUserLogInAndSecurityCustomerSecurityAnswers(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUser_UpdateCustomerUserSecurityQuestionsAnswersDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_CustomerUser_UpdateCustomerUserSecurityQuestionsAnswersDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/customer-user-log-in-and-security/customer-security-answers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserLogInAndSecuritySendCustomerUserMultiPurposeOTp(): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-log-in-and-security/send-customer-user-multi-purpose-oTP',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserLogInAndSecurityVerifyCustomerUserMultiPurposeOTp(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUserOTP_CustomerUserMultiPurposeOTPVerficationDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-log-in-and-security/verify-customer-user-multi-purpose-oTP',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserLogInAndSecurityChangeCustomerUserSecurityQuestions(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserSecurityQuestionsDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-log-in-and-security/change-customer-user-security-questions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserLogInAndSecurityChangeCustomerUserContactNumber(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserContactNumberDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-log-in-and-security/change-customer-user-contact-number',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserLogInAndSecurityVerifyCustomerUserMultiPurposeSecurityQuestionAnswer(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_VerifyCustomerUserMultiPurposeSecurityQuestionAnswerDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-log-in-and-security/verify-customer-user-multi-purpose-security-question-answer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerUserLogInAndSecurityChangeCustomerUserLogInPasswordWithSecurityQuestionAnswer(
requestBody?: Wealthlane_CustomerService_Dtos_CustomerUserLogInAndSecurity_ChangeCustomerUserLogInPasswordDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_CustomerUserLogInAndSecurity_CustomerUserLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-user-log-in-and-security/change-customer-user-log-in-password-with-security-question-answer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserLogInAndSecuritySecurityQuestionsByCustomerUserId(): CancelablePromise<Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user-log-in-and-security/security-questions-by-customer-user-id',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCustomerUserLogInAndSecurityCheckCustomerSignUp(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/customer-user-log-in-and-security/check-customer-sign-up',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
