import { type UseQueryResult, useQuery } from 'react-query';
import {
  CustomerUserLogInAndSecurityService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto,
} from '../../services/wealthlane-customer-services';

const useGetSelectedSecurityQuestions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto | undefined,
  unknown
> => {
  const getSelectedSecurityQuestions = useQuery(['getSelectedSecurityQuestions'], async () => {
    return await handleRequest(
      CustomerUserLogInAndSecurityService.getApiCustomerApiAppCustomerUserLogInAndSecuritySecurityQuestionsByCustomerUserId()
    );
  });

  return getSelectedSecurityQuestions;
};

export default useGetSelectedSecurityQuestions;
