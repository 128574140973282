import { type UseQueryResult, useQuery } from 'react-query';
import { OptionAwardService } from '../../services/wealthlane-award-services';

const useGetOptionAwardsPlanTypes = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const OptionAwardsPlanTypes = useQuery(
    ['getOptionAwardsPlanTypes'],
    async () => {
      return await handleRequest(OptionAwardService.getApiAwardApiAppOptionAwardPlanTypes());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return OptionAwardsPlanTypes;
};

export default useGetOptionAwardsPlanTypes;
