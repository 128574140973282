/* eslint-disable camelcase */
import { type UseMutationResult, useMutation } from 'react-query';
import { TaxCalculationService } from '../../services/wealthlane-tax-services';

const usePostTaxCalculationImport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult => {
  return useMutation(async (payload: any) => {
    return await handleRequest(
      TaxCalculationService.postApiTaxApiAppTaxCalculationTaxCalculationUsingImport(payload)
    );
  });
};

export default usePostTaxCalculationImport;
