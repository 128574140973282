/* eslint-disable camelcase */
import { type UseMutationResult, useMutation } from 'react-query';
import {
  TaxItemApprovalService,
  type Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto,
} from '../../services/wealthlane-tax-services';

import { CommaFormatted } from '../../utils/global';
import InitialValuesForm from '../../constants/initialValues';

const useGetTaxApprovalViewByTaxId = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setInitialData: any
): UseMutationResult<
  Wealthlane_TaxService_Dtos_TaxItemDto_TaxItemDto | undefined,
  unknown,
  string,
  unknown
> => {
  return useMutation(
    async (id: string) => {
      return await handleRequest(
        TaxItemApprovalService.getApiTaxApiAppTaxItemApprovalTaxItemById(id)
      );
    },
    {
      onSuccess: (response) => {
        if (response != null) {
          const sortedTaxBracket = response?.taxBrackets?.sort(
            (a: any, b: any) => Number(a.lowerLimit) - Number(b.lowerLimit)
          );
          const userDefined =
            response?.userDefinedCriteria !== null
              ? JSON.parse(String(response?.userDefinedCriteria?.replaceAll('/', '')))
              : null;

          const payload = {
            ...response,
            taxBrackets: sortedTaxBracket?.map((a: any, i: any) => ({
              ...a,
              lowerLimit: CommaFormatted(a?.lowerLimit),
              upperLimit:
                sortedTaxBracket[i + 1]?.lowerLimit !== undefined
                  ? CommaFormatted(sortedTaxBracket[i + 1]?.lowerLimit)
                  : '',
            })),
            userDefinedCriteria:
              response?.userDefinedCriteria !== null
                ? userDefined
                : InitialValuesForm?.taxSetup?.userDefinedCriteria,
          };
          setInitialData(payload);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default useGetTaxApprovalViewByTaxId;
